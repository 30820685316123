<div class="row">
    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 card card-shadow btn btn-lg btn-success" (click)="loadDataForPopUp()" data-bs-toggle="modal" data-bs-target="#infomodal">
        <h2 i18n="@@createOrder">Create Order</h2>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
</div>
<app-spinner *ngIf="(usersRepo.isLoading$ | async) || (ordersRepo.isLoading$ | async)"></app-spinner>
<app-orders-filter *ngIf="!(usersRepo.isLoading$ | async)" (filterEmiter)="loadByFilter($event)" [drivers]="usersRepo.all$ | async"></app-orders-filter>
<ng-container *ngVar="drivers$ | async as drivers">
    <ng-container *ngVar="ordersRepo.orders$ | async as orders">
        <div class="mt-4 ms-2 card card-shadow btn-lg blood-red text-white ms-2 mt-3 scaled-long" *ngIf="!(ordersRepo.isLoading$ | async) && checkRejected(orders)">
            <h5 class="mt-2" i18n="@@rejectedOrders">Rejected orders</h5>
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th i18n="@@rejectedDate" class="text-center align-middle">Date</th>
                            <th i18n="@@rejectedStart" class="text-center align-middle">Start</th>
                            <th i18n="@@rejectedClient" class="text-center align-middle">Client</th>
                            <th i18n="@@rejectedWeight" class="text-center align-middle">Weight</th>
                            <th i18n="@@rejectedProduct" class="text-center align-middle">Product</th>
                            <th i18n="@@rejectedReference" class="text-center align-middle">Reference</th>
                            <th i18n="@@rejectedBy" class="text-center align-middle">Rejected By</th>
                            <th i18n="@@rejectedAt" class="text-center align-middle">Rejected At</th>
                            <th i18n="@@rejectedInfo" class="text-center align-middle">Trip info</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let order of orders; let i = index" [draggable]="true" class="hoverable grabbable">
                            <ng-container class="ms-4" *ngIf="order.isRejected">
                                <td class="text-center align-middle">{{order.plannedToStartAt | date:'dd/MM/yyyy'}}</td>
                                <td class="text-center align-middle">{{order.plannedToStartAt | date: 'h:mm'}}</td>
                                <td class="text-center align-middle">{{order?.client?.name}}</td>
                                <td class="text-center align-middle">{{order.weight}}</td>
                                <td class="text-center align-middle">{{getProducts(order?.products)}}</td>
                                <td class="text-center align-middle">{{order.reference}}</td>
                                <td class="text-center align-middle">{{order.userName}}</td>
                                <td class="text-center align-middle">{{order.rejectedAt | date}}</td>
                                <td class="text-center align-middle"><i class="bi bi-info-circle hoverable" *ngIf="order" (click)="loadDataForPopUp(); updateChild(order)" data-bs-toggle="modal" data-bs-target="#infomodal"></i></td>
                            </ng-container>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    
        <div class="mt-4 ms-2 card card-shadow btn-lg bordered-primary text-white mb-4 scaled-medium" *ngIf="!(ordersRepo.isLoading$ | async) ">
            <!-- if we don't need open orders for 1 driver filter && drivers && drivers.length && drivers.length > 1-->
            <h5 class="mt-2" i18n="@@openOrders">Open trips</h5>
            <div class="table-responsive">
                <table class="table table-bordered bordered-primary">
                    <thead>
                        <tr>
                            <th i18n="@@openDate" class="text-center align-middle">Date</th>
                            <th i18n="@@openStart" class="text-center align-middle">Start</th>
                            <th i18n="@@openClient" class="text-center align-middle">Client</th>
                            <th i18n="@@openWeight" class="text-center align-middle">Weight</th>
                            <th i18n="@@openProduct" class="text-center align-middle">Product</th>
                            <th i18n="@@openReference" class="text-center align-middle">Reference</th>                        
                            <th i18n="@@openInfo" class="text-center align-middle">Trip info</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let order of orders; let i = index" [draggable]="true" class="hoverable grabbable">
                            <ng-container *ngIf="!findEvent(order, 'TripEnd') && !findEvent(order, 'TripStart') && !order.userId" class="ms-4">
                                <td class="text-center align-middle">{{order.plannedToStartAt | date:'dd/MM/yyyy'}}</td>
                                <td class="text-center align-middle">{{order.plannedToStartAt | date: 'h:mm'}}</td>
                                <td class="text-center align-middle">{{order?.client?.name}}</td>
                                <td class="text-center align-middle">{{order.weight}}</td>
                                <td class="text-center align-middle">{{getProducts(order?.products)}}</td>
                                <td class="text-center align-middle">{{order.reference}}</td>
                                <td class="text-center align-middle"><i class="bi bi-info-circle hoverable" *ngIf="order" (click)="loadDataForPopUp(); updateChild(order)" data-bs-toggle="modal" data-bs-target="#infomodal"></i></td>
                            </ng-container>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <ng-container *ngFor="let driver of (drivers$ | async)" >
            <ng-container>
                <div class="mt-4 ms-2 card card-shadow btn-lg text-center ms-2 mt-2 mb-3 scaled-medium" *ngIf="!(ordersRepo.isLoading$ | async)">                
                    <h5><span i18n="@@driverCard">Driver </span>{{driver?.name}} {{driver?.surname}} <span *ngIf="driver.vehicleName" i18n="@@driverVehicleCard">reg. nr. </span> {{driver.vehicleName}} <span *ngIf="driver.trailerName" i18n="@@driverTrailerCard">trailer </span> {{driver.trailerName}}</h5>                               
                </div>
                <div class="mt-4 ms-2 card card-shadow btn-lg bg-success text-white mb-4 scaled-medium" *ngIf="!(ordersRepo.isLoading$ | async) && checkFinished(orders, driver.id)">    
                    <div class="table-responsive">
                        <table class="table table-bordered bordered-success">
                            <thead>
                                <tr>
                                    <th i18n="@@successDate" class="text-center align-middle">Date</th>
                                    <th i18n="@@successStart" class="text-center align-middle">Start</th>
                                    <th i18n="@@successClient" class="text-center align-middle">Client</th>
                                    <th i18n="@@successWeight" class="text-center align-middle">Weight</th>
                                    <th i18n="@@successProduct" class="text-center align-middle">Product</th>
                                    <th i18n="@@successReference" class="text-center align-middle">Reference</th>
                                    <th i18n="@@successTime" class="text-center align-middle">Time</th>
                                    <th i18n="@@successInfo" class="text-center align-middle">Trip info</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let order of orders; let i = index" [draggable]="true" class="hoverable grabbable">
                                    <ng-container class="ms-4" *ngIf="order.userId === driver.id"> <!--findEvent(order, 'TripEnd') && --> 
                                        <td class="text-center align-middle">{{order.plannedToStartAt | date:'dd/MM/yyyy'}}</td>
                                        <td class="text-center align-middle">{{order.plannedToStartAt | date: 'h:mm'}}</td>
                                        <td class="text-center align-middle">{{order?.client?.name}}</td>
                                        <td class="text-center align-middle">{{order.weight}}</td>
                                        <td class="text-center align-middle">{{getProducts(order?.products)}}</td>
                                        <td class="text-center align-middle">{{order.reference}}</td>
                                        <td class="text-center align-middle">{{order.time}}</td>
                                        <td class="text-center align-middle" ><i class="bi bi-info-circle hoverable" *ngIf="order" (click)="loadDataForPopUp(); updateChild(order)" data-bs-toggle="modal" data-bs-target="#infomodal"></i></td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="mt-4 ms-2 card card-shadow btn-lg bg-warning text-white mb-4 scaled-medium" *ngIf="!(ordersRepo.isLoading$ | async) && checkStarted(orders, driver.id)">    
                    <div class="table-responsive">
                        <table class="table table-bordered bordered-warning">
                            <thead>
                                <tr>
                                    <th i18n="@@currentDate" class="text-center align-middle">Date</th>
                                    <th i18n="@@currentStart" class="text-center align-middle">Start</th>
                                    <th i18n="@@currentClient" class="text-center align-middle">Client</th>
                                    <th i18n="@@currentWeight" class="text-center align-middle">Weight</th>
                                    <th i18n="@@currentProduct" class="text-center align-middle">Product</th>
                                    <th i18n="@@currentReference" class="text-center align-middle">Reference</th>
                                    <th i18n="@@currentTime" class="text-center align-middle">Time</th>
                                    <th i18n="@@currentInfo" class="text-center align-middle">Trip info</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let order of orders; let i = index" [draggable]="true" class="hoverable grabbable"><!--  findEvent(order, 'TripStart') && !findEvent(order, 'TripEnd') &&  -->
                                    <ng-container class="ms-4" *ngIf="order.userId === driver.id">
                                        <td class="text-center align-middle">{{order.plannedToStartAt | date:'dd/MM/yyyy'}}</td>
                                        <td class="text-center align-middle">{{order.plannedToStartAt | date: 'h:mm'}}</td>
                                        <td class="text-center align-middle">{{order?.client?.name}}</td>
                                        <td class="text-center align-middle">{{order.weight}}</td>
                                        <td class="text-center align-middle">{{getProducts(order?.products)}}</td>
                                        <td class="text-center align-middle">{{order.reference}}</td>
                                        <td class="text-center align-middle">{{order.time}}</td>
                                        <td class="text-center align-middle"><i class="bi bi-info-circle hoverable" *ngIf="order" (click)="loadDataForPopUp(); updateChild(order)" data-bs-toggle="modal" data-bs-target="#infomodal"></i></td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="mt-4 ms-2 card card-shadow btn-lg blood-red text-white mb-2 ms-2 mt-3 scaled-medium" *ngIf="!(ordersRepo.isLoading$ | async)">                
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th i18n="@@nextDate" class="text-center align-middle">Date</th>
                                    <th i18n="@@nextStart" class="text-center align-middle">Start</th>
                                    <th i18n="@@nextClient" class="text-center align-middle">Client</th>
                                    <th i18n="@@nextWeight" class="text-center align-middle">Weight</th>
                                    <th i18n="@@nextProduct" class="text-center align-middle">Product</th>
                                    <th i18n="@@nextReference" class="text-center align-middle">Reference</th>                        
                                    <th i18n="@@nextInfo" class="text-center align-middle">Trip info</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let order of orders; let i = index" [draggable]="true" class="hoverable grabbable">
                                    <ng-container class="ms-4" *ngIf="!findEvent(order, 'TripStart') && !findEvent(order, 'TripEnd') && order.userId === driver.id">
                                        <td class="text-center align-middle">{{order.plannedToStartAt | date:'dd/MM/yyyy'}}</td>
                                        <td class="text-center align-middle">{{order.plannedToStartAt | date: 'h:mm'}}</td>
                                        <td class="text-center align-middle">{{order?.client?.name}}</td>
                                        <td class="text-center align-middle">{{order.weight}}</td>
                                        <td class="text-center align-middle">{{getProducts(order?.products)}}</td>
                                        <td class="text-center align-middle">{{order.reference}}</td>
                                        <td class="text-center align-middle"><i class="bi bi-info-circle hoverable" *ngIf="order" (click)="loadDataForPopUp(); updateChild(order)" data-bs-toggle="modal" data-bs-target="#infomodal"></i></td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </ng-container>
        </ng-container>    
    </ng-container>
</ng-container>
<div class="modal fade" id="infomodal" tabindex="-1" aria-labelledby="infomodal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content bg-lights border-0">
        <div class="modal-header">
          <h6 class="modal-title" id="infomodalLabel" i18n="Title for infomodal window">Order Info</h6>
          <button type="button" class="btn-close" (click)="orderUpdate = null; resetChild()" #closeModal data-bs-dismiss="modal" aria-label="Close" i18n-aria-label="Close label @@close"></button>
        </div>
        <div class="modal-body">
          <div class="form-floating mb-3">
           <app-order-form  (orderEmiter)="createUpdateOrder($event)"
                            [orderUpdate]="orderUpdate"
                            [drivers]="usersRepo.all$ | async"
                            [accessories]="accessoryRepo.all$ | async"
                            [cleanings]="cleaningRepo.all$ | async"
                            [companies]="companiesRepo.all$ | async"
                            [orders]="ordersRepo.orders$ | async"
                            [products]="productsRepo.all$ | async"
           ></app-order-form>  
          </div>
        </div>        
      </div>
    </div>
  </div>
