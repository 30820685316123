import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filterNil } from '@ngneat/elf';
import { combineLatest, Observable } from 'rxjs';
import { map, skipWhile, switchMap, tap } from 'rxjs/operators';
import { Post, PostsRepository } from 'src/app/state/posts.repository';
import { PostsService } from 'src/app/state/posts.service';

@Component({
  selector: 'app-news-post',
  templateUrl: './news-post.component.html',
  styleUrls: ['./news-post.component.scss']
})
export class NewsPostComponent implements OnInit {
  post$!: Observable<Post>;
  image = 'assets/img/product1.jpg';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private repo: PostsRepository,
    private service: PostsService
  ) { }

  ngOnInit(): void {
    const id$ = this.route.paramMap.pipe(
      map(param => param.get('id')!)
    );
    id$.pipe(
      // request data
      switchMap(id => this.service.loadOne(id))
    ).subscribe();
    this.post$ = id$.pipe(
      switchMap(id => combineLatest([this.repo.post(id), this.repo.status(id)])),
      skipWhile(([_, status]) => status.value === 'pending' || status.value === 'idle'),
      map(([post, _]) => post),
      tap(post => {
        if (!post) {
          this.router.navigate(['/not-found'], { skipLocationChange: true });
        }
      }),
      filterNil()
    );
  }

}
