import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { ScheduledForRetryError } from '../modules/core/interceptors/retry.interceptor';
import { DayjsService } from '../modules/shared/services/dayjs.service';
import { sortBy } from '../modules/shared/pipes/sort.pipe';
import {
  buildIdPromise,
  buildUrlPromise,
  RequestsRepository,
} from './requests.repository';
import { TrailersRepository } from './trailers.repository';
import { TripEventsRepository } from './tripevents.repository';
import {
  BulkEProduct,
  PushedInvoiceResponse,
  skipWhileTripsCached,
  StringContainer,
  trackTripsRequestsStatus,
  Trip,
  TripDto,
  TripEvent,
  TripEventFile,
  TripEventType,
  TripsRepository,
} from './trips.repository';
import { VehiclesRepository } from './vehicles.repository';
import { FilterOptions } from './workdays.repository';
import { DeletedTripsRepository } from './deletedtrips.repository';
import {
  Order,
  OrdersRepository,
  trackOrdersRequestsStatus,
} from './orders.repository';
import { TripLogDto } from './models/trip/TripLogDto';
import { TripLogFilterDto } from './models/shared/TripLogFilterDto';
import { TenantNoteChangeDto } from './models/shared/TenantNoteChangeDto';
import { TripPhotoUrlDto } from './models/trip/TripPhotoUrlDto';
import { TripEventClientDto } from './models/trip/TripEventClientDto';

const API = '/api/trips';
const EVENTS_API = '/api/tripevents';
const SUMMARY_API = '/api/usersSummary';
const OFFLINE_ERROR = $localize`:Could not load info while offline:Not available offline`;

@Injectable({
  providedIn: 'root',
})
export class TripsService {
  constructor(
    private http: HttpClient,
    private repo: TripsRepository,
    private ordersRepo: OrdersRepository,
    private tripEventsRepo: TripEventsRepository,
    private requestsRepo: RequestsRepository,
    private vehicles: VehiclesRepository,
    private trailers: TrailersRepository,
    public ngDay: DayjsService
  ) {}

  loadTripLogsOverview(
    filterOptions: FilterOptions,
    isEconomic: boolean = false
  ) {
    let filter: TripLogFilterDto = {
      dateTo: filterOptions.dateTo ?? '',
      dateFrom: filterOptions.dateFrom ?? '',
      clientId: filterOptions.clientSelected
        ? filterOptions.clientSelected
        : undefined,
      driverId: filterOptions.driverSelected
        ? filterOptions.driverSelected
        : undefined,
      productIds: filterOptions.productSelected
        ? filterOptions.productSelected
        : undefined,
      isApproved: filterOptions.isApproved ?? false,
      isParkedOverview: filterOptions.isParkedOverview,
      isEconomic: isEconomic,
    };
    return this.http.put<TripLogDto[]>(
      `${SUMMARY_API}/triplogsoverview`,
      filter
    );
  }

  updateApproveStatus(id: string) {
    return this.http.patch<Trip>(`${API}/${id}/changeApproveStatus`, {});
  }

  updateTenantNote(request: TenantNoteChangeDto) {
    const query = [`tenantNote=${request.tenantNote}`];
    return this.http.patch(
      `${API}/tenantNote/${request.id}?${query.join('&')}`,
      {}
    );
  }

  getEventClients(id: string) {
    return this.http.get<TripEventClientDto[]>(`${API}/getEventsClients/${id}`);
  }

  addTripEventPhoto(tripId: string, tripEventId?: string, files?: File[]) {
    if (!files || !files.length) {
      return of({ tripId });
    }
    const formData = new FormData();
    if (files && files.length > 0) {
      for (let f of files) {
        formData.append('files', f);
      }
    }
    const query = [`tripEventId=${tripEventId ?? ''}`];

    return this.http.post(
      `${API}/addphoto/${tripId}?${query.join('&')}`,
      formData
    );
  }

  getPhotos(id: string, type: TripEventType) {
    const query = [`type=${type}`];
    return this.http.get<TripPhotoUrlDto[]>(
      `${API}/getphotos/${id}?${query.join('&')}`
    );
  }

  movePhotoEvent(event: TripPhotoUrlDto) {
    return this.http.patch(`${API}/movephoto/${event.id}`, {});
  }

  //Not refactored//

  loadOneTemp(id: string) {
    return this.http.get<Trip>(`${API}/temp/${id}`);
  }

  load() {
    return this.http.get<Trip[]>(API).pipe(
      tap((res) => this.repo.setTrips(res)),
      trackTripsRequestsStatus(this.repo.name),
      skipWhileTripsCached(this.repo.name)
    );
  }

  loadOverview() {
    return this.http.get<Trip[]>(`${SUMMARY_API}/tripsoverview`).pipe(
      tap((res) => this.repo.setTrips(res)),
      trackTripsRequestsStatus(this.repo.name)
      //skipWhileTripsCached(this.repo.name)
    );
  }

  loadForGoPage() {
    return this.http.get<Trip[]>(`${SUMMARY_API}/gopage`).pipe(
      tap((res) => this.repo.setTrips(res)),
      trackTripsRequestsStatus(this.repo.name)
    );
  }

  loadCurrent() {
    return this.http.get<Trip>(`${API}/current`).pipe(
      tap((trip) => {
        if (trip) {
          this.repo.upsertTrip(trip, `${this.repo.name}_current`);
          this.repo.setActiveId(trip.id);
        }
      }),
      trackTripsRequestsStatus(`${this.repo.name}_current`)
    );
  }

  bulkUpdate(dto: BulkEProduct) {
    return this.http.patch(`${API}/bulkeconomicupdate`, dto);
  }

  unexport(id: string) {
    return this.http.get<Trip>(`${API}/unexport/${id}`);
  }

  updateOfficeNote(id: string, note?: string) {
    return this.http.patch<Trip>(`${API}/${id}/officenote`, { url: note });
  }

  updateOfficeAddress(
    id: string,
    pickUpAddress?: string,
    deliveryAddress?: string
  ) {
    return this.http.patch<Trip>(`${API}/${id}/officeaddress`, {
      pickUpAddress: pickUpAddress,
      deliveryAddress: deliveryAddress,
    });
  }

  parkTrip(id: string, park: string) {
    return this.http
      .patch<Trip>(`${API}/${id}/parktrip/${park}`, { park: park })
      .pipe(
        tap((trip) => {
          if (trip && !trip.isOrder) {
            this.repo.upsertTrip(trip);
            this.repo.setActiveId(null);
          }
        }),
        trackTripsRequestsStatus(id)
      );
  }

  resumeTrip(id: string, resume: string) {
    return this.http
      .patch<Trip>(`${API}/${id}/resumetrip/${resume}`, { resume: resume })
      .pipe(
        tap((trip) => {
          if (trip && !trip.isOrder) {
            this.repo.setActiveId(trip.id);
            this.repo.upsertTrip(trip);
          }
        }),
        trackTripsRequestsStatus(id)
      );
  }

  eexport(ids: string[], union: boolean = false) {
    return this.http.patch<PushedInvoiceResponse[]>(
      `/api/sync/eexport/${union}`,
      ids
    );
  }

  loadOne(id: string) {
    return this.http.get<Trip>(`${API}/${id}`).pipe(
      tap((trip) => {
        if (trip) {
          this.repo.upsertTrip(trip);
        }
      }),
      trackTripsRequestsStatus(id)
    );
  }

  addPhoto(id?: string, files?: File[] | null) {
    if (!files || !files.length) {
      // No photos included - skip upload
      return of({ id });
    }
    const formData = new FormData();
    if (files && files.length > 1) {
      for (let f of files) {
        formData.append('files', f);
      }
    } else if (files && files.length === 1) {
      formData.append('files', files[0]);
    }
    return this.http.post<Trip>(`${API}/${id}/addphoto`, formData).pipe(
      tap((trip) => this.repo.upsertTrip(trip)),
      tap((trip) => trackTripsRequestsStatus(trip.id))
    );
  }

  updateApprove(entity: TripDto) {
    return this.http
      .patch<Trip>(`${API}/${entity.tripId}/isApproved/${entity.isApproved}`, {
        isApproved: entity.isApproved,
      })
      .pipe(tap(), trackTripsRequestsStatus(entity.tripId!));
  }

  start(
    event: Partial<TripEvent>,
    vehicleId: string,
    trailerId?: string,
    userId?: string,
    files?: File[] | null
  ) {
    const startEvent = {
      ...event,
      type: 'TripStart' as TripEventType,
      eventTime: this.ngDay.dayjs.utc().toDate(),
      createdAt: this.ngDay.dayjs.utc().toDate(),
    };
    const pickUpAddress = localStorage.getItem('pickupaddress') || '';
    const deliveryAddress = localStorage.getItem('deliveryaddress') || '';
    return this.http
      .post<Trip>(API, {
        vehicleId,
        trailerId,
        pickUpAddress,
        deliveryAddress,
        type: 'Start',
        comment: event.comment,
        reference: event.reference,
        plannedToStartAt: event.eventTime,
        clientId: event.clientId,
        weight: event.weight,
        products: event.products,
      })
      .pipe(
        switchMap((trip) =>
          this.http.post<Trip>(`${API}/${trip.id}/events`, startEvent)
        ),
        this.processOfflineSubmit(startEvent, vehicleId, trailerId),
        switchMap((trip) => {
          if (!files || !files.length) {
            // No photos included - skip upload
            return of(trip);
          }
          const lastCreatedEvent = sortBy(
            trip.tripEvents.filter((x) => x.type !== 'Total'),
            {
              parameter: { property: 'createdAt' },
              direction: 'desc',
            }
          )[0];
          const formData = new FormData();
          files.forEach((x) => formData.append('files', x));
          return this.http.post<Trip>(
            `${EVENTS_API}/${lastCreatedEvent.id}/photos`,
            formData
          );
        }),
        tap((trip) => {
          if (!trip.userId && userId) {
            trip.userId = userId;
          }
          this.repo.upsertTrip(trip);
          this.repo.setActiveId(trip.id);
          trackTripsRequestsStatus(trip.id);
          this.tripEventsRepo.clearPages();
        })
      );
  }

  takephoto(tripEvent: string, files?: File[] | null) {
    if (!files || !files.length) {
      // No photos included - skip upload
      return of(tripEvent);
    }
    const formData = new FormData();
    if (files && files.length > 1) {
      for (let f of files) {
        formData.append('files', f);
      }
    } else if (files && files.length === 1) {
      formData.append('files', files[0]);
    }

    return this.http.patch<Trip>(`${EVENTS_API}/${tripEvent}`, formData);
  }

  tripAction(
    eventType: string,
    trip: Trip,
    event: Partial<TripEvent>,
    files?: File[] | null
  ) {
    const tripEvent = {
      ...event,
      type: eventType as TripEventType,
      eventTime: this.ngDay.dayjs.utc().toDate(),
      createdAt: this.ngDay.dayjs.utc().toDate(),
    };
    return this.http.post<Trip>(`${API}/${trip.id}/events`, tripEvent).pipe(
      this.processOfflineSubmit(tripEvent),
      switchMap((trip) => {
        if (!files || !files.length) {
          return of(trip);
        }
        const lastCreatedEvent = sortBy(
          trip.tripEvents.filter((x) => x.type !== 'Total'),
          {
            parameter: { property: 'createdAt' },
            direction: 'desc',
          }
        )[0];
        const formData = new FormData();
        files.forEach((x) => formData.append('files', x));
        return this.http.post<Trip>(
          `${EVENTS_API}/${lastCreatedEvent.id}/photos`,
          formData
        );
      }),
      tap((trip) => {
        this.repo.upsertTrip(trip);
        if (eventType == 'TripEnd') {
          this.repo.setActiveId(null);
        }
        this.tripEventsRepo.clearPages();
      }),
      trackTripsRequestsStatus(trip.id)
    );
  }

  weighing(trip: Trip, event?: Partial<TripEvent>, files?: File[] | null) {
    const weighing = {
      ...event,
      type: 'Weighing' as TripEventType,
      eventTime: this.ngDay.dayjs.utc().toDate(),
    };
    return this.http.post<Trip>(`${API}/${trip?.id}/events`, weighing).pipe(
      this.processOfflineSubmit(weighing),
      switchMap((trip) => {
        if (!files || !files.length) {
          return of(trip);
        }
        const lastCreatedEvent = sortBy(
          trip.tripEvents.filter((x) => x.type !== 'Total'),
          {
            parameter: { property: 'createdAt' },
            direction: 'desc',
          }
        )[0];
        const formData = new FormData();
        if (files && files.length > 1) {
          for (let f of files) {
            formData.append('files', f);
          }
        } else if (files && files.length === 1) {
          formData.append('files', files[0]);
        }
        return this.http.post<TripEvent>(
          `${API}/${lastCreatedEvent.id}/photos`,
          formData
        );
      })
    );
  }

  addNote(tripEventId: string, note: string): any {
    var text: StringContainer = { text: note };
    let tevent = this.tripEventsRepo.getOne(tripEventId);
    if (tevent) {
      tevent.note = note;
    }
    return this.http.patch<Trip>(`${API}/${tripEventId}/addnote`, text).pipe(
      tap((x) => {
        if (tevent && !x.isOrder) {
          x.tripEvents.push(tevent);
          this.repo.upsertTrip(x);
        }
      }),
      tap((x) => {
        if (tevent && !x.isOrder) {
          this.tripEventsRepo.upsert(tevent);
        }
      }),
      tap((x) => {
        let o = x as Order;
        if (o && o.isOrder) {
          o.note = note;
          if (tevent) {
            o.tripEvents.push(tevent);
          }
          this.ordersRepo.upsertOrder(o);
        }
      }),

      this.processOfflineNoteSubmit(tripEventId, note)
    );
  }

  private processOfflineNoteSubmit(tripEventId: string, note: string) {
    this.requestsRepo.all$.subscribe((x) => {
      if (x) {
        x.forEach((x) => {
          if (x.url.includes(tripEventId)) {
            x.body.note = note;
          }
        });
      }
    });
    return catchError((err) => {
      if (err instanceof ScheduledForRetryError) {
        if (err.request.url.includes(API)) {
          return of<TripEvent>();
        }
      }
      return err;
    });
  }

  changePhotoEvent(url: string) {
    return this.http
      .patch<Trip>(`${API}/photos`, { url: url })
      .pipe(tap((trip) => this.repo.upsertTrip(trip)));
  }

  export(): Observable<string> {
    return this.http
      .get<Blob>(`${API}/csv`, { responseType: 'blob' as any })
      .pipe(
        map((resp) => {
          const blob = new Blob([resp], { type: resp.type });
          return window.URL.createObjectURL(blob);
        })
      );
  }

  private processOfflineSubmit(
    event: Partial<TripEvent>,
    vehicleId: string = '',
    trailerId?: string
  ): OperatorFunction<Trip, Trip> {
    return catchError((err) => {
      if (err instanceof ScheduledForRetryError) {
        if (err.request.url.endsWith(API)) {
          // trip POST request failed
          // create additional request for the event
          const tripEventRequest = this.requestsRepo.add({
            body: { ...event, eventTime: this.ngDay.dayjs.utc().toDate() },
            url: `${API}/${buildIdPromise(err.request.id)}/events`,
            method: 'POST',
            userId: err.request.userId,
            createdAt: new Date(),
            responseIdPath: 'tripEvents.0.id',
          });
          const vehiclePlaceholder = {
            id: '',
            name: '',
            numberplate: OFFLINE_ERROR,
            createdAt: err.request.createdAt,
            isBeingUsed: false,
          };
          return of<Trip>({
            id: buildIdPromise(err.request.id),
            vehicle: this.vehicles.getOne(vehicleId) || vehiclePlaceholder,
            trailer: trailerId
              ? this.trailers.getOne(trailerId) || vehiclePlaceholder
              : null,
            createdAt: err.request.createdAt,
            isApproved: err.request.body,
            tripEvents: [
              {
                ...tripEventRequest.body,
                id: buildIdPromise(tripEventRequest.id),
              },
            ],
          });
        }
        // trip was created, but event has failed
        // pull trip id from the failed request's URL
        const tripIdFromUrl = err.request.url.split('/').slice(-2)[0];
        const tripState = this.repo.getTrip(tripIdFromUrl);
        if (tripState) {
          this.requestsRepo.update(err.request.id, {
            responseIdPath: `tripEvents.${tripState.tripEvents.length}.id`,
          });
          tripState.tripEvents.push({
            ...err.request.body,
            id: buildIdPromise(err.request.id),
          });
          return of(tripState);
        }
      }
      throw err;
    });
  }
}
