<h2 class="mb-4">
  <ng-container *ngIf="action === 'start'" i18n="Start trip label"
    >Start trip</ng-container
  >
  <ng-container *ngIf="action === 'stop'" i18n="End trip label"
    >End trip</ng-container
  >
  <ng-container *ngIf="action === 'unload'" i18n="Unloading label"
    >Unloading</ng-container
  >
  <ng-container *ngIf="action === 'load'" i18n="Loading Label"
    >Loading</ng-container
  >
  <ng-container *ngIf="action === 'edit'" i18n="@@edit">Edit</ng-container>
  <ng-container *ngIf="action === 'takephoto'" i18n="@@takephoto"
    >Take Photo</ng-container
  >
</h2>

<h5 *ngIf="!isActionResolved || (isLoading$ | async)">
  <app-spinner [showWithText]="true"></app-spinner>
</h5>

<div *ngIf="isActionResolved && !(isLoading$ | async)" class="row mb-4">
  <div class="col-12">
    <div class="card mb-4" *ngIf="isActionResolved">
      <ul class="list-group list-group-horizontal bg-none">
        <li class="no-outer-border list-group-item flex-fill w-100">
          <div class="row">
            <div class="col-auto pr-0 align-self-center text-end">
              <h2 class="mb-3">
                <i class="bi bi-truck"></i>
              </h2>
            </div>
            <div
              class="col"
              *ngVar="
                activeTrip
                  ? activeTripVehicle
                  : (authRepo.settings$ | async)?.vehicleName as vehicle
              "
            >
              <p class="text-muted small mb-1" i18n="Vehicle label">Vehicle</p>
              <h6 *ngIf="vehicle">{{ vehicle }}</h6>
              <h6 *ngIf="!vehicle" i18n="Not selected message @@notSelected">
                Not selected
              </h6>
            </div>
          </div>
        </li>
        <li class="no-outer-border list-group-item flex-fill w-100">
          <div class="row">
            <div class="col-auto pr-0 align-self-center text-end">
              <h2 class="mb-3">
                <i class="bi bi-minecart"></i>
              </h2>
            </div>
            <div
              class="col"
              *ngVar="
                activeTrip
                  ? activeTripTrailer
                  : (authRepo.settings$ | async)?.trailerName as trailer
              "
            >
              <p class="text-muted small mb-1">Trailer</p>
              <h6 *ngIf="trailer">{{ trailer }}</h6>
              <h6 *ngIf="!trailer" i18n="@@notSelected">Not selected</h6>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <app-trip-info-form
      *ngIf="isActionResolved"
      [isAdmin]="isAdmin"
      [deletedCompanies]="deletedCompanies"
      [deletedAccessories]="deletedAccessories"
      [deletedProducts]="deletedProducts"
      [accessories]="accessoryRepo.all$ | async"
      [products]="productsRepo.all$ | async"
      [companies]="companiesRepo.all$ | async"
      [cleanings]="cleaningRepo.all$ | async"
      [isLoading]="
        (tripsRepo.isAdding$ | async) ||
        (activeTrip && (tripsRepo.isLoadingOne$(activeTrip.id) | async))
      "
      (tripSubmit)="createEvent($event)"
      [initialEvent]="initialEvent$ | async"
      [pickUpAddress]="pickUpAddress"
      [deliveryAddress]="deliveryAddress"
      [features]="(authRepo.features$ | async) || []"
      [action]="action"
    ></app-trip-info-form>

    <app-error-alert
      title="Could not complete the action"
      i18n-title="Could not complete action message"
      [errors]="submitErrors"
    ></app-error-alert>
  </div>
</div>

<app-confirm-refresh-redirect
  [errorMessage]="doubleActionError"
  (confirm)="confirmRedirect()"
></app-confirm-refresh-redirect>
