import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AuthRepository } from 'src/app/state/auth.repository';
import { ProductsRepository, productsSortOptions } from 'src/app/state/products.repository';
import { ProductsService } from 'src/app/state/products.service';

@Component({
  selector: 'app-product-page',
  templateUrl: './product-page.component.html',
  styleUrls: ['./product-page.component.scss']
})
export class ProductPageComponent implements OnInit {
  search = '';
  sortOptions = productsSortOptions;
  exportFactory = () => this.service.export();
  importFactory = (file: File) => this.service.import(file);

  constructor(
    public service: ProductsService,
    public repo: ProductsRepository,
    public auth: AuthRepository    
  ) { }

  ngOnInit(): void {
    this.service.reloadPage().subscribe();
  }

  loadBySearch($event: string) {    
    this.service.reloadPage(10, $event).subscribe(); 
  }

}
