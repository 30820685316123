import { HttpClient } from '@angular/common/http';
import { EProductsRepository } from './eproducts.repository';
import {
  BaseService,
  DEFAULT_ENTITIES_PER_PAGE,
} from './abstract/base.service';
import { Injectable } from '@angular/core';
import { Product, ProductsRepository } from './products.repository';
import { PaginationData } from '@ngneat/elf-pagination';
import { Observable, tap } from 'rxjs';
import { SortState, sortBy } from '../modules/shared/pipes/sort.pipe';

const API = '/api/products';

@Injectable({
  providedIn: 'root',
})
export class EProductsService extends BaseService<Product> {
  constructor(
    http: HttpClient,
    repo: EProductsRepository,
    private prodRepo: ProductsRepository
  ) {
    super(API, http, repo);
  }

  reloadPage(
    defaultTake: number = DEFAULT_ENTITIES_PER_PAGE,
    search = ''
  ): Observable<(PaginationData & { data: Product[] }) | null> {
    const data = this.repo.getPaginationData();
    if (data && Object.keys(data.pages).length) {
      this.repo.clearPages();
      return this.loadPage(data.currentPage, data.perPage, search);
    }
    return this.loadPage(1, defaultTake, search);
  }

  loadPage(
    page: number,
    take: number = DEFAULT_ENTITIES_PER_PAGE,
    search = ''
  ): Observable<PaginationData & { data: Product[] }> {
    const sortOrder = this.repo.getSort();
    const query = [
      `page=${page}`,
      `take=${take}`,
      `search=${search}`,
      `sort=${sortOrder.parameter.property}`,
      `direction=${sortOrder.direction}`,
    ];
    this.repo.setPage(page);
    return this.http
      .get<PaginationData & { data: Product[] }>(
        `${API + '/eproducts'}?${query.join('&')}`
      )
      .pipe(
        tap((res) => {
          if (
            sortOrder.parameter.property === 'priceTypeName' &&
            sortOrder.direction === 'asc'
          ) {
            res.data = sortBy(res.data, {
              parameter: { property: 'priceTypeName' },
              direction: 'asc',
            });
            this.repo.addPage(res);
          }
          if (
            sortOrder.parameter.property === 'priceTypeName' &&
            sortOrder.direction === 'desc'
          ) {
            res.data = sortBy(res.data, {
              parameter: { property: 'priceTypeName' },
              direction: 'desc',
            });
            this.repo.addPage(res);
          } else {
            this.repo.addPage(res);
          }
        }),

        this.repo.track(),
        this.repo.skipWhilePageCached(page)
      );
  }

  sort(sortBy: SortState) {
    this.repo.setSort(sortBy);
    return this.reloadPage();
  }

  loadAll() {
    return this.http.get<Product[]>(`${API}/eproducts/all`);
  }

  load(): Observable<PaginationData & { data: Product[] }> {
    return this.http
      .get<PaginationData & { data: Product[] }>(API + '/eproducts')
      .pipe(
        tap((res) => this.repo.set(res.data)),
        tap((res) => {
          this.prodRepo.clear();
          this.prodRepo.set(res.data);
        }),
        this.repo.track()
        //this.repo.skipWhileCached()
      );
  }
  deleteUsedProducts(id: string) {
    return this.http.get<Product>(`${API}/deleteUsedProduct/${id}`);
  }
}
