<router-outlet></router-outlet>
<app-offline-label></app-offline-label>
<app-pwa-install-button></app-pwa-install-button>
<!-- <app-current-trip></app-current-trip> -->
<app-unsynced-changes
  *ngIf="features.offlineChanges"
  [titleResolver]="resolveActionTitle"
></app-unsynced-changes>
<app-session-over-state
  *ngIf="isSessionRefreshModalDisplayed$ | async"
></app-session-over-state>
