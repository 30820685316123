import {
  Component,
  DoCheck,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {
  catchError,
  combineLatest,
  map,
  Observable,
  of,
  Subscription,
  switchMap,
} from 'rxjs';
import { DayjsService } from 'src/app/modules/shared/services/dayjs.service';
import { LocationService } from 'src/app/modules/shared/services/location.service';
import { RequestsResolverRunner } from 'src/app/modules/shared/services/requests-resolver';
import { AccessoriesRepository } from 'src/app/state/accessories.repository';
import { AuthRepository } from 'src/app/state/auth.repository';
import { CleaningsRepository } from 'src/app/state/cleaning.repository';
import { CompaniesRepository } from 'src/app/state/companies.repository';
import {
  Order,
  OrderFamily,
  OrdersRepository,
} from 'src/app/state/orders.repository';
import { OrdersService } from 'src/app/state/orders.service';
import { ProductsRepository } from 'src/app/state/products.repository';
import { TripEventsRepository } from 'src/app/state/tripevents.repository';
import { TripEvent } from 'src/app/state/trips.repository';
import { WorkdaysRepository } from 'src/app/state/workdays.repository';
import { WorkdaysService } from 'src/app/state/workdays.service';
import { ConnectionService } from '../../../shared/services/connection.service';
import { sortBy } from 'src/app/modules/shared/pipes/sort.pipe';
import { AccessoriesService } from 'src/app/state/accessory.service';
import { ProductsService } from 'src/app/state/products.service';
import { CompaniesService } from 'src/app/state/companies.service';
import { OrderInfoFormComponent } from '../../components/order-info-form/order-info-form.component';
import { FormGroup } from '@angular/forms';
import { CleaningsService } from 'src/app/state/cleaning.service';

const LOCATION_PERMISSION_ERROR = $localize`:Location permission error:Please allow access to the location service in your browser`;
const VEHICLE_ERROR = $localize`:Trip event page vehicle settings error:Vehicle is not selected`;
const TRIP_ERROR = $localize`:Trip event page error while loading current trip:Could not load current trip`;
const ORDER_STARTED_ERROR = $localize`:Order event page error because order is started:Trip has been already started`;
const ORDER_ENDED_ERROR = $localize`:Order event page error because order is ended:Trip has been already ended`;
const ORDER_REJECT_ERROR = $localize`:Order event page error because order is rejected:Trip has been already rejected`;
const ANOTHER_DRIVER_ERROR = $localize`:Order event page error because order is started by another:Trip has already started by another driver`;

@Component({
  selector: 'app-order-event-page',
  templateUrl: './order-event-page.component.html',
  styleUrls: ['./order-event-page.component.scss'],
})
export class OrderEventPageComponent implements OnInit, DoCheck, OnDestroy {
  ping = true;
  orderFamily: OrderFamily | null = null;
  currentChildren = new Array<string>();
  userSub: any;
  userId: string | null = null;
  doubleActionError: string = '';
  ordersSub: any;
  dataSubscription?: Subscription;
  submitErrors: string[] | null = null;
  id?: string | null = null;
  order: Order | null = null;
  orderSub?: Subscription;
  eventid: string | undefined;
  isAdmin: 'true' | 'false' = 'false';
  order$?: Observable<Order | undefined> | null;
  orderId?: string;
  initialEvent$?: Observable<TripEvent | undefined>;
  action: 'start' | 'stop' | 'unload' | 'load' | 'takephoto' | null = null;
  activeOrder: Order | null = null;
  activeOrderVehicle: string | null = null;
  activeOrderTrailer: string | null = null;
  @ViewChildren(OrderInfoFormComponent)
  orderForm: QueryList<OrderInfoFormComponent> | null = null;
  isLoading$ = combineLatest([
    this.authRepo.isSettingsLoading$,
    this.accessoryRepo.isLoading$,
    this.cleaningRepo.isLoading$,
    this.companiesRepo.isLoading$,
    this.productsRepo.isLoading$,
  ]).pipe(map(([au, ac, cl, co, pr]) => au || ac || cl || co || pr));
  isActionResolved = false;

  delivery: any;
  picUp: any;
  requestInProgress: boolean = false;

  constructor(
    public isOnlineService: ConnectionService,
    public authRepo: AuthRepository,
    public companiesRepo: CompaniesRepository,
    public companiesService: CompaniesService,
    public productsRepo: ProductsRepository,
    public productService: ProductsService,
    private cleaningService: CleaningsService,
    public cleaningRepo: CleaningsRepository,
    public accessoryRepo: AccessoriesRepository,
    public accessoryService: AccessoriesService,
    private workdaysRepo: WorkdaysRepository,
    private router: Router,
    private route: ActivatedRoute,
    private ordersService: OrdersService,
    public ordersRepo: OrdersRepository,
    public ngDay: DayjsService,
    private tripEventRepo: TripEventsRepository,
    private locationService: LocationService,
    private offlineResolver: RequestsResolverRunner
  ) {
    this.route.paramMap.subscribe((x) => (this.id = x.get('id')));
    this.userSub = this.authRepo.id$.subscribe((a) => (this.userId = a));
    if (this.id) {
      this.orderSub = this.ordersRepo.one(this.id).subscribe((a) => {
        if (a) {
          this.order = a;
        }
      });
      this.ordersSub = ordersRepo.ordersByUser$(this.userId!).subscribe((a) => {
        this.orderFamily = this.groupRelatedOrders(a, this.id);
      });
    }
    this.subProduct1 = this.productService
      .loadDeletedAsListwithRepo()
      .subscribe();

    this.subProduct2 = this.productsRepo.allDeleted$.subscribe(
      (x) => (this.deletedProducts = x)
    );

    this.subAcc1 = this.accessoryService.loadDeleted().subscribe();

    this.subAcc2 = this.accessoryRepo.allDeleted$.subscribe(
      (x) => (this.deletedAccessories = x)
    );

    this.subCleanings1 = this.cleaningService.loadDeleted().subscribe();
    this.subCleanings2 = this.cleaningRepo.allDeleted$.subscribe(
      (x) => (this.deletedCleanings = x)
    );

    this.subCompanies1 = this.companiesService
      .loadDeletedAsListwithRepo()
      .subscribe();
    this.subCompanies2 = this.companiesRepo.allDeleted$.subscribe(
      (x) => (this.deletedCompanies = x)
    );
  }
  deletedCompanies: any;
  deletedAccessories: any;
  deletedProducts: any;
  deletedCleanings: any;
  subCleanings1: any;
  subCleanings2: any;
  subCompanies1: any;
  subCompanies2: any;
  subProduct1: any;
  subAcc1: any;
  subProduct2: any;
  subAcc2: any;

  ngOnDestroy(): void {
    this.subAcc1?.unsubscribe();
    this.subAcc2?.unsubscribe();
    this.subProduct1?.unsubscribe();
    this.subProduct2?.unsubscribe();
    this.subCompanies1?.unsubscribe();
    this.subCompanies2?.unsubscribe();
    this.dataSubscription?.unsubscribe();
    this.orderSub?.unsubscribe();
    this.userSub.unsubscribe();
    this.subCleanings1.unsubscribe();
    this.subCleanings2.unsubscribe();
  }
  updateValue(form: FormGroup, control: string, value: any) {
    const controlObject = form?.get(control);
    controlObject?.setValue(value);
  }

  rebuildForm(order: Order) {
    if (this.orderForm) {
      this.orderForm?.map((c) => {
        if (c.form) {
          const dayObjStart = this.ngDay.dayjs
            .utc(order.plannedToStartAt)
            .local();
          const dayObjEnd = this.ngDay.dayjs.utc(order.plannedToEndAt).local();
          let weight = this.order?.weight?.toString();
          if (weight?.includes('.')) {
            weight = weight.replace(/\./g, ',');
          }
          this.updateValue(c.form, 'reference', order.reference);
          this.updateValue(c.form, 'weight', weight);
          this.updateValue(c.form, 'km', 0);
          this.updateValue(c.form, 'deliveryAddress', order.deliveryAddress);
          this.updateValue(c.form, 'pickUpAddress', order.pickUpAddress);
          this.updateValue(c.form, 'clientId', order.clientId || '');
          this.updateValue(c.form, 'vendorId', order.vendorId);
          this.updateValue(c.form, 'accessoryId', order.accessoryId);
          this.updateValue(c.form, 'contactPerson', order.contactPerson);
          this.updateValue(c.form, 'cleaningId', order.cleaningId);
          this.updateValue(c.form, 'note', order.note);
          this.updateValue(
            c.form,
            'productIds',
            order.products?.map((x) => x.id)
          );
        }
      });
    }
  }

  ngDoCheck() {
    this.route.paramMap.subscribe((x) => {
      let checkId = x.get('id');
      if (this.orderFamily && this.orderFamily.children) {
        let checkOrder = this.orderFamily.children.find(
          (x) => x.id === checkId
        );
        if (checkOrder && this.order && this.order !== checkOrder) {
          this.order = checkOrder;
          this.rebuildForm(this.order);
        }
      }
    });
  }

  ngOnInit(): void {
    if (this.isOnlineService.isOnline()) {
      this.ordersService.loadForCurrentDriver().subscribe();
    }

    this.dataSubscription = this.route.paramMap
      .pipe(
        map((params) => [params.get('action')]),
        switchMap(([action]) => {
          if (
            action === 'start' ||
            action === 'stop' ||
            action === 'unload' ||
            action === 'load' ||
            action === 'takephoto'
          ) {
            this.action = action;
          } else {
            this.router.navigate(['/not-found'], { skipLocationChange: true });
          }
          return of(null);
        }),
        this.offlineResolver.useUnsyncedChanges()
      )
      .subscribe(() => {
        const day = this.workdaysRepo.getActiveWorkday();
        const order = this.ordersRepo.getActiveOrder();
        this.mergeNamePlate(order);
        if (!day && order && this.action === 'start') {
          this.router.navigate(['/go']);
        } else {
          this.mergeNamePlate(order);
          this.isActionResolved = true;
        }
      });
    setTimeout(() => {
      this.ping = false;
    }, 1000);
  }

  confirmRedirect() {
    localStorage.setItem('go_reload', 'true');
    this.router.navigate(['/go']);
  }

  groupRelatedOrders(orders: Order[], id?: string | null): OrderFamily {
    let family = {} as OrderFamily;
    if (id) {
      family.parentId = id;
      let list = new Array<Order>();
      if (orders && orders.length > 0) {
        let children = this.findChild(id, orders, list);
        family.children = children;
      }
    }
    return family;
  }

  findChild(id: string, orders: Order[], list: Order[]) {
    let child = orders.find((x) => x.parentId === id);
    if (orders.length !== 0) {
      if (child) {
        let index = orders.indexOf(child);
        list.push(child);
        id = child.id;
        orders.splice(index, 1);
        this.findChild(id, orders, list);
      } else {
        return list;
      }
    }
    return list;
  }

  mergeNamePlate(order?: Order | null) {
    if (!order) {
      return;
    }
    this.activeOrder = order;
    this.activeOrderVehicle =
      order && order.vehicle
        ? `[${order.vehicle.numberplate}] ${order.vehicle.name}`
        : null;
    this.activeOrderTrailer =
      order && order.trailer
        ? `[${order.trailer.numberplate}] ${order.trailer.name}`
        : null;
  }

  toCorrectDate(date: Date, type?: string) {
    if (type === 'day') {
      return this.ngDay.dayjs
        .utc(date)
        .local()
        .format('DD/MM/YYYY')
        .slice(0, 16);
    }
    if (type === 'time') {
      return this.ngDay.dayjs.utc(date).local().format('H:mm').slice(0, 16);
    } else {
      return this.ngDay.dayjs
        .utc(date)
        .local()
        .format('H:mm DD/MM/YYYY')
        .slice(0, 16);
    }
  }

  rejectOrder($event: string) {
    localStorage.removeItem('currentChildren');
    if (
      this.orderFamily &&
      this.orderFamily.children &&
      this.orderFamily.children.length > 0
    ) {
      this.rejectMultiple(this.orderFamily.parentId);
      this.orderFamily.children.forEach((a) => {
        this.rejectMultiple(a.id);
      });

      if (!this.ordersRepo.getActiveOrder()) {
        this.router.navigate(['go']);
      } else {
        this.ordersRepo.remove($event);
        this.router.navigate(['go']);
      }
    } else {
      this.rejectSingleOrder($event);
    }
  }

  rejectSingleOrder($event: string) {
    this.ordersService
      .updateRejected($event, this.userId)
      .pipe(
        catchError(async (err) => {
          this.ordersRepo.remove($event);
        })
      )
      .subscribe({
        complete: () => {
          if (!this.ordersRepo.getActiveOrder()) {
            this.router.navigate(['go']);
          } else {
            this.ordersRepo.remove($event);
            this.router.navigate(['go']);
          }
        },
        error: (data) => {
          if (data.error === 'Trip has been already rejected') {
            this.doubleActionError = ORDER_REJECT_ERROR;
          }
        },
      });
  }

  rejectMultiple($event: string) {
    localStorage.removeItem('currentChildren');
    this.ordersService
      .updateRejected($event, this.userId)
      .pipe(
        catchError(async (err) => {
          this.ordersRepo.remove($event);
        })
      )
      .subscribe({
        error: (data) => {
          if (data.error === 'Trip has been already rejected') {
            this.doubleActionError = ORDER_REJECT_ERROR;
          }
        },
      });
  }

  updateOrderAddress(event: Partial<Order>) {
    if (this.id && (event.pickUpAddress || event.deliveryAddress)) {
      if (!this.isOnlineService.isOnline()) {
        this.delivery = event.deliveryAddress;
        this.picUp = event.pickUpAddress;
      } else {
        this.ordersService.updateOrderAddress(this.id, event).subscribe();
        this.delivery = event.deliveryAddress;
        this.picUp = event.pickUpAddress;
      }
    }
  }

  async createEvent(event: Partial<TripEvent>) {
    this.requestInProgress = true;
    this.submitErrors = null;
    let submitObservable: Observable<any> | null = null;
    const settings = this.authRepo.getActiveSettings();
    if (!settings || !settings.vehicleId) {
      this.requestInProgress = false;
      this.submitErrors = [VEHICLE_ERROR];
      return;
    }
    if (this.activeOrder) {
      this.activeOrder.vehicleId = settings.vehicleId;
      this.activeOrder.trailerId = settings.trailerId;
    }
    try {
      const coordinates = await this.locationService.getPosition();
      event = {
        ...event,
        ...coordinates,
      };
    } catch {
      this.submitErrors = [LOCATION_PERMISSION_ERROR];
      this.requestInProgress = false;
      return;
    }
    switch (this.action) {
      case 'start':
        const order = this.ordersRepo.getOrder(this.id!);
        if (settings && settings.vehicleId && order) {
          order.vehicleId = settings.vehicleId;
          order.trailerId = settings.trailerId;
          order.productIds = order.products?.map((x) => x.id);
          order.type = 'Start';
          order.curTime = this.ngDay.dayjs.utc().toDate();

          if (this.delivery) {
            order.deliveryAddress = this.delivery;
          }
          if (this.picUp) {
            order.pickUpAddress = this.picUp;
          }
          //let innit = order;
          order.driverIds = [];
          order.userId = this.userId;
          // if (!this.isOnlineService.isOnline()) {
          //this.ordersRepo.upsertOrder(order);
          //this.ordersRepo.setActiveId(order.id);
          // }
          this.ordersRepo.upsertOrder(order);

          submitObservable = this.ordersService.start(
            order,
            event,
            event?.tripFiles
          );
        } else {
          this.doubleActionError = ANOTHER_DRIVER_ERROR;
        }

        break;
      case 'stop':
        // if (!this.activeOrder) {
        //   this.submitErrors = [TRIP_ERROR];
        //   return;
        // }
        const orderStop = this.activeOrder;
        if (settings && settings.vehicleId && orderStop) {
          orderStop.vehicleId = settings.vehicleId;
          orderStop.trailerId = settings.trailerId;

          if (this.delivery) {
            orderStop.deliveryAddress = this.delivery;
          }
          if (this.picUp) {
            orderStop.pickUpAddress = this.picUp;
          }
          if (
            !this.orderFamily ||
            !this.orderFamily.children ||
            this.orderFamily.children.length === 0
          ) {
            localStorage.removeItem('currentChildren');
          }
          if (
            this.orderFamily &&
            this.orderFamily.children &&
            this.orderFamily.children.length > 0
          ) {
            let sortedChildren = sortBy(this.orderFamily.children, {
              parameter: { property: 'plannedToStartAt' },
              direction: 'asc',
            });
            localStorage.setItem(
              'currentChildren',
              sortedChildren.map((x) => x.id).toString()
            );
            this.currentChildren = sortedChildren.map((x) => x.id);
          }
          submitObservable = this.ordersService.stop(
            orderStop,
            event,
            event?.tripFiles
          );
        }
        break;
      case 'unload':
        if (!this.activeOrder) {
          this.submitErrors = [TRIP_ERROR];
          this.requestInProgress = false;
          return;
        }
        const orderUnload = this.activeOrder;
        if (settings && settings.vehicleId && orderUnload) {
          orderUnload.vehicleId = settings.vehicleId;
          orderUnload.trailerId = settings.trailerId;

          if (this.delivery) {
            orderUnload.deliveryAddress = this.delivery;
          }
          if (this.picUp) {
            orderUnload.pickUpAddress = this.picUp;
          }
          submitObservable = this.ordersService.orderAction(
            orderUnload,
            event,
            'Unload',
            event.tripFiles
          );
        }
        break;
      case 'load':
        if (!this.activeOrder) {
          this.submitErrors = [TRIP_ERROR];
          this.requestInProgress = false;
          return;
        }
        const orderLoad = this.activeOrder;
        if (settings && settings.vehicleId && orderLoad) {
          orderLoad.vehicleId = settings.vehicleId;
          orderLoad.trailerId = settings.trailerId;

          if (this.delivery) {
            orderLoad.deliveryAddress = this.delivery;
          }
          if (this.picUp) {
            orderLoad.pickUpAddress = this.picUp;
          }
          submitObservable = this.ordersService.orderAction(
            orderLoad,
            event,
            'Load',
            event.tripFiles
          );
        }
        break;
      case 'takephoto':
        this.id =
          this.activeOrder?.tripEvents.find((x) => x.type === 'TripStart')
            ?.id || undefined;
        if (this.activeOrder?.id) {
          submitObservable = this.ordersService.takephoto(
            this.id || '',
            event.tripFiles
          );
        }
        break;
      default:
        this.requestInProgress = false;
        return;
    }
    if (submitObservable) {
      submitObservable.subscribe({
        next: (value) => {
          this.requestInProgress = false;
          if (value.id) {
            this.ordersRepo.setActiveId(value.id);
          }
        },
        complete: () => {
          this.requestInProgress = false;
          if (this.action === 'stop' && this.orderFamily && this.id) {
            this.ping = true;
            setTimeout(() => {
              this.ping = false;
            }, 1000);
            if (this.orderFamily.parentId === this.id) {
              if (
                this.orderFamily.children &&
                this.orderFamily.children.length > 0
              ) {
                let next = this.orderFamily.children[0];
                this.router.navigate([`order/${next.id}/start`]);
              } else if (
                !this.currentChildren ||
                this.currentChildren.length === 0
              ) {
                this.router.navigate(['/go']);
              }
            } else if (
              this.orderFamily.children.find((x) => x.id === this.id)
            ) {
              let index = this.orderFamily.children.findIndex(
                (x) => x.id === this.id
              );
              let currentChildrenStored =
                localStorage.getItem('currentChildren');
              if (currentChildrenStored) {
                this.currentChildren = currentChildrenStored.split(',');
                let finished = this.currentChildren.find((x) => x === this.id);
                if (finished) {
                  this.currentChildren.shift();
                  localStorage.setItem(
                    'currentChildren',
                    this.currentChildren.toString()
                  );
                }
              }
              if (
                this.orderFamily.children &&
                this.orderFamily.children.length > index
              ) {
                let next = this.orderFamily.children[index + 1];
                this.router.navigate([`order/${next.id}/start`]);
              } else {
                this.router.navigate(['/go']);
              }
            }
          } else if (this.action === 'stop' && !this.orderFamily && this.id) {
            this.router.navigate(['/go']);
          } else {
            this.router.navigate(['/go']);
          }
        },
        error: (data) => {
          this.requestInProgress = false;
          this.submitErrors = data;
          if (data.error === 'Trip has already started by another driver') {
            this.doubleActionError = ANOTHER_DRIVER_ERROR;
            this.submitErrors = [ANOTHER_DRIVER_ERROR];
          }
          if (data.error === 'Trip has already started') {
            this.doubleActionError = ORDER_STARTED_ERROR;
            this.submitErrors = [ORDER_STARTED_ERROR];
          }
          if (data.error === 'Trip has already ended') {
            this.doubleActionError = ORDER_ENDED_ERROR;
            this.submitErrors = [ORDER_ENDED_ERROR];
          }
          if (data.error === 'Could not load current trip') {
            this.doubleActionError = TRIP_ERROR;
            this.submitErrors = [TRIP_ERROR];
          }
        },
      });
    } else {
      this.requestInProgress = false;
    }
  }
}
