import { XhrFactory } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { filter, from, map, Observable, of, tap } from 'rxjs';
import { AuthRepository, UserRoles } from 'src/app/state/auth.repository';
import { Column } from 'src/app/state/columns.repository';
import { ColumnsService } from 'src/app/state/columns.service';

@Component({
  selector: 'app-columns-page',
  templateUrl: './columns-page.component.html',
  styleUrls: ['./columns-page.component.scss']
})
export class ColumnsPageComponent implements OnInit {
  columns: Column[] | null = null;
  resetable?: boolean;
  
  readonly DEFAULT_BACKGROUND = 'assets/img/background_default.webp';

  constructor(private service: ColumnsService,
              private router: Router,
              private authRepo: AuthRepository,) { }

  ngOnInit(): void {
    this.service.loadColumns().pipe(
      tap(x => this.columns = x),
      (tap(x => this.resetable = x.every(x => x.tenantId !== null)))
    ).subscribe();
  }

  updateColumns() {
    if(this.columns) {
      this.service.updateColumns(this.columns).pipe(
        tap(x => this.columns = x),
      (tap(x => this.resetable = x.every(x => x.tenantId !== null)))
    ).subscribe(() => {
      if (this.authRepo.isInRole(UserRoles.Driver)) {
        this.router.navigate(['/trips']);
      } else {       
          this.router.navigate(['drivers/trips']);
       
      }
    });
    }     
  }

  resetOrder() {
    if(this.columns) {
      this.service.deleteOrder().subscribe(() => {
          this.service.loadColumns().pipe(
            tap(x => this.columns = x),
            (tap(x => this.resetable = x.every(x => x.tenantId !== null)))
          ).subscribe();
        }
      );
      
    }
  }

}
