import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { UiRepository } from 'src/app/state/ui.repository';
import { Router } from '@angular/router';
import { EnvState } from '../../shared/helpers/env-state';

// List of UI routes and API endpoints
// that must not cause "Session is over" popup
const DO_NOT_INTERCEPT = [ 
  '/signin',
  '/forgotpassword',
  '/api/auth',
  '/api/auth/password'
];

@Injectable({ providedIn: 'root' })
export class SessionInterceptor implements HttpInterceptor {
  constructor(
    private repo: UiRepository, 
    private router: Router,
    private env: EnvState
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse && err.status === 401
          && !this.isExceptionUrl(err.url)
          && !this.isExceptionUrl(this.router.url)) {
          this.repo.setSessionOver();
        }
        // return the error back to the caller
        throw err;
      })
    );
  }

  isExceptionUrl(url: string | null): boolean {
    if (!url) {
      return false;
    }
    var parsed = new URL(url, location.origin);
    let path = parsed.pathname;
    if (path.startsWith(this.env.apiUrl)) {
      path = path.substring(this.env.apiUrl.length);
    }
    return DO_NOT_INTERCEPT.indexOf(path) >= 0;
  }
}
