import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filterNil } from '@ngneat/elf';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, skipWhile, switchMap, tap } from 'rxjs/operators';
import { UrlIdResolverService } from 'src/app/modules/shared/services/url-id-resolver.service';
import { Cleaning, CleaningsRepository } from 'src/app/state/cleaning.repository';
import { CleaningsService } from 'src/app/state/cleaning.service';

@Component({
  selector: 'app-cleaning-create-page',
  templateUrl: './cleaning-create-page.component.html',
  styleUrls: ['./cleaning-create-page.component.scss']
})
export class CleaningCreatePageComponent implements OnInit {
  cleaning$: Observable<Cleaning> | null = null;
  id: string | undefined;
  submitErrors: string[] | null = null;

  constructor(
    private cleaningService: CleaningsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private repo: CleaningsRepository,
    private urlId: UrlIdResolverService
  ) { }

    ngOnInit(): void {
      this.cleaning$ = this.urlId.resolve(
        this.activatedRoute.paramMap,
        id => this.cleaningService.loadOne(id),
        id => this.repo.one(id),
        id => this.repo.statusOne(id),
        id => this.id = id
      );
    }

    createCleaning(cleaning: Partial<Cleaning>) {
      this.submitErrors = null;
      let updateResult: Observable<Cleaning> | undefined;
      if(this.id === 'new'){
        updateResult = this.cleaningService.add(cleaning);
        
      } else if(this.id){
        updateResult = this.cleaningService.update(this.id , cleaning);
      }
      if (updateResult) {
        updateResult.subscribe({
          complete: () => {
            this.router.navigate(['/cleanings']);
          },
          error: data => this.submitErrors = data
        });
      } 
    }
}
