import { Injectable } from '@angular/core';
import { RequestsResolver } from '../../shared/services/requests-resolver';
import { buildIdPromise, Request } from 'src/app/state/requests.repository';
import { WorkdaysRepository } from 'src/app/state/workdays.repository';

const DAY_API = "/api/workdays";
const EVENT_API = '/api/workdayevents';

@Injectable({
  providedIn: 'root'
})
export class DayRequestsResolverService implements RequestsResolver {

  constructor(
    private workdays: WorkdaysRepository
  ) { }

  canResolve(request: Request): boolean {
    return request.url.startsWith(DAY_API)
      || request.url.startsWith(EVENT_API);
  }

  modifyLocalState(request: Request): void {
    if (request.url.startsWith(EVENT_API)) {
      // event edit request
      const eventIdFromUrl = request.url.split('/').pop();
      const dayState = this.workdays.getWorkdayByEvent(eventIdFromUrl || '');
      if (dayState) {
        const eventIndex = dayState.workdayEvents
          .findIndex(x => x.id === eventIdFromUrl);
          if (eventIndex >= 0) {
            dayState.workdayEvents[eventIndex] = {
              ...dayState.workdayEvents[eventIndex],
              ...request.body
            };
            this.workdays.upsertWorkday(dayState);
          }
      }
    } else if (request.url.endsWith(DAY_API)) {
      // there's a workday POST request enqueued
      const day = {
        id: buildIdPromise(request.id),
        createdAt: request.createdAt,
        workdayEvents: [ ]
      };
      this.workdays.upsertWorkday(day);
      this.workdays.setActiveId(day.id);
    } else {
      // workday event
      const dayIdFromUrl = request.url.split('/').slice(-2)[0];
      const dayState = this.workdays.getWorkday(dayIdFromUrl);
      if (dayState) {
        dayState.workdayEvents.push({ 
          ...request.body, 
          id: buildIdPromise(request.id)
        });
        this.workdays.upsertWorkday(dayState);
        if (request.body.type === 'DayEnd') {
          this.workdays.setActiveId(null);
        }
      }
    }
  }
}
