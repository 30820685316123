import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { UserSettings } from 'src/app/state/auth.repository';
import { Trailer } from 'src/app/state/trailers.repository';
import { Vehicle } from 'src/app/state/vehicles.repository';

@Component({
  selector: 'app-trip-settings',
  templateUrl: './trip-settings.component.html',
  styleUrls: ['./trip-settings.component.scss']
})
export class TripSettingsComponent implements OnInit {
  @Input() settings: UserSettings | null = null;
  @Input() set vehicles(value: Vehicle[] | null) {
    if (!value) {
      this.vehicleOptions = null;
    } else {
      this.vehicleOptions = value.map(x => ({
        value: x.id,
        label: x.numberplate,
        sublabel: x.name
      }));
    }
  }
  @Input() set trailers(value: Trailer[] | null) {
    if (!value) {
      this.trailerOptions = null;
    } else {
      this.trailerOptions = value.map(x => ({
        value: x.id,
        label: x.numberplate,
        sublabel: x.name
      }));
    }
  }
  @Output() settingsSubmit = new EventEmitter<UserSettings>();

  form?: UntypedFormGroup;
  vehicleOptions: DatalistOption[] | null = null;
  trailerOptions: DatalistOption[] | null = null;

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      vehicleId: [this.settings?.vehicleId || null],
      trailerId: [this.settings?.trailerId || null],
      defaultPause: [this.settings?.defaultPause || 0, [Validators.required, Validators.min(0)]],
    });
  }

  onSubmit() {
    if (this.form && !this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.settingsSubmit.emit({
      vehicleId: this.form?.value.vehicleId,
      trailerId: this.form?.value.trailerId,
      defaultPause: this.form?.value.defaultPause || 0
    });
    this.form?.markAsUntouched();
  }
}
