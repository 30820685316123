import { Injectable } from '@angular/core';
import { AuthRepository, UserSettings } from 'src/app/state/auth.repository';
import { Request } from 'src/app/state/requests.repository';
import { TrailersRepository } from 'src/app/state/trailers.repository';
import { VehiclesRepository } from 'src/app/state/vehicles.repository';
import { RequestsResolver } from '../../shared/services/requests-resolver';

const API = '/api/auth/settings';
const NAME_LOADING_ERROR = $localize `:Could not load vehicle/trailer info while offline:Name unavailable offline`;

@Injectable({
  providedIn: 'root'
})
export class SettingsRequestsResolverService implements RequestsResolver {

  constructor(
    private vehicles: VehiclesRepository,
    private trailers: TrailersRepository,
    private auth: AuthRepository
  ) { }

  canResolve(request: Request): boolean {
    return request.url === API;
  }

  modifyLocalState(request: Request): void {
    const settings = this.buildSettings(request);
    this.auth.setSettings(settings);
  }

  buildSettings(request: Request): UserSettings {
    const settings = request.body;
    if (settings.vehicleId) {
      const vehicle = this.vehicles.getOne(settings.vehicleId);
      settings.vehicleName = vehicle
        ? `[${vehicle.numberplate}] ${vehicle.name}`
        : `[${NAME_LOADING_ERROR}]`;
    }
    if (settings.trailerId) {
      const trailer = this.trailers.getOne(settings.trailerId);
      settings.trailerName = trailer
        ? `[${trailer.numberplate}] ${trailer.name}`
        : `[${NAME_LOADING_ERROR}]`;
    }
    return settings;
  }
}
