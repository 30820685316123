import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UrlIdResolverService } from 'src/app/modules/shared/services/url-id-resolver.service';
import { AccessoriesRepository, Accessory } from 'src/app/state/accessories.repository';
import { AccessoriesService } from 'src/app/state/accessory.service';

@Component({
  selector: 'app-accessory-create-page',
  templateUrl: './accessory-create-page.component.html',
  styleUrls: ['./accessory-create-page.component.scss']
})
export class AccessoryCreatePageComponent implements OnInit {
  accessory$: Observable<Accessory> | null = null;
  id: string | undefined;
  submitErrors: string[] | null = null;

  constructor(
    private accessoryService: AccessoriesService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private repo: AccessoriesRepository,
    private urlId: UrlIdResolverService
  ) { }

    ngOnInit(): void {
      this.accessory$ = this.urlId.resolve(
        this.activatedRoute.paramMap,
        id => this.accessoryService.loadOne(id),
        id => this.repo.one(id),
        id => this.repo.statusOne(id),
        id => this.id = id
      );
    }

    createAccessory(accessory: Partial<Accessory>) {
      this.submitErrors = null;
      let updateResult: Observable<Accessory> | undefined;
      if(this.id === 'new'){
        updateResult = this.accessoryService.add(accessory);

      } else if(this.id){
        updateResult = this.accessoryService.update(this.id , accessory);
      }
      if (updateResult) {
        updateResult.subscribe({
          complete: () => {
            this.router.navigate(['/accessories']);
          },
          error: data => this.submitErrors = data
        });
      }
    }
}
