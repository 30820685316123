import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationData } from '@ngneat/elf-pagination';
import { map, Observable, tap } from 'rxjs';
import {
  BaseService,
  DEFAULT_ENTITIES_PER_PAGE,
} from './abstract/base.service';
import { TripEventsRepository } from './tripevents.repository';
import {
  ClientEventDto,
  EconomicDto,
  EconomicInfo,
  EconomicInfoExtended,
  TenantNoteEvent,
  TripEvent,
  TripEventDto,
} from './trips.repository';
import { TripEventLogDto } from './models/trip/TripEventLogDto';

const API = '/api/tripevents';

@Injectable({
  providedIn: 'root',
})
export class TripEventsService extends BaseService<TripEvent> {
  constructor(http: HttpClient, repo: TripEventsRepository) {
    super(API, http, repo);
  }

  loadLoadUnloadTripEvents(id: string) {
    return this.http.get<TripEventLogDto[]>(
      `${API}/loadUnloadTripEvents/${id}`
    );
  }

  loadPage(
    page: number,
    take: number = DEFAULT_ENTITIES_PER_PAGE
  ): Observable<PaginationData & { data: TripEvent[] }> {
    const query = [`page=${page}`, `take=${take}`];
    this.repo.setPage(page);
    return this.http
      .get<PaginationData & { data: TripEvent[] }>(
        `${API}/totaloverview?${query.join('&')}`
      )
      .pipe(
        tap((res) => this.repo.addPage(res)),
        this.repo.track(),
        this.repo.skipWhilePageCached(page)
      );
  }

  loadPageByUser(
    id: string,
    page: number,
    take: number = DEFAULT_ENTITIES_PER_PAGE
  ): Observable<PaginationData & { data: TripEvent[] }> {
    const query = [`page=${page}`, `take=${take}`];
    this.repo.setPage(page);
    return this.http
      .get<PaginationData & { data: TripEvent[] }>(
        `${API}/user/${id}?${query.join('&')}`
      )
      .pipe(
        tap((res) => this.repo.addPage(res)),
        this.repo.track(),
        this.repo.skipWhilePageCached(page)
      );
  }

  updateTenantNote(model: Partial<TenantNoteEvent>): Observable<TripEvent> {
    const query = [`tenantNote=${model.tenantNote}`];
    return this.http
      .patch<TripEvent>(
        `${API}/tenantNote/${model.tripEventId}?${query.join('&')}`,
        {}
      )
      .pipe(
        tap((res) => this.repo.upsert(res)),
        this.repo.trackOne(model.tripEventId!)
      );
  }

  updateEProducts(dto: EconomicInfoExtended): Observable<TripEvent[]> {
    return this.http.put<TripEvent[]>(`${API}/eproductupdate`, dto).pipe(
      tap((res) => this.repo.set(res)),
      this.repo.track()
    );
  }

  updateClients(dto: ClientEventDto) {
    return this.http.put(`${API}/clientsupdate`, dto);
  }

  exportByUser(id: string): Observable<string> {
    return this.http
      .get<Blob>(`${API}/csv/${id}`, { responseType: 'blob' as any })
      .pipe(
        map((resp) => {
          const blob = new Blob([resp], { type: resp.type });
          return window.URL.createObjectURL(blob);
        })
      );
  }
}
