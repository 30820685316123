import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getRegistry } from '@ngneat/elf';
import { of, OperatorFunction } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { ScheduledForRetryError } from '../modules/core/interceptors/retry.interceptor';
import { SettingsRequestsResolverService } from '../modules/truck/services/settings-requests-resolver.service';
import {
  AuthProps,
  AuthRepository,
  PasswordChangeRequest,
  trackAuthRequestsStatus,
  UserSettings,
} from './auth.repository';

const API = '/api/auth';

const USER_INDEPENDENT_STORES = ['ui', 'requests'];

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private http: HttpClient,
    private repo: AuthRepository,
    private offlineResolver: SettingsRequestsResolverService,
    private route: ActivatedRoute
  ) {}

  login(email: string, password: string, isRemembered = false) {
    return this.http
      .post<AuthProps>(API, {
        email: email,
        password: password,
        rememberme: isRemembered,
      })
      .pipe(
        tap((resp) => this.repo.setToken(resp?.token)),
        trackAuthRequestsStatus(this.repo.name)
      );
  }

  forgot(email: string) {
    return this.http.post<AuthProps>(`${API}/forgot`, { email });
  }

  reset(password: string, id: string, token: string) {
    return this.http.post<AuthProps>(`${API}/reset`, { password, id, token });
  }

  changePassword(dto: PasswordChangeRequest) {
    return this.http.post<void>(`${API}/password`, dto);
  }

  logout() {
    localStorage.setItem('specialToken', '');
    return this.http.delete(API).pipe(tap(() => this.clearStores()));
  }

  impersonate(id: string) {
    return this.http.post<AuthProps>(`${API}/impersonate`, { id }).pipe(
      tap(() => this.clearStores()),
      tap((resp) => this.repo.setToken(resp?.token)),
      trackAuthRequestsStatus(this.repo.name)
    );
  }

  unimpersonate() {
    return this.http.delete<AuthProps>(`${API}/impersonate`).pipe(
      tap(() => this.clearStores()),
      tap((resp) => this.repo.setToken(resp?.token)),
      trackAuthRequestsStatus(this.repo.name)
    );
  }

  refresh(token?: string) {
    let tokenOut;
    if (token && token.length >= 1) {
      tokenOut = token;
    } else {
      tokenOut = 'empty';
    }
    return this.http
      .post<AuthProps>(`${API}/refresh?token=${tokenOut}`, token)
      .pipe(
        tap((resp) => this.repo.setToken(resp?.token)),
        trackAuthRequestsStatus(this.repo.name)
      );
  }

  autoRefresh() {
    let token = localStorage.getItem('specialToken');
    return this.repo.isExpiresSoon$.pipe(switchMap(() => this.refresh(token!)));
  }

  loadSettings() {
    return this.http.get<UserSettings>(`${API}/settings`).pipe(
      tap((settings) => this.repo.setSettings(settings)),
      trackAuthRequestsStatus(`${this.repo.name}_settings`)
    );
  }

  updateSettings(settings: UserSettings) {
    return this.http.patch<UserSettings>(`${API}/settings`, settings).pipe(
      this.processOfflineSubmit(),
      tap((settings) => this.repo.setSettings(settings))
    );
  }

  private clearStores(): void {
    getRegistry().forEach((store, key) => {
      if (!USER_INDEPENDENT_STORES.includes(key)) {
        store.reset();
      }
    });
  }

  private processOfflineSubmit(): OperatorFunction<UserSettings, UserSettings> {
    return catchError((err) => {
      if (
        err instanceof ScheduledForRetryError &&
        this.offlineResolver.canResolve(err.request)
      ) {
        const settings = this.offlineResolver.buildSettings(err.request);
        return of(settings);
      }
      throw err;
    });
  }
}
