import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface FilePathContainer {
  filePath: string;
}

const API = '/api/fileupload';
@Injectable({
  providedIn: 'root',
})
export class FileUploaderService {
  constructor(protected http: HttpClient) {}

  uploadFile(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<FilePathContainer>(`${API}`, formData);
  }
}
