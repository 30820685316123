import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { EnvState } from 'src/app/modules/shared/helpers/env-state';
import { AuthRepository } from 'src/app/state/auth.repository';

import { PriceType } from 'src/app/state/pricetypes.repository';
import { Product } from 'src/app/state/products.repository';

@Component({
  selector: 'app-eproduct-form',
  templateUrl: './eproduct-form.component.html',
  styleUrls: ['./eproduct-form.component.scss'],
})
export class EproductFormComponent implements OnInit {
  @Input() editedEProduct: Product | null = null;

  form: UntypedFormGroup | null = null;
  @Output() eproductSubmit = new EventEmitter<Partial<Product>>();
  @Input() set priceTypes(value: PriceType[] | null) {
    if (!value) {
      this.priceTypesOptions = null;
    } else {
      this.priceTypesOptions = value.map((x) => ({
        value: x.id,
        label: x.name.replace('_', ' ').replace('Minutter', 'Timer'),
      }));
    }
  }
  priceTypesOptions: DatalistOption[] | null = null;
  constructor(
    private formBuilder: UntypedFormBuilder,
    public env: EnvState,
    public authRepo: AuthRepository
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [this.editedEProduct?.name, Validators.required],
      productNumber: [this.editedEProduct?.productNumber],
      description: [this.editedEProduct?.description],
      priceType: [this.editedEProduct?.priceTypeId || '', Validators.required],
    });
  }

  updateValue(control: string, value: any) {
    const controlObject = this.form?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  onSubmit() {
    if (this.form && !this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    let name = this.form?.value?.name.toString();
    let description = this.form?.value?.description?.toString();
    let productNumber = this.form?.value?.productNumber?.toString();
    let priceTypeId = this.form?.value?.priceType?.toString();

    this.eproductSubmit.emit({ name, productNumber, description, priceTypeId });
  }
}
