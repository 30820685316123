import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss'],
})
export class ConfirmDeleteComponent {
  @Input() isMultipleDrivers: boolean = false;
  @Output() confirm = new EventEmitter();
  @Output() cancel = new EventEmitter<void>();
  @Input() deleteCandidate: string = '';
  @Input() deleteButton: string = '';
  @Input() deleteText: string = '';
  @Input() deleteMessage: string = '';
  @Input() isReccurence: boolean = false;

  @ViewChild('isForOneDriver') isForOneDriver:
    | ElementRef<HTMLInputElement>
    | undefined;

  @ViewChild('isRecurred') isRecurred: ElementRef<HTMLInputElement> | undefined;

  addNewLines(str: string) {
    return str.replace(/\n/g, '<br>');
  }
  confirmCall() {
    try {
      if (this.isMultipleDrivers) {
        this.confirm.emit({
          recurrence: this.isReccurence
            ? this.isRecurred?.nativeElement.checked
            : true,
          forOneDriver: this.isForOneDriver?.nativeElement.checked,
        });
      } else if (this.isReccurence) {
        this.confirm.emit(this.isRecurred?.nativeElement.checked);
      } else {
        this.confirm.emit(true);
      }
    } catch (s: any) {
      this.confirm.emit();
    }
  }
  constructor() {}
}
