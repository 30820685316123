<div *ngIf="pause"
    class="card core shadow-sm mb-3 border-primary border-left-5">
    <div class="card-body">
        <h6 class="my-1" i18n="Paused label">Paused</h6>
        <p class="text-muted mb-1" i18n="Resuming in period label">Resuming in {{ duration$ | async | timing }}</p>
    </div>
</div>
<div class="core card shadow-sm bg-primary text-white w-100 me-2" role="button"
  (click)="resume()">
  <div class="card-body">
    <h2 class="mb-3">
      <i *ngIf="!isLoading" class="bi bi-play-fill"></i>
      <app-spinner *ngIf="isLoading"></app-spinner>
    </h2>
    <h6 i18n="Resume now label">Resume now</h6>
  </div>
</div>
