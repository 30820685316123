<div
  class="modal fade in"
  *ngIf="urls && urls!.length > 0"
  id="photoModal"
  [style.display]="display"
  [style.opacity]="opacity"
  tabindex="-1"
  aria-labelledby="photoModal"
  aria-hidden="false"
>
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content bg-lights border-0">
      <div class="modal-header">
        <h6
          class="modal-title"
          id="photoModalLabel"
          i18n="Title for gallery window"
        >
          Trip Photos {{ slideIndex + 1 }}/{{ urls?.length }}
        </h6>
        <button
          type="button"
          class="btn-close"
          (click)="refreshIndex(); cancel.emit()"
          aria-label="Close"
          i18n-aria-label="Close label @@close"
        ></button>
      </div>
      <div class="modal-body" *ngIf="urls?.length !== 0">
        <div class="form-floating mb-3 hfc">
          <div class="prev" (click)="minusSlide()">&#10094;</div>
          <div class="next" (click)="plusSlide()">&#10095;</div>
          <img class="img-slide" *ngIf="urls" src="{{ urls[slideIndex] }}" />
          <div *ngIf="moveEnabled && (connection.isOnline$ | async)">
            <!-- <button
              (click)="
                this.moveToStart.emit(urls[slideIndex]); deleteMovedFile()
              "
              class="btn btn-primary mt-2"
            >
              <span i18n="@@moveToStart">Move To Start</span>
            </button> -->
            <!-- <button
              (click)="
                this.moveToWeight.emit(urls[slideIndex]); deleteMovedFile()
              "
              class="btn btn-primary mt-2 ms-2"
            >
              <span i18n="@@moveToWeight">Move To Weighing</span>
            </button> -->
          </div>
          <button
            *ngIf="weighEvents && weighEvents.length > 0"
            (click)="
              weighPhotoEmmiter.emit({
                url: urls[slideIndex],
                trips: weighEvents
              });
              change()
            "
            class="btn btn-primary mt-2"
          >
            <span i18n="@@moveToStart">Move To Start</span>
          </button>
          <button
            *ngIf="usualEvents && usualEvents.length > 0"
            (click)="
              usualPhotoEmmiter.emit({
                url: urls[slideIndex],
                trips: usualEvents
              });
              change()
            "
            class="btn btn-primary mt-2"
          >
            <span i18n="@@moveToWeight">Move To Weighing</span>
          </button>

          <button
            *ngIf="modalEvents && modalEvents.length > 0"
            (click)="
              modalPhotoEmmiter.emit({
                url: urls[slideIndex],
                events: modalEvents
              });
              change()
            "
            class="btn btn-primary mt-2"
          >
            <span i18n="@@moveModalToWeight">Move To Weighing</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
