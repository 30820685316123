import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationData } from '@ngneat/elf-pagination';
import { map, Observable, tap } from 'rxjs';
import { BaseService, DEFAULT_ENTITIES_PER_PAGE } from './abstract/base.service';
import { WorkdayEventsRepository } from './workdayevents.repository';
import { WorkdayEvent } from './workdays.repository';

const API = '/api/workdayevents';

@Injectable({
  providedIn: 'root'
})
export class WorkDayEventsService extends BaseService<WorkdayEvent>  {

  constructor(
    http: HttpClient,
    repo: WorkdayEventsRepository
  ) {
    super(API, http, repo);
  }

  loadForTrip(id: string) {    
    return this.http.get<WorkdayEvent[]>(`${API}/trip/${id}`);
  }
  
  loadPageByUser(id: string, page: number, take: number = DEFAULT_ENTITIES_PER_PAGE): Observable<PaginationData & { data: WorkdayEvent[] }> {
    const query = [
      `page=${page}`,
      `take=${take}`
    ];
    this.repo.setPage(page);
    return this.http
      .get<PaginationData & { data: WorkdayEvent[] }>(`${API}/user/${id}?${query.join('&')}`)
      .pipe(
        tap(res => this.repo.addPage(res)),
        this.repo.track(),
        this.repo.skipWhilePageCached(page)
      );
  }

  exportByUser(id: string): Observable<string> {
    return this.http
      .get<Blob>(`${API}/csv/${id}`, { responseType: 'blob' as any })
      .pipe(
        map(resp => {
          const blob = new Blob([resp], { type: resp.type });
          return window.URL.createObjectURL(blob);
        })
      );
  }

}
