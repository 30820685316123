<div class="card shadow-sm">
    <div class="card-body">
        <div class="row">
            <div class="col-auto">
                <div [ngClass]="['avatar avatar-40 rounded-circle', iconClass ]">
                    <i [ngClass]="['bi', icon]"></i>
                </div>
            </div>
            <div class="col px-0">
                <h6 class="mb-0">
                    <ng-container *ngIf="!isLoading">{{ title }}</ng-container>
                    <app-spinner class="text-muted" *ngIf="isLoading"></app-spinner>
                </h6>
                <p class="text-muted small">{{ subtitle }}</p>
            </div>
        </div>
    </div>
</div>