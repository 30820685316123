import { Component, Input, OnInit } from '@angular/core';
import { sortBy } from 'src/app/modules/shared/pipes/sort.pipe';
import { Company } from 'src/app/state/companies.repository';
import { Order } from 'src/app/state/orders.repository';
import { Product } from 'src/app/state/products.repository';
import { TripEvent } from 'src/app/state/trips.repository';

@Component({
  selector: 'app-go-order-info',
  templateUrl: './go-order-info.component.html',
  styleUrls: ['./go-order-info.component.scss'],
})
export class GoOrderInfoComponent implements OnInit {
  order: Order | null = null;
  noteModalShow: boolean = false;
  @Input() companies: Company[] | null = null;
  @Input() products: Product[] | null = null;
  lastCreatedEvent?: TripEvent | null = null;
  @Input() set activeOrder(value: Order | null) {
    if (!value) {
      this.order = null;
    } else {
      this.order = value;
      this.lastCreatedEvent = sortBy(
        value.tripEvents.filter((x) => x.type !== 'Weighing'),
        { parameter: { property: 'createdAt' }, direction: 'desc' }
      )[0];
    }
  }
  constructor() {}

  ngOnInit(): void {}

  getClientName(companies: Company[] | null, clientId?: string) {
    if (companies && clientId) {
      return companies.find((x) => x.id === clientId)?.name;
    }
    return '';
  }

  getDanishEventType(type: string): string {
    switch (type) {
      case 'TripStart': {
        return 'Start';
      }
      case 'TripEnd': {
        return 'Slut';
      }
      case 'Load': {
        return 'Laste';
      }
      case 'Unload': {
        return 'Losse';
      }
      default: {
        return type;
      }
    }
  }
}
