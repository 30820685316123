import { Injectable } from '@angular/core';
import { clearRequestsCache } from '@ngneat/elf-requests';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';

export interface Vehicle {
  id: string;
  name: string;
  numberplate: string;
  isBeingUsed: boolean;
  createdAt: Date;
}

export const vehiclesSortOptions: SortOption[] = [
  { label: $localize `:Sort label Name:Name`, property: 'name' },
  { label: $localize `:Sort label Number plate:Number plate`, property: 'numberplate' },
  { label: $localize `:Sort label Date:Date`, property: 'createdAt' }
];

@Injectable({ providedIn: 'root' })
export class VehiclesRepository extends BaseRepository<Vehicle> {

  constructor() {
    super('vehicles', vehiclesSortOptions);
  }

  clearCache() {
    this.store.update(
      clearRequestsCache()
    );
  }
}
