import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';
import { EnvState } from 'src/app/modules/shared/helpers/env-state';
import { AuthRepository, UserRoles } from 'src/app/state/auth.repository';
import { AuthService } from 'src/app/state/auth.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit {
  title: any;
  loginForm = this.formBuilder.group({
    email: ['', [Validators.email, Validators.required]],
    password: ['', Validators.required],
    rememberMe: false,
  });

  private returnUrl$: Observable<string>;

  constructor(
    private auth: AuthService,
    public repo: AuthRepository,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    public env: EnvState
  ) {
    this.returnUrl$ = this.route.queryParamMap.pipe(
      map((params) => params.get('returnUrl') || '/')
    );
  }

  ngOnInit(): void {
    this.repo.isAuthenticated$
      .pipe(
        filter((x) => x),
        take(1),
        withLatestFrom(this.returnUrl$)
      )
      .subscribe(([_, returnUrl]) => this.router.navigateByUrl(returnUrl));
  }

  private showTooltip() {
    var tooltiptriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltiptriggerList.map(function (e: any) {
      return new bootstrap.Tooltip(e);
    });
  }

  private renderError(err: any) {
    this.title = err.error;
    setTimeout(this.showTooltip);
  }

  login(): void {
    this.auth
      .login(
        this.loginForm.value.email,
        this.loginForm.value.password,
        this.loginForm.value.rememberMe
      )
      .subscribe({
        next: (x) => {
          let isAccessed = false;
          for (let role in UserRoles) {
            if (this.repo.isInRole(role)) {
              isAccessed = true;
            }
          }
          if (!isAccessed) {
            this.router.navigate(['/profile']);
            return;
          }
        },
        error: (err) => this.renderError(err),
      });
  }
}
