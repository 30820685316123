import { Injectable } from "@angular/core";
import { SortOption } from "../modules/shared/pipes/sort.pipe";
import { BaseRepository } from "./abstract/base.repository";
import { Trip } from "./trips.repository";


export const deletedTripsSortOptions: SortOption[] = [  
  { label: $localize`:Sort label Created Date: Created date`, property: 'createdAt' },
  { label: $localize`:Sort label Deleted Date: Deleted date`, property: 'deletedAt' },
];

@Injectable({ providedIn: 'root' })
export class DeletedTripsRepository extends BaseRepository<Trip> {
  constructor() {
    super('deletedtrips', deletedTripsSortOptions);
  }
}
