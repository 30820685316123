import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { Column, ColumnsRepository } from './columns.repository';

const API = '/api/columns';

@Injectable({ providedIn: 'root' })
export class ColumnsService {
  constructor(private http: HttpClient, private repo: ColumnsRepository) {}

  loadColumns(): Observable<Column[]> {
    return this.http.get<Column[]>(API).pipe(tap((res) => this.repo.set(res)));
  }

  loadAll() {
    return this.http.get<Column[]>(API);
  }

  updateColumns(dtos: Column[]): Observable<Column[]> {
    return this.http.put<Column[]>(API, dtos).pipe(
      tap((x) => this.repo.clear()),
      tap((res) => this.repo.set(res))
    );
  }

  deleteOrder() {
    this.repo.clear();
    return this.http
      .delete<void>(API)
      .pipe(
        tap(() => this.loadColumns().pipe(tap((res) => this.repo.set(res))))
      );
  }
}
