import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Product } from 'src/app/state/products.repository';

@Component({
  selector: 'app-eproduct-list',
  templateUrl: './eproduct-list.component.html',
  styleUrls: ['./eproduct-list.component.scss']
})
export class EproductListComponent implements OnInit {
  @Input() eproducts: Product[] | null = null;
  
  constructor() { }

  ngOnInit(): void {
  }

}
