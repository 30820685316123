import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { switchMap, tap } from 'rxjs/operators';
import { skipWhileTenantsCached, Tenant, TenantsRepository, trackTenantRequestsStatus } from "./tenants.repository";

const API = '/api/tenants';

@Injectable({ providedIn: 'root' })
export class TenantsService {
  constructor(private http: HttpClient, private repo: TenantsRepository) { }

  load() {
    return this.http
      .get<Tenant[]>(API)
      .pipe(
        tap((tenants) => this.repo.setTenants(tenants)),      
        trackTenantRequestsStatus(this.repo.name),
        //skipWhileTenantsCached(this.repo.name)
      );
  }

  loadOne(id: string) {
    return this.http
      .get<Tenant>(`${API}/${id}`)
      .pipe(
        tap((tenant) => this.repo.upsertTenant(tenant)),
        trackTenantRequestsStatus(id),
        skipWhileTenantsCached(id)
      );
  }
   

  add(tenant: Partial<Tenant>) {
    const formData = new FormData();
    formData.append("file", tenant.image!);

    return this.http
      .post<Tenant>(API, tenant)
      .pipe(
        tap((tenant) => this.repo.upsertTenant(tenant)),
        switchMap((tenant) => this.http.post<Tenant>(`${API}/${tenant.id}/file`, formData)),
        trackTenantRequestsStatus(`${this.repo.name}_add`)
      );
  }

  update(id: string, model: Partial<Tenant>) {
    return this.http
      .patch<Tenant>(`${API}/${id}`, model)
      .pipe(
        tap((tenant) => this.repo.upsertTenant(tenant)),
        switchMap((tenant) => {
          if (model.image) {
            const formData = new FormData();
            formData.append("file", model.image);
            return this.http.post<Tenant>(`${API}/${id}/file`, formData);
          }
          return of(tenant);
        }),
        trackTenantRequestsStatus(id)
      );
  }

  delete(id: string): Observable<void> {
    return this.http
      .delete<void>(`${API}/${id}`)
      .pipe(
        tap(() => this.repo.remove(id))
    );
  }
}
