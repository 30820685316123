import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { ConnectionService } from '../../shared/services/connection.service';
import { AuthRepository } from 'src/app/state/auth.repository';
import { Request, RequestsRepository } from 'src/app/state/requests.repository';
import { EnvState } from '../../shared/helpers/env-state';

const RETRY_METHODS = [ 'POST', 'PATCH' ];

export class ScheduledForRetryError implements Error {
  name: string;
  message: string;
  stack?: string | undefined;

  constructor(public request: Request, public inner: Error) {
    this.name = 'ScheduledForRetryError';
    this.message = 'A request has failed in offline mode and will be syncronized once online.';
  }
}

const DO_NOT_INTERCEPT = [
  '/api/auth'
];

export const RETRY_HEADER = "IsRetriedRequest";

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  constructor(
    private connection: ConnectionService,
    private auth: AuthRepository,
    private repo: RequestsRepository,
    private env: EnvState
  ) {
    this.reqId = 0;
   }

reqId:number = 0;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.repo.count$.subscribe(x=>this.reqId = x+1);
    return next.handle(request).pipe(
      catchError((error: Error) => {
        if (this.shouldRetry(request, error)) {
          // Handle offline request
          let url = request.urlWithParams;
          if (url.startsWith(this.env.apiUrl)) {
            url = url.substring(this.env.apiUrl.length);
          }          
          var entity = {
          id: this.reqId,
          body: request.body,
          url,
          method: request.method.toUpperCase(),
          userId: this.auth.getId(),
          createdAt: new Date()} as Request;
          
          if(request.body && request.body.type) {
            if(!(url.includes('orders') && url.endsWith('events')) &&
            !(request.body.type === 'TripEnd' || request.body.type === 'Load' || request.body.type === 'Unload' || request.body.type === 'Weighing')) {
            this.repo.add(entity);
            }
          } 
          if(request.body && !request.body.type && url.includes('orders') && request.method.toUpperCase() === 'PATCH') {
            this.repo.add(entity); 
          }
          
          if(url.includes('workdays') && !url.includes('events')) {
            this.repo.add(entity);            
          }
          
          if(request.body && url.includes('trips')) {            
             this.repo.add(entity);
          }
          throw new ScheduledForRetryError(entity, error);        
        }
        // return the error back to the caller
        throw error;
      })
    );
  }

  private shouldRetry(request: HttpRequest<any>, error: any) {
    let requestUrl = request.url;    
    if (this.env.apiUrl && requestUrl.startsWith(this.env.apiUrl)) {
      requestUrl = requestUrl.substring(this.env.apiUrl.length);      
    }
    return !this.connection.isOnline()
      && !request.headers.has(RETRY_HEADER)
      && error instanceof HttpErrorResponse
      && RETRY_METHODS.includes(request.method.toUpperCase())
      && !DO_NOT_INTERCEPT.includes(requestUrl);
  }
}
