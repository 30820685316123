import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { EnvState } from 'src/app/modules/shared/helpers/env-state';
import { AuthRepository } from 'src/app/state/auth.repository';
import { TenantFeatures } from 'src/app/state/feature.repository';

import { Product } from 'src/app/state/products.repository';

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss']
})
export class ProductFormComponent implements OnInit {
  TenantFeatures = TenantFeatures;
  features = this.env.features;
  @Input() editedProduct: Product | null = null; 
  
  form: UntypedFormGroup | null = null;   
  @Output() productSubmit = new EventEmitter<Partial<Product>>();

  constructor(private formBuilder: UntypedFormBuilder,
              public env: EnvState,
              public authRepo: AuthRepository) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [this.editedProduct?.name, Validators.required],
      productNumber: [this.editedProduct?.productNumber],
      description: [this.editedProduct?.description]      
    });
  }

  updateValue(control: string, value: any) {
    const controlObject = this.form?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  onSubmit() {
      if (this.form && !this.form.valid) {
        this.form.markAllAsTouched();
        return;
      }
    let name = this.form?.value?.name.toString();
    let description = this.form?.value?.description?.toString();
    let productNumber = this.form?.value?.productNumber?.toString();
    

    this.productSubmit.emit({ name, productNumber,description });
  }

}
