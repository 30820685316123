<div class="row">
  <div class="col mb-4 mt-3 ms-2">
    <h2 i18n="Dashboard label">Dashboard</h2>
  </div>

  <div
    class="col-auto d-flex align-items-center pe-3"
    *ngIf="
      (authrepo.isTenantAdmin$ | async) &&
      (authrepo.hasFeature$(TenantFeatures.Order) | async)
    "
  >
    <button
      data-bs-toggle="collapse"
      data-bs-target="#collapseInfo"
      style="margin-right: 10px"
      class="btn btn-primary"
    >
      <i class="bi bi-info-circle"></i>
    </button>

    <button
      class="btn btn-primary"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#collapseExample"
      i18n="showDetails"
      aria-expanded="false"
      aria-controls="collapseExample"
    >
      <i class="bi bi-lightning-charge me-2"></i>
      Show details
    </button>
  </div>
</div>

<div class="collapse" id="collapseInfo">
  <div class="row mb-3">
    <p><span class="green">GRØN</span> = Ture der skal køres i dag ( d.d.)</p>
    <p><span class="lightblue">LYSEBLÅ</span> = Ture der skal køres i morgen</p>
    <p>
      <span class="blue">BLÅ</span> = Ture der skal køres indenfor et dato
      interval. ( Skifter til <span class="lightblue">LYSEBLÅ</span> /
      <span class="green">GRØN</span> når man nærmer sig sidste dato for kørsel
      af turen ).
    </p>
    <p>
      <span class="red">RØD</span> = Alle ture i pulje der IKKE er
      kørt/påbegyndt endnu.
    </p>
    <p>
      <span class="orange">ORANGE</span> = Ture der er påbegyndt af chaufføren.
    </p>
    <p>
      <span class="purple">LILLA</span> = Tur der er parkeret af chaufføren ( NB
      Turen kan flyttes til en anden chauffør hvis det viser sig nødvendigt ).
    </p>
  </div>
</div>

<div
  [class.collapse]="
    (authrepo.isTenantAdmin$ | async) &&
    (authrepo.hasFeature$(TenantFeatures.Order) | async)
  "
  id="collapseExample"
>
  <div class="row mb-3">
    <div class="col-12 col-lg-3 mb-3">
      <div class="card shadow-sm bg-primary text-white h-100">
        <div class="card-body d-flex align-items-center">
          <h3 class="m-0 h6" i18n="Active drivers label">Active drivers</h3>
        </div>
      </div>
    </div>
    <app-stat-card
      *ngFor="let card of driverStats; let index = index"
      style="cursor: pointer"
      (click)="cardClick(subtitles[index])"
      [title]="driverStats[index]"
      [subtitle]="subtitles[index]"
      icon="bi-person"
      [iconClass]="'text-white bg-primary bg-light-' + index"
      [isLoading]="!driverStats[index]"
      class="col-12 col-lg-3 mb-3"
    ></app-stat-card>
  </div>
  <div class="row mb-3">
    <div class="col-12 col-lg-3 mb-3">
      <div class="card shadow-sm bg-success text-white h-100">
        <div class="card-body d-flex align-items-center">
          <h3 class="m-0 h6" i18n="Trips label">Trips</h3>
        </div>
      </div>
    </div>
    <app-stat-card
      *ngFor="let card of tripStats; let index = index"
      [title]="tripStats[index]"
      [subtitle]="subtitles[index]"
      icon="bi-truck"
      [iconClass]="'text-white bg-success bg-light-' + index"
      [isLoading]="!tripStats[index]"
      class="col-12 col-lg-3 mb-3"
    ></app-stat-card>
  </div>
  <div class="row mb-3">
    <div class="col-12 col-lg-3 mb-3">
      <div class="card shadow-sm bg-warning text-white h-100">
        <div class="card-body d-flex align-items-center">
          <h3 class="m-0 h6" i18n="Products unloaded label">
            Products unloaded
          </h3>
        </div>
      </div>
    </div>
    <app-stat-card
      *ngFor="let card of productStats; let index = index"
      [title]="productStats[index]"
      [subtitle]="subtitles[index]"
      icon="bi-box"
      [iconClass]="'text-white bg-warning bg-light-' + index"
      [isLoading]="!productStats[index]"
      class="col-12 col-lg-3 mb-3"
    ></app-stat-card>
  </div>
</div>
<ng-container
  *ngIf="
    (authrepo.isTenantAdmin$ | async) &&
    (authrepo.hasFeature$(TenantFeatures.Order) | async)
  "
>
  <div
    class="ms-2 card card-shadow btn-lg btn-success text-white text-center hoverable"
    (click)="loadDataForPopUp(); getDatesToModal()"
    #newOrder
    data-bs-toggle="modal"
    data-bs-target="#infomodal"
  >
    <h2 i18n="@@createOrder">Create Order</h2>
  </div>
  <app-orders-filter
    (filterEmiter)="loadByFilter($event)"
    [drivers]="driversFixed"
    [usersDeleted]="usersDeleted"
    [isLoading]="(usersRepo.isLoading$ | async) || false"
  ></app-orders-filter>
  <ng-container>
    <ng-container *ngVar="sortOrdersByDateDesc(the_orders) as orders">
      <h2
        class="mt-4 ms-3"
        i18n="@@rejectedOrders"
        *ngIf="checkRejected(orders)"
      >
        Rejected orders
      </h2>
      <div
        class="mt-3 ms-2 card card-shadow btn-lg blood-red text-white"
        *ngIf="checkRejected(orders)"
      >
        <div class="table-responsive tableFixHeadReject mt-0">
          <table class="table enableScrol table-bordered">
            <thead>
              <tr>
                <th i18n="@@successDate" class="text-center align-middle">
                  Start Date
                </th>
                <th i18n="@@successStart" class="text-center align-middle">
                  Start Time
                </th>
                <th i18n="@@endDate" class="text-center align-middle">
                  End Date
                </th>
                <th i18n="@@endTime" class="text-center align-middle">
                  End Time
                </th>
                <th i18n="@@rejectedClient" class="text-center align-middle">
                  Client
                </th>
                <th i18n="@@rejectedWeight" class="text-center align-middle">
                  Weight
                </th>
                <th i18n="@@rejectedProduct" class="text-center align-middle">
                  Product
                </th>
                <th i18n="@@rejectedReference" class="text-center align-middle">
                  Reference
                </th>
                <th i18n="@@openComment" class="text-center align-middle">
                  Comment
                </th>
                <th i18n="@@rejectedBy" class="text-center align-middle">
                  Rejected By
                </th>
                <th i18n="@@rejectedAt" class="text-center align-middle">
                  Rejected At
                </th>
                <th i18n="@@rejectedInfo" class="text-center align-middle">
                  Trip info
                </th>
                <th i18n="@@rejectedDelete" class="text-center align-middle">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let order of orders; let i = index"
                [draggable]="!order.parentId"
                [class.hoverable]="!order.parentId"
                [class.grabbable]="!order.parentId"
                [class.child-grey]="order.parentId"
                (drag)="getOrder(order)"
              >
                <ng-container class="ms-4" *ngIf="order.isRejected">
                  <td class="text-center align-middle">
                    <span *ngIf="order && order.plannedToStartAt">{{
                      toCorrectDate(order.plannedToStartAt, "day")
                    }}</span>
                  </td>
                  <td class="text-center align-middle">
                    <span *ngIf="order && order.plannedToStartAt">{{
                      toCorrectDate(order.plannedToStartAt, "time")
                    }}</span>
                  </td>
                  <td class="text-center align-middle">
                    <span *ngIf="order && order.plannedToEndAt">{{
                      toCorrectDate(order.plannedToEndAt, "day")
                    }}</span>
                  </td>
                  <td class="text-center align-middle">
                    <span *ngIf="order && order.plannedToEndAt">{{
                      toCorrectDate(order.plannedToEndAt, "time")
                    }}</span>
                  </td>
                  <td class="text-center align-middle">
                    {{ order?.client?.name }}
                  </td>
                  <td class="text-center align-middle">{{ order.weight }}</td>
                  <td class="text-center align-middle">
                    {{ getProducts(order?.products) }}
                  </td>
                  <td class="text-center align-middle">
                    {{ order.reference }}
                  </td>
                  <td class="text-center align-middle">
                    {{ order.comment }}
                  </td>
                  <td class="text-center align-middle">
                    {{ order.rejectedByName }}
                  </td>
                  <td class="text-center align-middle">
                    {{
                      (order.rejectedAt | date) +
                        " - " +
                        (order.rejectedAt | date : "H:mm")
                    }}
                  </td>
                  <td class="text-center align-middle">
                    <i
                      style="margin-right: 5px"
                      class="bi bi-calendar3 hoverable"
                      *ngIf="order && order.isReccurence"
                    >
                    </i>
                    <i
                      class="bi bi-info-circle hoverable"
                      *ngIf="order && !order.parentId"
                      (click)="loadDataForPopUp(order)"
                      data-bs-toggle="modal"
                      data-bs-target="#infomodal"
                    >
                      <span style="color: #ffeb36">{{
                        order?.familyNumber
                      }}</span></i
                    >
                    <i
                      class="bi bi-person ms-2"
                      *ngIf="order && !order.parentId && !order.isOrder"
                    >
                    </i>
                    <i
                      *ngIf="order && order.parentId"
                      (click)="loadDataForPopUp(order)"
                      data-bs-toggle="modal"
                      data-bs-target="#infomodal"
                      class="bi bi-link-45deg linked-ico hoverable"
                    >
                      <span style="color: #ffeb36">{{
                        order?.familyNumber
                      }}</span></i
                    >
                  </td>
                  <td class="text-center align-middle">
                    <i
                      class="bi bi-x-square hoverable"
                      *ngIf="order"
                      (click)="deleteConfirmation = order"
                    ></i>
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <h2 class="mt-4 ms-3" i18n="@@openOrders" *ngIf="checkOpen(orders)">
        Open trips
      </h2>
      <div
        class="mt-3 ms-2 card card-shadow btn-lg bordered-primary text-white mb-4"
        *ngIf="checkOpen(orders)"
        (drop)="updateDriver()"
        (dragover)="getDriver()"
      >
        <!-- if we don't need open orders for 1 driver filter && drivers && drivers.length && drivers.length > 1-->

        <div class="tableFixHead mt-0">
          <table class="table enableScrol table-bordered bordered-primary my-0">
            <thead>
              <tr>
                <th i18n="@@successDate" class="text-center align-middle">
                  Start Date
                </th>
                <th i18n="@@successStart" class="text-center align-middle">
                  Start Time
                </th>
                <th i18n="@@endDate" class="text-center align-middle">
                  End Date
                </th>
                <th i18n="@@endTime" class="text-center align-middle">
                  End Time
                </th>
                <th i18n="@@openClient" class="text-center align-middle">
                  Client
                </th>
                <th i18n="@@openWeight" class="text-center align-middle">
                  Weight
                </th>
                <th i18n="@@openProduct" class="text-center align-middle">
                  Product
                </th>
                <th i18n="@@openReference" class="text-center align-middle">
                  Reference
                </th>
                <th i18n="@@openComment" class="text-center align-middle">
                  Comment
                </th>
                <th i18n="@@openInfo" class="text-center align-middle">
                  Trip info
                </th>
                <th i18n="@@openDelete" class="text-center align-middle">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                [class.green-backg]="
                  toCorrectDate(order.plannedToStartAt, 'day') ==
                  toCorrectDate(today, 'day')
                "
                [class.blue-backg]="
                  toCorrectDate(order.plannedToEndAt, 'day') ==
                    toCorrectDate(tommorow, 'day') &&
                  !(
                    toCorrectDate(order.plannedToStartAt, 'day') ==
                    toCorrectDate(today, 'day')
                  )
                "
                *ngFor="let order of orders; let i = index"
                [draggable]="!order.parentId"
                [class.hoverable]="!order.parentId"
                [class.grabbable]="!order.parentId"
                [class.child-grey]="order.parentId"
                (drag)="getOrder(order)"
              >
                <ng-container
                  *ngIf="
                    !findEvent(order, 'TripEnd') &&
                    !findEvent(order, 'TripStart') &&
                    checkIfOpen(order)
                  "
                  class="ms-4"
                >
                  <td class="text-center align-middle">
                    <span *ngIf="order && order.plannedToStartAt">{{
                      toCorrectDate(order.plannedToStartAt, "day")
                    }}</span>
                  </td>
                  <td class="text-center align-middle">
                    <span *ngIf="order && order.plannedToStartAt">{{
                      toCorrectDate(order.plannedToStartAt, "time")
                    }}</span>
                  </td>
                  <td class="text-center align-middle">
                    <span *ngIf="order && order.plannedToEndAt">{{
                      toCorrectDate(order.plannedToEndAt, "day")
                    }}</span>
                  </td>
                  <td class="text-center align-middle">
                    <span *ngIf="order && order.plannedToEndAt">{{
                      toCorrectDate(order.plannedToEndAt, "time")
                    }}</span>
                  </td>
                  <td class="text-center align-middle">
                    {{ order?.client?.name }}
                  </td>
                  <td class="text-center align-middle">{{ order.weight }}</td>
                  <td class="text-center align-middle">
                    {{ getProducts(order?.products) }}
                  </td>
                  <td class="text-center align-middle">
                    {{ order.reference }}
                  </td>
                  <td class="text-center align-middle">
                    {{ order.comment }}
                  </td>
                  <td class="text-center align-middle">
                    <i
                      style="margin-right: 5px"
                      class="bi bi-calendar3 hoverable"
                      *ngIf="order && order.isReccurence"
                    >
                    </i>
                    <i
                      class="bi bi-info-circle hoverable"
                      *ngIf="order && !order.parentId"
                      (click)="loadDataForPopUp(order)"
                      data-bs-toggle="modal"
                      data-bs-target="#infomodal"
                    >
                      <span style="color: #ffeb36">{{
                        order?.familyNumber
                      }}</span></i
                    >
                    <i
                      class="bi bi-person ms-2"
                      *ngIf="order && !order.parentId && !order.isOrder"
                    >
                    </i>
                    <i
                      *ngIf="order && order.parentId"
                      (click)="loadDataForPopUp(order)"
                      data-bs-toggle="modal"
                      data-bs-target="#infomodal"
                      class="bi bi-link-45deg linked-ico hoverable"
                    >
                      <span style="color: #ffeb36">{{
                        order?.familyNumber
                      }}</span></i
                    >
                  </td>
                  <td class="text-center align-middle">
                    <i
                      class="bi bi-x-square hoverable"
                      *ngIf="order"
                      (click)="deleteConfirmation = order"
                    ></i>
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <ng-container
        *ngFor="
          let driver of sortDriversByOrders(
            (pageDrivers ? pageDrivers : driversFixed)!
          )
        "
      >
        <app-spinner *ngIf="ordersRepo.isLoading$ | async"></app-spinner>
        <div
          *ngIf="!(ordersRepo.isLoading$ | async)"
          (drop)="updateDriver()"
          (dragover)="getDriver(driver)"
        >
          <h2 class="mt-4 ms-3">
            <span i18n="@@driverCard">Driver </span>{{ driver?.name }}
            {{ driver?.surname }}
          </h2>

          <div
            class="mt-3 ms-2 card card-shadow btn-lg text-white px-0 py-0"
            (dragover)="getDriver(driver)"
          >
            <div class="table-responsive tableFixHeadCommon">
              <table class="table enableScrol table-bordered my-0">
                <thead class="bg-blank">
                  <tr>
                    <th i18n="@@successDate" class="text-center align-middle">
                      Start Date
                    </th>
                    <th i18n="@@successStart" class="text-center align-middle">
                      Start Time
                    </th>
                    <th i18n="@@endDate" class="text-center align-middle">
                      End Date
                    </th>
                    <th i18n="@@endTime" class="text-center align-middle">
                      End Time
                    </th>
                    <th i18n="@@successClient" class="text-center align-middle">
                      Client
                    </th>
                    <th i18n="@@successWeight" class="text-center align-middle">
                      Weight
                    </th>
                    <th
                      i18n="@@successProduct"
                      class="text-center align-middle"
                    >
                      Product
                    </th>
                    <th
                      i18n="@@successReference"
                      class="text-center align-middle"
                    >
                      Reference
                    </th>
                    <th i18n="@@openComment" class="text-center align-middle">
                      Comment
                    </th>
                    <th i18n="@@successTime" class="text-center align-middle">
                      Time
                    </th>
                    <th i18n="@@successInfo" class="text-center align-middle">
                      Trip info
                    </th>
                    <th i18n="@@openDelete" class="text-center align-middle">
                      Delete
                    </th>
                  </tr>
                </thead>
                <!--<tbody class="bordered-success">
                  <tr
                    *ngFor="
                      let order of checkFinished(orders, driver.id);
                      let i = index
                    "
                  >
                    <ng-container
                      class="ms-4"
                      *ngIf="order && order.userId === driver.id"
                    >
                      <td class="text-center align-middle">
                        <span *ngIf="order && order.plannedToStartAt">{{
                          toCorrectDate(order.plannedToStartAt, "day")
                        }}</span>
                      </td>
                      <td class="text-center align-middle">
                        <span *ngIf="order && order.plannedToStartAt">{{
                          toCorrectDate(order.plannedToStartAt, "time")
                        }}</span>
                      </td>
                      <td class="text-center align-middle">
                        {{ order?.client?.name }}
                      </td>
                      <td class="text-center align-middle">
                        {{ order.weight }}
                      </td>
                      <td class="text-center align-middle">
                        {{ getProducts(order?.products) }}
                      </td>
                      <td class="text-center align-middle">
                        {{ order.reference }}
                      </td>
                      <td class="text-center align-middle">
                        {{ calculateDuration(order) }}
                      </td>
                      <td class="text-center align-middle">
                        <i
                          class="bi bi-info-circle hoverable"
                          *ngIf="order"
                          (click)="loadDataForPopUp(order)"
                          data-bs-toggle="modal"
                          data-bs-target="#infomodal"
                        ></i>
                      </td>
                      <td class="text-center align-middle"></td>
                    </ng-container>
                  </tr>
                </tbody>-->
                <tbody class="bordered-warning">
                  <tr
                    *ngFor="
                      let order of checkStarted(orders, driver.id);
                      let i = index
                    "
                  >
                    <ng-container
                      class="ms-4"
                      *ngIf="order.userId === driver.id"
                    >
                      <td class="text-center align-middle">
                        <span *ngIf="order && order.plannedToStartAt">{{
                          toCorrectDate(order.plannedToStartAt, "day")
                        }}</span>
                      </td>
                      <td class="text-center align-middle">
                        <span *ngIf="order && order.plannedToStartAt">{{
                          toCorrectDate(order.plannedToStartAt, "time")
                        }}</span>
                      </td>

                      <td class="text-center align-middle">
                        <span *ngIf="order && order.plannedToEndAt">{{
                          toCorrectDate(order.plannedToEndAt, "day")
                        }}</span>
                      </td>
                      <td class="text-center align-middle">
                        <span *ngIf="order && order.plannedToEndAt">{{
                          toCorrectDate(order.plannedToEndAt, "time")
                        }}</span>
                      </td>

                      <td class="text-center align-middle">
                        {{ order?.client?.name }}
                      </td>
                      <td class="text-center align-middle">
                        {{ order.weight }}
                      </td>
                      <td class="text-center align-middle">
                        {{ getProducts(order?.products) }}
                      </td>
                      <td class="text-center align-middle">
                        {{ order.reference }}
                      </td>
                      <td class="text-center align-middle">
                        {{ order.comment }}
                      </td>
                      <td class="text-center align-middle">
                        {{ calculateDuration(order) }}
                      </td>
                      <td class="text-center align-middle">
                        <i
                          style="margin-right: 5px"
                          class="bi bi-calendar3 hoverable"
                          *ngIf="order && order.isReccurence"
                        >
                        </i>
                        <i
                          class="bi bi-info-circle hoverable"
                          *ngIf="order && !order.parentId"
                          (click)="loadDataForPopUp(order)"
                          data-bs-toggle="modal"
                          data-bs-target="#infomodal"
                        >
                          <span style="color: #ffeb36">{{
                            order?.familyNumber
                          }}</span></i
                        >
                        <i
                          class="bi bi-person ms-2"
                          *ngIf="order && !order.parentId && !order.isOrder"
                        >
                        </i>
                        <i
                          *ngIf="order && order.parentId"
                          (click)="loadDataForPopUp(order)"
                          data-bs-toggle="modal"
                          data-bs-target="#infomodal"
                          class="bi bi-link-45deg linked-ico hoverable"
                        >
                          <span style="color: #ffeb36">{{
                            order?.familyNumber
                          }}</span></i
                        >
                      </td>
                      <td class="text-center align-middle"></td>
                    </ng-container>
                  </tr>
                </tbody>
                <tbody class="bg-violet">
                  <tr
                    *ngFor="
                      let order of checkParked(orders, driver.id);
                      let i = index
                    "
                  >
                    <ng-container
                      class="ms-4"
                      *ngIf="order.userId === driver.id"
                    >
                      <td class="text-center align-middle">
                        <span *ngIf="order && order.plannedToStartAt">{{
                          toCorrectDate(order.plannedToStartAt, "day")
                        }}</span>
                      </td>
                      <td class="text-center align-middle">
                        <span *ngIf="order && order.plannedToStartAt">{{
                          toCorrectDate(order.plannedToStartAt, "time")
                        }}</span>
                      </td>
                      <td class="text-center align-middle">
                        <span *ngIf="order && order.plannedToEndAt">{{
                          toCorrectDate(order.plannedToEndAt, "day")
                        }}</span>
                      </td>
                      <td class="text-center align-middle">
                        <span *ngIf="order && order.plannedToEndAt">{{
                          toCorrectDate(order.plannedToEndAt, "time")
                        }}</span>
                      </td>
                      <td class="text-center align-middle">
                        {{ order?.client?.name }}
                      </td>
                      <td class="text-center align-middle">
                        {{ order.weight }}
                      </td>
                      <td class="text-center align-middle">
                        {{ getProducts(order?.products) }}
                      </td>
                      <td class="text-center align-middle">
                        {{ order.reference }}
                      </td>
                      <td class="text-center align-middle">
                        {{ order.comment }}
                      </td>
                      <td
                        class="d-flex align-items-center justify-content-center"
                      >
                        <div>{{ calculateDurationForParked(order) }}</div>
                        <i class="bi bi-pause strong"></i>
                      </td>
                      <td class="text-center align-middle">
                        <i
                          style="margin-right: 5px"
                          class="bi bi-calendar3 hoverable"
                          *ngIf="order && order.isReccurence"
                        >
                        </i>
                        <i
                          class="bi bi-cone-striped hoverable me-1"
                          (click)="openDriversModal(order)"
                        ></i>
                        <i
                          class="bi bi-info-circle hoverable"
                          *ngIf="order && !order.parentId"
                          (click)="loadDataForPopUp(order)"
                          data-bs-toggle="modal"
                          data-bs-target="#infomodal"
                        >
                          <span style="color: #ffeb36">{{
                            order?.familyNumber
                          }}</span></i
                        >
                        <i
                          class="bi bi-person ms-2"
                          *ngIf="order && !order.parentId && !order.isOrder"
                        >
                        </i>
                        <i
                          *ngIf="order && order.parentId"
                          (click)="loadDataForPopUp(order)"
                          data-bs-toggle="modal"
                          data-bs-target="#infomodal"
                          class="bi bi-link-45deg linked-ico hoverable"
                        >
                          <span style="color: #ffeb36">{{
                            order?.familyNumber
                          }}</span></i
                        >
                      </td>
                      <td class="text-center align-middle"></td>
                    </ng-container>
                  </tr>
                </tbody>
                <tbody class="blood-red">
                  <tr
                    [class.green-backg]="
                      toCorrectDate(order.plannedToStartAt, 'day') ==
                      toCorrectDate(today, 'day')
                    "
                    [class.blue-backg]="
                      toCorrectDate(order.plannedToEndAt, 'day') ==
                        toCorrectDate(tommorow, 'day') &&
                      !(
                        toCorrectDate(order.plannedToStartAt, 'day') ==
                        toCorrectDate(today, 'day')
                      )
                    "
                    *ngFor="
                      let order of checkNotStarted(orders, driver.id);
                      let i = index
                    "
                    [draggable]="!order.parentId"
                    [class.hoverable]="!order.parentId"
                    [class.grabbable]="!order.parentId"
                    [class.child-grey]="order.parentId"
                    (drag)="getOrder(order); getLastDriver(driver)"
                  >
                    <ng-container class="ms-4">
                      <td class="text-center align-middle">
                        <span *ngIf="order && order.plannedToStartAt">{{
                          toCorrectDate(order.plannedToStartAt, "day")
                        }}</span>
                      </td>
                      <td class="text-center align-middle">
                        <span *ngIf="order && order.plannedToStartAt">{{
                          toCorrectDate(order.plannedToStartAt, "time")
                        }}</span>
                      </td>
                      <td class="text-center align-middle">
                        <span *ngIf="order && order.plannedToEndAt">{{
                          toCorrectDate(order.plannedToEndAt, "day")
                        }}</span>
                      </td>
                      <td class="text-center align-middle">
                        <span *ngIf="order && order.plannedToEndAt">{{
                          toCorrectDate(order.plannedToEndAt, "time")
                        }}</span>
                      </td>
                      <td class="text-center align-middle">
                        {{ order?.client?.name }}
                      </td>
                      <td class="text-center align-middle">
                        {{ order.weight }}
                      </td>
                      <td class="text-center align-middle">
                        {{ getProducts(order?.products) }}
                      </td>
                      <td class="text-center align-middle">
                        {{ order.reference }}
                      </td>
                      <td class="text-center align-middle">
                        {{ order.comment }}
                      </td>
                      <td class="text-center align-middle">
                        {{ calculateDuration(order) }}
                      </td>
                      <td class="text-center align-middle">
                        <i
                          style="margin-right: 5px"
                          class="bi bi-calendar3 hoverable"
                          *ngIf="order && order.isReccurence"
                        >
                        </i>
                        <i
                          class="bi bi-info-circle hoverable"
                          *ngIf="order && !order.parentId"
                          (click)="loadDataForPopUp(order)"
                          data-bs-toggle="modal"
                          data-bs-target="#infomodal"
                        >
                          <span style="color: #ffeb36">{{
                            order?.familyNumber
                          }}</span></i
                        >
                        <i
                          class="bi bi-person ms-2"
                          *ngIf="order && !order.parentId && !order.isOrder"
                        >
                        </i>
                        <i
                          *ngIf="order && order.parentId"
                          (click)="loadDataForPopUp(order)"
                          data-bs-toggle="modal"
                          data-bs-target="#infomodal"
                          class="bi bi-link-45deg linked-ico hoverable"
                        >
                          <span style="color: #ffeb36">{{
                            order?.familyNumber
                          }}</span></i
                        >
                      </td>
                      <td class="text-center align-middle">
                        <i
                          class="bi bi-x-square hoverable"
                          *ngIf="order"
                          (click)="
                            deleteConfirmation = order;
                            deleteForDriverId = driver.id;
                            isMultipleDrivers = order.driverIds!.length > 1
                          "
                        ></i>
                      </td>
                    </ng-container>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    id="infomodal"
    tabindex="-1"
    aria-labelledby="infomodal"
    aria-hidden="true"
    (onHidden)="resetChild(false, true)"
  >
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content bg-lights border-0">
        <div class="modal-header">
          <h6
            class="modal-title"
            *ngIf="!previous"
            id="infomodalLabel"
            i18n="Title for infomodal window"
          >
            Order Info
          </h6>
          <h6
            class="modal-title"
            *ngIf="previous"
            id="infomodalLabel"
            i18n="Title for infomodal optional window"
          >
            Linked to order:
            <span>
              [ {{ previous.client.name }} -
              {{ toCorrectDate(previous.plannedToStartAt) }} ]
            </span>
          </h6>
          <button
            type="button"
            class="btn-close"
            (click)="orderUpdate = null; resetChild(false, true)"
            #closeModal
            data-bs-dismiss="modal"
            aria-label="Close"
            i18n-aria-label="Close label @@close"
          ></button>
        </div>
        <div class="modal-body">
          <div #container class="form-floating mb-3">
            <!-- <app-order-form
              *ngIf="testIf"
              id="orderForm"
              (orderEmiter)="createUpdateOrder($event)"
              (addLinkedEmiter)="addLinkedTrip($event)"
              [orderUpdate]="orderUpdate"
              [drivers]="usersRepo.all$ | async"
              [accessories]="accessoryRepo.all$ | async"
              [cleanings]="cleaningRepo.all$ | async"
              [companies]="companiesT"
              [orders]="ordersRepo.orders$ | async"
              [products]="test"
              [companiesDeleted]="companiesTD"
              [productsDeleted]="eproductRepo.allDeleted$ | async"
              [DriversDisabled]="previous !== null && previous !== undefined"
            ></app-order-form> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<app-confirm-delete
  *ngIf="
    deleteConfirmation &&
    deleteConfirmation.client &&
    deleteConfirmation.client.name
  "
  [deleteCandidate]="'[' + ORDER_FOR + deleteConfirmation.client.name + '] '"
  (confirm)="
    delete(deleteConfirmation.id, deleteForDriverId, $event);
    deleteConfirmation = null;
    deleteForDriverId = null
  "
  [isMultipleDrivers]="isMultipleDrivers"
  (cancel)="deleteConfirmation = null"
  [isReccurence]="deleteConfirmation?.isReccurence ?? false"
></app-confirm-delete>

<app-trip-change-driver-modal
  *ngIf="parkedOrder"
  [drivers]="driversM"
  [clientName]="parkedOrder.client?.name"
  [currentId]="parkedOrder.userId"
  [date]="toCorrectDate(parkedOrder.plannedToStartAt)"
  (confirm)="updateParkedTrip($event)"
  (cancel)="parkedOrder = null"
>
</app-trip-change-driver-modal>
