<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()" enctype="multipart/form-data">
  <div class="row">
    <div class="col-12 col-md-6 col-lg-4">
      <div [appValidateState]="form.get('name')" class="form-group form-floating mb-3">
        <input formControlName="name" type="text" class="form-control"
               placeholder="Tenant name" i18n-placeholder="Tenant name placeholder @@tenantName"/>
        <label for="name" i18n="@@tenantName">Tenant name</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input formControlName="cvr" type="text" class="form-control"
               placeholder="CVR number" i18n-placeholder="Tenant CVR number placeholder @@cvr"/>
        <label for="name" i18n="@@cvr">CVR number</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input formControlName="address" type="text" class="form-control"
               placeholder="Tenant address" i18n-placeholder="Tenant address placeholder @@tenaddress"/>
        <label for="address" i18n="@@tenantaddress">Address</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div [appValidateState]="form.get('maxAdministrators')" class="form-group form-floating mb-3">
        <input formControlName="maxAdministrators" type="number" class="form-control"
               placeholder="Max Administrators" i18n-placeholder="Max Administrators placeholder @@maxAdministrators"/>
        <label for="maxAdministrators" i18n="@@maxAdministrators">Max Administrators</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div [appValidateState]="form.get('maxDrivers')" class="form-group form-floating mb-3">
        <input formControlName="maxDrivers" type="number" class="form-control"
               placeholder="Max Drivers" i18n-placeholder="Max Drivers number placeholder @@maxDrivers"/>
        <label for="maxDrivers" i18n="@@maxDrivers">Max Drivers</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input formControlName="mainMail" type="text" class="form-control"
               placeholder="Main mail" i18n-placeholder="Main mail placeholder @@mainMail"/>
        <label for="mainMail" i18n="@@mainMail">Main mail</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <textarea rows="4" style="height:100%;" formControlName="remarks" type="text" class="form-control "
               placeholder="Tenant Remarks" i18n-placeholder="Tenant remarks placeholder @@tenremarks"></textarea>
        <label for="remarks" i18n="@@tenantremarks">Remarks</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input formControlName="phoneNumber" type="text" class="form-control"
               placeholder="Phone number" i18n-placeholder="Tenant phone number placeholder @@phoneNumber"/>
        <label for="phoneNumber" i18n="@@phoneNumber">Phone number</label>
      </div>
      <div class="form-group form-floating mb-3">
        <input formControlName="image" type="file" class="form-control"
               placeholder="Tenant picture" i18n-placeholder="Tenant picture placeholder @@tenantPicture"/>
        <label for="image" i18n="@@tenantPicture">Tenant picture</label>
      </div>      
    </div>    
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <app-datalist-select class="app-datalist-select-double-height"
                             [options]="featureOptions"
                             [multiple]="true"
                             [activeValue]="form.value.featureIds"
                             (activeValueChange)="updateValue('featureIds', $event)"
                             label="Features" i18n-label="Features label"></app-datalist-select>
      </div>
      
    </div>
    <div class="col-12 col-md-6 col-lg-6">
      <div class="form-group form-floating mb-3">
        <input formControlName="appSecretToken" type="text" class="form-control"
               placeholder="Economic secret" i18n-placeholder="Economic secret placeholder @@appSecretTokenPlaceholder"/>
        <label for="appSecretToken" i18n="@@appSecretToken">Economic Secret Token</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-6">
      <div class="form-group form-floating mb-3">
        <input formControlName="agreementGrantToken" type="text" class="form-control"
               placeholder="Economic Agreement Token" i18n-placeholder="Economic secret placeholder @@agreementGrantToken"/>
        <label for="agreementGrantToken" i18n="@@agreementGrantToken">Economic Agreement Token</label>        
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input formControlName="layoutNumber" type="number" class="form-control"
               placeholder="Layout Number" i18n-placeholder="layoutNumber placeholder @@layoutNumber"/>
        <label for="layoutNumber" i18n="@@layoutNumber">Layout Number</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4"></div>
    <div class="col-12 col-md-6 col-lg-4"></div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating pb-3 d-grid">
        <button type="submit" class="btn btn-primary shadow-sm" i18n="Save button @@save">Save</button>
      </div>
    </div>
  </div>
</form>
