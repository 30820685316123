import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { sortBy } from 'src/app/modules/shared/pipes/sort.pipe';
import { UserSettings } from 'src/app/state/auth.repository';
import { Order } from 'src/app/state/orders.repository';
import { Trip, TripEvent } from 'src/app/state/trips.repository';
import { Workday } from 'src/app/state/workdays.repository';
const ADD_NOTE_TITLE = $localize`:Add notete title:Add note`;
@Component({
  selector: 'app-end-day-form',
  templateUrl: './end-day-form.component.html',
  styleUrls: ['./end-day-form.component.scss'],
})
export class EndDayFormComponent implements OnInit {
  noteTitle = ADD_NOTE_TITLE;
  text: string | undefined = undefined;
  title: string | null = null;
  @Input() set currentTrip(value: Trip | Order | null) {
    this.trip = value;
    if (this.trip && value) {
      this.tripStartEvent = sortBy(
        value.tripEvents.filter((x) => x.type !== 'Weighing'),
        { parameter: { property: 'createdAt' }, direction: 'desc' }
      )[0];

      if (this.tripStartEvent && this.tripStartEvent.note) {
        this.text = this.tripStartEvent.note;
      }
    }
  }
  trip?: Trip | Order | null;
  tripStartEvent?: TripEvent | null;
  tripStart: Date | null = null;
  @Input() isTripActive = false;
  @Input() isLoading: boolean | null = null;
  @Input() set currentDay(value: Workday | null) {
    this.dayStarted = value?.workdayEvents.find(
      (x) => x.type === 'DayStart'
    )?.eventTime;
  }
  @Input() userSettings?: UserSettings | null;
  @Output() endDay = new EventEmitter<void>();
  @Output() parkTrip = new EventEmitter<string>();
  @Output() pauseDay = new EventEmitter<number | null>();
  @Output() makeNote = new EventEmitter<string>();
  dayStarted?: Date;
  pauseForm?: UntypedFormGroup;
  @ViewChild('opennote') opennote!: ElementRef;
  @ViewChild('closeModal') closeModal!: ElementRef;

  constructor(private formBuilder: UntypedFormBuilder) {}
  innerWidth = 0;
  ngOnInit(): void {
    this.pauseForm = this.formBuilder.group({
      pause: [
        this.userSettings?.defaultPause || 0,
        [Validators.required, Validators.min(0)],
      ],
    });
    this.innerWidth = window.innerWidth;
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  onPauseSubmit() {
    this.pauseDay.emit(this.pauseForm?.value.pause || null);
    this.closeModal.nativeElement.click();
  }

  singleTextBoxOppened: boolean = false;

  openNote() {
    this.title = this.noteTitle;
    if (this.tripStartEvent && this.tripStartEvent.note && !this.text) {
      this.text = this.tripStartEvent.note;
    }
    this.singleTextBoxOppened = true;
  }

  cancelAddNote() {
    this.text = undefined;
    this.title = null;
    this.singleTextBoxOppened = false;
  }

  addNote($event: string) {
    this.makeNote.emit($event);
    this.text = $event;
    if (this.tripStartEvent && this.tripStartEvent.note && !this.text[0]) {
      this.text = this.tripStartEvent.note;
    }
    this.cancelAddNote();
  }
}
