import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { ConnectionService } from 'src/app/modules/shared/services/connection.service';
import { DayjsService } from 'src/app/modules/shared/services/dayjs.service';
import { LocationService } from 'src/app/modules/shared/services/location.service';
import { RequestsResolverRunner } from 'src/app/modules/shared/services/requests-resolver';
import { AuthRepository } from 'src/app/state/auth.repository';
import { Order, OrdersRepository } from 'src/app/state/orders.repository';
import { OrdersService } from 'src/app/state/orders.service';
import { TripsRepository } from 'src/app/state/trips.repository';
import {
  Workday,
  WorkdayEvent,
  WorkdaysRepository,
} from 'src/app/state/workdays.repository';

@Component({
  selector: 'app-navigation-page',
  templateUrl: './navigation-page.component.html',
  styleUrls: ['./navigation-page.component.scss'],
})
export class NavigationPageComponent implements OnInit {
  dataSubscription?: Subscription;
  submitErrors: string[] | null = null;
  contactPerson = '';
  id?: string | null = null;
  order: Order | null = null;
  orderSub?: Subscription;
  eventid: string | undefined;
  isAdmin: 'true' | 'false' = 'false';
  order$?: Observable<Order | undefined> | null;
  orderId?: string;
  activeOrder: Order | null = null;
  activeOrderVehicle: string | null = null;
  activeOrderTrailer: string | null = null;

  images: string[] = [];
  imageIndex: number = 0;
  isActionResolved = false;

  delivery: any;
  picUp: any;
  trip: any;
  isTripActive: any;
  activeOrder2: any;
  isOrderActive: any;
  settings: any;
  vehicleError: boolean = false;
  workDay: Workday | undefined;
  dayPause: WorkdayEvent | undefined | null = null;

  constructor(
    public isOnlineService: ConnectionService,
    public authRepo: AuthRepository,
    private router: Router,
    private route: ActivatedRoute,
    private ordersService: OrdersService,
    public ordersRepo: OrdersRepository,
    public ngDay: DayjsService,
    private locationService: LocationService,
    private offlineResolver: RequestsResolverRunner,
    private tripRepo: TripsRepository,
    public dayRepo: WorkdaysRepository
  ) {
    this.dayRepo.activeWorkday$.subscribe((x) => {
      this.workDay = x;
    });
    this.dayRepo.activePause$.subscribe((x) => {
      this.dayPause = x;
    });
    this.route.paramMap.subscribe((x) => (this.id = x.get('id')));
    if (this.id) {
      this.orderSub = this.ordersRepo.one(this.id).subscribe((a) => {
        if (a) {
          this.order = a;
          a?.contactPerson ? (this.contactPerson = a.contactPerson) : '';
        }
      });
    }
    this.tripRepo.activeTrip$.subscribe((a) => {
      this.trip = a;
      this.isTripActive = !!this.trip;
    });
    this.ordersRepo.activeOrder$.subscribe((a) => {
      this.activeOrder2 = a;
      this.isOrderActive = !!(
        this.activeOrder2 && this.activeOrder2.tripEvents.length > 0
      );
    });
    authRepo.settings$.subscribe((a) => (this.settings = a));
  }
  ngOnDestroy(): void {
    this.dataSubscription?.unsubscribe();
    this.orderSub?.unsubscribe();
  }

  ngOnInit(): void {
    if (this.isOnlineService.isOnline()) {
      this.ordersService.loadForCurrentDriver().subscribe();
    }
    this.route.paramMap.subscribe((x) => (this.id = x.get('id')));
    if (this.id) {
      this.orderSub = this.ordersRepo.one(this.id).subscribe((a) => {
        if (a) {
          this.order = a;
          a?.contactPerson ? (this.contactPerson = a.contactPerson) : '';
        }
      });
    }
    this.dataSubscription = this.route.paramMap
      .pipe(this.offlineResolver.useUnsyncedChanges())
      .subscribe(() => {
        const order = this.ordersRepo.getActiveOrder();
        this.mergeNamePlate(order);
        this.isActionResolved = true;
      });
  }

  launchStartOrder(id: string) {
    if (!this.settings?.vehicleId) {
      this.vehicleError = true;
      return;
    }
    this.navigateToOrderAction(id, 'TripStart');
  }

  navigateToOrderAction(id: string, action: string) {
    if (action === 'TripStart') {
      this.router.navigate([`order/${id}/start`]);
    }
    if (action === 'Load') {
      this.router.navigate([`order/${id}/load`]);
    }
    if (action === 'Unload') {
      this.router.navigate([`order/${id}/unload`]);
    }
    if (action === 'TripEnd') {
      this.router.navigate([`order/${id}/stop`]);
    }
  }

  getProducts() {
    let str = '';
    if (this.order?.products) var length = this.order?.products.length ?? 0;
    this.order?.products!.forEach((element, index) => {
      str += element.name;
      if (length - 1 !== index) {
        str += ', ';
      }
    });
    return str;
  }

  mergeNamePlate(order?: Order | null) {
    if (!order) {
      return;
    }
    this.activeOrder = order;
    this.activeOrderVehicle =
      order && order.vehicle
        ? `[${order.vehicle.numberplate}] ${order.vehicle.name}`
        : null;
    this.activeOrderTrailer =
      order && order.trailer
        ? `[${order.trailer.numberplate}] ${order.trailer.name}`
        : null;
  }

  toCorrectDate(date: Date, type?: string) {
    if (type === 'day') {
      return this.ngDay.dayjs
        .utc(date)
        .local()
        .format('DD/MM/YYYY')
        .slice(0, 16);
    }
    if (type === 'time') {
      return this.ngDay.dayjs.utc(date).local().format('H:mm').slice(0, 16);
    } else {
      return this.ngDay.dayjs
        .utc(date)
        .local()
        .format('H:mm DD/MM/YYYY')
        .slice(0, 16);
    }
  }

  openFile(path: string) {
    window.open(path, '_blank');
  }

  iphoneHref: string | undefined = undefined;

  redirectToAddressOnMaps(destination?: string) {
    if (!destination) {
      return;
    }
    const p = 'DK';
    var coordinates = this.locationService.getPosition();
    coordinates.then((x) => {
      setTimeout(() => {
        this.iphoneHref =
          `https://www.google.com/maps/dir/?api=1&origin=${x.latitude.valueOf()},${x.longitude.valueOf()}` +
          '&destination=' +
          destination +
          '&travelmode=driving';
        window.open(
          `https://www.google.com/maps/dir/?api=1&origin=${x.latitude.valueOf()},${x.longitude.valueOf()}` +
            '&destination=' +
            destination +
            '&travelmode=driving',
          '_blank'
        );

        document.getElementById('iphone_redirect')?.click();
      }, 100);
    });
  }

  openImageModal(index: number) {
    if (this.order?.commentPhotos) {
      this.images = this.order?.commentPhotos?.map((x) => x.imagePath);
      this.imageIndex = index;
    }
  }

  closeImageSlider() {
    this.images = [];
    this.imageIndex = 0;
  }
}
