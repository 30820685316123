<div
  class="modal fade"
  bsModal
  [config]="{ show: true }"
  id="officeNoteModal"
  tabindex="-1"
  #officeNoteModal
  aria-hidden="true"
  (onHidden)="cancel.emit()"
>
  <div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content bg-lights border-0">
      <div class="modal-header">
        <h6 class="modal-title" id="officeNoteModalLabel" i18n="editOfficeNote">
          Edit office note
        </h6>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          i18n-aria-label="Close label @@close"
          (click)="cancel.emit()"
        ></button>
      </div>
      <div class="modal-body">
        <div class="modal-body">
          <div class="row mb-3" *ngIf="endNote">
            <div class="form-group form-floating mb-3">
              <textarea
                readonly
                id="textbox"
                [innerHtml]="endNote"
                class="form-control"
              ></textarea>
              <label class="ms-2" for="driverNote" i18n="driverNote"
                >Driver note</label
              >
            </div>
          </div>

          <div class="row mb-3">
            <div class="form-group form-floating mb-3">
              <textarea
                id="textbox"
                [(ngModel)]="officeNote"
                class="form-control"
              ></textarea>
              <label class="ms-2" for="officeNote" i18n="officeNote"
                >Office note</label
              >
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <button
            class="btn btn-primary w-100"
            (click)="save()"
            i18n="Save button"
          >
            Save
          </button>
          <button
            class="btn btn-secondary w-100"
            (click)="cancel.emit()"
            i18n="@@cancel"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
