<div class="row mt-1">
  <div class="col-12">
    <div class="card shadow-md mb-4">
      <ul class="list-group list-group-flush">
        <app-model-card *ngFor="let trailer of trailers"
          [title]="trailer.numberplate"
          [subtitle]="trailer.name"
          icon="bi-minecart"
          [date]="trailer.createdAt"
          [id]="trailer.id"
          routerLinkBase="/trailers"
          [isDeleteDisabled]="trailer.isBeingUsed"
          (deleteClick)="deleteConfirmation = trailer"></app-model-card>
      </ul>
    </div>
  </div>
</div>
<app-confirm-delete *ngIf="deleteConfirmation"
  [deleteCandidate]="'[' + deleteConfirmation.numberplate + '] ' + deleteConfirmation.name"
  (confirm)="delete.emit(deleteConfirmation.id);deleteConfirmation = null;"
  (cancel)="deleteConfirmation = null"></app-confirm-delete>