import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface SimpleCardAction {
  routerLink: string | any[];
  text: string;
  icon?: string;
}

@Component({
  selector: 'app-simple-card',
  templateUrl: './simple-card.component.html',
  styleUrls: ['./simple-card.component.scss']
})
export class SimpleCardComponent implements OnInit {
  @Input() image: string | undefined;
  @Input() title: string | undefined;
  @Input() actions: SimpleCardAction[] = [];
  @Output() ctaClick = new EventEmitter<Event>();

  constructor() { }

  ngOnInit(): void {
  }

}
