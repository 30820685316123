<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-12 col-md-6 col-lg-4">
      <div
        [appValidateState]="form.get('clientId')"
        class="form-group form-floating mb-3"
      >
        <app-datalist-select
          [isDisabled]="action === 'takephoto'"
          [optionsDeleted]="delCompanies"
          [options]="clientOptions"
          [activeValue]="form.value.clientId"
          (activeValueChange)="updateValue('clientId', $event)"
          label="Client"
          i18n-label="Client label"
        ></app-datalist-select>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4" *ngIf="locationCheck()">
      <fieldset [disabled]="action === 'takephoto'">
        <div class="form-group form-floating mb-3">
          <div class="input-group form-floating">
            <input
              ngx-google-places-autocomplete
              [options]="myOptions"
              #placePickRef="ngx-places"
              (onAddressChange)="handleAddressChange($event, 'pickUpAddress')"
              formControlName="pickUpAddress"
              aria-label="pickUpAddress"
              aria-describedby="pickUpAddressButton"
              class="form-control"
              [class.isDisabled]="
                action !== 'start' &&
                action !== 'stop' &&
                action !== 'unload' &&
                action !== 'load'
              "
            />
            <label
              class="visible"
              for="pickUpAddress"
              i18n="pickUpAddress label"
              >PickUp Address</label
            >
            <button
              class="btn btn-outline-secondary"
              [disabled]="
                !form.value.pickUpAddress || form.value.pickUpAddress.length < 5
              "
              type="button"
              (click)="redirectToAddressOnMaps(form.value.pickUpAddress)"
              id="pickUpAddressButton"
            >
              <i class="bi bi-geo-alt-fill strong danger-text"></i>
            </button>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="col-12 col-md-6 col-lg-4" *ngIf="locationCheck()">
      <fieldset [disabled]="action === 'takephoto'">
        <div class="form-group form-floating mb-3">
          <div class="input-group form-floating">
            <input
              ngx-google-places-autocomplete
              [options]="myOptions"
              #placeDelRef="ngx-places"
              (onAddressChange)="handleAddressChange($event, 'deliveryAddress')"
              formControlName="deliveryAddress"
              aria-label="deliveryAddress"
              aria-describedby="deliveryAddressButton"
              class="form-control"
              [class.isDisabled]="
                action !== 'start' &&
                action !== 'stop' &&
                action !== 'unload' &&
                action !== 'load'
              "
            />
            <label
              class="visible"
              for="deliveryAddress"
              i18n="deliveryAddress label"
              >Delivery Address</label
            >
            <button
              class="btn btn-outline-secondary"
              [disabled]="
                !form.value.deliveryAddress ||
                form.value.deliveryAddress.length < 5
              "
              type="button"
              (click)="redirectToAddressOnMaps(form.value.deliveryAddress)"
              id="deliveryAddressButton"
            >
              <i class="bi bi-geo-alt-fill strong danger-text"></i>
            </button>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <fieldset [disabled]="action === 'takephoto'">
        <div
          [appValidateState]="form.get('km')"
          class="form-group form-floating mb-3"
        >
          <input
            formControlName="km"
            type="number"
            (keydown)="validateInput($event)"
            class="form-control"
            [class.isDisabled]="action === 'takephoto'"
          />
          <label for="km" i18n="Odometer kilometres label">Odometer, km</label>
        </div>
      </fieldset>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <fieldset [disabled]="action === 'takephoto'">
        <div
          [appValidateState]="form.get('weight')"
          class="form-group form-floating mb-3"
        >
          <input
            formControlName="weight"
            type="text"
            (keydown)="validateInput($event)"
            class="form-control"
            [class.isDisabled]="action === 'takephoto'"
          />
          <label for="km" i18n="Weight label">Weight, kg/m<sup>3</sup></label>
        </div>
      </fieldset>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <fieldset [disabled]="action === 'takephoto'">
        <div class="form-group form-floating mb-3">
          <input
            formControlName="reference"
            type="text"
            class="form-control"
            [class.isDisabled]="action === 'takephoto'"
          />
          <label for="km" i18n="Reference label">Reference</label>
        </div>
      </fieldset>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <fieldset [disabled]="action === 'takephoto'">
        <div class="form-group form-floating mb-3">
          <input
            formControlName="comment"
            type="text"
            class="form-control"
            [class.isDisabled]="action === 'takephoto'"
          />
          <label for="km" i18n="orderComment label">Comment</label>
        </div>
      </fieldset>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <app-datalist-select
          [options]="vendorOptions"
          [optionsDeleted]="delCompanies"
          [isDisabled]="action === 'takephoto'"
          [activeValue]="form.value.vendorId"
          (activeValueChange)="updateValue('vendorId', $event)"
          label="Vendor"
          i18n-label="Vendor label"
        ></app-datalist-select>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <fieldset [disabled]="action === 'takephoto'">
        <div class="form-group form-floating mb-3">
          <div class="input-group form-floating">
            <input
              formControlName="contactPerson"
              type="text"
              class="form-control"
              [class.isDisabled]="action === 'takephoto'"
              aria-label="contactPersonDial"
              aria-describedby="contactPersonDialButton"
            />
            <label class="visible" for="km" i18n="contactPersonDial label"
              >Contact person</label
            >
            <a
              class="btn btn-outline-secondary"
              href="tel:{{ form.value.contactPerson }}"
              [class.disabled]="
                !form.value.contactPerson || form.value.contactPerson.length < 5
              "
              type="button"
              id="contactPersonDial"
              ><i class="bi bi-phone-vibrate-fill strong success-text"></i
            ></a>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <app-datalist-select
          [options]="cleaningOptions"
          [isDisabled]="action === 'takephoto'"
          [activeValue]="form.value.cleaningId"
          (activeValueChange)="updateValue('cleaningId', $event)"
          label="Cleaning"
          i18n="OrderCleaning label"
        ></app-datalist-select>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <app-datalist-select
          class="app-datalist-select-double-height"
          [options]="productOptions"
          [optionsDeleted]="delProducts"
          [isDisabled]="action === 'takephoto'"
          [multiple]="true"
          [activeValue]="form.value.productIds"
          (activeValueChange)="updateValue('productIds', $event)"
          label="Products"
          i18n-label="Products label"
        ></app-datalist-select>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <app-datalist-select
          [options]="accessoryOptions"
          [optionsDeleted]="delAccessories"
          [isDisabled]="action === 'takephoto'"
          [activeValue]="form.value.accessoryId"
          (activeValueChange)="updateValue('accessoryId', $event)"
          label="Accessory"
          i18n-label="Accessory label"
        ></app-datalist-select>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <fieldset [disabled]="action === 'takephoto'">
        <div class="form-group form-floating mb-3">
          <textarea
            formControlName="note"
            [style.height]="getHeight(form.value.note)"
            class="form-control"
            [class.isDisabled]="action === 'takephoto'"
          ></textarea>
          <label for="km" i18n-label="Note label">Note</label>
        </div>
      </fieldset>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <app-image-uploader [files]="files" [urls]="urls"></app-image-uploader>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <app-image-card [files]="files" [urls]="urls"></app-image-card>
  </div>
  <div class="row">
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating h-100 pb-3 d-grid">
        <button
          type="submit"
          class="btn btn-success shadow-sm"
          [class.disabled]="deletedSelected"
          [disabled]="isLoading || requestInProgress"
        >
          <span *ngIf="!isLoading">
            <ng-container *ngIf="action === 'start'" i18n="Start label"
              >Start</ng-container
            >
            <ng-container *ngIf="action === 'stop'" i18n="End label"
              >End</ng-container
            >
            <ng-container *ngIf="action === 'unload'" i18n="@@unload"
              >Unload</ng-container
            >
            <ng-container *ngIf="action === 'load'" i18n="@@load"
              >Load</ng-container
            >
            <ng-container *ngIf="action === 'takephoto'" i18n="@@takeaphoto"
              >Save</ng-container
            >
          </span>
          <app-spinner *ngIf="isLoading"></app-spinner>
        </button>
      </div>
    </div>
    <div
      class="col-12 col-md-6 col-lg-4"
      *ngIf="action === 'start' || action === 'stop'"
    >
      <div *ngIf="id" class="form-group form-floating h-100 pb-3 d-grid">
        <a
          (click)="rejectOrder.emit(id)"
          class="btn btn-danger shadow-sm"
          [attr.disabled]="isLoading || null"
          [class.disabled]="isLoading"
          i18n="@@rejectOrder"
          >Reject</a
        >
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <!-- *ngIf="orderFamily && order && orderFamily.parentId === order.id" -->
      <div class="form-group form-floating h-100 pb-3 d-grid">
        <a
          [routerLink]="'/go'"
          class="btn btn-secondary shadow-sm"
          [attr.disabled]="isLoading || null"
          [class.disabled]="isLoading"
          i18n="@@cancel"
          >Cancel</a
        >
      </div>
    </div>
  </div>
</form>
