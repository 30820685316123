import { Injectable } from '@angular/core';
import { SortOption, sortBy } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';
import { Accessory } from './accessories.repository';
import { Cleaning } from './cleaning.repository';
import { Company } from './companies.repository';
import { Product } from './products.repository';
import { TripEvent } from './trips.repository';
import { Vehicle } from './vehicles.repository';
import { Trailer } from './trailers.repository';
import {
  withEntities,
  selectAllEntities,
  withActiveId,
  selectActiveEntity,
  setActiveId,
  upsertEntities,
  selectEntity,
  getEntity,
  getActiveEntity,
  deleteAllEntities,
  deleteEntities,
  getAllEntities,
} from '@ngneat/elf-entities';
import {
  withRequestsCache,
  withRequestsStatus,
  createRequestsStatusOperator,
  createRequestsCacheOperator,
  updateRequestCache,
  updateRequestsStatus,
  selectRequestStatus,
} from '@ngneat/elf-requests';
import { createStore } from '@ngneat/elf';
import { map } from 'rxjs/operators';

export interface Order {
  id: string;
  vehicleId?: string | null;
  trailerId?: string | null;
  vehicle?: Vehicle | null;
  trailer?: Trailer | null;
  createdAt?: Date;
  isApproved?: boolean;
  isExported?: boolean;
  tripEvents: TripEvent[];
  userId?: string | null;
  driverIds?: string[] | null;
  userName?: string;
  isOrder?: boolean;
  isRejected?: boolean;
  parentId?: string | null;
  pickUpAddress?: string;
  deliveryAddress?: string;
  plannedToStartAt?: Date;
  plannedToEndAt?: Date;
  startedAt?: Date;
  rejectedAt?: Date;
  contactPerson?: string;
  reference?: string;
  comment?: string;
  note?: string;
  tenantNote?: string;
  clientId?: string;
  vendorId?: string;
  accessoryId?: string;
  cleaningId?: string;
  client?: Company;
  vendor?: Company;
  accessory?: Accessory;
  cleaning?: Cleaning;
  productIds?: string[];
  products?: Product[];
  weight?: number;
  casualUpdate?: boolean;
  familyNumber?: number;
  rejectedById?: string;
  rejectedByName?: string;
  type?: string;
  curTime?: Date;
  parkedAt?: Date;
  resumedAt?: Date | null;
  parkDuration?: number;
  isReccurence?: boolean;
  reccurence?: any;
  commentPhotos?: CommentPhotoDto[];
}

export interface CommentPhotoDto {
  id?: string;
  tripId?: string;
  imagePath: string;
}

export interface OrderFamily {
  parentId: string;
  children: Order[];
}

export interface OrderFilter {
  date_from: Date;
  date_to: Date;
  search: string;
  driverIds: string[];
}

export const ordersSortOptions: SortOption[] = [
  { label: $localize`:Sort label Date:Date`, property: 'plannedToStartAt' },
  {
    label: $localize`:Sort label Description:Description`,
    property: 'description',
  },
];

const store = createStore(
  { name: 'orders' },
  withEntities<Order>(),
  withActiveId(),
  withRequestsCache(),
  withRequestsStatus()
);

export const trackOrdersRequestsStatus = createRequestsStatusOperator(store);
export const skipWhileOrdersCached = createRequestsCacheOperator(store);

@Injectable({ providedIn: 'root' })
export class OrdersRepository {
  name = store.name;

  activeOrder$ = store.pipe(selectActiveEntity()).pipe(
    map((active) => {
      return active;
    })
  );

  lastActiveEvent$ = this.activeOrder$.pipe(
    map(
      (order) =>
        order &&
        sortBy(order.tripEvents, {
          parameter: { property: 'eventTime' },
          direction: 'asc',
        }).pop()
    )
  );
  orders$ = store.pipe(selectAllEntities());

  parkedOrders$ = (id: string) =>
    store
      .pipe(selectAllEntities())
      .pipe(
        map((x) =>
          this.uniqueList(
            x.filter(
              (a) =>
                a.userId === id &&
                ((a.parkedAt && !a.resumedAt) ||
                  (a.parkedAt && a.resumedAt && a.parkedAt > a.resumedAt))
            )
          )
        )
      );

  uniqueList(list: Order[]): Order[] {
    return Array.from(new Set(list.map((item) => item.id)))
      .map((id) => list.find((item) => item.id === id))
      .filter((item) => item !== undefined) as Order[];
  }

  getAllOrders() {
    return store.query(getAllEntities());
  }
  getParkedOrders() {
    return store
      .query(getAllEntities())
      .filter(
        (x) =>
          (x.parkedAt && !x.resumedAt) ||
          (x.parkedAt && x.resumedAt && x.parkedAt > x.resumedAt)
      );
  }
  events$ = this.orders$.pipe(
    map((orders) =>
      sortBy(
        orders.reduce(
          (events, order) => events.concat(order.tripEvents),
          [] as TripEvent[]
        ),
        { parameter: { property: 'eventTime' }, direction: 'desc' }
      )
    )
  );

  clear(): void {
    store.update(deleteAllEntities());
  }

  one = (id: string) => store.pipe(selectEntity(id));
  ordersByUser$ = (id: string) =>
    this.orders$.pipe(
      map((x) =>
        x.filter(
          (a) =>
            (a.driverIds?.find((d) => d === id) || a.userId === id) &&
            !a.isRejected &&
            !a.tripEvents.find((y) => y.type === 'TripEnd')
        )
      )
    );
  getOrdersForNow = (id: string) =>
    this.getAllOrders().filter(
      (a) =>
        (a.driverIds?.includes(id) || a.userId === id) &&
        !a.isRejected &&
        !a.tripEvents.find((y) => y.type === 'TripEnd')
    );

  isLoading$ = store.pipe(
    selectRequestStatus(this.name),
    map((x) => x.value === 'pending')
  );
  isLoadingCurrent$ = store.pipe(
    selectRequestStatus(`${this.name}_current`),
    map((x) => x.value === 'pending')
  );

  isLoadingCurrentDriver$ = store.pipe(
    selectRequestStatus(`${store.name}_current_driver`),
    map((x) => x.value === 'pending')
  );

  isAdding$ = store.pipe(
    selectRequestStatus(`${this.name}_add`),
    map((x) => x.value === 'pending')
  );

  isLoadingOne$ = (id: Order['id']) =>
    store.pipe(
      selectRequestStatus(id),
      map((x) => x.value === 'pending')
    );

  setActiveId(id: Order['id'] | null) {
    store.update(setActiveId(id));
  }

  setOrders(orders: Order[]) {
    store.update(
      updateRequestCache(store.name),
      upsertEntities(orders),
      updateRequestsStatus([store.name], 'success')
    );
  }

  setOrdersForDriver(orders: Order[]) {
    store.update(
      updateRequestCache(`${store.name}_current_driver`),
      upsertEntities(orders),
      updateRequestsStatus([`${store.name}_current_driver`], 'success')
    );
  }

  upsertOrder(order: Order, customStatus?: string) {
    store.update(
      updateRequestCache(order.id),
      upsertEntities([order]),
      updateRequestsStatus([customStatus ?? order.id], 'success')
    );
  }

  getOrder(id: string) {
    return store.query(getEntity(id));
  }

  getActiveOrder() {
    return store.query(getActiveEntity());
  }

  remove(id: string): void {
    store.update(
      updateRequestCache(id),
      deleteEntities(id),
      updateRequestsStatus([id], 'success')
    );
  }
}
