<div class="row mb-2">
  <div class="">
    <div class="bg-none row p-0 m-0">
      <div class="col-6 mb-2 no-outer-border list-group-item flex-fill">
        <div class="row">
          <div class="col-auto pr-0 align-self-center text-end">
            <h2 class="mb-3">
              <i class="bi bi-truck"></i>
            </h2>
          </div>
          <div
            class="col"
            *ngVar="
              activeOrder
                ? activeOrderVehicle
                : (authRepo.settings$ | async)?.vehicleName as vehicle
            "
          >
            <p class="text-muted small mb-1" i18n="Vehicle label">Vehicle</p>
            <h6>{{ vehicle }}</h6>
          </div>
        </div>
      </div>
      <div class="col-6 mb-2 no-outer-border list-group-item flex-fill">
        <div class="row">
          <div class="col-auto pr-0 align-self-center text-end">
            <h2 class="mb-3">
              <i class="bi bi-minecart"></i>
            </h2>
          </div>
          <div
            class="col"
            *ngVar="
              activeOrder
                ? activeOrderTrailer
                : (authRepo.settings$ | async)?.trailerName as trailer
            "
          >
            <p class="text-muted small mb-1">Trailer</p>
            <h6>{{ trailer }}</h6>
          </div>
        </div>
      </div>
      <div
        *ngIf="order && order.userName"
        class="col-6 mb-2 no-outer-border list-group-item flex-fill"
      >
        <div class="row">
          <div class="col-auto pr-0 align-self-center text-end">
            <h2 class="mb-3">
              <i class="bi bi-person-fill"></i>
            </h2>
          </div>
          <div class="col">
            <p class="text-muted small mb-1" i18n="@@driverCard">Driver</p>
            <h6>{{ order.userName }}</h6>
          </div>
        </div>
      </div>
      <a
        *ngIf="order && order.contactPerson"
        class="col-6 mb-2 no-outer-border list-group-item flex-fill hoverable"
        href="tel:{{ contactPerson }}"
        [class.disabled]="!contactPerson || contactPerson.length < 5"
      >
        <div class="row">
          <div class="col-auto pr-0 align-self-center text-end">
            <h2 class="mb-3">
              <i class="bi bi-phone-vibrate-fill strong success-text"></i>
            </h2>
          </div>
          <div class="col">
            <p class="text-muted small mb-1" i18n="Contact Person label">
              Contact Person
            </p>
            <h6>{{ order.contactPerson }}</h6>
          </div>
        </div>
      </a>

      <div
        *ngIf="order && order.plannedToStartAt"
        class="col-6 mb-2 no-outer-border list-group-item flex-fill"
      >
        <div class="row">
          <div class="col-auto pr-0 align-self-center text-end">
            <h2 class="mb-3">
              <i class="bi bi-hourglass-top"></i>
            </h2>
          </div>
          <div class="col">
            <p class="text-muted small mb-1" i18n="Planned start label">
              Planned to start at
            </p>
            <h6>{{ toCorrectDate(order.plannedToStartAt) }}</h6>
          </div>
        </div>
      </div>
      <div
        *ngIf="order && order.plannedToEndAt"
        class="col-6 mb-2 no-outer-border list-group-item flex-fill"
      >
        <div class="row">
          <div class="col-auto pr-0 align-self-center text-end">
            <h2 class="mb-3">
              <i class="bi bi-hourglass-bottom"></i>
            </h2>
          </div>
          <div class="col">
            <p class="text-muted small mb-1" i18n="Planned end label">
              Planned to end at
            </p>
            <h6>{{ toCorrectDate(order.plannedToEndAt) }}</h6>
          </div>
        </div>
      </div>
      <div
        *ngIf="order && order.client && order.client.name"
        class="col-6 mb-2 no-outer-border list-group-item flex-fill"
      >
        <div class="row">
          <div class="col-auto pr-0 align-self-center text-end">
            <h2 class="mb-3">
              <i class="bi bi-briefcase"></i>
            </h2>
          </div>
          <div class="col">
            <p class="text-muted small mb-1" i18n="Planned start label">
              Client
            </p>
            <h6>{{ order.client.name }}</h6>
          </div>
        </div>
      </div>
      <div
        *ngIf="order && order.vendor && order.vendor.name"
        class="col-6 mb-2 no-outer-border list-group-item flex-fill"
      >
        <div class="row">
          <div class="col-auto pr-0 align-self-center text-end">
            <h2 class="mb-3">
              <i class="bi bi-briefcase"></i>
            </h2>
          </div>
          <div class="col">
            <p class="text-muted small mb-1" i18n="Planned end label">Vendor</p>
            <h6>{{ order.vendor.name }}</h6>
          </div>
        </div>
      </div>
      <div
        *ngIf="order && order.pickUpAddress"
        class="cursor-pointer col-6 mb-2 no-outer-border list-group-item flex-fill"
        (click)="redirectToAddressOnMaps(order.pickUpAddress)"
      >
        <div class="row">
          <div class="col-auto pr-0 align-self-center text-end">
            <h2 class="mb-3">
              <i class="bi bi-geo-alt-fill strong danger-text"></i>
            </h2>
          </div>
          <div class="col">
            <p class="text-muted small mb-1" i18n="Planned start label">
              Pickup Address
            </p>
            <h6>{{ order.pickUpAddress }}</h6>
          </div>
        </div>
      </div>
      <div
        *ngIf="order && order.deliveryAddress"
        class="cursor-pointer col-6 mb-2 no-outer-border list-group-item flex-fill"
        (click)="redirectToAddressOnMaps(order.deliveryAddress)"
      >
        <div class="row">
          <div class="col-auto pr-0 align-self-center text-end">
            <h2 class="mb-3">
              <i class="bi bi-geo-alt-fill strong danger-text"></i>
            </h2>
          </div>
          <div class="col">
            <p class="text-muted small mb-1" i18n="Planned end label">
              Delivery Address
            </p>
            <h6>{{ order.deliveryAddress }}</h6>
          </div>
        </div>
      </div>
      <div
        *ngIf="order && order.note"
        class="col-6 mb-2 no-outer-border list-group-item flex-fill"
      >
        <div class="row">
          <div class="col-auto pr-0 align-self-center text-end">
            <h2 class="mb-3">
              <i class="bi bi-bookmarks"></i>
            </h2>
          </div>
          <div class="col text-break">
            <p class="text-muted small mb-1" i18n="Planned start label">Note</p>
            <h6>{{ order.note }}</h6>
          </div>
        </div>
      </div>
      <div
        *ngIf="order && order.reference"
        class="col-6 mb-2 no-outer-border list-group-item flex-fill"
      >
        <div class="row">
          <div class="col-auto pr-0 align-self-center text-end">
            <h2 class="mb-3">
              <i class="bi bi-gear"></i>
            </h2>
          </div>
          <div class="col">
            <p class="text-muted small mb-1" i18n="orderReference label">
              Reference
            </p>
            <h6>{{ order.reference }}</h6>
          </div>
        </div>
      </div>
      <div
        *ngIf="order && order.comment"
        class="col-6 mb-2 no-outer-border list-group-item flex-fill"
      >
        <div class="row">
          <div class="col-auto pr-0 align-self-center text-end">
            <h2 class="mb-3">
              <i class="bi bi-chat-left-dots"></i>
            </h2>
          </div>
          <div class="col">
            <p class="text-muted small mb-1" i18n="orderComment label">
              Comment
            </p>
            <h6>{{ order.comment }}</h6>
          </div>
        </div>
      </div>
      <div
        *ngIf="order && order.products && order.products.length > 0"
        class="col-6 mb-2 no-outer-border list-group-item flex-fill"
      >
        <div class="row">
          <div class="col-auto pr-0 align-self-center text-end">
            <h2 class="mb-3">
              <i class="bi bi-box"></i>
            </h2>
          </div>
          <div class="col">
            <p class="text-muted small mb-1" i18n="Label for products page">
              Products
            </p>
            <h6 class="d-flex text-break">
              <div>{{ getProducts() }}</div>
            </h6>
          </div>
        </div>
      </div>
      <div
        *ngIf="order && order.weight"
        class="col-6 mb-2 no-outer-border list-group-item flex-fill"
      >
        <div class="row">
          <div class="col-auto pr-0 align-self-center text-end">
            <h2 class="mb-3">
              <i class="bi bi-boxes"></i>
            </h2>
          </div>
          <div class="col">
            <p class="text-muted small mb-1" i18n="orderWeight label">
              Weight, kg/m
            </p>
            <h6>{{ order.weight }}</h6>
          </div>
        </div>
      </div>
      <div
        *ngIf="order && order.accessory"
        class="col-6 mb-2 no-outer-border list-group-item flex-fill"
      >
        <div class="row">
          <div class="col-auto pr-0 align-self-center text-end">
            <h2 class="mb-3">
              <i class="bi bi-cone-striped"></i>
            </h2>
          </div>
          <div class="col">
            <p class="text-muted small mb-1" i18n="orderAccessory label">
              Accessory
            </p>
            <h6 class="d-flex text-break">{{ order.accessory }}</h6>
          </div>
        </div>
      </div>
      <div
        *ngIf="order && order.cleaning"
        class="col-6 mb-2 no-outer-border list-group-item flex-fill"
      >
        <div class="row">
          <div class="col-auto pr-0 align-self-center text-end">
            <h2 class="mb-3">
              <i class="bi bi-droplet"></i>
            </h2>
          </div>
          <div class="col">
            <p class="text-muted small mb-1" i18n="@formOrderCleaning label">
              Rengøring
            </p>
            <h6>{{ order.cleaning }}</h6>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-wrap">
      <div *ngFor="let commentPhoto of order?.commentPhotos; let i = index">
        <div class="image">
          <img
            class="comment-img"
            (click)="openImageModal(i)"
            src="{{ commentPhoto.imagePath }}"
          />
        </div>
      </div>
    </div>
  </div>
  <span *ngIf="vehicleError" style="color: red">Choose vehicle first</span>
  <button
    *ngIf="
      order &&
      !isTripActive &&
      !isOrderActive &&
      (!order.parkedAt ||
        (order.parkedAt &&
          order.resumedAt &&
          order.parkedAt < order.resumedAt)) &&
      workDay &&
      !dayPause
    "
    [class.bg-success]="
      !isTripActive &&
      !isOrderActive &&
      (!order.parkedAt ||
        (order.parkedAt && order.resumedAt && order.parkedAt < order.resumedAt))
    "
    [class.disabled-success]="
      isOrderActive ||
      !(
        !isTripActive &&
        !isOrderActive &&
        (!order.parkedAt ||
          (order.parkedAt &&
            order.resumedAt &&
            order.parkedAt < order.resumedAt))
      ) ||
      isTripActive ||
      (order.parkedAt && !order.resumedAt) ||
      (order.parkedAt && order.resumedAt && order.parkedAt > order.resumedAt)
    "
    [disabled]="
      isOrderActive ||
      isTripActive ||
      (order.parkedAt && !order.resumedAt) ||
      (order.parkedAt && order.resumedAt && order.parkedAt > order.resumedAt)
    "
    (click)="launchStartOrder(order.id)"
    style="flex: none"
    class="but card shadow-sm me-2 bg-success text-white d-flex justify-content-center align-items-center"
  >
    <h4 class="mt-1 mb-1" i18n="@@startGoOrder">
      Start <i class="bi bi-play-fill"></i>
    </h4>
  </button>

  <a
    *ngIf="iphoneHref"
    type="hidden"
    target="_blank"
    [href]="iphoneHref"
    id="iphone_redirect"
  ></a>
</div>

<app-image-slider
  [urls]="images"
  (cancel)="closeImageSlider()"
></app-image-slider>
