import { Injectable } from '@angular/core';
import { BaseRepository } from './abstract/base.repository';
import { TripEvent } from './trips.repository';

@Injectable({ providedIn: 'root' })
export class TripEventsRepository extends BaseRepository<TripEvent> {

  constructor() {
    super(
      'tripevents', 
      [ { label: 'Date', property: 'eventTime' } ],
      { parameter: { property: 'eventTime' }, direction: 'desc' }
    );
  }

}