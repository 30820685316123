<div
  class="modal fade in"
  *ngIf="urls && urls!.length > 0"
  id="photoModal"
  [style.display]="display"
  [style.opacity]="opacity"
  tabindex="-1"
  aria-labelledby="photoModal"
  aria-hidden="false"
>
  <div
    class="modal-dialog modal-dialog-centered modal-xl"
    style="transform: translateY(0)"
  >
    <div class="modal-content bg-lights border-0">
      <div class="modal-header">
        <h6
          class="modal-title"
          id="photoModalLabel"
          i18n="Title for gallery window"
        >
          Trip Photos {{ slideIndex + 1 }}/{{ urls.length }}
        </h6>
        <button
          type="button"
          class="btn-close"
          (click)="refreshIndex()"
          aria-label="Close"
          i18n-aria-label="Close label @@close"
        ></button>
      </div>
      <div class="modal-body" *ngIf="urls?.length !== 0">
        <div class="form-floating mb-3 hfc">
          <div class="prev" (click)="minusSlide()">&#10094;</div>
          <div class="next" (click)="plusSlide()">&#10095;</div>
          <div class="image-container">
            <img class="image" *ngIf="urls" src="{{ urls[slideIndex] }}" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
