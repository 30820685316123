<div class="row mb-4">
  <div class="col">
    <h2 i18n="Title for the list of company's drivers">Drivers logs(WorkDays)</h2>
  </div>
</div>
<div class="row mb-4">
</div>
<div class="row mb-4">
  <div class="col">
  
    <app-sort-table-workdays [dateType]="dateType"
                             [workDays]="workDaysRepo.workdays$"    
                    [drivers]="drivers"></app-sort-table-workdays>
  </div>
</div>

