import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UrlIdResolverService } from 'src/app/modules/shared/services/url-id-resolver.service';
import { FeatureRepository } from 'src/app/state/feature.repository';
import { FeatureService } from 'src/app/state/feature.service';
import { Tenant, TenantsRepository } from 'src/app/state/tenants.repository';
import { TenantsService } from 'src/app/state/tenants.service';

@Component({
  selector: 'app-tenants-create-page',
  templateUrl: './tenants-create-page.component.html',
  styleUrls: ['./tenants-create-page.component.scss']
})
export class TenantsCreatePageComponent implements OnInit {
  tenant$: Observable<Tenant> | null = null;
  id?: string;
  submitErrors: string[] | null = null;  
  
  constructor(
    private tenantService: TenantsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private repo: TenantsRepository,
    private urlId: UrlIdResolverService,
    private featureService: FeatureService,
    public featureRepo: FeatureRepository
  ) { }

  ngOnInit(): void {
    this.tenant$ = this.urlId.resolve(
      this.activatedRoute.paramMap,
      id => this.tenantService.loadOne(id),
      id => this.repo.tenant(id),
      id => this.repo.status(id),
      id => this.id = id
    );
    this.loadData();
  }

  loadData() {   
   this.featureService.load().subscribe();
  }

  createTenant(tenant: Partial<Tenant>) {
    this.submitErrors = null;
    let updateResult: Observable<Tenant> | undefined;
    if(this.id === 'new'){
      updateResult = this.tenantService.add(tenant);
      
    } else if(this.id){
      updateResult = this.tenantService.update(this.id , tenant);
    }
    if (updateResult) {
      updateResult.subscribe({
        complete: () => {
          this.router.navigate(['/tenants']);
        },
        error: data => this.submitErrors = data
      });
    }    
  }  
}
