<div
  style="font-size: 5px !important"
  #kekform
  *ngIf="!parked || parked.length === 0"
  class="card shadow-sm bg-success text-white"
  role="button"
  (click)="launchStartTrip()"
>
  <div class="card-body">
    <h2 class="mb-3">
      <i *ngIf="!isLoading" class="bi bi-play-fill"></i>
      <app-spinner *ngIf="isLoading"></app-spinner>
    </h2>
    <h4 class="mb-2" i18n="Start trip label">Start trip</h4>
    <p *ngIf="userSettings" class="small d-flex mb-2">
      <span
        id="vehicleLabel"
        class="w-100 me-2"
        [class.text-danger]="isVehicleError"
      >
        <i class="bi bi-truck me-1"></i>
        <span *ngIf="userSettings.vehicleId">{{
          userSettings.vehicleName
        }}</span>
        <span *ngIf="!userSettings.vehicleId" i18n="@@notSelectedVehicle"
          >Vehicle is not selected</span
        >
      </span>
      <span class="w-100">
        <i class="bi bi-minecart me-1"></i>
        <span *ngIf="userSettings.trailerId">{{
          userSettings.trailerName
        }}</span>
        <span
          *ngIf="!userSettings.trailerId"
          i18n="Trailer is not selected error message"
          >Trailer is not selected</span
        >
      </span>
    </p>
  </div>
</div>

<div *ngIf="parked && parked.length > 0" class="d-flex">
  <div
    class="card shadow-sm bg-success w-100 h-75 me-2 text-white"
    role="button"
    (click)="launchStartTrip()"
  >
    <div class="card-body">
      <h2 class="mb-3">
        <i *ngIf="!isLoading" class="bi bi-play-fill"></i>
        <app-spinner *ngIf="isLoading"></app-spinner>
      </h2>
      <h4 class="mb-2" i18n="Start trip label">Start trip</h4>
      <p *ngIf="userSettings" class="d-flex mb-2 resp-font">
        <span
          id="vehicleLabel"
          class="w-100 me-2"
          [class.text-danger]="isVehicleError"
        >
          <i class="bi bi-truck me-1"></i>
          <span *ngIf="userSettings.vehicleId">{{
            userSettings.vehicleName
          }}</span>
          <span *ngIf="!userSettings.vehicleId" i18n="@@notSelectedVehicle"
            >Vehicle is not selected</span
          >
        </span>
        <span class="w-100">
          <i class="bi bi-minecart me-1"></i>
          <span *ngIf="userSettings.trailerId">{{
            userSettings.trailerName
          }}</span>
          <span
            *ngIf="!userSettings.trailerId"
            i18n="Trailer is not selected error message"
            >Trailer is not selected</span
          >
        </span>
      </p>
    </div>
  </div>
  <div
    class="card shadow-sm bg-violet w-100 h-100 text-white"
    (click)="resume.emit()"
    role="button"
  >
    <div class="card-body">
      <h2 class="mb-3">
        <i *ngIf="!isLoading"></i>
        <app-spinner *ngIf="isLoading"></app-spinner>
      </h2>
      <h4 class="mb-2" i18n="Start trip label">Parked trips</h4>
      <br />
      <p class="small d-flex mb-2">
        <span i18n="You have parked trips label" class="w-100 me-2"
          >You have parked trips</span
        >
      </p>
    </div>
  </div>
</div>
