<div class="row mt-1">
    <div class="col-12">
      <div class="card shadow-md mb-4">
        <ul class="list-group list-group-flush">
          <app-model-card *ngFor="let accessory of accessories"
            [title]="accessory.name"
            [subtitle]="accessory.description"
            icon="bi-tools"
            [date]="accessory.createdAt"
            [id]="accessory.id"
            routerLinkBase="/accessories"
            [isDeleteDisabled]="accessory.isBeingUsed"
            (deleteClick)="deleteConfirmation = accessory"></app-model-card>
        </ul>
      </div>
    </div>
</div>
<app-confirm-delete *ngIf="deleteConfirmation"
  [deleteCandidate]="deleteConfirmation.name"
  (confirm)="delete.emit(deleteConfirmation.id);deleteConfirmation = null;"
  (cancel)="deleteConfirmation = null"></app-confirm-delete>
