import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  constructor() {}

  saveEvent$: Subject<any> = new BehaviorSubject(undefined);
  saveOfficeAddressEvent$: Subject<any> = new BehaviorSubject(undefined);
  cancelEvent$: Subject<boolean> = new BehaviorSubject<boolean>(false);
  cancelOfficeEvent$: Subject<boolean> = new BehaviorSubject<boolean>(false);
  linkedOrderEvent$: Subject<any> = new BehaviorSubject(undefined);
  destroyModal$: Subject<string> = new BehaviorSubject('');
  starttime$: Subject<any> = new BehaviorSubject(undefined);
  endtime$: Subject<any> = new BehaviorSubject(undefined);
  clientEmit$: Subject<any> = new BehaviorSubject(undefined);

  requestToGetTime$: Subject<boolean> = new BehaviorSubject(false);
  closeImageModal$: Subject<boolean> = new BehaviorSubject(false);

  previousDashboardFromData$: Subject<any> = new BehaviorSubject(undefined);
  orderFormParentId$: Subject<any> = new BehaviorSubject(undefined);
  tripOfficeNote$: Subject<any> = new BehaviorSubject(undefined);

  sendClientEmit(val: any) {
    this.clientEmit$.next(val);
  }

  changePreviousDashboardFromData(val: any) {
    this.previousDashboardFromData$.next(val);
  }

  changeOrderFormParentId(val: any) {
    this.orderFormParentId$.next(val);
  }

  changeOfficeTripNote(val: any) {
    this.tripOfficeNote$.next(val);
  }

  sendSaveEvent(val: any) {
    this.saveEvent$.next(val);
  }

  saveOfficeAddressEvent(val: any) {
    this.saveOfficeAddressEvent$.next(val);
  }

  sendDestroyModal(val: any) {
    this.destroyModal$.next(val);
  }

  sendCancelEvent(val: any) {
    this.cancelEvent$.next(val);
  }

  sendCancelOfficeAddressEvent(val: any) {
    this.cancelOfficeEvent$.next(val);
  }

  sendLinkedOrderEvent(val: any) {
    this.linkedOrderEvent$.next(val);
  }
  sendStartTimeValue(val: any) {
    this.starttime$.next(val);
  }

  sendEndTimeValue(val: any) {
    this.endtime$.next(val);
  }
  sendRequestToGetTime(val: any) {
    this.requestToGetTime$.next(val);
  }

  closeImageModal(val: any) {
    this.closeImageModal$.next(val);
  }
}
