import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { Order } from 'src/app/state/orders.repository';
import { User } from 'src/app/state/users.repository';

@Component({
  selector: 'app-trip-change-driver-modal',
  templateUrl: './trip-change-driver-modal.component.html',
  styleUrls: ['./trip-change-driver-modal.component.scss'],
})
export class TripChangeDriverModalComponent {
  form: UntypedFormBuilder | any;
  @Output() confirm = new EventEmitter<string>();
  @Output() cancel = new EventEmitter<void>();
  @Input() clientName: string | undefined;
  @Input() date: string | undefined | null;
  @Input() currentId: string | null | undefined;
  @Input() set drivers(value: User[] | null) {
    if (!value) {
      this.driverOptions = null;
    } else {
      this.driverOptions = value.map((x) => ({
        value: x.id,
        label: x.name + ' ' + x.surname,
      }));
    }
  }
  @Input() set driverDatalistOptions(value: DatalistOption[] | null) {
    this.driverOptions = value;
  }

  driverOptions: DatalistOption[] | null = null;
  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      driverId: [this.currentId, Validators.required],
    });
  }

  updateDriverId(value: any) {
    const controlObject = this.form?.get('driverId');
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  onSubmit() {
    if (this.form && !this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    const driverId = this.form.value?.driverId;
    this.confirm.emit(driverId);
  }
}
