import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { async, Observable } from 'rxjs';
import { AuthRepository, UserRoles } from 'src/app/state/auth.repository';
import { Column, ColumnsRepository } from 'src/app/state/columns.repository';
import { ColumnsService } from 'src/app/state/columns.service';
import {
  CompaniesRepository,
  Company,
} from 'src/app/state/companies.repository';
import { CompaniesService } from 'src/app/state/companies.service';
import { TripEventsService } from 'src/app/state/tripevents.service';
import {
  Product,
  ProductsRepository,
} from '../../../../state/products.repository';
import {
  ClientEventDto,
  ClientEventInfoDto,
  EconomicInfoExtended,
  PhotoEventDto,
  PhotoModalEventDto,
  TenantNoteEvent,
  Trip,
  TripDto,
  TripsRepository,
} from '../../../../state/trips.repository';
import { TripsService } from '../../../../state/trips.service';
import {
  User,
  UserSelectDto,
  UsersRepository,
} from '../../../../state/users.repository';
import { UsersService } from '../../../../state/users.service';
import { EProductsService } from 'src/app/state/eproducts.service';
import { PriceTypesService } from 'src/app/state/pricetypes.service';
import {
  PriceType,
  PriceTypeRepository,
} from 'src/app/state/pricetypes.repository';
import { EProductsRepository } from 'src/app/state/eproducts.repository';
import {
  FilterOptions,
  Workday,
  WorkdayEvent,
} from 'src/app/state/workdays.repository';
import { WorkDayEventsService } from 'src/app/state/workdayevents.service';
import { DataSelectDto } from 'src/app/state/models/dataSelectModels/dataSelectDto';
import { TripLogDto } from 'src/app/state/models/trip/TripLogDto';
import { DayjsService } from 'src/app/modules/shared/services/dayjs.service';
import { ProductsService } from 'src/app/state/products.service';
import { TripPhotoUrlDto } from 'src/app/state/models/trip/TripPhotoUrlDto';
import { TenantNoteChangeDto } from 'src/app/state/models/shared/TenantNoteChangeDto';

@Component({
  selector: 'app-economic-page',
  templateUrl: './economic-page.component.html',
  styleUrls: ['./economic-page.component.scss'],
})
export class EconomicPageComponent implements OnInit {
  isColumnsLoaded: boolean = false;
  isClientsLoaded: boolean = false;
  isProductsLoaded: boolean = false;

  driverSelectOptions: UserSelectDto[] | undefined;
  clientSelectOptions: DataSelectDto[] | undefined;
  productSelectOptions: DataSelectDto[] | undefined;
  loadUnloadColumns: Column[] | null = null;
  tableColumns: Column[] | null = null;

  tripLogs: TripLogDto[] | null | any = null;

  dayObj = this.ngDay.dayjs.utc().local().format('YYYY-MM-DD');
  filterOptions: FilterOptions = {
    isApproved: false,
    dateFrom: this.dayObj,
    dateTo: this.dayObj,
    driverSelected: undefined,
    clientSelected: undefined,
    productSelected: undefined,
    isParkedOverview: false,
  };

  //not ref
  dateType?: 'day' | ' week' | 'month';
  drivers: User[] | null = [];
  workDayEvents$: Observable<WorkdayEvent[]> | null = null;
  trips: Trip[] | null | any = null;
  products: Product[] | null | any = null;

  eProducts: Product[] | null = null;
  clients: Company[] | null | any = null;
  deletedClients: Company[] | null = null;
  columns: Column[] | null = null;
  priceTypes: PriceType[] | null = null;

  isEProductsLoaded: boolean = false;

  isDeletedClientsLoaded: boolean = false;
  isPriceTypesLoaded: boolean = false;
  updateInProgress: boolean = false;

  constructor(
    public ngDay: DayjsService,
    private companyService: CompaniesService,
    public productsService: ProductsService,
    private eProdService: EProductsService,
    public usersService: UsersService,

    public authRepo: AuthRepository,
    public service: TripsService,
    public router: ActivatedRoute,
    private columnsService: ColumnsService,
    private tripEventService: TripEventsService,
    private route: Router,
    private priceTypeService: PriceTypesService,

    public repo: TripsRepository
  ) {
    this.getFiltersFromLocaleStorage();
  }

  getFiltersFromLocaleStorage() {
    let dateFrom = localStorage.getItem('dateFrom')?.toString();
    if (dateFrom) {
      this.filterOptions.dateFrom = dateFrom;
    }
    let dateTo = localStorage.getItem('dateTo')?.toString();
    if (dateTo) {
      this.filterOptions.dateTo = dateTo;
    }
    this.filterOptions.clientSelected =
      localStorage.getItem('clientSelected')?.toString() || '';
    this.filterOptions.driverSelected =
      localStorage.getItem('driverSelected')?.toString() || '';
    this.filterOptions.isApproved = JSON.parse(
      localStorage.getItem('isApprovedSearch')?.toString() || 'false'
    );
    var productSelected = localStorage.getItem('productSelected');
    if (productSelected) {
      this.filterOptions.productSelected = JSON.parse(productSelected);
    }
  }

  setFiltersToLocaleStorage() {
    localStorage.setItem('dateFrom', this.filterOptions.dateFrom ?? '');
    localStorage.setItem('dateTo', this.filterOptions.dateTo ?? '');
    localStorage.setItem(
      'clientSelected',
      this.filterOptions.clientSelected || ''
    );
    localStorage.setItem(
      'driverSelected',
      this.filterOptions.driverSelected || ''
    );

    localStorage.setItem(
      'productSelected',
      JSON.stringify(this.filterOptions.productSelected || '')
    );
    localStorage.setItem(
      'isApprovedSearch',
      JSON.stringify(this.filterOptions.isApproved)
    );
  }

  requestLoadData(filterOptions: FilterOptions) {
    this.filterOptions = filterOptions;
    if (
      this.filterOptions &&
      this.filterOptions.dateFrom &&
      this.filterOptions.dateTo &&
      this.filterOptions.dateTo >= this.filterOptions.dateFrom
    ) {
      this.setFiltersToLocaleStorage();
      this.loadTripLogs(this.filterOptions);
    }
  }

  loadTripLogs(filterOptions: FilterOptions) {
    this.service.loadTripLogsOverview(filterOptions, true).subscribe((x) => {
      this.tripLogs = x;
      this.updateInProgress = false;
    });
  }

  movePhoto(event: TripPhotoUrlDto) {
    this.service.movePhotoEvent(event).subscribe({
      complete: () => {
        this.loadTripLogs(this.filterOptions);
      },
    });
  }

  ngOnInit(): void {
    this.usersService
      .loadDriversForSelect()
      .subscribe((x) => (this.driverSelectOptions = x));

    this.companyService.loadCompaniesForSelect().subscribe((x) => {
      this.clientSelectOptions = x;
      this.isClientsLoaded = true;
    });

    this.productsService.loadEconomicProductsForSelect().subscribe((x) => {
      this.productSelectOptions = x;
      this.isProductsLoaded = true;
    });

    this.columnsService.loadAll().subscribe((x) => {
      this.loadUnloadColumns = x.filter(
        (c) => c.isUnloadColumn && !c.name.includes('Edit')
      );

      this.tableColumns = x
        .filter((c) => c.isStandartColumn && !c.name.includes('Edit'))
        .map((col) => {
          if (col.name === 'Trip Info' || col.name === 'Time') {
            return { ...col, isVisible: false };
          }
          return col;
        });

      this.isColumnsLoaded = true;
    });

    this.priceTypeService.loadTypes().subscribe((x) => {
      this.priceTypes = x;
      this.isPriceTypesLoaded = true;
    });

    this.loadTripLogs(this.filterOptions);
  }

  updateTenantNote($event: TenantNoteChangeDto) {
    this.service.updateTenantNote($event).subscribe();
  }

  updateTripEventClients(result: ClientEventDto) {
    this.tripEventService.updateClients(result).subscribe({
      complete: () => {
        this.loadTripLogs(this.filterOptions);
      },
    });
  }

  updateTripProducts($event: EconomicInfoExtended) {
    this.updateInProgress = true;
    this.tripEventService.updateEProducts($event).subscribe({
      complete: () => {
        this.loadTripLogs(this.filterOptions);
      },
    });
  }

  checkIfDataLoaded() {
    return (
      this.isClientsLoaded &&
      this.isColumnsLoaded &&
      this.isProductsLoaded &&
      this.isPriceTypesLoaded
    );
  }
}
