import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  DoCheck,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { DatalistOption } from '../../../shared/components/datalist-select/datalist-select.component';
import {
  EconomicInfo,
  EconomicInfoExtended,
  TripDto,
  TripEventDto,
  TripPhoto,
} from 'src/app/state/trips.repository';
import { PriceType } from 'src/app/state/pricetypes.repository';
import { LabelValue } from 'src/app/state/eproducts.repository';
import { Product } from 'src/app/state/products.repository';
import { WorkdayEvent } from 'src/app/state/workdays.repository';
import { DayjsService } from '../../../shared/services/dayjs.service';

import { ImgSliderComponent } from '../../../shared/components/img-slider/img-slider.component';
import { BaseService } from 'src/app/base-service';
import { AuthRepository } from 'src/app/state/auth.repository';
import { TenantFeatures } from 'src/app/state/feature.repository';
import { DataSelectDto } from 'src/app/state/models/dataSelectModels/dataSelectDto';
import { DataSelectService } from 'src/app/state/dataSelect.service';

@Component({
  selector: 'app-single-group-modal',
  templateUrl: './single-group-modal.component.html',
  styleUrls: ['./single-group-modal.component.scss'],
})
export class SingleGroupModalComponent implements OnInit, DoCheck {
  eprods: DataSelectDto[] | null = null;
  clientId = '';
  additions = new Array<LabelValue>();
  photos: TripPhoto[] = [];
  tevents: TripEventDto[] | null = null;
  TenantFeatures = TenantFeatures;
  theTrip: TripDto | null = null;
  @Input() priceTypes: PriceType[] | null = null;
  @Input() mutable = true;
  @Input() workEvents: WorkdayEvent[] | null = null;
  weightImg?: TripPhoto[];

  @Input() set trip(value: TripDto | null) {
    if (!value?.tripEvents) {
      this.additions = [];
      this.theTrip = null;
    } else {
      this.theTrip = value;
      this.tevents = value.tripEvents;
      this.weightImg = value.weighingPhotos;
      this.additions = [];
    }
  }

  eProductOptions: DatalistOption[] | null = null;
  eProductOptionsDeleted: DatalistOption[] | null = null;

  @Output() economicEmmiter = new EventEmitter<EconomicInfoExtended>();

  @Output() close = new EventEmitter<void>();
  productGroupForm?: UntypedFormGroup;

  @Input() set eproducts(value: DataSelectDto[] | null) {
    if (value) {
      this.eProductOptions = this.dataSelectService.setDataSelectOptions(
        value.filter((x) => x.isActive && !x.isDeleted && x.sublabel)
      );
      this.eProductOptionsDeleted = this.dataSelectService.setDataSelectOptions(
        value.filter((x) => !x.isActive || x.isDeleted)
      );

      this.eprods = value;
    }
  }

  constructor(
    private readonly resolver: ComponentFactoryResolver,
    private readonly injector: Injector,
    private formBuilder: UntypedFormBuilder,
    private cdRef: ChangeDetectorRef,
    public ngDay: DayjsService,
    private readonly baseService: BaseService,
    public auth: AuthRepository,
    private dataSelectService: DataSelectService
  ) {
    this.sub = this.baseService.closeImageModal$.subscribe((x) => {
      if (x) {
        this.clientcomponentRef?.destroy();
      }
    });

    this.productGroupForm = this.formBuilder.group({
      eProductInfos: this.formBuilder.array([]),
    });

    //this.fillTheVoidInitial();
  }

  sub: any;
  @ViewChild('imageSlider', { read: ViewContainerRef })
  clientscontainer?: ViewContainerRef;

  clientcomponentRef?: ComponentRef<any>;

  ngOnInit(): void {
    if (this.productGroupForm) {
      this.productGroupForm = this.formBuilder.group({
        eProductInfos: this.formBuilder.array([]),
      });
      let control = this.productGroupForm?.controls
        .eProductInfos as UntypedFormArray;
      this.theTrip?.tripEvents?.forEach((x: TripEventDto, index: number) => {
        if (index > 0) {
          this.checkForFixedPrice(index, x);
        }
        control.push(
          this.formBuilder.group({
            eproductIds: [x.economics?.map((x) => x.productId)],
            tripEventId: x.tripEventId,
          })
        );
      });
    }

    this.fillTheVoidInitial();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  ngDoCheck() {
    this.getUrls();
    if (this.tevents && this.additions.length === 0) {
      this.tevents.forEach((x: TripEventDto, index: number) => {
        if (index > 0) {
          this.checkForFixedPrice(index, x);
        }
        this.fillTheVoidInitial();
      });
    }
    // this.cdRef.detectChanges();
  }

  getUrls() {
    if (this.tevents) {
      this.photos = [];
      this.tevents.forEach((x) => {
        x.photos?.forEach((y) => {
          this.photos.push(y);
        });
      });
    }
    if (this.weightImg) {
      this.weightImg.forEach((x) => {
        this.photos.push(x);
      });
    }
  }

  getImgModal(index: number) {
    var img: string[] = [];
    if (this.tevents) {
      this.tevents.forEach((x) => {
        x.photos?.forEach((y) => {
          img.push(y.url);
        });
      });
    }
    if (this.weightImg) {
      this.weightImg.forEach((x) => {
        img.push(x.url);
      });
    }

    const injector = Injector.create({
      providers: [],
      parent: this.injector,
    });

    const factory = this.resolver.resolveComponentFactory(ImgSliderComponent);
    this.clientcomponentRef = this.clientscontainer?.createComponent(
      factory,
      undefined,
      injector
    );
    if (this.clientcomponentRef?.instance) {
      this.clientcomponentRef.instance.slideIndex = index;
      this.clientcomponentRef.instance.urls = img;
    }
  }

  filterOptionsByEvent(tripEvent: TripEventDto) {
    if (this.eProductOptions) {
      if (tripEvent.type === 'Total') {
        return this.eProductOptions;
      }
      let options = this.eProductOptions;
      if (tripEvent.diffKm == null || tripEvent.diffKm == undefined) {
        options = options.filter((x) => !x.sublabel?.includes('Km'));
      }
      if (tripEvent.diffKm == null || tripEvent.diffKm == undefined) {
        options = options.filter((x) => !x.sublabel?.includes('Navn'));
      }
      if (tripEvent.weight == null || tripEvent.weight == undefined) {
        options = options.filter((x) => !x.sublabel?.includes('Kg'));
      }
      return options;
    }
    return [];
  }

  stringToMinutes(str: string | null) {
    if (str) {
      const regex = /(\d+)\s*h\.\s*(\d+)\s*m\.\s*(\d+)\s*s\./;
      const matches = str.match(regex);
      const hours = Number(matches![1]);
      const minutes = Number(matches![2]);
      const seconds = Number(matches![3]);
      const totalMinutes = hours * 60 + minutes + seconds / 60;
      return totalMinutes;
    }
    return 0;
  }

  stringToMinutesNoSeconds(str: string | null) {
    if (str) {
      const regex = /^(\d+)h\. (\d+)m\.$/;
      const matches = str.match(regex);
      const hours = Number(matches![1]);
      const minutes = Number(matches![2]);
      const totalMinutes = hours * 60 + minutes;
      return totalMinutes;
    }
    return 0;
  }

  getTotalEventsLength(tevents: TripEventDto[]) {
    return tevents.filter((c) => c.type === 'Total').length;
  }

  changeStringToUtc(str?: string) {
    if (str) {
      return str.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2-$1-$3');
    }
    return '';
  }

  calculateTotalDurationWithoutOverlap(events: WorkdayEvent[]): number {
    const sortedEvents = events
      .slice()
      .sort((a, b) => a.eventTime.getTime() - b.eventTime.getTime());
    let totalDuration = 0;
    let previousEndTime = 0;
    sortedEvents.forEach((event) => {
      var durToMs = (event.duration || 0) * 60000;
      const endTime = event.eventTime.getTime() + durToMs;
      if (endTime > previousEndTime) {
        // if (previousEndTime !== 0) {
        //   const adjustedDuration = Math.max(0, endTime - previousEndTime);
        //   totalDuration += adjustedDuration;
        // } else {
        //   totalDuration += durToMs;
        // }
        totalDuration += durToMs;
      }
    });
    return totalDuration;
  }

  timeStringJustify(
    tripEvent: TripEventDto,
    tevents: TripEventDto[],
    str?: string | null
  ) {
    if (str) {
      const timeArray = str.split(' ');
      const hours = parseInt(timeArray[0]) || 0;
      const minutes = parseInt(timeArray[1]) || 0;
      const seconds = parseInt(timeArray[2]) || 0;
      let totalSeconds = hours * 3600 + minutes * 60 + seconds;
      let eventIndex = tevents.indexOf(tripEvent);
      let previous = tevents[eventIndex - 1];
      if (
        this.workEvents &&
        tripEvent.eventTime &&
        previous &&
        previous.eventTime
      ) {
        let previousTime = this.ngDay
          .dayjs(this.changeStringToUtc(previous.eventTime))
          .utc()
          .toDate();
        let time = this.ngDay
          .dayjs(this.changeStringToUtc(tripEvent.eventTime))
          .utc()
          .toDate();
        let eventsFiltered = this.workEvents.filter(
          (x) => x.eventTime <= time && x.eventTime >= previousTime
        );
        if (eventsFiltered.length > 0) {
          let withDuration = eventsFiltered.filter((x) => x.type === 'Pause');
          let withoutDuration = eventsFiltered.filter(
            (x) => x.type !== 'Pause'
          );
          let duration =
            this.calculateTotalDurationWithoutOverlap(withDuration);
          // withoutDuration.forEach((x: WorkdayEvent, index: number) => {
          //   if (index % 2 === 0 && index + 1 <= withoutDuration.length - 1) {
          //     let next = withoutDuration[index + 1];
          //     let diff = dayjs
          //       .duration(dayjs(next.eventTime).diff(dayjs(x.eventTime)))
          //       .asMilliseconds();
          //     if (diff > 0) {
          //       duration = duration + diff;
          //     }
          //   }
          // });

          totalSeconds = totalSeconds - duration / 1000;
        }
      }
      const addMinutes = totalSeconds % 900 > 0;
      const resSeconds = addMinutes
        ? totalSeconds + 900 - (totalSeconds % 900)
        : totalSeconds;

      const resHours = Math.floor(resSeconds / 3600);
      const resMinutes = Math.floor(((resSeconds - resHours * 3600) / 60) % 60);
      return `${resHours}h. ${resMinutes}m.`;
    }
    return '';
  }

  updateproductGroupFormValue(control: string, value: any, index: number) {
    const controlObject = this.productGroupForm
      ?.get('eProductInfos')
      ?.get(`${index}`)
      ?.get(control);
    controlObject?.setValue(value);
    this.additions = [];

    this.tevents?.forEach((x: TripEventDto, index: number) => {
      this.checkForFixedPrice(index, x);
    });
    this.fillTheVoidInitial();
    controlObject?.markAsTouched();
  }

  getDiffKg(events: TripEventDto[], tevent: TripEventDto) {
    let eventIndex = events.findIndex(
      (x) => x.tripEventId === tevent.tripEventId
    );
    if (eventIndex > 0) {
      let previousEvent = events[eventIndex - 1];
      if (
        previousEvent.weight !== undefined &&
        previousEvent.weight > -1 &&
        tevent.weight !== undefined &&
        tevent.weight > -1
      ) {
        let diffKg = tevent.weight - previousEvent.weight;
        if (diffKg > 0) {
          return `${diffKg}`;
        }
        return ' ';
      }
      return ' ';
    }
    return ' ';
  }

  getDiffKgHeader(events: TripEventDto[], tevent: TripEventDto) {
    let eventIndex = events.findIndex(
      (x) => x.tripEventId === tevent.tripEventId
    );
    if (eventIndex > 0) {
      let previousEvent = events[eventIndex - 1];
      if (
        previousEvent.weight !== undefined &&
        previousEvent.weight > -1 &&
        tevent.weight !== undefined &&
        tevent.weight > -1
      ) {
        let diffKg = tevent.weight - previousEvent.weight;
        return diffKg > 0 ? `+${diffKg}` : diffKg;
      }
      return 0;
    }
    return 0;
  }

  getNameForTypes(str: string) {
    if (str.includes('Tid')) {
      return str.replace('_', ' ');
    }
    return str.split('_')[0].replace('Minutter', 'Timer');
  }

  getDiffKmHeader(events: TripEventDto[], tevent: TripEventDto) {
    let eventIndex = events.findIndex(
      (x) => x.tripEventId === tevent.tripEventId
    );
    if (eventIndex > 0) {
      let previousEvent = events[eventIndex - 1];
      if (
        previousEvent.km !== undefined &&
        previousEvent.km > -1 &&
        tevent.km !== undefined &&
        tevent.km > -1
      ) {
        let diffKm = tevent.km - previousEvent.km;
        return diffKm >= 0 ? `+${diffKm}` : diffKm;
      }
      return `+${0}`;
    }
    return `+${0}`;
  }

  filterTEvents() {
    return this.tevents?.filter(
      (x) => x.type !== 'Total' && x.economics && x.economics.length > 0
    );
  }

  customRound(number: number) {
    const decimalPart = (number % 1).toFixed(2).substring(2);
    if (decimalPart === '00') {
      return Math.floor(number);
    } else if (decimalPart[1] === '0') {
      return parseFloat(number.toFixed(1));
    } else {
      return parseFloat(number.toFixed(2));
    }
  }

  updateTotal(id: string, isTotalEvent: boolean) {
    if (!isTotalEvent) {
      var totalEvent = this.tevents?.find((x) => x.type == 'Total');
      if (totalEvent) {
        var val = 0;
        var productControls = this.productGroupForm?.value.eProductInfos.filter(
          (f: any) =>
            f.tripEventId != totalEvent?.tripEventId &&
            f.eproductIds.indexOf(id) != -1
        );
        productControls?.forEach((t: any) => (val += t[`price${id}`] ?? 0));

        let control = <UntypedFormGroup>(
          this.productGroupForm?.controls.eProductInfos.get(
            `${this.tevents?.length! - 1}`
          )
        );

        var valueSaved = this.additions.find(
          (x) => x.tripEventId === totalEvent?.tripEventId && x.eProductId == id
        );

        if (control.value.eproductIds.indexOf(id) != -1 && !valueSaved?.value) {
          control.controls[`price${id}`].setValue(val);
        }
      }
    }
  }

  getAdd(id: string) {
    return this.additions.filter((x) => x.tripEventId === id);
  }
  getFast(id: string) {
    return this.additions.filter(
      (x) =>
        x.tripEventId === id &&
        (x.label.includes('Fast') || x.label.includes('Stk'))
    );
  }

  removeBeforeWhitespace(str: string) {
    return str.replace(/^[^\s]+\s/, '');
  }

  checkForFixedPrice(index: number, tripEvent?: TripEventDto) {
    let control = this.productGroupForm
      ?.get('eProductInfos')
      ?.get(`${index}`)
      ?.get('eproductIds');
    if (control && control?.value) {
      let ids = control?.value as Array<string>;
      let infos = new Array<DataSelectDto>();
      ids.forEach((x) => {
        let prod = this.eprods?.find((a) => a.id === x);
        if (prod) {
          infos.push(prod);
        }
      });
      infos.forEach((a) => {
        let price = this.priceTypes?.find((y) => y.id === a.sublabel);
        let priceValue = tripEvent?.economics?.find(
          (d) => d.productId === a.id
        )?.fixedPriceValue;

        let onlyTakeValueFromTotal = tripEvent?.economics?.find(
          (d) => d.productId === a.id
        )?.onlyTakeValueFromTotal;
        let pair = {
          label: (price?.name ? price.name + ' ' : '') + a.sublabel,
          tripEventId: tripEvent?.tripEventId,
          eventType: tripEvent?.type,
          diffKM: tripEvent?.diffKm,
          diffKg: tripEvent?.diffKg,
          duration: tripEvent?.duration,
          value: priceValue || 0,
          priceTypeId: price?.id,
          eProductId: a.id,
          onlyTakeValueFromTotal: onlyTakeValueFromTotal ?? false,
        } as LabelValue;
        if (pair.label.includes('Minutter') && pair.eventType !== 'Total') {
          pair.value = Number((pair.value / 60).toFixed(2));
        }
        this.additions.push(pair);
      });
    }
  }

  removeSpaces(text: string) {
    return `${text.replace(/\s/g, '')}`;
  }

  shortenString(value: string) {
    if (value.length > 25) {
      return value.slice(0, 25) + '...';
    }
    return value;
  }

  obliterate() {
    this.additions = [];
    this.baseService.sendDestroyModal('product');
    this.productGroupForm?.reset();
    this.productGroupForm?.markAsUntouched();
    this.close.emit();
  }

  getDanishEventType(type: string): string {
    if (type === 'TripStart') {
      return 'Start';
    }
    if (type === 'TripEnd') {
      return 'Slut';
    }
    if (type === 'Load') {
      return 'Laste';
    }
    if (type === 'Unload') {
      return 'Losse';
    }
    return type;
  }

  fillTheVoidInitial() {
    if (this.additions && this.additions.length > 0) {
      for (let i = 1; i < this.tevents?.length!; i++) {
        let filtered = this.additions.filter(
          (x) => x.tripEventId === this.tevents![i].tripEventId
        );
        let tevent = this.tevents![i];
        filtered.forEach((x: LabelValue, index: number) => {
          let control = <UntypedFormGroup>(
            this.productGroupForm?.controls.eProductInfos.get(`${i}`)
          );
          try {
            if (tevent.type === 'Total') {
              control.addControl(
                this.removeSpaces(`onlyTakeValueFromTotal${x.eProductId}`),
                new UntypedFormControl(x.onlyTakeValueFromTotal)
              );
              if (this.productGroupForm) {
                var val = x.value || 0;
                if (x.label.includes('Minutter')) {
                  val = val || tevent.totalMinutes || 0;
                  val = Number((val / 60).toFixed(2));
                } else if (
                  !x.value ||
                  (x.label.includes('Fast_pris') && x.value == 0)
                ) {
                  var productControls =
                    this.productGroupForm.value.eProductInfos.filter(
                      (f: any) =>
                        f.tripEventId != tevent.tripEventId &&
                        f.eproductIds.indexOf(x.eProductId) != -1
                    );
                  productControls?.forEach(
                    (t: any) => (val += t[`price${x.eProductId}`] ?? 0)
                  );
                }

                control.addControl(
                  this.removeSpaces(`price${x.eProductId}`),
                  new UntypedFormControl(val)
                );
              }
            } else {
              let quantity = 0;
              switch (true) {
                case x.label.includes('Km') || x.label.includes('Chauffør'):
                  if (tevent.diffKm) {
                    quantity = +tevent.diffKm;
                  }
                  break;

                case x.label.includes('Kg'):
                  if (tevent.weight) {
                    quantity = +tevent.weight;
                  }
                  break;

                case x.label.includes('Tons'):
                  if (tevent.weight) {
                    quantity = this.customRound(tevent.weight / 1000);
                  }
                  break;

                case x.label.includes('Minutter'):
                  const duration = this.timeStringJustify(
                    tevent,
                    this.tevents!,
                    tevent.duration
                  );
                  quantity = Number(
                    (this.stringToMinutesNoSeconds(duration) / 60).toFixed(2)
                  );
                  break;

                default:
                  break;
              }

              if (this.productGroupForm) {
                control.addControl(
                  this.removeSpaces(`price${x.eProductId}`),
                  new UntypedFormControl(x.value || quantity)
                );
              }
            }
          } catch {}
        });
      }
    }
  }

  save() {
    this.photos?.forEach((x, i) => {
      var el = document.getElementById('order_photo_' + i);
      if (el) {
        x.isActive = (el as any).checked;
      }
    });

    let economicDto = [] as EconomicInfo[];
    if (this.productGroupForm?.value) {
      const result = this.productGroupForm.value.eProductInfos as Array<any>;

      result.forEach((x) => {
        x.eproductIds.forEach((pId: string) => {
          let productType = this.eprods?.find((eP) => eP.id == pId)?.sublabel;

          let priceTypeId = this.priceTypes?.find(
            (pT) => pT.name === productType
          )?.id;
          let pairs = Object.entries(x);

          let fixedPrice = null;
          if (pairs.find((t) => this.removeSpaces(t[0]) === `price${pId}`)) {
            fixedPrice = pairs.find(
              (t) => this.removeSpaces(t[0]) === `price${pId}`
            )?.[1] as number;
          }
          let onlyTakeFromTotal = !!pairs.find(
            (t) => this.removeSpaces(t[0]) === `onlyTakeValueFromTotal${pId}`
          )?.[1];

          let tripEventId = pairs.find(
            (x) => x[0] === 'tripEventId'
          )?.[1] as string;
          let info = {
            tripEventId: tripEventId,
            productId: pId,
            priceTypeId: priceTypeId,
            fixedPrice:
              productType && productType.includes('Minutter')
                ? Number(((fixedPrice ?? 0) * 60).toFixed(0))
                : fixedPrice,
            onlyTakeValueFromTotal: onlyTakeFromTotal,
          } as EconomicInfo;

          economicDto.push(info);
        });
      });
    }
    var model: EconomicInfoExtended = {
      infos: economicDto,
      photos: this.photos,
    };
    this.economicEmmiter.emit(model);

    this.obliterate();
  }
}
