<div class="row mb-4">
  <div class="col">
    <h2 i18n="Trailers label">Trailers</h2>
  </div>
  <div *ngIf="auth.isTenantAdmin$ | async" class="col-auto d-flex align-items-center">
      <app-import-export class="me-2"
        filenamePrefix="trailers"
        [importFactory]="importFactory"
        [exportFactory]="exportFactory"></app-import-export>
    <a routerLink="/trailers/new" class="btn btn-sm btn-success">
      <i class="bi bi-plus"></i> <ng-container i18n="@@add">Add</ng-container>
    </a>
  </div>
</div>
<div class="row mb-4">
  <div class="col-12 col-md mb-3 mb-md-0 pagination-wrapper">
    <app-pagination [page]="(repo.pageNumber$ | async) || 1"
      [total]="(repo.paginationData$ | async)?.lastPage || 0"
      (pageChange)="service.loadPage($event).subscribe()"></app-pagination>
  </div>
  <div class="col-12 col-md-auto">
    <app-sort-dropdown [sortingOptions]="sortOptions"
      [sorting]="repo.sort$ | async"
      (sortingChange)="service.sort($event).subscribe()"></app-sort-dropdown>
  </div>
</div>
<div class="row mb-4">
    <div class="col">
      <app-spinner *ngIf="repo.isLoading$ | async"></app-spinner>
      <app-trailer-list [trailers]="repo.page$ | async"
        (delete)="service.delete($event).subscribe()"></app-trailer-list>
    </div>
</div>
