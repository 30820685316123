<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row mb-3">
    <div class="col-12 col-md-6 col-lg-4">
        <div class="form-group form-floating mb-3">
          <app-datalist-select [options]="vehicleOptions"
            [(activeValue)]="form.value.vehicleId"
            label="Vehicle" i18n-label="Vehicle label"></app-datalist-select>
        </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
        <div class="form-group form-floating mb-3">
          <app-datalist-select [options]="trailerOptions"
            [(activeValue)]="form.value.trailerId"
            label="Trailer" i18n-label="Trailer label"></app-datalist-select>
        </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3"
        [appValidateState]="form.get('defaultPause')">
        <input formControlName="defaultPause" type="number" class="form-control"
               placeholder="Default pause, min" i18n-placeholder="@@defaultPause">
        <label for="defaultPause" i18n="@@defaultPause">Default pause, min</label>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating h-100 pb-3 d-grid">
        <button type="submit" class="btn btn-primary shadow-sm" i18n="@@save">Save</button>
      </div>
    </div>
  </div>
</form>
