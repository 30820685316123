import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UrlIdResolverService } from 'src/app/modules/shared/services/url-id-resolver.service';
import { CompaniesRepository, Company } from 'src/app/state/companies.repository';
import { CompaniesService } from 'src/app/state/companies.service';

@Component({
  selector: 'app-company-create-page',
  templateUrl: './company-create-page.component.html',
  styleUrls: ['./company-create-page.component.scss']
})
export class CompanyCreatePageComponent implements OnInit {
  company$: Observable<Company> | null = null;
  id: string | undefined;
  submitErrors: string[] | null = null;
  returnUrl: string | undefined;

  constructor(
    private companiesService: CompaniesService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private repo: CompaniesRepository,
    private urlId: UrlIdResolverService,
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'];
    });
  }

  ngOnInit(): void {
    this.company$ = this.urlId.resolve(
      this.activatedRoute.paramMap,
      id => this.companiesService.loadOne(id),
      id => this.repo.one(id),
      id => this.repo.statusOne(id),
      id => this.id = id
    )
  }

  createCompany(company: Partial<Company>) {
    this.submitErrors = null;
    let updateResult: Observable<Company> | undefined;
    if(this.id === 'new'){
      updateResult = this.companiesService.add(company);

    } else if(this.id){
      updateResult = this.companiesService.update(this.id , company);
    }
    if (updateResult) {
      updateResult.subscribe({
        complete: () => {
          if (this.returnUrl === undefined) {
            this.returnUrl = "/companies/clients";
          }
          this.router.navigate([this.returnUrl]);
        },
        error: data => this.submitErrors = data
      });
    }
  }

}
