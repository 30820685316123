import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-model-card',
  templateUrl: './model-card.component.html',
  styleUrls: ['./model-card.component.scss']
})
export class ModelCardComponent implements OnInit {
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() icon?: string;
  @Input() date?: Date;
  @Input() routerLinkBase!: string;
  @Input() roles?: string[];
  @Input() id!: string;
  @Input() isDeleteDisabled = true;
  @Input() isEditDisabled = false;
  @Input() returnUrl?: string;

  @Output() deleteClick = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  handleDeleteClick($event: Event) {
    $event.preventDefault();
    if (!this.isDeleteDisabled) {
      this.deleteClick.emit();
    }
  }

}
