<li class="list-group-item">
    <div class="row">
      <div class="col-12 col-md-auto">
        <div class="row mb-3 mb-md-0">
          <div class="col-auto position-relative" *ngIf="icon">
            <app-entity-avatar [icon]="icon"
              [roles]="roles"></app-entity-avatar>
          </div>
          <div class="col">
            <p>
              <span>{{ title }}</span>
              <br>
              <small>
                <span class="text-muted me-2">{{ subtitle }}</span>
              </small>
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md">
        <div class="row">
          <div class="col d-flex align-items-center text-md-end" [class.mt-3]="isEditDisabled">
            <small class="text-muted mr-2 w-100" i18n="Created date label">Created {{ date | date:'longDate' }}</small>
          </div>
          <div *ngIf="!isEditDisabled" class="col-auto">
            <div class="vr h-100"></div>
          </div>
          <div *ngIf="!isDeleteDisabled" class="col-auto pl-0 d-flex align-items-center">
            <button type="button" class="btn btn-md btn-link w-100 text-danger"
                (click)="handleDeleteClick($event)" title="Delete" i18n-title="Delete button">
              <i class="bi bi-trash"></i>
            </button>
          </div>
          <div *ngIf="!isEditDisabled" class="col-auto pl-0 d-flex align-items-center">
            <a class="btn btn-md btn-link w-100" [routerLink]="[routerLinkBase, id]" [queryParams]="{returnUrl : returnUrl}">
              <i class="bi bi-pencil me-1"></i> <ng-container i18n="@@edit">Edit</ng-container>
            </a>
          </div>
        </div>
      </div>
    </div>
</li>
