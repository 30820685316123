import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-stat-card',
  templateUrl: './stat-card.component.html',
  styleUrls: ['./stat-card.component.scss']
})
export class StatCardComponent implements OnInit {
  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() icon: string = 'bi-check-lg';
  @Input() iconClass: string = 'bg-primary text-white';
  @Input() isLoading: boolean | null = null;

  constructor() { }

  ngOnInit(): void {
  }

}
