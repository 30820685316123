import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import {
  BaseService,
  DEFAULT_ENTITIES_PER_PAGE,
} from './abstract/base.service';
import { CompaniesRepository, Company } from './companies.repository';
import { PaginationData } from '@ngneat/elf-pagination';
import { SortState } from '../modules/shared/pipes/sort.pipe';
import { VendorsRepository } from './vendors.repository';

const API = '/api/companies/canbevendor';

@Injectable({
  providedIn: 'root',
})
export class VendorsService extends BaseService<Company> {
  constructor(http: HttpClient, repo: VendorsRepository) {
    super(API, http, repo);
  }
}
