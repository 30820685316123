import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilterOptions } from './workdays.repository';
import { map, Observable } from 'rxjs';

const API = '/api/trips';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  constructor(private http: HttpClient) {}

  featuredExport(tripIds: string[], timeZone: number = 0): Observable<string> {
    return this.http
      .post<Blob>(`${API}/csv/options/${timeZone}`, tripIds, {
        responseType: 'blob' as any,
      })
      .pipe(
        map((resp) => {
          const blob = new Blob([resp], { type: resp.type });
          return window.URL.createObjectURL(blob);
        })
      );
  }

  featuredPhotosExport(tripIds: string[]): Observable<string> {
    return this.http
      .post<Blob>(`${API}/zip/options`, tripIds, {
        responseType: 'blob' as any,
      })
      .pipe(
        map((resp) => {
          const blob = new Blob([resp], { type: 'application/zip' });
          return window.URL.createObjectURL(blob);
        })
      );
  }
}
