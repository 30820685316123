<div class="row mb-4">
  <div class="col">
    <h2 *ngIf="companiesType === 'clients'" i18n="Clients label">Clients</h2>
    <h2 *ngIf="companiesType === 'vendors'" i18n="Vendors label">Vendors</h2>
  </div>
  <div
    class="col-auto d-flex align-items-center"
    *ngIf="
      companiesType === 'clients' &&
      (authRepo.hasFeature$(TenantFeatures.Economic) | async)
    "
  >
    <button
      class="btn btn-sm btn-outline-primary"
      i18n="@Economics import"
      (click)="sync()"
      [class.disabled]="isSync"
    >
      Import from economics
      <i *ngIf="!isSync && !isSyncError" class="bi bi-arrow-repeat"></i
      ><i
        *ngIf="!isSync && isSyncError"
        [class.text-danger]="isSyncError"
        class="bi bi-x-octagon"
      ></i>
      <app-spinner *ngIf="isSync"></app-spinner>
    </button>
  </div>
  <div
    *ngIf="
      (auth.isTenantAdmin$ | async) &&
      checkCompanyType(
        authRepo.hasFeature$(TenantFeatures.Economic) | async,
        companiesType
      )
    "
    class="col-auto d-flex align-items-center"
  >
    <app-import-export
      class="me-2"
      filenamePrefix="companies"
      [importFactory]="importFactory"
      [exportFactory]="exportCompanyFactory"
    ></app-import-export>
    <a
      routerLink="/companies/new"
      *ngIf="
        (auth.isTenantAdmin$ | async) &&
        checkCompanyType(
          authRepo.hasFeature$(TenantFeatures.Economic) | async,
          companiesType
        )
      "
      [queryParams]="{ returnUrl: returnUrl }"
      class="btn btn-sm btn-success"
    >
      <i class="bi bi-plus"></i> <ng-container i18n="@@add">Add</ng-container>
    </a>
  </div>
</div>
<div class="row mb-4">
  <div class="col-12 col-md mb-3 mb-md-0 pagination-wrapper">
    <app-pagination
      [page]="(repo.pageNumber$ | async) || 1"
      [total]="(repo.paginationData$ | async)?.lastPage || 0"
      (pageChange)="
        service.loadPageT($event, 10, companiesType === 'vendors').subscribe()
      "
    ></app-pagination>
  </div>
  <div class="col-12 col-md-auto">
    <app-sort-dropdown
      [sortingOptions]="sortOptions"
      [sorting]="repo.sort$ | async"
      (sortingChange)="
        service.sortT($event, 10, companiesType === 'vendors').subscribe();
        search = ''
      "
    ></app-sort-dropdown>
  </div>
</div>
<div class="row">
  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 mb-2">
    <div class="form-group form-floating">
      <input
        id="search"
        [(ngModel)]="search"
        (ngModelChange)="loadBySearch($event)"
        type="text"
        autocomplete="off"
        class="form-control"
        placeholder="Search"
        i18n-placeholder="Search placeholder @@google_search"
      />
      <label for="google_search" i18n="@@google_search">Search</label>
    </div>
  </div>
  <div
    *ngIf="
      companiesType === 'clients' &&
      (authRepo.hasFeature$(TenantFeatures.Economic) | async)
    "
    class="col-lg-6 col-md-6 col-sm-6 col-xs-6 mb-2"
  >
    <div class="btn-group w-100 box-normal">
      <div class="form-group form-floating mb-3 w-100 h-100">
        <app-datalist-select
          [options]="templateOptions"
          [activeValue]="templateId"
          (activeValueChange)="updateValue($event)"
          label="Template"
          i18n-label="Template label"
        ></app-datalist-select>
      </div>
      <button
        [class.disabled]="
          !templateId || !companiesTList || companiesTList.length === 0
        "
        class="btn btn-lg btn-primary w-100"
        style="height: 59px"
        i18n="BulkTemplateUpdate"
        (click)="updateTemplates()"
      >
        Update template
      </button>
    </div>
  </div>
</div>
<div class="row mb-4">
  <div class="col">
    <app-spinner *ngIf="repo.isLoading$ | async"></app-spinner>
    <app-company-list
      *ngIf="!(repo.isLoading$ | async)"
      (companiesChosen)="companiesTList = $event"
      [companiesList]="companiesTList"
      [companies]="companiesList | async"
      (delete)="service.delete($event).subscribe()"
      [returnUrl]="returnUrl"
    ></app-company-list>
  </div>
</div>
