<div class="card shadow-md">
  <div class="mt-2 text-center">
    <div class="mt-4 mb-1">
      <h6>{{ replaceWithOnly(column?.danskName!) }}</h6>
      <i
        style="font-size: 26px"
        class="bi bi-eye-fill"
        (click)="column!.isVisible = false"
        *ngIf="column?.isVisible"
      ></i>
      <i
        style="font-size: 26px"
        class="bi bi-eye-slash-fill"
        (click)="column!.isVisible = true"
        *ngIf="!column?.isVisible"
      ></i>
    </div>
  </div>
</div>
