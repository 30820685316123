<div
  class="modal fade"
  bsModal
  [config]="{ show: true }"
  id="changeParkedModal"
  tabindex="-1"
  #changeParkedModal
  aria-hidden="true"
  (onHidden)="cancel.emit()"
>
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content bg-lights border-0">
      <div class="modal-header">
        <h6
          class="modal-title"
          id="changeModalLabel"
          i18n="Title for @ParkedTripsDriver"
        >
          Skift chauffør: <br />
          {{ clientName }} <br />
          {{ date }}
        </h6>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          i18n-aria-label="Close label @@close"
          (click)="cancel.emit()"
        ></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="form" enctype="multipart/form-data">
          <div class="mb-3">
            <div
              [appValidateState]="form.get('driverId')"
              class="form-group form-floating"
            >
              <app-datalist-select
                [multiple]="false"
                [options]="driverOptions"
                [(activeValue)]="form.value.driverId"
                (activeValueChange)="updateDriverId($event)"
                label="Select Drivers"
                i18n-label="Label orderDriver Id"
              ></app-datalist-select>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer justify-content-center">
        <button class="btn btn-primary" (click)="onSubmit()" i18n="@@save">
          Save
        </button>
        <button
          class="btn btn-secondary"
          (click)="cancel.emit()"
          i18n="@@close"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
