import { Component, OnInit, Output, EventEmitter, Input, HostListener } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { Feature, TenantFeatures } from 'src/app/state/feature.repository';
import { Tenant } from 'src/app/state/tenants.repository';

const FEATURE_NAMES: { [f:string]: string } = {
  [TenantFeatures.Accessory]: $localize `:Accessory label:Accessory`,
  [TenantFeatures.Cleaning]: $localize `:Cleaning label:Cleaning`,
  [TenantFeatures.ContactPerson]: $localize `:Contact person label:Contact person`,
  [TenantFeatures.Note]: $localize `:Note label:Note`,
  [TenantFeatures.Products]: $localize `:Products label:Products`,
  [TenantFeatures.Reference]: $localize `:Reference label:Reference`,
  [TenantFeatures.Vendor]: $localize `:Vendor label:Vendor`,
  [TenantFeatures.Order]: $localize `:Order label:Order`,
  [TenantFeatures.Economic]: $localize `:Economic label:E-conomic`,
  [TenantFeatures.EconomicOrder]: $localize `:EconomicOrder label:EconomicOrder`
};

@Component({
  selector: 'app-tenant-form',
  templateUrl: './tenant-form.component.html',
  styleUrls: ['./tenant-form.component.scss']
})
export class TenantFormComponent implements OnInit {
  @Input() editedTenant?: Tenant | null;
  form?: UntypedFormGroup;
  file: File | null = null;

  @Input() set features(value: Feature[] | null) {
    if (!value) {
      this.featureOptions = null;
    } else {
      this.featureOptions = value.map(x => ({
        value: x.id,
        label: FEATURE_NAMES[x.name]
      }));
    }
  }

  @Output() tenantSubmit = new EventEmitter<Partial<Tenant>>();

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    if(this.form) {
      if((event && event.item(0)) instanceof File) {
        this.file = event && event.item(0);
        this.form.value.image = this.file;
      }
    }
  }

  featureOptions: DatalistOption[] | null = null;

  constructor(private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [this.editedTenant?.name, Validators.required],
      image: [this.editedTenant?.image],
      cvr: [this.editedTenant?.cvr],
      maxAdministrators: [this.editedTenant?.maxAdministrators, Validators.required],
      maxDrivers: [this.editedTenant?.maxDrivers, Validators.required],
      address: [this.editedTenant?.address],
      mainMail: [this.editedTenant?.mainMail],
      remarks:[this.editedTenant?.remarks],
      phoneNumber: [this.editedTenant?.phoneNumber],
      appSecretToken:[this.editedTenant?.appSecretToken],
      agreementGrantToken: [this.editedTenant?.agreementGrantToken],
      layoutNumber: [this.editedTenant?.layoutNumber || 0],
      featureIds: [this.editedTenant?.features?.map(x => x.id)]
    });
  }

  updateValue(control: string, value: any) {
    const controlObject = this.form?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  onSubmit() {
    if (this.form && !this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.tenantSubmit.emit({
      name: this.form?.value?.name.toString(),
      image: this.file,
      cvr: this.form?.value?.cvr,
      maxAdministrators: this.form?.value?.maxAdministrators,
      maxDrivers: this.form?.value?.maxDrivers,
      address: this.form?.value?.address,
      mainMail: this.form?.value?.mainMail,
      remarks: this.form?.value?.remarks,
      phoneNumber: this.form?.value?.phoneNumber,
      featureIds: this.form?.value?.featureIds,
      agreementGrantToken: this.form?.value?.agreementGrantToken,
      layoutNumber: this.form?.value?.layoutNumber,
      appSecretToken: this.form?.value?.appSecretToken
    });
  }
}
