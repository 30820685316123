import { Injectable } from '@angular/core';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';
import { Company } from './companies.repository';

export const SortOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'name' },
  {
    label: $localize`:Sort label Description:Description`,
    property: 'description',
  },
  { label: $localize`:Sort label Date:Date`, property: 'createdAt' },
];

@Injectable({ providedIn: 'root' })
export class VendorsRepository extends BaseRepository<Company> {
  constructor() {
    super('vendors', SortOptions);
  }
}
