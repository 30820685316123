<div class="row h-100">
    <div class="col-10 col-md-6 col-lg-5 col-xl-3 mx-auto mt-auto pt-4 text-center mb-100px ">
        <img class="mw-100 mb-4" [alt]="env.name" src="/assets/img/header_logo.webp"/>
        <p class="text-muted mb-4" i18n="Sign in label">Sign in now by entering<br>your email and password</p>
        <form class="d-grid gap-2" [formGroup]="loginForm" (ngSubmit)="login()">
            <div class="form-group form-floating" [class.is-invalid]="loginForm.get('email')?.errors && loginForm.get('email')?.touched" >
                <input formControlName="email" type="text" class="form-control" id="email" placeholder="Email" i18n-placeholder="Email placeholder @@email">
                <label class="form-control-label" for="email" i18n="@@email">Email</label>
            </div>
            <div class="form-group form-floating" [class.is-invalid]="loginForm.get('password')?.errors && loginForm.get('password')?.touched">
                <input formControlName="password" type="password" class="form-control " id="password" placeholder="Password" i18n-placeholder="Password placeholder @@password">
                <label class="form-control-label" for="password" i18n="@@password">Password</label>
                <button type="button" class="btn btn-link text-danger tooltip-btn" data-bs-toggle="tooltip"
                    data-bs-placement="left" id="passworderror" [hidden]="!title" [title]="title">
                    <i class="bi bi-info-circle"></i>
                </button>
            </div>
            <div class="form-check float-start">
                <input class="form-check-input" formControlName="rememberMe" id="rememberMe" type="checkbox">
                <label class="text-white float-start" for="rememberMe" i18n="Remember user label">Remember me</label>

            </div>
            <button type="submit" [disabled]="repo.isLoading$ | async"
                class="btn btn-lg btn-default btn-block mt-3">
                <ng-container *ngIf="!(repo.isLoading$ | async)" i18n="Button sign in">Sign in</ng-container>
                <app-spinner *ngIf="repo.isLoading$ | async"></app-spinner>
            </button>
            <p class="mb-0"><a routerLink="/forgotpassword" class="btn btn-link text-white" i18n="Button forgot password">Forgot your password?</a></p>
        </form>
    </div>
</div>
