<h1 i18n="Active Drivers">Active Drivers</h1>
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-4 col-md-8 col-lg-6">
      <label for="StartDate" class="col-xs-3 col-sm-auto col-form-label">Fra</label>
      <input [(ngModel)]="default_start_value" (ngModelChange)="ListSubmit()" id="StartDate" type="date" class="form-control" name="StartDate">
    </div>
    <div class="col-sm-4 col-md-8 col-lg-6">
      <label for="EndDate" class="col-xs-3 col-sm-auto col-form-label">Til</label>
      <input [(ngModel)]="default_end_value" (ngModelChange)="ListSubmit()" id="EndDate" type="date" class="form-control" name="EndDate">
    </div>
  </div>
</div>
<app-active-drivers-list [activeDriversData]="activeDrivers">
</app-active-drivers-list>

