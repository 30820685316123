import { Component, Input, OnInit } from '@angular/core';
import { sortBy } from 'src/app/modules/shared/pipes/sort.pipe';
import { Product } from 'src/app/state/products.repository';
import { Trip, TripEvent } from 'src/app/state/trips.repository';

@Component({
  selector: 'app-go-info',
  templateUrl: './go-info.component.html',
  styleUrls: ['./go-info.component.scss'],
})
export class GoInfoComponent implements OnInit {
  @Input() set activeTrip(value: Trip | null) {
    if (!value) {
      this.actualTrip = null;
      this.lastCreatedEvent = null;
    } else {
      this.actualTrip = value;
      this.lastCreatedEvent = sortBy(
        this.actualTrip.tripEvents.filter((x) => x.type !== 'Weighing'),
        { parameter: { property: 'createdAt' }, direction: 'desc' }
      )[0];
    }
  }
  actualTrip: Trip | null = null;
  lastCreatedEvent: TripEvent | null = null;
  noteModalShow: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  getDanishEventType(type: string): string {
    if (type === 'TripStart') {
      return 'Start';
    }
    if (type === 'TripEnd') {
      return 'Slut';
    }
    if (type === 'Load') {
      return 'Laste';
    }
    if (type === 'Unload') {
      return 'Losse';
    }
    return '';
  }
}
