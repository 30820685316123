import { Component, OnInit } from '@angular/core';
import { UiRepository } from 'src/app/state/ui.repository';

@Component({
  selector: 'app-headermenu',
  templateUrl: './headermenu.component.html',
  styleUrls: ['./headermenu.component.scss']
})
export class HeadermenuComponent implements OnInit {
  
  constructor(public repo: UiRepository) { }

  ngOnInit(): void {

  }
}
