import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as dayjs from 'dayjs';
import { sortBy } from 'src/app/modules/shared/pipes/sort.pipe';
import { DayjsService } from 'src/app/modules/shared/services/dayjs.service';
import { AuthRepository, UserSettings } from 'src/app/state/auth.repository';
import { Trip } from 'src/app/state/trips.repository';

@Component({
  selector: 'app-parked-trips-modal',
  templateUrl: './parked-trips-modal.component.html',
  styleUrls: ['./parked-trips-modal.component.scss'],
})
export class ParkedTripsModalComponent implements OnInit {
  display: string = 'block !important';
  opacity: string = '1 !important';
  @Input() trips: Trip[] | null = null;
  @Input() children: Trip[] | null = null;
  @Output() cancel = new EventEmitter<void>();
  @Output() resume = new EventEmitter<string>();
  @Output() delete = new EventEmitter<string>();
  @Output() reject = new EventEmitter<string>();
  @Input() userSettings: UserSettings | null = null;
  @Input() isLoading: boolean | null = null;
  constructor(
    public ngDay: DayjsService,
    public router: Router,
    public authRepo: AuthRepository
  ) {}

  ngOnInit(): void {}

  obliterate() {
    this.trips = [];
  }

  findChilds(id: string) {
    var ids: string[] = [];
    if (this.children) {
      var check = true;
      while (check) {
        var order = this.children.find((x) => x.parentId === id);
        if (order) {
          id = order.id;
          ids.push(id);
        } else {
          check = false;
          return ids;
        }
      }
    }
    return [];
  }

  findChild(id: string) {
    if (this.children) {
      return this.children.find((x) => x.parentId === id);
    }
    return null;
  }

  getTripEvent(trip: Trip, direction: 'asc' | 'desc') {
    return sortBy(trip.tripEvents, {
      parameter: { property: 'createdAt' },
      direction: direction,
    })[0];
  }
  toDate(str: string) {
    return this.ngDay.dayjs(str).toDate();
  }

  toCorrectDate(date: Date, type?: string) {
    if (type === 'day') {
      return this.ngDay.dayjs
        .utc(date)
        .local()
        .format('DD/MM/YYYY')
        .slice(0, 16);
    }
    if (type === 'time') {
      return this.ngDay.dayjs.utc(date).local().format('H:mm').slice(0, 16);
    } else {
      return this.ngDay.dayjs
        .utc(date)
        .local()
        .format('H:mm DD/MM/YYYY')
        .slice(0, 16);
    }
  }
}
