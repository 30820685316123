import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { DayjsService } from 'src/app/modules/shared/services/dayjs.service';

import { Workday, WorkdayEvent } from 'src/app/state/workdays.repository';

@Component({
  selector: 'app-day-info-form',
  templateUrl: './day-info-form.component.html',
  styleUrls: ['./day-info-form.component.scss']
})
export class DayInfoFormComponent implements OnInit {
  @Input() dayEvent: WorkdayEvent | null = null;  
  @Input() isAdminPage: string | null| undefined;
  @Input() status: "error" | "success" | "pending" | "idle" | undefined;
  @Input() isLoading: boolean | null = null;
  @Input() addPause: boolean | null = null;
  @Input() workDay$: Observable<Workday> | null = null;
  @Output() eventSubmit = new EventEmitter<Partial<WorkdayEvent>>();
  form: UntypedFormGroup | null = null;
  isAdmin?: boolean;
  
  constructor(
    private formBuilder: UntypedFormBuilder,
    public ngDay: DayjsService
  ) { }

  ngOnInit(): void {    
    if (this.isAdminPage) {
      if (this.isAdminPage === "true") {
        this.isAdmin = true;
      }
      else {
        this.isAdmin = false;
      }
    }
    
    const dayObj = this.ngDay.dayjs.utc(this.dayEvent?.eventTime).local();
    if(this.dayEvent) {
      this.form = this.formBuilder.group({
        date: [dayObj.format("YYYY-MM-DD")],
        time: [dayObj.format("HH:mm:ss")],
        pause: [this.roundFloat(this.dayEvent?.duration)]
      });
    }
    if(!this.dayEvent && this.addPause) {
      this.workDay$?.subscribe(x => {
        if(x) {
          let startTime = x.workdayEvents.find(x => x.type === 'DayStart')?.eventTime;          
          if(startTime) {            
            const pauseObj = this.ngDay.dayjs.utc(startTime).local();
            this.form = this.formBuilder.group({
              date: [pauseObj.format("YYYY-MM-DD")],
              time: [pauseObj.format("HH:mm:ss")],
              pause: [0]
            });
          }
          
        }
      });      
      
    }
  }
  roundFloat(duration: number | undefined | null){
    if(duration) {
      return Math.round(duration * 100) / 100;
    }
    return duration;
  }
  onSubmit(){
    if (this.form && !this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    const date = this.form?.value?.date.toString();
    const time = this.form?.value?.time.toString();
    const date_time = this.ngDay.dayjs(date + ' ' + time).utc().toDate();

    if(this.dayEvent) {      
      this.eventSubmit.emit({
        eventTime: date_time,
        duration: this.roundFloat(this.form?.value?.pause),
        type: this.dayEvent?.type
      });
    }
    if(!this.dayEvent && this.addPause) {
      this.eventSubmit.emit({
        eventTime: date_time,
        duration: this.roundFloat(this.form?.value?.pause),
        type: 'Pause'
      });
    }
    
  }
}
