import { Component } from '@angular/core';

@Component({
  selector: 'app-big-spinner',
  templateUrl: './big-spinner.component.html',
  styleUrls: ['./big-spinner.component.scss']
})
export class BigSpinnerComponent {

}
