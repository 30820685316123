import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthRepository } from 'src/app/state/auth.repository';
import { Trailer } from 'src/app/state/trailers.repository';

@Component({
  selector: 'app-trailer-list',
  templateUrl: './trailer-list.component.html',
  styleUrls: ['./trailer-list.component.scss']
})
export class TrailerListComponent implements OnInit {
  @Input() trailers: Trailer[] | null = null;
  @Output() delete = new EventEmitter<string>();
  deleteConfirmation: Trailer | null = null;

  constructor(public auth: AuthRepository) { }
  
  ngOnInit() {
  }

}
