<div class="mb-3">
  <app-file-uploader
    (FileSubmit)="addPhoto($event)"
    [class.isDisabled]="restrictEdit"
  ></app-file-uploader>
  <div class="d-flex flex-wrap">
    <div *ngFor="let commentImage of photos?.controls; let i = index">
      <div class="image">
        <img
          class="comment-img"
          (click)="openImageModal(i)"
          src="{{ commentImage.value.imagePath }}"
        />
        <div class="delete-ico" *ngIf="!restrictEdit">
          <i class="bi bi-trash3-fill red-ico" (click)="deletePhoto(i)"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<app-image-slider
  [urls]="imgUrls"
  (cancel)="closeImageSlider()"
></app-image-slider>
