import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  combineLatest,
  map,
  Observable,
  of,
  Subscription,
  switchMap,
} from 'rxjs';
import { ConnectionService } from 'src/app/modules/shared/services/connection.service';
import { LocationService } from 'src/app/modules/shared/services/location.service';
import { RequestsResolverRunner } from 'src/app/modules/shared/services/requests-resolver';
import { UrlIdResolverService } from 'src/app/modules/shared/services/url-id-resolver.service';
import {
  AccessoriesRepository,
  Accessory,
} from 'src/app/state/accessories.repository';
import { AccessoriesService } from 'src/app/state/accessory.service';
import { AuthRepository, UserRoles } from 'src/app/state/auth.repository';
import { CleaningsRepository } from 'src/app/state/cleaning.repository';
import {
  CompaniesRepository,
  Company,
} from 'src/app/state/companies.repository';
import { CompaniesService } from 'src/app/state/companies.service';
import { EProductsRepository } from 'src/app/state/eproducts.repository';
import { TenantFeatures } from 'src/app/state/feature.repository';
import { Product, ProductsRepository } from 'src/app/state/products.repository';
import { ProductsService } from 'src/app/state/products.service';
import { TripEventsRepository } from 'src/app/state/tripevents.repository';
import { TripEventsService } from 'src/app/state/tripevents.service';
import {
  Trip,
  TripEvent,
  TripsRepository,
} from 'src/app/state/trips.repository';
import { TripsService } from 'src/app/state/trips.service';
import { WorkdaysRepository } from 'src/app/state/workdays.repository';

const LOCATION_PERMISSION_ERROR = $localize`:Location permission error:Please allow access to the location service in your browser`;
const VEHICLE_ERROR = $localize`:Trip event page vehicle settings error:Vehicle is not selected`;
const TRIP_ERROR = $localize`:Trip event page error while loading current trip:Could not load current trip`;
const TRIP_STARTED_ERROR = $localize`:Trip event page error because trip is started:Trip has already started`;
const TRIP_ENDED_ERROR = $localize`:Trip event page error because trip is ended:Trip has already ended`;

@Component({
  selector: 'app-trip-event-page',
  templateUrl: './trip-event-page.component.html',
  styleUrls: ['./trip-event-page.component.scss'],
})
export class TripEventPageComponent implements OnInit, OnDestroy {
  dataSubscription?: Subscription;
  TenantFeatures = TenantFeatures;
  doubleActionError: string = '';
  companies: Company[] | null = null;
  subComp: Subscription | null = null;
  products: Product[] | null = null;
  subProd: Subscription | null = null;
  submitErrors: string[] | null = null;
  id: string | undefined;
  isAdmin: 'true' | 'false' = 'false';
  trip?: Trip | null = null;
  tripId?: string;
  initialEvent$?: Observable<TripEvent | undefined>;
  action: 'start' | 'stop' | 'unload' | 'load' | 'edit' | 'takephoto' | null =
    null;
  activeTrip: Trip | null = null;
  activeTripVehicle: string | null = null;
  activeTripTrailer: string | null = null;
  isLoading$ = combineLatest([
    this.authRepo.isSettingsLoading$,
    this.accessoryRepo.isLoading$,
    this.cleaningRepo.isLoading$,
    this.companiesRepo.isLoading$,
    this.productsRepo.isLoading$,
    this.eproductRepo.isLoading$,
  ]).pipe(
    map(([au, ac, cl, co, pr, epr]) => au || ac || cl || co || pr || epr)
  );
  isActionResolved = false;
  userId: string | null = null;

  constructor(
    public authRepo: AuthRepository,
    public companiesRepo: CompaniesRepository,
    public companiesService: CompaniesService,
    public productService: ProductsService,
    public productsRepo: ProductsRepository,
    public eproductRepo: EProductsRepository,
    public cleaningRepo: CleaningsRepository,
    public accessoryService: AccessoriesService,
    public accessoryRepo: AccessoriesRepository,
    private workdaysRepo: WorkdaysRepository,
    public tripsRepo: TripsRepository,
    private router: Router,
    private route: ActivatedRoute,
    private tripsService: TripsService,
    private tripEventService: TripEventsService,
    private tripEventRepo: TripEventsRepository,
    private urlId: UrlIdResolverService,
    private locationService: LocationService,
    private offlineResolver: RequestsResolverRunner,
    public isOnlineService: ConnectionService
  ) {
    this.authRepo.id$.subscribe((a) => (this.userId = a));

    this.initialEvent$ = this.route.paramMap.pipe(
      switchMap((params) => {
        this.isAdmin =
          params.get('isAdmin') && params.get('isAdmin') === 'true'
            ? 'true'
            : 'false';
        const id = params.get('id');
        return id
          ? this.tripEventRepo.one(id)
          : this.tripsRepo.lastActiveEvent$;
      })
    );
    this.subAcc1 = this.accessoryService.loadDeleted().subscribe();
    this.companiesService.loadAsListwithRepo().subscribe((x) => {
      this.subComp = this.companiesRepo.all$.subscribe((x) => {
        this.companies = x;
      });
    });
    this.subCompanies1 = this.companiesService
      .loadDeletedAsListwithRepo()
      .subscribe();

    this.subProd = this.productsRepo.all$.subscribe((x) => {
      this.products = x;
    });
    this.subProduct1 = this.productService
      .loadDeletedAsListwithRepo()
      .subscribe();

    this.subProduct2 = this.productsRepo.allDeleted$.subscribe(
      (x) => (this.deletedProducts = x)
    );

    this.subAcc2 = this.accessoryRepo.allDeleted$.subscribe(
      (x) => (this.deletedAccessories = x)
    );

    this.subCompanies2 = this.companiesRepo.allDeleted$.subscribe(
      (x) => (this.deletedCompanies = x)
    );
  }
  deletedAccessories: Accessory[] | null = null;
  deletedProducts: Product[] | null = null;

  ngOnDestroy(): void {
    this.subAcc1?.unsubscribe();
    this.subAcc2?.unsubscribe();
    this.subProduct1?.unsubscribe();
    this.subProduct2?.unsubscribe();
    this.subCompanies1?.unsubscribe();
    this.subCompanies2?.unsubscribe();
    this.subComp?.unsubscribe();
    this.subProd?.unsubscribe();
    this.dataSubscription?.unsubscribe();
  }

  deletedCompanies: any;
  subCompanies1: any;
  subCompanies2: any;
  subProduct1: any;
  subAcc1: any;
  subProduct2: any;
  subAcc2: any;

  pickUpAddress: string | undefined = undefined;
  deliveryAddress: string | undefined = undefined;

  ngOnInit(): void {
    this.tripsRepo.activeTrip$.subscribe((x) => {
      this.pickUpAddress = x?.pickUpAddress;
      this.deliveryAddress = x?.deliveryAddress;
    });

    this.initialEvent$ = this.route.paramMap.pipe(
      switchMap((params) => {
        this.isAdmin =
          params.get('isAdmin') && params.get('isAdmin') === 'true'
            ? 'true'
            : 'false';
        const id = params.get('id');
        return id
          ? this.tripEventRepo.one(id)
          : this.tripsRepo.lastActiveEvent$;
      })
    );
    this.dataSubscription = this.route.paramMap
      .pipe(
        map((params) => [params.get('action'), params.get('id')]),
        switchMap(([action, id]) => {
          if (
            action === 'start' ||
            action === 'stop' ||
            action === 'unload' ||
            action === 'load' ||
            action === 'takephoto'
          ) {
            this.action = action;
          } else if (id && this.isOnlineService.isOnline()) {
            this.action = 'edit';
            return this.urlId.resolve(
              this.route.paramMap,
              (id) => this.tripEventService.loadOne(id),
              (id) => this.tripEventRepo.one(id),
              (id) => this.tripEventRepo.statusOne(id),
              (id) => (this.id = id)
            );
          } else {
            this.router.navigate(['/not-found'], { skipLocationChange: true });
          }
          return of(null);
        }),
        this.offlineResolver.useUnsyncedChanges()
      )
      .subscribe(() => {
        const day = this.workdaysRepo.getActiveWorkday();
        const trip = this.tripsRepo.getActiveTrip();

        this.mergeNamePlate(trip);
        if (!day && trip && this.action === 'start') {
          this.router.navigate(['/go']);
        } else {
          this.mergeNamePlate(trip);
          this.isActionResolved = true;
        }
      });
  }

  confirmRedirect() {
    localStorage.setItem('go_reload', 'true');
    this.router.navigate(['/go']);
  }

  mergeNamePlate(trip?: Trip | null) {
    if (!trip) {
      return;
    }
    this.activeTrip = trip;
    this.activeTripVehicle =
      trip && trip.vehicle
        ? `[${trip.vehicle.numberplate}] ${trip.vehicle.name}`
        : null;
    this.activeTripTrailer =
      trip && trip.trailer
        ? `[${trip.trailer.numberplate}] ${trip.trailer.name}`
        : null;
  }

  async createEvent(event: Partial<TripEvent>) {
    this.submitErrors = null;
    let submitObservable: Observable<any> | null = null;

    if (this.action !== 'edit') {
      try {
        const coordinates = await this.locationService.getPosition();
        event = {
          ...event,
          ...coordinates,
        };
        let company = this.companies?.find((x) => x.id === event.clientId);
        let products = this.products?.filter((x) =>
          event.productIds?.includes(x.id)
        );
        event.client = company;
        event.products = products;
      } catch {
        this.submitErrors = [LOCATION_PERMISSION_ERROR];
        return;
      }
    }

    //var isOnline = this.isOnlineService.isOnline();

    switch (this.action) {
      case 'start':
        const settings = this.authRepo.getActiveSettings();
        if (!settings || !settings.vehicleId) {
          this.submitErrors = [VEHICLE_ERROR];
          return;
        }
        submitObservable = this.tripsService.start(
          event,
          settings.vehicleId,
          settings.trailerId,
          this.userId ?? undefined,
          event.tripFiles
        );
        break;
      case 'stop':
        if (!this.activeTrip) {
          this.submitErrors = [TRIP_ERROR];
          this.doubleActionError = TRIP_ERROR;
          return;
        }
        submitObservable = this.tripsService.tripAction(
          'TripEnd',
          this.activeTrip,
          event,
          event.tripFiles
        );
        break;
      case 'edit':
        if (this.id) {
          submitObservable = this.tripEventService.update(this.id, event);
        }
        break;
      case 'unload':
        if (!this.activeTrip) {
          this.submitErrors = [TRIP_ERROR];
          return;
        }
        submitObservable = this.tripsService.tripAction(
          'Unload',
          this.activeTrip,
          event,
          event.tripFiles
        );
        break;
      case 'load':
        if (!this.activeTrip) {
          this.submitErrors = [TRIP_ERROR];
          return;
        }
        submitObservable = this.tripsService.tripAction(
          'Load',
          this.activeTrip,
          event,
          event.tripFiles
        );
        break;
      case 'takephoto':
        this.id =
          this.activeTrip?.tripEvents.find((x) => x.type === 'TripStart')?.id ||
          undefined;
        if (this.activeTrip?.id) {
          submitObservable = this.tripsService.takephoto(
            this.id || '',
            event.tripFiles
          );
        }
        break;
      default:
        return;
    }

    if (submitObservable) {
      submitObservable.subscribe({
        complete: () => {
          if (this.action === 'edit') {
            if (
              this.authRepo.isInRole(UserRoles.Driver) &&
              this.isAdmin === 'false'
            ) {
              this.tripsService.loadOverview().subscribe({
                complete: () => {
                  this.router.navigate(['/trips']);
                },
                error: (data) => (this.submitErrors = data),
              });
            } else {
              if (this.action === 'edit' && this.isAdmin === 'true') {
                this.tripsService.loadOverview().subscribe({
                  complete: () => {
                    this.router.navigate(['/drivers/trips']);
                  },
                  error: (data) => (this.submitErrors = data),
                });
              } else {
                this.tripsService.loadOverview().subscribe({
                  complete: () => {
                    this.router.navigate(['/trips']);
                  },
                  error: (data) => (this.submitErrors = data),
                });
              }
            }
          } else {
            this.router.navigate(['/go']);
          }
        },
        error: (data) => {
          this.submitErrors = data;
          if (data.error === 'Trip has already started') {
            this.doubleActionError = TRIP_STARTED_ERROR;
            this.submitErrors = [TRIP_STARTED_ERROR];
          }
          if (data.error === 'Trip has already ended') {
            this.doubleActionError = TRIP_ENDED_ERROR;
            this.submitErrors = [TRIP_ENDED_ERROR];
          }
          if (data.error === 'Could not load current trip') {
            this.doubleActionError = TRIP_ERROR;
            this.submitErrors = [TRIP_ERROR];
          }
        },
      });
      localStorage.removeItem('pickupaddress');
      localStorage.removeItem('deliveryaddress');
    }
  }
}
