import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Trailer } from 'src/app/state/trailers.repository';
import { Vehicle } from 'src/app/state/vehicles.repository';

@Component({
  selector: 'app-trailer-form',
  templateUrl: './trailer-form.component.html',
  styleUrls: ['./trailer-form.component.scss']
})
export class TrailerFormComponent implements OnInit {
  @Input() editedTrailer: Trailer | null = null;
  form?: UntypedFormGroup; 

  @Output() trailerSubmit = new EventEmitter<Partial<Trailer>>();

  constructor(private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [this.editedTrailer?.name, Validators.required],   
      numberplate: [this.editedTrailer?.numberplate, Validators.required]   
    });
  }

  onSubmit() {
    if (this.form && !this.form?.valid) {
      this.form.markAllAsTouched();
      return;
    }
    let name = this.form?.value?.name.toString();
    let numberplate = this.form?.value?.numberplate.toString();

    this.trailerSubmit.emit({ name, numberplate });
  }


}
