import { Injectable } from '@angular/core';
import { Store, createState, withProps, select } from '@ngneat/elf';
import { withEntities, selectAllEntities, upsertEntities, selectEntity } from '@ngneat/elf-entities';
import { createRequestsCacheOperator, createRequestsStatusOperator, selectRequestStatus, updateRequestCache, updateRequestsStatus, withRequestsCache, withRequestsStatus } from '@ngneat/elf-requests';
import { combineLatest, map } from 'rxjs';
import { sortBy, SortOption, SortState } from 'src/app/modules/shared/pipes/sort.pipe';
import { UserRoles } from './auth.repository';
import { BaseRepository } from './abstract/base.repository';

export interface Driver {
  id: string;
  email: string;
  phoneNumber: string;
  name: string;
  surname: string;
  title: string;
  location: string;
  birthday?: Date;
  tenantId?: string;
  createdAt: Date;
  image: File | null;
  imagePath: string;
  roles: UserRoles[];
}


@Injectable({ providedIn: 'root' })
export class ActiveDriversRepository {

  constructor() {
  }

}
