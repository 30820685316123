import { Inject, Injectable, Optional } from '@angular/core';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  of,
  OperatorFunction,
  switchMap,
  tap,
} from 'rxjs';
import { AuthRepository } from 'src/app/state/auth.repository';
import { Request, RequestsRepository } from 'src/app/state/requests.repository';

export const REQUESTS_RESOLVER = 'APP_REQUESTS_RESOLVER';

export interface RequestsResolver {
  canResolve(request: Request): boolean;
  modifyLocalState(request: Request): void;
}

@Injectable({ providedIn: 'root' })
export class RequestsResolverRunner {
  constructor(
    private requests: RequestsRepository,
    @Optional()
    @Inject(REQUESTS_RESOLVER)
    private resolvers: RequestsResolver[],
    private authRepo: AuthRepository
  ) {
    if (!this.resolvers) {
      this.resolvers = [];
    }
  }

  useUnsyncedChanges(): OperatorFunction<any, void> {
    return (source$) =>
      source$.pipe(
        // once initialized, check if we need to update the UI
        // according to changes that were made offline and are
        // not yet synced
        switchMap(() =>
          combineLatest([
            this.requests.all$,
            this.authRepo.id$,
            this.authRepo.isAuthenticated$,
          ])
        ),
        filter(([req, usr, auth]) => !!req && !!usr && auth),
        distinctUntilChanged((prev, curr) => prev[1] === curr[1]),
        tap(([requests, user]) => {
          requests
            .filter((req) => req.userId === user)
            .forEach((req) => {
              this.resolvers.filter((x) => x.canResolve(req));
            });
        }),
        switchMap(() => of(void 0))
      );
  }

  // useUnsyncedChanges(): OperatorFunction<any, void> {
  //   return (source$) =>
  //     source$.pipe(
  //       // once initialized, check if we need to update the UI
  //       // according to changes that were made offline and are
  //       // not yet synced
  //       switchMap(() =>
  //         combineLatest([
  //           this.requests.all$,
  //           this.authRepo.id$,
  //           this.authRepo.isAuthenticated$,
  //         ])
  //       ),
  //       filter(([req, usr, auth]) => !!req && !!usr && auth),
  //       distinctUntilChanged((prev, curr) => prev[1] === curr[1]),
  //       tap(([requests, user]) => {
  //         requests
  //           .filter((req) => req.userId === user)
  //           .forEach((req) => {
  //             this.resolvers
  //               .filter((x) => x.canResolve(req))
  //               .forEach((x) => x.modifyLocalState(req));
  //           });
  //       }),
  //       switchMap(() => of(void 0))
  //     );
  // }
}
