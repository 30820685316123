import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { getRegistry } from "@ngneat/elf";
import { from, of, OperatorFunction } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { ScheduledForRetryError } from "../modules/core/interceptors/retry.interceptor";
import { SettingsRequestsResolverService } from "../modules/truck/services/settings-requests-resolver.service";
import { ActiveDriversRepository,Driver } from "./activedrivers.repository";

const API = '/api/activedrivers';

@Injectable({ providedIn: 'root' })
export class ActiveDriversService {
  constructor(
    private http: HttpClient,
    private repo: ActiveDriversRepository,
  ) { }

  getActivedrivers(StartDate?: string, EndDate?: string) {

    if (!StartDate || StartDate.length > 10) {
      StartDate = new Date().toDateString();
    }
    if (!EndDate || EndDate.length>10) {
      EndDate = new Date().toDateString();
    }
    return this.http.get<Driver[]>(`${API}/getdrivers/${StartDate}/${EndDate}`)
  }

}
