import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/state/auth.service';
import { User, UsersRepository } from 'src/app/state/users.repository';
import { UsersService } from 'src/app/state/users.service';
import { AuthRepository, UserRoles } from 'src/app/state/auth.repository';
import { TenantsService } from 'src/app/state/tenants.service';
import { TenantsRepository } from 'src/app/state/tenants.repository';
import { combineLatest } from 'rxjs';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';

@Component({
  selector: 'app-impersonation-modal',
  templateUrl: './impersonation-modal.component.html',
  styleUrls: ['./impersonation-modal.component.scss'],
})
export class ImpersonationModalComponent implements OnInit {
  console = console;
  selectedUserId: string | undefined;
  userOptions$ = combineLatest([
    this.repo.allSorted$,
    this.tenantsRepo.tenants$,
  ]).pipe(
    map(([users, tenants]) =>
      users.map((x) => {
        let option: DatalistOption = {
          value: x.id,
          label: x.email,
          sublabel: `${x.name} ${x.surname}`,
        };
        if (x.tenantId && tenants && tenants.length) {
          const tenantName = tenants.find((t) => t.id === x.tenantId)?.name;
          if (tenantName) {
            option.sublabel += `${option.sublabel ? ' ' : ''}(${tenantName})`;
          }
        }
        return option;
      })
    )
  );

  constructor(
    public repo: UsersRepository,
    private service: UsersService,
    private auth: AuthService,
    private authRepo: AuthRepository,
    private tenantsService: TenantsService,
    private tenantsRepo: TenantsRepository,
    private router: Router
  ) {}

  ngOnInit(): void {}

  load() {
    this.service.load().subscribe();
    if (this.authRepo.isInRole(UserRoles.Superamin)) {
      this.tenantsService.load().subscribe();
    }
  }

  impersonate() {
    if (!this.selectedUserId) {
      return;
    }
    this.auth.impersonate(this.selectedUserId).subscribe(() => {
      let isAccessed = false;
      for (let role in UserRoles) {
        if (this.authRepo.isInRole(role)) {
          isAccessed = true;
        }
      }
      if (!isAccessed) {
        this.router.navigate(['/profile']);
        return;
      }
      this.router.navigate(['/']);
    });
  }
}
