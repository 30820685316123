<!-- profile picture -->
<app-user-header-image></app-user-header-image>

<!-- followers and connections -->
<div *ngIf="repo.isDriver$ | async" class="row bg-whiter py-4">
  <div>
    <div class="container">
      <div class="row">
        <ng-container *ngFor="let item of tripStats, let i = index">
          <div class="col-sm">
            <div class="text-center">
              <app-spinner class="text-muted" *ngIf="!isLoaded[i]"></app-spinner>
              <div [routerLink]="['/drivers', items[i]]">
                <h6 class="mb-1">
                  <span class="badge bg-success" i18n="Trip stats counter">{{ item === 1 ? item + " tur" : item + " ture"  }} </span>
                </h6>
                <p class="text-muted small badge" i18n="Label today">{{i === 0 ? "I dag" : i === 1 ? "Denne uge" : i === 2 ? "Denne måned" : ""}}</p>
              </div>
            </div>
          </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="row py-4 mb-4">
    <div class="col d-grid" *ngIf="repo.isSuperAdmin$ | async">
      <app-impersonation-modal></app-impersonation-modal>
    </div>
    <div class="col d-grid">
      <a routerLink="/profile/edit" class="btn btn-primary shadow-sm" i18n="Edit profile button">Edit profile</a>
    </div>
  </div>
