<div class="modal fade" bsModal [config]="{ show: true }" id="refreshModal" tabindex="-1" 
    *ngIf="errorMessage" aria-hidden="true" (onHidden)="confirm.emit()">
    <div class="modal-dialog modal-dialog-centered modal-sm">
      <div class="modal-content bg-lights border-0">
        <div class="modal-header">
          <h6 class="modal-title" id="refreshModalLabel" i18n="Title for confirmation dialog">Confirm your action</h6>
          <button type="button" class="btn-close" aria-label="Close" i18n-aria-label="Close label @@close"
            (click)="confirm.emit()"></button>
        </div>
        <div class="modal-body">
          <div class="form-floating mb-3">
            <p class="mb-1" i18n="Confirm refresh message start">You are not allowed to proceed because:</p>
            <p class="mb-1"><strong>{{ errorMessage }}</strong></p>
            <p class="mb-0" i18n="Confirm refresh message end">Please refresh the page by clicking Refresh.</p>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
            <button class="btn btn-success me-2"
                (click)="confirm.emit()" i18n="Refresh button"><i class="bi bi-arrow-repeat"></i> Refresh</button>            
        </div>
      </div>
    </div>
</div>