import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { DayjsService } from 'src/app/modules/shared/services/dayjs.service';
import { OrderFilter } from 'src/app/state/orders.repository';
import { User } from 'src/app/state/users.repository';

@Component({
  selector: 'app-orders-filter',
  templateUrl: './orders-filter.component.html',
  styleUrls: ['./orders-filter.component.scss'],
})
export class OrdersFilterComponent implements OnInit {
  form?: UntypedFormGroup;
  driverOptions: DatalistOption[] | null = null;
  @Output() filterEmiter = new EventEmitter<Partial<OrderFilter>>();
  @Input() isLoading = false;
  @Input() set drivers(value: User[] | null) {
    if (!value) {
      this.driverOptions = null;
    } else {
      this.driverOptions = value
        .filter((x) => !x.deletedAt)
        .map((x) => ({
          value: x.id,
          label: x.name + ' ' + x.surname,
        }));
    }
    this.sendFilters();
  }

  @Input() set usersDeleted(value: User[] | null) {
    if (!value) {
      this.usersDeletedOptions = null;
    } else {
      this.usersDeletedOptions = value.map((x) => ({
        value: x.id,
        label: x.name + ' ' + x.surname,
      }));
    }
  }

  usersDeletedOptions: DatalistOption[] | null = [];

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    const from = localStorage.getItem('from');
    const to = localStorage.getItem('to');
    const drivers = localStorage.getItem('driversSelected');

    this.form = this.formBuilder.group({
      date_from: [from ? from : new Date().toISOString().split('T')[0]],
      date_to: [to ? to : new Date().toISOString().split('T')[0]],
      google_search: [''],
      driverIds: [drivers ? JSON.parse(drivers) : ''],
    });
  }

  updateValue(control: string, value: any) {
    const controlObject = this.form?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  sendFilters() {
    let search =
      this.form?.value?.google_search.length >= 3
        ? this.form?.value?.google_search
        : '';
    this.filterEmiter.emit({
      date_from: this.form?.value.date_from,
      date_to: this.form?.value.date_to,
      search: search,
      driverIds: this.form?.value?.driverIds,
    });
  }
}
