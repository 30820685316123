<igx-time-picker
  #picker
  showClearIcon="false"
  (valueChange)="sendNewValue($event)"
  [(value)]="date"
  [inputFormat]="format"
  [overlaySettings]="{ outlet: element }"
  [spinLoop]="false"
  [minValue]="'00:00:00'"
  mode="dialog"
  [disabled]="disabled"
>
  <label class="picker" igxLabel>{{ title }}</label>
  <ng-template igxTimePickerActions>
    <div class="action-buttons">
      <button
        i18n="timepicker-cancel"
        igxButton="flat"
        (click)="picker.cancelButtonClick()"
      >
        cancel
      </button>
      <button
        i18n="timepicker-done"
        igxButton="flat"
        (click)="picker.okButtonClick()"
      >
        done
      </button>
      <button
        i18n="timepicker-now"
        igxButton="flat"
        (click)="selectNow(picker)"
      >
        now
      </button>
    </div>
  </ng-template>
</igx-time-picker>
