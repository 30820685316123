import { Injectable } from '@angular/core';
import { buildIdPromise, buildUrlPromise, Request } from 'src/app/state/requests.repository';
import { TrailersRepository } from 'src/app/state/trailers.repository';
import { TripsRepository } from 'src/app/state/trips.repository';
import { VehiclesRepository } from 'src/app/state/vehicles.repository';
import { RequestsResolver } from '../../shared/services/requests-resolver';
import { OrdersRepository } from 'src/app/state/orders.repository';

const TRIPS_API = "/api/trips";
const ORDERS_API = "/api/orders/"
const OFFLINE_ERROR = $localize `:Could not load info while offline:Not available offline`;

@Injectable({
  providedIn: 'root'
})
export class TruckRequestsResolverService implements RequestsResolver {

  constructor(
    private vehicles: VehiclesRepository,
    private trailers: TrailersRepository,
    private trips: TripsRepository,
    private orders: OrdersRepository
  ) { }

  canResolve(request: Request): boolean {
    return request.url.startsWith(TRIPS_API) || request.url.includes(ORDERS_API);
  }

  modifyLocalState(request: Request): void {
    const vehiclePlaceholder = { 
      id: '',
      name: '', 
      numberplate: OFFLINE_ERROR, 
      createdAt: request.createdAt, 
      isBeingUsed: false 
    };

    if(request.url.includes(ORDERS_API) ) {      
      if(!request.url.includes('reject')) {
        const order = {
          id: buildUrlPromise(request.url.split(ORDERS_API)[1]),
          vehicle: this.vehicles.getOne(request.body.vehicleId) || vehiclePlaceholder, 
          trailer: request.body.trailerId ? this.trailers.getOne(request.body.trailerId) || vehiclePlaceholder : null, 
          createdAt: request.createdAt,
          tripEvents: [ ]
        };      
        this.orders.upsertOrder(order);
        this.orders.setActiveId(order.id);
      }

      if(request.url.includes('events') && request.url.includes('orders')) {
        const orderIdFromUrl = request.url.split('api/orders/')[1].split('/events')[0];
        const orderState = this.orders.getOrder(orderIdFromUrl);
  
        if(orderState) {        
          orderState.tripEvents.push({ 
            ...request.body, 
            id: buildUrlPromise(request.url.split(ORDERS_API)[1])
          });
          this.orders.upsertOrder(orderState);
          this.orders.setActiveId(orderIdFromUrl);
          if (request.body.type === 'TripEnd') {
            this.orders.setActiveId(null);            
          }
        }
      }

      if(request.url.endsWith('reject')) {
        const orderIdFromUrl = request.url.split('api/orders/')[1].split('/reject')[0];
        const order = this.orders.getOrder(orderIdFromUrl);
      
        if (order) {
          this.orders.remove(orderIdFromUrl);
        }
      }
     }

    if (request.url.endsWith(TRIPS_API)) {
      // there's a trip POST request enqueued      
      const trip = {
        id: buildIdPromise(request.id),
        vehicle: this.vehicles.getOne(request.body.vehicleId) || vehiclePlaceholder, 
        trailer: request.body.trailerId ? this.trailers.getOne(request.body.trailerId) || vehiclePlaceholder : null, 
        createdAt: request.createdAt,
        tripEvents: [ ]
      };            
      this.trips.upsertTrip(trip);
      this.trips.setActiveId(trip.id);      
    } 
    if(request.url.includes(TRIPS_API) && request.url.endsWith('events')) {
      // trip event
      const tripIdFromUrl = request.url.split('/').slice(-2)[0];
      const tripState = this.trips.getTrip(tripIdFromUrl);
      
      if (tripState) {
        tripState.tripEvents.push({ 
          ...request.body, 
          id: buildIdPromise(request.id)
        });
        this.trips.upsertTrip(tripState);
        if (request.body.type === 'TripEnd') {
          this.trips.setActiveId(null);
        }
      }      
    }
  }
}
