import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit {
  @Input() urls = new Array<string>();  
  @Output() fileSubmit = new EventEmitter<File[]>();
  @Output() urlSubmit = new EventEmitter<string[]>();
  @Input() files? = new Array<File>();

  constructor() { }
  ngOnInit(): void {
    
  }
  addImage(event: any) {
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.urls.push(e.target.result);
        }
        reader.readAsDataURL(file);
        this.files?.push(file);
        this.fileSubmit.emit(this.files);
        this.urlSubmit.emit(this.urls);
      }
    }
  }
}