import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  PhotoEventDto,
  PhotoModalEventDto,
  TripDto,
  TripEventDto,
  TripPhoto,
} from 'src/app/state/trips.repository';
import { ConnectionService } from '../../services/connection.service';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss'],
})
export class ImageSliderComponent implements OnInit {
  @Input() urls?: string[];
  @Input() photos?: TripPhoto[];
  //@Input() weighEvents?: TripDto[];
  @Input() weighEvents?: TripDto[];
  @Input() usualEvents?: TripDto[];
  @Input() modalEvents?: TripEventDto[];
  @Output() weighPhotoEmmiter = new EventEmitter<PhotoEventDto>();
  @Output() usualPhotoEmmiter = new EventEmitter<PhotoEventDto>();
  @Output() modalPhotoEmmiter = new EventEmitter<PhotoModalEventDto>();
  @Output() cancel = new EventEmitter<void>();
  @Output() moveToStart = new EventEmitter<string>();
  @Output() moveToWeight = new EventEmitter<string>();

  @Input() moveEnabled: boolean = false;

  @Input() slideIndex = 0;
  display: string = 'block !important';
  opacity: string = '1 !important';

  constructor(public connection: ConnectionService) {}

  ngOnInit(): void {}

  deleteMovedFile() {
    this.urls?.splice(this.slideIndex, 1);
    this.change();
  }

  plusSlide() {
    if (this.urls?.length && this.slideIndex < this.urls?.length - 1) {
      this.slideIndex += 1;
    } else {
      this.slideIndex = 0;
    }
  }

  change() {
    if (this.slideIndex > 0 && this.urls) {
      this.slideIndex =
        this.urls?.length >= this.slideIndex
          ? this.slideIndex - 1
          : this.urls?.length;
    }
  }

  minusSlide() {
    if (this.slideIndex > 0) {
      this.slideIndex -= 1;
    } else if (this.urls?.length) {
      {
        this.slideIndex = this.urls?.length - 1;
      }
    }
  }
  refreshIndex() {
    this.urls = [];
    this.weighEvents = [];
    this.usualEvents = [];
    this.modalEvents = [];
    this.slideIndex = 0;
  }
}
