import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Trip } from 'src/app/state/trips.repository';

@Component({
  selector: 'app-start-day-form',
  templateUrl: './start-day-form.component.html',
  styleUrls: ['./start-day-form.component.scss']
})
export class StartDayFormComponent implements OnInit {
  @Input() set currentTrip(value: Trip | null | undefined) {
    if(value) {
      this.tripStart = value?.tripEvents
      .find(x => x.type === 'TripStart')
      ?.eventTime;
    }
  }
  @Input() isLoading: boolean | null = null;
  @Output() startDay = new EventEmitter<void>();

  tripStart?: Date | null;

  constructor() { }

  ngOnInit(): void {
  }

}
