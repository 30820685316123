<div class="row h-100">
  <div class="col-10 col-md-6 col-lg-5 col-xl-3 mx-auto mt-auto pt-4 text-center mb-100px">
      <img class="mw-100 mb-4" [alt]="env.name" src="/assets/img/header_logo.webp"/>
      <h3 class="mb-4" i18n="Label reset password">Reset your password</h3>
      <app-error-alert class="mb-3 text-dark" [errors]="errors"></app-error-alert>
      <form *ngIf="state === 'idle' || state === 'processing'" class="d-grid gap-2" [formGroup]="form" (ngSubmit)="reset()">
        <div class="form-group form-floating" [appValidateState]="form.get('password')">
          <input formControlName="password" type="password" class="form-control" placeholder="New password" i18n-placeholder="New password label">
          <label class="form-control-label" for="password" i18n="New password label">New password</label>
        </div>
        <button type="submit" class="btn btn-lg btn-default btn-block mt-3" 
            [disabled]="state === 'processing'">
            <ng-container *ngIf="state === 'idle'" i18n="Change password">Change password</ng-container>
            <app-spinner *ngIf="state === 'processing'"></app-spinner>
        </button>
      </form>
      <a *ngIf="state === 'idle' || state === 'processing'" routerLink="/signin" target="_self" 
        class="text-white btn btn-lg btn-link" i18n="Label link to signin page">Already have password?</a>
      <div *ngIf="state === 'success'">
        <p class="h1 text-center mb-2">
            <i class="bi bi-patch-check-fill text-success"></i>
        </p>
        <p class="text-muted mb-4" i18n="Password changed message">Your password has been successfully changed</p>
        <a routerLink="/signin" class="btn btn-lg btn-default btn-block mt-3" i18n="Button sign in">Sign in</a>
      </div>
  </div>
</div>
