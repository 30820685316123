import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, tap } from "rxjs";
import { StatiscticsPeriods, StatisticsRepository, trackStatsRequestsStatus, TruckStatistics } from "./statistics.repository";

const API = '/api/statistics';

@Injectable({ providedIn: 'root' })
export class StatisticsService {

    constructor(
        private http: HttpClient,
        private repo: StatisticsRepository
    ) { }

    load(period: StatiscticsPeriods, onlyOwnStats = false) {
        return this.http
            .get<TruckStatistics>(`${API}/${period}${onlyOwnStats ? '?isOnlyOwn=true' : ''}`)
            .pipe(
                tap(stats => this.repo.set(period, stats, onlyOwnStats)),
                trackStatsRequestsStatus(`${this.repo.name}_${period}${onlyOwnStats ? '_own' : ''}`)                
            );
    }
}