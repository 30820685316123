<div class="row mb-4">
    <div class="col">
      <h2 i18n="Products label">Products</h2>
    </div>    
    <div *ngIf="auth.isTenantAdmin$ | async" class="col-auto d-flex align-items-center">
      <app-import-export class="me-2"
        filenamePrefix="products"
        [importFactory]="importFactory"
        [exportFactory]="exportFactory"></app-import-export>
      <a routerLink="/products/new" class="btn btn-sm btn-success">
        <i class="bi bi-plus"></i> <ng-container i18n="@@add">Add</ng-container>
      </a>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-12 col-md mb-3 mb-md-0 pagination-wrapper">
      <app-pagination [page]="(repo.pageNumber$ | async) || 1"
        [total]="(repo.paginationData$ | async)?.lastPage || 0"
        (pageChange)="service.loadPage($event).subscribe()"></app-pagination>
    </div>
    <div class="col-12 col-md-auto">
      <app-sort-dropdown [sortingOptions]="sortOptions"
        [sorting]="repo.sort$ | async"
        (sortingChange)="service.sort($event).subscribe(); search = ''"></app-sort-dropdown>
    </div>
  </div>
  <div>
    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 mb-2">
      <div class="form-group form-floating">
        <input
          id="search"          
          [(ngModel)]="search"
          (ngModelChange)="loadBySearch($event)"
          type="text"
          autocomplete="off"
          class="form-control"
          placeholder="Search"
          i18n-placeholder="Search placeholder @@google_search"
        />
        <label for="google_search" i18n="@@google_search">Search</label>
      </div>
    </div>
  </div>  
  <div class="row mb-4">
      <div class="col">
        <app-spinner *ngIf="repo.isLoading$ | async"></app-spinner>
        <app-product-list [products]="repo.page$ | async"
          (delete)="service.delete($event).subscribe()"></app-product-list>
      </div>
  </div>
