<ng-container>
  <div class="card shadow-sm yellow text-white">
    <div
      class="card-body text-center d-flex align-items-center justify-content-center"
    >
      <h2 class="mt-3 mb-3">
        <app-spinner *ngIf="isLoading"></app-spinner>
        <span i18n="@@myOrders">My Orders</span>
      </h2>
    </div>
  </div>
  <ng-container *ngFor="let order of orders">
    <div
      class="mt-1 card shadow-sm bg-secondary text-white"
      *ngIf="order.isOrder"
      [hidden]="hideNotSatisfied(order)"
    >
      <div class="card-body row ms-2 me-2 d-flex align-items-center">
        <div class="p-0 col-sm col-12">
          <h5>{{ order?.client?.name }}</h5>
          <h5>{{ order?.comment }}</h5>
          <h5 *ngIf="order && order.plannedToStartAt">
            {{ toCorrectDate(order.plannedToStartAt, "day") }}
          </h5>
          <h5>
            <span *ngIf="order && order.plannedToStartAt">{{
              toCorrectDate(order.plannedToStartAt, "time")
            }}</span>
            {{ order?.plannedToStartAt ? "-" : "" }}
            <span *ngIf="order && order.plannedToEndAt">{{
              toCorrectDate(order.plannedToEndAt, "time")
            }}</span>
          </h5>
        </div>
        <div
          class="p-0 col-sm col-12 d-flex flex-column align-items-center justify-content-center"
        >
          <div class="d-flex">
            <h1 class="me-2">Info</h1>
            <h1
              class="mutable hoverable"
              (click)="router.navigate(['orderinfo/' + order.id])"
            >
              <i class="bi bi-info-circle"></i>
            </h1>
          </div>
          <!-- <h1 class="mutable ms-2 me-2 hoverable" *ngIf="findChild(order.id)" (click)="router.navigate(['orderinfo/' + findChild(order.id)?.id])"> <i class="bi bi-link-45deg strong"></i></h1>                       -->
          <div
            *ngIf="findChild(order.id)"
            [class.dissable-ico]="!workDay"
            class="ms-2 me-2 d-flex scroll"
          >
            <div
              class="mutable hoverable"
              *ngFor="let id of findChilds(order.id)"
            >
              <i
                (click)="router.navigate(['orderinfo/' + id])"
                class="bi bi-link-45deg linked-ico"
              ></i>
            </div>
          </div>
        </div>

        <div
          class="p-0 col-sm col-12 d-flex align-items-center justify-content-sm-end justify-content-between"
        >
          <button
            [class.disabled-success]="
              !workDay || isOrderActive || isTripActive || isDayPaused
            "
            (click)="
              (order.parkedAt && !order.resumedAt) ||
              (order.parkedAt &&
                order.resumedAt &&
                order.parkedAt > order.resumedAt)
                ? resume.emit(order.id)
                : launchStartOrder(order.id)
            "
            class="but card shadow-sm me-2 bg-success text-white d-flex justify-content-center align-items-center"
          >
            <h4 class="mt-1 mb-1">
              {{
                (order.parkedAt && !order.resumedAt) ||
                (order.parkedAt &&
                  order.resumedAt &&
                  order.parkedAt > order.resumedAt)
                  ? "Fortsæt tur"
                  : "Start"
              }}
              <i *ngIf="!isLoadingOrder" class="bi bi-play-fill"></i>
              <app-spinner
                *ngIf="
                  isLoadingOrder &&
                  (!order.parkedAt ||
                    (order.parkedAt &&
                      order.resumedAt &&
                      order.parkedAt < order.resumedAt) ||
                    (order.parkedAt && !order.resumedAt) ||
                    (order.parkedAt &&
                      order.resumedAt &&
                      order.parkedAt > order.resumedAt))
                "
              ></app-spinner>
            </h4>
          </button>
          <button
            (click)="reject(order.id)"
            [class.disabled-reject]="
              !workDay || activeOrder?.id === order.id || isDayPaused
            "
            class="card shadow-sm bg-danger text-white but d-flex justify-content-center align-items-center"
            style="flex: none"
          >
            <h4 class="mt-1 mb-1" i18n="@@rejectGoOrder">
              Reject <i *ngIf="!isLoading" class="bi bi-x-diamond-fill"></i>
              <app-spinner
                *ngIf="
                  isLoadingOrder &&
                  (!order.parkedAt ||
                    (order.parkedAt &&
                      order.resumedAt &&
                      order.parkedAt < order.resumedAt) ||
                    (order.parkedAt && !order.resumedAt) ||
                    (order.parkedAt &&
                      order.resumedAt &&
                      order.parkedAt > order.resumedAt))
                "
              ></app-spinner>
            </h4>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
