<table
  class="d-flex"
  *ngIf="
    actualTrip && actualTrip.tripEvents && actualTrip.tripEvents.length > 0
  "
>
  <div
    class="bordered shadow-sm text-grey w-100 row m-0"
    *ngIf="lastCreatedEvent"
  >
    <div class="card-body col-11 p-0">
      <thead>
        <th>
          <span id="vehicleLabel" class="w-100 me-2">
            <i class="bi bi-truck me-1"></i>
            <span *ngIf="actualTrip.vehicle"
              >[{{ actualTrip.vehicle.numberplate }}]
              {{ actualTrip.vehicle.name }}</span
            >
            <span
              *ngIf="!actualTrip.vehicle"
              i18n="Vehicle is not selected error message @@notSelectedVehicle"
              >Vehicle is not selected</span
            >
          </span>
        </th>
        <th>
          <strong i18n="@@eventClientName">C: </strong>
          <span>{{ lastCreatedEvent.client?.name }}</span>
        </th>
      </thead>
      <thead>
        <th>
          <span class="w-100" *ngIf="actualTrip.trailer">
            <i class="bi bi-minecart me-1"></i>
            <span
              >[{{ actualTrip.trailer.numberplate }}]
              {{ actualTrip.trailer.name }}</span
            >
          </span>
        </th>
        <th>
          <strong i18n="@@eventWeight">W: </strong>
          <span>{{ lastCreatedEvent.weight }}</span>
        </th>
      </thead>
      <thead>
        <th>
          <span>Status: {{ getDanishEventType(lastCreatedEvent.type) }}</span>
        </th>
        <th>
          <strong i18n="@@eventProducts">P: </strong>
          <span
            *ngIf="
              lastCreatedEvent.products && lastCreatedEvent.products[0]?.name
            "
            >{{ lastCreatedEvent.products[0].name
            }}<span
              *ngIf="
                lastCreatedEvent.products[0].name.length >= 20 ||
                (lastCreatedEvent?.products)!.length > 1
              "
              >...</span
            ></span
          >
        </th>
      </thead>
    </div>
    <div
      class="col-1 p-0 m-0 h-100 d-flex align-items-center justify-content-center"
    >
      <div>
        <i class="bi bi-info-circle icon" (click)="noteModalShow = true"></i>
      </div>
    </div>
  </div>
</table>

<app-notes-info-modal
  id="notesModal"
  *ngIf="noteModalShow"
  [tripEvents]="actualTrip?.tripEvents"
  (closeModal)="noteModalShow = false"
></app-notes-info-modal>

<!-- <div class="row ms-1">
    <div class="col-12 col-md-3 col-sm-5 col-lg-3">
        <span id="vehicleLabel" class="w-100 me-2">
            <i class="bi bi-truck me-1"></i>
            <span *ngIf="activeTrip.vehicle">[{{ activeTrip.vehicle.numberplate }}] {{ activeTrip.vehicle.name }}</span>
            <span *ngIf="!activeTrip.vehicle" i18n="Vehicle is not selected error message @@notSelectedVehicle">Vehicle is not selected</span>
        </span>
        <strong i18n="@@eventClientName">C: </strong>
        <span>{{event.client?.name}}</span>
    </div>
    <div class="col-6 col-md-3 col-sm-5 col-lg-3">
        <span class="w-100" *ngIf="activeTrip.trailer">
            <i class="bi bi-minecart me-1"></i>
            <span>[{{ activeTrip.trailer.numberplate }}] {{ activeTrip.trailer.name }}</span>
        </span>
        <strong i18n="@@eventWeight">W: </strong>
        <span>{{event?.weight}}</span>
    </div>
</div>
<div class="row ms-1">
    <div class="col-12 col-md-3 col-sm-5 col-lg-3">
        <span>Status: {{getDanishEventType(event.type)}}</span>
        <strong i18n="@@eventProducts">P: </strong>                                       
        <span *ngIf="event.products && event.products.length > 0">{{event.products[0]?.name}}<span *ngIf="event.products[0].name.length>=20 || event?.products!.length > 1">...</span></span>
    </div>
</div>           -->
