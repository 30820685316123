<h2 class="mb-4" i18n="Trip settings label">Trip settings</h2>
<div class="row mb-4">
    <div class="col-12">
        <h2 *ngIf="isLoading$ | async"><app-spinner></app-spinner></h2>
        <app-trip-settings *ngIf="!(isLoading$ | async)"
          [settings]="authRepo.settings$ | async"
          [vehicles]="vehiclesRepo.all$ | async"
          [trailers]="trailersRepo.all$ | async"
          (settingsSubmit)="updateSettings($event)"></app-trip-settings>
        <app-error-alert title="Could not submit the trip settings" i18n-title="Could not submit trip error message"
          [errors]="submitErrors"></app-error-alert>
    </div>
</div>
