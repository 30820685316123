<div class="mb-4">
    <app-columns-list [columns]="columns"></app-columns-list>

</div>
<div class="row">
    <div class="col-md-2 centered" (click)="updateColumns()">
        <div class="card btn btn-primary shadow-md">
            <div class="text-center">
                <h5 i18n="Save button @@colSave">Save</h5>
            </div>
        </div>
    </div>
    <div *ngIf="resetable" class="col-md-2 centered" (click)="resetOrder()">
        <div class="card btn btn-warning shadow-md">
            <div class="text-center">
                <h5 i18n="Reset button @@colReset">Reset</h5>
            </div>
        </div>
    </div>
</div>





