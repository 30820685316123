import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SharedModule } from '../shared/shared.module';
import { NewsEditComponent } from './news-edit/news-edit.component';
import { NewsFormComponent } from './news-form/news-form.component';
import { NewsPostComponent } from './news-post/news-post.component';
import { NewsComponent } from './news/news.component';

@NgModule({
  declarations: [
    NewsEditComponent,
    NewsFormComponent,
    NewsComponent,
    NewsPostComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CKEditorModule,
  ],
  exports: [
    NewsEditComponent,
    NewsComponent,
    NewsPostComponent
  ]
})
export class NewsModule { }
