import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FilePathContainer,
  FileUploaderService,
} from '../../services/fileuploader.service';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent {
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;
  fileType: string = 'image/*';

  uploadInProgress: boolean = false;
  @Output() FileSubmit = new EventEmitter<FilePathContainer>();

  constructor(private uploadService: FileUploaderService) {}

  selectFile(event: any) {
    var selectedFile = event.target.files[0];
    if (selectedFile) {
      this.uploadInProgress = true;
      this.upload(selectedFile);
    }
  }

  clearInput() {
    const inputEl = this.fileInput.nativeElement;
    inputEl.value = '';
  }

  upload(file: File) {
    this.uploadService.uploadFile(file).subscribe({
      next: (x) => {
        this.FileSubmit.emit(x);
        this.uploadInProgress = false;
      },
      error: () => (this.uploadInProgress = false),
    });
    this.clearInput();
  }
}
