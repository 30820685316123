<div class="container-fluid mt-4">
  <div class="btn-group" role="group">
    <button
      *ngFor="let pageNumber of pages"
      class="btn"
      [class.btn-outline-primary]="page !== pageNumber"
      [class.btn-primary]="page === pageNumber"
      (click)="changePage(pageNumber)"
    >
      {{ pageNumber }}
    </button>

  </div>
</div>
