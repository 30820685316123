import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Product } from 'src/app/state/products.repository';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {
  @Input() products: Product[] | null = null;
  @Output() delete = new EventEmitter<string>();
  deleteConfirmation: Product | null = null;

  constructor() { }

  ngOnInit(): void {
  }

}
