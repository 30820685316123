<div *ngIf="!(connection.isOnline$ | async)" [@enterFromTop]
    class="bg-danger text-white text-center offline-label shadow-sm pb-1">
    <small>
        <i class="bi bi-wifi-off me-2"></i>
        <span i18n="Label that user offline">You are offline</span>
    </small>
    <p *ngIf="features.offlineChanges && (repo.count$ | async) as count">
        <small>(<ng-container i18n="Offline changes counter">{count, plural, =1 {1 unsynced change} other {{{count}} unsynced changes}}</ng-container>)</small>
    </p>
</div>
