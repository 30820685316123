<h2 class="mb-4" *ngIf="id === 'new'" i18n="Create cleaning label">Create cleaning</h2>
<h2 class="mb-4" *ngIf="id !== 'new'" i18n="Edit cleaning label">Edit cleaning</h2>
<div *ngIf="(id === 'new') || (cleaning$ | async)" class="row mb-4">
    <div class="col-12">
        <app-cleaning-form [editedCleaning]="cleaning$ | async"
          (cleaningSubmit)="createCleaning($event)"></app-cleaning-form>
        <app-error-alert title="Could not submit the cleaning info" i18n-title="Could not sumbit cleaning message"
          [errors]="submitErrors"></app-error-alert>          
    </div>
</div>
