import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthRepository } from 'src/app/state/auth.repository';
import { Tenant } from 'src/app/state/tenants.repository';
import { User } from 'src/app/state/users.repository';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {
  @Input() users: User[] | null = null;
  @Input() tenants: Tenant[] | null = null;
  @Input() isDeleteDisabled = true;
  @Output() delete = new EventEmitter<string>();
  deleteConfirmation: User | null = null;
  
  constructor(public auth: AuthRepository) { }

  ngOnInit(): void {
  }
  
  handleDeleteClick(user: User) {    
    if (!this.isDeleteDisabled) {
      this.delete.emit(user.id);
    }
  }
}
