import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Cleaning } from 'src/app/state/cleaning.repository';

@Component({
  selector: 'app-cleaning-form',
  templateUrl: './cleaning-form.component.html',
  styleUrls: ['./cleaning-form.component.scss']
})
export class CleaningFormComponent implements OnInit {
  @Input() editedCleaning: Cleaning | null = null;
  form: UntypedFormGroup | null = null; 

  @Output() cleaningSubmit = new EventEmitter<Partial<Cleaning>>();

  constructor(private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [this.editedCleaning?.name, Validators.required]    
    });
  }

  onSubmit() {
      if (this.form && !this.form.valid) {
        this.form.markAllAsTouched();
        return;
      }
    let name = this.form?.value?.name.toString();    

    this.cleaningSubmit.emit({ name});
  }
}
