<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()" enctype="multipart/form-data">
    <div class="row h-100 mb-4">
      <div class="col-12 col-md-6 col-lg-4">
        <div [appValidateState]="form.get('name')" class="form-group form-floating mb-3">
          <input formControlName="name" type="text" class="form-control"
                 placeholder="Name" i18n-placeholder="Name placeholder @@name">
          <label for="name" i18n="@@name">Name</label>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div [appValidateState]="form.get('description')" class="form-group form-floating mb-3">
          <input formControlName="description" type="text" class="form-control"
                 placeholder="Description" i18n-placeholder="Description placeholder @@description">
          <label for="description" i18n="@@description">Description</label>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="form-group form-floating h-100 pb-3 d-grid">
          <button type="submit" class="btn btn-primary shadow-sm" i18n="@@save">Save</button>
        </div>
      </div>
    </div>
  </form>
