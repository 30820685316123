import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prop'
})
export class PropPipe implements PipeTransform {

  transform(value: any, propName: any): any {
    return value[propName];
  }

}
