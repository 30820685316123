import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseService } from 'src/app/base-service';
import { PhotoEventDto, PhotoModalEventDto, TripDto, TripEventDto, TripPhoto } from 'src/app/state/trips.repository';

@Component({
  selector: 'app-img-slider',
  templateUrl: './img-slider.component.html',
  styleUrls: ['./img-slider.component.scss']
})
export class ImgSliderComponent {
  @Input() urls?: string[];
  @Input() slideIndex = 0;

  display:string = "block !important";
  opacity:string = "1 !important";
  
  constructor(private readonly baseService:BaseService) {   
  }
  
  ngOnInit(): void {
  }
  
  plusSlide() {
    if(this.urls?.length && this.slideIndex < (this.urls?.length - 1)) {
      this.slideIndex += 1;      
    }
    else {
      this.slideIndex = 0;
    }
  }

  change() {
    if(this.slideIndex > 0 && this.urls) {
      this.slideIndex = this.urls?.length >= this.slideIndex  ? this.slideIndex - 1  : this.urls?.length;
    }
    
  }

  minusSlide() {
    if(this.slideIndex > 0) {
      this.slideIndex -= 1;
    }
    else if(this.urls?.length) {
      {
        this.slideIndex = this.urls?.length - 1;
      }
    }
  }
  refreshIndex() {    
    this.baseService.closeImageModal(true);
    this.urls = [];
    this.slideIndex = 0;
  } 
}
