import { Injectable } from '@angular/core';
import { DatalistOption } from '../modules/shared/components/datalist-select/datalist-select.component';

@Injectable({
  providedIn: 'root',
})
export class DataSelectService {
  constructor() {}

  setDataSelectOptions(models?: any): DatalistOption[] | null {
    return models?.map((x: any) => ({
      value: x.id,
      label: x.name,
      sublabel: x.sublabel,
      isActive: x.isActive,
    }));
  }
}
