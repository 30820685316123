<div
  class="modal fade"
  bsModal
  [config]="{ show: true }"
  id="textModal"
  tabindex="-1"
  #textModal
  aria-hidden="true"
  (onHidden)="cancel.emit()"
>
  <div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content bg-lights border-0">
      <div class="modal-header">
        <h6
          class="modal-title"
          id="textBoxModalLabel"
          i18n="Title for @@textBoxModal"
        >
          {{ title }}
        </h6>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          i18n-aria-label="Close label @@close"
          (click)="cancel.emit()"
        ></button>
      </div>
      <div class="modal-body">
        <div class="row mb-3">
          <div class="form-group form-floating mb-3">
            <textarea
              [class.isDisabled]="!mutable"
              id="textbox"
              [innerHtml]="text"
              class="form-control"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button
          class="btn btn-primary me-2"
          (click)="save()"
          i18n="Save button"
        >
          Save
        </button>
        <button
          class="btn btn-secondary"
          (click)="cancelEmit()"
          i18n="@@cancel"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
