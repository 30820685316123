import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, switchMap } from 'rxjs';
import { EnvState } from 'src/app/modules/shared/helpers/env-state';
import { AuthRepository } from 'src/app/state/auth.repository';
import { AuthService } from 'src/app/state/auth.service';
import { TenantFeatures } from 'src/app/state/feature.repository';
import { StatisticsService } from 'src/app/state/statistics.service';
import { UiRepository } from 'src/app/state/ui.repository';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  TenantFeatures = TenantFeatures;
  features = this.env.features;
  
  constructor(
    private auth: AuthService,
    public repo: AuthRepository,
    public ui: UiRepository,
    private router: Router,
    public env: EnvState,
    private service: StatisticsService,
  ) { }

  ngOnInit(): void {
  }

  
  
  menuclose() {
    this.ui.toggleMenuOpened(false);
  }

  logout() {
    this.menuclose();    
    this.auth.logout().subscribe(() => this.router.navigate(['/signin']));    
    return false; // prevent default
  }

  unimpersonate() {
    this.menuclose();
    this.auth.unimpersonate().subscribe(() => {
      this.service
      .load('day')
      .pipe(
        switchMap(() => this.service.load('week')),
        switchMap(() => this.service.load('month'))
      )
      .subscribe();
      this.router.navigate(['/'])
    });
    return false; // prevent default
  }

  toggleDarkMode() {
    this.menuclose();
    this.ui.toggleDarkMode();
    return false; // prevent default
  }
}
