import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth.guard';
import { ApphomelayoutComponent } from '../modules/core/layouts/apphomelayout/apphomelayout.component';
import { AppinnerlayoutComponent } from '../modules/core/layouts/appinnerlayout/appinnerlayout.component';
import { NewsEditComponent } from '../modules/news/news-edit/news-edit.component';
import { NewsPostComponent } from '../modules/news/news-post/news-post.component';
import { NewsComponent } from '../modules/news/news/news.component';
import { UserRoles } from '../state/auth.repository';

const routes: Routes = [
  {
    path: '',
    component: ApphomelayoutComponent,
    canActivateChild: [AuthGuard],

    children: [
      {
        path: 'news',
        component: NewsComponent,
      }
    ]
  },
  {
    path: '',
    component: AppinnerlayoutComponent,
    canActivateChild: [AuthGuard],

    children: [
      {
        path: 'news/:id/edit',
        component: NewsEditComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] }
      },
      {
        path: 'news/new',
        component: NewsEditComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] }
      },
      {

        path: 'news/:id',
        component: NewsPostComponent,
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule]
})
export class NewsRoutingModule { }
