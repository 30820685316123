<app-sort-table-pagination
  [(page)]="_state.page"
  [pages]="pages"
  (pageChange)="setPageData()"
></app-sort-table-pagination>
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-4 col-md-8 col-lg-6">
      <label for="dateFrom" class="col-xs-3 col-sm-auto col-form-label"
        >Fra</label
      >
      <input
        id="dateFrom"
        type="date"
        class="form-control"
        [(ngModel)]="filterOptions!.dateFrom"
        (ngModelChange)="filterChange()"
      />
    </div>
    <div class="col-sm-4 col-md-8 col-lg-6">
      <label for="dateTo" class="col-xs-3 col-sm-auto col-form-label"
        >Til</label
      >
      <input
        id="dateTo"
        type="date"
        class="form-control"
        [(ngModel)]="filterOptions!.dateTo"
        (ngModelChange)="filterChange()"
      />
    </div>
    <div class="col-sm-3 col-md-6 col-lg-4">
      <label class="col-xs-3 col-sm-auto col-form-label">Kunder</label>
      <app-datalist-select
        [options]="clientOptions"
        [(activeValue)]="filterOptions!.clientSelected"
        (activeValueChange)="filterChange()"
        label="Kunder"
        i18n-label="Label client"
      ></app-datalist-select>
    </div>
    <div *ngIf="isAdminPage" class="col-sm-3 col-md-6 col-lg-4">
      <label for="dateTo" class="col-xs-3 col-sm-auto col-form-label"
        >Chauffør</label
      >
      <app-datalist-select
        [options]="driverOptions"
        [(activeValue)]="filterOptions!.driverSelected"
        (activeValueChange)="filterChange()"
        label="Chauffør"
        i18n-label="Label driver"
      ></app-datalist-select>
    </div>
    <div class="col-sm-3 col-md-6 col-lg-4">
      <label for="dateTo" class="col-xs-3 col-sm-auto col-form-label"
        >Produkter</label
      >
      <app-datalist-select
        [options]="productsOptions"
        [multiple]="true"
        [(activeValue)]="filterOptions!.productSelected"
        (activeValueChange)="filterChange()"
        label="Produkter"
        i18n-label="Features label"
      ></app-datalist-select>
    </div>
    <div *ngIf="isAdminPage" class="col-sm-3 col-md-6 col-lg-4 mt-4">
      <label
        for="isApprovedSearch"
        class="col-xs-3 col-sm-auto col-form-label align-middle"
        >Ikke behandlet</label
      >
      <input
        [(ngModel)]="filterOptions!.isApproved"
        (ngModelChange)="filterChange()"
        class="text-center align-middle"
        style="margin-left: 10px; width: 20px; height: 20px"
        type="checkbox"
        aria-label=""
      />
    </div>
    <div class="col-sm-3 col-md-6 col-lg-4 mt-4">
      <app-import-export
        *ngIf="authRepo.isTenantAdmin$ | async"
        class="me-2"
        filenamePrefix="trips"
        [exportFactory]="exportFactory"
      ></app-import-export>
    </div>
    <div class="col-sm-3 col-md-6 col-lg-4 mt-4">
      <app-import-export
        *ngIf="authRepo.isTenantAdmin$ | async"
        class="me-2"
        [titlePrefix]="'Vejesedler Billede '"
        filenamePrefix="fotos/{{ filterOptions!.dateFrom }}/{{
          filterOptions!.dateTo
        }}"
        [filenameExtension]="'.zip'"
        [icon]="'bi bi-file-earmark-zip'"
        [format]="''"
        [exportFactory]="exportPhotoFactory"
      ></app-import-export>
    </div>
  </div>
</div>
<div class="table-responsive custom-scroll">
  <table
    style="margin-top: 15px"
    class="table table-hover table-bordered"
    [class.darkModeCustom]="ui.isDarkMode$ | async"
  >
    <thead>
      <tr>
        <th
          class="text-center align-middle hoverable"
          scope="col"
          *ngFor="let item of simpleColumns; let i = index"
          [hidden]="!item.isVisible"
          sortable="{{ standartVariableNames[i] }}"
          (sort)="onSort($event)"
        >
          {{ item.danskName }}
          <i
            *ngIf="headers?.get(i)?.direction === 'asc'"
            class="bi bi-arrow-up"
          ></i
          ><i
            *ngIf="headers?.get(i)?.direction === 'desc'"
            class="bi bi-arrow-down"
          ></i>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        [class.darkModeCustomTr]="
          record.isApproved ? (ui.isDarkMode$ | async) : ''
        "
        [class.bg-yellow]="record.isOrder"
        [class.bg-approved]="isAdminPage && record.isApproved"
        *ngFor="let record of filteredTrips; let i = index"
        id="ordering{{ i }}"
      >
        <td id="modal{{ i }}" class="text-center align-middle hoverable">
          <a
            class="anchor"
            *ngIf="record.hasLoadUnloadEvents"
            (click)="getDataToSortModal(record)"
            >*</a
          >
        </td>
        <th
          id="Approved{{ i }}"
          *ngIf="isAdminPage"
          class="text-center align-middle"
        >
          <input
            [checked]="record.isApproved"
            [attr.disabled]="isAdminPage ? null : true"
            (change)="changeApprove(record)"
            style="width: 20px; height: 20px"
            type="checkbox"
          />
        </th>
        <td
          id="Note{{ i }}"
          class="text-center align-middle"
          [innerHTML]="record.noteStartEnd"
        ></td>
        <td
          id="TenantNote{{ i }}"
          *ngIf="isAdminPage"
          class="text-center align-middle hoverable"
        >
          <a
            [class.anchor-green]="!record.tenantNote"
            [class.anchor-blue]="record.tenantNote"
            (click)="openTenantComment(record)"
            >*</a
          >
        </td>
        <td id="Numberplate{{ i }}" class="text-center align-middle">
          {{ record.carNumber }}
        </td>
        <td id="Trailer{{ i }}" class="text-center align-middle">
          {{ record.trailerNumber }}
        </td>
        <td
          id="ClientID{{ i }}"
          class="text-center align-middle"
          [innerHTML]="record.clientNumberStartEnd"
        ></td>
        <td
          id="ClientName{{ i }}"
          class="text-center align-middle"
          [innerHTML]="record.clientNameStartEnd"
        ></td>
        <td id="DriverID{{ i }}" class="text-center align-middle">
          {{ record.driverName }}
        </td>

        <td
          id="ProductName{{ i }}"
          class="text-center align-middle"
          [innerHTML]="record.productNameStartEnd"
        ></td>
        <td
          id="Weight{{ i }}"
          class="text-center align-middle"
          [innerHTML]="record.weightStartEnd"
        ></td>
        <td id="TripTotalKm{{ i }}" class="text-center align-middle">
          {{ record.tripKm }}
        </td>
        <td id="Totaltime{{ i }}" class="text-center align-middle">
          {{ convertMS(record.tripDuration) }}
        </td>
        <td
          id="VendorID{{ i }}"
          class="text-center align-middle"
          [innerHTML]="record.vendorIdStartEnd"
        ></td>
        <td
          id="VendorName{{ i }}"
          class="text-center align-middle"
          [innerHTML]="record.vendorNameStartEnd"
        ></td>
        <td
          id="Reference{{ i }}"
          class="text-center align-middle"
          [innerHTML]="record.referenceStartEnd"
        ></td>
        <td
          id="Orderinfo{{ i }}"
          class="text-center align-middle"
          [innerHTML]="record.commentStartEnd"
        ></td>
        <td id="TripStartphotos{{ i }}" class="text-center align-middle">
          <span *ngIf="record.isStartPhotosAdded && record.tripEventStartId">
            <a
              class="btn btn-sm btn-link py-0 p-md-0"
              (click)="getUrls(record.id, 'TripStart')"
            >
              <i class="bi bi-card-image"></i>
            </a>
          </span>
          <br />
          <span>
            <a
              *ngIf="record.tripEventStartId"
              class="btn btn-sm btn-link py-0 p-md-0"
            >
              <label
                for="startImageInput{{ i }}"
                class="hoverable"
                i18n="Label for startimg"
                ><i class="bi bi-plus-lg"></i
              ></label>
              <input
                type="file"
                accept="image/*"
                id="startImageInput{{ i }}"
                (change)="addImage($event, record.id, record.tripEventStartId)"
                style="display: none"
                multiple
              />
            </a>
          </span>
        </td>
        <td id="Weighingphotos{{ i }}" class="text-center align-middle">
          <span *ngIf="record.isWeighingPhotosAdded">
            <a
              class="btn btn-sm btn-link py-0 p-md-0"
              (click)="getUrls(record.id, 'Weighing')"
            >
              <i class="bi bi-card-image"></i>
            </a>
          </span>
          <br />
          <span>
            <a class="btn btn-sm btn-link py-0 p-md-0">
              <label
                for="weighImageInput{{ i }}"
                class="hoverable"
                i18n="Label for weighimg"
                ><i class="bi bi-plus-lg"></i
              ></label>
              <input
                type="file"
                accept="image/*"
                id="weighImageInput{{ i }}"
                (change)="addImage($event, record.id)"
                style="display: none"
                multiple
              />
            </a>
          </span>
        </td>
        <td id="TripStarttime{{ i }}" class="text-center align-middle">
          {{ record.startTime | date : "dd-MM-YYYY HH:mm:ss" }}
        </td>
        <td id="TripStartKm{{ i }}" class="text-center align-middle">
          {{ record.startKm }}
        </td>
        <td id="EditTripStart{{ i }}" class="text-center align-middle">
          <a
            class="btn btn-sm btn-link py-0 p-md-0"
            (click)="navigateTo(record.tripEventStartId!)"
          >
            <i class="bi bi-pencil me-1"></i>
            <ng-container i18n="@@edit">Edit</ng-container>
          </a>
        </td>
        <td id="StartTripposition{{ i }}" class="text-center align-middle">
          <a target="_blank" href="{{ record.startPosition }}">Link</a>
        </td>
        <td id="TripEndphotos{{ i }}" class="text-center align-middle">
          <span *ngIf="record.isEndPhotosAdded">
            <a
              class="btn btn-sm btn-link py-0 p-md-0"
              (click)="getUrls(record.id, 'TripEnd')"
            >
              <i class="bi bi-card-image"></i>
            </a>
          </span>
          <br />
          <span>
            <a
              class="btn btn-sm btn-link py-0 p-md-0"
              *ngIf="record.tripEventEndId"
            >
              <label
                for="endImageInput{{ i }}"
                class="hoverable"
                i18n="Label for endimg"
                ><i class="bi bi-plus-lg"></i
              ></label>
              <input
                type="file"
                accept="image/*"
                id="endImageInput{{ i }}"
                (change)="addImage($event, record.id, record.tripEventEndId)"
                style="display: none"
                multiple
              />
            </a>
          </span>
        </td>
        <td id="TripEndTime{{ i }}" class="text-center align-middle">
          {{ record.endTime | date : "dd-MM-YYYY HH:mm:ss" }}
        </td>
        <td id="TripEndKm{{ i }}" class="text-center align-middle">
          {{ record.endKm }}
        </td>
        <td id="EditTripEnd{{ i }}" class="text-center align-middle">
          <a
            *ngIf="record.tripEventEndId"
            class="btn btn-sm btn-link py-0 p-md-0"
            (click)="navigateTo(record.tripEventEndId)"
          >
            <i class="bi bi-pencil me-1"></i>
            <ng-container i18n="@@edit">Edit</ng-container>
          </a>
        </td>
        <td id="TripEndGPS{{ i }}" class="text-center align-middle">
          <a
            *ngIf="record.endPosition"
            target="_blank"
            href="{{ record.endPosition }}"
            >Link</a
          >
        </td>

        <td
          id="Cleaning{{ i }}"
          class="text-center align-middle"
          [innerHTML]="record.cleaningStartEnd"
        ></td>
        <td
          id="Accessory{{ i }}"
          class="Accessory{{ i }}"
          class="text-center align-middle"
          [innerHTML]="record.accessoryStartEnd"
        ></td>
        <td id="Time{{ i }}" class="text-center align-middle">
          <div>
            <div>{{ calculateDurationForParked(record) }}</div>
            <i class="bi bi-pause strong"></i>
          </div>
        </td>
        <td id="TripInfo{{ i }}" class="text-center align-middle">
          <div *ngIf="record.isOrder">
            <i
              class="bi bi-cone-striped hoverable me-1"
              (click)="openDriversModal(record)"
            ></i>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<app-sort-table-pagination
  [(page)]="_state.page"
  [pages]="pages"
  (pageChange)="setPageData()"
></app-sort-table-pagination>

<app-single-textbox-modal
  *ngIf="selectedTripLog && noteEditing"
  [title]="'Vognmand Bemærkning'"
  [text]="selectedTripLog.tenantNote"
  (stringEmmiter)="addTenantNote($event)"
  (cancel)="cancelEvent()"
></app-single-textbox-modal>

<app-trip-image-slider
  *ngIf="photoUrls"
  [photoUrls]="photoUrls"
  [type]="sliderType"
  (movePhoto)="movePhotos($event)"
  (cancel)="cancelEvent()"
></app-trip-image-slider>

<app-trip-change-driver-modal
  *ngIf="selectedTripLog && driverChanging"
  [driverDatalistOptions]="driverStaticOptions"
  [clientName]="selectedTripLog.clientNameStartEnd"
  [currentId]="selectedTripLog.driverId"
  [date]="selectedTripLog.startTime | date : 'dd-MM-YYYY HH:mm:ss'"
  (confirm)="updateParkedTrip($event)"
  (cancel)="cancelEvent()"
>
</app-trip-change-driver-modal>

<app-sort-table-modal
  id="sortModal"
  *ngIf="selectedTripLog && sortModalActive"
  [tripLog]="selectedTripLog"
  [isAdminPage]="isAdminPage"
  [simpleLoadUnloadColumns]="simpleLoadUnloadColumns"
  (cancel)="cancelEvent()"
></app-sort-table-modal>
