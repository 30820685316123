import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, OperatorFunction, combineLatest, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { BaseService } from './abstract/base.service';
import {
  CommentPhotoDto,
  Order,
  OrdersRepository,
  skipWhileOrdersCached,
  trackOrdersRequestsStatus,
} from './orders.repository';
import { TripEventsRepository } from './tripevents.repository';
import {
  buildIdPromise,
  buildUrlPromise,
  RequestsRepository,
} from './requests.repository';
import { VehiclesRepository } from './vehicles.repository';
import { TrailersRepository } from './trailers.repository';
import { DayjsService } from '../modules/shared/services/dayjs.service';
import {
  Trip,
  TripEvent,
  TripEventType,
  TripsRepository,
  trackTripsRequestsStatus,
} from './trips.repository';
import { sortBy } from '../modules/shared/pipes/sort.pipe';
import { ScheduledForRetryError } from '../modules/core/interceptors/retry.interceptor';
import { FilterOptions } from './workdays.repository';
const OFFLINE_ERROR = $localize`:Could not load info while offline:Not available offline`;
const API = '/api/orders';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  constructor(
    public http: HttpClient,
    public repo: OrdersRepository,
    private tripRepo: TripsRepository,
    private tripEventsRepo: TripEventsRepository,
    private requestsRepo: RequestsRepository,
    private vehicles: VehiclesRepository,
    private trailers: TrailersRepository,
    public ngDay: DayjsService
  ) {}

  loadOrders(
    from?: Date | string,
    to?: Date | string,
    search?: string
  ): Observable<Order[]> {
    const query = [
      `from=${from || ''}`,
      `to=${to || ''}`,
      `search=${search || ''}`,
    ];
    return this.http.get<Order[]>(`${API}/allorders?${query.join('&')}`).pipe(
      tap((res) => {
        this.repo.clear(), this.repo.setOrders(res);
        //skipWhileOrdersCached(this.repo.name);
      })
    );
  }

  add(model: Partial<Order>): Observable<Order> {
    const timeOffset = new Date().getTimezoneOffset();
    return this.http
      .post<Order>(`${API}/createOrder?timeOffset=${timeOffset}`, model)
      .pipe(
        tap((res) => {
          if (!model.isReccurence) {
            this.repo.upsertOrder(res);
          }
        }),
        trackOrdersRequestsStatus('add')
      );
  }
  update(id: string, model: Partial<Order>): Observable<Order> {
    model.type = 'Update';
    return this.http.patch<Order>(`${API}/${id}`, model).pipe(
      tap((res) => {
        this.repo.upsertOrder(res), trackOrdersRequestsStatus(res.id);
      })
    );
  }

  updateParkedDriver(id: string, driverId: string) {
    return this.http
      .patch<Order>(`${API}/${id}/updateparkeddriver/${driverId}`, {
        driverId: driverId,
      })
      .pipe(
        tap((trip) => {
          this.repo.upsertOrder(trip);
        }),
        trackOrdersRequestsStatus(id)
      );
  }

  updateParkedDriverWidthChilds(id: string, driverId: string) {
    return this.http.get<Order>(
      `${API}/${id}/updateparkeddriverwudthchilds/${driverId}`
    );
  }

  parkOrder(id: string, park: string) {
    return this.http
      .patch<Order>(`${API}/${id}/parktrip/${park}`, { park: park })
      .pipe(
        tap((trip) => {
          if (trip && trip.isOrder) {
            this.repo.upsertOrder(trip);
            this.repo.setActiveId(null);
          }
        }),
        trackOrdersRequestsStatus(id)
      );
  }

  resumeOrder(id: string, resume: string) {
    return this.http
      .patch<Order>(`${API}/${id}/resumetrip/${resume}`, { resume: resume })
      .pipe(
        tap((order) => {
          if (order && order.isOrder) {
            this.repo.setActiveId(order.id);
            this.repo.upsertOrder(order);
          }
        }),
        trackOrdersRequestsStatus(id)
      );
  }

  updateAll(id: string, model: Partial<Order>): Observable<Order[]> {
    return this.http.patch<Order[]>(`${API}/trips/${id}`, model).pipe(
      tap((res) => {
        res.forEach((element) => {
          this.repo.upsertOrder(element), trackOrdersRequestsStatus(element.id);
        });
      })
    );
  }
  updateParentId(id: string, parentId: string): Observable<Order> {
    return this.http
      .get<Order>(`${API}/parentchange/${id}?&parentId=${parentId}`)
      .pipe(
        tap((res) => {
          this.repo.upsertOrder(res), trackOrdersRequestsStatus(res.id);
        })
      );
  }

  moveImageToStart(commentPhoto: CommentPhotoDto) {
    return this.http.put<Order>(`${API}/movetostart`, commentPhoto).pipe(
      tap((res) => {
        this.repo.upsertOrder(res), trackOrdersRequestsStatus(res.id);
      })
    );
  }

  moveImageToWeight(commentPhoto: CommentPhotoDto, clientId?: string) {
    const query = [`clientId=${clientId}`];
    return this.http
      .put<Order>(`${API}/movetoweight?${query.join('&')}`, commentPhoto)
      .pipe(
        tap((res) => {
          this.repo.upsertOrder(res), trackOrdersRequestsStatus(res.id);
        })
      );
  }

  delete(
    id: string,
    driverId?: string | null,
    deleteOnlyOne: boolean = false,
    forOneDriver: boolean = false
  ): Observable<Order> {
    return this.http
      .get<Order>(
        `${API}/remove/${id}?&driverId=${
          driverId ?? ''
        }&onlyone=${deleteOnlyOne}&forOneDriver=${forOneDriver}`
      )
      .pipe(
        tap((res) => {
          if (res) {
            this.repo.upsertOrder(res), trackOrdersRequestsStatus(res.id);
          } else if (forOneDriver) {
            let currentOrder = this.repo.getOrder(id);
            const indefOfDriverToRemove = currentOrder?.driverIds?.indexOf(
              driverId!
            );
            if (indefOfDriverToRemove != -1) {
              currentOrder?.driverIds?.splice(indefOfDriverToRemove!, 1);
              this.repo.upsertOrder(currentOrder!);
            }
          } else {
            this.repo.remove(id);
          }
        })
        //tap(() => this.repo.remove(id))
      );
  }

  updateOrderAddress(id: string, model: Partial<Order>): Observable<Order> {
    return this.http.patch<Order>(`${API}/${id}/address`, model).pipe(
      tap((res) => {
        this.repo.clear(), this.repo.upsertOrder(res);
        trackOrdersRequestsStatus(res.id);
      })
    );
  }

  updateDriver(id: string, model: Partial<Order>): Observable<Order> {
    return this.http.patch<Order>(`${API}/${id}/driver`, model).pipe(
      tap((res) => {
        //this.repo.clear(),
        this.repo.upsertOrder(res);
        trackOrdersRequestsStatus(res.id);
      })
    );
  }

  loadParkedForCurrentDriver() {
    return this.http.get<Order[]>(`${API}/getparkedcurrent`).pipe(
      tap((res) => {
        this.repo.clear();
        this.tripRepo.clear();
        res.forEach((x) => {
          if (x.isOrder) {
            this.repo.upsertOrder(x);
          } else {
            this.tripRepo.upsertTrip(x as Trip);
          }
        });
        this.repo.setOrders(res);
      }),
      trackOrdersRequestsStatus(this.repo.name)
    );
  }

  loadForCurrentDriver() {
    return this.http.get<Order[]>(`${API}/getForCurrentDriver`).pipe(
      tap((res) => {
        this.repo.clear();
        this.repo.setOrdersForDriver(res);
      }),
      trackOrdersRequestsStatus(this.repo.name)
    );
  }

  /// Look when work on go page
  loadCurrent() {
    return this.http.get<Order>(`${API}/currentOrder`).pipe(
      tap((order) => {
        if (order) {
          this.repo.upsertOrder(order, `${this.repo.name}_current`);
          this.repo.setActiveId(order.id);
        }
      }),
      trackOrdersRequestsStatus(`${this.repo.name}_current`)
    );
  }

  loadOne(id: string) {
    return this.http.get<Order>(`${API}/${id}`).pipe(
      tap((order) => this.repo.upsertOrder(order)),
      trackOrdersRequestsStatus(id)
    );
  }

  start(
    order: Partial<Order>,
    event: Partial<TripEvent>,
    files?: File[] | null
  ) {
    const startEvent = {
      ...event,
      type: 'TripStart' as TripEventType,
      tripId: order.id,
      eventTime: this.ngDay.dayjs.utc().toDate(),
      createdAt: this.ngDay.dayjs.utc().toDate(),
    };
    return this.http.patch<Order>(`${API}/${order.id}`, order).pipe(
      switchMap((ordered) =>
        this.http.post<Order>(`${API}/${ordered.id}/events`, startEvent)
      ),
      this.processOfflineSubmit(startEvent, order?.vehicleId, order?.trailerId),
      switchMap((ordered) => {
        if (!files || !files.length) {
          // No photos included - skip upload
          return of(ordered);
        }
        const lastCreatedEvent = sortBy(ordered.tripEvents, {
          parameter: { property: 'createdAt' },
          direction: 'desc',
        })[0];
        const formData = new FormData();
        files.forEach((x) => formData.append('files', x));
        return this.http.post<Order>(
          `${API}/${lastCreatedEvent.id}/photos`,
          formData
        );
      }),
      tap((ordered) => {
        this.repo.upsertOrder(ordered);
        this.repo.setActiveId(ordered.id);
        trackOrdersRequestsStatus(ordered.id);
        this.tripEventsRepo.clearPages();
      })
    );
  }

  orderAction(
    order: Partial<Order>,
    event: Partial<TripEvent>,
    eventType: string,
    files?: File[] | null
  ) {
    const orderEvent = {
      ...event,
      type: eventType as TripEventType,
      tripId: order.id,
      eventTime: this.ngDay.dayjs.utc().toDate(),
      createdAt: this.ngDay.dayjs.utc().toDate(),
    };
    return this.http.post<Trip>(`${API}/${order.id}/events`, orderEvent).pipe(
      this.processOfflineSubmit(orderEvent),
      switchMap((ordered) => {
        if (!files || !files.length) {
          return of(ordered);
        }
        const lastCreatedEvent = sortBy(
          ordered.tripEvents.filter((x) => x.type !== 'Total'),
          {
            parameter: { property: 'createdAt' },
            direction: 'desc',
          }
        )[0];
        const formData = new FormData();
        files.forEach((x) => formData.append('files', x));
        return this.http.post<Trip>(
          `${API}/${lastCreatedEvent.id}/photos`,
          formData
        );
      }),
      tap((ordered) => {
        this.repo.upsertOrder(ordered);
        this.tripEventsRepo.clearPages();
        trackTripsRequestsStatus(ordered.id);
      })
    );
  }

  stop(
    order: Partial<Order>,
    event: Partial<TripEvent>,
    files?: File[] | null
  ) {
    const stopEvent = {
      ...event,
      type: 'TripEnd' as TripEventType,
      tripId: order.id,
      eventTime: this.ngDay.dayjs.utc().toDate(),
    };
    order.type = 'Stop';
    order.curTime = stopEvent.eventTime;
    order.productIds = order.products?.map((x) => x.id);
    order.isApproved = false;
    order.tripEvents = [];
    return this.http.patch<Order>(`${API}/${order.id}`, order).pipe(
      switchMap((ordered) =>
        this.http.post<Order>(`${API}/${ordered.id}/events`, stopEvent)
      ),
      this.processOfflineSubmit(stopEvent, order?.vehicleId, order?.trailerId),
      switchMap((ordered) => {
        if (
          (!files || !files.length) &&
          (!order.commentPhotos || !order.commentPhotos?.length)
        ) {
          // No photos included - skip upload
          return of(ordered);
        }
        const lastCreatedEvent = sortBy(
          ordered.tripEvents.filter((x) => x.type !== 'Total'),
          {
            parameter: { property: 'createdAt' },
            direction: 'desc',
          }
        )[0];
        const formData = new FormData();
        if (files) {
          files.forEach((x) => formData.append('files', x));
        }
        return this.http.post<Order>(
          `${API}/${lastCreatedEvent.id}/photos`,
          formData
        );
      }),
      tap((ordered) => {
        this.repo.upsertOrder(ordered);
        this.repo.setActiveId(null);
        this.repo.remove(ordered.id);
        this.tripEventsRepo.clearPages();
      })
    );
  }

  takephoto(tripEvent: string, files?: File[] | null) {
    if (!files || !files.length) {
      // No photos included - skip upload
      return of(tripEvent);
    }
    const formData = new FormData();
    if (files && files.length > 1) {
      for (let f of files) {
        formData.append('files', f);
      }
    } else if (files && files.length === 1) {
      formData.append('files', files[0]);
    }

    return this.http.patch<Order>(`${API}/${tripEvent}`, formData);
  }

  weighing(trip: Order, event?: Partial<TripEvent>, files?: File[] | null) {
    const weighing = {
      ...event,
      type: 'Weighing' as TripEventType,
      eventTime: this.ngDay.dayjs.utc().toDate(),
    };
    return this.http.post<Order>(`${API}/${trip?.id}/events`, weighing).pipe(
      this.processOfflineSubmit(weighing),
      switchMap((trip) => {
        if (!files || !files.length) {
          return of(trip);
        }
        const lastCreatedEvent = sortBy(trip.tripEvents, {
          parameter: { property: 'createdAt' },
          direction: 'desc',
        })[0];
        const formData = new FormData();
        if (files && files.length > 1) {
          for (let f of files) {
            formData.append('files', f);
          }
        } else if (files && files.length === 1) {
          formData.append('files', files[0]);
        }
        return this.http.post<Order>(
          `${API}/${lastCreatedEvent.id}/photos`,
          formData
        );
      })
    );
  }

  changePhotoEvent(url: string) {
    return this.http
      .patch<Order>(`${API}/photos`, { url: url })
      .pipe(tap((order) => this.repo.upsertOrder(order)));
  }

  export(): Observable<string> {
    return this.http
      .get<Blob>(`${API}/csv`, { responseType: 'blob' as any })
      .pipe(
        map((resp) => {
          const blob = new Blob([resp], { type: resp.type });
          return window.URL.createObjectURL(blob);
        })
      );
  }

  featuredExport(options?: FilterOptions): Observable<string> {
    return this.http
      .post<Blob>(`${API}/csv/options`, options, {
        responseType: 'blob' as any,
      })
      .pipe(
        map((resp) => {
          const blob = new Blob([resp], { type: resp.type });
          return window.URL.createObjectURL(blob);
        })
      );
  }

  featuredPhotosExport(options?: FilterOptions): Observable<string> {
    return this.http
      .post<Blob>(`${API}/zip/options`, options, {
        responseType: 'blob' as any,
      })
      .pipe(
        map((resp) => {
          const blob = new Blob([resp], { type: 'application/zip' });
          return window.URL.createObjectURL(blob);
        })
      );
  }

  private processOfflineSubmit(
    event: Partial<TripEvent>,
    vehicleId?: string | null,
    trailerId?: string | null
  ): OperatorFunction<Order, Order> {
    return catchError((err) => {
      if (err instanceof ScheduledForRetryError) {
        const orderIdFromUrl = err.request.url
          .replace(`${API}/`, '')
          .replace('/events', '');
        if (
          err.request.url.includes(API) &&
          !err.request.url.includes('events')
        ) {
          // trip POST request failed
          // create additional request for the event
          const tripEventRequest = this.requestsRepo.add({
            body: { ...event, eventTime: this.ngDay.dayjs.utc().toDate() },
            url: `${API}/${orderIdFromUrl}/events`,
            method: 'POST',
            userId: err.request.userId,
            createdAt: new Date(),
            responseIdPath: 'tripEvents.0.id',
          });
          const vehiclePlaceholder = {
            id: '',
            name: '',
            numberplate: OFFLINE_ERROR,
            createdAt: err.request.createdAt,
            isBeingUsed: false,
          };

          return of<Order>({
            id: err.request.url.replace(`${API}/`, '').replace('/events', ''),
            vehicle: vehicleId
              ? this.vehicles.getOne(vehicleId)
              : vehiclePlaceholder,
            trailer: trailerId
              ? this.trailers.getOne(trailerId) || vehiclePlaceholder
              : null,
            createdAt: err.request.createdAt,
            isApproved: err.request.body,
            tripEvents: [
              {
                ...tripEventRequest.body,
                id: buildIdPromise(tripEventRequest.id),
              },
            ],
          });
        }
        // trip was created, but event has failed
        // pull trip id from the failed request's URL

        const tripState = this.repo.getOrder(orderIdFromUrl);
        if (tripState) {
          this.requestsRepo.add({
            body: { ...event, eventTime: this.ngDay.dayjs.utc().toDate() },
            url: `${API}/${orderIdFromUrl}/events`,
            method: 'POST',
            userId: err.request.userId,
            createdAt: new Date(),
            responseIdPath: 'tripEvents.0.id',
          });
          tripState.tripEvents.push({
            ...err.request.body,
            id: buildIdPromise(err.request.id),
          });
          return of(tripState);
        }
      }
      throw err;
    });
  }

  updateRejected(id: string, driverId: string | null) {
    return this.http
      .patch<Order>(`${API}/${id}/reject?&driverId=${driverId}`, {})
      .pipe(
        this.processOfflineReject(),
        tap((res) => {
          res.tripEvents = [];
          const active = this.repo.getActiveOrder();
          if (active && active.id === id) {
            this.repo.setActiveId(null);
          }
          this.repo.remove(id);
          this.tripEventsRepo.clearPages();
        }),
        trackOrdersRequestsStatus(id)
      );
  }

  private processOfflineReject(): OperatorFunction<Order, Order> {
    return catchError((err) => {
      if (err instanceof ScheduledForRetryError) {
        if (err.request.url.endsWith('/reject')) {
          const url = err.request.url.split('api/orders/')[1];
          const id = url.split('/reject')[0];
          this.requestsRepo.add({
            body: {},
            url: `${API}/${url}`,
            method: 'PATCH',
            userId: err.request.userId,
            createdAt: new Date(),
            responseIdPath: id,
          });
          const active = this.repo.getActiveOrder();
          const order = this.repo.getOrder(id);
          if (order) {
            order.tripEvents = new Array<TripEvent>();
            this.repo.upsertOrder(order);
          }
          if (active && active.id === id) {
            this.repo.setActiveId(null);
          }
          this.repo.remove(id);
          this.tripEventsRepo.clearPages();
        }
      }
      throw err;
    });
  }
}
