import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Template } from 'src/app/state/templates.repository';

@Component({
  selector: 'app-templates-list',
  templateUrl: './templates-list.component.html',
  styleUrls: ['./templates-list.component.scss']
})
export class TemplatesListComponent {
  @Input() templates: Template[] | null = null;
  @Output() delete = new EventEmitter<string>();
  deleteConfirmation: Template | null = null;

  constructor() { }

  ngOnInit(): void {
  }
}
