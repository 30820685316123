<div
  class="modal fade"
  bsModal
  [config]="{ show: true }"
  id="textBoxModal"
  tabindex="-1"
  #textBoxModal
  aria-hidden="true"
  (onHidden)="cancel.emit()"
>
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content bg-lights border-0">
      <div class="modal-header">
        <h6 class="modal-title" id="textBoxModalLabel" i18n="clients">
          Clients
        </h6>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          i18n-aria-label="Close label @@close"
          (click)="cancel.emit()"
        ></button>
      </div>
      <div class="modal-body p-0" [class.isDisabled]="exported">
        <div class="row mb-3 mt-2 ms-1">
          <div class="col-8 col-md-6 col-lg-4">
            <strong i18n="Title for @@type">Type</strong>
          </div>
          <div class="col-8 col-md-6 col-lg-4">
            <strong i18n="Title for @@client"></strong>
          </div>
          <div class="col-8 col-md-6 col-lg-4">
            <strong i18n="Title for @@editClient">Edit client</strong>
          </div>
        </div>
        <div
          class="row ms-1"
          *ngFor="let tripEvent of tripEvents; let i = index"
        >
          <div class="col-8 col-md-6 col-lg-4">
            {{ tripEvent.type }}
          </div>
          <div class="col-8 col-md-6 col-lg-4">
            {{ tripEvent.clientName }}
          </div>
          <div class="col-8 col-md-6 col-lg-4">
            <div
              class="form-group form-floating mb-3 me-3"
              *ngIf="clientOptions"
            >
              <app-datalist-select
                [isCompanies]="true"
                [isDisabled]="exported"
                [options]="clientOptions"
                [multiple]="false"
                [(activeValue)]="tripEvent.clientId"
                [optionsDeleted]="clientOptionsDeleted"
                label="Clients"
                i18n-label="Clients label"
              ></app-datalist-select>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <button
            class="btn btn-primary me-2"
            (click)="save()"
            i18n="Save button"
          >
            Save
          </button>
          <button
            class="btn btn-secondary"
            (click)="cancel.emit()"
            i18n="@@cancel"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
