<div *ngIf="!(isLoading$ | async)">
  <div *ngIf="!pause">
    <div class="row">
      <div class="col-12 col-md-8 col-sm-12 col-lg-8">
        <app-start-day-form
          *ngIf="!workday && !isClicked"
          (startDay)="startDay()"
          [isLoading]="dayRepo.isAdding$ | async"
          [currentTrip]="trip"
        ></app-start-day-form>
        <app-spinner *ngIf="dayRepo.isAdding$ | async"></app-spinner>

        <div *ngIf="workday">
          <div class="mb-2" *ngIf="!trip && !order">
            <app-start-trip-form
              [parked]="parked"
              [isVehicleError]="vehicleError"
              (resume)="openParked()"
              (reject)="getOrderToRejectModal($event)"
              (startTrip)="router.navigate(['/trip/start'])"
              [isLoading]="tripRepo.isAdding$ | async"
              [userSettings]="settings"
            ></app-start-trip-form>
          </div>

          <div
            class="mb-2"
            *ngIf="trip && trip.tripEvents.length > 0 && !trip.isOrder"
          >
            <app-end-trip-form
              (endTrip)="router.navigate(['/trip/stop'])"
              (unload)="router.navigate(['/trip/unload'])"
              (load)="router.navigate(['/trip/load'])"
              (takePhotos)="takePhotos($event)"
              (takeWeighingPhotos)="addWeighing($event)"
              [isLoading]="tripRepo.isLoadingOne$(trip.id) | async"
              [currentTrip]="trip"
            ></app-end-trip-form>
          </div>
          <div class="mb-2" *ngIf="order">
            <app-end-order-form
              (endOrder)="navigateToOrderAction(order.id, 'TripEnd')"
              (unload)="navigateToOrderAction(order.id, 'Unload')"
              (load)="navigateToOrderAction(order.id, 'Load')"
              (takePhotos)="takePhotos($event)"
              (takeWeighingPhotos)="addWeighingForOrder($event)"
              [isLoading]="ordersRepo.isLoadingOne$(order.id) | async"
              [currentOrder]="order"
            >
            </app-end-order-form>
          </div>

          <div *ngIf="settings">
            <div class="mb-2">
              <app-end-day-form
                [userSettings]="settings"
                [isTripActive]="!!trip || !!order"
                [currentTrip]="trip || order"
                (parkTrip)="permitParkAction($event)"
                (endDay)="stopDay(workday)"
                (pauseDay)="createPause($event)"
                [currentDay]="workday"
                (makeNote)="addNote($event)"
                [isLoading]="dayRepo.isLoadingOne$(workday.id) | async"
              ></app-end-day-form>
            </div>

            <div class="mb-2" *ngIf="trip && !order">
              <app-go-info [activeTrip]="trip"></app-go-info>
            </div>
            <div class="mb-2" *ngIf="order">
              <app-go-order-info
                [activeOrder]="order"
                [products]="products"
                [companies]="companies"
              ></app-go-order-info>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-2" *ngIf="pause">
    <div class="col-12 col-md-8 col-lg-8 col-sm-12">
      <app-active-pause
        [pause]="pause"
        [isLoading]="dayRepo.isLoadingOne$(workday.id) | async"
        (resumeDay)="updatePause(workday, $event)"
      ></app-active-pause>
    </div>
  </div>
  <div
    *ngIf="authRepo.hasFeature$(TenantFeatures.Order) | async"
    class="row mb-2"
  >
    <div
      class="col-12 col-md-8 col-sm-12 col-lg-8"
      *ngIf="!(ordersRepo.isLoadingCurrentDriver$ | async)"
    >
      <ng-container *ngIf="userId">
        <app-start-order-form
          [isDayPaused]="pause !== null"
          [workDay]="workday"
          [currentChildren]="currentChildren"
          [activeOrder]="order && order.tripEvents.length > 0 ? order : null"
          [isTripActive]="!!trip"
          [isOrderActive]="!!(order && order.tripEvents.length > 0)"
          [orders]="ordersForDriver"
          (startOrder)="navigateToOrderAction($event, 'TripStart')"
          (rejectOrder)="getOrderToRejectModal($event)"
          (vehicleError)="vehicleError = true"
          [isLoading]="ordersRepo.isAdding$ | async"
          [userSettings]="settings"
          (resume)="resumeTrip($event); tripsForModal = []"
        ></app-start-order-form>
      </ng-container>
    </div>
  </div>
</div>
<div *ngIf="isLoading$ | async" class="row mb-2">
  <div class="col-12">
    <app-spinner [showWithText]="true"></app-spinner>
  </div>
</div>
<app-error-alert
  title="Could not upload the photo"
  i18n-title="Could not upload the photo"
  [errors]="submitErrors"
></app-error-alert>

<app-confirm-delete
  id="deleteordermodal"
  *ngIf="deleteConfirmation"
  [deleteButton]="rejectButton"
  [deleteText]="rejectText"
  [deleteMessage]="rejectMessage"
  [deleteCandidate]="
    deleteConfirmation.client?.name +
    ' ' +
    toCorrectDate(deleteConfirmation.plannedToStartAt)
  "
  (confirm)="rejectOrder(deleteConfirmation.id); deleteConfirmation = null"
  (cancel)="deleteConfirmation = null"
></app-confirm-delete>

<app-confirm-refresh-redirect
  [errorMessage]="doubleActionError"
  (confirm)="reload()"
></app-confirm-refresh-redirect>

<app-confirm-delete
  id="deleteusermodal"
  *ngIf="parkedTripOrder"
  [deleteMessage]="parkedMessage"
  [deleteCandidate]="parkedCandidate"
  [deleteText]="parkedText"
  [deleteButton]="parkAction"
  (confirm)="parkTrip(parkedTripOrder.id); parkedTripOrder = null"
  (cancel)="parkedTripOrder = null"
></app-confirm-delete>

<div
  class="modal fade in"
  *ngIf="tripsForModal && tripsForModal.length !== 0"
  id="parkedModal"
  style="max-height: 100dvh"
  [style.display]="display"
  [style.opacity]="opacity"
  tabindex="-1"
  aria-labelledby="parkedModal"
  aria-hidden="false"
>
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content bg-lights border-0">
      <div class="modal-header">
        <app-spinner *ngIf="ordersRepo.isLoading$ | async"></app-spinner>
        <h6
          class="modal-title"
          id="sortModalLabel"
          i18n="Title for @ParkedTrips"
        >
          Parked Trips
        </h6>

        <button
          type="button"
          class="btn-close"
          (click)="tripsForModal = []"
          aria-label="Close"
          i18n-aria-label="Close label @@close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="form-floating mb-3" style="overflow-y: scroll">
          <app-parked-trips-modal
            id="parkedModal"
            [isLoading]="ordersRepo.isLoading$ | async"
            (reject)="rejectOrder($event); tripsForModal = []"
            (resume)="resumeTrip($event); tripsForModal = []"
            [userSettings]="settings"
            [trips]="tripsForModal"
          >
          </app-parked-trips-modal>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button
          (click)="tripsForModal = []"
          style="flex: none"
          class="btn btn-sm btn-primary"
        >
          <h4 class="mt-1 mb-1" i18n="@@closeParked">Close</h4>
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  bsModal
  [config]="{ show: true }"
  id="endDayModal"
  tabindex="-1"
  *ngIf="endDayWarning"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content bg-lights border-0">
      <div class="modal-header">
        <h6
          class="modal-title"
          id="refreshModalLabel"
          i18n="Title for confirmation dialog"
        >
          Confirm your action
        </h6>
        <button
          (click)="endDayWarning = false"
          type="button"
          class="btn-close"
          aria-label="Close"
          i18n-aria-label="Close label @@close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="form-floating mb-3">
          <p class="mb-1">
            <strong i18n="enddaywarning"
              >You have parked trips. Are you sure that you want to end
              day?</strong
            >
          </p>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button
          (click)="stopDay(workday, true); endDayWarning = false"
          class="btn btn-danger me-2"
          i18n="endday"
        >
          <i class="bi bi-stop-fill"></i> End day
        </button>
        <button
          (click)="endDayWarning = false"
          class="btn btn-secondary me-2"
          i18n="@@cancel"
        >
          <i class="bi bi-arrow-right-square-fill"></i> Cancel
        </button>
      </div>
    </div>
  </div>
</div>
