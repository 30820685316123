<div
  class="card shadow-sm bg-warning text-white mb-1"
  role="button"
  (click)="startDay.emit()"
>
  <div class="card-body">
    <h2 class="mb-3">
      <i *ngIf="!isLoading" class="bi bi-play-fill"></i>
      <app-spinner *ngIf="isLoading"></app-spinner>
    </h2>
    <h4 i18n="Start day label">Start day</h4>
    <p
      *ngIf="tripStart"
      class="text-muted small"
      i18n="Current trip started label"
    >
      Current trip started: {{ tripStart | timing }},
      {{ tripStart | date : "longDate" }}
    </p>
  </div>
</div>
