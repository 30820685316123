<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()" enctype="multipart/form-data">
    <div class="row mb-2">
      <div class="col">
          <h6 class="my-1" i18n="Company info label">Company Information</h6>
      </div>
    </div>
    <div class="row mb-3">
      <div [appValidateState]="form.get('name')" class="col-12 col-md-6 col-lg-4">
        <div class="form-group form-floating mb-3">
          <input formControlName="name" type="text" class="form-control"
                 placeholder="Name" i18n-paceholder="@@name">
          <label for="name" i18n="@@name">Name</label>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div [appValidateState]="form.get('idNumber')"  class="form-group form-floating mb-3">
          <input formControlName="idNumber" type="text" class="form-control"
                 placeholder="Id number" i18n-placeholder="Id number label @@idNumber">
          <label for="idNumber" i18n="@@idNumber">Id number</label>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div [appValidateState]="form.get('email')" class="form-group form-floating mb-3">
          <input formControlName="email" type="email" class="form-control"
                 placeholder="Email" i18n-placeholder="@@email">
          <label for="email" i18n="@@email">Email</label>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div [appValidateState]="form.get('phoneNumber')" class="form-group form-floating mb-3">
          <input formControlName="phoneNumber" type="tel" class="form-control"
                 placeholder="Phone number" i18n-placeholder="Phone number label @@phoneNumber">
          <label for="phone" i18n="@@phoneNumber">Phone number</label>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="form-group form-floating mb-3">
          <input formControlName="address" type="text" class="form-control"
                 placeholder="Address" i18n-placeholder="Address label @@compaddress">
          <label for="address" i18n="@@companyaddress">Address</label>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div [appValidateState]="form.get('zipCode')" class="form-group form-floating mb-3">
          <input formControlName="zipCode" type="text" class="form-control"
                 placeholder="ZipCode" i18n-placeholder="ZipCode label @@zipCode">
          <label for="zipCode" i18n="@@zipCode">ZipCode</label>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="form-group form-floating mb-3">
          <input formControlName="city" type="text" class="form-control"
                 placeholder="City" i18n-placeholder="City label @@city">
          <label for="city" i18n="@@city">City</label>
        </div>
      </div>
  </div>
  <ng-container *ngIf="!(authRepo.hasFeature$(TenantFeatures.Economic) | async)">
    <div class="row mb-2">
      <div class="col">
        <h6 class="my-1" i18n="Company roles label">Company roles</h6>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        <div class="form-group form-floating h-100 pb-3 d-grid">
          <div class="card">
            <ul class="list-group list-group-horizontal bg-none">
              <li class="no-outer-border list-group-item flex-fill w-100">
                <div class="row">
                  <div class="col-auto pr-0 align-self-center text-end">
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" formControlName="canBeVendor"
                      [attr.disabled]="editedCompany?.canBeVendor && editedCompany?.isBeingUsedAsVendor || null">
                      <label class="form-check-label" for="canBeVendor"></label>
                    </div>
                  </div>
                  <div class="col">
                    <h6 class="mb-1" i18n="Vendor label">Vendor</h6>
                    <p class="text-muted small" i18n="Vendor list displayed message">Will be displayed in the vendors list</p>
                  </div>
                </div>
              </li>
              <li class="no-outer-border list-group-item flex-fill w-100">
                <div class="row">
                  <div class="col-auto pr-0 align-self-center text-end">
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" formControlName="canBeClient"
                        [attr.disabled]="editedCompany?.canBeClient && editedCompany?.isBeingUsedAsClient || null">
                      <label class="form-check-label" for="canBeClient"></label>
                    </div>
                  </div>
                  <div class="col">
                    <h6 class="mb-1" i18n="Client label">Client</h6>
                    <p class="text-muted small" i18n="Client list displayed message">Will be displayed in the clients list</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  
  <div class="row mb-3">
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating h-100 pb-3 d-grid">
        <button type="submit" class="btn btn-primary shadow-sm" i18n="@@save">Save</button>
      </div>
    </div>
  </div>
</form>
