import { Component, Input, OnInit } from '@angular/core';

const DEFAULT_ERROR = $localize `:Unknown error message in Angular UI:An error occured while completing your request. Please try again in a moment or contact the support if the problem persists.`;

@Component({
  selector: 'app-error-alert',
  templateUrl: './error-alert.component.html',
  styleUrls: ['./error-alert.component.scss']
})
export class ErrorAlertComponent implements OnInit {
  parsedErrors: string[] = [];

  @Input() title: string = '';
  @Input() set errors(value: any) {
    this.parsedErrors = this.parse(value);
  } 

  constructor() { }

  ngOnInit(): void {
  }

  private parse(value: any): string[] {
    if (!value) {
      return [];
    }
    if (typeof value.error === 'string') {
      return [value.error];
    }
    if (typeof value.errors === 'string') {
      return [value.errors];
    }
    for (const errorArray of [ value, value.error, value.errors, value.error?.errors ]) {
      if (Array.isArray(errorArray)) {
        const parsed = this.parseErrorsArray(errorArray);
        if (parsed.length) {
          return parsed;
        }
      }
    }
    return [ DEFAULT_ERROR ];
  }

  private parseErrorsArray(value: any[]): string[] {
    return value.map(e => {
      if (typeof e === 'string') {
        return e;
      }
      if (e && typeof e.description === 'string') {
        return e.description as string;
      }
      return '';
    }).filter(x => !!x);
  }

}
