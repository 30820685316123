import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Post, PostsRepository, skipWhilePostsCached, trackPostRequestsStatus } from "./posts.repository";
import { tap } from 'rxjs/operators';
import { PaginationData } from "@ngneat/elf-pagination";

const API = '/api/posts';

@Injectable({ providedIn: 'root' })
export class PostsService {
  constructor(private http: HttpClient, private repo: PostsRepository) { }

  load() {
    return this.http
      .get<PaginationData & { data: Post[] }>(API)
      .pipe(
        tap((posts) => this.repo.setPosts(posts.data)),
        trackPostRequestsStatus(this.repo.name),
        skipWhilePostsCached(this.repo.name)
      );
  }

  loadOne(id: string) {
    return this.http
      .get<Post>(`${API}/${id}`)
      .pipe(
        tap((post) => this.repo.upsertPost(post)),
        trackPostRequestsStatus(id),
        skipWhilePostsCached(id)
      );
  }

  add(post: Partial<Post>) {
    return this.http.post<Post>(`/api/posts`, post)
    .pipe(
      tap((post) => this.repo.upsertPost(post)),
      trackPostRequestsStatus(`${this.repo.name}_add`)
    );
}

  update(id: string, post: Partial<Post>) {
    return this.http.patch<Post>(`/api/posts/${id}`, post)
      .pipe(
        tap((tenant) => this.repo.upsertPost(tenant)),
        trackPostRequestsStatus(id)
      );
  }
}
