import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EnvState } from 'src/app/modules/shared/helpers/env-state';
import { AuthService } from 'src/app/state/auth.service';


@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {
  form = this.formBuilder.group({
    password: ['', Validators.required]
  });
  state: 'idle' | 'processing' | 'success' = 'idle';
  errors?: any;
  public token!:string;
  

  constructor(
    private auth: AuthService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    public env: EnvState
  ) { 
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
      localStorage.setItem('specialToken', this.token);
  });
  }

  ngOnInit(): void { 
       
  }

  reset(): void {
    this.state = 'processing';
    this.errors = undefined;
    this.auth.reset(
      this.form.value.password,
      this.route.snapshot.queryParamMap.get('id') || '',
      this.route.snapshot.queryParamMap.get('token') || ''
    ).subscribe({
      complete: () => this.state = 'success',   
      error: err => {
        this.state = 'idle';    
        this.errors = err;
      } 
    });
  }
}
