import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PasswordChangeRequest } from 'src/app/state/auth.repository';

@Component({
  selector: 'app-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss']
})
export class ChangePasswordFormComponent implements OnInit {
  form?: UntypedFormGroup;

  @Output() passwordSubmit = new EventEmitter<PasswordChangeRequest>();

  constructor(
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      currentPassword: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  onSubmit() {
    if (this.form && !this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.passwordSubmit.emit({
      password : this.form?.value?.password,
      currentPassword : this.form?.value?.currentPassword,
    });
    this.form?.markAsUntouched();
  }
}
