import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JoinPipe } from './pipes/join.pipe';
import { NgVarDirective } from './directives/ng-var.directive';
import { SortPipe } from './pipes/sort.pipe';
import { FindByPipe } from './pipes/find-by.pipe';
import { PropPipe } from './pipes/prop.pipe';
import { TimingPipe } from './pipes/timing.pipe';
import { ValidateStateDirective } from './directives/validate-state.directive';
import { StripHtmlPipe } from './pipes/strip-html.pipe';
import { SimpleCardComponent } from './components/simple-card/simple-card.component';
import { DatalistSelectComponent } from './components/datalist-select/datalist-select.component';
import { RolePinComponent } from './components/role-pin/role-pin.component';
import { EntityAvatarComponent } from './components/entity-avatar/entity-avatar.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SortDropdownComponent } from './components/sort-dropdown/sort-dropdown.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorAlertComponent } from './components/error-alert/error-alert.component';

import { CalendarPipe } from './pipes/calendar.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ScrollSpyDirective } from './directives/scroll-spy.directive';
import { GoogleMapsLinkComponent } from './components/google-maps-link/google-maps-link.component';
import { StatCardComponent } from './components/stat-card/stat-card.component';
import { ImportExportComponent } from './components/import-export/import-export.component';
import { TranslateRolesPipe } from './pipes/translate-roles.pipe';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { ImageSliderComponent } from './components/image-slider/image-slider.component';
import { ImageCardComponent } from './components/image-card/image-card.component';
import { ConfirmDeleteComponent } from './components/confirm-delete/confirm-delete.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SingleTextboxModalComponent } from './components/single-textbox-modal/single-textbox-modal.component';
import { ConfirmRefreshRedirectComponent } from './components/confirm-refresh-redirect/confirm-refresh-redirect.component';
import { SingleGroupModalComponent } from '../truck/components/single-group-modal/single-group-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TimepickerDropdownComponent } from './components/timepicker-dropdown/timepicker-dropdown.component';
import {
  IgxIconModule,
  IgxInputGroupModule,
  IgxTimePickerModule,
} from 'igniteui-angular';
import { HammerModule } from '@angular/platform-browser';
import { ImgSliderComponent } from './components/img-slider/img-slider.component';
import { ConfirmActionComponent } from './components/confirm-action/confirm-action.component';
import { BigSpinnerComponent } from './components/big-spinner/big-spinner.component';
import { TripImageSliderComponent } from './components/trip-image-slider/trip-image-slider.component';

@NgModule({
  declarations: [
    JoinPipe,
    NgVarDirective,
    SortPipe,
    FindByPipe,
    PropPipe,
    TimingPipe,
    ValidateStateDirective,
    StripHtmlPipe,
    SimpleCardComponent,
    DatalistSelectComponent,
    RolePinComponent,
    EntityAvatarComponent,
    SpinnerComponent,
    SortDropdownComponent,
    ErrorAlertComponent,
    CalendarPipe,
    PaginationComponent,
    ScrollSpyDirective,
    GoogleMapsLinkComponent,
    StatCardComponent,
    ImportExportComponent,
    TranslateRolesPipe,
    ImageUploaderComponent,
    ImageSliderComponent,
    ImageCardComponent,
    ConfirmDeleteComponent,
    SingleTextboxModalComponent,
    ConfirmRefreshRedirectComponent,
    SingleGroupModalComponent,
    TimepickerDropdownComponent,
    ImgSliderComponent,
    ConfirmActionComponent,
    BigSpinnerComponent,
    TripImageSliderComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    IgxTimePickerModule,
    IgxInputGroupModule,
    IgxIconModule,
    HammerModule,
    BrowserAnimationsModule,
    ModalModule.forRoot(),
  ],
  exports: [
    JoinPipe,
    NgVarDirective,
    SortPipe,
    FindByPipe,
    PropPipe,
    TimingPipe,
    ValidateStateDirective,
    StripHtmlPipe,
    SimpleCardComponent,
    DatalistSelectComponent,
    RolePinComponent,
    EntityAvatarComponent,
    SpinnerComponent,
    SortDropdownComponent,
    ErrorAlertComponent,
    CalendarPipe,
    BrowserAnimationsModule,
    PaginationComponent,
    ScrollSpyDirective,
    GoogleMapsLinkComponent,
    StatCardComponent,
    ImportExportComponent,
    ImageUploaderComponent,
    TranslateRolesPipe,
    ImageSliderComponent,
    ImageCardComponent,
    ConfirmDeleteComponent,
    ConfirmActionComponent,
    SingleTextboxModalComponent,
    ConfirmRefreshRedirectComponent,
    SingleGroupModalComponent,
    TimepickerDropdownComponent,
    BigSpinnerComponent,
    TripImageSliderComponent,
  ],
})
export class SharedModule {}
