import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { DataSelectService } from 'src/app/state/dataSelect.service';
import { DataSelectDto } from 'src/app/state/models/dataSelectModels/dataSelectDto';
import { TripEventClientDto } from 'src/app/state/models/trip/TripEventClientDto';
import { ClientEventInfoDto } from 'src/app/state/trips.repository';

@Component({
  selector: 'app-client-update-modal',
  templateUrl: './client-update-modal.component.html',
  styleUrls: ['./client-update-modal.component.scss'],
})
export class ClientUpdateModalComponent implements OnInit {
  @Input() tripEvents: TripEventClientDto[] | null = null;
  @Input() exported: boolean = false;

  @Output() cancel = new EventEmitter<void>();
  @Output() confirm = new EventEmitter<ClientEventInfoDto[]>();

  clientOptions: DatalistOption[] | null = null;
  clientOptionsDeleted: DatalistOption[] | null = null;

  @Input() set clients(value: DataSelectDto[] | null) {
    if (value) {
      this.clientOptions = this.dataSelectService.setDataSelectOptions(
        value.filter((x) => !x.isDeleted && x.isActive)
      );
      this.clientOptionsDeleted = this.dataSelectService.setDataSelectOptions(
        value.filter((x) => x.isDeleted)
      );
    }
  }

  constructor(private dataSelectService: DataSelectService) {}

  ngOnInit(): void {}

  save() {
    var result =
      this.tripEvents?.map((x) => ({
        tripEventId: x.id,
        clientId: x.clientId || '',
      })) || [];
    this.confirm.emit(result);
  }
}
