import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, map } from 'rxjs';
import { AuthRepository, UserSettings } from 'src/app/state/auth.repository';
import { AuthService } from 'src/app/state/auth.service';
import { TrailersRepository } from 'src/app/state/trailers.repository';
import { TrailersService } from 'src/app/state/trailers.service';
import { VehiclesRepository } from 'src/app/state/vehicles.repository';
import { VehiclesService } from 'src/app/state/vehicles.service';

@Component({
  selector: 'app-trip-settings-page',
  templateUrl: './trip-settings-page.component.html',
  styleUrls: ['./trip-settings-page.component.scss'],
})
export class TripSettingsPageComponent implements OnInit {
  submitErrors: string[] | null = null;
  isLoading$ = combineLatest([
    this.authRepo.isSettingsLoading$,
    this.vehiclesRepo.isLoading$,
    this.trailersRepo.isLoading$,
  ]).pipe(map(([auth, vehicle, trailer]) => auth && vehicle && trailer));

  constructor(
    public authRepo: AuthRepository,
    private authService: AuthService,
    public vehiclesRepo: VehiclesRepository,
    private vehiclesService: VehiclesService,
    public trailersRepo: TrailersRepository,
    private trailersService: TrailersService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authService.loadSettings().subscribe();
    this.vehiclesService.load().subscribe();
    this.trailersService.load().subscribe();
  }

  updateSettings(settings: UserSettings) {
    this.submitErrors = null;
    this.authService.updateSettings(settings).subscribe({
      complete: () => {
        this.vehiclesRepo.clearCache();
        this.trailersRepo.clearCache();
        this.router.navigate(['/go']);
      },
      error: (data) => (this.submitErrors = data),
    });
  }
}
