import { Injectable } from '@angular/core';
import {
  Trip,
  TripDto,
  TripEvent,
  TripEventDto,
  TripPhoto,
} from '../trips.repository';
import * as dayjs from 'dayjs';

@Injectable({ providedIn: 'root' })
export class TempService {
  constructor() {}
  //This service contains logic for single group modal thats need to be refactored

  InitializeTripDto(trip: Trip) {
    var dto = new TripDto();
    dto.driverId = trip.userId;
    dto.driverName = trip.userName;
    dto.tripId = trip.id;
    dto.isApproved = trip.isApproved;
    dto.isOrder = trip.isOrder;
    dto.officeNote = trip.officeNote;
    dto.deliveryAddress = trip.deliveryAddress;
    dto.pickUpAddress = trip.pickUpAddress;
    dto.exportedAt = trip.exportedAt ? trip.exportedAt.toString() : '';
    dto.failedSyncAt = trip.failedSyncAt ? trip.failedSyncAt.toString() : '';
    dto.carNumber = trip.vehicle?.numberplate;
    dto.trailerNumber = trip.trailer?.numberplate
      ? trip.trailer?.numberplate
      : '';
    let start;
    let end;
    let tripEventStart = trip.tripEvents?.filter(
      (x) => x.type === 'TripStart'
    )[0];
    if (tripEventStart) {
      dto.tripEventIdStart = tripEventStart.id;
      dto.clientNameStart = tripEventStart.client?.name;
      dto.clientIdStart = tripEventStart.client?.id;
      dto.clientIdNumberStart = tripEventStart.client?.idNumber;
      dto.startEventTimeForFilter = tripEventStart.eventTime;
      start = tripEventStart.eventTime;
      dto.startEventTime = this.dateFormater(tripEventStart.eventTime);
      dto.kmStart = tripEventStart.km;
      dto.startPosition = `https://maps.google.com/?q=${tripEventStart.latitude},${tripEventStart.longitude}`;
      dto.startEventPhotos = tripEventStart.tripPhotos;
      dto.startPhotoUrls = this.getTripPhotoUrls(tripEventStart.tripPhotos);
      dto.weightStart = tripEventStart.weight;
      dto.tenantNote = tripEventStart.tenantNote;

      if (tripEventStart.products) {
        if (tripEventStart.products[0]) {
          dto.productNameStart = tripEventStart.products
            .map((x) => x.name)
            .toString();
          dto.productIdStart = tripEventStart.products
            .map((x) => x.id)
            .toString();
        }
      }
      dto.cleaningStart = tripEventStart.cleaning?.name;
      dto.noteStart = tripEventStart.note;
      dto.referenceStart = tripEventStart.reference;
      dto.commentStart = tripEventStart.comment;
      dto.vendorNameStart = tripEventStart.vendor?.name;
      dto.vendorIdNumberStart = tripEventStart.vendor?.idNumber;
      dto.accessoryNameStart = tripEventStart.accessory?.name;
    }

    let tripEventEnd = trip.tripEvents?.filter((x) => x.type === 'TripEnd')[0];

    if (tripEventEnd) {
      dto.tripEventIdEnd = tripEventEnd.id;
      end = tripEventEnd.eventTime;
      dto.clientNameEnd = tripEventEnd.client?.name;
      dto.clientIdEnd = tripEventEnd.client?.id;
      dto.clientIdNumberEnd = tripEventEnd.client?.idNumber;
      dto.endEventTime = this.dateFormater(tripEventEnd.eventTime);
      dto.kmEnd = tripEventEnd.km;
      dto.endPosition = `https://maps.google.com/?q=${tripEventEnd.latitude},${tripEventEnd.longitude}`;
      dto.endEventPhotos = tripEventEnd.tripPhotos;
      dto.endPhotoUrls = this.getTripPhotoUrls(tripEventEnd.tripPhotos);
      dto.noteEnd = tripEventEnd.note;
      dto.referenceEnd = tripEventEnd.reference;
      dto.commentEnd = tripEventEnd.comment;
      dto.cleaningEnd = tripEventEnd.cleaning?.name;
      dto.weightEnd = tripEventEnd.weight;
      dto.vendorIdNumberEnd = tripEventEnd.vendor?.idNumber;
      dto.vendorNameEnd = tripEventEnd.vendor?.name;
      dto.accessoryNameEnd = tripEventEnd.accessory?.name;
      if (tripEventEnd.products) {
        if (tripEventEnd.products[0]) {
          dto.productNameEnd = tripEventEnd.products
            .map((x) => x.name)
            .toString();
          dto.productIdEnd = tripEventEnd.products.map((x) => x.id).toString();
        }
      }
    }
    let duration = dayjs
      .duration(dayjs(end).diff(dayjs(start)))
      .asMilliseconds();
    dto.tripTime = this.convertMS(
      duration - (trip?.parkDuration ? trip!.parkDuration : 0)
    );

    dto.tripKm = (dto.kmEnd ?? 0) - (dto.kmStart ?? 0);

    let tripEventWeighing = trip.tripEvents?.filter(
      (x) => x.type === 'Weighing'
    );
    dto.weighingPhotos = new Array<TripPhoto>();
    if (tripEventWeighing) {
      tripEventWeighing.forEach((x) => {
        x.tripPhotos?.forEach((y) => {
          dto.weighingPhotos?.push(y);
        });
      });
    }
    dto.tripEvents = trip.tripEvents
      ?.filter((x) => x.type !== 'Weighing')
      .map((event) => {
        const eventDto = new TripEventDto();
        eventDto.tripEventId = event.id;
        eventDto.type = event.type;
        eventDto.economics = event.economics;
        eventDto.eventTime = this.dateFormater(event.eventTime);
        eventDto.km = event.km;
        eventDto.note = event.note;
        eventDto.reference = event.reference;
        eventDto.comment = event.comment;
        eventDto.accessoriesName = event.accessory?.name;
        eventDto.vendorName = event.vendor?.name;
        eventDto.vendorIdNumber = event.vendor?.idNumber;
        eventDto.cleaning = event.cleaning?.name;
        eventDto.isClientFromEconomic = event.client?.isFromEconomic;
        eventDto.totalKg = event.totalKg;
        eventDto.totalKm = event.totalKm;
        eventDto.totalMinutes = event.totalMinutes;
        eventDto.weight = event.weight;

        if (event.clientId) {
          eventDto.clientId = event.clientId;
        }
        if (event.client) {
          eventDto.clientName = event.client.name;
          eventDto.clientIdNumber = event.client.idNumber;
          eventDto.isClientDeleted = event.client.isDeleted;
        }

        if (event.products && event.products.length > 0) {
          eventDto.productName = event.products.map((x) => x.name).join(', ');
          eventDto.productId = event.products.map((x) => x.id).join(', ');
        }

        eventDto.position = `https://maps.google.com/?q=${event.latitude},${event.longitude}`;
        eventDto.photos = event.tripPhotos;
        eventDto.photoUrls = this.getTripPhotoUrls(event.tripPhotos);
        eventDto.duration = this.getDurationForLoadUnload(trip, event);
        eventDto.diffKg = this.getKgDiff(trip, event).toString();
        eventDto.diffKm = this.getKmDiff(trip, event).toString();

        return eventDto;
      });

    return dto;
  }

  private getDanishEventType(type: string) {
    if (type === 'TripStart') {
      return 'Start';
    }
    if (type === 'TripEnd') {
      return 'Slut';
    }
    if (type === 'Load') {
      return 'Laste';
    }
    if (type === 'Unload') {
      return 'Losse';
    }
    return '';
  }

  private getDurationForLoadUnload(trip: Trip, event: TripEvent) {
    let actualEvents = trip.tripEvents.filter((x) => x.type !== 'Weighing');
    let index = actualEvents.indexOf(event);
    let previous = actualEvents[index - 1];
    if (
      event.eventTime &&
      previous &&
      previous.eventTime &&
      previous.eventTime <= event.eventTime
    ) {
      let durationMs = dayjs
        .duration(dayjs(event.eventTime).diff(dayjs(previous.eventTime)))
        .asMilliseconds();
      return this.convertMS(durationMs);
    }
    return '';
  }

  private dateFormater(dt: Date) {
    const year = dt.getFullYear().toString().padStart(4, '0');
    const month = (dt.getMonth() + 1).toString().padStart(2, '0');
    const date = dt.getDate().toString().padStart(2, '0');
    const hours = dt.getHours().toString().padStart(2, '0');
    const minutes = dt.getMinutes().toString().padStart(2, '0');
    const seconds = dt.getSeconds().toString().padStart(2, '0');
    return `${date}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  }

  private getTripPhotoUrls(files?: TripPhoto[]) {
    let urls = new Array<string>();
    if (files) {
      urls = files?.map((x) => x.url);
    }
    return urls;
  }

  private getKmDiff(trip: Trip, event: TripEvent) {
    let actualEvents = trip.tripEvents.filter((x) => x.type !== 'Weighing');
    let index = actualEvents.indexOf(event);

    let previous = actualEvents[index - 1];
    if (event && previous && event.km) {
      return (event.km - previous.km).toString();
    }
    return '';
  }
  private getKgDiff(trip: Trip, event: TripEvent) {
    let actualEvents = trip.tripEvents.filter((x) => x.type !== 'Weighing');
    let index = actualEvents.indexOf(event);
    let previous = actualEvents[index - 1];

    if (event && previous && event.weight) {
      return (event.weight - previous.weight).toString();
    }
    return '';
  }

  private convertMS(duration: number): string {
    let d, h, m, s;
    s = Math.floor(duration / 1000);
    m = Math.floor(s / 60);
    s = s % 60;
    h = Math.floor(m / 60);
    m = m % 60;
    d = Math.floor(h / 24);
    h = h % 24;
    h += d * 24;
    return `${h}h. ${m}m. ${s}s. `;
  }
}
