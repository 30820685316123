import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { EnvState } from 'src/app/modules/shared/helpers/env-state';
import { AuthRepository } from 'src/app/state/auth.repository';
import { AuthService } from 'src/app/state/auth.service';

@Component({
  selector: 'app-forgetpassword',
  templateUrl: './forgetpassword.component.html',
  styleUrls: ['./forgetpassword.component.scss']
})
export class ForgetpasswordComponent implements OnInit, OnDestroy {
  form = this.formBuilder.group({
    email: ['', [Validators.email, Validators.required]]
  });
  state: 'idle' | 'processing' | 'error' | 'success' = 'idle';

  authSubscription?: Subscription;

  constructor(
    private auth: AuthService,
    private repo: AuthRepository,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    public env: EnvState
  ) { }

  ngOnDestroy(): void {
    this.authSubscription?.unsubscribe();
  }

  ngOnInit(): void {   
    this.authSubscription = this.repo.isAuthenticated$.pipe(
      filter(x => x)
    ).subscribe(() => this.router.navigateByUrl("/"));
  }

  reset(): void {
    this.state = 'processing';
    this.auth.forgot(
      this.form.value.email
    ).subscribe({
      complete: () => this.state = 'success',   
      error: () => this.state = 'error'    
    });
  }

}
