import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store, createState, withProps, select } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { createRequestsStatusOperator, selectRequestStatus, updateRequestsStatus, withRequestsStatus } from '@ngneat/elf-requests';
import { combineLatest, timer } from 'rxjs';
import { distinctUntilChanged, filter, map, withLatestFrom } from 'rxjs/operators';
import { ImageInterceptor } from 'src/app/modules/core/interceptors/image.interceptor';
import { EnvState } from '../modules/shared/helpers/env-state';
import { TenantFeatures } from './feature.repository';

const EXPIRES_SOON_MS = 10000;
const EXPIRES_CHECK_MS = EXPIRES_SOON_MS / 2;

export interface UserSettings {
  defaultPause: number;
  vehicleId?: string;
  trailerId?: string;
  vehicleName?: string;
  trailerName?: string;
}

export interface PasswordChangeRequest {
  currentPassword: string;
  password: string;
}

export interface AuthProps {
  token: string | null;
  settings: UserSettings | null;
}

const { state, config } = createState(
  withProps<AuthProps>({ token: null, settings: null }),
  withRequestsStatus()
);
export const store = new Store({ name: 'auth', state, config });
persistState(store, { 
  storage: localStorageStrategy,
  source: store => store.pipe(
    select(state => ({ token: state.token }))
  )
});

export const trackAuthRequestsStatus = createRequestsStatusOperator(store);

export function getStoredToken() {
  return store.getValue().token;
}

enum ClaimTypes {
  Sub = 'sub',
  Name = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
  Email = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
  Image = 'img',
  Role = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',
  ImpersonatorId = 'imp',
  NotBefore = 'nbf',
  Expires = 'exp',
  Issuer = 'iss',
  Audience = 'aud',
  TenantFeatures = 'ftr'
}

export enum UserRoles {
  Superamin = "Superadmin",
  TenantAdmin = "Administrator",
  Driver = "Driver"
}

@Injectable({ providedIn: 'root' })
export class AuthRepository {
  name = store.name;

  constructor(
    private jwtHelper: JwtHelperService,
    private env: EnvState
  ) { }

  isLoading$ = store.pipe(
    selectRequestStatus(this.name),
    map(x => x.value === 'pending')
  );
  token$ = store.pipe(select(state => state.token));
  isAuthenticated$ = this.token$.pipe(map(token => this.isTokenAuthenticated(token)));
  claims$ = this.token$.pipe(map(token => this.decodeToken(token)));
  name$ = this.claims$.pipe(map(claims => claims[ClaimTypes.Name] as string || null));
  email$ = this.claims$.pipe(map(claims => claims[ClaimTypes.Email] as string || null));
  displayName$ = combineLatest([this.name$, this.email$]).pipe(
    map(([name, email]) => name || email)
  );
  image$ = this.claims$.pipe(
    map(claims => {
      let image = claims[ClaimTypes.Image] as string || null;
      if (image && ImageInterceptor.resourcesRegex.test(image)) {
        image = ImageInterceptor.buildUrl(image, this.env.apiUrl);
      }
      return image;
    })
  );
  id$ = this.claims$.pipe(map(claims => claims[ClaimTypes.Sub] as string || null));
  expires$ = this.claims$.pipe(map(claims => claims[ClaimTypes.Expires] as number || null));
  roles$ = combineLatest([ this.claims$, this.isAuthenticated$ ]).pipe(
    map(([claims, isAuth]): UserRoles[] => {
      const roleClaim = claims && claims[ClaimTypes.Role];
      if (!roleClaim || !isAuth) {
        return [ ];
      }
      return Array.isArray(roleClaim) ? roleClaim : [roleClaim];
    })
  );
  features$ = this.claims$.pipe(
    map((claims): string[] => {
      const featureClaim = claims && claims[ClaimTypes.TenantFeatures];
      if (!featureClaim) {
        return [ ];
      }
      return Array.isArray(featureClaim) ? featureClaim : [featureClaim];
    })
  );
  hasFeature$ = (feature: TenantFeatures) => this.features$.pipe(
    map(features => features.includes(feature))
  );
  isImpersonating$ = this.claims$.pipe(map(claims => !!claims[ClaimTypes.ImpersonatorId]));
  isSuperAdmin$ = this.roles$.pipe(
    map(roles => roles.indexOf(UserRoles.Superamin) >= 0)
  );
  isTenantAdmin$ = this.roles$.pipe(
    map(roles => roles.indexOf(UserRoles.TenantAdmin) >= 0)
  );
  isAnyAdmin$ = combineLatest([this.isSuperAdmin$, this.isTenantAdmin$]).pipe(
    map(([isSuper, isTenant]) => isSuper || isTenant)
  );
  isDriver$ = this.roles$.pipe(
    map(roles => roles.indexOf(UserRoles.Driver) >= 0)
  );

  private expiryClock$ = timer(0, EXPIRES_CHECK_MS).pipe(
    withLatestFrom(this.expires$),
    map(([_, expires]) => expires && (expires * 1000))
  );
  isExpiresSoon$ = this.expiryClock$.pipe(
    map(expires => !expires
      || (expires - Date.now()) < EXPIRES_SOON_MS),
    distinctUntilChanged(),
    filter(x => !!x)
  );
  isExpired$ = this.expiryClock$.pipe(
    filter(expires => !!expires && expires < Date.now()),
  );

  settings$ = store.pipe(select(state => state.settings));
  isSettingsLoading$ = store.pipe(
    selectRequestStatus(`${this.name}_settings`),
    map(x => x.value === 'pending')
  );

  getActiveSettings() {
    return store.getValue().settings;
  }

  getId() {
    const token = store.getValue().token;
    const claims = this.decodeToken(token);
    return claims[ClaimTypes.Sub] as string || null;
  }

  setToken(token: AuthProps['token']) {
    store.update((state) => ({
      ...state,
      token
    }));
    store.update(
      updateRequestsStatus([this.name], 'success')
    );
  }

  setSettings(settings: UserSettings) {
    store.update((state) => ({
      ...state,
      settings
    }));
    store.update(
      updateRequestsStatus([`${this.name}_settings`], 'success')
    );
  }

  isAuthenticated() {
    const token = store.getValue().token;
    return this.isTokenAuthenticated(token);
  }

  isInRole(role: string) {
    const token = store.getValue().token;
    const claims = this.decodeToken(token);
    const roleClaim = claims[ClaimTypes.Role];
    return !!roleClaim && (roleClaim === role
      || Array.isArray(roleClaim) && roleClaim.indexOf(role) >= 0);
  }

  private isTokenAuthenticated(token: string | null) {
    return !!token && !this.jwtHelper.isTokenExpired(token);
  }

  private decodeToken(token: string | null) {
    return token && this.jwtHelper.decodeToken<{ [claim: string]: any }>(token) || {};
  }
}
