import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusState } from '@ngneat/elf-requests';
import { Observable, switchMap } from 'rxjs';
import { UrlIdResolverService } from 'src/app/modules/shared/services/url-id-resolver.service';
import { AuthRepository, UserRoles } from 'src/app/state/auth.repository';
import { WorkdayEventsRepository } from 'src/app/state/workdayevents.repository';
import { WorkDayEventsService } from 'src/app/state/workdayevents.service';
import {
  Workday,
  WorkdayEvent,
  WorkdaysRepository,
} from 'src/app/state/workdays.repository';
import { WorkdaysService } from '../../../../state/workdays.service';

@Component({
  selector: 'app-day-event-page',
  templateUrl: './day-event-page.component.html',
  styleUrls: ['./day-event-page.component.scss'],
})
export class DayEventPageComponent implements OnInit {
  workDayEvent$: Observable<WorkdayEvent> | null = null;
  id: string | undefined;
  workDayId: string | null = null;
  workDay$: Observable<Workday> | null = null;
  submitErrors: string[] | null = null;
  isAdminPage?: string | null;
  tripId?: string;

  constructor(
    private eventService: WorkDayEventsService,
    public repo: WorkdayEventsRepository,
    public workdaysRepo: WorkdaysRepository,
    public workdaysService: WorkdaysService,
    private authRepo: AuthRepository,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private urlId: UrlIdResolverService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(
      (x) => (this.isAdminPage = x.get('isAdminPage'))
    );

    this.activatedRoute.paramMap.subscribe(
      (x) => (this.workDayId = x.get('workdayid'))
    );
    if (this.workDayId) {
      this.workDay$ = this.workdaysService.loadOneById(this.workDayId);
    }

    this.workDayEvent$ = this.urlId.resolve(
      this.activatedRoute.paramMap,
      (id) => this.eventService.loadOne(id),
      (id) => this.repo.one(id),
      (id) => this.repo.statusOne(id),
      (id) => (this.id = id)
    );
  }

  editEvent(event: Partial<WorkdayEvent>) {
    this.submitErrors = null;
    this.workDayEvent$?.subscribe(
      (x) => (event.eventLocation = x.eventLocation)
    );
    if (this.id && this.id !== 'new') {
      this.eventService.update(this.id, event).subscribe({
        complete: () => {
          if (this.authRepo.isInRole(UserRoles.Driver)) {
            this.router.navigate(['/workdays']);
          } else {
            this.router.navigate(['drivers/workdays']);
          }
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }

  addPause(event: Partial<WorkdayEvent>) {
    if (this.workDayId && event) {
      event.workDayId = this.workDayId;
      this.workdaysService.addPause(event).subscribe({
        complete: () => {
          if (this.authRepo.isInRole(UserRoles.Driver)) {
            this.router.navigate(['/workdays']);
          } else {
            this.router.navigate(['drivers/workdays']);
          }
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }
}
