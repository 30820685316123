import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Column } from 'src/app/state/columns.repository';

@Component({
  selector: 'app-columns-list',
  templateUrl: './columns-list.component.html',
  styleUrls: ['./columns-list.component.scss']
})
export class ColumnsListComponent implements OnInit {
  @Input() columns?: Column[] | null = null;
  @Output() colEmiter = new EventEmitter<Column[]>();
  @Input() isEconomic = false;
  counter: number | null = null;
  draggenIndex: number | null = null;
  
  constructor() { }
  taken!: Column;
  pointed!: Column;

  ngOnInit(): void {
  }

  take(column: Column) {    
    this.taken = column;    
    if(this.pointed && this.taken) {
      this.exchange()
    }    
  }  
 
  point(pointed: Column) {    
    this.pointed = pointed;
  }

  exchange() {
    let takenIndex = this.columns?.indexOf(this.taken);
    let pointedIndex = this.columns?.indexOf(this.pointed);
    if(this.columns && (takenIndex || takenIndex === 0) && (pointedIndex || pointedIndex === 0)) {
      if(takenIndex === pointedIndex) {
        return;
      }
      if(takenIndex - 1 === pointedIndex) {
        let change = this.taken.orderNumber;
        this.taken.orderNumber = this.pointed.orderNumber;
        this.pointed.orderNumber = change;
        this.columns![takenIndex!] = this.pointed;
        this.columns![pointedIndex!] = this.taken;
      }
      if(takenIndex - 1 > pointedIndex) {        
        this.taken.orderNumber = pointedIndex;
        this.columns.splice(pointedIndex, 0, this.taken);
        this.columns.splice((takenIndex + 1), 1);
        this.columns[(pointedIndex + 1)] = this.pointed;     
        this.pointed.orderNumber = (pointedIndex + 1);
              
        this.columns = this.columns.map(x => {
          let index = this.columns?.indexOf(x);       
          if(index! > pointedIndex! + 1) {
            x.orderNumber = index!; 
          }
          return x;
        });
      }
      if(takenIndex === pointedIndex - 1) {        
        let change = this.taken.orderNumber;
        this.taken.orderNumber = this.pointed.orderNumber;
        this.pointed.orderNumber = change;
        this.columns![takenIndex!] = this.pointed;
        this.columns![pointedIndex!] = this.taken;
      }
      if(takenIndex < pointedIndex - 1) {        
        this.taken.orderNumber = this.pointed.orderNumber;        
        this.columns.splice(pointedIndex + 1, 0, this.taken);
        this.columns.splice(takenIndex, 1);
        this.columns.map((x) => {
          x.orderNumber = this.columns!.indexOf(x);
          return x;
        });        
      }
      this.colEmiter.emit(this.columns);
    }
        
  }  
}

  
