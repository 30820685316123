<app-sort-table-pagination
  [(page)]="_state.page"
  [pages]="pages"
  (pageChange)="setPageData()"
></app-sort-table-pagination>
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-4 col-md-8 col-lg-6">
      <label for="dateFrom" class="col-xs-3 col-sm-auto col-form-label"
        >Fra</label
      >
      <input
        id="dateFrom"
        type="date"
        class="form-control"
        name="dateFrom"
        [(ngModel)]="filterOptions!.dateFrom"
        (ngModelChange)="filterChange()"
      />
    </div>
    <div class="col-sm-4 col-md-8 col-lg-6">
      <label for="dateTo" class="col-xs-3 col-sm-auto col-form-label"
        >Til</label
      >
      <input
        id="dateTo"
        type="date"
        class="form-control"
        name="dateTo"
        [(ngModel)]="filterOptions!.dateTo"
        (ngModelChange)="filterChange()"
      />
    </div>

    <div class="col-sm-3 col-md-6 col-lg-4">
      <label for="dateTo" class="col-xs-3 col-sm-auto col-form-label"
        >Kunder</label
      >
      <app-datalist-select
        id="clientsSelect"
        [options]="clientOptions"
        [(activeValue)]="filterOptions!.clientSelected"
        (activeValueChange)="filterChange()"
        label="Kunder"
        i18n-label="Label client"
      ></app-datalist-select>
    </div>
    <div *ngIf="isAdminPage" class="col-sm-3 col-md-6 col-lg-4">
      <label for="dateTo" class="col-xs-3 col-sm-auto col-form-label"
        >Chauffør</label
      >
      <app-datalist-select
        id="driversSelect"
        [options]="driverOptions"
        [(activeValue)]="filterOptions!.driverSelected"
        (activeValueChange)="filterChange()"
        label="Chauffør"
        i18n-label="Label driver"
      ></app-datalist-select>
    </div>
    <div class="col-sm-3 col-md-6 col-lg-4">
      <label for="dateTo" class="col-xs-3 col-sm-auto col-form-label"
        >Produkter</label
      >
      <app-datalist-select
        id="productsSelect"
        [options]="productsOptions"
        [multiple]="true"
        [(activeValue)]="filterOptions!.productSelected"
        (activeValueChange)="filterChange()"
        label="Produkter"
        i18n-label="Features label"
      ></app-datalist-select>
    </div>
    <div class="col-sm-3 col-md-6 col-lg-4 mt-4">
      <button
        class="btn btn-lg btn-outline-primary me-3"
        style="max-height: 78%; width: 48%"
        [class.btn-primary]="tripsEconomicExport.length !== 0"
        [disabled]="tripsEconomicExport.length === 0"
        (click)="bulkExport()"
        i18n="ExportEconomic"
      >
        <h6 class="mt-1">Export to economic</h6>
      </button>
      <button
        class="btn btn-lg btn-outline-primary"
        style="max-height: 78%; width: 48%"
        [class.btn-primary]="tripsEconomicExport.length !== 0"
        [disabled]="tripsEconomicExport.length === 0"
        (click)="bulkExport(true)"
        i18n="ExportEconomicunion"
      >
        <h6 class="mt-1">Export union</h6>
      </button>
    </div>
    <div class="col-sm-12 col-md-10 col-lg-8 mt-4">
      <div
        class="btn-group box-group w-100 box-normal"
        [class.box-bigger]="eprods && eprods.length > 1"
      >
        <div class="form-group form-floating mb-3 w-100 h-100">
          <app-datalist-select
            [options]="eproductOptions"
            [multiple]="true"
            [activeValue]="eprods"
            (activeValueChange)="updateValue($event)"
            label="Products"
            i18n-label="Products label"
          ></app-datalist-select>
        </div>
        <button
          class="btn btn-lg btn-outline-primary w-100"
          style="max-height: 78% !important"
          (click)="bulkUpdate()"
          [ngClass]="
            tripsEconomicExport.length > 0 && this.eprods.length > 0
              ? 'btn-primary'
              : 'bg-greyish disabled'
          "
          i18n="BulkUpdate"
        >
          Update trips
        </button>
      </div>
    </div>
    <div *ngIf="isAdminPage" class="col-12 px-4">
      <label
        for="isApprovedSearch"
        class="col-xs-3 col-sm-auto col-form-label align-middle"
        >Ikke behandlet</label
      >
      <input
        id="isApprovedSearch"
        name="isApprovedSearch"
        [(ngModel)]="filterOptions!.isApproved"
        (ngModelChange)="filterChange()"
        class="text-center align-middle aprove-box"
        type="checkbox"
      />
    </div>
  </div>
  <app-error-alert
    title="Export errors"
    i18n-title="Export errors"
    [errors]="submitErrors"
  ></app-error-alert>
</div>
<div class="table-responsive custom-scroll">
  <table class="table table-hover table-bordered">
    <thead>
      <tr>
        <th
          class="text-center align-middle hoverable"
          [class.px-5]="
            item.danskName === 'Kunde Navn' ||
            item.danskName === 'Kunde' ||
            item.danskName === 'Produktet'
          "
          scope="col"
          *ngFor="let item of simpleColumns; let i = index"
          [hidden]="!item.isVisible"
          sortable="{{
            item.danskName !== 'Vælg' ? standartVariableNames[i] : null
          }}"
          (sort)="item.danskName !== 'Vælg' ? onSort($event) : null"
        >
          {{ item.danskName }}
          <i
            *ngIf="item.danskName !== 'Vælg'"
            [ngClass]="{
              'bi-arrow-up': headers?.get(i)?.direction === 'asc',
              'bi-arrow-down': headers?.get(i)?.direction === 'desc'
            }"
            class="bi"
          ></i>
          <input
            *ngIf="item.danskName === 'Vælg'"
            [checked]="allSelected"
            (change)="changeAll()"
            style="width: 20px; height: 20px"
            type="checkbox"
            aria-label=""
          />
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        [class.bg-approved]="isAdminPage && tripLog.isApproved"
        [class.bg-noclient]="tripLog.failedSyncAt"
        *ngFor="let tripLog of filteredTrips; let i = index"
        [class.bg-yellow]="tripLog.isOrder"
        id="ordering{{ i }}"
      >
        <th id="chosen{{ i }}" class="text-center align-middle">
          <input
            [checked]="tripLog?.exportedAt || checkIfChosen(tripLog.id)"
            (change)="changeChosen(tripLog.id, $event)"
            [class.isDisabled]="tripLog.exportedAt"
            class="checkbox-box"
            type="checkbox"
          />
        </th>
        <td id="Exported{{ i }}" class="text-center align-middle">
          <div>{{ tripLog.exportedAt ? "Ja" : "Nej" }}</div>
          <div *ngIf="tripLog.exportedAt">
            <i
              (click)="unexportCandidate = tripLog"
              class="unexport bi bi-file-earmark-arrow-up"
            ></i>
          </div>
        </td>
        <td id="modal{{ i }}" class="text-center align-middle hoverable">
          <a
            class="anchor"
            *ngIf="tripLog.hasLoadUnloadEvents"
            (click)="getDataToSortModal(tripLog)"
            >*</a
          >
        </td>
        <th
          id="Approved{{ i }}"
          *ngIf="isAdminPage"
          class="text-center align-middle"
        >
          <input
            [disabled]="updateInProgress"
            [checked]="tripLog?.isApproved"
            (change)="changeApprove(tripLog)"
            class="checkbox-box"
            type="checkbox"
          />
        </th>
        <td id="Note{{ i }}" class="text-center align-middle">
          <span
            [innerHTML]="
              tripLog.noteStartEnd + ' / ' + (tripLog.officeNote ?? '')
            "
          ></span>
          <br />
          <a
            class="btn btn-sm btn-link py-0 p-md-0"
            (click)="openOfficeNote(tripLog)"
          >
            <i class="bi bi-pencil me-1"></i
          ></a>
        </td>
        <td
          id="TenantNote{{ i }}"
          *ngIf="isAdminPage"
          class="text-center align-middle hoverable"
        >
          <a
            *ngIf="tripLog.tenantNote"
            [class.anchor-blue]="tripLog.tenantNote"
            (click)="openTenantComment(tripLog)"
            >*</a
          >
        </td>
        <td id="Numberplate{{ i }}" class="text-center align-middle">
          {{ tripLog.carNumber }}
        </td>
        <td id="Trailer{{ i }}" class="text-center align-middle">
          {{ tripLog.trailerNumber }}
        </td>
        <td
          id="ClientID{{ i }}"
          class="text-center align-middle"
          [innerHTML]="tripLog.clientNumberStartEnd"
        ></td>
        <td id="ClientName{{ i }}" class="text-center align-middle">
          <span [innerHTML]="tripLog.clientNameStartEnd"></span>
          <a
            class="btn btn-sm btn-link py-0 mx-3"
            (click)="getEventClients(tripLog)"
          >
            <i
              [ngClass]="tripLog.exportedAt ? 'bi-eye strong' : 'bi-pencil'"
              class="bi ms-2 me-1"
            ></i>
            <i
              [class.exported]="tripLog.exportedAt"
              class="bi ms-1 me-1"
              [ngClass]="
                tripLog.isClientsReadyToExport
                  ? 'bi-check-lg anchor-green-smaller'
                  : 'bi-x-circle anchor-red-smaller'
              "
            ></i>
          </a>
        </td>
        <td id="Product{{ i }}" class="text-center align-middle">
          <a
            class="btn btn-sm btn-link py-0 p-md-0"
            (click)="openProductGroupModal(tripLog)"
          >
            <i
              [ngClass]="tripLog.exportedAt ? 'bi-eye strong' : 'bi-pencil'"
              class="bi ms-2 me-1"
            ></i>
            <i
              [class.exported]="tripLog.exportedAt"
              class="bi ms-1 me-1"
              [ngClass]="
                tripLog.isProductsReadyToExport
                  ? 'bi-check-lg anchor-green-smaller'
                  : 'bi-x-circle anchor-red-smaller'
              "
            ></i>
          </a>
        </td>
        <td id="DriverID{{ i }}" class="text-center align-middle">
          {{ tripLog.driverName }}
        </td>
        <td
          id="ProductName{{ i }}"
          class="text-center align-middle"
          [innerHTML]="tripLog.productNameStartEnd"
        ></td>
        <td id="Address{{ i }}" class="text-center align-middle">
          <span
            [innerHTML]="
              (tripLog.pickUpAddress ?? '') +
              ' / ' +
              (tripLog.deliveryAddress ?? '')
            "
          ></span
          ><br />
          <a
            class="btn btn-sm btn-link py-0 p-md-0"
            (click)="openAdressPopUp(tripLog)"
            ><i class="bi bi-pencil me-1"></i
          ></a>
        </td>
        <td
          id="Weight{{ i }}"
          class="text-center align-middle"
          [innerHTML]="tripLog.weightStartEnd"
        ></td>
        <td id="TripTotalKm{{ i }}" class="text-center align-middle">
          {{ tripLog.tripKm }}
        </td>
        <td id="Totaltime{{ i }}" class="text-center align-middle">
          {{ convertMS(tripLog.tripDuration) }}
        </td>
        <td
          id="VendorID{{ i }}"
          class="text-center align-middle"
          [innerHTML]="tripLog.vendorIdStartEnd"
        ></td>
        <td
          id="VendorName{{ i }}"
          class="text-center align-middle"
          [innerHTML]="tripLog.vendorNameStartEnd"
        ></td>
        <td
          id="Reference{{ i }}"
          class="text-center align-middle"
          [innerHTML]="tripLog.referenceStartEnd"
        ></td>
        <td
          id="Orderinfo{{ i }}"
          class="text-center align-middle"
          [innerHTML]="tripLog.commentStartEnd"
        ></td>
        <td id="TripStartphotos{{ i }}" class="text-center align-middle">
          <span *ngIf="tripLog?.isStartPhotosAdded">
            <a
              class="btn btn-sm btn-link py-0 p-md-0"
              (click)="getUrls(tripLog.id, 'TripStart')"
            >
              <i class="bi bi-card-image"></i>
            </a>
          </span>
        </td>
        <td id="Weighingphotos{{ i }}" class="text-center align-middle">
          <span *ngIf="tripLog?.isWeighingPhotosAdded">
            <a
              class="btn btn-sm btn-link py-0 p-md-0"
              (click)="getUrls(tripLog.id, 'Weighing')"
            >
              <i class="bi bi-card-image"></i>
            </a>
          </span>
        </td>
        <td id="TripStarttime{{ i }}" class="text-center align-middle">
          {{ tripLog.startTime | date : "dd-MM-YYYY HH:mm:ss" }}
        </td>
        <td id="TripStartKm{{ i }}" class="text-center align-middle">
          {{ tripLog.startKm }}
        </td>
        <td
          [hidden]="true"
          id="EditTripStart{{ i }}"
          class="text-center align-middle"
        >
          <a
            class="btn btn-sm btn-link py-0 p-md-0"
            [routerLink]="[
              '/trips',
              'edit',
              tripLog.tripEventStartId,
              isAdminPage
            ]"
          >
            <i class="bi bi-pencil me-1"></i>
            <ng-container i18n="@@edit">Edit</ng-container>
          </a>
        </td>
        <td id="StartTripposition{{ i }}" class="text-center align-middle">
          <a target="_blank" href="{{ tripLog.startPosition }}">Link</a>
        </td>
        <td id="TripEndphotos{{ i }}" class="text-center align-middle">
          <span *ngIf="tripLog.isEndPhotosAdded">
            <a
              class="btn btn-sm btn-link py-0 p-md-0"
              (click)="getUrls(tripLog.id, 'TripEnd')"
            >
              <i class="bi bi-card-image"></i>
            </a>
          </span>
        </td>
        <td id="TripEndTime{{ i }}" class="text-center align-middle">
          {{ tripLog.endTime | date : "dd-MM-YYYY HH:mm:ss" }}
        </td>
        <td id="TripEndKm{{ i }}" class="text-center align-middle">
          {{ tripLog.endKm }}
        </td>
        <td
          [hidden]="true"
          id="EditTripEnd{{ i }}"
          class="text-center align-middle"
        >
          <a
            *ngIf="tripLog.tripEventEndId"
            class="btn btn-sm btn-link py-0 p-md-0"
            [routerLink]="[
              '/trips',
              'edit',
              tripLog.tripEventEndId,
              isAdminPage
            ]"
          >
            <i class="bi bi-pencil me-1"></i>
            <ng-container i18n="@@edit">Edit</ng-container>
          </a>
        </td>
        <td id="TripEndGPS{{ i }}" class="text-center align-middle">
          <a
            *ngIf="tripLog.endPosition"
            target="_blank"
            href="{{ tripLog.endPosition }}"
            >Link</a
          >
        </td>

        <td
          id="Cleaning{{ i }}"
          class="text-center align-middle"
          [innerHTML]="tripLog.cleaningStartEnd"
        ></td>
        <td
          id="Accessory{{ i }}"
          class="Accessory{{ i }}"
          class="text-center align-middle"
          [innerHTML]="tripLog.accessoryStartEnd"
        ></td>
      </tr>
    </tbody>
  </table>
</div>
<app-sort-table-pagination
  [(page)]="_state.page"
  [pages]="pages"
  (pageChange)="setPageData()"
></app-sort-table-pagination>

<app-single-textbox-modal
  *ngIf="selectedTripLog && noteEditing"
  [title]="'Vognmand Bemærkning'"
  [text]="selectedTripLog.tenantNote"
  (stringEmmiter)="addTenantNote($event)"
  (cancel)="cancelEvent()"
></app-single-textbox-modal>

<app-trip-image-slider
  *ngIf="photoUrls"
  [photoUrls]="photoUrls"
  [type]="sliderType"
  (movePhoto)="movePhotos($event)"
  (cancel)="cancelEvent()"
></app-trip-image-slider>

<app-sort-table-modal
  id="sortModal"
  *ngIf="selectedTripLog && sortModalActive"
  [tripLog]="selectedTripLog"
  [isAdminPage]="isAdminPage"
  [isEconomic]="true"
  [simpleLoadUnloadColumns]="simpleLoadUnloadColumns"
  (cancel)="cancelEvent()"
></app-sort-table-modal>

<app-office-note-form
  *ngIf="selectedTripLog && officeNoteModalActive"
  [officeNote]="selectedTripLog.officeNote"
  [endNote]="selectedTripLog.noteStartEnd"
  (confirm)="updateOfficeNote($event)"
  (cancel)="cancelEvent()"
>
</app-office-note-form>

<app-office-address-form
  *ngIf="selectedTripLog && addressModalActive"
  [deliveryAddress]="selectedTripLog.deliveryAddress"
  [pickUpAddress]="selectedTripLog.pickUpAddress"
  (cancel)="cancelEvent()"
  (confirm)="updateAdress($event)"
></app-office-address-form>

<app-client-update-modal
  *ngIf="clientModalActive && tripClients && selectedTripLog"
  [clients]="allClients"
  [tripEvents]="tripClients"
  [exported]="!!selectedTripLog.exportedAt"
  (cancel)="cancelEvent()"
  (confirm)="updateClients($event)"
></app-client-update-modal>
<app-single-group-modal
  *ngIf="trip && isWorkEventsLoaded"
  (economicEmmiter)="updateGroups($event)"
  [eproducts]="allProducts"
  [priceTypes]="priceTypes"
  [workEvents]="workEvents"
  [trip]="trip"
  (close)="closeGroupModal()"
></app-single-group-modal>
<app-confirm-action
  *ngIf="unexportCandidate"
  (confirm)="
    handleUnexportClick(unexportCandidate.id); unexportCandidate = undefined
  "
  (cancel)="unexportCandidate = undefined"
></app-confirm-action>
