import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { async, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Company } from 'src/app/state/companies.repository';
import { CompaniesService } from 'src/app/state/companies.service';
import { Product, ProductsRepository } from '../../../../state/products.repository';
import { ProductsService } from '../../../../state/products.service';
import { TripDto, TripsRepository } from '../../../../state/trips.repository';
import { TripsService } from '../../../../state/trips.service';
import { User, UsersRepository } from '../../../../state/users.repository';
import { UsersService } from '../../../../state/users.service';
import { Workday, WorkdaysRepository } from '../../../../state/workdays.repository';
import { WorkdaysService } from '../../../../state/workdays.service';

@Component({
  selector: 'app-drivers-workdays-page',
  templateUrl: './drivers-workdays-page.component.html',
  styleUrls: ['./drivers-workdays-page.component.scss']
})
export class DriversWorkdaysPageComponent implements OnInit {
  dateType?: 'day' | ' week' | 'month';
  workdays: Workday[] | null = null;
  drivers: User[] | null = null;
  clients: Company[] | null | any = null;
  products: Product[] | null | any = null;
  constructor(
    public workDaysRepo: WorkdaysRepository,
    public workDaysService: WorkdaysService,
    public repo: TripsRepository,
    public service: TripsService,
    public router: ActivatedRoute,
    public usersRepo: UsersRepository,
    public usersService: UsersService,
    private companyService: CompaniesService,
    public productsRep: ProductsRepository,
    public productsService: ProductsService
  ) { }

  ngOnInit(): void {
    this.workDaysService.loadOverview().subscribe();
    this.service.loadOverview().subscribe();
    this.dateType = this.router.snapshot.data.dateType;
    this.usersService.loadDrivers().subscribe(x => this.drivers = x);
    this.companyService.loadWithoutPagination().subscribe((x: any) => this.clients = x.data.filter((y: any) => y.canBeClient === true));
    this.productsService.loadWithoutPagination().subscribe(x => this.products = x);  
  }
}
