import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { sortBy } from 'src/app/modules/shared/pipes/sort.pipe';
import { Order } from 'src/app/state/orders.repository';
import { Trip, TripEvent } from 'src/app/state/trips.repository';
const ADD_NOTE_TITLE = $localize `:Add notete title:Add note`
@Component({
  selector: 'app-end-trip-form',
  templateUrl: './end-trip-form.component.html',
  styleUrls: ['./end-trip-form.component.scss']
})
export class EndTripFormComponent implements OnInit {
  noteTitle = ADD_NOTE_TITLE;
  text = [''];
  title = new Array<string>();
  @Input() set currentTrip(value: Trip | null) {
    this.trip = value;
    this.tripStartEvent = value?.tripEvents
    .find(x => x.type === 'TripStart');
    this.tripStart = value?.tripEvents
      .find(x => x.type === 'TripStart')
      ?.eventTime || null;  
    if(this.tripStartEvent && this.tripStartEvent.note) {
      this.text = [this.tripStartEvent.note]
    }  
  }
 
  @Input() isLoading: boolean | null = null;
  @Output() endTrip = new EventEmitter<void>();
  @Output() unload = new EventEmitter<void>();
  @Output() load = new EventEmitter<void>();
  @Output() takePhotos = new EventEmitter<File[]>();
  @Output() takeWeighingPhotos = new EventEmitter<Partial<TripEvent>>();

  @Output() makeNote = new EventEmitter<string>();
  
  
  trip?: Trip | null;
  tripStartEvent?: TripEvent | null;
  tripStart: Date | null = null;

  constructor() { }
  innerWidth = 0;
  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  } 
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;    
  }
  addImage($event: any) {    
    let files = $event.target.files;
    if (files) {      
      this.takePhotos.emit(files);
    }
  }

  openNote() {
    this.title = [this.noteTitle];    
  }

  addNote($event: string) {
    this.makeNote.emit($event)
  }
  addWeighingImage($event: any) {
    let files = $event.target.files;
    if (files) {
      this.takeWeighingPhotos.emit({clientId: this.tripStartEvent?.clientId ,tripFiles: files});
    }    
  }
}
