import { Component, OnInit } from '@angular/core';
import { SimpleCardAction } from '../../../shared/components/simple-card/simple-card.component';

@Component({
  selector: 'app-session-over-state',
  templateUrl: './session-over-state.component.html',
  styleUrls: ['./session-over-state.component.scss']
})
export class SessionOverStateComponent implements OnInit {
  actions: SimpleCardAction[] = [
    {
      text: $localize `:Refresh page button:Refresh`,
      routerLink: ['/signin']
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

  refresh($event: Event) {
    $event.preventDefault();
    window.location.reload();
  }

}
