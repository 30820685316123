<div class="closemenu text-muted" (click)="menuclose()" i18n="Close menu label">
  Close Menu
</div>
<div class="sidebar">
  <div class="row">
    <div class="col-auto mx-auto text-center">
      <app-user-card
        class="mobile-card"
        size="md"
        (profileClick)="menuclose()"
      ></app-user-card>
      <app-user-card
        class="desktop-card"
        [size]="(ui.isMenuExpanded$ | async) ? 'md' : 'sm'"
        (profileClick)="menuclose()"
      ></app-user-card>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <ul class="nav nav-pills">
        <li class="nav-item" *ngIf="repo.isAnyAdmin$ | async">
          <a
            class="nav-link"
            aria-current="page"
            routerLink="/home"
            routerLinkActive="active"
            (click)="menuclose()"
          >
            <div class="avatar avatar-40 rounded icon">
              <i class="bi bi-house-door"></i>
            </div>
            <div class="col" i18n="Label for admin dashboard">Dashboard</div>
          </a>
        </li>
        <!-- <li class="nav-item" *ngIf="features.truck && (repo.isTenantAdmin$ | async)">
              <a class="nav-link" aria-current="page" routerLink="/orders" routerLinkActive='active' (click)="menuclose()">
                <div class="avatar avatar-40 rounded icon"><i class="bi bi-lightning-charge"></i></div>
                <div class="col" i18n="Label for orders page">Orders</div>
              </a>
            </li> -->
        <li class="nav-item" *ngIf="features.truck && (repo.isDriver$ | async)">
          <a
            class="nav-link"
            aria-current="page"
            routerLink="/go"
            routerLinkActive="active"
            (click)="menuclose()"
          >
            <div class="avatar avatar-40 rounded icon">
              <i class="bi bi-truck"></i>
            </div>
            <div class="col" i18n="Label for driver controls page">Go</div>
          </a>
        </li>
        <li class="nav-item" *ngIf="features.truck && (repo.isDriver$ | async)">
          <a
            class="nav-link"
            aria-current="page"
            routerLink="/workdays"
            routerLinkActive="active"
            (click)="menuclose()"
          >
            <div class="avatar avatar-40 rounded icon">
              <i class="bi bi-stopwatch"></i>
            </div>
            <div class="col" i18n="Label for time log">Time log</div>
          </a>
        </li>
        <li class="nav-item" *ngIf="features.truck && (repo.isDriver$ | async)">
          <a
            class="nav-link"
            aria-current="page"
            routerLink="/trips"
            routerLinkActive="active"
            (click)="menuclose()"
          >
            <div class="avatar avatar-40 rounded icon">
              <i class="bi bi-signpost-split"></i>
            </div>
            <div class="col" i18n="Label for trips log">Trips log</div>
          </a>
        </li>
        <li class="nav-item" *ngIf="features.truck && (repo.isDriver$ | async)">
          <a
            class="nav-link"
            aria-current="page"
            routerLink="/trip/settings"
            routerLinkActive="active"
            (click)="menuclose()"
          >
            <div class="avatar avatar-40 rounded icon">
              <i class="bi bi-gear"></i>
            </div>
            <div class="col" i18n="Label for trips settings">Trip settings</div>
          </a>
        </li>
        <li class="nav-item" *ngIf="repo.isSuperAdmin$ | async">
          <a
            class="nav-link"
            routerLink="/tenants"
            routerLinkActive="active"
            (click)="menuclose()"
          >
            <div class="avatar avatar-40 rounded icon">
              <i class="bi bi-building"></i>
            </div>
            <div class="col" i18n="Label for tenants page">Tenants</div>
          </a>
        </li>
        <li class="nav-item" *ngIf="repo.isAnyAdmin$ | async">
          <a
            class="nav-link"
            routerLink="/users"
            routerLinkActive="active"
            (click)="menuclose()"
          >
            <div class="avatar avatar-40 rounded icon">
              <i class="bi bi-people"></i>
            </div>
            <div class="col" i18n="Label for users page">Users</div>
          </a>
        </li>
        <li
          class="nav-item"
          *ngIf="features.truck && (repo.isTenantAdmin$ | async)"
        >
          <a
            class="nav-link"
            routerLink="/drivers/trips"
            routerLinkActive="active"
            (click)="menuclose()"
          >
            <div class="avatar avatar-40 rounded icon">
              <i class="bi bi-signpost-split me-1"></i>
            </div>
            <div class="col" i18n="Title for the time logs overview">
              Driver logs(Trips log)
            </div>
          </a>
        </li>
        <li
          class="nav-item"
          *ngIf="features.truck && (repo.isTenantAdmin$ | async)"
        >
          <a
            class="nav-link"
            routerLink="/drivers/parkedtrips"
            routerLinkActive="active"
            (click)="menuclose()"
          >
            <div class="avatar avatar-40 rounded icon">
              <i class="bi bi-signpost-split me-1"></i>
            </div>
            <div class="col" i18n="Title for the time logs overview parked">
              Driver logs(Trips log) - Parked
            </div>
          </a>
        </li>
        <li
          class="nav-item"
          *ngIf="
            features.truck &&
            (repo.isTenantAdmin$ | async) &&
            (repo.hasFeature$(TenantFeatures.Economic) | async)
          "
        >
          <a
            class="nav-link"
            routerLink="/drivers/economic"
            routerLinkActive="active"
            (click)="menuclose()"
          >
            <div class="avatar avatar-40 rounded icon">
              <i class="bi bi-calculator"></i>
            </div>
            <div class="col" i18n="Title for the trips economic">Economic</div>
          </a>
        </li>
        <li
          class="nav-item"
          *ngIf="
            features.truck &&
            (repo.isTenantAdmin$ | async) &&
            (repo.hasFeature$(TenantFeatures.Economic) | async)
          "
        >
          <a
            class="nav-link"
            routerLink="/eproducts"
            routerLinkActive="active"
            (click)="menuclose()"
          >
            <div class="avatar avatar-40 rounded icon">
              <i class="bi bi-collection"></i>
            </div>
            <div class="col" i18n="Title for the eproducts">Products</div>
          </a>
        </li>
        <li
          class="nav-item"
          *ngIf="features.truck && (repo.isTenantAdmin$ | async)"
        >
          <a
            class="nav-link"
            routerLink="/drivers/workdays"
            routerLinkActive="active"
            (click)="menuclose()"
          >
            <div class="avatar avatar-40 rounded icon">
              <i class="bi bi-stopwatch me-1"></i>
            </div>
            <div class="col" i18n="Title for the trips logs overview">
              Driver logs(Time log)
            </div>
          </a>
        </li>
        <li
          class="nav-item"
          *ngIf="features.truck && (repo.isTenantAdmin$ | async)"
        >
          <a
            class="nav-link"
            routerLink="/vehicles"
            routerLinkActive="active"
            (click)="menuclose()"
          >
            <div class="avatar avatar-40 rounded icon">
              <i class="bi bi-truck"></i>
            </div>
            <div class="col" i18n="Label for vehicles page">Vehicles</div>
          </a>
        </li>
        <li
          class="nav-item"
          *ngIf="features.truck && (repo.isTenantAdmin$ | async)"
        >
          <a
            class="nav-link"
            routerLink="/trailers"
            routerLinkActive="active"
            (click)="menuclose()"
          >
            <div class="avatar avatar-40 rounded icon">
              <i class="bi bi-minecart-loaded"></i>
            </div>
            <div class="col" i18n="Label for trailers page">Trailers</div>
          </a>
        </li>
        <li
          class="nav-item"
          *ngIf="
            features.truck &&
            (repo.isTenantAdmin$ | async) &&
            (repo.hasFeature$(TenantFeatures.Accessory) | async)
          "
        >
          <a
            class="nav-link"
            routerLink="/accessories"
            routerLinkActive="active"
            (click)="menuclose()"
          >
            <div class="avatar avatar-40 rounded icon">
              <i class="bi bi-tools"></i>
            </div>
            <div class="col" i18n="Label for accessories page">Accessories</div>
          </a>
        </li>
        <li
          class="nav-item"
          *ngIf="
            features.truck &&
            (repo.isTenantAdmin$ | async) &&
            (repo.hasFeature$(TenantFeatures.Cleaning) | async)
          "
        >
          <a
            class="nav-link"
            routerLink="/cleanings"
            routerLinkActive="active"
            (click)="menuclose()"
          >
            <div class="avatar avatar-40 rounded icon">
              <i class="bi bi-droplet"></i>
            </div>
            <div class="col" i18n="Label for cleanings page">Cleanings</div>
          </a>
        </li>
        <li class="nav-item" *ngIf="features.news">
          <a
            class="nav-link"
            routerLink="/news"
            routerLinkActive="active"
            (click)="menuclose()"
          >
            <div class="avatar avatar-40 rounded icon">
              <i class="bi bi-file-earmark-text"></i>
            </div>
            <div class="col" i18n="Label for news page">News</div>
          </a>
        </li>
        <li
          class="nav-item"
          *ngIf="
            features.truck &&
            (repo.isTenantAdmin$ | async) &&
            (repo.hasFeature$(TenantFeatures.Products) | async) &&
            !(repo.hasFeature$(TenantFeatures.Economic) | async)
          "
        >
          <a
            class="nav-link"
            routerLink="/products"
            routerLinkActive="active"
            (click)="menuclose()"
          >
            <div class="avatar avatar-40 rounded icon">
              <i class="bi bi-box"></i>
            </div>
            <div class="col" i18n="Label for products page">Products</div>
          </a>
        </li>
        <li
          class="nav-item"
          *ngIf="features.truck && (repo.isTenantAdmin$ | async)"
        >
          <a
            class="nav-link"
            routerLink="/companies/clients"
            routerLinkActive="active"
            (click)="menuclose()"
          >
            <div class="avatar avatar-40 rounded icon">
              <i class="bi bi-briefcase"></i>
            </div>
            <div class="col" i18n="Label for clients page">Clients</div>
          </a>
        </li>
        <li
          class="nav-item"
          *ngIf="features.truck && (repo.isTenantAdmin$ | async)"
        >
          <a
            class="nav-link"
            routerLink="/companies/vendors"
            routerLinkActive="active"
            (click)="menuclose()"
          >
            <div class="avatar avatar-40 rounded icon">
              <i class="bi bi-briefcase"></i>
            </div>
            <div class="col" i18n="Label for vendors page">Vendors</div>
          </a>
        </li>
        <li
          class="nav-item"
          *ngIf="features.truck && (repo.isTenantAdmin$ | async)"
        >
          <a
            class="nav-link"
            routerLink="/columns"
            routerLinkActive="active"
            (click)="menuclose()"
          >
            <div class="avatar avatar-40 rounded icon">
              <i class="bi bi-columns"></i>
            </div>
            <div class="col" i18n="Label for Columns order">Columns order</div>
          </a>
        </li>
        <li
          class="nav-item"
          *ngIf="
            features.truck &&
            (repo.isTenantAdmin$ | async) &&
            (repo.hasFeature$(TenantFeatures.Economic) | async)
          "
        >
          <a
            class="nav-link"
            routerLink="/templates"
            routerLinkActive="active"
            (click)="menuclose()"
          >
            <div class="avatar avatar-40 rounded icon">
              <i class="bi bi-bank"></i>
            </div>
            <div class="col" i18n="Label for Templates">Templates</div>
          </a>
        </li>
        <!-- <li class="nav-item" *ngIf="features.truck && (repo.isTenantAdmin$ | async)">
              <a class="nav-link" routerLink="/deleted-trips" routerLinkActive='active' (click)="menuclose()">
                <div class="avatar avatar-40 rounded icon"><i class="bi bi-trash"></i></div>
                <div class="col" i18n="Label for Deleted trips">Deleted trips</div>
              </a>
            </li> -->
        <li class="nav-item">
          <a
            *ngIf="!(repo.isImpersonating$ | async)"
            class="nav-link"
            href="#logout"
            (click)="logout()"
          >
            <div class="avatar avatar-40 rounded icon">
              <i class="bi bi-box-arrow-right"></i>
            </div>
            <div class="col" i18n="Logout button">Logout</div>
          </a>
          <a
            *ngIf="repo.isImpersonating$ | async"
            class="nav-link"
            href="#logout"
            (click)="unimpersonate()"
          >
            <div class="avatar avatar-40 rounded icon">
              <i class="bi bi-box-arrow-right"></i>
            </div>
            <div class="col" i18n="Stop impersonation button">
              Stop impersonating
            </div>
          </a>
        </li>
        <!-- <li class="nav-item mt-4">
              <a *ngIf="!(ui.isDarkMode$ | async)" class="nav-link" href="#mode" (click)="toggleDarkMode()">
                <div class="avatar avatar-40 rounded icon bg-dark text-white"><i class="bi bi-moon-stars"></i></div>
                <div class="col" i18n="Dark mode button">Switch to dark mode</div>
              </a>
              <a *ngIf="ui.isDarkMode$ | async" class="nav-link" href="#mode" (click)="toggleDarkMode()">
                <div class="avatar avatar-40 rounded icon bg-white text-dark"><i class="bi bi-cloud-sun"></i></div>
                <div class="col" i18n="Light mode button">Switch to light mode</div>
              </a>
            </li> -->
      </ul>
    </div>
  </div>
</div>
