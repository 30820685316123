import {
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { Column, SimpleColumn } from 'src/app/state/columns.repository';
import { DataSelectDto } from 'src/app/state/models/dataSelectModels/dataSelectDto';
import { TripLogDto } from 'src/app/state/models/trip/TripLogDto';
import {
  BulkEProduct,
  ClientEventDto,
  ClientEventInfoDto,
  EconomicInfoExtended,
  SearchState,
  TripDto,
  TripEventType,
} from 'src/app/state/trips.repository';
import { UserSelectDto } from 'src/app/state/users.repository';
import { FilterOptions, WorkdayEvent } from 'src/app/state/workdays.repository';
import {
  NgbdSortableHeader,
  SortColumn,
  SortDirection,
  SortEvent,
} from '../sortable.directive';
import { DataSelectService } from 'src/app/state/dataSelect.service';
import { Router } from '@angular/router';
import { TripsService } from 'src/app/state/trips.service';
import { OrdersService } from 'src/app/state/orders.service';
import { TenantNoteChangeDto } from 'src/app/state/models/shared/TenantNoteChangeDto';
import { TripEventFileUploadDto } from 'src/app/state/models/shared/TripEventFileUploadDto';
import { TripPhotoUrlDto } from 'src/app/state/models/trip/TripPhotoUrlDto';
import { AdressChangeDto } from 'src/app/state/models/shared/AddressChangeDto';
import { TripEventClientDto } from 'src/app/state/models/trip/TripEventClientDto';
import { WorkDayEventsService } from 'src/app/state/workdayevents.service';
import { PriceType } from 'src/app/state/pricetypes.repository';

import { TempService } from 'src/app/state/temp/temp.service';

const compare = (v1: string | number | any, v2: string | number | any) =>
  v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(
  dtos: TripLogDto[],
  column: SortColumn,
  direction: string
): TripLogDto[] {
  let paramName: string = '';
  switch (column) {
    case 'ClientName':
      paramName = 'clientNameStartEnd';
      break;
    case 'ClientID':
      paramName = 'clientNumberStartEnd';
      break;
    case 'Weight':
      paramName = 'weightStartEnd';
      break;
    case 'TripStartKm':
      paramName = 'startKm';
      break;
    case 'TripTotalKm':
      paramName = 'tripKm';
      break;
    case 'ProductName':
      paramName = 'productNameStartEnd';
      break;
    case 'TripEndKm':
      paramName = 'endKm';
      break;
    case 'Cleaning':
      paramName = 'cleaningStartEnd';
      break;
    case 'TenantNote':
      paramName = 'tenantNote';
      break;
    case 'TripStarttime':
      paramName = 'startTime';
      break;
    case 'TripEndTime':
      paramName = 'endTime';
      break;
    case 'Totaltime':
      paramName = 'tripDuration';
      break;
    case 'Note':
      paramName = 'noteStartEnd';
      break;
    case 'Reference':
      paramName = 'referenceStartEnd';
      break;
    case 'VendorName':
      paramName = 'vendorNameStartEnd';
      break;
    case 'Accessory':
      paramName = 'accessoryStartEnd';
      break;

    default:
      paramName = 'startTime';
      break;
  }

  return [...dtos].sort((a: any, b: any) => {
    const res = compare(a[paramName] ?? '', b[paramName] ?? '');
    return direction === 'asc' ? res : -res;
  });
}

@Component({
  selector: 'app-economic-table1',
  templateUrl: './economic-table1.component.html',
  styleUrls: ['./economic-table1.component.scss'],
})
export class EconomicTable1Component {
  pages: number[] = [1];

  public _state: SearchState = {
    page: 1,
    pageSize: 20,
    searchTerm: '',
    sortColumn: '',
    sortDirection: '',
    unloadPosition: '',
  };

  driverStaticOptions: DatalistOption[] | null = null;
  driverOptions: DatalistOption[] | null = null;

  clientStaticOptions: DatalistOption[] | null = null;
  clientOptions: DatalistOption[] | null = null;

  productsStaticOptions: DatalistOption[] | null = null;
  productsOptions: DatalistOption[] | null = null;
  eproductOptions: DatalistOption[] | null = null;

  simpleColumns: SimpleColumn[] = [
    {
      name: 'chosen',
      danskName: 'Vælg',
      isVisible: true,
    },
    {
      name: 'Exported',
      danskName: 'Eksporteret',
      isVisible: true,
    },
    {
      name: 'modal',
      danskName: 'Vis alle data',
      isVisible: true,
    },
  ];
  simpleLoadUnloadColumns: SimpleColumn[] | null = null;
  standartVariableNames: string[] = ['Vis alle data'];

  eprods: string[] = [];
  @Output() tenantNoteChange = new EventEmitter<TenantNoteChangeDto>();
  @Output() takePhotos = new EventEmitter<TripEventFileUploadDto>();
  @Output() movePhoto = new EventEmitter<TripPhotoUrlDto>();
  @Output() filterOptionsChange = new EventEmitter<FilterOptions>();
  @Output() clientsEmmiter = new EventEmitter<ClientEventDto>();

  allTrips: TripLogDto[] | undefined = undefined;
  filteredTrips: TripLogDto[] | undefined = undefined;

  @Input() filterOptions: FilterOptions | undefined;

  @Input() set tripLogs(value: TripLogDto[] | undefined) {
    if (value) {
      this.allTrips = value;
      // this.onSort({
      //   column: this._state.sortColumn,
      //   direction: this._state.sortDirection,
      //   unloadPosition: '',
      // });
      this.setData();
    } else {
      this.allTrips = undefined;
      this.filteredTrips = undefined;
    }
  }

  allClients: DataSelectDto[] | null = null;

  @Input() set clients(value: DataSelectDto[] | undefined) {
    if (value) {
      this.allClients = value;
      this.clientOptions = this.dataSelectService.setDataSelectOptions(
        value.filter((x) => !x.isDeleted)
      );
      this.clientStaticOptions = this.clientOptions;
    }
  }

  allProducts: DataSelectDto[] | null = null;
  @Input() set products(value: DataSelectDto[] | undefined) {
    if (value) {
      this.allProducts = value;
      this.productsStaticOptions = this.dataSelectService.setDataSelectOptions(
        value.filter((x) => x.isActive && !x.isDeleted && x.sublabel)
      );
      this.productsOptions = this.productsStaticOptions;

      this.eproductOptions = this.dataSelectService.setDataSelectOptions(
        value.filter((x) => x.isActive && !x.isDeleted)
      );
    }
  }

  @Input() set drivers(value: UserSelectDto[] | undefined) {
    if (value) {
      this.driverOptions = this.driverOptions = value.map((x) => ({
        value: x.id,
        label: `${x.name} ${x.surname}`,
      }));
      this.driverStaticOptions = this.driverOptions;
    }
  }

  @Input() set tableColumns(value: Column[] | null) {
    if (value) {
      value.map((col) => {
        var name = col.name.replace(/\s/g, '').split(',')[0];
        if (
          !this.isAdminPage &&
          (name === 'Approved' || name === 'TenantNote')
        ) {
          return;
        }
        this.simpleColumns.push({
          name: name,
          danskName: col.danskName.split(',')[0],
          isVisible: col.isVisible,
        });
        if (name == 'ClientName') {
          this.simpleColumns.push(
            {
              name: 'Product',
              danskName: 'Produktet',
              isVisible: true,
            },
            {
              name: 'Address',
              danskName: 'Adresse',
              isVisible: true,
            }
          );
        }

        this.standartVariableNames.push(name);
      });
    }
  }

  @Input() set loadUnloadColumns(value: Column[] | null) {
    if (value) {
      this.simpleLoadUnloadColumns = [];
      value.map((x) =>
        this.simpleLoadUnloadColumns?.push({
          name:
            x.name.replace(/\s/g, '').split(',')?.length > 1
              ? x.name.replace(/\s/g, '').split(',')[1]
              : x.name.replace(/\s/g, '').split(',')[0],
          danskName:
            x.danskName.replace(/\s/g, '').split(',')?.length > 1
              ? x.danskName
                  .split(',')[1]
                  .replace('losning', '')
                  .replace('Losning', '')
                  .replace('aflosset', '')
                  .replace('fotos', 'Fotos')
              : x.danskName.split(',')[0],
          isVisible: x.isVisible,
        })
      );
    }
  }

  @Input() isAdminPage: boolean = true;
  @Input() updateInProgress: boolean = false;
  @Input() priceTypes: PriceType[] | null = null;

  @ViewChildren(NgbdSortableHeader) headers:
    | QueryList<NgbdSortableHeader>
    | undefined;

  constructor(
    private dataSelectService: DataSelectService,
    public router: Router,
    public service: TripsService,
    public orderService: OrdersService,
    private workEventService: WorkDayEventsService,
    public tempService: TempService
  ) {
    this.getState();
  }

  getState() {
    this._state.page = Number(localStorage.getItem('page')) || 1;
    this._state.sortColumn =
      localStorage.getItem('sortColumn')?.toString() || '';
    this._state.sortDirection =
      (localStorage.getItem('sortDirection')?.toString() as SortDirection) ||
      '';
  }

  setState() {
    localStorage.setItem('page', this._state.toString());
    localStorage.setItem('sortColumn', this._state.sortColumn);
    localStorage.setItem('sortDirection', this._state.sortDirection ?? '');
  }

  filterChange() {
    this.filterOptionsChange.emit(this.filterOptions);
  }

  setData() {
    if (this.allTrips) {
      this.allTrips = sort(
        this.allTrips,
        this._state.sortColumn,
        this._state.sortDirection
      );

      this.setPageData();
      if (this.filterOptions) {
        this.filterOptions.order = this._state.sortDirection ?? '';
        this.filterOptions.orderColumn = this._state.sortColumn ?? '';
      }
    }
  }

  setPageData() {
    if (this.allTrips) {
      let total = this.allTrips.length;
      let numberOfPages = Math.floor(total / this._state.pageSize);
      if (total % this._state.pageSize !== 0) {
        numberOfPages = numberOfPages + 1;
      }
      this.pages = Array.from(
        { length: numberOfPages ? numberOfPages : 1 },
        (_, i) => i + 1
      );

      if (!this.pages.includes(this._state.page)) {
        this._state.page = this.pages.length;
      }
      this.filteredTrips = this.allTrips?.slice(
        (this._state.page - 1) * this._state.pageSize,
        (this._state.page - 1) * this._state.pageSize + this._state.pageSize
      );
    }
    this.setDriverSelectOptions();
    this.setProductSelectOptions();
    this.setClientSelectOptions();
  }

  setDriverSelectOptions() {
    if (this.allTrips) {
      const currDrivers = this.allTrips.map((trip) => trip.driverId);
      const uniqueDriverIds = [...new Set(currDrivers)];

      this.driverOptions =
        this.driverStaticOptions?.filter((option) =>
          uniqueDriverIds.includes(option.value)
        ) ?? null;
    } else {
      this.driverOptions = this.driverStaticOptions;
    }
  }

  setProductSelectOptions() {
    if (this.allTrips) {
      const currProductIds = this.allTrips.flatMap((trip) => trip.productIds);
      const uniqueProductIds = [...new Set(currProductIds)];
      this.productsOptions =
        this.productsStaticOptions?.filter((option) =>
          uniqueProductIds.includes(option.value)
        ) ?? null;
    } else {
      this.productsOptions = this.productsStaticOptions;
    }
  }

  setClientSelectOptions() {
    if (this.allTrips) {
      const currClientIds = this.allTrips.flatMap((trip) => trip.clientIds);
      const uniqueClientIds = [...new Set(currClientIds)];
      this.clientOptions =
        this.clientStaticOptions?.filter((option) =>
          uniqueClientIds.includes(option.value)
        ) ?? null;
    } else {
      this.clientOptions = this.clientStaticOptions;
    }
  }

  setHeaders() {
    this.headers?.forEach((header) => {
      if (header.sortable !== this._state.sortColumn) {
        header.direction = '';
      } else {
        header.direction = this._state.sortDirection ?? '';
      }
    });
  }

  onSort({ column, direction }: SortEvent) {
    this._state.sortColumn = column;
    this._state.sortDirection = direction;

    this.headers?.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.setState();
    this.setData();
  }

  ngOnInit() {}

  updateValue(value: string[]) {
    this.eprods = value;
  }

  selectedTripLog: TripLogDto | undefined = undefined;
  noteEditing: boolean = false;
  driverChanging: boolean = false;
  sortModalActive: boolean = false;
  photoUrls: TripPhotoUrlDto[] | undefined = undefined;
  sliderType: 'Weigh' | 'Usual' = 'Usual';
  officeNoteModalActive: boolean = false;
  addressModalActive: boolean = false;
  clientModalActive: boolean = false;
  tripClients: TripEventClientDto[] | undefined = undefined;

  cancelEvent() {
    this.selectedTripLog = undefined;
    this.noteEditing = false;
    this.officeNoteModalActive = false;
    this.driverChanging = false;
    this.sortModalActive = false;
    this.photoUrls = undefined;
    this.sliderType = 'Usual';
    this.photoUrls = undefined;
    this.addressModalActive = false;
    this.clientModalActive = false;
    this.tripClients = undefined;
  }
  getUrls(id: string, type: TripEventType) {
    this.service.getPhotos(id, type).subscribe((x) => {
      this.photoUrls = x;
      if (type == 'Weighing') {
        this.sliderType = 'Weigh';
      } else {
        this.sliderType = 'Usual';
      }
    });
  }

  getDataToSortModal(tripLog: TripLogDto) {
    this.selectedTripLog = tripLog;
    this.sortModalActive = true;
  }

  changeApprove(tripLog: TripLogDto) {
    let tripFromAll = this.allTrips?.find((x) => x.id === tripLog.id);
    if (tripFromAll) {
      tripFromAll.isApproved = !tripFromAll.isApproved;
    }

    this.service.updateApproveStatus(tripLog.id).subscribe();
  }

  movePhotos(photo: TripPhotoUrlDto) {
    this.movePhoto.emit(photo);
  }

  openTenantComment(tripLog: TripLogDto) {
    this.noteEditing = true;
    this.selectedTripLog = tripLog;
  }

  addTenantNote($event?: string) {
    if (this.selectedTripLog) {
      this.tenantNoteChange.emit({
        id: this.selectedTripLog.id,
        tenantNote: $event,
      });
      this.updateNotesInArray(
        this.allTrips,
        this.selectedTripLog.id,
        $event,
        this.selectedTripLog.officeNote
      );
      this.updateNotesInArray(
        this.filteredTrips,
        this.selectedTripLog.id,
        $event,
        this.selectedTripLog.officeNote
      );
    }
    this.cancelEvent();
  }

  updateNotesInArray(
    tripLogs?: TripLogDto[],
    id?: string,
    tenantNote: string = '',
    officeNote: string = ''
  ) {
    let tripLog = tripLogs?.find((x) => x.id === id);
    if (tripLog) {
      tripLog.tenantNote = tenantNote;
      tripLog.officeNote = officeNote;
    }
  }

  openOfficeNote(tripLog: TripLogDto) {
    this.selectedTripLog = tripLog;
    this.officeNoteModalActive = true;
  }

  updateOfficeNote(note?: string) {
    if (this.selectedTripLog) {
      this.service.updateOfficeNote(this.selectedTripLog.id, note).subscribe();
      this.updateNotesInArray(
        this.allTrips,
        this.selectedTripLog.id,
        this.selectedTripLog.tenantNote,
        note
      );
      this.updateNotesInArray(
        this.filteredTrips,
        this.selectedTripLog.id,
        this.selectedTripLog.tenantNote,
        note
      );
    }
    this.cancelEvent();
  }

  openAdressPopUp(tripLog: TripLogDto) {
    this.selectedTripLog = tripLog;
    this.addressModalActive = true;
  }

  updateAdress(dto: AdressChangeDto) {
    if (this.selectedTripLog) {
      this.service
        .updateOfficeAddress(
          this.selectedTripLog?.id,
          dto.pickUpAddress,
          dto.deliveryAddress
        )
        .subscribe((x) => {
          this.filterChange();
        });
      this.cancelEvent();
    }
  }

  getEventClients(tripLog: TripLogDto) {
    this.clientModalActive = true;
    this.selectedTripLog = tripLog;
    this.service.getEventClients(tripLog.id).subscribe((x) => {
      this.tripClients = x;
    });
  }

  updateClients(result: ClientEventInfoDto[]) {
    if (this.selectedTripLog) {
      let dto = {
        id: this.selectedTripLog.id,
        clientEventInfoDtos: result,
      } as ClientEventDto;
      this.clientsEmmiter.emit(dto);
    }

    this.cancelEvent();
  }

  ngAfterContentChecked() {
    this.setHeaders();
    this.filteredTrips?.forEach((x, y) => {
      let row = document.getElementById(`ordering${y}`);

      this.simpleColumns?.forEach((a, b) => {
        let sortedColumnName = `${a.name}${y}`;
        let unsortedColumnName = row?.children.item(b)?.id;
        if (row) {
          let sortedColumn = row.querySelector(`#${sortedColumnName}`);
          let unsortedColumn = row.querySelector(`#${unsortedColumnName}`);
          if (sortedColumn) {
            row.insertBefore(sortedColumn, unsortedColumn);
          }
        }
        if (!a.isVisible) {
          let toRemove = `${a.name}${y}`;
          let toRemoveColumn = row?.querySelector(`#${toRemove}`);
          if (row && toRemoveColumn) {
            row.removeChild(toRemoveColumn);
          }
        }
      });
    });
    this.allSelected = this.checkAll();
  }

  submitErrors: string[] | null = null;

  bulkExport(union: boolean = false) {
    if (this.tripsEconomicExport.length > 0) {
      this.service.eexport(this.tripsEconomicExport, union).subscribe({
        next: (value) => {
          if (value && value.find((x) => x.errorMessage)) {
            this.submitErrors = value.map((a) => a.errorMessage);
          }
        },
        complete: () => {
          this.filterChange();
        },
        error: (data) => (this.submitErrors = data),
      });
      this.tripsEconomicExport = [];
    }
  }

  bulkUpdate() {
    if (this.tripsEconomicExport.length > 0) {
      let obj = {
        tripIds: this.tripsEconomicExport,
        productIds: this.eprods,
      } as BulkEProduct;
      this.service.bulkUpdate(obj).subscribe((x) => {
        this.filterChange();
      });
    }
    this.tripsEconomicExport = [];
  }

  tripsEconomicExport: string[] = [];

  checkIfChosen(tripId: string) {
    return this.tripsEconomicExport.find((x) => x === tripId);
  }

  checkIfReadyToExport(tripId: string) {
    return this.tripsEconomicExport.find((x) => x === tripId);
  }

  unexportCandidate: TripLogDto | undefined = undefined;

  handleUnexportClick(id: string) {
    this.service.unexport(id).subscribe((trip) => {
      let logFromAll = this.allTrips?.find((x) => x.id == id);
      if (logFromAll) {
        logFromAll.exportedAt = undefined;
      }
      let logFromFiltered = this.filteredTrips?.find((x) => x.id == id);
      if (logFromFiltered) {
        logFromFiltered.exportedAt = undefined;
      }
    });
  }

  checkAll() {
    let check = true;
    this.filteredTrips?.forEach((x) => {
      if (!x.exportedAt && !this.checkIfChosen(x.id)) {
        check = false;
      }
    });
    return check;
  }
  allSelected: boolean = false;
  changeAll() {
    this.allSelected = !this.allSelected;
    this.filteredTrips?.forEach((x) => {
      if (!x?.exportedAt) {
        this.changeAllChosen(x.id, this.allSelected);
      }
    });
  }

  changeChosen(tripId: string, event: any) {
    let index = this.tripsEconomicExport.findIndex((x) => x === tripId);
    if (index != -1) {
      this.tripsEconomicExport.splice(index, 1);
    } else if (event.target.checked) {
      this.tripsEconomicExport.push(tripId);
    }
  }

  changeAllChosen(tripId: string, all: boolean = false) {
    let index = this.tripsEconomicExport.findIndex((x) => x === tripId);
    if (index != -1 && !all) {
      this.tripsEconomicExport.splice(index, 1);
    } else if (all && !this.checkIfChosen(tripId)) {
      this.tripsEconomicExport.push(tripId);
    }
  }

  convertMS(duration: number): string {
    let d, h, m, s;
    s = Math.floor(duration / 1000);
    m = Math.floor(s / 60);
    s = s % 60;
    h = Math.floor(m / 60);
    m = m % 60;
    d = Math.floor(h / 24);
    h = h % 24;
    h += d * 24;
    return `${h}h. ${m}m. ${s}s. `;
  }

  //need Refactor temporary solution
  isWorkEventsLoaded: boolean = false;
  trip: TripDto | null = null;
  workEvents: WorkdayEvent[] | null = null;
  @Output() economicEmmiter = new EventEmitter<EconomicInfoExtended>();

  openProductGroupModal(tripLog: TripLogDto) {
    this.workEventService.loadForTrip(tripLog.id).subscribe((x) => {
      this.workEvents = x;
      this.isWorkEventsLoaded = true;
    });
    this.loadTrip(tripLog.id);
  }

  loadTrip(id: string) {
    this.service.loadOneTemp(id).subscribe((x) => {
      this.trip = this.tempService.InitializeTripDto(x);
    });
  }

  updateGroups($event: EconomicInfoExtended) {
    this.economicEmmiter.emit($event);
    this.closeGroupModal();
  }

  closeGroupModal() {
    this.isWorkEventsLoaded = false;
    this.trip = null;
    this.workEvents = null;
  }
}
