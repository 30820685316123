import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Accessory } from 'src/app/state/accessories.repository';

@Component({
  selector: 'app-accessory-list',
  templateUrl: './accessory-list.component.html',
  styleUrls: ['./accessory-list.component.scss']
})
export class AccessoryListComponent implements OnInit {
  @Input() accessories: Accessory[] | null = null;
  @Output() delete = new EventEmitter<string>();
  deleteConfirmation: Accessory | null = null;

  constructor() { }

  ngOnInit(): void {
  }

}
