<div class="mb-3">
  <label for="formFile" class="form-label" i18n="uploadCommentImage"
    >Upload comment image</label
  >

  <input
    class="form-control"
    type="file"
    #fileInput
    id="fileInput"
    name="fileInput"
    accept="{{ fileType }}"
    (change)="selectFile($event)"
  />
</div>

<app-big-spinner *ngIf="uploadInProgress"></app-big-spinner>
