<h2 class="mb-4" *ngIf="id === 'new'" i18n="Create vehicle label">Create vehicle</h2>
<h2 class="mb-4" *ngIf="id !== 'new'" i18n="Edit vehicle label">Edit vehicle</h2>
<div *ngIf="(id === 'new') || (vehicle$ | async)" class="row mb-4">
    <div class="col-12">
        <app-vehicle-form [editedVehicle]="vehicle$ | async"
          (vehicleSubmit)="createVehicle($event)"></app-vehicle-form>
        <app-error-alert title="Could not submit the vehicle info" i18n-title="Could not submit vehicle error message"
          [errors]="submitErrors"></app-error-alert>          
    </div>
</div>
