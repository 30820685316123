<form
  class="mt-3 row me-1 d-flex justify-content-center"
  *ngIf="form"
  [formGroup]="form"
  enctype="multipart/form-data"
>
  <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 mb-2">
    <div class="form-group form-floating">
      <input
        formControlName="date_from"
        (change)="sendFilters()"
        type="date"
        class="form-control"
        placeholder="From"
        i18n-placeholder="Date_from placeholder @@date_from"
      />
      <label for="date_from" i18n="@@date_from">From</label>
    </div>
  </div>
  <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 mb-2">
    <div class="form-group form-floating">
      <input
        formControlName="date_to"
        (change)="sendFilters()"
        type="date"
        class="form-control"
        placeholder="To"
        i18n-placeholder="Date_to placeholder @@date_to"
      />
      <label for="date_to" i18n="@@date_to">To</label>
    </div>
  </div>
  <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 mb-2">
    <div class="form-group form-floating">
      <input
        id="order_filter_search"
        formControlName="google_search"
        (input)="sendFilters()"
        type="text"
        class="form-control"
        placeholder="Search"
        i18n-placeholder="Search placeholder @@google_search"
      />
      <label for="google_search" i18n="@@google_search">Search</label>
    </div>
  </div>

  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 mb-2">
    <app-spinner *ngIf="isLoading"></app-spinner>
    <div *ngIf="!isLoading" class="form-group form-floating">
      <app-datalist-select
        [class.app-datalist-select-double-height]="
          form.value.driverIds && form.value.driverIds.length > 1
        "
        id="driverName"
        [options]="driverOptions"
        [multiple]="true"
        [optionsDeleted]="usersDeletedOptions"
        [(activeValue)]="form.value.driverIds"
        (activeValueChange)="updateValue('driverIds', $event); sendFilters()"
        label="Driver name"
        i18n-label="Label Driver name"
      ></app-datalist-select>
    </div>
  </div>
</form>
