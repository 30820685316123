import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommentPhotoDto, Order } from 'src/app/state/orders.repository';
import { OrdersService } from 'src/app/state/orders.service';
import { TripEvent, TripPhoto } from 'src/app/state/trips.repository';

@Component({
  selector: 'app-notes-info-modal',
  templateUrl: './notes-info-modal.component.html',
  styleUrls: ['./notes-info-modal.component.scss'],
})
export class NotesInfoModalComponent {
  @Output() closeModal = new EventEmitter<void>();
  @Input() order: Order | null = null;
  @Input() tripEvents: TripEvent[] | undefined;
  filteredTripEvents: TripEvent[] | undefined;
  openedEvents: string[] = [];
  images: string[] = [];
  imageIndex: number = 0;
  moveEnabled: boolean = false;
  constructor(public orderService: OrdersService) {}

  sortTEvents() {
    this.filteredTripEvents = this.tripEvents?.sort(
      (objA, objB) => Number(objA.createdAt) - Number(objB.createdAt)
    );
  }

  ngOnInit() {
    this.sortTEvents();
  }
  close() {
    this.closeModal.emit();
  }

  manageOpenedEvents(id: string) {
    var index = this.openedEvents.indexOf(id);
    if (index > -1) {
      this.openedEvents.splice(index, 1);
    } else {
      this.openedEvents.push(id);
    }
  }

  check(id: string) {
    return this.openedEvents.indexOf(id) <= -1;
  }

  getDanishEventType(type: string): string {
    switch (type) {
      case 'TripStart': {
        return 'Start';
      }
      case 'TripEnd': {
        return 'Slut';
      }
      case 'Load': {
        return 'Laste';
      }
      case 'Unload': {
        return 'Losse';
      }
      case 'Weighing': {
        return 'Vejning';
      }
      default: {
        return type;
      }
    }
  }

  openImageModal(index: number) {
    if (this.order?.commentPhotos) {
      this.images = this.order?.commentPhotos?.map((x) => x.imagePath);
      this.imageIndex = index;
      this.moveEnabled = true;
    }
  }

  openImageModalTripEvents(photos: TripPhoto[], index: number) {
    this.images = photos.map((x) => x.url);
    this.imageIndex = index;
  }

  closeImageSlider() {
    this.images = [];
    this.imageIndex = 0;
    this.moveEnabled = false;
  }

  moveToStart(path: string) {
    var commentPhoto = this.order?.commentPhotos?.find(
      (x) => x.imagePath == path
    );
    if (commentPhoto) {
      this.orderService.moveImageToStart(commentPhoto).subscribe((x) => {
        this.tripEvents = x.tripEvents;
        this.sortTEvents();
      });
    }
  }

  moveToWeight(path: string) {
    var commentPhoto = this.order?.commentPhotos?.find(
      (x) => x.imagePath == path
    );
    if (commentPhoto) {
      this.orderService
        .moveImageToWeight(commentPhoto, this.order?.clientId)
        .subscribe((x) => {
          this.tripEvents = x.tripEvents;
          this.sortTEvents();
        });
    }
  }
}
