<div class="row mb-4">
  <div class="col">
    <h2
      *ngIf="!isParkedOverview"
      i18n="Title for the list of company's drivers"
    >
      Drivers logs(Trips)
    </h2>
    <h2 *ngIf="isParkedOverview" i18n="Title for the time logs overview parked">
      Driver logs(Trips log) - Parked
    </h2>
  </div>
</div>
<div class="row mb-4"></div>
<div class="row mb-4">
  <div class="col">
    <app-sort-table1
      *ngIf="checkIfDataLoaded(); else loadingContent"
      [products]="productSelectOptions"
      [clients]="clientSelectOptions"
      [drivers]="driverSelectOptions"
      [tableColumns]="tableColumns"
      [loadUnloadColumns]="loadUnloadColumns"
      [tripLogs]="tripLogs"
      [filterOptions]="filterOptions"
      (takePhotos)="addPhotos($event)"
      (movePhoto)="movePhoto($event)"
      (filterOptionsChange)="requestLoadData($event)"
      (tenantNoteChange)="updateTenantNote($event)"
    ></app-sort-table1>
    <ng-template #loadingContent>
      <app-spinner [showWithText]="true"></app-spinner>
    </ng-template>
  </div>
</div>
