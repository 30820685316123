<h2 class="mb-4" *ngIf="id === 'new'" i18n="Create user label">Create user</h2>
<h2 class="mb-4" *ngIf="id !== 'new'" i18n="Edit user label">Edit user</h2>
<div *ngIf="(id === 'new') || (user$ | async)">
  <app-user-basic-info-form [initialValue]="user$ | async"
                            [tenants]="tenantRepo.tenants$ | async"
                            (userSubmit)="onInfoSubmit($event)">
  </app-user-basic-info-form>
  <app-error-alert title="Could not submit the user's profile" i18n-title="User profile submit error alert"
    [errors]="submitErrors"></app-error-alert>
</div>
