import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Input() page: number = 1;
  @Input() set total(length: number) {
    this.pages = Array.from({ length }, (_, i) => i + 1);
  };
  @Output() pageChange = new EventEmitter<number>();
  
  pages: number[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
