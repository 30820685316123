import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ScheduledForRetryError } from '../modules/core/interceptors/retry.interceptor';
import { DayjsService } from '../modules/shared/services/dayjs.service';
import { buildIdPromise, RequestsRepository } from './requests.repository';
import { WorkdayEventsRepository } from './workdayevents.repository';
import { WorkDayEventsService } from './workdayevents.service';
import {
  FilterOptions,
  skipWhileWorkdaysCached,
  trackWorkdaysRequestsStatus,
  Workday,
  WorkdayEvent,
  WorkdaysDto,
  WorkdaysRepository,
} from './workdays.repository';
import {
  Coordinates,
  LocationService,
} from '../modules/shared/services/location.service';

const API = '/api/workdays';

@Injectable({
  providedIn: 'root',
})
export class WorkdaysService {
  constructor(
    private http: HttpClient,
    private repo: WorkdaysRepository,
    private eventRepo: WorkdayEventsRepository,
    private eventService: WorkDayEventsService,
    private requestsRepo: RequestsRepository,
    private locationService: LocationService,
    public ngDay: DayjsService
  ) {}

  loadOverview() {
    return this.http.get<Workday[]>(`/api/usersSummary/workdaysoverview`).pipe(
      tap((res) => this.repo.setWorkdays(res)),
      trackWorkdaysRequestsStatus(this.repo.name)
      //skipWhileWorkdaysCached(this.repo.name)
    );
  }

  load() {
    return this.http.get<Workday[]>(API).pipe(
      tap((res) => this.repo.setWorkdays(res)),
      trackWorkdaysRequestsStatus(this.repo.name),
      skipWhileWorkdaysCached(this.repo.name)
    );
  }

  loadCurrent() {
    return this.http.get<Workday>(`${API}/current`).pipe(
      tap((day) => {
        if (day) {
          this.repo.upsertWorkday(day, `${this.repo.name}_current`);
          this.repo.setActiveId(day.id);
        }
      }),
      trackWorkdaysRequestsStatus(`${this.repo.name}_current`),
      skipWhileWorkdaysCached(`${this.repo.name}_current`)
    );
  }
  loadOneById(id: string) {
    return this.http.get<Workday>(`${API}/${id}`).pipe(
      tap((trip) => {
        if (trip) {
          this.repo.upsertWorkday(trip);
        }
      }),
      trackWorkdaysRequestsStatus(id)
    );
  }

  loadOne() {
    return this.http.get<Workday>(API).pipe(
      tap((day) => {
        if (day) {
          this.repo.upsertWorkday(day, this.repo.name);
          this.repo.setActiveId(day.id);
        }
      }),
      trackWorkdaysRequestsStatus(this.repo.name),
      skipWhileWorkdaysCached(this.repo.name)
    );
  }

  async start() {
    let eventLocation: string | undefined;
    const getLocationAsync = await this.locationService
      .getPosition()
      .catch((err) => (eventLocation = err.message));
    if (getLocationAsync.latitude) {
      eventLocation = `${getLocationAsync.latitude}, ${getLocationAsync.longitude}`;
    }
    const event: Partial<WorkdayEvent> = {
      type: 'DayStart',
      eventTime: this.ngDay.dayjs.utc().toDate(),
      eventLocation,
    };
    return this.http.post<Workday>(API, {}).pipe(
      switchMap((day) =>
        this.http.post<Workday>(`${API}/${day.id}/events`, event)
      ),
      this.processOfflineSubmit(event),
      tap((day) => {
        this.repo.upsertWorkday(day, `${this.repo.name}_add`);
        this.repo.setActiveId(day.id);
        this.eventRepo.clearPages();
      }),
      trackWorkdaysRequestsStatus(`${this.repo.name}_add`)
    );
  }

  stop(day: Workday) {
    const event: Partial<WorkdayEvent> = {
      type: 'DayEnd',
      eventTime: this.ngDay.dayjs.utc().toDate(),
    };
    return this.http.post<Workday>(`${API}/${day.id}/events`, event).pipe(
      this.processOfflineSubmit(event),
      tap((day) => {
        this.repo.upsertWorkday(day);
        this.repo.setActiveId(null);
        this.eventRepo.clearPages();
      }),
      trackWorkdaysRequestsStatus(day.id)
    );
  }

  pause(day: Workday, duration: WorkdayEvent['duration'], tripId?: string) {
    const event: Partial<WorkdayEvent> = {
      type: 'Pause',
      duration,
      eventTime: this.ngDay.dayjs.utc().toDate(),
      tripId: tripId,
    };
    return this.http.post<Workday>(`${API}/${day.id}/events`, event).pipe(
      this.processOfflineSubmit(event),
      tap((day) => {
        this.repo.upsertWorkday(day);
        this.eventRepo.clearPages();
      }),
      trackWorkdaysRequestsStatus(day.id)
    );
  }

  addPause(event: Partial<WorkdayEvent>) {
    return this.http
      .put<Workday>(`${API}/${event.workDayId}/events`, event)
      .pipe(
        this.processOfflineSubmit(event),
        tap((day) => {
          this.repo.upsertWorkday(day);
          this.eventRepo.clearPages();
        })
      );
  }

  updatePause(day: Workday, pause: WorkdayEvent) {
    return this.eventService.update(pause.id, pause).pipe(
      map((res) => {
        const index = day.workdayEvents.findIndex((x) => x.id === res.id);
        if (index >= 0) {
          day.workdayEvents[index] = res;
        }
        return day;
      }),
      this.processOfflineSubmit(pause),
      tap((day) => {
        this.repo.upsertWorkday(day);
        this.eventRepo.clearPages();
      }),
      trackWorkdaysRequestsStatus(day.id)
    );
  }

  updateApprove(entity: WorkdaysDto) {
    return this.http
      .patch<Workday>(
        `${API}/${entity.workdayId}/isApproved/${entity.isApprove}`,
        { isApproved: entity.isApprove }
      )
      .pipe(
        tap((trip) => this.repo.upsertWorkday(trip)),
        trackWorkdaysRequestsStatus(entity.workdayId!)
      );
  }

  export(): Observable<string> {
    return this.http
      .get<Blob>(`${API}/csv`, { responseType: 'blob' as any })
      .pipe(
        map((resp) => {
          const blob = new Blob([resp], { type: resp.type });
          return window.URL.createObjectURL(blob);
        })
      );
  }

  featuredExport(options?: FilterOptions): Observable<string> {
    return this.http
      .post<Blob>(`${API}/csv/options`, options, {
        responseType: 'blob' as any,
      })
      .pipe(
        map((resp) => {
          const blob = new Blob([resp], { type: resp.type });
          return window.URL.createObjectURL(blob);
        })
      );
  }

  private processOfflineSubmit(
    event: Partial<WorkdayEvent>
  ): OperatorFunction<Workday, Workday> {
    return catchError((err) => {
      if (err instanceof ScheduledForRetryError) {
        if (
          err.request.method === 'PATCH' &&
          err.request.body.type === 'Pause'
        ) {
          const eventIdFromUrl = err.request.url.split('/').pop();

          if (eventIdFromUrl) {
            const day = this.repo.getWorkdayByEvent(eventIdFromUrl);
            if (day) {
              const index = day.workdayEvents.findIndex(
                (x) => x.id === eventIdFromUrl
              );
              if (index >= 0) {
                day.workdayEvents[index] = {
                  ...day.workdayEvents[index],
                  ...event,
                };
              }
              return of(day);
            }
          }
          throw err;
        }
        if (err.request.url.endsWith(API)) {
          // day POST request failed
          // create additional request for the event
          const eventRequest = this.requestsRepo.add({
            body: { ...event, eventTime: this.ngDay.dayjs.utc().toDate() },
            url: `${API}/${buildIdPromise(err.request.id)}/events`,
            method: 'POST',
            userId: err.request.userId,
            createdAt: new Date(),
            responseIdPath: 'workdayEvents.0.id',
          });
          return of<Workday>({
            id: buildIdPromise(err.request.id),
            createdAt: err.request.createdAt,
            workdayEvents: [
              {
                ...eventRequest.body,
                id: buildIdPromise(eventRequest.id),
              },
            ],
          });
        }
        // day was created, but event has failed
        // pull day id from the failed request's URL
        const dayIdFromUrl = err.request.url.split('/').slice(-2)[0];
        const dayState = this.repo.getWorkday(dayIdFromUrl);
        if (dayState) {
          this.requestsRepo.update(err.request.id, {
            responseIdPath: `workdayEvents.${dayState.workdayEvents.length}.id`,
          });
          dayState.workdayEvents.push({
            ...err.request.body,
            id: buildIdPromise(err.request.id),
          });
          return of(dayState);
        }
      }
      throw err;
    });
  }
}
