import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './abstract/base.service';
import { Trailer, TrailersRepository } from './trailers.repository';

const API = '/api/trailers';

@Injectable({
  providedIn: 'root'
})
export class TrailersService extends BaseService<Trailer> {

  constructor(
    http: HttpClient,
    repo: TrailersRepository
  ) {
    super(API, http, repo);
  }

}
