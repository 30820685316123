<div
  class="modal fade in"
  bsModal
  [config]="{ show: true }"
  *ngIf="photoUrls && photoUrls.length > 0"
  id="photoModal"
  tabindex="-1"
  aria-labelledby="photoModal"
  aria-hidden="false"
  (onHidden)="cancel.emit()"
>
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content bg-lights border-0">
      <div class="modal-header">
        <h6
          class="modal-title"
          id="photoModalLabel"
          i18n="Title for gallery window"
        >
          Trip Photos {{ slideIndex + 1 }}/{{ photoUrls.length }}
        </h6>
        <button
          type="button"
          class="btn-close"
          (click)="cancel.emit()"
          aria-label="Close"
          i18n-aria-label="Close label @@close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="form-floating mb-3 hfc">
          <div class="prev" (click)="changeSlide(-1)">&#10094;</div>
          <div class="next" (click)="changeSlide(1)">&#10095;</div>
          <img class="img-slide" [src]="photoUrls[slideIndex].url" />

          <button
            *ngIf="type === 'Weigh'"
            (click)="movePhoto.emit(photoUrls[slideIndex]); change()"
            class="btn btn-primary mt-2"
          >
            <span i18n="@@moveToStart">Move To Start</span>
          </button>
          <button
            *ngIf="type === 'Usual'"
            (click)="movePhoto.emit(photoUrls[slideIndex]); change()"
            class="btn btn-primary mt-2"
          >
            <span i18n="@@moveToWeight">Move To Weighing</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
