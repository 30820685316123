import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Accessory } from 'src/app/state/accessories.repository';

@Component({
  selector: 'app-accessory-form',
  templateUrl: './accessory-form.component.html',
  styleUrls: ['./accessory-form.component.scss']
})
export class AccessoryFormComponent implements OnInit {

  @Input() editedAccessory: Accessory | null = null;
  form: UntypedFormGroup | null = null; 

  @Output() accessorySubmit = new EventEmitter<Partial<Accessory>>();

  constructor(private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [this.editedAccessory?.name, Validators.required],   
      description: [this.editedAccessory?.description]   
    });
  }

  onSubmit() {
      if (this.form && !this.form.valid) {
        this.form.markAllAsTouched();
        return;
      }
    let name = this.form?.value?.name.toString();
    let description = this.form?.value?.description?.toString();

    this.accessorySubmit.emit({ name, description });
  }

}
