<form
  id="form"
  *ngIf="form"
  [formGroup]="form"
  [class.isDisabled]="
    orderUpdate && orderUpdate.tripEvents && orderUpdate.tripEvents.length > 0
  "
  enctype="multipart/form-data"
>
  <div
    [appValidateState]="form.get('clientId')"
    class="form-group form-floating mb-3"
  >
    <app-datalist-select
      [isCompanies]="true"
      [isExported]="orderUpdate?.isExported"
      [options]="clientOptions"
      [optionsDeleted]="clientOptionsDeleted"
      [activeValue]="form.value.clientId"
      (activeValueChange)="updateValue('clientId', $event)"
      label="Client"
      i18n-label="orderClient label"
    ></app-datalist-select>
  </div>
  <div
    [appValidateState]="form.get('weight')"
    class="form-group form-floating mb-3"
  >
    <input
      required="required"
      formControlName="weight"
      type="text"
      (keydown)="validateInput($event)"
      class="form-control"
    />
    <label for="weight" i18n="orderWeight label"
      >Weight, kg/m<sup>3</sup></label
    >
  </div>
  <div class="form-group form-floating mb-3">
    <app-datalist-select
      class="app-datalist-select-double-height"
      [isExported]="orderUpdate?.isExported"
      [options]="productOptions"
      [multiple]="true"
      [activeValue]="form.value.productIds"
      [optionsDeleted]="productOptionsDeleted"
      (activeValueChange)="updateValue('productIds', $event)"
      label="Products"
      i18n-label="orderProducts label"
    ></app-datalist-select>
  </div>
  <div class="form-group form-floating mb-3">
    <input formControlName="reference" type="text" class="form-control" />
    <label for="km" i18n="orderReference label">Reference</label>
  </div>
  <div class="form-group form-floating mb-3">
    <input formControlName="comment" type="text" class="form-control" />
    <label for="km" i18n="orderComment label">Comment</label>
  </div>

  <div class="form-group form-floating mb-3">
    <app-datalist-select
      [isExported]="orderUpdate?.isExported"
      [options]="vendorOptions"
      [optionsDeleted]="vendorsOptionsDeleted"
      [activeValue]="form.value.vendorId"
      (activeValueChange)="updateValue('vendorId', $event)"
      label="Vendor"
      i18n-label="orderVendor label"
    ></app-datalist-select>
  </div>
  <div class="form-group form-floating mb-3" *ngIf="locationCheck()">
    <input
      ngx-google-places-autocomplete
      [options]="myOptions"
      #placePickRef="ngx-places"
      (onAddressChange)="handleAddressChange($event, 'pickUpAddress')"
      formControlName="pickUpAddress"
      class="form-control"
    />
    <label for="pickUpAddress" i18n="orderPickUpAddress label"
      >Pick Up Address</label
    >
  </div>
  <div class="form-group form-floating mb-3" *ngIf="locationCheck()">
    <input
      ngx-google-places-autocomplete
      [options]="myOptions"
      #placeDelRef="ngx-places"
      (onAddressChange)="handleAddressChange($event, 'deliveryAddress')"
      formControlName="deliveryAddress"
      class="form-control"
    />
    <label for="deliveryAddress" i18n="orderDeliveryAddress label"
      >Delivery Address</label
    >
  </div>
  <div class="form-group form-floating mb-3">
    <app-datalist-select
      [isExported]="orderUpdate?.isExported"
      [optionsDeleted]="accessoriesOptionsDeleted"
      [options]="accessoryOptions"
      [activeValue]="form.value.accessoryId"
      (activeValueChange)="updateValue('accessoryId', $event)"
      label="Accessory"
      i18n-label="orderAccessory label"
    ></app-datalist-select>
  </div>
  <div class="form-group form-floating mb-3">
    <app-datalist-select
      [isExported]="orderUpdate?.isExported"
      [optionsDeleted]="cleaningOptionsDeleted"
      [options]="cleaningOptions"
      [activeValue]="form.value.cleaningId"
      (activeValueChange)="updateValue('cleaningId', $event)"
      label="Rengøring"
      i18n="@formOrderCleaning label"
    ></app-datalist-select>
  </div>
  <div class="form-group form-floating mb-3">
    <input formControlName="contactPerson" type="text" class="form-control" />
    <label for="contactPerson" i18n="orderContact person label"
      >Contact person</label
    >
  </div>
  <div class="mb-3">
    <div class="form-group form-floating">
      <app-datalist-select
        [isExported]="orderUpdate?.isExported"
        class="app-datalist-select-double-height"
        id="driverIds"
        [multiple]="true"
        [optionsDeleted]="driverOptionsDeleted"
        [options]="driverOptions"
        [isDisabled]="
          (orderUpdate?.parentId !== null &&
            orderUpdate?.parentId !== undefined) ||
          DriversDisabled
        "
        [(activeValue)]="form.value.driverIds"
        (activeValueChange)="updateValue('driverIds', $event)"
        label="Select Drivers"
        i18n-label="Label orderDriver Id"
      ></app-datalist-select>
    </div>
  </div>

  <div *ngIf="!orderUpdate?.parentId && !parentIdS && !recurrence">
    <div
      [appValidateState]="form.get('plannedToStartAtDate')"
      class="form-group form-floating mb-3"
      style="position: relative"
    >
      <input
        (change)="startDateChange()"
        type="date"
        class="form-control"
        (change)="onDateChange()"
        formControlName="plannedToStartAtDate"
        placeholder="Start Date"
        i18n-placeholder="plannedDateStart placeholder @@plannedDateStart"
      />
      <label for="plannedToStartAtDate" i18n="@@orderPlannedDateStart"
        >Planned to Start Date</label
      >
    </div>
    <div
      [appValidateState]="form.get('plannedToStartAtTime')"
      class="form-group form-floating mb-3"
      style="position: relative"
    >
      <!-- <input type="time" id="startTime" class="form-control" formControlName="plannedToStartAtTime" 
            placeholder="Start Time" i18n-placeholder="plannedTimeStart placeholder @@plannedTimeStart"/>
        <label for="orderPlannedTimeStart" i18n="@@orderPlannedTimeStart">Planned to Start Time</label>         -->
      <app-timepicker-dropdown
        [title]="START_TIME"
        [date]="timeFrom"
        [dateStr]="'Start'"
        class="ig-typography ig-scrollbar w-100 desk"
      ></app-timepicker-dropdown>
      <input
        type="time"
        class="form-control mob"
        formControlName="plannedToStartAtTime"
        (change)="timeChange()"
        placeholder="Start Time"
        i18n-placeholder="
          plannedToStartAtTime placeholder @@plannedToStartAtTime"
      />
      <label
        class="mob"
        for="plannedToStartAtTime"
        i18n="plannedToStartAtTime label"
        >Start Time</label
      >
    </div>
    <div
      [appValidateState]="form.get('plannedToEndAtDate')"
      class="form-group form-floating mb-3"
      style="position: relative"
    >
      <input
        type="date"
        class="form-control"
        formControlName="plannedToEndAtDate"
        placeholder="End Date"
        i18n-placeholder="plannedToEndAtDate placeholder @@plannedToEndAtDate"
      />
      <label for="plannedToEndAtDate" i18n="@@plannedToEndAtDate"
        >Planned to End Date</label
      >
    </div>
    <div
      [appValidateState]="form.get('plannedToEndAtTime')"
      class="form-group form-floating mb-3"
      style="position: relative"
    >
      <!-- <input type="time" class="form-control" formControlName="plannedToEndAtTime" 
            placeholder="End Time" i18n-placeholder="plannedToEndAtTime placeholder @@plannedToEndAtTime"/>
        <label for="plannedToEndAtTime" i18n="@@plannedToEndAtTime">Planned to End Time</label>      -->
      <app-timepicker-dropdown
        [title]="END_TIME"
        [date]="timeTo"
        [dateStr]="'End'"
        class="ig-typography ig-scrollbar w-100 desk"
      ></app-timepicker-dropdown>
      <input
        (change)="timeChange()"
        type="time"
        class="form-control mob"
        formControlName="plannedToEndAtTime"
        placeholder="End Time"
        i18n-placeholder="plannedToEndAtTime placeholder @@plannedToEndAtTime"
      />
      <label
        class="mob"
        for="plannedToEndAtTime"
        i18n="plannedToEndAtTime label"
        >End Time</label
      >
    </div>
  </div>

  <!-- <div [appValidateState]="form.get('plannedToStartAt')" class="form-group form-floating mb-3">
        <input  formControlName="plannedToStartAt" (change)="onDateChange()"  type="datetime-local" class="form-control"
                placeholder="Date/time" i18n-placeholder="plannedDateTimeStart placeholder @@plannedDateTimeStart"/>
        <label for="plannedToStartAt" i18n="@@orderPlannedDateTimeStart">Planned to Start</label>
    </div>
    <div [appValidateState]="form.get('plannedToEndAt')" class="form-group form-floating mb-3">
        <input formControlName="plannedToEndAt" (change)="onDateChange()"  type="datetime-local" class="form-control"
                placeholder="Date/time" i18n-placeholder="plannedDateTimeEnd placeholder @@plannedDateTimeEnd"/>
        <label for="plannedToEndAt" i18n="@@orderPlannedDateTimeEnd">Planned to End</label>
    </div> -->

  <div class="form-group form-floating mb-3">
    <textarea
      formControlName="note"
      [style.height]="getHeight(form.value.note)"
      type="text"
      class="form-control"
    ></textarea>
    <!-- form.value.note && form.value.note.length > 45" -->
    <label for="note" i18n="orderNote label">Bemærkning</label>
  </div>
  <app-commentphotos-form
    [Form]="form"
    [restrictEdit]="
      orderUpdate && orderUpdate.tripEvents && orderUpdate.tripEvents.length > 0
    "
    class="clickable"
  ></app-commentphotos-form>
  <div class="form-group form-floating mb-3">
    <input formControlName="tenantNote" type="text" class="form-control" />
    <label for="tenantNote" i18n="orderTenantNote label">Tenant Note</label>
  </div>

  <div
    *ngIf="!orderUpdate || orderUpdate.isReccurence"
    class="form-group form-floating mb-3"
  >
    <div
      style="display: flex; align-items: center"
      class="form-check form-switch"
    >
      <input
        [disabled]="orderUpdate?.isReccurence"
        (change)="changeReccurence()"
        class="form-check-input custom-switch"
        type="checkbox"
        id="recurrenceCheck"
        #recurrenceCheck
      />
      <label
        style="font-size: 23px; margin-left: 10px"
        class="form-check-label"
        for="recurrenceCheck"
        i18n="Reccurence label"
        >Recurrence</label
      >
    </div>
    <div
      i18n="Reccurence choose"
      style="color: red"
      *ngIf="reccurrenceEmptyError"
    >
      Choose at least one option
    </div>
  </div>
  <div *ngIf="recurrence" class="line-separator"></div>
  <div *ngIf="recurrence">
    <div class="row">
      <div class="col-md-12">
        <div
          style="display: flex; align-items: center"
          class="form-check form-switch"
        >
          <input
            [disabled]="orderUpdate?.isReccurence"
            (change)="changeReccurenceOption(1)"
            class="form-check-input custom-switch"
            type="checkbox"
            id="weekdaysCheck"
            #weekdaysCheck
          />
          <label
            style="font-size: 23px; margin-left: 10px"
            class="form-check-label"
            for="weekdaysCheck"
            i18n="Reccurence weekdays"
            >Weekdays</label
          >
        </div>
        <div style="margin-top: 13px" *ngIf="curentOption == 1">
          <label class="ml-3 custom-flex">
            <input
              [disabled]="orderUpdate?.isReccurence"
              id="opt1ch1"
              (change)="changeReccurenceData(1, 1, 1)"
              class="custom-check"
              type="checkbox"
            />
            <span i18n="Reccurence monday" style="margin-left: 3px"
              >Monday</span
            >
          </label>
          <label class="ml-3 custom-flex">
            <input
              [disabled]="orderUpdate?.isReccurence"
              id="opt1ch2"
              (change)="changeReccurenceData(1, 1, 2)"
              class="custom-check"
              type="checkbox"
            /><span i18n="Reccurence tuesday" style="margin-left: 3px"
              >Tuesday</span
            >
          </label>
          <label class="ml-3 custom-flex">
            <input
              [disabled]="orderUpdate?.isReccurence"
              id="opt1ch3"
              (change)="changeReccurenceData(1, 1, 3)"
              class="custom-check"
              type="checkbox"
            />
            <span i18n="Reccurence wednesday" style="margin-left: 3px"
              >Wednesday</span
            >
          </label>
          <label class="ml-3 custom-flex">
            <input
              [disabled]="orderUpdate?.isReccurence"
              id="opt1ch4"
              (change)="changeReccurenceData(1, 1, 4)"
              class="custom-check"
              type="checkbox"
            />
            <span i18n="Reccurence thursday" style="margin-left: 3px"
              >Thursday</span
            >
          </label>
          <label class="ml-3 custom-flex">
            <input
              [disabled]="orderUpdate?.isReccurence"
              id="opt1ch5"
              (change)="changeReccurenceData(1, 1, 5)"
              class="custom-check"
              type="checkbox"
            />
            <span i18n="Reccurence friday" style="margin-left: 3px"
              >Friday</span
            >
          </label>
          <br />
          <br />
          <label class="ml-3 custom-flex">
            <input
              [disabled]="orderUpdate?.isReccurence"
              id="opt1ch6"
              (change)="changeReccurenceData(1, 2, 1)"
              class="custom-check"
              type="checkbox"
            />
            <span i18n="Reccurence repeat second week" style="margin-left: 3px"
              >Repeat every second week</span
            >
          </label>
          <div
            i18n="Reccurence time is incorect"
            *ngIf="reccurrenceIntervalError"
            style="color: red"
          >
            Time interval is incorrect
          </div>
          <div
            class="form-group form-floating mb-3"
            style="position: relative; margin-top: 10px"
          >
            <!-- <input type="time" class="form-control" formControlName="plannedToEndAtTime" 
            placeholder="End Time" i18n-placeholder="plannedToEndAtTime placeholder @@plannedToEndAtTime"/>
        <label for="plannedToEndAtTime" i18n="@@plannedToEndAtTime">Planned to End Time</label>      -->
            <app-timepicker-dropdown
              [disabled]="orderUpdate?.isReccurence ?? false"
              [title]="chooseStart"
              [date]="recTimeStart"
              [dateStr]="'End'"
              class="ig-typography ig-scrollbar w-100 desk"
              (timeOutputEvent)="changeReccurenceData(1, 3, 1, $event)"
            ></app-timepicker-dropdown>
            <input
              [disabled]="orderUpdate?.isReccurence"
              type="time"
              id="reccurenceTimeOption1"
              class="form-control mob"
              placeholder="Time"
            />
            <label i18n="Reccurence time" class="mob">Time</label>
          </div>

          <div
            class="form-group form-floating mb-3"
            style="position: relative; margin-top: 10px"
          >
            <!-- <input type="time" class="form-control" formControlName="plannedToEndAtTime" 
          placeholder="End Time" i18n-placeholder="plannedToEndAtTime placeholder @@plannedToEndAtTime"/>
      <label for="plannedToEndAtTime" i18n="@@plannedToEndAtTime">Planned to End Time</label>      -->
            <app-timepicker-dropdown
              [disabled]="orderUpdate?.isReccurence ?? false"
              [title]="chooseEnd"
              [date]="recTimeEnd"
              [dateStr]="'End'"
              class="ig-typography ig-scrollbar w-100 desk"
              (timeOutputEvent)="changeReccurenceData(1, 3, 2, $event)"
            ></app-timepicker-dropdown>
            <input
              [disabled]="orderUpdate?.isReccurence"
              type="time"
              id="reccurenceTimeOption1"
              class="form-control mob"
              placeholder="Time"
            />
            <label i18n="Reccurence time" class="mob">Time</label>
          </div>
        </div>
      </div>
      <div class="line-separator"></div>
      <div class="col-md-12">
        <div
          style="display: flex; align-items: center"
          class="form-check form-switch"
        >
          <input
            [disabled]="orderUpdate?.isReccurence"
            (change)="changeReccurenceOption(2)"
            class="form-check-input custom-switch"
            type="checkbox"
            id="daysCheck"
            #daysCheck
          />
          <label
            i18n="Reccurence daysmonth"
            style="font-size: 23px; margin-left: 10px"
            class="form-check-label"
            for="daysCheck"
            >Days of month</label
          >
        </div>
        <div *ngIf="curentOption == 2">
          <br />
          <label
            *ngFor="let isChecked of daysCheckboxes; let i = index"
            class="ml-3 custom-flex"
          >
            <input
              [disabled]="orderUpdate?.isReccurence"
              [id]="'opt2ch' + i"
              (change)="toggleCheckbox(i)"
              class="custom-check"
              type="checkbox"
            />
            <span style="margin-left: 3px">{{ i + 1 }}</span>
          </label>
          <div
            i18n="Reccurence time is incorect"
            *ngIf="reccurrenceIntervalError"
            style="color: red"
          >
            Time interval is incorrect
          </div>
          <div
            class="form-group form-floating mb-3"
            style="position: relative; margin-top: 10px"
          >
            <!-- <input type="time" class="form-control" formControlName="plannedToEndAtTime" 
          placeholder="End Time" i18n-placeholder="plannedToEndAtTime placeholder @@plannedToEndAtTime"/>
      <label for="plannedToEndAtTime" i18n="@@plannedToEndAtTime">Planned to End Time</label>      -->
            <app-timepicker-dropdown
              [disabled]="orderUpdate?.isReccurence ?? false"
              [title]="chooseStart"
              [date]="recTimeStart"
              [dateStr]="'End'"
              class="ig-typography ig-scrollbar w-100 desk"
              (timeOutputEvent)="changeReccurenceData(2, 2, 1, $event)"
            ></app-timepicker-dropdown>
            <input
              [disabled]="orderUpdate?.isReccurence"
              type="time"
              class="form-control mob"
              placeholder="Time"
            />
            <label i18n="Reccurence time" class="mob">Time</label>
          </div>

          <div
            class="form-group form-floating mb-3"
            style="position: relative; margin-top: 10px"
          >
            <!-- <input type="time" class="form-control" formControlName="plannedToEndAtTime" 
        placeholder="End Time" i18n-placeholder="plannedToEndAtTime placeholder @@plannedToEndAtTime"/>
    <label for="plannedToEndAtTime" i18n="@@plannedToEndAtTime">Planned to End Time</label>      -->
            <app-timepicker-dropdown
              [disabled]="orderUpdate?.isReccurence ?? false"
              [title]="chooseEnd"
              [date]="recTimeEnd"
              [dateStr]="'End'"
              class="ig-typography ig-scrollbar w-100 desk"
              (timeOutputEvent)="changeReccurenceData(2, 2, 2, $event)"
            ></app-timepicker-dropdown>
            <input
              [disabled]="orderUpdate?.isReccurence"
              type="time"
              class="form-control mob"
              placeholder="Time"
            />
            <label i18n="Reccurence time" class="mob">Time</label>
          </div>
        </div>
      </div>
      <div class="line-separator"></div>
      <div class="col-md-12">
        <div
          style="display: flex; align-items: center"
          class="form-check form-switch"
        >
          <input
            [disabled]="orderUpdate?.isReccurence"
            (change)="changeReccurenceOption(3)"
            class="form-check-input custom-switch"
            type="checkbox"
            id="weekMonthCheck"
            #weekMonthCheck
          />
          <label
            i18n="Reccurence weekdaysmonths"
            style="font-size: 23px; margin-left: 10px"
            class="form-check-label"
            for="weekMonthCheck"
            >Weekdays of month</label
          >
        </div>
        <div *ngIf="curentOption == 3">
          <br />
          <label class="ml-3 custom-flex">
            <input
              [disabled]="orderUpdate?.isReccurence"
              id="opt3ch1"
              (change)="changeReccurenceData(3, 1, 1)"
              class="custom-check"
              type="checkbox"
            />
            <span i18n="Reccurence monday" style="margin-left: 3px"
              >Monday</span
            >
          </label>
          <label class="ml-3 custom-flex">
            <input
              [disabled]="orderUpdate?.isReccurence"
              id="opt3ch2"
              (change)="changeReccurenceData(3, 1, 2)"
              class="custom-check"
              type="checkbox"
            /><span i18n="Reccurence tuesday" style="margin-left: 3px"
              >Tuesday</span
            >
          </label>
          <label class="ml-3 custom-flex">
            <input
              [disabled]="orderUpdate?.isReccurence"
              id="opt3ch3"
              (change)="changeReccurenceData(3, 1, 3)"
              class="custom-check"
              type="checkbox"
            />
            <span i18n="Reccurence wednesday" style="margin-left: 3px"
              >Wednesday</span
            >
          </label>
          <label class="ml-3 custom-flex">
            <input
              [disabled]="orderUpdate?.isReccurence"
              id="opt3ch4"
              (change)="changeReccurenceData(3, 1, 4)"
              class="custom-check"
              type="checkbox"
            />
            <span style="margin-left: 3px" i18n="Reccurence thursday"
              >Thursday</span
            >
          </label>
          <label class="ml-3 custom-flex">
            <input
              [disabled]="orderUpdate?.isReccurence"
              id="opt3ch5"
              (change)="changeReccurenceData(3, 1, 5)"
              class="custom-check"
              type="checkbox"
            />
            <span i18n="Reccurence friday" style="margin-left: 3px"
              >Friday</span
            >
          </label>
          <br />
          <br />
          <div>
            <label class="ml-3 custom-flex">
              <input
                [disabled]="orderUpdate?.isReccurence"
                id="opt3ch6"
                (change)="changeReccurenceData(3, 2, 1)"
                class="custom-check"
                type="checkbox"
              />
              <span i18n="Reccurence 1w" style="margin-left: 3px"
                >1 week of month</span
              >
            </label>
          </div>
          <div>
            <label class="ml-3 custom-flex">
              <input
                [disabled]="orderUpdate?.isReccurence"
                id="opt3ch7"
                (change)="changeReccurenceData(3, 2, 2)"
                class="custom-check"
                type="checkbox"
              />
              <span i18n="Reccurence 2w" style="margin-left: 3px"
                >2 week of month</span
              >
            </label>
          </div>
          <label class="ml-3 custom-flex">
            <input
              [disabled]="orderUpdate?.isReccurence"
              id="opt3ch8"
              (change)="changeReccurenceData(3, 2, 3)"
              class="custom-check"
              type="checkbox"
            />
            <span i18n="Reccurence 3w" style="margin-left: 3px"
              >3 week of month</span
            >
          </label>
          <div>
            <label class="ml-3 custom-flex">
              <input
                [disabled]="orderUpdate?.isReccurence"
                id="opt3ch9"
                (change)="changeReccurenceData(3, 2, 4)"
                class="custom-check"
                type="checkbox"
              />
              <span i18n="Reccurence 4w" style="margin-left: 3px"
                >4 week of month</span
              >
            </label>
          </div>
          <div
            i18n="Reccurence time is incorect"
            *ngIf="reccurrenceIntervalError"
            style="color: red"
          >
            Time interval is incorrect
          </div>
          <div
            class="form-group form-floating mb-3"
            style="position: relative; margin-top: 10px"
          >
            <!-- <input type="time" class="form-control" formControlName="plannedToEndAtTime" 
          placeholder="End Time" i18n-placeholder="plannedToEndAtTime placeholder @@plannedToEndAtTime"/>
      <label for="plannedToEndAtTime" i18n="@@plannedToEndAtTime">Planned to End Time</label>      -->
            <app-timepicker-dropdown
              [disabled]="orderUpdate?.isReccurence ?? false"
              [title]="chooseStart"
              [date]="recTimeStart"
              [dateStr]="'End'"
              class="ig-typography ig-scrollbar w-100 desk"
              (timeOutputEvent)="changeReccurenceData(3, 3, 1, $event)"
            ></app-timepicker-dropdown>
            <input
              [disabled]="orderUpdate?.isReccurence"
              type="time"
              class="form-control mob"
              placeholder="Time"
            />
            <label i18n="Reccurence time" class="mob">Time</label>
          </div>

          <div
            class="form-group form-floating mb-3"
            style="position: relative; margin-top: 10px"
          >
            <!-- <input type="time" class="form-control" formControlName="plannedToEndAtTime" 
          placeholder="End Time" i18n-placeholder="plannedToEndAtTime placeholder @@plannedToEndAtTime"/>
      <label for="plannedToEndAtTime" i18n="@@plannedToEndAtTime">Planned to End Time</label>      -->
            <app-timepicker-dropdown
              [disabled]="orderUpdate?.isReccurence ?? false"
              [title]="chooseEnd"
              [date]="recTimeEnd"
              [dateStr]="'End'"
              class="ig-typography ig-scrollbar w-100 desk"
              (timeOutputEvent)="changeReccurenceData(3, 3, 2, $event)"
            ></app-timepicker-dropdown>
            <input type="time" class="form-control mob" placeholder="Time" />
            <label i18n="Reccurence time" class="mob">Time</label>
          </div>
        </div>
      </div>
      <div class="line-separator"></div>
      <div
        i18n="Reccurence datesincorrect"
        style="color: red"
        *ngIf="reccurenceTimeError"
      >
        Start and end dates are incorrect!
      </div>
      <div class="form-group form-floating mb-3" style="position: relative">
        <input
          [disabled]="orderUpdate?.isReccurence"
          (change)="changeReccurenceInterval(1, $event)"
          [value]="today"
          type="date"
          class="form-control"
          i18n-placeholder="Reccurence startdate2"
          placeholder="Start Date"
        />
        <label i18n="Reccurence startdate">Recurrence Start Date</label>
      </div>
      <div class="form-group form-floating mb-3" style="position: relative">
        <input
          [disabled]="orderUpdate?.isReccurence"
          (change)="changeReccurenceInterval(2, $event)"
          [value]="todayPlusMonth"
          type="date"
          class="form-control"
          i18n-placeholder="Reccurence enddate2"
          placeholder="End Date"
        />
        <label i18n="Reccurence enddate">Recurrence End Date</label>
      </div>
    </div>
  </div>

  <div
    *ngIf="
      (orderUpdate && !orderUpdate.tripEvents) ||
      (orderUpdate &&
        orderUpdate.tripEvents &&
        orderUpdate.tripEvents.length === 0) ||
      !orderUpdate
    "
    class="form-group form-floating h-100 d-grid"
  >
    <button
      [class.disabled]="deletedSelected || !form.value.clientId"
      *ngIf="(!orderUpdate || !orderUpdate.isRejected) && !recurrence"
      (click)="childSubmit()"
      class="btn btn-primary mt-2"
      i18n="linkedSubmit button"
    >
      Add linked order
    </button>
    <button
      [class.disabled]="deletedSelected || !form.value.clientId"
      type="submit"
      class="btn btn-success mt-2"
      i18n="orderSubmit button"
      (click)="onSubmit()"
    >
      Save
    </button>
    <button
      id="cancel_btn"
      (click)="cancel()"
      class="btn btn-secondary mt-2"
      data-bs-dismiss="modal"
      aria-label="Close"
      i18n-aria-label="Close label @@close"
      i18n="@@orderCancel"
    >
      Cancel
    </button>
  </div>
</form>
