<h2 class="mb-4" *ngIf="id === 'new'" i18n="Create product label">Create product</h2>
<h2 class="mb-4" *ngIf="id !== 'new'" i18n="Edit product label">Edit product</h2>
<div *ngIf="(id === 'new') || (product$ | async)" class="row mb-4">    
  <div class="col-12">
    <app-product-form [editedProduct]="product$ | async"
                     
      (productSubmit)="createProduct($event)"></app-product-form>       
    <app-error-alert title="Could not submit the product" i18n-title="Could not sumbit product message"
      [errors]="submitErrors"></app-error-alert>
    </div>
</div>
