<table style="margin-top:15px;" class="table table-hover table-bordered">
  <thead>
    <tr>
      <th i18n="@@firstName">Name</th>
      <th i18n="@@SurName">Surname</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of activeDriversData">
      <td>{{item.name}}</td>
      <td>{{item.surname}}</td>
    </tr>

  </tbody>
</table>
