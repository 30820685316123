import {
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { AuthRepository } from 'src/app/state/auth.repository';
import { Column, SimpleColumn } from 'src/app/state/columns.repository';
import { DataSelectService } from 'src/app/state/dataSelect.service';
import { ExportService } from 'src/app/state/export.service';
import { DataSelectDto } from 'src/app/state/models/dataSelectModels/dataSelectDto';
import { TripLogDto } from 'src/app/state/models/trip/TripLogDto';

import { SearchState, TripEventType } from 'src/app/state/trips.repository';
import { UiRepository } from 'src/app/state/ui.repository';
import { UserSelectDto } from 'src/app/state/users.repository';
import { FilterOptions } from 'src/app/state/workdays.repository';
import {
  NgbdSortableHeader,
  SortColumn,
  SortDirection,
  SortEvent,
} from '../sortable.directive';
import * as dayjs from 'dayjs';
import { TripEventsService } from 'src/app/state/tripevents.service';
import { Router } from '@angular/router';
import { TripsService } from 'src/app/state/trips.service';
import { TenantNoteChangeDto } from 'src/app/state/models/shared/TenantNoteChangeDto';
import { TripEventFileUploadDto } from 'src/app/state/models/shared/TripEventFileUploadDto';
import { TripPhotoUrlDto } from 'src/app/state/models/trip/TripPhotoUrlDto';
import { OrdersService } from 'src/app/state/orders.service';
import { DayjsService } from 'src/app/modules/shared/services/dayjs.service';

const compare = (v1: string | number | any, v2: string | number | any) =>
  v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(
  dtos: TripLogDto[],
  column: SortColumn,
  direction: string
): TripLogDto[] {
  let paramName: string = '';
  switch (column) {
    case 'ClientName':
      paramName = 'clientNameStartEnd';
      break;
    case 'ClientID':
      paramName = 'clientNumberStartEnd';
      break;
    case 'Weight':
      paramName = 'weightStartEnd';
      break;
    case 'TripStartKm':
      paramName = 'startKm';
      break;
    case 'TripTotalKm':
      paramName = 'tripKm';
      break;
    case 'ProductName':
      paramName = 'productNameStartEnd';
      break;
    case 'TripEndKm':
      paramName = 'endKm';
      break;
    case 'Cleaning':
      paramName = 'cleaningStartEnd';
      break;
    case 'TenantNote':
      paramName = 'tenantNote';
      break;
    case 'TripStarttime':
      paramName = 'startTime';
      break;
    case 'TripEndTime':
      paramName = 'endTime';
      break;
    case 'Totaltime':
      paramName = 'tripDuration';
      break;
    case 'Note':
      paramName = 'noteStartEnd';
      break;
    case 'Reference':
      paramName = 'referenceStartEnd';
      break;
    case 'VendorName':
      paramName = 'vendorNameStartEnd';
      break;
    case 'Accessory':
      paramName = 'accessoryStartEnd';
      break;

    default:
      paramName = 'startTime';
      break;
  }

  return [...dtos].sort((a: any, b: any) => {
    const res = compare(a[paramName] ?? '', b[paramName] ?? '');
    return direction === 'asc' ? res : -res;
  });
}

@Component({
  selector: 'app-sort-table1',
  templateUrl: './sort-table1.component.html',
  styleUrls: ['./sort-table1.component.scss'],
})
export class SortTable1Component {
  pages: number[] = [1];

  public _state: SearchState = {
    page: 1,
    pageSize: 20,
    searchTerm: '',
    sortColumn: '',
    sortDirection: '',
    unloadPosition: '',
  };

  driverStaticOptions: DatalistOption[] | null = null;
  driverOptions: DatalistOption[] | null = null;

  clientStaticOptions: DatalistOption[] | null = null;
  clientOptions: DatalistOption[] | null = null;

  productsStaticOptions: DatalistOption[] | null = null;
  productsOptions: DatalistOption[] | null = null;

  exportFactory = () =>
    this.exportService.featuredExport(this.allTripIds, this.timeZone);
  exportPhotoFactory = () =>
    this.exportService.featuredPhotosExport(this.allTripIds);

  simpleColumns: SimpleColumn[] = [
    {
      name: 'modal',
      danskName: 'Vis alle data',
      isVisible: true,
    },
  ];
  simpleLoadUnloadColumns: SimpleColumn[] | null = null;
  standartVariableNames: string[] = ['Vis alle data'];

  @Output() tenantNoteChange = new EventEmitter<TenantNoteChangeDto>();
  @Output() takePhotos = new EventEmitter<TripEventFileUploadDto>();
  @Output() movePhoto = new EventEmitter<TripPhotoUrlDto>();
  @Output() filterOptionsChange = new EventEmitter<FilterOptions>();

  allTrips: TripLogDto[] | undefined = undefined;
  filteredTrips: TripLogDto[] | undefined = undefined;
  allTripIds: string[] = [];
  timeZone: number = 0;

  @Input() filterOptions: FilterOptions | undefined;

  @Input() dateType: string | undefined = undefined;

  @Input() set tripLogs(value: TripLogDto[] | undefined) {
    if (value) {
      this.allTrips = value;
      this.setData();
    } else {
      this.allTrips = undefined;
      this.filteredTrips = undefined;
    }
  }

  @Input() set clients(value: DataSelectDto[] | undefined) {
    if (value) {
      this.clientOptions = this.dataSelectService.setDataSelectOptions(value);
      this.clientStaticOptions = this.clientOptions;
    }
  }

  @Input() set products(value: DataSelectDto[] | undefined) {
    if (value) {
      this.productsOptions = this.dataSelectService.setDataSelectOptions(value);
      this.productsStaticOptions = this.productsOptions;
    }
  }

  @Input() set drivers(value: UserSelectDto[] | undefined) {
    if (value) {
      this.driverOptions = this.driverOptions = value.map((x) => ({
        value: x.id,
        label: `${x.name} ${x.surname}`,
      }));
      this.driverStaticOptions = this.driverOptions;
    }
  }

  @Input() set tableColumns(value: Column[] | null) {
    if (value) {
      value.map((col) => {
        var name = col.name.replace(/\s/g, '').split(',')[0];

        this.simpleColumns.push({
          name: name,
          danskName: col.danskName.split(',')[0],
          isVisible: col.isVisible,
        });

        this.standartVariableNames.push(name);
      });
    }
  }

  @Input() set loadUnloadColumns(value: Column[] | null) {
    if (value) {
      this.simpleLoadUnloadColumns = [];
      value.map((x) =>
        this.simpleLoadUnloadColumns?.push({
          name:
            x.name.replace(/\s/g, '').split(',')?.length > 1
              ? x.name.replace(/\s/g, '').split(',')[1]
              : x.name.replace(/\s/g, '').split(',')[0],
          danskName:
            x.danskName.replace(/\s/g, '').split(',')?.length > 1
              ? x.danskName
                  .split(',')[1]
                  .replace('losning', '')
                  .replace('Losning', '')
                  .replace('aflosset', '')
                  .replace('fotos', 'Fotos')
              : x.danskName.split(',')[0],
          isVisible: x.isVisible,
        })
      );
    }
  }

  @Input() isParkedOverview: boolean = false;

  @Input() isAdminPage: boolean = true;

  @ViewChildren(NgbdSortableHeader) headers:
    | QueryList<NgbdSortableHeader>
    | undefined;

  constructor(
    public authRepo: AuthRepository,
    private dataSelectService: DataSelectService,
    public ui: UiRepository,
    private tripEventService: TripEventsService,
    public router: Router,
    public service: TripsService,
    public exportService: ExportService,
    public orderService: OrdersService,
    public ngDay: DayjsService
  ) {
    this.getState();
    this.timeZone = -new Date().getTimezoneOffset() / 60;
  }

  setDatePicker(dateType: string) {
    if (this.filterOptions) {
      const dayObj = this.ngDay.dayjs.utc().local();
      switch (dateType) {
        case 'day':
          this.filterOptions.dateFrom = dayObj.format('YYYY-MM-DD');
          this.filterOptions.dateTo = dayObj.format('YYYY-MM-DD');
          break;

        case 'week':
          const weekStart = this.ngDay.dayjs().startOf('week');
          const weekEnd = this.ngDay.dayjs().endOf('week');
          this.filterOptions.dateFrom = weekStart.format('YYYY-MM-DD');
          this.filterOptions.dateTo = weekEnd.format('YYYY-MM-DD');
          break;

        case 'month':
          let monthStart = this.ngDay.dayjs().startOf('month');
          let monthEnd = this.ngDay.dayjs().endOf('month');

          if (this.isAdminPage) {
            monthStart = monthStart.subtract(1, 'month');
            monthEnd = monthStart.endOf('month');
          }

          this.filterOptions.dateFrom = monthStart.format('YYYY-MM-DD');
          this.filterOptions.dateTo = monthEnd.format('YYYY-MM-DD');
          break;

        default:
          break;
      }
    }
  }

  getState() {
    this._state.page = Number(localStorage.getItem('page')) || 1;
    this._state.sortColumn =
      localStorage.getItem('sortColumn')?.toString() || '';
    this._state.sortDirection =
      (localStorage.getItem('sortDirection')?.toString() as SortDirection) ||
      '';
  }

  setState() {
    localStorage.setItem('page', this._state.toString());
    localStorage.setItem('sortColumn', this._state.sortColumn);
    localStorage.setItem('sortDirection', this._state.sortDirection ?? '');
  }

  filterChange() {
    this.filterOptionsChange.emit(this.filterOptions);
  }

  setData() {
    if (this.allTrips) {
      this.allTrips = sort(
        this.allTrips,
        this._state.sortColumn,
        this._state.sortDirection
      );

      this.allTripIds = this.allTrips?.map((x) => x.id) ?? [];

      this.exportService.featuredExport(this.allTripIds, this.timeZone);
      this.exportService.featuredPhotosExport(this.allTripIds);

      this.setPageData();

      this.filterOptions!.order = this._state.sortDirection ?? '';
      this.filterOptions!.orderColumn = this._state.sortColumn;
    }
  }

  setPageData() {
    if (this.allTrips) {
      let total = this.allTrips.length;
      let numberOfPages = Math.floor(total / this._state.pageSize);
      if (total % this._state.pageSize !== 0) {
        numberOfPages = numberOfPages + 1;
      }
      this.pages = Array.from(
        { length: numberOfPages ? numberOfPages : 1 },
        (_, i) => i + 1
      );

      if (!this.pages.includes(this._state.page)) {
        this._state.page = this.pages.length;
      }
      this.filteredTrips = this.allTrips?.slice(
        (this._state.page - 1) * this._state.pageSize,
        (this._state.page - 1) * this._state.pageSize + this._state.pageSize
      );
    }
    this.setDriverSelectOptions();
    this.setProductSelectOptions();
    this.setClientSelectOptions();
  }

  setDriverSelectOptions() {
    if (this.allTrips) {
      const currDrivers = this.allTrips.map((trip) => trip.driverId);
      const uniqueDriverIds = [...new Set(currDrivers)];

      this.driverOptions =
        this.driverStaticOptions?.filter((option) =>
          uniqueDriverIds.includes(option.value)
        ) ?? null;
    } else {
      this.driverOptions = this.driverStaticOptions;
    }
  }

  setProductSelectOptions() {
    if (this.allTrips) {
      const currProductIds = this.allTrips.flatMap((trip) => trip.productIds);
      const uniqueProductIds = [...new Set(currProductIds)];
      this.productsOptions =
        this.productsStaticOptions?.filter((option) =>
          uniqueProductIds.includes(option.value)
        ) ?? null;
    } else {
      this.productsOptions = this.productsStaticOptions;
    }
  }

  setClientSelectOptions() {
    if (this.allTrips) {
      const currClientIds = this.allTrips.flatMap((trip) => trip.clientIds);
      const uniqueClientIds = [...new Set(currClientIds)];
      this.clientOptions =
        this.clientStaticOptions?.filter((option) =>
          uniqueClientIds.includes(option.value)
        ) ?? null;
    } else {
      this.clientOptions = this.clientStaticOptions;
    }
  }

  ngOnInit() {
    if (this.dateType) {
      this.setDatePicker(this.dateType);
    } else if (!this.filterOptions?.dateTo || !this.filterOptions.dateFrom) {
      this.setDatePicker('month');
    }
  }

  ngAfterContentChecked() {
    this.setHeaders();
    this.filteredTrips?.forEach((x, y) => {
      let row = document.getElementById(`ordering${y}`);
      this.simpleColumns?.forEach((a, b) => {
        let sortedColumnName = `${a.name}${y}`;
        let unsortedColumnName = row?.children.item(b)?.id;
        if (row) {
          let sortedColumn = row.querySelector(`#${sortedColumnName}`);
          let unsortedColumn = row.querySelector(`#${unsortedColumnName}`);
          if (sortedColumn) {
            row.insertBefore(sortedColumn, unsortedColumn);
          }
        }
        if (!a.isVisible) {
          let toRemove = `${a.name}${y}`;
          let toRemoveColumn = row?.querySelector(`#${toRemove}`);
          if (row && toRemoveColumn) {
            row.removeChild(toRemoveColumn);
          }
        }
      });
    });
  }

  onSort({ column, direction }: SortEvent) {
    this._state.sortColumn = column;
    this._state.sortDirection = direction;

    this.headers?.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.setState();
    this.setData();
  }

  setHeaders() {
    this.headers?.forEach((header) => {
      if (header.sortable !== this._state.sortColumn) {
        header.direction = '';
      } else {
        header.direction = this._state.sortDirection ?? '';
      }
    });
  }

  navigateTo(str: string) {
    this.tripEventService.loadOneWithoutCache(str).subscribe((x) => {
      if (x) {
        this.router.navigate([`/trips/edit/${x.id}/${this.isAdminPage}`]);
      }
    });
  }

  changeApprove(tripLog: TripLogDto) {
    let tripFromAll = this.allTrips?.find((x) => x.id === tripLog.id);
    if (tripFromAll) {
      tripFromAll.isApproved = !tripFromAll.isApproved;
    }
    this.service.updateApproveStatus(tripLog.id).subscribe();
  }

  selectedTripLog: TripLogDto | undefined = undefined;
  noteEditing: boolean = false;
  driverChanging: boolean = false;
  sortModalActive: boolean = false;
  photoUrls: TripPhotoUrlDto[] | undefined = undefined;
  sliderType: 'Weigh' | 'Usual' = 'Usual';

  openDriversModal(trip: TripLogDto) {
    this.selectedTripLog = trip;
    this.driverChanging = true;
  }

  openTenantComment(tripLog: TripLogDto) {
    this.noteEditing = true;
    this.selectedTripLog = tripLog;
  }

  addTenantNote($event?: string) {
    if (this.selectedTripLog) {
      this.tenantNoteChange.emit({
        id: this.selectedTripLog.id,
        tenantNote: $event,
      });
      this.updateTenantNoteInArray(
        this.allTrips,
        this.selectedTripLog.id,
        $event
      );
      this.updateTenantNoteInArray(
        this.filteredTrips,
        this.selectedTripLog.id,
        $event
      );
    }
    this.cancelEvent();
  }

  updateTenantNoteInArray(
    tripLogs?: TripLogDto[],
    id?: string,
    tenantNote: string = ''
  ) {
    let tripLog = tripLogs?.find((x) => x.id === id);
    if (tripLog) {
      tripLog.tenantNote = tenantNote;
    }
  }

  cancelEvent() {
    this.selectedTripLog = undefined;
    this.noteEditing = false;
    this.driverChanging = false;
    this.sortModalActive = false;
    this.photoUrls = undefined;
    this.sliderType = 'Usual';
    this.photoUrls = undefined;
  }

  addImage($event: any, tripId: string, tripEventId?: string) {
    let files = $event.target.files;
    this.takePhotos.emit({ tripId, tripEventId, files });
  }

  getUrls(id: string, type: TripEventType) {
    this.service.getPhotos(id, type).subscribe((x) => {
      this.photoUrls = x;
      if (type == 'Weighing') {
        this.sliderType = 'Weigh';
      } else {
        this.sliderType = 'Usual';
      }
    });
  }

  movePhotos(photo: TripPhotoUrlDto) {
    this.movePhoto.emit(photo);
  }

  getDataToSortModal(tripLog: TripLogDto) {
    this.selectedTripLog = tripLog;
    this.sortModalActive = true;
  }

  updateParkedTrip(driverId: string) {
    if (this.selectedTripLog) {
      this.orderService
        .updateParkedDriverWidthChilds(this.selectedTripLog.id, driverId)
        .subscribe({
          complete: () => {
            this.filterChange();
            this.cancelEvent();
          },
        });
    }
  }

  calculateDurationForParked(trip: TripLogDto): string {
    let duration = dayjs
      .duration(dayjs(trip.parkedAt).diff(dayjs(trip.startTime)))
      .asMilliseconds();
    return this.convertMS(duration - (trip?.parkDuration ?? 0));
  }

  convertMS(duration: number): string {
    let d, h, m, s;
    s = Math.floor(duration / 1000);
    m = Math.floor(s / 60);
    s = s % 60;
    h = Math.floor(m / 60);
    m = m % 60;
    d = Math.floor(h / 24);
    h = h % 24;
    h += d * 24;
    return `${h}h. ${m}m. ${s}s. `;
  }
}
