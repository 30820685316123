import {
  AfterViewChecked,
  OnDestroy,
  Component,
  DoCheck,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  combineLatest,
  filter,
  forkJoin,
  catchError,
  map,
  Observable,
  tap,
} from 'rxjs';
import { LocationService } from 'src/app/modules/shared/services/location.service';
import { RequestsResolverRunner } from 'src/app/modules/shared/services/requests-resolver';
import { AccessoriesService } from 'src/app/state/accessory.service';
import { AuthRepository } from 'src/app/state/auth.repository';
import { AuthService } from 'src/app/state/auth.service';
import { CleaningsService } from 'src/app/state/cleaning.service';
import { CompaniesService } from 'src/app/state/companies.service';
import { TenantFeatures } from 'src/app/state/feature.repository';
import {
  Order,
  OrderFamily,
  OrdersRepository,
} from 'src/app/state/orders.repository';
import { OrdersService } from 'src/app/state/orders.service';
import { ProductsService } from 'src/app/state/products.service';
import {
  Trip,
  TripEvent,
  TripsRepository,
} from 'src/app/state/trips.repository';
import { TripsService } from 'src/app/state/trips.service';
import {
  Workday,
  WorkdayEvent,
  WorkdaysRepository,
} from 'src/app/state/workdays.repository';
import { WorkdaysService } from 'src/app/state/workdays.service';
import { ConnectionService } from '../../../shared/services/connection.service';
import { Product, ProductsRepository } from 'src/app/state/products.repository';
import { CompaniesRepository } from 'src/app/state/companies.repository';
import { sortBy } from 'src/app/modules/shared/pipes/sort.pipe';
import { DayjsService } from 'src/app/modules/shared/services/dayjs.service';
import { EProductsService } from 'src/app/state/eproducts.service';
import { EProductsRepository } from 'src/app/state/eproducts.repository';
import * as dayjs from 'dayjs';
const LOCATION_PERMISSION_ERROR = $localize`:Location permission error:Please allow access to the location service in your browser`;
const VEHICLE_ERROR = $localize`:Trip event page vehicle settings error:Vehicle is not selected`;
const TRIP_ERROR = $localize`:Trip event page error while loading current trip:Could not load current trip`;
const REJECT_TEXT = $localize`You are about to reject the following order:`;
const REJECT_MESSAGE = $localize`Please confirm your action by clicking Reject.`;
const REJECT_BUTTON = $localize`Reject`;
const WORKDAY_ERROR = $localize`:Workday error:Workday has already started`;
const DAY_START_ERROR = $localize`:DayStart error:DayStart has already happened`;
const DAY_END_ERROR = $localize`:DayEnd error:DayEnd has already happened`;
const ORDER_REJECT_ERROR = $localize`:Order event page error because order is rejected:Trip has been already rejected`;
const PARK = $localize`:Park Action:Park`;
const PARKED_MESSAGE = $localize`:Confirm parking of the trip:Please confirm your action by clicking Park`;
const PARKED_TEXT = $localize`:About to park the trip:You are about to park the folowing trip:`;

@Component({
  selector: 'app-go-page',
  templateUrl: './go-page.component.html',
  styleUrls: ['./go-page.component.scss'],
})
export class GoPageComponent implements OnInit, OnDestroy {
  display: string = 'block !important';
  opacity: string = '1 !important';
  activeTrip: Trip | null = null;
  tripsForModal = new Array<Trip>();
  title = [];
  TenantFeatures = TenantFeatures;
  vehicleError = false;
  isClicked = false;
  parkedTripOrder: Trip | Order | null = null;
  parkedMessage = '';
  parkAction = '';
  parkedCandidate = '';
  parkedText = '';
  currentTrip?: Trip | null = null;
  submitErrors: string[] | null = null;
  orderFamily?: OrderFamily | null = null;
  orderFamilies: OrderFamily[] | null = null;
  userSub: any;
  userId: string | null = null;
  ordersForDriver: Order[] | null = null;
  workday: any;
  workDaySub: any;
  trip?: Trip | null = null;
  tripSub: any;
  order: any;
  ordersSub: any;
  orderSub: any;
  settings: any;
  settingsSub: any;
  pause: WorkdayEvent | null = null;
  pauseSub: any;
  companies: any;
  companiesSub: any;
  products: any;
  productsSub: any;
  eproducts: any;
  eproductsSub: any;
  currentChildren: string[] | null = null;
  parked: Trip[] | null = null;
  rejectText: string = '';
  rejectMessage: string = '';
  rejectButton: string = '';
  isDeleteDisabled = true;
  deleteConfirmation?: Order | null = null;
  doubleActionError: string = '';
  isLoading$ = combineLatest([
    this.dayRepo.isLoadingCurrent$,
    this.tripRepo.isLoadingCurrent$,
    this.ordersRepo.isLoadingCurrent$,
    this.authRepo.isSettingsLoading$,
  ]).pipe(
    map(([day, trip, order, settings]) => day && trip && order && settings)
  );

  constructor(
    public isOnlineService: ConnectionService,
    public dayRepo: WorkdaysRepository,
    public dayService: WorkdaysService,
    public tripRepo: TripsRepository,
    public tripService: TripsService,
    public ordersRepo: OrdersRepository,
    public ordersService: OrdersService,
    private authService: AuthService,
    public authRepo: AuthRepository,
    private tripsService: TripsService,
    public router: Router,
    private locationService: LocationService,
    private offlineResolver: RequestsResolverRunner,
    private accessoryService: AccessoriesService,
    private cleaningService: CleaningsService,
    private companiesService: CompaniesService,
    private productsService: ProductsService,
    private eprodService: EProductsService,
    public prodRepo: ProductsRepository,
    public eprodRepo: EProductsRepository,
    public companyRepo: CompaniesRepository,
    public ngDay: DayjsService
  ) {
    this.workDaySub = dayRepo.activeWorkday$.subscribe((x) => {
      this.workday = x;
    });
    this.getActive();
    this.userSub = this.authRepo.id$.subscribe((a) => (this.userId = a));

    this.ordersSub = ordersRepo.ordersByUser$(this.userId!).subscribe((a) => {
      let todayDate = dayjs().add(-5, 'days').startOf('day').toDate();
      let futureDate = dayjs().add(5, 'days').endOf('day').toDate();
      this.ordersForDriver = this.sortOrdersByDateDesc(a).filter(
        (x) =>
          x.plannedToStartAt! >= todayDate && x.plannedToStartAt! <= futureDate
      );
      this.orderFamilies = this.groupRelatedOrders(a);
      let currentChildrenStorage = localStorage.getItem('currentChildren');
      if (currentChildrenStorage) {
        this.currentChildren = currentChildrenStorage.split(',');
      }
    });

    this.settingsSub = authRepo.settings$.subscribe((a) => (this.settings = a));
    this.pauseSub = dayRepo.activePause$.subscribe((a) => {
      this.pause = a ?? null;
    });
    this.productsSub = prodRepo.all$.subscribe((a) => {
      this.products = a;
    });
    this.eproductsSub = eprodRepo.all$.subscribe((a) => (this.eproducts = a));
    this.companiesSub = companyRepo.all$.subscribe((a) => (this.companies = a));
  }

  getActive() {
    this.tripSub = this.tripRepo.activeTrip$.subscribe((a) => {
      this.trip = a;
    });
    this.orderSub = this.ordersRepo.activeOrder$.subscribe((a) => {
      this.order = a;
    });
  }

  isDataLoaded: boolean = false;

  ngOnInit(): void {
    this.endDayWarning = false;
    if (this.isOnlineService.isOnline()) {
      this.ordersService.loadParkedForCurrentDriver().subscribe();
      let noTrip = localStorage.getItem('go_reload');
      if (noTrip) {
        localStorage.removeItem('go_reload');
        window.location.reload();
      }
    }
    if (this.isOnlineService.isOnline()) {
      forkJoin([
        this.dayService.loadCurrent(),
        this.ordersService.loadForCurrentDriver(),
        this.tripService.loadCurrent(),
        this.ordersService.loadCurrent(),
        this.authService.loadSettings(),
      ])
        .pipe(this.offlineResolver.useUnsyncedChanges())
        .subscribe((x) => (this.isDataLoaded = true));
      this.accessoryService.load().subscribe();
      this.cleaningService.load().subscribe();

      this.companiesService.loadAllForGoPage().subscribe();
      this.productsService.loadAsListwithRepo().subscribe();
      this.tripsService.loadForGoPage().subscribe();
      this.eprodService.loadAsListwithRepo().subscribe();
    } else {
      this.isDataLoaded = true;
    }
    combineLatest({
      trips1: this.tripRepo.parkedTrips$(this.userId!),
      trips2: this.ordersRepo.parkedOrders$(this.userId!),
    }).subscribe((x) => {
      this.parked = this.uniqueList([...x.trips1, ...(x.trips2 as Trip[])]);
    });
  }

  ngOnDestroy(): void {
    this.workDaySub?.unsubscribe();
    this.tripSub?.unsubscribe();
    this.orderSub?.unsubscribe();
    this.ordersSub?.unsubscribe();
    this.settingsSub?.unsubscribe();
    this.pauseSub?.unsubscribe();
    this.productsSub?.unsubscribe();
    this.eproductsSub?.unsubscribe();
    this.companiesSub?.unsubscribe();
    this.userSub?.unsubscribe();
  }

  sortOrdersByDateDesc(orders: Order[] | null): Order[] {
    if (orders && orders.length > 0) {
      return sortBy(orders, {
        parameter: { property: 'plannedToStartAt' },
        direction: 'asc',
      });
    }
    return [];
  }

  createPause(event: any) {
    let order = this.ordersRepo.getActiveOrder();
    let trip = this.tripRepo.getActiveTrip();
    var id = undefined;
    if (order) {
      id = order.id;
    } else if (trip) {
      id = trip.id;
    }
    this.dayService.pause(this.workday, event, id).subscribe();
  }

  permitParkAction($event: string) {
    let order = this.ordersRepo.getActiveOrder();
    let trip = this.tripRepo.getActiveTrip();
    if (order) {
      this.parkedTripOrder = order;
      this.parkAction = PARK;
      this.parkedMessage = PARKED_MESSAGE;
      this.parkedText = PARKED_TEXT;
      this.parkedCandidate = `${order.client?.name}\n${this.getProducts(
        order.products
      )}\n${order.weight}`;
    }
    if (trip) {
      this.parkedTripOrder = trip;
      const lastCreatedEvent = sortBy(trip.tripEvents, {
        parameter: { property: 'createdAt' },
        direction: 'desc',
      })[0];
      this.parkAction = PARK;
      this.parkedMessage = PARKED_MESSAGE;
      this.parkedText = PARKED_TEXT;
      this.parkedCandidate = `${
        lastCreatedEvent.client?.name ?? '-'
      }\n${this.getProducts(lastCreatedEvent.products)}\n${
        lastCreatedEvent.weight
      }`;
    }
  }

  getProducts(products?: Product[]) {
    if (products && products.length > 1) {
      return products[0].name + '...';
    }

    if (products && products.length === 1) {
      return products[0].name;
    }
    return '';
  }

  combineParkedTripsOrders() {
    combineLatest({
      trips1: this.tripRepo.parkedTrips$(this.userId!),
      trips2: this.ordersRepo.parkedOrders$(this.userId!),
    }).subscribe((x) => {
      this.parked = this.uniqueList([...x.trips1, ...(x.trips2 as Trip[])]);
    });
  }

  openParked() {
    this.combineParkedTripsOrders();
    if (this.parked) {
      this.tripsForModal = this.parked;
    }
  }

  uniqueList(list: Trip[]): Trip[] {
    return Array.from(new Set(list.map((item) => item.id)))
      .map((id) => list.find((item) => item.id === id))
      .filter((item) => item !== undefined) as Trip[];
  }

  parkTrip($event: string) {
    let order = this.ordersRepo.getActiveOrder();
    let trip = this.tripRepo.getActiveTrip();
    let now = new Date();
    if (order) {
      this.ordersRepo.setActiveId(null);
      order.parkedAt = now;
      this.ordersRepo.upsertOrder(order);
      this.ordersService
        .parkOrder(order.id, now.toUTCString())
        .subscribe(() => {
          this.ordersService.loadParkedForCurrentDriver().subscribe();
          if (this.isOnlineService.isOnline()) {
            forkJoin([
              this.dayService.loadCurrent(),
              this.ordersService.loadForCurrentDriver(),
              this.tripService.loadCurrent(),
              this.ordersService.loadCurrent(),
              this.authService.loadSettings(),
            ]).subscribe();
          }
          this.router.navigate(['go']);
        });
    }
    if (trip) {
      this.tripRepo.setActiveId(null);
      trip.parkedAt = now;
      this.tripRepo.upsertTrip(trip);
      if (this.parked?.every((e) => e.id !== trip?.id)) {
        this.parked.push(trip);
      }

      this.tripsService.parkTrip(trip.id, now.toUTCString()).subscribe(() => {
        this.ordersService.loadParkedForCurrentDriver().subscribe();
        if (this.isOnlineService.isOnline()) {
          forkJoin([
            this.dayService.loadCurrent(),
            this.ordersService.loadForCurrentDriver(),
            this.tripService.loadCurrent(),
            this.ordersService.loadCurrent(),
            this.authService.loadSettings(),
          ]).subscribe();
        }
        this.router.navigate(['go']);
      });
    }
  }
  resumeTrip($event: string) {
    let order = this.ordersRepo.getOrder($event);
    let trip = this.tripRepo.getTrip($event);
    let now = new Date();
    if (order && order.isOrder) {
      order.resumedAt = now;
      this.ordersRepo.setActiveId(order.id);
      this.ordersRepo.upsertOrder(order);
      this.ordersService
        .resumeOrder($event, now.toUTCString())
        .subscribe(() => {
          if (this.isOnlineService.isOnline()) {
            this.ordersService.loadCurrent().subscribe(() => {
              this.router.navigate(['go']);
            });
          }
          this.getActive();
          this.router.navigate(['go']);
        });
    }
    if (trip && !trip.isOrder) {
      trip.resumedAt = now;
      trip.parkedAt = undefined;
      this.tripRepo.setActiveId(trip.id);
      this.tripRepo.upsertTrip(trip);
      this.trip = trip;
      this.tripService.resumeTrip($event, now.toUTCString()).subscribe(() => {
        if (this.isOnlineService.isOnline()) {
          this.tripService.loadCurrent().subscribe(() => {
            this.router.navigate(['go']);
          });
        }
        this.getActive();
        this.router.navigate(['go']);
      });
    }
  }

  async startDay() {
    this.isClicked = true;
    try {
      const observable = await this.dayService.start();
      observable.subscribe({
        error: (data) => {
          if (data.error === 'DayStart has already happened') {
            this.doubleActionError = DAY_START_ERROR;
          }
          if (data.error === 'Workday has already started') {
            this.doubleActionError = WORKDAY_ERROR;
          }
        },
      });
    } catch (error) {
      console.error('Error starting the day:', error);
    }
  }

  reload() {
    window.location.reload();
  }

  endDayWarning: boolean = false;

  stopDay(workday: Workday, forceStop: boolean = false) {
    if (this.parked && this.parked?.length >= 1 && !forceStop) {
      this.endDayWarning = true;
      return;
    }
    this.dayService.stop(workday).subscribe({
      error: (data) => {
        if (data.error === 'DayEnd has already happened') {
          this.doubleActionError = DAY_END_ERROR;
        }
      },
    });
    this.isClicked = false;
  }

  addNote($event: string) {
    if (this.trip) {
      let lastCreatedEvent = sortBy(
        this.trip.tripEvents.filter((a: TripEvent) => a.type !== 'Weighing'),
        { parameter: { property: 'createdAt' }, direction: 'desc' }
      )[0] as TripEvent;
      if (lastCreatedEvent && $event) {
        lastCreatedEvent.note = $event;
        this.tripService.addNote(lastCreatedEvent.id, $event).subscribe();
      }
    }
    if (this.order) {
      let lastCreatedEvent = sortBy(
        this.order.tripEvents.filter((a: TripEvent) => a.type !== 'Weighing'),
        { parameter: { property: 'createdAt' }, direction: 'desc' }
      )[0] as TripEvent;
      if (lastCreatedEvent && $event) {
        lastCreatedEvent.note = $event;
        this.tripService.addNote(lastCreatedEvent.id, $event).subscribe();
      }
    }
  }
  toCorrectDate(date?: Date | null) {
    if (date) {
      return this.ngDay.dayjs
        .utc(date)
        .local()
        .format('H:mm DD/MM/YYYY')
        .slice(0, 16);
    }
    return '';
  }

  rejectOrder($event: string) {
    localStorage.removeItem('currentChildren');
    const familyByParent = this.orderFamilies?.find(
      (i) => i.parentId === $event
    );
    this.ordersService.updateRejected($event, this.userId).subscribe({
      error: (data) => {
        if (data.error === 'Trip has been already rejected') {
          this.doubleActionError = ORDER_REJECT_ERROR;
        }
      },
    });
    if (
      familyByParent &&
      familyByParent.children &&
      familyByParent.children.length > 0
    ) {
      familyByParent.children.forEach((a) => {
        this.ordersService.updateRejected(a.id, this.userId).subscribe({
          error: (data) => {
            if (data.error === 'Trip has been already rejected') {
              this.doubleActionError = ORDER_REJECT_ERROR;
            }
          },
        });
      });
    }
    this.combineParkedTripsOrders();
    if (!this.ordersRepo.getActiveOrder()) {
      this.router.navigate(['go']);
    } else {
      this.ordersRepo.remove($event);
      this.router.navigate(['go']);
    }
  }

  getOrderToRejectModal($event: string) {
    this.rejectButton = REJECT_BUTTON;
    this.rejectMessage = REJECT_MESSAGE;
    this.rejectText = REJECT_TEXT;
    this.ordersRepo
      .one($event)
      .subscribe((x) => (this.deleteConfirmation = x!));
  }

  groupRelatedOrders(orders: Order[]): OrderFamily[] {
    let parents = orders.filter((x) => !x.parentId);

    let families = new Array<OrderFamily>();

    let common = orders.filter((x) => x.parentId);
    parents.forEach((x) => {
      let list = new Array<Order>();
      let children = this.findChild(x.id, common, list);
      let family = { parentId: x.id, children: children } as OrderFamily;
      families?.push(family);
    });

    return families;
  }

  findChild(id: string, orders: Order[], list: Order[]) {
    let child = orders.find((x) => x.parentId === id);
    if (orders.length !== 0) {
      if (child) {
        let index = orders.indexOf(child);
        list.push(child);
        id = child.id;
        orders.splice(index, 1);
        this.findChild(id, orders, list);
      }
      return list;
    }
    return list;
  }

  updatePause(day: Workday, pause: WorkdayEvent) {
    this.dayService.updatePause(day, pause).subscribe((x) => {});
  }

  navigateToOrderAction(id: string, action: string) {
    var urlPart;
    switch (action) {
      case 'TripStart': {
        urlPart = 'start';
        break;
      }
      case 'Load': {
        urlPart = 'load';
        break;
      }
      case 'Unload': {
        urlPart = 'unload';
        break;
      }
      case 'TripEnd': {
        urlPart = 'stop';
        break;
      }
    }
    if (urlPart) {
      this.router.navigate([`order/${id}/${urlPart}`]);
    }
  }

  async addWeighing($event: Partial<TripEvent>) {
    this.submitErrors = null;
    let submitObservable: Observable<any> | null = null;
    try {
      const coordinates = await this.locationService.getPosition();
      $event = {
        ...$event,
        ...coordinates,
      };
    } catch {
      this.submitErrors = [LOCATION_PERMISSION_ERROR];
      return;
    }
    const trip = this.tripRepo.getActiveTrip();
    const settings = this.authRepo.getActiveSettings();
    if (!settings || !settings.vehicleId) {
      this.submitErrors = [VEHICLE_ERROR];
      return;
    }
    if (trip) {
      submitObservable = this.tripService.weighing(
        trip,
        $event,
        $event.tripFiles
      );
      submitObservable.subscribe({
        next: (data) => {
          this.trip?.tripEvents.push(data.tripEvents[0]);
        },
        error: (data: string[] | null) => (this.submitErrors = data),
      });
    }
  }

  async addWeighingForOrder($event: Partial<TripEvent>) {
    this.submitErrors = null;
    let submitObservable: Observable<any> | null = null;
    try {
      const coordinates = await this.locationService.getPosition();
      $event = {
        ...$event,
        ...coordinates,
      };
    } catch {
      this.submitErrors = [LOCATION_PERMISSION_ERROR];
      return;
    }
    const order = this.ordersRepo.getActiveOrder();
    const settings = this.authRepo.getActiveSettings();
    if (!settings || !settings.vehicleId) {
      this.submitErrors = [VEHICLE_ERROR];
      return;
    }
    if (order) {
      submitObservable = this.ordersService.weighing(
        order,
        $event,
        $event.tripFiles
      );
      submitObservable.subscribe({
        next: (data) => {
          this.order.tripEvents.push(data.tripEvents[0]);
        },
        error: (data: string[] | null) => (this.submitErrors = data),
      });
    }
  }

  takePhotos($event: File[]) {
    let id =
      this.tripRepo
        .getActiveTrip()
        ?.tripEvents.find((x) => x.type === 'TripStart')?.id ||
      this.ordersRepo
        .getActiveOrder()
        ?.tripEvents.find((x) => x.type === 'TripStart')?.id ||
      '';
    if (id) {
      let submitObservable: any = this.tripService.takephoto(id, $event);
      if (submitObservable) {
        submitObservable.subscribe({
          next: (data: TripEvent) => {
            this.updateData(data, this.order);
          },
          error: (data: string[] | null) => (this.submitErrors = data),
        });
      }
    }
  }

  updateData(tripEvent: TripEvent, isForOrder: boolean) {
    if (isForOrder || this.order) {
      this.order.tripEvents.forEach((x: any) => {
        if (x.type == 'TripStart') {
          x.tripPhotos = tripEvent.tripPhotos;
        }
      });
    } else if (this.trip) {
      this.trip.tripEvents.forEach((x) => {
        if (x.type == 'TripStart') {
          x.tripPhotos = tripEvent.tripPhotos;
        }
      });
    }
  }
}
