<div
  class="modal fade"
  bsModal
  [config]="{ show: true }"
  id="notesModal"
  tabindex="-1"
  #notesModal
  aria-hidden="true"
  (onHidden)="closeModal.emit()"
>
  <div class="modal-dialog modal-dialog-centered noteModal">
    <div class="modal-content bg-lights border-0">
      <div class="modal-header">
        <h6 class="modal-title" id="notesModalLabel" i18n="Notes">
          Information
        </h6>
      </div>
      <div class="modal-body p-0">
        <div>
          <table>
            <tr>
              <th i18n="Date label">Date</th>
              <th i18n="Type">Type</th>
              <th i18n="Note">Note</th>
              <th i18n="Photos">Photos</th>
            </tr>
            <tr *ngIf="order">
              <td></td>
              <td>Før start</td>
              <td>{{ order.comment }}</td>

              <td class="text-breaks h-100">
                <div class="d-flex flex-wrap">
                  <div
                    *ngFor="let photo of order.commentPhotos; let i = index"
                    class="me-1 mb-1"
                  >
                    <img
                      (click)="openImageModal(i)"
                      class=""
                      width="40"
                      height="35"
                      src="{{ photo.imagePath }}"
                    />
                  </div>
                </div>
              </td>
            </tr>
            <tr *ngFor="let tripEvent of filteredTripEvents">
              <td>{{ tripEvent.createdAt | date : "dd/MM/yyyy HH:mm" }}</td>
              <td>{{ getDanishEventType(tripEvent.type) }}</td>
              <td>
                <div *ngIf="check(tripEvent.id); else elseBlock">
                  <div class="text-break">
                    {{ tripEvent.note?.slice(0, 160) }}
                    <span *ngIf="tripEvent.note && tripEvent.note!.length > 160"
                      >...
                      <span
                        class="showAll ps-1"
                        (click)="manageOpenedEvents(tripEvent.id)"
                        i18n="Show all"
                        >Show all</span
                      ></span
                    >
                  </div>
                </div>
                <ng-template #elseBlock
                  ><div class="text-break">
                    {{ tripEvent.note }}
                    <span
                      class="showAll ps-1"
                      (click)="manageOpenedEvents(tripEvent.id)"
                      i18n="Hide"
                      >Hide</span
                    >
                  </div>
                </ng-template>
              </td>
              <td class="text-breaks h-100">
                <div class="d-flex flex-wrap">
                  <div
                    *ngFor="let photo of tripEvent.tripPhotos; let i = index"
                    class="me-1 mb-1"
                  >
                    <img
                      (click)="
                        openImageModalTripEvents(tripEvent.tripPhotos!, i)
                      "
                      class=""
                      width="40"
                      height="35"
                      src="{{ photo.url }}"
                    />
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button
          class="btn btn-secondary"
          (click)="closeModal.emit()"
          i18n="@@close"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
<app-image-slider
  *ngIf="images"
  [moveEnabled]="moveEnabled"
  [urls]="images"
  (cancel)="closeImageSlider()"
  (moveToStart)="moveToStart($event)"
  (moveToWeight)="moveToWeight($event)"
></app-image-slider>
