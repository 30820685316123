import { Injectable } from '@angular/core';
import { BaseRepository } from './abstract/base.repository';
import { WorkdayEvent } from './workdays.repository';

export interface WorkDayEventInfo {
  driverId: string;
  tripId?: string
}

@Injectable({ providedIn: 'root' })
export class WorkdayEventsRepository extends BaseRepository<WorkdayEvent> {

  
  constructor() {
    super(
      'workdayevents', 
      [ { label: 'Date', property: 'eventTime' } ],
      { parameter: { property: 'eventTime' }, direction: 'desc' }
    );
  }

}