<ng-container *ngIf="!isLoading">
  <ng-container *ngFor="let trip of trips">
    <div class="mt-1 card shadow-sm bg-secondary text-white">
      <div class="card-body row ms-2 me-2 d-flex align-items-center">
        <div class="p-0 col-sm col-12">
          <h5>{{ getTripEvent(trip, "asc").client?.name }}</h5>
          <h5>{{ getTripEvent(trip, "asc").comment }}</h5>
          <h5 *ngIf="trip">
            {{
              trip.resumedAt
                ? toCorrectDate(trip.resumedAt)
                : toCorrectDate(getTripEvent(trip, "asc").eventTime, "day")
            }}
          </h5>
          <h5>
            <span *ngIf="trip">{{
              trip.resumedAt
                ? toCorrectDate(trip.resumedAt, "time")
                : toCorrectDate(getTripEvent(trip, "asc").eventTime, "time")
            }}</span>
            -
            <span *ngIf="trip && trip.parkedAt">{{
              toCorrectDate(trip.parkedAt, "time")
            }}</span>
          </h5>
        </div>
        <div
          class="p-0 col-sm col-12 d-flex flex-column align-items-center justify-content-center"
        >
          <div class="d-flex">
            <h1 class="me-2">Info</h1>
            <h1
              class="mutable hoverable"
              (click)="router.navigate(['parkedtripinfo/' + trip.id])"
            >
              <i class="bi bi-info-circle"></i>
            </h1>
          </div>

          <div *ngIf="findChild(trip.id)" class="ms-2 me-2 d-flex scroll">
            <div
              class="mutable hoverable"
              *ngFor="let id of findChilds(trip.id)"
            >
              <i
                (click)="router.navigate(['orderinfo/' + id])"
                class="bi bi-link-45deg linked-ico"
              ></i>
            </div>
          </div>
        </div>
        <div
          class="p-0 col-sm col-12 d-flex align-items-center justify-content-center justify-content-around"
        >
          <button
            (click)="resume.emit(trip.id)"
            style="flex: none"
            [class.isDisabled]="!userSettings?.vehicleId"
            class="but card shadow-sm me-2 bg-success text-white d-flex justify-content-center align-items-center"
          >
            <h4 class="mt-1 mb-1" i18n="@@resumeOrder">
              Resume <i class="bi bi-play-fill"></i>
            </h4>
          </button>
          <button
            *ngIf="trip.isOrder"
            (click)="reject.emit(trip.id)"
            class="card shadow-sm bg-danger text-white but d-flex justify-content-center align-items-center"
            style="flex: none"
          >
            <h4 class="mt-1 mb-1" i18n="@@rejectOrder">
              Reject <i class="bi bi-x-diamond-fill"></i>
            </h4>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
