import { Injectable } from '@angular/core';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';

export interface Accessory {
  id: string;
  name: string;
  description?: string;
  isBeingUsed: boolean;
  createdAt: Date;
  deletedAt?: string;
}

export const accessorysSortOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'name' },
  {
    label: $localize`:Sort label Description:Description`,
    property: 'description',
  },
  { label: $localize`:Sort label Date:Date`, property: 'createdAt' },
];

@Injectable({ providedIn: 'root' })
export class AccessoriesRepository extends BaseRepository<Accessory> {
  constructor() {
    super('accessories', accessorysSortOptions);
  }
}
