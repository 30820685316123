import { Pipe, PipeTransform } from '@angular/core';

export interface SortState {
  parameter: SortOption;
  direction: 'asc' | 'desc';
}

export interface SortOption {
  property: string;
  label?: string;
}

function compareStrings(a: string, b: string) {
  return a.localeCompare(b, undefined, {sensitivity: 'base'});
}

function compareDefault(a: any, b: any) {
  if (a === b) {
    return 0;
  }
  return a < b ? -1 : 1;
}

export function sortBy<T>(value: T[], sort: SortState): T[] {
  let multiplier = 1;
  if (sort.direction === 'desc') {
    multiplier = -1;
  }
  const sorted = [ ...value ].sort((a: any, b: any) => {
    let aProp = a[sort.parameter.property];
    let bProp = b[sort.parameter.property];
    if (typeof(aProp) === 'string' && typeof(bProp) === 'string') {
      return multiplier * compareStrings(aProp, bProp);
    }
    return multiplier * compareDefault(aProp, bProp);
  });
  return sorted;
}

@Pipe({ name: 'sort' })
export class SortPipe implements PipeTransform {

  transform(value: any[] | null, sort: SortState): any[] | null {
    if(!sort || !value){
        return value;
    }
    return sortBy(value, sort);
  }
}
