import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as dayjs from 'dayjs';
import { Observable, Subscription } from 'rxjs';
import { sortBy } from 'src/app/modules/shared/pipes/sort.pipe';
import { ConnectionService } from 'src/app/modules/shared/services/connection.service';
import { DayjsService } from 'src/app/modules/shared/services/dayjs.service';
import { LocationService } from 'src/app/modules/shared/services/location.service';
import { RequestsResolverRunner } from 'src/app/modules/shared/services/requests-resolver';
import { AuthRepository } from 'src/app/state/auth.repository';
import { OrdersRepository } from 'src/app/state/orders.repository';
import { OrdersService } from 'src/app/state/orders.service';
import {
  Trip,
  TripEvent,
  TripsRepository,
} from 'src/app/state/trips.repository';
import { TripsService } from 'src/app/state/trips.service';

@Component({
  selector: 'app-trip-navigation-page',
  templateUrl: './trip-navigation-page.component.html',
  styleUrls: ['./trip-navigation-page.component.scss'],
})
export class TripNavigationPageComponent {
  dataSubscription?: Subscription;
  submitErrors: string[] | null = null;
  contactPerson = '';
  id?: string | null = null;
  trip: Trip | null = null;
  tripSub?: Subscription;
  orderSub?: Subscription;
  eventid: string | undefined;
  isAdmin: 'true' | 'false' = 'false';
  trip$?: Observable<Trip | undefined> | null;
  tripId?: string;
  activeTrip: Trip | null = null;
  activeTripVehicle: string | null = null;
  activeTripTrailer: string | null = null;
  lastActiveEvent: TripEvent | null = null;
  startEvent: TripEvent | null = null;

  isActionResolved = false;

  images: string[] = [];
  imageIndex: number = 0;

  delivery: any;
  picUp: any;
  settings: any;
  constructor(
    public isOnlineService: ConnectionService,
    public authRepo: AuthRepository,
    private route: ActivatedRoute,
    private tripsService: TripsService,
    public tripsRepo: TripsRepository,
    private ordersService: OrdersService,
    public ordersRepo: OrdersRepository,
    public ngDay: DayjsService,
    private offlineResolver: RequestsResolverRunner,
    public router: Router
  ) {
    authRepo.settings$.subscribe((a) => {
      this.settings = a;
    });
    this.route.paramMap.subscribe((x) => (this.id = x.get('id')));
    if (this.id) {
      this.tripSub = this.tripsRepo.one(this.id).subscribe((a) => {
        if (a) {
          this.trip = a;
        }
        if (!a && this.id) {
          this.orderSub = this.ordersRepo.one(this.id).subscribe((x) => {
            if (x) {
              this.trip = x as Trip;
            }
          });
        }
        if (this.trip) {
          this.lastActiveEvent = sortBy(this.trip.tripEvents, {
            parameter: { property: 'createdAt' },
            direction: 'desc',
          })[0];
          this.startEvent = this.lastActiveEvent = sortBy(
            this.trip.tripEvents,
            { parameter: { property: 'createdAt' }, direction: 'asc' }
          )[0];
        }
      });
    }
  }
  ngOnDestroy(): void {
    this.dataSubscription?.unsubscribe();
    this.tripSub?.unsubscribe();
    this.orderSub?.unsubscribe();
  }

  ngOnInit(): void {
    if (this.isOnlineService.isOnline() && this.id) {
      this.tripsService.loadOne(this.id).subscribe({
        error: (err) => {
          if (this.id) {
            this.ordersService.loadOne(this.id).subscribe();
          }
        },
      });
    }
    this.route.paramMap.subscribe((x) => (this.id = x.get('id')));
    if (this.id) {
      this.tripSub = this.tripsRepo.one(this.id).subscribe((a) => {
        if (a) {
          this.trip = a;
          if (!a && this.id) {
            this.orderSub = this.ordersRepo.one(this.id).subscribe((x) => {
              if (x) {
                this.trip = x as Trip;
              }
            });
          }
        }
        if (this.trip) {
          this.lastActiveEvent = sortBy(this.trip.tripEvents, {
            parameter: { property: 'createdAt' },
            direction: 'desc',
          })[0];
          this.startEvent = this.lastActiveEvent = sortBy(
            this.trip.tripEvents,
            { parameter: { property: 'createdAt' }, direction: 'asc' }
          )[0];
        }
      });
    }
    this.dataSubscription = this.route.paramMap
      .pipe(this.offlineResolver.useUnsyncedChanges())
      .subscribe(() => {
        let trip = this.tripsRepo.getActiveTrip();
        if (!trip) {
          trip = this.ordersRepo.getActiveOrder() as Trip;
        }
        this.mergeNamePlate(trip);
        this.isActionResolved = true;
      });
  }

  getProducts() {
    let str = '';
    if (this.lastActiveEvent?.products)
      var length = this.lastActiveEvent?.products.length ?? 0;
    this.lastActiveEvent?.products!.forEach((element, index) => {
      str += element.name;
      if (length - 1 !== index) {
        str += ', ';
      }
    });
    return str;
  }

  mergeNamePlate(trip?: Trip | null) {
    if (!trip) {
      return;
    }
    this.activeTrip = trip;
    this.activeTripVehicle =
      trip && trip.vehicle
        ? `[${trip.vehicle.numberplate}] ${trip.vehicle.name}`
        : null;
    this.activeTripTrailer =
      trip && trip.trailer
        ? `[${trip.trailer.numberplate}] ${trip.trailer.name}`
        : null;
  }

  getActive() {
    this.tripSub = this.tripsRepo.activeTrip$.subscribe((a) => {
      if (a) {
        this.trip = a;
      }
    });
  }

  convertDateFormat(input: string): string {
    const regex = /^(\d{2}-\d{2}-\d{4}) (\d{2}:\d{2}:\d{2}( [AP]M)?)$/;

    if (regex.test(input)) {
      const formattedString = dayjs(input, 'DD-MM-YYYY HH:mm:ss').format(
        'M/DD/YYYY HH:mm:ss'
      );
      return formattedString.toString();
    }
    return input;
  }
  toCorrectDate(date: Date, type?: string) {
    if (type === 'day') {
      return this.ngDay.dayjs
        .utc(date)
        .local()
        .format('DD/MM/YYYY')
        .slice(0, 16);
    }
    if (type === 'time') {
      return this.ngDay.dayjs.utc(date).local().format('H:mm').slice(0, 16);
    } else {
      return this.ngDay.dayjs
        .utc(date)
        .local()
        .format('H:mm DD/MM/YYYY')
        .slice(0, 16);
    }
  }

  toDate(str: string) {
    return this.ngDay.dayjs(str).local().format('H:mm DD/MM/YYYY').slice(0, 16);
  }

  resumeTrip(id?: string) {
    if (!this.settings?.vehicleId) {
      return;
    }
    if (id) {
      let order = this.ordersRepo.getOrder(id);
      let trip = this.tripsRepo.getTrip(id);
      let now = new Date();
      if (order && order.isOrder) {
        order.resumedAt = now;
        this.ordersRepo.setActiveId(order.id);
        this.ordersRepo.upsertOrder(order);
        this.ordersService.resumeOrder(id, now.toUTCString()).subscribe(() => {
          if (this.isOnlineService.isOnline()) {
            this.ordersService.loadCurrent().subscribe(() => {
              this.router.navigate(['go']);
            });
          }
          this.getActive();
          this.router.navigate(['go']);
        });
      }
      if (trip && !trip.isOrder) {
        trip.resumedAt = now;
        trip.parkedAt = undefined;
        this.tripsRepo.setActiveId(trip.id);
        this.tripsRepo.upsertTrip(trip);
        this.trip = trip;
        this.tripsService.resumeTrip(id, now.toUTCString()).subscribe(() => {
          if (this.isOnlineService.isOnline()) {
            this.tripsService.loadCurrent().subscribe(() => {
              this.router.navigate(['go']);
            });
          }
          this.getActive();
          this.router.navigate(['go']);
        });
      }
    }
  }

  openImageModal(index: number) {
    if (this.trip?.commentPhotos) {
      this.images = this.trip?.commentPhotos?.map((x) => x.imagePath);
      this.imageIndex = index;
    }
  }

  closeImageSlider() {
    this.images = [];
    this.imageIndex = 0;
  }
}
