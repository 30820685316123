import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthRepository } from 'src/app/state/auth.repository';
import { Company } from 'src/app/state/companies.repository';
import { TenantFeatures } from 'src/app/state/feature.repository';

@Component({
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.scss']
})
export class CompanyFormComponent implements OnInit {
  TenantFeatures = TenantFeatures;
  @Input() editedCompany: Company | null = null;
  form: UntypedFormGroup | null = null;

  @Output() companySubmit = new EventEmitter<Partial<Company>>();

  constructor(private formBuilder: UntypedFormBuilder,
    public authRepo: AuthRepository) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [this.editedCompany?.name, Validators.required],
      idNumber: [this.editedCompany?.idNumber, Validators.required],
      email: [this.editedCompany?.email, Validators.email],
      phoneNumber: [this.editedCompany?.phoneNumber],
      address: [this.editedCompany?.address],
      zipCode: [this.editedCompany?.zipCode],
      city: [this.editedCompany?.city],
      canBeVendor: [this.editedCompany?.canBeVendor || false],
      canBeClient: [this.editedCompany?.canBeClient || false]
    });
  }

  onSubmit() {
    if (this.form && !this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }    

    this.authRepo.hasFeature$(TenantFeatures.Economic).subscribe(x => {
      this.companySubmit.emit({
        name: this.form?.value?.name,
        idNumber: this.form?.value?.idNumber,
        email: this.form?.value?.email,
        phoneNumber: this.form?.value?.phoneNumber,
        address: this.form?.value?.address,
        zipCode: this.form?.value?.zipCode,
        city: this.form?.value?.city,
        canBeVendor: x ? x : this.form?.value?.canBeVendor,
        canBeClient: this.form?.value?.canBeClient
      });
    });

    
  }


}
