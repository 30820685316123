<div class="row mt-1">
  <div class="col-12">
    <div class="card shadow-md mb-4">
      <ul class="list-group list-group-flush">
        <app-product-card
          *ngFor="let product of eproducts"
          class="list-group-item"
          [title]="product?.name"
          [subtitle]="product?.description"
          [type]="product?.priceType?.name?.replace('Minutter', 'Timer')"
          icon="bi-box-seam"
          [date]="product.createdAt"
          [id]="product.id"
          routerLinkBase="/eproducts"
        ></app-product-card>
      </ul>
    </div>
  </div>
</div>
