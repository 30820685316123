import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {
  @Input() title?: string | null = null;
  @Input() type?: string | null = null;
  @Input() description?: string;

  @Input() subtitle?: string | null = null;
  @Input() icon?: string;
  @Input() date?: Date;
  @Input() routerLinkBase!: string;
  
  @Input() id!: string;
  @Input() isDeleteDisabled = true;
  @Input() isEditDisabled = false;
  @Input() returnUrl?: string;

  

  constructor() { }

  ngOnInit(): void {    
  }
}
