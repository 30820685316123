import { Injectable } from '@angular/core';
import { Store, createState, withProps, select } from '@ngneat/elf';
import { withEntities, selectAllEntities, upsertEntities, selectEntity, deleteEntities } from '@ngneat/elf-entities';
import { createRequestsCacheOperator, createRequestsStatusOperator, selectRequestStatus, updateRequestCache, updateRequestsStatus, withRequestsCache, withRequestsStatus } from '@ngneat/elf-requests';
import { combineLatest, map } from 'rxjs';
import { sortBy, SortOption, SortState } from 'src/app/modules/shared/pipes/sort.pipe';
import { Feature } from './feature.repository';

export interface Tenant {
  id: string;
  name: string;
  createdAt: Date;
  countOfUsers: number;
  image: File | null;
  imagePath: string;
  cvr: string;
  address: string;
  mainMail: string;
  maxAdministrators: number;
  maxDrivers: number;
  remarks: string;
  layoutNumber: number;
  phoneNumber: string;
  appSecretToken: string;
  agreementGrantToken: string;
  featureIds: string[];
  features: Feature[];
}

export const tenantSortOptions: SortOption[] = [
  { label: $localize `:Sort label Name:Name`, property: 'name' },
  { label: $localize `:Sort label Number of users:Number of users`, property: 'countOfUsers' },
  { label: $localize `:Sort label Date:Date`, property: 'createdAt' }
];

export interface TenantProps {
  sortBy: SortState;
}

const { state, config } = createState(
  withProps<TenantProps>({ 
    sortBy: { parameter: tenantSortOptions[0], direction: 'asc'} 
  }),
  withEntities<Tenant>(),
  withRequestsCache(), 
  withRequestsStatus()
);
const store = new Store({ name: 'tenants', state, config });
export const trackTenantRequestsStatus = createRequestsStatusOperator(store);
export const skipWhileTenantsCached = createRequestsCacheOperator(store);

@Injectable({ providedIn: 'root' })
export class TenantsRepository {
  name = store.name;

  sort$ = store.pipe(select(state => state.sortBy));
  tenants$ = store.pipe(selectAllEntities());
  tenantsSorted$ = combineLatest([this.tenants$, this.sort$]).pipe(
    map(([list, sort]) => sortBy(list, sort))
  );
  isLoading$ = store.pipe(
    selectRequestStatus(this.name),
    map(x => x.value === 'pending')
  );
  isLoadingOne$ = (id: Tenant['id']) => store.pipe(
    selectRequestStatus(id),
    map(x => x.value === 'pending')
  );

  tenant = (id: string) => store.pipe(selectEntity(id));
  status = (id: string) => store.pipe(selectRequestStatus(id, { groupKey: store.name }));

  setSort(sortBy: SortState) {
    store.update((state) => ({
      ...state,
      sortBy
    }));
  }

  setTenants(posts: Tenant[]) {
    store.update(
      updateRequestCache(store.name),
      upsertEntities(posts),
      updateRequestsStatus([store.name], 'success')
    );
  }

  upsertTenant(post: Tenant) {
    store.update(
      updateRequestCache(post.id),
      upsertEntities([post]),
      updateRequestsStatus([post.id], 'success')
    );
  }

  remove(id: string): void {
      store.update(
        updateRequestCache(id),
        deleteEntities(id),
        updateRequestsStatus([id], 'success')
    );
}
}
