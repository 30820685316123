import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Order } from 'src/app/state/orders.repository';
import { Trip, TripEvent } from 'src/app/state/trips.repository';

@Component({
  selector: 'app-end-order-form',
  templateUrl: './end-order-form.component.html',
  styleUrls: ['./end-order-form.component.scss']
})
export class EndOrderFormComponent implements OnInit {
  @Input() set currentOrder(value: Order | null) {
    this.order = value;
    this.tripStartEvent = value?.tripEvents
    .find(x => x.type === 'TripStart');
    this.tripStart = value?.tripEvents
      .find(x => x.type === 'TripStart')
      ?.eventTime || null;
  }
  @Input() isLoading: boolean | null = null;
  @Output() endOrder = new EventEmitter<void>();
  @Output() unload = new EventEmitter<void>();
  @Output() load = new EventEmitter<void>();
  @Output() takePhotos = new EventEmitter<File[]>();
  @Output() takeWeighingPhotos = new EventEmitter<Partial<TripEvent>>();
  innerWidth = 0;
  
  order?: Order | null;
  tripStartEvent?: TripEvent | null;
  tripStart: Date | null = null;

  constructor() { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
  addImage($event: any) {    
    let files = $event.target.files;
    if (files) {      
      this.takePhotos.emit(files);
    }
  }

  addWeighingImage($event: any) {
    let files = $event.target.files;
    if (files) {
      this.takeWeighingPhotos.emit({clientId: this.tripStartEvent?.clientId ,tripFiles: files});
    }
  }

}
