import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { Column } from 'src/app/state/columns.repository';
import { ColumnsService } from 'src/app/state/columns.service';
import { TemplatesRepository, templatesSortOptions } from 'src/app/state/templates.repository';
import { TemplatesService } from 'src/app/state/templates.service';

@Component({
  selector: 'app-templates-page',
  templateUrl: './templates-page.component.html',
  styleUrls: ['./templates-page.component.scss']
})
export class TemplatesPageComponent {
  sortOptions = templatesSortOptions;
  
  constructor(
    public service: TemplatesService,
    public repo: TemplatesRepository    
  ) { }

  ngOnInit(): void {
    this.service.reloadPage().subscribe();
  }
  
}
