import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth.guard';
import { ApphomelayoutComponent } from '../modules/core/layouts/apphomelayout/apphomelayout.component';
import { AppinnerlayoutComponent } from '../modules/core/layouts/appinnerlayout/appinnerlayout.component';
import { AuthlayoutComponent } from '../modules/core/layouts/authlayout/authlayout.component';
import { ForgetpasswordComponent } from '../modules/core/pages/forgetpassword/forgetpassword.component';
import { PagenotauthorizedComponent } from '../modules/core/pages/pagenotauthorized/pagenotauthorized.component';
import { PagenotfoundComponent } from '../modules/core/pages/pagenotfound/pagenotfound.component';
import { ProfileEditComponent } from '../modules/core/pages/profile-edit/profile-edit.component';
import { ProfileComponent } from '../modules/core/pages/profile/profile.component';
import { ResetpasswordComponent } from '../modules/core/pages/resetpassword/resetpassword.component';
import { SigninComponent } from '../modules/core/pages/signin/signin.component';
import { TenantsCreatePageComponent } from '../modules/core/pages/tenants-create-page/tenants-create-page.component';
import { TenantsPageComponent } from '../modules/core/pages/tenants-page/tenants-page.component';
import { UsersCreatePageComponent } from '../modules/core/pages/users-create-page/users-create-page.component';
import { UsersListPageComponent } from '../modules/core/pages/users-list-page/users-list-page.component';
import { UserRoles } from '../state/auth.repository';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: '',
    component: AuthlayoutComponent,
    children: [
      {
          path: 'signin',
          component: SigninComponent,
      },
      {
          path: 'forgotpassword',
          component: ForgetpasswordComponent,
      },
      {
          path: 'resetpassword',
          component: ResetpasswordComponent,
      },
    ]
  },
  {
    path: '',
    component: ApphomelayoutComponent,
    canActivateChild: [AuthGuard],

    children: [
      {
        path:'profile',
        component: ProfileComponent
      },
      {
        path: 'tenants',
        component: TenantsPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: UserRoles.Superamin }
      },
      {
        path: 'users',
        component: UsersListPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [ UserRoles.Superamin, UserRoles.TenantAdmin ] }
      }
    ]
  },
  {
    path: '',
    component: AppinnerlayoutComponent,
    canActivateChild: [AuthGuard],

    children: [
      {
        path: 'tenants/:id',
        component: TenantsCreatePageComponent,
        canActivate: [AuthGuard],
        data: { authorize: UserRoles.Superamin }
      },
      {
        path: 'users/:id',
        component: UsersCreatePageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] }
      },
      {
        path: 'profile/edit',
        component: ProfileEditComponent
      },
    ]
  },
  { path: 'unauthorized', component: PagenotauthorizedComponent },
  { path: 'pagenotfound', component: PagenotfoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
