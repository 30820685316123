import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TripPhotoUrlDto } from 'src/app/state/models/trip/TripPhotoUrlDto';

@Component({
  selector: 'app-trip-image-slider',
  templateUrl: './trip-image-slider.component.html',
  styleUrls: ['./trip-image-slider.component.scss'],
})
export class TripImageSliderComponent {
  @Input() photoUrls: TripPhotoUrlDto[] | undefined = undefined;
  @Input() slideIndex: number = 0;
  @Input() type: 'Weigh' | 'Usual' = 'Usual';

  @Output() cancel = new EventEmitter<void>();
  @Output() movePhoto = new EventEmitter<TripPhotoUrlDto>();

  @Input() moveEnabled: boolean = true;

  constructor() {}

  ngOnInit(): void {}

  change() {
    if (this.slideIndex >= 0 && this.photoUrls) {
      this.photoUrls.splice(this.slideIndex, 1);
      this.changeSlide(-1);
    }
  }

  changeSlide(n: number) {
    let length = this.photoUrls?.length ?? 0;
    if (length === 0) {
      return;
    }
    this.slideIndex += n;

    if (this.slideIndex < 0) {
      this.slideIndex = length - 1;
    } else if (this.slideIndex >= length) {
      this.slideIndex = 0;
    }
  }
}
