<!-- Product Images and titles -->
<div class="row">
    <div class="col-12 position-relative px-0 mb-4">
        <button class="btn btn-info avatar avatar-36 p-0 rounded-circle position-absolute top-0 end-0 m-2 text-white shadow-sm">
            <i class="bi bi-share h5 mb-0"></i></button>
        <figure *ngIf="image" class="overflow-hidden mb-0 coverimg h-190" [ngStyle]="{ 'background-image': 'url(' + image + ')' }">
            <img src="{{ image }}" alt="project images" i18n-alt="Project images label @@projectImages">
        </figure>
    </div>
    <div class="col-12 mb-4">
        <h5 class="mb-1">{{ (post$ | async)?.title }}</h5>
        <p class=" text-muted mb-2 small">{{ (post$ | async)?.createdAt | date }}</p>
    </div>
</div>

<!-- Discriptions -->
<div class="row mb-4">
    <div [innerHTML] ="(post$ | async)?.content" class="col-12 text-muted">
     
    </div>
</div>

<!-- Comments -->
<div class="row mb-2">
    <div class="col-12">
        <h6 class="my-1" i18n="Comments label">Comments</h6>
    </div>
</div>
<div class="row mb-4">
    <div class="col-12">
        <div class="row py-3 gx-3">
            <div class="col-auto position-relative">
                <figure class="avatar avatar-50 rounded-circle">
                    <img src="assets/img/user1.jpg" alt="">
                </figure>
            </div>
            <div class="col">
                <a routerLink="/profile" class="mb-1 text-normal d-block">Ajinkya McMohan <i class="bi bi-arrow-right-short text-primary"></i></a>
                <p class="text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
                    sollicitudin dignissim nisi, eget malesuada ligula ultricies sit amet. Suspendisse
                    efficitur ex eu est placerat mattis.</p>
            </div>
        </div>

        <div class="row py-3 gx-3">
            <div class="col-auto position-relative">
                <figure class="avatar avatar-50 rounded-circle">
                    <img src="assets/img/user2.jpg" alt="">
                </figure>
            </div>
            <div class="col">
                <a routerLink="/profile" class="mb-1 text-normal d-block">Jessica Willson <i class="bi bi-arrow-right-short text-primary"></i></a>
                <p class="text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
                    sollicitudin dignissim nisi, eget malesuada ligula ultricies sit amet. Suspendisse
                    efficitur ex eu est placerat mattis.</p>
            </div>
        </div>

        <div class="row py-3 gx-3">
            <div class="col-auto position-relative">
                <figure class="avatar avatar-50 rounded-circle">
                    <img src="assets/img/user3.jpg" alt="">
                </figure>
            </div>
            <div class="col">
                <a routerLink="/profile" class="mb-1 text-normal d-block">Bhagat Chaturvedy <i class="bi bi-arrow-right-short text-primary"></i></a>
                <p class="text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
                    sollicitudin dignissim nisi, eget malesuada ligula ultricies sit amet. Suspendisse
                    efficitur ex eu est placerat mattis.</p>
            </div>
        </div>
    </div>
</div>
