import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthRepository } from 'src/app/state/auth.repository';
import { Post, PostsRepository } from 'src/app/state/posts.repository';
import { PostsService } from 'src/app/state/posts.service';
import { SimpleCardAction } from '../../shared/components/simple-card/simple-card.component';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  postCards$ = combineLatest([
    this.repo.posts$,
    this.authRepo.isAnyAdmin$
  ]).pipe(
    map(([posts, isAdmin]) => posts.map(post => {
      const card: { post: Post, actions: SimpleCardAction[] } = {
        post,
        actions: [{ 
          text: 'Read more', 
          routerLink: ['/news', post.id] 
        }]
      };
      if (isAdmin) {
        card.actions.push({ 
          text: 'Edit', 
          routerLink: ['/news', post.id, 'edit'],
          icon: 'bi-pencil'
        });
      }
      return card;
    }))
  );

  constructor(
    private repo: PostsRepository,
    private service: PostsService,
    public authRepo: AuthRepository
  ) { }

  ngOnInit(): void {
    this.service.load().subscribe();
  }

}
