<div class="row mb-4" *ngVar="user$ | async as user">
  <div class="col">
    <h2>
      <ng-container i18n="Label for trips log">Trips log</ng-container>
      <span class="text-muted" *ngIf="user">
        <i class="bi bi-person ms-3 me-1"></i>
        <ng-container *ngIf="user.name || user.surname"
          >{{ user.name }} {{ user.surname }} ({{ user.email }})</ng-container
        >
        <ng-container *ngIf="!user.name && !user.surname">{{
          user.email
        }}</ng-container>
      </span>
    </h2>
  </div>
</div>
<div class="row mb-4">
  <div class="col" *ngIf="auth.id$ | async">
    <app-sort-table1
      *ngIf="checkIfDataLoaded(); else loadingContent"
      [dateType]="dateType"
      [products]="productSelectOptions"
      [clients]="clientSelectOptions"
      [drivers]="driverSelectOptions"
      [tableColumns]="tableColumns"
      [loadUnloadColumns]="loadUnloadColumns"
      [tripLogs]="tripLogs"
      [isAdminPage]="false"
      [filterOptions]="filterOptions"
      (takePhotos)="addPhotos($event)"
      (movePhoto)="movePhoto($event)"
      (filterOptionsChange)="requestLoadData($event)"
      (tenantNoteChange)="updateTenantNote($event)"
    ></app-sort-table1>

    <ng-template #loadingContent>
      <app-spinner [showWithText]="true"></app-spinner>
    </ng-template>
  </div>
</div>
