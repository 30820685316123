import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Cleaning } from 'src/app/state/cleaning.repository';

@Component({
  selector: 'app-cleaning-list',
  templateUrl: './cleaning-list.component.html',
  styleUrls: ['./cleaning-list.component.scss']
})
export class CleaningListComponent implements OnInit {
  @Input() cleanings: Cleaning [] | null = null;
  @Output() delete = new EventEmitter<string>();
  deleteConfirmation: Cleaning | null = null;
  
  constructor() { }

  ngOnInit(): void {
  }

}
