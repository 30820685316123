import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirm-refresh-redirect',
  templateUrl: './confirm-refresh-redirect.component.html',
  styleUrls: ['./confirm-refresh-redirect.component.scss']
})
export class ConfirmRefreshRedirectComponent implements OnInit {
  @Input() errorMessage: string = '';
  @Input() redirect: string = '';
  @Output() confirm = new EventEmitter<void>();
  constructor() { }

  ngOnInit(): void {
  }
  
}
