<div class="row wrapper" *ngIf="columns">
  <div class="col-md-2" *ngFor="let item of columns">
    <div
      class="form-group form-floating mb-3 hoverable"
      [draggable]="true"
      (dragend)="take(item)"
      (dragover)="point(item)"
    >
      <app-column-card [column]="item" [isEconomic]="isEconomic">
      </app-column-card>
    </div>
  </div>
</div>
