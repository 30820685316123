<div class="row mb-2">
  <div class="">
    <div class="bg-none row p-0 m-0">
      <div class="col-6 mb-2 no-outer-border list-group-item flex-fill">
        <div class="row">
          <div class="col-auto pr-0 align-self-center text-end">
            <h2 class="mb-3">
              <i class="bi bi-truck"></i>
            </h2>
          </div>
          <div
            class="col"
            *ngVar="
              activeTrip
                ? activeTripVehicle
                : (authRepo.settings$ | async)?.vehicleName as vehicle
            "
          >
            <p class="text-muted small mb-1" i18n="Vehicle label">Vehicle</p>
            <h6>{{ vehicle }}</h6>
          </div>
        </div>
      </div>
      <div class="col-6 mb-2 no-outer-border list-group-item flex-fill">
        <div class="row">
          <div class="col-auto pr-0 align-self-center text-end">
            <h2 class="mb-3">
              <i class="bi bi-minecart"></i>
            </h2>
          </div>
          <div
            class="col"
            *ngVar="
              activeTrip
                ? activeTripTrailer
                : (authRepo.settings$ | async)?.trailerName as trailer
            "
          >
            <p class="text-muted small mb-1">Trailer</p>
            <h6>{{ trailer }}</h6>
          </div>
        </div>
      </div>
      <div
        *ngIf="trip && trip.userName"
        class="col-6 mb-2 no-outer-border list-group-item flex-fill"
      >
        <div class="row">
          <div class="col-auto pr-0 align-self-center text-end">
            <h2 class="mb-3">
              <i class="bi bi-person-fill"></i>
            </h2>
          </div>
          <div class="col">
            <p class="text-muted small mb-1" i18n="@@driverCard">Driver</p>
            <h6>{{ trip.userName }}</h6>
          </div>
        </div>
      </div>
      <a
        *ngIf="lastActiveEvent && lastActiveEvent.contactPerson"
        class="col-6 mb-2 no-outer-border list-group-item flex-fill hoverable"
        href="tel:{{ lastActiveEvent.contactPerson }}"
        [class.disabled]="
          !lastActiveEvent.contactPerson ||
          lastActiveEvent.contactPerson.length < 5
        "
      >
        <div class="row">
          <div class="col-auto pr-0 align-self-center text-end">
            <h2 class="mb-3">
              <i class="bi bi-phone-vibrate-fill strong success-text"></i>
            </h2>
          </div>
          <div class="col">
            <p class="text-muted small mb-1" i18n="Contact Person label">
              Contact Person
            </p>
            <h6>{{ lastActiveEvent.contactPerson }}</h6>
          </div>
        </div>
      </a>
      <div
        *ngIf="
          lastActiveEvent &&
          lastActiveEvent.client &&
          lastActiveEvent.client.name
        "
        class="col-6 mb-2 no-outer-border list-group-item flex-fill"
      >
        <div class="row">
          <div class="col-auto pr-0 align-self-center text-end">
            <h2 class="mb-3">
              <i class="bi bi-briefcase"></i>
            </h2>
          </div>
          <div class="col">
            <p class="text-muted small mb-1" i18n="Planned start label">
              Client
            </p>
            <h6>{{ lastActiveEvent.client.name }}</h6>
          </div>
        </div>
      </div>

      <div
        *ngIf="startEvent && startEvent.eventTime"
        class="col-6 mb-2 no-outer-border list-group-item flex-fill"
      >
        <div class="row">
          <div class="col-auto pr-0 align-self-center text-end">
            <h2 class="mb-3">
              <i class="bi bi-hourglass-top"></i>
            </h2>
          </div>
          <div class="col">
            <p class="text-muted small mb-1" i18n="Planned start label">
              Started At
            </p>
            <h6>{{ toCorrectDate(startEvent.eventTime) }}</h6>
          </div>
        </div>
      </div>
      <div
        *ngIf="trip && trip.parkedAt"
        class="col-6 mb-2 no-outer-border list-group-item flex-fill"
      >
        <div class="row">
          <div class="col-auto pr-0 align-self-center text-end">
            <h2 class="mb-3">
              <i class="bi bi-hourglass-bottom"></i>
            </h2>
          </div>
          <div class="col">
            <p class="text-muted small mb-1" i18n="Planned end label">
              Paused At
            </p>
            <h6>{{ toCorrectDate(trip.parkedAt) }}</h6>
          </div>
        </div>
      </div>

      <div
        *ngIf="
          lastActiveEvent &&
          lastActiveEvent.vendor &&
          lastActiveEvent.vendor.name
        "
        class="col-6 mb-2 no-outer-border list-group-item flex-fill"
      >
        <div class="row">
          <div class="col-auto pr-0 align-self-center text-end">
            <h2 class="mb-3">
              <i class="bi bi-briefcase"></i>
            </h2>
          </div>
          <div class="col">
            <p class="text-muted small mb-1" i18n="Planned end label">Vendor</p>
            <h6>{{ lastActiveEvent.vendor.name }}</h6>
          </div>
        </div>
      </div>
      <div
        *ngIf="lastActiveEvent && lastActiveEvent.note"
        class="col-6 mb-2 no-outer-border list-group-item flex-fill"
      >
        <div class="row">
          <div class="col-auto pr-0 align-self-center text-end">
            <h2 class="mb-3">
              <i class="bi bi-bookmarks"></i>
            </h2>
          </div>
          <div class="col text-break">
            <p class="text-muted small mb-1" i18n="Planned start label">Note</p>
            <h6>{{ lastActiveEvent.note }}</h6>
          </div>
        </div>
      </div>
      <div
        *ngIf="lastActiveEvent && lastActiveEvent.reference"
        class="col-6 mb-2 no-outer-border list-group-item flex-fill"
      >
        <div class="row">
          <div class="col-auto pr-0 align-self-center text-end">
            <h2 class="mb-3">
              <i class="bi bi-gear"></i>
            </h2>
          </div>
          <div class="col">
            <p class="text-muted small mb-1" i18n="tripReference label">
              Reference
            </p>
            <h6>{{ lastActiveEvent.reference }}</h6>
          </div>
        </div>
      </div>
      <div
        *ngIf="
          lastActiveEvent &&
          lastActiveEvent.products &&
          lastActiveEvent.products.length > 0
        "
        class="col-6 mb-2 no-outer-border list-group-item flex-fill"
      >
        <div class="row">
          <div class="col-auto pr-0 align-self-center text-end">
            <h2 class="mb-3">
              <i class="bi bi-box"></i>
            </h2>
          </div>
          <div class="col">
            <p class="text-muted small mb-1" i18n="Label for products page">
              Products
            </p>
            <h6 class="d-flex text-break">
              <div>{{ getProducts() }}</div>
            </h6>
          </div>
        </div>
      </div>
      <div
        *ngIf="lastActiveEvent && lastActiveEvent.weight"
        class="col-6 mb-2 no-outer-border list-group-item flex-fill"
      >
        <div class="row">
          <div class="col-auto pr-0 align-self-center text-end">
            <h2 class="mb-3">
              <i class="bi bi-boxes"></i>
            </h2>
          </div>
          <div class="col">
            <p class="text-muted small mb-1" i18n="tripWeight label">
              Weight, kg/m
            </p>
            <h6>{{ lastActiveEvent.weight }}</h6>
          </div>
        </div>
      </div>
      <div
        *ngIf="lastActiveEvent && lastActiveEvent.accessory"
        class="col-6 mb-2 no-outer-border list-group-item flex-fill"
      >
        <div class="row">
          <div class="col-auto pr-0 align-self-center text-end">
            <h2 class="mb-3">
              <i class="bi bi-cone-striped"></i>
            </h2>
          </div>
          <div class="col">
            <p class="text-muted small mb-1" i18n="tripAccessory label">
              Accessory
            </p>
            <h6 class="d-flex text-break">{{ lastActiveEvent.accessory }}</h6>
          </div>
        </div>
      </div>
      <div
        *ngIf="lastActiveEvent && lastActiveEvent.cleaning"
        class="col-6 mb-2 no-outer-border list-group-item flex-fill"
      >
        <div class="row">
          <div class="col-auto pr-0 align-self-center text-end">
            <h2 class="mb-3">
              <i class="bi bi-droplet"></i>
            </h2>
          </div>
          <div class="col">
            <p class="text-muted small mb-1" i18n="@formTripCleaning label">
              Rengøring
            </p>
            <h6>{{ lastActiveEvent.cleaning }}</h6>
          </div>
        </div>
      </div>
      <div
        *ngIf="lastActiveEvent && lastActiveEvent.comment"
        class="col-6 mb-2 no-outer-border list-group-item flex-fill"
      >
        <div class="row">
          <div class="col-auto pr-0 align-self-center text-end">
            <h2 class="mb-3">
              <i class="bi bi-chat-left-dots"></i>
            </h2>
          </div>
          <div class="col">
            <p class="text-muted small mb-1" i18n="@formComment label">
              Comment
            </p>
            <h6>{{ lastActiveEvent.comment }}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex flex-wrap">
    <div *ngFor="let commentPhoto of trip?.commentPhotos; let i = index">
      <div class="image">
        <img
          class="comment-img"
          (click)="openImageModal(i)"
          src="{{ commentPhoto.imagePath }}"
        />
      </div>
    </div>
  </div>
</div>
<button
  [class.isDisabled]="!settings?.vehicleId"
  (click)="resumeTrip(trip?.id)"
  *ngIf="trip?.parkedAt"
  style="flex: none"
  class="but card shadow-sm me-2 bg-success text-white d-flex justify-content-center align-items-center"
>
  <h4 class="mt-1 mb-1" i18n="@@resumeOrder">
    Resume <i class="bi bi-play-fill"></i>
  </h4>
</button>

<app-image-slider
  [urls]="images"
  (cancel)="closeImageSlider()"
></app-image-slider>
