import { Injectable } from "@angular/core";
import { Trip } from "./trips.repository";

import { HttpClient } from "@angular/common/http";
import { DeletedTripsRepository } from "./deletedtrips.repository";
import { BaseService } from "./abstract/base.service";
import { Observable, tap } from "rxjs";

const API = '/api/trips/deletedtrips';

@Injectable({
  providedIn: 'root'
})
export class DeletedTripsService extends BaseService<Trip> {

  constructor(
    http: HttpClient,
    repo: DeletedTripsRepository
  ) {
    super(API, http, repo);
  }

  restore(id: string): Observable<void> {
    return this.http.delete<void>(`${API}/${id}/restore`).pipe(
      tap(() => this.repo.remove(id)),
      this.tapReloadPage(),
      this.repo.trackOne(id)
    );
  }
}
