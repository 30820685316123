import { Component, OnInit } from '@angular/core';
import { AuthRepository } from 'src/app/state/auth.repository';
import { TrailersRepository, trailersSortOptions } from 'src/app/state/trailers.repository';
import { TrailersService } from 'src/app/state/trailers.service';

@Component({
  selector: 'app-trailer-page',
  templateUrl: './trailer-page.component.html',
  styleUrls: ['./trailer-page.component.scss']
})
export class TrailerPageComponent implements OnInit {
  sortOptions = trailersSortOptions;
  exportFactory = () => this.service.export();
  importFactory = (file: File) => this.service.import(file);


  constructor(
    public repo: TrailersRepository,
    public service: TrailersService,
    public auth: AuthRepository
  ) { }

ngOnInit() {
    this.service.reloadPage().subscribe();
  }

}
