import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, map, Subscription } from 'rxjs';
import { enterFromBottomAnimation } from 'src/app/modules/shared/helpers/animaions';
import { ConnectionService } from 'src/app/modules/shared/services/connection.service';
import { AuthRepository } from 'src/app/state/auth.repository';
import { hasIdPromise, RequestsRepository } from 'src/app/state/requests.repository';
import { RequestsService } from 'src/app/state/requests.service';

@Component({
  selector: 'app-unsynced-changes',
  templateUrl: './unsynced-changes.component.html',
  styleUrls: ['./unsynced-changes.component.scss'],
  animations: [ enterFromBottomAnimation ]
})
export class UnsyncedChangesComponent implements OnInit, OnDestroy {
  @Input() titleResolver: 
    (relativeUrl: string, body: any, method: string) => string = (url) => url;

  isDisplayed$ = combineLatest([
    this.connection.isOnline$,
    this.repo.count$
  ]).pipe(
    map(([online, count]) => online && count > 0)
  );

  requests$ = combineLatest([
    this.repo.all$,
    this.auth.id$,
    this.auth.isAuthenticated$
  ]).pipe(
    map(([all, id, auth]) => all.map(r => ({
      id: r.id,
      title: this.titleResolver(
        r.url.substring('/api/'.length),
        r.body,
        r.method
      ),
      date: r.createdAt,
      icon: r.method === 'POST' 
        ? 'bi-plus-lg text-success'
        : 'bi-pencil text-primary',
      isCurrentUser: auth && r.userId === id,
      hasDependency: hasIdPromise(r.url)
    })))
  );

  onlineSubscription?: Subscription;
  isSynchronizing = false;

  constructor(
    public connection: ConnectionService,
    public repo: RequestsRepository,
    private service: RequestsService,
    public auth: AuthRepository,
  ) { }

  ngOnDestroy(): void {
    this.onlineSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.onlineSubscription = this.isDisplayed$
      .subscribe(isReady => {
        if (isReady && !this.isSynchronizing) {
          this.sync();
        }
      });
  }

  sync() {
    this.isSynchronizing = true;
    const userId = this.auth.getId();
    if (userId) {
      this.service.syncAll(userId)
        .subscribe({
          complete: () => window.location.reload()
        });
    }
  }

  dismissAll() {
    this.repo.clear();
    window.location.reload();
  }

}
