
<div class="row footer-info bg-whiter py-2">
  <div class="col small d-flex align-items-center overflow-hidden">

    <span class="text-muted text-truncate">
      {{ year }} <i class="bi bi-dot"></i> {{ env.name }}
    </span>
  </div>
  <div class="col-auto text-end d-flex">
    <!-- Contact links -->
    <!-- <a routerLink="" class="btn btn-sm btn-link py-0 border-0"
        aria-label="Email us"
        i18n-aria-label="Footer email icon">
        <i class="bi bi-envelope-fill"></i>
    </a> -->
  </div>
</div>
