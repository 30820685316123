<div
  class="modal fade"
  bsModal
  [config]="{ show: true }"
  id="confirmModal"
  tabindex="-1"
  #confirmModal
  aria-hidden="true"
  (onHidden)="cancel.emit()"
>
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content bg-lights border-0">
      <div class="modal-header">
        <h6
          class="modal-title"
          id="confirmModalLabel"
          i18n="Title for confirmation dialog"
        >
          Confirm your action
        </h6>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          i18n-aria-label="Close label @@close"
          (click)="cancel.emit()"
        ></button>
      </div>
      <div class="modal-body p-0">
        <div class="form-floating p-3">
          <p class="mb-0" i18n="Confirm action">Please confirm your action.</p>
        </div>

        <div class="modal-footer justify-content-center">
          <button
            class="btn btn-danger me-2"
            (click)="confirmCall()"
            i18n="Unexport"
          >
            Unexport
          </button>
          <button
            class="btn btn-secondary"
            (click)="cancel.emit()"
            i18n="@@cancel"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
