<div class="row mt-1">
    <div class="col-12">
      <div class="card shadow-md mb-4">
        <ul class="list-group list-group-flush">
          <app-model-card *ngFor="let cleaning of cleanings"
            [title]="cleaning.name"
            icon="bi-droplet"
            [date]="cleaning.createdAt"
            [id]="cleaning.id"
            routerLinkBase="/cleanings"
            [isDeleteDisabled]="cleaning.isBeingUsed"
            (deleteClick)="deleteConfirmation = cleaning"></app-model-card>
        </ul>
      </div>
    </div>
</div>
<app-confirm-delete *ngIf="deleteConfirmation"
  [deleteCandidate]="deleteConfirmation.name"
  (confirm)="delete.emit(deleteConfirmation.id);deleteConfirmation = null;"
  (cancel)="deleteConfirmation = null"></app-confirm-delete>
  