<div class="row mb-4">
  <div class="col">
    <h2 i18n="Title for the list of company's drivers">Time log</h2>
  </div>
</div>
<div class="row mb-4">
</div>
<div class="row mb-4">
  <div class="col">
   
    <app-sort-table-workdays [dateType]="dateType"
                             [isAdminPage]="false"
                             [workDays]="workDaysRepo.workdays$"
                             [drivers]="drivers"></app-sort-table-workdays>
  </div>
</div>

