import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { DayjsService } from '../../services/dayjs.service';

@Component({
  selector: 'app-import-export',
  templateUrl: './import-export.component.html',
  styleUrls: ['./import-export.component.scss'],
})
export class ImportExportComponent implements OnInit {
  @Input() exportFactory?: () => Observable<string>;
  @Input() importFactory?: (file: File) => Observable<void>;

  @Input() filenamePrefix: string = '';
  @Input() filenameExtension: string = '.csv';
  @Input() titlePrefix: string = '';
  @Input() icon: string = 'bi bi-file-earmark-arrow-up';
  @Input() format: string = 'YYYY-MM-DD-HH-mm-ss';
  isExporting = false;
  isImporting = false;
  isImportError = false;

  @ViewChild('importExportLink') importExportLink!: ElementRef;
  @ViewChild('fileImport') fileImport!: ElementRef;

  constructor(public ngDay: DayjsService) {}

  ngOnInit(): void {}

  export() {
    if (!this.exportFactory) {
      return;
    }
    this.isExporting = true;
    this.exportFactory().subscribe({
      next: (url) => {
        this.importExportLink.nativeElement.href = url;
        const filename = this.format
          ? `${this.filenamePrefix}-${this.ngDay.dayjs().format(this.format)}${
              this.filenameExtension
            }`
          : `${this.filenamePrefix}${this.filenameExtension}`;
        this.importExportLink.nativeElement.setAttribute('download', filename);
        this.importExportLink.nativeElement.click();
        this.isExporting = false;
      },
      error: () => (this.isExporting = false),
    });
  }

  import(event: Event) {
    this.isImportError = false;
    if (!this.importFactory) {
      return;
    }
    const target = event.target as HTMLInputElement;
    if (!target.files || !target.files.length) {
      return;
    }
    this.isImporting = true;
    this.importFactory(target.files[0]).subscribe({
      next: () => this.resetImport(),
      error: () => {
        this.isImportError = true;
        this.resetImport();
      },
    });
  }

  resetImport() {
    this.fileImport.nativeElement.value = null;
    this.isImporting = false;
  }
}
