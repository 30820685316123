import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { AccessoriesService } from 'src/app/state/accessory.service';
import { AuthService } from 'src/app/state/auth.service';
import { CleaningsService } from 'src/app/state/cleaning.service';
import { CompaniesService } from 'src/app/state/companies.service';
import { OrdersService } from 'src/app/state/orders.service';
import { ProductsService } from 'src/app/state/products.service';
import { TrailersService } from 'src/app/state/trailers.service';
import { TripsService } from 'src/app/state/trips.service';
import { VehiclesService } from 'src/app/state/vehicles.service';
import { WorkdaysService } from 'src/app/state/workdays.service';

@Injectable({
  providedIn: 'root',
})
export class DriverDataPreloaderService {
  constructor(
    private accessories: AccessoriesService,
    private auth: AuthService,
    private cleaning: CleaningsService,
    private companies: CompaniesService,
    private products: ProductsService,
    private trailers: TrailersService,
    private vehicles: VehiclesService,
    private trips: TripsService,
    private orders: OrdersService,
    private workdays: WorkdaysService
  ) {}

  preloadDrivingModels() {
    const models$ = [
      this.accessories.loadAsListwithRepo(),
      this.cleaning.loadAsListwithRepo(),
      this.companies.loadAsListwithRepo(),
      this.products.loadAsListwithRepo(),
      this.trailers.loadAsListwithRepo(),
      this.vehicles.loadAsListwithRepo(),
      this.auth.loadSettings(),
      this.trips.loadCurrent(),
      this.orders.loadCurrent(),
      this.workdays.loadCurrent(),
    ];
    forkJoin(models$).subscribe();
  }
}
