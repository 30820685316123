<app-user-header-image></app-user-header-image>

<div class="pt-4" *ngIf="userData$ | async as userData">
    <app-user-basic-info-form
        [initialValue]="userData"
        (userSubmit)="onInfoSubmit($event)">
    </app-user-basic-info-form>
    <app-change-password-form
        (passwordSubmit)="onPasswordSubmit($event)"></app-change-password-form>
    <app-error-alert title="Could not update your profile" i18n-title="Profile update error alert"
      [errors]="submitErrors"></app-error-alert>
</div>
