<div class="row mb-4">
    <div class="col">
      <h2 i18n="EProducts label">Products</h2>
    </div>
    <div class="col-auto d-flex align-items-center">
        <button class="btn btn-sm btn-outline-primary" i18n="@Economics import" (click)="sync()"  [class.disabled]="isSync">
        Import products <i *ngIf="!isSync && !isSyncError" class="bi bi-arrow-repeat"></i><i *ngIf="!isSync && isSyncError" [class.text-danger]="isSyncError" class="bi bi-x-octagon"></i> 
        <app-spinner *ngIf="isSync"></app-spinner>
        </button>
      </div>
  </div>
  
  <div class="row mb-4">
    <div class="col-12 col-md mb-3 mb-md-0 pagination-wrapper">
      <app-pagination [page]="(erepo.pageNumber$ | async) || 1"
        [total]="(erepo.paginationData$ | async)?.lastPage || 0"
        (pageChange)="eservice.loadPage($event).subscribe()"></app-pagination>
    </div>
    <div class="col-12 col-md-auto">
      <app-sort-dropdown [sortingOptions]="sortOptions"
        [sorting]="erepo.sort$ | async"
        (sortingChange)="eservice.sort($event).subscribe(); search = ''"></app-sort-dropdown>
    </div>
  </div>
  <div>
    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 mb-2">
      <div class="form-group form-floating">
        <input
          id="search"          
          [(ngModel)]="search"
          (ngModelChange)="loadBySearch($event)"
          type="text"
          autocomplete="off"
          class="form-control"
          placeholder="Search"
          i18n-placeholder="Search placeholder @@google_search"
        />
        <label for="google_search" i18n="@@google_search">Search</label>
      </div>
    </div>
  </div>  
  <div class="row mb-4">
      <div class="col">
        <app-spinner *ngIf="erepo.isLoading$ | async"></app-spinner>
        <app-eproduct-list [eproducts]="erepo.page$ | async"></app-eproduct-list>
      </div>
  </div>
