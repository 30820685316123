import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';

import {
  ActiveDriversRepository,
  Driver,
} from 'src/app/state/activedrivers.repository';
import { ActiveDriversService } from 'src/app/state/activedrivers.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DayjsService } from 'src/app/modules/shared/services/dayjs.service';

@Component({
  selector: 'app-active-drivers-page',
  templateUrl: './active-drivers-page.component.html',
  styleUrls: ['./active-drivers-page.component.scss'],
})
export class ActiveDriversPageComponent implements OnInit {
  @Output() activeDriversData = new EventEmitter<Driver[]>();

  id: string | null = null;
  period: string | null = null;
  activeDrivers: Driver[] | null = null;
  default_start_value?: string;
  default_end_value?: string;

  constructor(
    public ngDay: DayjsService,
    private activatedRoute: ActivatedRoute,
    public repository: ActiveDriversRepository,
    public service: ActiveDriversService
  ) {}

  ngOnInit(): void {
    this.detectDateFromUrl();
  }

  detectDateFromUrl() {
    const dayObj = this.ngDay.dayjs.utc().local();
    this.activatedRoute.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('tenant_id');
        this.period = value.get('period');
        switch (this.period) {
          case 'today': {
            this.default_start_value = dayObj.format('YYYY-MM-DD');
            this.default_end_value = dayObj.format('YYYY-MM-DD');
            break;
          }
          case 'thisweek': {
            this.default_start_value = this.ngDay
              .dayjs()
              .startOf('week')
              .format('YYYY-MM-DD');
            this.default_end_value = this.ngDay
              .dayjs()
              .endOf('week')
              .format('YYYY-MM-DD');
            break;
          }
          case 'thismonth': {
            this.default_start_value = this.ngDay
              .dayjs()
              .startOf('month')
              .format('YYYY-MM-DD');
            this.default_end_value = this.ngDay
              .dayjs()
              .endOf('month')
              .format('YYYY-MM-DD');
            break;
          }
          default: {
            this.default_start_value = dayObj.format('YYYY-MM-DD');
            this.default_end_value = dayObj.format('YYYY-MM-DD');
            break;
          }
        }
        this.ListSubmit();
      },
    });
  }

  ListSubmit() {
    if (`${this.default_start_value}`.length > 10) {
      this.detectDateFromUrl();
      return;
    }

    if (`${this.default_end_value}`.length > 10) {
      this.default_end_value = '9999-12-31';
    }

    this.service
      .getActivedrivers(
        `${this.default_start_value}`,
        `${this.default_end_value}`
      )
      .subscribe({
        next: (x) => {
          this.activeDrivers = x;
          this.activeDriversData.emit(this.activeDrivers);
        },
      });
  }
}
