import { Injectable } from "@angular/core";
import { Template, TemplatesRepository } from "./templates.repository";
import { BaseService } from "./abstract/base.service";
import { HttpClient } from "@angular/common/http";
import { Column } from "./columns.repository";
import { Observable, tap } from "rxjs";
import { PaginationData } from "@ngneat/elf-pagination";

const API = '/api/templates';

@Injectable({
  providedIn: 'root'
})
export class TemplatesService extends BaseService<Template> {

  constructor(
    http: HttpClient,
    repo: TemplatesRepository
  ) {
    super(API, http, repo);
  }

  create(dtos: Column[], name: string, showExtraData: boolean, description?: string): Observable<Template> {
    return this.http.post<Template>(`${API}/new`, {columns: dtos, showExtraData, name: name, description: description}).pipe(      
      tap(res => this.repo.upsert(res))
    );
  }

  load(): Observable<PaginationData & { data: Template[] }> {
    return this.http.get<PaginationData & { data: Template[] }>(API).pipe(
      tap((res) => this.repo.set(res.data)),
      this.repo.track()      
    );
  }

  updateTemplateColumns(dtos: Column[], name: string, showExtraData: boolean, description?: string, id?: string | null): Observable<Template> {
    return this.http.put<Template>(`${API}/change`, {id: id, columns: dtos, showExtraData, name: name, description: description}).pipe(      
      tap(res => this.repo.upsert(res))
    );
  }

  loadTemplateColumns(): Observable<Column[]> {
    return this.http
    .get<Column[]>(`${API}/columns`);
  }
}