import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  PipeTransform,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  SearchResult,
  SearchState,
  Trip,
  TripDto,
  TripEvent,
  TripUnloadDto,
} from '../../../../state/trips.repository';
import {
  SortColumn,
  SortDirection,
  SortEvent,
  NgbdSortableHeader,
} from '../sortable.directive';
import {
  BehaviorSubject,
  Observable,
  of,
  Subject,
  map,
  tap,
  debounceTime,
  switchMap,
  delay,
} from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { DayjsService } from 'src/app/modules/shared/services/dayjs.service';
import { User } from '../../../../state/users.repository';
import { DatalistOption } from '../../../shared/components/datalist-select/datalist-select.component';
import { Company } from 'src/app/state/companies.repository';
import { Product } from '../../../../state/products.repository';
import * as dayjs from 'dayjs';
import { UiRepository } from '../../../../state/ui.repository';
import { TripsService } from '../../../../state/trips.service';
import {
  FilterOptions,
  Workday,
  WorkdaysDto,
  WorkdaysRepository,
} from '../../../../state/workdays.repository';
import { AuthRepository } from '../../../../state/auth.repository';
import { WorkdaysService } from 'src/app/state/workdays.service';

function dateFormaterWithoutTime(dt: Date): string {
  return `${dt.getDate().toString().padStart(2, '0')}-${(dt.getMonth() + 1)
    .toString()
    .padStart(2, '0')}
             ${dt.getFullYear().toString().padStart(4, '0')}`;
}

function dateFormater(dt: Date): string {
  return `${dt.getDate().toString().padStart(2, '0')}-${(dt.getMonth() + 1)
    .toString()
    .padStart(2, '0')}
             ${dt.getFullYear().toString().padStart(4, '0')} ${dt
    .getHours()
    .toString()
    .padStart(2, '0')}:
             ${dt.getMinutes().toString().padStart(2, '0')}:${dt
    .getSeconds()
    .toString()
    .padStart(2, '0')}`;
}

const compare = (v1: string | number | any, v2: string | number | any) =>
  v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(
  dtos: WorkdaysDto[],
  column: SortColumn,
  direction: string
): WorkdaysDto[] {
  if (direction === '' || column === '') {
    return dtos;
  } else {
    return [...dtos].sort((a: any, b: any) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(dto: WorkdaysDto, term: string, pipe: PipeTransform) {
  if (dto.userName) {
    return dto.userName?.toLowerCase().includes(term.toLowerCase());
  }
  return dto;
}

@Component({
  selector: 'app-sort-table-workdays',
  templateUrl: './sort-table-workdays.component.html',
  styleUrls: ['./sort-table-workdays.component.scss'],
})
export class SortTableWorkdaysComponent implements OnInit {
  @Input() set workDays(value: Observable<Workday[]>) {
    if (!value) {
      this.workDayDtos = null;
    } else {
      value.subscribe((x) => {
        this.InitializeWorkdayDtos(x);
      });
      this._search();
    }
  }
  @Input() isAdminPage: boolean = true;
  @Input() set drivers(value: User[] | null) {
    if (!value) {
      this.driverOptions = null;
    } else {
      this.driverOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }

  calculateDurationSum(workDayDtos: WorkdaysDto[]): string {
    let hours: number = 0,
      minutes: number = 0;
    let dtos: WorkdaysDto[];
    let dateToCustom = new Date(this.dateTo!);
    dateToCustom.setDate(dateToCustom.getDate() + 1);
    if (workDayDtos) {
      dtos = workDayDtos!.filter(
        (x) => x.date! <= dateToCustom && x.date! >= new Date(this.dateFrom!)
      );
      dtos?.map((e) => {
        let time = e.workTime?.split(':');
        if (time) {
          hours = hours + +time[0];
          minutes = minutes + +time[1];
        }
      });
    }
    if (minutes >= 60) {
      hours = Math.floor(hours + minutes / 60);
      minutes = minutes % 60;
    }
    return `${hours} Timer ${minutes} Minutter`;
  }

  @Input() dateType?: string;
  @Output() tripApprove = new EventEmitter<WorkdaysDto>();
  driverSelected?: string;
  clientSelected?: string;
  driverOptions: DatalistOption[] | null = null;
  clientOptions: DatalistOption[] | null = null;
  productSelected?: string[];
  productsOptions: DatalistOption[] | null = null;
  tripDtos: TripDto[] | null = null;
  OutValues: WorkdaysDto[] | null = null;
  workDayDtos: WorkdaysDto[] | null = null;
  numbersForLoop: number[] | null = null;
  dateFrom?: string;
  dateTo?: string;
  isApprovedSearch?: boolean;
  private _loading$ = new BehaviorSubject<boolean>(true);
  public _search$ = new Subject<void>();
  private _countries$ = new BehaviorSubject<WorkdaysDto[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  options?: FilterOptions;
  exportFactory = () => this.service.featuredExport(this.options);
  ifLoad?: boolean = false;

  pages: number[] = [1];

  private _state: SearchState = {
    page: 1,
    pageSize: 20,
    searchTerm: '',
    sortColumn: '',
    sortDirection: '',
    unloadPosition: '',
  };

  @ViewChildren(NgbdSortableHeader) headers:
    | QueryList<NgbdSortableHeader>
    | undefined;

  constructor(
    private pipe: DecimalPipe,
    public ngDay: DayjsService,
    public ui: UiRepository,
    public service: WorkdaysService,
    public repo: WorkdaysRepository,
    public authRepo: AuthRepository
  ) {
    //this._state.searchTerm = localStorage.getItem('searchTermW')?.toString() || "";
    this._state.page = Number(localStorage.getItem('pageW')) || 1;
    this._state.sortColumn =
      localStorage.getItem('sortColumnW')?.toString() || '';
    this._state.sortDirection =
      (localStorage.getItem('sortDirectionW')?.toString() as SortDirection) ||
      '';
    this.dateFrom = localStorage.getItem('dateFromW')?.toString() || '';
    this.dateTo = localStorage.getItem('dateToW')?.toString() || '';

    this.driverSelected =
      localStorage.getItem('driverSelectedW')?.toString() || '';
    this.isApprovedSearch = JSON.parse(
      localStorage.getItem('isApprovedSearchW')?.toString() || 'false'
    );

    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(300),
        switchMap(() => this._search()),
        delay(1000),
        tap(() => this._loading$.next(false))
      )
      .subscribe((result: any) => {
        this._countries$.next(result.countries);
        this._total$.next(result.total);
      });

    this._search$.next();
  }

  get countries$() {
    return this._countries$.asObservable();
  }
  get total$() {
    return this._total$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }
  get page() {
    return this._state.page;
  }
  get pageSize() {
    return this._state.pageSize;
  }
  get searchTerm() {
    return this._state.searchTerm;
  }

  set page(page: number) {
    this._set({ page });
  }
  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }
  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
  }
  set sortColumn(sortColumn: SortColumn) {
    this._set({ sortColumn });
  }
  set sortDirection(sortDirection: SortDirection) {
    this._set({ sortDirection });
  }

  private _set(patch: Partial<SearchState>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  onSort({ column, direction }: SortEvent) {
    this._state.sortColumn = column;
    this._state.sortDirection = direction;
    // resetting other headers
    if (this.headers) {
      this.headers.forEach((header) => {
        if (header.sortable !== column) {
          header.direction = '';
        }
      });
    }
    this.changeState();
  }

  changePage(page: number) {
    this.page = page;
  }

  changeApprove(event: WorkdaysDto) {
    if (event.isApprove === true) {
      event.isApprove = false;
    } else {
      event.isApprove = true;
    }
    this.onUpdateApprove(event);
  }

  onUpdateApprove(event: Partial<WorkdaysDto>) {
    var el = this.workDayDtos?.filter(
      (x) => x.workdayId === event.workdayId
    )[0];
    if (el) {
      el.isApprove = event.isApprove;
      let objIndex = this.workDayDtos?.findIndex(
        (obj) => obj.workdayId === el?.workdayId
      );
      if (objIndex !== undefined && this.workDayDtos !== null) {
        if (
          this.workDayDtos[objIndex] !== null &&
          this.workDayDtos[objIndex] !== undefined
        ) {
          this.workDayDtos[objIndex].isApprove = el.isApprove;
        }
      }
    }
    this.service.updateApprove(event).subscribe();
    this._search();
  }

  updateDriver(value: any) {}
  updateProducts(value: any) {}

  changeState() {
    //trigger timeLogs
    this._search$.next();
  }

  loadData() {
    this.service.load().subscribe((x) => (this.workDays = this.repo.workdays$));
  }

  ngOnInit(): void {
    this.loadData();
    this.ifLoad = true;
    this._search$.next();
    if (this.dateType) {
      if (this.dateType) {
        this.setDatePicker(this.dateType);
      } else {
        this.setDatePicker('month');
      }
    } else if (!this.dateTo && !this.dateFrom) {
      this.setDatePicker('month');
    }
  }

  setDatePicker(dateType: string) {
    if (dateType === 'day') {
      const dayObj = this.ngDay.dayjs.utc().local();
      this.dateFrom = dayObj.format('YYYY-MM-DD');
      this.dateTo = dayObj.format('YYYY-MM-DD');
    }
    if (dateType === 'week') {
      const weekStart = this.ngDay.dayjs().startOf('week');
      const weekEnd = this.ngDay.dayjs().endOf('week');
      this.dateFrom = weekStart.format('YYYY-MM-DD');
      this.dateTo = weekEnd.format('YYYY-MM-DD');
    }
    if (dateType === 'month') {
      let monthStart = this.ngDay.dayjs().startOf('month');
      let monthEnd = this.ngDay.dayjs().endOf('month');
      if (this.isAdminPage) {
        monthStart = monthStart.subtract(1, 'month');
        monthEnd = monthStart.endOf('month');
      }
      this.dateFrom = monthStart.format('YYYY-MM-DD');
      this.dateTo = monthEnd.format('YYYY-MM-DD');
    }
  }

  private _search(): Observable<SearchResult> | any {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } =
      this._state;

    // 1. sort

    if (this.workDayDtos) {
      let countries = sort(this.workDayDtos, sortColumn, sortDirection);

      // 2. filter
      let dateToCustom = new Date(this.dateTo!);
      dateToCustom.setDate(dateToCustom.getDate() + 1);
      countries = countries
        .filter((country) => matches(country, searchTerm, this.pipe))
        .filter(
          (x) => x.date! <= dateToCustom && x.date! >= new Date(this.dateFrom!)
        );

      if (this.driverSelected) {
        countries = countries.filter((x) =>
          this.driverSelected?.includes(x.userId!)
        );
      }

      if (this.isApprovedSearch) {
        countries = countries.filter((x) => x.isApprove === false);
      }
      if (!this.isAdminPage) {
        let currentUserId: string | null = '';
        this.authRepo.id$.subscribe((f) => (currentUserId = f));
        if (currentUserId && currentUserId !== '') {
          countries = countries.filter((x) => x.userId === currentUserId);
        }
      }

      const total = countries.length;

      let opt: FilterOptions = new FilterOptions();
      opt.resultIds = countries.map((x) => x.workdayId || '');

      /// 3. paginate
      countries = countries.slice(
        (page - 1) * pageSize,
        (page - 1) * pageSize + pageSize
      );
      this.OutValues = countries;
      let numberOfPages = Math.floor(total / this.pageSize);
      if (total % this.pageSize !== 0) {
        numberOfPages = numberOfPages + 1;
      }

      this.pages = Array.from(
        { length: numberOfPages ? numberOfPages : 1 },
        (_, i) => i + 1
      );
      if (!this.pages.includes(this.page)) {
        this.page = this.pages.length;
      }
      if (this.ifLoad) {
        this.page = 1;
        this.ifLoad = false;
      }

      if (this.driverSelected) {
        opt.driverSelected = this.driverSelected;
      }

      opt.isApproved = this.isApprovedSearch;
      opt.searchWord = this.searchTerm;
      opt.dateFrom = this.dateFrom;
      opt.dateTo = this.dateTo;
      opt.order = sortDirection;
      opt.orderColumn = sortColumn;

      this.options = opt;

      this.service.featuredExport(this.options);

      //localStorage.setItem('searchTermW', this.searchTerm);
      localStorage.setItem('pageW', this.page.toString());
      localStorage.setItem('sortColumnW', this._state.sortColumn);
      localStorage.setItem('sortDirectionW', this._state.sortDirection);
      localStorage.setItem('dateFromW', this.dateFrom || '');
      localStorage.setItem('dateToW', this.dateTo || '');

      localStorage.setItem('driverSelectedW', this.driverSelected || '');
      localStorage.setItem('searchTermW', this.searchTerm);

      localStorage.setItem(
        'isApprovedSearchW',
        JSON.stringify(this.isApprovedSearch)
      );

      return of({ countries, total });
    }
  }

  InitializeWorkdayDtos(workdays: Workday[] | null) {
    if (workdays) {
      let workDaysDto: WorkdaysDto[] = [];
      workdays.forEach(function (workDay) {
        if (workDay) {
          var dto = new WorkdaysDto();
          dto.workdayId = workDay.id;
          if (workDay.userName) {
            dto.userName = workDay.userName;
          }
          dto.userDeleted = workDay.deletedAt;

          if (workDay.workdayEvents.filter((x) => x.type === 'DayStart')[0]) {
            dto.dateForShown = dateFormaterWithoutTime(
              workDay.workdayEvents.filter((x) => x.type === 'DayStart')[0]
                ?.eventTime
            );
            dto.date = workDay.workdayEvents.filter(
              (x) => x.type === 'DayStart'
            )[0]?.eventTime;
          }
          if (workDay.workdayEvents.filter((x) => x.type === 'DayStart')[0]) {
            dto.eventSrartId = workDay.workdayEvents.filter(
              (x) => x.type === 'DayStart'
            )[0].id;
          }
          if (workDay.workdayEvents.filter((x) => x.type === 'DayEnd')[0]) {
            dto.eventEndId = workDay.workdayEvents.filter(
              (x) => x.type === 'DayEnd'
            )[0].id;
          }
          if (workDay.workdayEvents.filter((x) => x.type === 'Pause')[0]) {
            dto.eventPauseId = workDay.workdayEvents.filter(
              (x) => x.type === 'Pause'
            )[0].id;
          }
          if (
            workDay.workdayEvents.filter((x) => x.type === 'DayEnd')[
              workDay.workdayEvents.filter((x) => x.type === 'DayEnd').length -
                1
            ]
          ) {
            dto.stopDate = dateFormaterWithoutTime(
              workDay.workdayEvents.filter((x) => x.type === 'DayEnd')[
                workDay.workdayEvents.filter((x) => x.type === 'DayEnd')
                  .length - 1
              ]?.eventTime
            );
          }

          dto.isApprove = workDay.isApproved;
          let startTimeForCount;
          let endTimeForCount;
          if (
            workDay.workdayEvents.filter((x) => x.type === 'DayStart').length >
            0
          ) {
            startTimeForCount = workDay.workdayEvents.filter(
              (x) => x.type === 'DayStart'
            )[0]?.eventTime;
            dto.startTime = workDay.workdayEvents
              .filter((x) => x.type === 'DayStart')[0]
              ?.eventTime.toLocaleTimeString('it-IT');
          }
          if (
            workDay.workdayEvents.filter((x) => x.type === 'DayEnd').length > 0
          ) {
            endTimeForCount = workDay.workdayEvents.filter(
              (x) => x.type === 'DayEnd'
            )[0]?.eventTime;
            dto.stopTime = workDay.workdayEvents
              .filter((x) => x.type === 'DayEnd')[0]
              ?.eventTime.toLocaleTimeString('it-IT');
          }
          dto.userId = workDay.userId;
          let sumPause: number | undefined | null;
          if (
            workDay.workdayEvents.filter((x) => x.type === 'Pause').length > 0
          ) {
            sumPause = workDay.workdayEvents
              .filter((x) => x.type === 'Pause')
              .map((a) => a.duration)
              .reduce(function (a, b) {
                if (
                  a !== null &&
                  a !== undefined &&
                  b !== null &&
                  b !== undefined
                ) {
                  return a + b;
                } else {
                  return null;
                }
              });
          }
          if (sumPause) {
            dto.pause = Math.round(sumPause);
          } else {
            dto.pause = 0;
          }

          if (startTimeForCount && endTimeForCount) {
            let tempDate =
              endTimeForCount.getTime() - startTimeForCount.getTime();

            let hours = Math.floor(Math.abs(tempDate) / (1000 * 60 * 60));
            let omin = Math.abs(tempDate) % (1000 * 60 * 60);
            let minutes = Math.floor(omin / (1000 * 60));

            if (minutes >= 60) {
              minutes = minutes % 60;
              ++hours;
            }
            dto.workTime = `${
              tempDate < 0 && (hours !== 0 || minutes !== 0) ? '-' : ''
            } ${hours} : ${minutes}`;
            dto.workTimeShow = `${
              tempDate < 0 && (hours !== 0 || minutes !== 0) ? '-' : ''
            } ${hours} Timer ${minutes} Minutter`;
          } else {
            dto.workTime = 0 + ':' + 0;
            dto.workTimeShow = 0 + ' Timer ' + 0 + ' Minutter ';
          }
          workDaysDto.push(dto);
        }
      });
      this.workDayDtos = workDaysDto;
    }
    return null;
  }
}
