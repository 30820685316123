<div *ngIf="trip" class="d-flex">
  <div
    for="addNote"
    *ngIf="innerWidth && innerWidth > 450"
    class="card shadow-sm dark-note text-white w-50 lesswide"
    role="button"
  >
    <div (click)="openNote()" class="card-body pe-2">
      <h2 class="mb-1">
        <i *ngIf="!isLoading" class="bi bi-journal-text me-2"></i>
        <app-spinner *ngIf="isLoading"></app-spinner>
      </h2>
      <h4 class="mb-2" i18n="@@addNote">Add Note</h4>
    </div>
  </div>
  <div
    *ngIf="innerWidth && innerWidth > 450"
    class="card shadow-sm bg-primary text-white w-50 lesswide ms-2"
    role="button"
    data-bs-toggle="modal"
    data-bs-target="#pausemdal"
  >
    <div class="card-body pe-2">
      <h2 class="mb-1">
        <i *ngIf="!isLoading" class="bi bi-pause-fill"></i>
        <app-spinner *ngIf="isLoading"></app-spinner>
      </h2>
      <h4 i18n="@@pause">Pause</h4>
    </div>
  </div>
  <div
    *ngIf="innerWidth && innerWidth < 450"
    class="half-size-div w-100 me-0"
    style="height: 145px"
    role="button"
  >
    <div
      class="card shadow-sm dark-note text-white"
      style="height: 79px"
      role="button"
    >
      <div (click)="openNote()" class="card-body d-flex">
        <h2 class="mb-1">
          <i *ngIf="!isLoading" class="bi bi-journal-text me-2"></i>
          <app-spinner *ngIf="isLoading"></app-spinner>
        </h2>
        <h5 class="mb-2" i18n="@@addNote">Add Note</h5>
      </div>
    </div>
    <label
      class="card shadow-sm bg-primary mt-2 text-white me-0 mb-2"
      style="height: 79px"
      role="button"
      data-bs-toggle="modal"
      data-bs-target="#pausemdal"
    >
      <div class="card-body d-flex">
        <h2 class="">
          <i *ngIf="!isLoading" class="bi bi-pause-fill"></i>
          <app-spinner *ngIf="isLoading"></app-spinner>
        </h2>
        <h5 class="mb-2" i18n="@@pause">Pause</h5>
      </div>
    </label>
  </div>
  <div
    *ngIf="!trip"
    class="card shadow-sm bg-danger text-white w-100 ms-2"
    role="button"
    (click)="endDay.emit()"
  >
    <div class="card-body">
      <h2 class="mb-1">
        <i *ngIf="!isLoading" class="bi bi-stop-fill"></i>
        <app-spinner *ngIf="isLoading"></app-spinner>
      </h2>
      <h4 *ngIf="isTripActive" i18n="Overnight pause label">Overnight pause</h4>
      <h4 *ngIf="!isTripActive" i18n="End day label">End day</h4>
      <p
        *ngIf="dayStarted"
        class="text-muted small"
        i18n="Day started timing label"
      >
        Day started: {{ dayStarted | timing }},
        {{ dayStarted | date : "longDate" }}
      </p>
    </div>
  </div>
  <div
    *ngIf="trip"
    class="card shadow-sm bg-violet text-white w-100 ms-2"
    role="button"
    (click)="parkTrip.emit(trip.id)"
  >
    <div class="card-body">
      <h2 class="mb-1">
        <i *ngIf="!isLoading" class="bi bi-stop-fill"></i>
        <app-spinner *ngIf="isLoading"></app-spinner>
      </h2>
      <h4 *ngIf="isTripActive" i18n="Overnight pause label">Park trip</h4>
    </div>
  </div>
</div>
<div class="d-flex" *ngIf="!trip">
  <div
    class="card shadow-sm bg-primary text-white mb-2 w-50 me-2"
    role="button"
    data-bs-toggle="modal"
    data-bs-target="#pausemdal"
  >
    <div class="card-body">
      <h2 class="mb-1">
        <i *ngIf="!isLoading" class="bi bi-pause-fill"></i>
        <app-spinner *ngIf="isLoading"></app-spinner>
      </h2>
      <h4 i18n="@@pause">Pause</h4>
    </div>
  </div>
  <div
    class="card shadow-sm bg-danger text-white w-50"
    role="button"
    (click)="endDay.emit()"
  >
    <div class="card-body">
      <h2 class="mb-1">
        <i *ngIf="!isLoading" class="bi bi-stop-fill"></i>
        <app-spinner *ngIf="isLoading"></app-spinner>
      </h2>
      <h4 *ngIf="isTripActive" i18n="Overnight pause label">Overnight pause</h4>
      <h4 *ngIf="!isTripActive" i18n="End day label">End day</h4>
      <p
        *ngIf="dayStarted"
        class="text-muted small"
        i18n="Day started timing label"
      >
        Day started: {{ dayStarted | timing }},
        {{ dayStarted | date : "longDate" }}
      </p>
    </div>
  </div>
</div>

<!-- Impersonate modal -->
<div
  class="modal fade"
  id="pausemdal"
  tabindex="-1"
  aria-labelledby="impersonatemodal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content bg-lights border-0">
      <div class="modal-header">
        <h6 class="modal-title" i18n="Start pause label">Start pause</h6>
        <button
          #closeModal
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          i18n-aria-label="@@close"
        ></button>
      </div>
      <div class="modal-body">
        <form
          *ngIf="pauseForm"
          [formGroup]="pauseForm"
          (ngSubmit)="onPauseSubmit()"
        >
          <div class="row mb-3">
            <div class="col-12">
              <div
                class="form-group form-floating"
                [appValidateState]="pauseForm.get('pause')"
              >
                <input
                  formControlName="pause"
                  type="number"
                  class="form-control"
                  placeholder="Default pause, min"
                  i18n-placeholder="Default pause minutes label @@defaultPause"
                />
                <label for="pause" i18n="@@defaultPause"
                  >Default pause, min</label
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group form-floating h-100 d-grid">
                <button type="submit" class="btn btn-primary shadow-sm">
                  <ng-container i18n="Start button">Start</ng-container>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- Impersonate modal end -->
<app-single-textbox-modal
  *ngIf="singleTextBoxOppened"
  [mutable]="true"
  [text]="text"
  [title]="title"
  (stringEmmiter)="addNote($event)"
  (cancel)="cancelAddNote()"
></app-single-textbox-modal>
