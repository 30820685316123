import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseService } from 'src/app/base-service';

@Component({
  selector: 'app-office-note-form',
  templateUrl: './office-note-form.component.html',
  styleUrls: ['./office-note-form.component.scss'],
})
export class OfficeNoteFormComponent implements OnInit {
  @Input() startNote: string | undefined = undefined;
  @Input() endNote: string | undefined = undefined;

  @Input() officeNote: string | undefined = undefined;

  @Output() cancel = new EventEmitter<void>();
  @Output() confirm = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  save() {
    this.confirm.emit(this.officeNote);
  }
}
