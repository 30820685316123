import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Vehicle } from 'src/app/state/vehicles.repository';


@Component({
  selector: 'app-vehicle-form',
  templateUrl: './vehicle-form.component.html',
  styleUrls: ['./vehicle-form.component.scss']
})
export class VehicleFormComponent implements OnInit {
  @Input() editedVehicle: Vehicle | null = null;
  form: UntypedFormGroup | null = null; 

  @Output() vehicleSubmit = new EventEmitter<Partial<Vehicle>>();

  constructor(private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [this.editedVehicle?.name, Validators.required],   
      numberplate: [this.editedVehicle?.numberplate, Validators.required]   
    });
  }

  onSubmit() {
      if (this.form && !this.form.valid) {
        this.form.markAllAsTouched();
        return;
      }
    let name = this.form?.value?.name.toString();
    let numberplate = this.form?.value?.numberplate.toString();

    this.vehicleSubmit.emit({ name, numberplate });
  }

}
