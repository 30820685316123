import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
} from '@angular/forms';
import { CommentPhotoDto } from 'src/app/state/orders.repository';
import { FilePathContainer } from '../../services/fileuploader.service';

@Component({
  selector: 'app-commentphotos-form',
  templateUrl: './commentphotos-form.component.html',
  styleUrls: ['./commentphotos-form.component.scss'],
})
export class CommentphotosFormComponent {
  @Output() submit = new EventEmitter<Partial<CommentPhotoDto>[]>();
  @Input() Form: FormGroup | any;
  @Input() restrictEdit: boolean | null = false;

  constructor(private formBuilder: UntypedFormBuilder) {}

  imgUrls: any[] = [];
  imageIndex: number = -1;

  get photos() {
    if (this.Form) {
      return this.Form.get('commentPhotos') as UntypedFormArray;
    }
    return null;
  }

  ngOnInit(): void {
    if (!this.photos && this.Form) {
      this.Form.addControl('commentPhotos', this.formBuilder.array([]));
    }
  }

  deletePhoto(index: number) {
    this.photos?.removeAt(index);
  }

  addPhoto(pathContainer: FilePathContainer) {
    const commentPhoto = this.formBuilder.group({
      id: null,
      tripId: null,
      imagePath: [pathContainer.filePath],
    });

    this.photos?.push(commentPhoto);
  }

  openImageModal(index: number) {
    if (this.photos?.controls) {
      this.imgUrls = this.photos?.controls?.map((x) => x.value.imagePath);
      this.imageIndex = index;
    }
  }

  closeImageSlider() {
    this.imgUrls = [];
    this.imageIndex = -1;
  }
}
