import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './abstract/base.service';
import { Vehicle, VehiclesRepository } from './vehicles.repository';

const API = '/api/vehicles';

@Injectable({
  providedIn: 'root'
})
export class VehiclesService extends BaseService<Vehicle> {

  constructor(
    http: HttpClient,
    repo: VehiclesRepository
  ) {
    super(API, http, repo);
  }

}
