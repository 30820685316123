<div class="row mt-1">
    <div class="col-12">
      <div class="card shadow-md mb-4">
        <ul class="list-group list-group-flush" *ngFor="let trip of trips">
            <li class="list-group-item">
                <div class="row">
                    <div class="col-12 col-md">
                    <div class="row mb-3 mb-md-0">
                        <div class="col-auto">
                        <app-entity-avatar [icon]="'bi bi-truck'"></app-entity-avatar>
                        </div>
                        <div class="col">
                            <p> 
                                <span>{{trip?.client?.name}}</span>
                                <br>
                                <span  class="dark badge">Driver: {{trip.userName || trip.rejectedByName}}</span>
                                <br>                               
                                <span  class="dark badge">Products:{{getProducts(trip.products)}} </span>                                
                            </p>
                        
                        </div>
                        <div class="col d-flex align-items-center">
                            <p>
                                <span  class="dark badge"> Planned To Start at {{trip.plannedToStartAt | date:'dd/MM/yyyy h:mm'}}</span>
                                <br>                                
                                <span  class="dark badge"> Planned To End at {{trip.plannedToEndAt | date:'dd/MM/yyyy h:mm'}}</span>                               
                            </p>
                        </div>                        
                    </div>
                    </div>
                    <div class="col-12 col-md-5">
                    <div class="row">
                        <div class="col d-flex align-items-center text-md-end">
                        <small class="text-muted mr-2 w-100" i18n="Label created date">Deleted at {{trip.deletedAt | date:'longDate'}} </small>
                        </div>
                        <div class="col-auto">
                        <div class="vr h-100"></div>
                        </div>
                        <div  class="col-auto pl-0 d-flex align-items-center">
                        <a class="btn btn-md btn-link w-100" (click)="restore.emit(trip.id)">
                            <i class="bi bi-rewind me-1"></i> <ng-container i18n="@@restore">Restore</ng-container>
                        </a>
                        </div>
                    </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>
