<h2 *ngIf="id && id !== 'new'" class="mb-4" i18n="@@edit">Edit</h2>
<h2 *ngIf="id && id === 'new'" class="mb-4" i18n="@@addPause">Add pause</h2>
<div class="col-12">
    <app-day-info-form
     *ngIf="id === 'new'" 
     [addPause]="id === 'new'"
     (eventSubmit)="addPause($event)"
     [workDay$]="workDay$">
    </app-day-info-form>
    <app-error-alert title="Could not submit the event" i18n-title="Could not sumbit event message"
            [errors]="submitErrors"></app-error-alert>
</div>
<div class="col-12">
    <app-day-info-form
    *ngIf="workDayEvent$ | async as event"
    [addPause]="id !== 'new'"
    [dayEvent]="event"
    [isAdminPage]="isAdminPage"
    [isLoading]="repo.isLoadingOne(event.id) | async"
    (eventSubmit)="editEvent($event)">
    </app-day-info-form>
    <app-error-alert title="Could not submit the event" i18n-title="Could not sumbit event message"
            [errors]="submitErrors"></app-error-alert>
</div>
