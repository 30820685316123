<div class="card shadow-sm">
  <figure *ngIf="image" class="card-img-top overflow-hidden mb-0 coverimg h-190"
          [ngStyle]="{ 'background-image': 'url(' + image + ')' }">
    <img src="{{ image }}" alt="project images" i18n-alt="@@projectImages">
  </figure>
  <div class="card-body">
    <h6>{{ title }}</h6>
    <div class="text-muted small mb-3">
      <ng-content></ng-content>
    </div>
    <div>
      <a *ngFor="let action of actions"
        class="btn-sm btn-link btn-light me-2"
        [routerLink]="action.routerLink"
        (click)="ctaClick.emit($event)">
        <i *ngIf="action.icon" [ngClass]="['bi', 'me-2', action.icon]"></i>
        <span>{{ action.text }}</span>
      </a>
    </div>
  </div>
</div>
