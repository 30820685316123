import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, Observable } from 'rxjs';
import { map } from 'rxjs';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { EnvState } from 'src/app/modules/shared/helpers/env-state';
import { AuthRepository } from 'src/app/state/auth.repository';
import { CompaniesRepository, companySortOptions } from 'src/app/state/companies.repository';
import { CompaniesService } from 'src/app/state/companies.service';
import { TenantFeatures } from 'src/app/state/feature.repository';
import { TemplatesService } from 'src/app/state/templates.service';

@Component({
  selector: 'app-company-page',
  templateUrl: './company-page.component.html',
  styleUrls: ['./company-page.component.scss']
})
export class CompanyPageComponent implements OnInit {
  search = '';
  
  sortOptions = companySortOptions;
  TenantFeatures = TenantFeatures;
  features = this.env.features;
  exportFactory = () => this.service.export();
  exportCompanyFactory = () => this.service.exportByCompany(false, false);
  importFactory = (file: File) => this.service.import(file);
  companiesList: Observable<any[]>;
  templateOptions: DatalistOption[] | null = null;
  returnUrl = "/companies/clients";
  companiesType: string;
  isSync = false;
  isSyncError = false;
  templateId = '';
  companiesTList = new Array<string>();


  constructor(
    public service: CompaniesService,
    private tService: TemplatesService,
    public repo: CompaniesRepository,
    public auth: AuthRepository,
    public router: ActivatedRoute,
    private http: HttpClient,
    public env: EnvState,
    public authRepo: AuthRepository
  )
  {
    this.companiesType = router.snapshot.data.companiesType;

    this.companiesList = this.repo.page$;
     if (this.companiesType === "vendors") {
       this.companiesList = this.repo.page$.pipe(
         map(x => x.filter(f => f.canBeVendor === true)));
       this.exportCompanyFactory = () => this.service.exportByCompany(false, true);
       this.returnUrl = "/companies/vendors";
    }
    else if (this.companiesType === "clients") {
      this.companiesList = this.repo.page$.pipe(
        map(x => x.filter(f => f.canBeClient === true)));
        this.exportCompanyFactory = () => this.service.exportByCompany(true, false)
    }

    this.tService.load().subscribe(value => {
      if (!value) {
        this.templateOptions = null;
      } else {
        this.templateOptions = value.data.map(x => ({
          value: x.id,
          label: x.name,
          sublabel: x.description
        }));
      }
    });
  }

  checkCompanyType(isEconomic: boolean | null, companyType?: string) {
    if(!isEconomic) {
      return true;
    } 
    else {
      if(companyType === 'vendors') {
        return true;
      }
      else {
        return false;
      }
    }    
  }

  ngOnInit(): void {
    this.service.reloadPageT(10, this.companiesType === "vendors").subscribe();   
  }

  loadBySearch($event: string) {    
    this.service.reloadPageT(10, this.companiesType === 'vendors', $event).subscribe(); 
  }

  updateValue(value: any) {
    this.templateId = value;
  }

  updateTemplates() {
    this.service.updateTemplates(this.templateId, this.companiesTList).subscribe(() => {
      this.templateId = '';
      this.companiesTList = [];
      this.service.reloadPageT(10, this.companiesType === "vendors").subscribe();
    });
  }

  sync() {
    if (this.isSync) {
      return false;
    }
    this.isSyncError = false;
    this.isSync = true;
    this.http.post('/api/sync/clientsimport', null)
      .subscribe({
        complete: () => window.location.reload(),
        error: () => {
          this.isSync = false;
          this.isSyncError = true;
        }
      });
    return false;
  }
}
