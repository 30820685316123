<div class="row mt-1">
    <div class="col-12">
      <div class="card shadow-md mb-4">
        <ul class="list-group list-group-flush">
          <app-model-card *ngFor="let template of templates"
            [title]="template.name"
            [subtitle]="template?.description"
            icon="bi-bank"
            [date]="template.createdAt"
            [isDeleteDisabled]="false"
            [id]="template.id"
            [routerLinkBase]="'/templates'"
            [isDeleteDisabled]="template.isBeingUsed"
            (deleteClick)="deleteConfirmation = template"></app-model-card>
        </ul>
      </div>
    </div>
</div>
<app-confirm-delete *ngIf="deleteConfirmation"
  [deleteCandidate]="deleteConfirmation.name"
  (confirm)="delete.emit(deleteConfirmation.id);deleteConfirmation = null;"
  (cancel)="deleteConfirmation = null"></app-confirm-delete>
  