import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { DayjsService } from 'src/app/modules/shared/services/dayjs.service';
import { LocationService } from 'src/app/modules/shared/services/location.service';
import { Accessory } from 'src/app/state/accessories.repository';
import { Cleaning } from 'src/app/state/cleaning.repository';
import { Company } from 'src/app/state/companies.repository';
import { Order, OrderFamily } from 'src/app/state/orders.repository';
import { Product } from 'src/app/state/products.repository';
import { TripEvent } from 'src/app/state/trips.repository';

@Component({
  selector: 'app-order-info-form',
  templateUrl: './order-info-form.component.html',
  styleUrls: ['./order-info-form.component.scss'],
})
export class OrderInfoFormComponent implements OnInit {
  testsLocation = 'localhost:9876';
  @ViewChild('placePickRef') placePickRef: GooglePlaceDirective | null = null;
  @ViewChild('placeDelRef') placeDelRef: GooglePlaceDirective | null = null;
  myOptions = new Options({
    componentRestrictions: {
      country: 'AU',
    },
  });

  formattedaddress = ' ';
  @Input() action: 'start' | 'stop' | 'unload' | 'load' | 'takephoto' | null =
    null;
  @Input() orderFamily: OrderFamily | null = null;
  @Input() features: string[] = [];
  @Input() initialEvent?: TripEvent | null;
  @Input() order: Order | null = null;
  @Input() id?: string | null = null;
  @Output() orderSubmit = new EventEmitter<Partial<TripEvent>>();
  @Output() orderBaseSubmit = new EventEmitter<Partial<Order>>();
  @Output() rejectOrder = new EventEmitter<string>();
  @Input() set deletedProducts(value: Product[] | null) {
    if (!value) {
      this.delProducts = null;
    } else {
      this.delProducts = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }

  @Input() set deletedAccessories(value: Accessory[] | null) {
    if (!value) {
      this.delAccessories = null;
    } else {
      this.delAccessories = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }

  @Input() set deletedCompanies(value: Company[] | null) {
    if (!value) {
      this.delCompanies = null;
    } else {
      this.delCompanies = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }

  @Input() set deletedCleanings(value: Cleaning[] | null) {
    if (!value) {
      this.delCleanings = null;
    } else {
      this.delCleanings = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }
  @Input() set accessories(value: Accessory[] | null) {
    if (!value) {
      this.accessoryOptions = null;
    } else {
      this.accessoryOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
        sublabel: x.description,
      }));
    }
  }
  @Input() set cleanings(value: Cleaning[] | null) {
    if (!value) {
      this.cleaningOptions = null;
    } else {
      this.cleaningOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
        sublabel: x.description,
      }));
    }
  }
  @Input() set companies(value: Company[] | null) {
    if (!value) {
      this.clientOptions = null;
      this.vendorOptions = null;
    } else {
      this.clientOptions = value
        .filter((x) => x.canBeClient)
        .map((x) => this.mapCompany(x));
      this.vendorOptions = value
        .filter((x) => x.canBeVendor)
        .map((x) => this.mapCompany(x));
    }
  }
  @Input() set products(value: Product[] | null) {
    if (!value) {
      this.productOptions = null;
    } else {
      this.productOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
        sublabel: x.description,
      }));
    }
  }
  @Input() isLoading: boolean | null = null;

  form?: UntypedFormGroup;
  delCompanies: DatalistOption[] | null = null;
  delCleanings: DatalistOption[] | null = null;
  delProducts: DatalistOption[] | null = null;
  delAccessories: DatalistOption[] | null = null;
  deletedSelected: boolean = false;
  accessoryOptions: DatalistOption[] | null = null;
  cleaningOptions: DatalistOption[] | null = null;
  clientOptions: DatalistOption[] | null = null;
  vendorOptions: DatalistOption[] | null = null;
  productOptions: DatalistOption[] | null = null;
  files? = new Array<File>();
  urls = new Array<string>();
  @Input() requestInProgress: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private locationService: LocationService,
    public ngDay: DayjsService
  ) {
    this.myOptions = new Options({
      componentRestrictions: {
        country: 'DK',
      },
    });
  }

  ngOnInit(): void {
    const dayObj = this.ngDay.dayjs.utc(this.initialEvent?.eventTime).local();
    let weight = this.order?.weight?.toString();
    if (weight?.includes('.')) {
      weight = weight.replace(/\./g, ',');
    }

    if (this.action !== 'takephoto' && this.order) {
      this.form = this.formBuilder.group({
        km: this.initialEvent?.km
          ? [this.initialEvent?.km, Validators.required]
          : [0, Validators.required],
        weight: this.order?.weight
          ? [weight, [Validators.required, this.commaOccurrenceValidator()]]
          : [0, [Validators.required, this.commaOccurrenceValidator()]],
        clientId: [
          this.initialEvent?.clientId
            ? this.initialEvent?.clientId
            : this.order?.clientId,
          Validators.required,
        ],
        vendorId: [
          this.initialEvent?.vendorId
            ? this.initialEvent?.vendorId
            : this.order?.vendorId,
        ],
        accessoryId: [
          this.initialEvent?.accessoryId
            ? this.initialEvent?.accessoryId
            : this.order?.accessoryId,
        ],
        contactPerson: [
          this.initialEvent?.contactPerson
            ? this.initialEvent?.contactPerson
            : this.order?.contactPerson,
        ],
        reference: [
          this.initialEvent?.reference
            ? this.initialEvent?.reference
            : this.order?.reference,
        ],
        comment: [
          this.initialEvent?.comment
            ? this.initialEvent?.comment
            : this.order?.comment,
        ],
        cleaningId: [
          this.initialEvent?.cleaningId
            ? this.initialEvent?.cleaningId
            : this.order?.cleaningId,
        ],
        note: [
          this.action === 'start' || this.action === 'stop'
            ? this.order?.note
            : '',
        ],
        tenantNote: [
          this.initialEvent?.tenantNote
            ? this.initialEvent?.tenantNote
            : this.order?.tenantNote,
        ],
        productIds: [this.order?.products?.map((x) => x.id)],
        type: [this.initialEvent?.type],
        date: [dayObj.format('YYYY-MM-DD')],
        time: [dayObj.format('HH:mm:ss')],
        plannedToStartAt: [this.order?.plannedToStartAt],
        plannedToEndAt: [this.order?.plannedToEndAt],
        pickUpAddress: [this.order?.pickUpAddress],
        deliveryAddress: [this.order?.deliveryAddress],
      });
    } else {
      this.form = this.formBuilder.group({
        km: [0],
        weight: [
          weight || 0,
          [Validators.required, this.commaOccurrenceValidator()],
        ],
        clientId: [this.initialEvent?.clientId],
        vendorId: [this.initialEvent?.vendorId],
        accessoryId: [this.initialEvent?.accessoryId],
        contactPerson: [this.initialEvent?.contactPerson],
        reference: [this.initialEvent?.reference],
        comment: [this.initialEvent?.comment],
        cleaningId: [this.initialEvent?.cleaningId],
        note: [this.initialEvent?.note],
        productIds: [this.initialEvent?.products?.map((x) => x.id)],
        type: [this.initialEvent?.type],
        date: [dayObj.format('YYYY-MM-DD')],
        time: [dayObj.format('HH:mm:ss')],
        pickUpAddress: [this.order?.pickUpAddress],
        deliveryAddress: [this.order?.deliveryAddress],
      });
    }
    this.validateDeleted();
  }

  commaOccurrenceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const inputValue = control.value as string;
      if (inputValue) {
        const commaCount = (inputValue.match(/,/g) || []).length;
        if (commaCount <= 1) {
          return null; // Return null if the validation passes
        } else {
          return { required: true }; // Return an error object if the validation fails
        }
      } else {
        return null;
      }
    };
  }

  getHeight(s?: string) {
    let length = s?.length || 0;
    let lineCount = s?.split('\n').length || 0;
    if (!s || length < 48) {
      return `${60 + lineCount * 50}px`;
    }
    if (s && length >= 48 && length < 96) {
      return `${100 + lineCount * 50}px`;
    }
    if (s && length >= 96) {
      return `${Math.round(length / 48 + lineCount) * 50}px`;
    }
    return '90px';
  }

  validateDeleted() {
    var companyCheck = this.delCompanies
      ?.map((x: any) => x.value)
      ?.filter(
        (x: any) =>
          this.form?.value?.clientId?.length > 0 &&
          this.form?.value?.clientId.includes(x)
      );

    var vendorsCheck = this.delCompanies
      ?.map((x: any) => x.value)
      ?.filter(
        (x: any) =>
          this.form?.value?.vendorId?.length > 0 &&
          this.form?.value?.vendorId.includes(x)
      );

    var accessoriesCheck = this.delAccessories
      ?.map((x: any) => x.value)
      ?.filter(
        (x: any) =>
          this.form?.value?.accessoryId?.length > 0 &&
          this.form?.value?.accessoryId.includes(x)
      );

    var cleanningsCheck = this.delCleanings
      ?.map((x: any) => x.value)
      ?.filter(
        (x: any) =>
          this.form?.value?.cleaningId?.length > 0 &&
          this.form?.value?.cleaningId.includes(x)
      );

    var productsCheck = this.delProducts
      ?.map((x: any) => x.value)
      ?.filter(
        (x: any) =>
          this.form?.value?.productIds?.length > 0 &&
          this.form?.value?.productIds.includes(x)
      );

    if (
      (productsCheck && productsCheck.length > 0) ||
      (accessoriesCheck && accessoriesCheck.length > 0) ||
      (cleanningsCheck && cleanningsCheck.length > 0) ||
      (vendorsCheck && vendorsCheck.length > 0) ||
      (companyCheck && companyCheck.length > 0)
      //||(companyCheck && companyCheck.length > 0)
    ) {
      this.deletedSelected = true;
    } else {
      this.deletedSelected = false;
    }
  }

  redirectToAddressOnMaps(destination: string) {
    if (!destination) {
      return;
    }
    const p = 'DK';
    var coordinates = this.locationService.getPosition();
    coordinates.then((x) => {
      window.open(
        `https://www.google.com/maps/dir/?api=1&origin=${x.latitude.valueOf()},${x.longitude.valueOf()}` +
          '&destination=' +
          destination +
          '&travelmode=driving',
        '_blank'
      );
    });
  }

  handleAddressChange($event: any, control: string) {
    this.formattedaddress = $event.formatted_address;
    this.updateValue(control, this.formattedaddress);
  }

  locationCheck() {
    if (window.location.host !== this.testsLocation) {
      return true;
    }
    return false;
  }
  private mapCompany(x: Company): DatalistOption {
    return {
      value: x.id,
      label: x.name,
      sublabel: [x.address, x.city, x.zipCode].filter((x) => x).join(', '),
      isActive: x.isActive,
    };
  }

  validateInput(event: KeyboardEvent) {
    const key = event.key;
    const allowedKeys = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      ',',
      'Backspace',
    ];

    if (!allowedKeys.includes(key)) {
      event.preventDefault();
    }
  }

  updateValue(control: string, value: any) {
    const controlObject = this.form?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
    this.validateDeleted();
  }

  onSubmit() {
    if (this.form && !this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    const date = this.form?.value?.date.toString();
    const time = this.form?.value?.time.toString();
    const date_time = this.ngDay
      .dayjs(date + ' ' + time)
      .utc()
      .toDate();
    let weight = this.form?.value.weight;

    if (weight && weight.toString().includes(',')) {
      weight = +this.form?.value.weight.replace(/\,/g, '.');
    }
    this.orderBaseSubmit.emit({
      pickUpAddress: this.form?.value?.pickUpAddress,
      deliveryAddress: this.form?.value?.deliveryAddress,
    });
    let note = this.form?.value.note
      ? this.form?.value.note.toString().replace(/(\S+)[\s\n]+$/, '$1')
      : '';
    this.orderSubmit.emit({
      km: this.form?.value.km,
      weight: weight,
      accessoryId: this.form?.value.accessoryId || null,
      contactPerson: this.form?.value.contactPerson,
      reference: this.form?.value.reference,
      comment: this.form?.value.comment,
      cleaningId: this.form?.value.cleaningId || null,
      clientId: this.form?.value.clientId || null,
      vendorId: this.form?.value.vendorId || null,
      note: note,
      tenantNote: this.form?.value.tenantNote,
      productIds: this.form?.value.productIds,
      type: this.form?.value.type,
      eventTime: date_time,
      tripFiles: this.files,
      // pickUpAddress: this.form?.value.pickUpAddress,
      // deliveryAddress: this.form?.value.deliveryAddress
    });
    this.form?.markAsUntouched();
  }
}
