import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { combineLatest, map } from 'rxjs';
import { AuthRepository, UserRoles } from 'src/app/state/auth.repository';
import { AuthService } from 'src/app/state/auth.service';
import { UiRepository } from 'src/app/state/ui.repository';
import { EnvState } from './modules/shared/helpers/env-state';
import { ConnectionService } from './modules/shared/services/connection.service';
import { DriverDataPreloaderService } from './modules/truck/services/driver-data-preloader.service';
import { Location, PopStateEvent } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  darkModeSubscription = this.ui.isDarkMode$.subscribe((val) =>
    this.switchClass('html', 'dark-mode', val)
  );
  sessionRefreshSubscription = this.auth
    .autoRefresh()
    .subscribe(() => console.info('Session refreshed'));
  sessionExpiredSubscription = this.authRepo.isExpired$.subscribe(() => {
    if (!this.router.url.startsWith('/signin')) {
      this.ui.setSessionOver();
    }
  });
  menuOpenedSubscription = this.ui.isMenuOpened$.subscribe((val) =>
    this.switchClass('body', 'menu-open', val)
  );
  menuExpandedSubscription = this.ui.isMenuExpanded$.subscribe((val) =>
    this.switchClass('body', 'menu-expanded', val)
  );
  userRoleSubscription = this.authRepo.roles$.subscribe((roles) => {
    let body = document.getElementsByTagName('body')[0];
    body.classList.forEach((element) => {
      if (element.startsWith('user-role-')) {
        body.classList.remove(element);
      }
    });
    roles.forEach((role) =>
      body.classList.add(`user-role-${role.toLocaleLowerCase()}`)
    );

    if (roles.indexOf(UserRoles.Driver) >= 0) {
      this.preloader.preloadDrivingModels();
    }
  });

  isSessionRefreshModalDisplayed$ = combineLatest([
    this.ui.isSessionOver$,
    this.connection.isOnline$,
  ]).pipe(map(([sesionOver, online]) => sesionOver && online));

  features = this.env.features;

  constructor(
    private ui: UiRepository,
    private auth: AuthService,
    private authRepo: AuthRepository,
    private router: Router,
    private preloader: DriverDataPreloaderService,
    public env: EnvState,
    private connection: ConnectionService,
    private location: Location
  ) {}

  ngOnDestroy(): void {
    this.darkModeSubscription.unsubscribe();
    this.sessionRefreshSubscription.unsubscribe();
    this.sessionExpiredSubscription.unsubscribe();
    this.menuOpenedSubscription.unsubscribe();
    this.menuExpandedSubscription.unsubscribe();
    this.userRoleSubscription.unsubscribe();
  }

  private lastPoppedUrl?: string;
  private yScrollStack: number[] = [];
  ngOnInit() {
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((ev: any) => {
      if (ev instanceof NavigationStart) {
        if (ev.url != this.lastPoppedUrl)
          this.yScrollStack.push(window.scrollY);
      } else if (ev instanceof NavigationEnd) {
        if (ev.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;

          window.scrollTo(0, this.yScrollStack.pop()!);
        } else {
          document.getElementById('scrol_anchor')?.scrollIntoView();
        }
      }
    });
  }

  switchClass(element: string, className: string, enabled: boolean) {
    let elem = document.getElementsByTagName(element)[0];
    if (enabled) {
      elem.classList.add(className);
    } else {
      elem.classList.remove(className);
    }
  }

  resolveActionTitle(relativeUrl: string, body: any, method: string) {
    const urlSegments = relativeUrl.split('/');
    const urlSegmentsCount = urlSegments.length;
    const lastSegment = urlSegments[urlSegmentsCount - 1];
    if (lastSegment === 'events') {
      switch (body.type) {
        case 'DayStart':
          return $localize`:Offline event - Day started:Day started`;
        case 'DayEnd':
          return $localize`:Offline event - Day ended:Day ended`;
        case 'Pause':
          return $localize`:Offline event - Pause added:Pause added`;
        case 'TripStart':
          return $localize`:Offline event - Trip started:Trip started`;
        case 'TripEnd':
          return $localize`:Offline event - Trip ended:Trip ended`;
        case 'Unload':
          return $localize`:Offline event - Cargo unloaded:Cargo unloaded`;
      }
    }
    if (lastSegment === 'settings') {
      return $localize`:Offline event - Personal settings updated:Personal settings updated`;
    }
    const firstSegment = urlSegments[0];
    if (urlSegmentsCount === 1 && firstSegment === 'trips') {
      return $localize`:Offline event - Trip initialized:Trip initialized`;
    } else if (urlSegmentsCount === 1 && firstSegment === 'workdays') {
      return $localize`:Offline event - Day initialized:Day initialized`;
    }
    let actionNameTitleCase =
      firstSegment.charAt(0).toUpperCase() + firstSegment.substring(1);
    if (firstSegment === 'workdayevents') {
      actionNameTitleCase = $localize`:Offline event - Workday event added or edited:Workday event`;
    } else if (firstSegment === 'tripevents') {
      actionNameTitleCase = $localize`:Offline event - Trip event added or edited:Trip event`;
    }
    return `${actionNameTitleCase}: ${method === 'POST' ? 'add' : 'edit'}`;
  }
}
