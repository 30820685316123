import { formatDate } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_ASYNC_VALIDATORS,
  Validators,
} from '@angular/forms';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { ROLE_NAMES } from 'src/app/modules/shared/pipes/translate-roles.pipe';
import { AuthRepository, UserRoles } from 'src/app/state/auth.repository';
import { Tenant } from 'src/app/state/tenants.repository';
import { User } from 'src/app/state/users.repository';
import { UserCreateUpdateDto } from 'src/app/state/users.service';

@Component({
  selector: 'app-user-basic-info-form',
  templateUrl: './user-basic-info-form.component.html',
  styleUrls: ['./user-basic-info-form.component.scss'],
})
export class UserBasicInfoFormComponent implements OnInit {
  readonly ROLE_DESCRIPTIONS = {
    [UserRoles.Superamin]: $localize`:Role description - Superadmin:Can edit system data and create tenants`,
    [UserRoles.TenantAdmin]: $localize`:Role description - Administrator:Can view and edit all data inside a tenant`,
    [UserRoles.Driver]: $localize`:Role description - Driver:Can create trips`,
  };
  ROLE_NAMES = ROLE_NAMES;
  isSuperAdmin?: boolean;
  tenantOptions: DatalistOption[] | null = null;
  roleOptions: UserRoles[] = [];
  form?: UntypedFormGroup;
  file: File | null = null;

  @Input() initialValue?: User | null;
  @Input() set tenants(value: Tenant[] | null) {
    if (!value) {
      this.tenantOptions = null;
    } else {
      this.tenantOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }

  @Output() userSubmit = new EventEmitter<UserCreateUpdateDto>();

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    if (this.form) {
      if ((event && event.item(0)) instanceof File) {
        this.file = event && event.item(0);
        this.form.value.picture = this.file;
      }
    }
  }

  updateValue(control: string, value: any) {
    const controlObject = this.form?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authRepo: AuthRepository
  ) {
    if (this.authRepo.isInRole(UserRoles.Superamin)) {
      this.roleOptions = Object.values(UserRoles);
    } else if (this.authRepo.isInRole(UserRoles.TenantAdmin)) {
      this.roleOptions = Object.values(UserRoles).filter(
        (r) => r !== UserRoles.Superamin
      );
    }
  }

  ngOnInit(): void {
    const birthday =
      this.initialValue && this.initialValue.birthday
        ? formatDate(this.initialValue.birthday, 'yyyy-MM-dd', 'en')
        : '';
    this.isSuperAdmin = this.initialValue?.roles[0] === 'Superadmin';
    this.form = this.formBuilder.group({
      name: [this.initialValue?.name || ''],
      surname: [this.initialValue?.surname || ''],
      email: [
        this.initialValue?.email,
        [Validators.required, Validators.email],
      ],
      phone: [this.initialValue?.phoneNumber || ''],
      picture: [this.initialValue?.image || ''],
      location: [this.initialValue?.location || ''],
      title: [this.initialValue?.title || ''],
      birthday: [birthday],
      password: ['', Validators.required],
      roles: this.formBuilder.array(
        this.roleOptions.map(
          (r) => this.initialValue && this.initialValue.roles.indexOf(r) >= 0
        )
      ),
      tenant: [this.initialValue?.tenantId || ''],
    });
    if (this.initialValue) {
      this.removeRequired(this.form?.controls['password']);
    }
    this.checkIfSuperAdmin();
    if (this.initialValue?.roles[0] === 'Superadmin') {
      this.removeRequired(this.form?.controls['tenant']);
    }
    if (!this.authRepo.isInRole(UserRoles.Superamin)) {
      this.removeRequired(this.form?.controls['tenant']);
    }
  }

  checkIfSuperAdmin() {
    this.addRequired(this.form?.controls['tenant']);
    this.form?.controls['roles'].valueChanges.subscribe((x) => {
      if (x[1] || x[2]) {
        this.addRequired(this.form?.controls['tenant']);
      } else if (x[1] && x[2]) {
        this.addRequired(this.form?.controls['tenant']);
      } else if (!x[0] && !x[1] && !x[2]) {
        this.addRequired(this.form?.controls['tenant']);
      } else if (x[0] && (x[1] || x[2])) {
        this.addRequired(this.form?.controls['tenant']);
      } else {
        this.removeRequired(this.form?.controls['tenant']);
      }
    });
    if (!this.authRepo.isInRole(UserRoles.Superamin)) {
      this.removeRequired(this.form?.controls['tenant']);
    }
  }

  addRequired(control: AbstractControl | undefined) {
    control?.addValidators(Validators.required);
    control?.updateValueAndValidity();
  }

  removeRequired(control: AbstractControl | undefined) {
    control?.removeValidators(Validators.required);
    control?.updateValueAndValidity();
  }

  onSubmit() {
    if (!this.authRepo.isInRole(UserRoles.Superamin)) {
      this.removeRequired(this.form?.controls['tenant']);
    }

    if (this.form && !this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    const roles = (this.form?.value?.roles as boolean[])
      .map((x, i) => (x && this.roleOptions[i]) as UserRoles)
      .filter((x) => !!x);

    if (this.form?.value?.roles[0]) {
      this.isSuperAdmin = true;
    }

    this.userSubmit.emit({
      email: this.form?.value?.email,
      phoneNumber: this.form?.value?.phone,
      name: this.form?.value?.name,
      surname: this.form?.value?.surname,
      title: this.form?.value?.title,
      location: this.form?.value?.location,
      birthday: this.form?.value?.birthday || null,
      image: this.file,
      password: this.form?.value?.password,
      tenantId: this.form?.value?.tenant || null,
      roles: roles,
    });

    this.form?.markAsUntouched();
  }
}
