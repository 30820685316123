<div class="btn-group" role="group">
  <button *ngIf="importFactory"
    type="button" class="btn btn-sm"
    [class.btn-danger]="isImportError"
    [class.btn-outline-primary]="!isImportError"
    [disabled]="isImporting" (click)="fileImport.click()">
    <i *ngIf="!isImporting" class="bi bi-file-earmark-arrow-up me-1"></i>
    <app-spinner *ngIf="isImporting"></app-spinner>
    <ng-container i18n="Button import">Import</ng-container>    
  </button>
  <button *ngIf="exportFactory"
    type="button" class="btn btn-sm btn-outline-primary"
    [disabled]="isExporting" (click)="export()">
    <i *ngIf="!isExporting" class="{{icon}} me-1"></i>
    <app-spinner *ngIf="isExporting"></app-spinner>
    <ng-container i18n="Button export">{{titlePrefix}}Export</ng-container>    
  </button>
</div>
<a #importExportLink class="d-none"></a>
<input #fileImport type="file" class="d-none"
  (change)="import($event)" [attr.accept]="filenameExtension">
