import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import * as dayjs from 'dayjs';
import * as moment from 'moment';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { BaseService } from 'src/app/base-service';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { DayjsService } from 'src/app/modules/shared/services/dayjs.service';
import { Accessory } from 'src/app/state/accessories.repository';
import { Cleaning } from 'src/app/state/cleaning.repository';
import { Company } from 'src/app/state/companies.repository';
import { Order } from 'src/app/state/orders.repository';
import { Product } from 'src/app/state/products.repository';
import { User } from 'src/app/state/users.repository';

@Component({
  selector: 'app-order-form',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.scss'],
})
export class OrderFormComponent implements OnInit {
  testsLocation = 'localhost:9876';
  START_TIME = $localize`:Start time label:Planned to Start at time`;
  END_TIME = $localize`:End time label:Planned to End at time`;
  chooseStart = $localize`:chooseStart2:Choose start time`;
  chooseEnd = $localize`:chooseEnd2:Choose end time`;
  @Input() orderUpdate: Order | null = null;
  @Input() orders: Order[] | null = null;
  @Input() parentId?: string | null = null;
  form?: UntypedFormGroup;
  @Output() orderEmiter = new EventEmitter<Partial<Order>>();
  @Output() addLinkedEmiter = new EventEmitter<Partial<Order>>();
  @Output() clearForm = new EventEmitter();
  driverOptions: DatalistOption[] | null = null;
  accessoryOptions: DatalistOption[] | null = null;
  cleaningOptions: DatalistOption[] | null = null;
  clientOptions: DatalistOption[] | null = null;
  vendorOptions: DatalistOption[] | null = null;
  productOptions: DatalistOption[] | null = null;
  @Input() DriversDisabled: boolean | false = false;

  @ViewChild('placePickRef') placePickRef: GooglePlaceDirective | null = null;
  @ViewChild('placeDelRef') placeDelRef: GooglePlaceDirective | null = null;
  @ViewChild('form') child: UntypedFormGroup | null = null;
  @ViewChild('recurrenceCheck') recurrenceCheckbox?: ElementRef;
  @ViewChild('weekdaysCheck') weekdaysCheckbox?: ElementRef;
  @ViewChild('daysCheck') daysCheckbox?: ElementRef;
  @ViewChild('weekMonthCheck') weekMonthCheckbox?: ElementRef;
  curentOption: number = 1;
  recurrence: boolean = false;
  daysCheckboxes: boolean[] = new Array(31).fill(false);
  daysCheckboxesCopy: boolean[] = new Array(31).fill(false);

  firstTypeReccurenceData: any = {
    dayNumbers: [],
    repeat: false,
    timeStart: '',
    timeEnd: '',
  };
  thirdTypeReccurenceData: any = {
    dayNumbers: [],
    repeatWeeks: [],
    timeStart: '',
    timeEnd: '',
  };

  myOptions = new Options({
    componentRestrictions: {
      country: 'AU',
    },
  });

  formattedaddress = ' ';

  toggleCheckbox(index: number): void {
    this.daysCheckboxesCopy[index] = !this.daysCheckboxesCopy[index];
  }

  reccurenceTime2: string = '';
  reccurenceTime22: string = '';

  changeReccurenceData(
    reccurenceType: number,
    optionType: number,
    dayNumber?: number,
    event?: any
  ) {
    this.reccurenceTimeError = false;
    this.reccurrenceEmptyError = false;
    this.reccurrenceIntervalError = false;
    switch (reccurenceType) {
      case 1:
        switch (optionType) {
          case 1:
            if (
              this.firstTypeReccurenceData.dayNumbers.indexOf(dayNumber) === -1
            ) {
              this.firstTypeReccurenceData.dayNumbers.push(dayNumber);
            } else {
              this.firstTypeReccurenceData.dayNumbers =
                this.firstTypeReccurenceData.dayNumbers.filter(
                  (x: any) => x != dayNumber
                );
            }
            break;
          case 2:
            this.firstTypeReccurenceData.repeat =
              !this.firstTypeReccurenceData.repeat;
            break;
          case 3:
            if (dayNumber == 1) {
              this.firstTypeReccurenceData.timeStart = event;
            } else if (dayNumber == 2) {
              this.firstTypeReccurenceData.timeEnd = event;
            }
            break;
        }
        break;
      case 2:
        if (dayNumber == 1) {
          this.reccurenceTime2 = event;
        } else if (dayNumber == 2) {
          this.reccurenceTime22 = event;
        }
        break;
      case 3:
        switch (optionType) {
          case 1:
            if (
              this.thirdTypeReccurenceData.dayNumbers.indexOf(dayNumber) === -1
            ) {
              this.thirdTypeReccurenceData.dayNumbers.push(dayNumber);
            } else {
              this.thirdTypeReccurenceData.dayNumbers =
                this.thirdTypeReccurenceData.dayNumbers.filter(
                  (x: any) => x != dayNumber
                );
            }
            break;
          case 2:
            if (
              this.thirdTypeReccurenceData.repeatWeeks.indexOf(dayNumber) === -1
            ) {
              this.thirdTypeReccurenceData.repeatWeeks.push(dayNumber);
            } else {
              this.thirdTypeReccurenceData.repeatWeeks =
                this.thirdTypeReccurenceData.repeatWeeks.filter(
                  (x: any) => x != dayNumber
                );
            }
            break;
          case 3:
            if (dayNumber == 1) {
              this.thirdTypeReccurenceData.timeStart = event;
            } else if (dayNumber == 2) {
              this.thirdTypeReccurenceData.timeEnd = event;
            }
            break;
        }

        break;
      default:
        return;
    }
  }
  recTimeStart: string = '11:00';
  recTimeEnd: string = '12:00';

  ngAfterViewInit() {
    if (this.orderUpdate) {
      this.recurrence = this.orderUpdate.isReccurence ?? false;
      if (this.recurrence) {
        this.curentOption = Number(this.orderUpdate.reccurence?.type);
      }
    }
    const checkbox = this.recurrenceCheckbox?.nativeElement;
    if (checkbox) {
      checkbox.checked = this.recurrence;
    }

    if (this.orderUpdate) {
      this.today = this.orderUpdate?.reccurence.start;
      this.todayPlusMonth = this.orderUpdate?.reccurence.end;
      setTimeout(() => {
        switch (this.curentOption) {
          case 1:
            if (this.weekdaysCheckbox) {
              this.weekdaysCheckbox!.nativeElement.checked = true;
            }

            this.orderUpdate?.reccurence.first.dayNumbers.forEach((f: any) => {
              document
                .getElementById('opt1ch' + f)
                ?.setAttribute('checked', 'checked');
            });

            if (this.orderUpdate?.reccurence.first.repeat) {
              document
                .getElementById('opt1ch6')
                ?.setAttribute('checked', 'checked');
            }

            this.recTimeStart = this.orderUpdate?.reccurence.first.timeStart;
            this.recTimeEnd = this.orderUpdate?.reccurence.first.timeEnd;

            break;
          case 2:
            if (this.daysCheckbox) {
              this.daysCheckbox!.nativeElement.checked = true;
            }

            for (let i = 0; i <= 30; i++) {
              if (this.orderUpdate?.reccurence.second.dayNumbers[i]) {
                document
                  .getElementById('opt2ch' + i)
                  ?.setAttribute('checked', 'checked');
              }
            }

            this.recTimeStart = this.orderUpdate?.reccurence.second.timeStart;
            this.recTimeEnd = this.orderUpdate?.reccurence.second.timeEnd;
            break;
          case 3:
            if (this.weekMonthCheckbox) {
              this.weekMonthCheckbox!.nativeElement.checked = true;
            }

            this.orderUpdate?.reccurence.third.dayNumbers.forEach((f: any) => {
              document
                .getElementById('opt3ch' + f)
                ?.setAttribute('checked', 'checked');
            });

            this.orderUpdate?.reccurence.third.repeatWeeks.forEach((f: any) => {
              f = Number(f) + 5;
              document
                .getElementById('opt3ch' + f)
                ?.setAttribute('checked', 'checked');
            });

            this.recTimeStart = this.orderUpdate?.reccurence.third.timeStart;
            this.recTimeEnd = this.orderUpdate?.reccurence.third.timeEnd;
            break;
          default:
            return;
        }
      }, 200);
    }
  }

  @Input() set accessories(value: Accessory[] | null) {
    if (!value) {
      this.accessoryOptions = null;
    } else {
      this.accessoryOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
        sublabel: x.description,
      }));
    }
  }

  today: string = '';
  todayPlusMonth: string = '';
  @Input() set cleanings(value: Cleaning[] | null) {
    if (!value) {
      this.cleaningOptions = null;
    } else {
      this.cleaningOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
        sublabel: x.description,
      }));
    }
  }

  changeReccurence() {
    this.reccurenceTimeError = false;
    this.reccurrenceEmptyError = false;
    this.reccurrenceIntervalError = false;
    const checkbox = this.recurrenceCheckbox?.nativeElement;
    if (checkbox) {
      this.recurrence = checkbox.checked;

      setTimeout(() => {
        switch (this.curentOption) {
          case 1:
            if (this.weekdaysCheckbox) {
              this.weekdaysCheckbox!.nativeElement.checked = true;
            }
            break;
          case 2:
            if (this.daysCheckbox) {
              this.daysCheckbox!.nativeElement.checked = true;
            }
            break;
          case 3:
            if (this.weekMonthCheckbox) {
              this.weekMonthCheckbox!.nativeElement.checked = true;
            }
            break;
          default:
            return;
        }
      }, 200);
    }
  }

  changeReccurenceOption(option: number) {
    this.curentOption = option;
    switch (this.curentOption) {
      case 1:
        this.weekdaysCheckbox!.nativeElement.checked = true;
        this.daysCheckbox!.nativeElement.checked = false;
        this.weekMonthCheckbox!.nativeElement.checked = false;
        break;
      case 2:
        this.daysCheckbox!.nativeElement.checked = true;
        this.weekdaysCheckbox!.nativeElement.checked = false;
        this.weekMonthCheckbox!.nativeElement.checked = false;
        break;
      case 3:
        this.weekMonthCheckbox!.nativeElement.checked = true;
        this.weekdaysCheckbox!.nativeElement.checked = false;
        this.daysCheckbox!.nativeElement.checked = false;
        break;
      default:
        return;
    }
  }

  @Input() isRejected: boolean = false;
  @Input() isOrder: boolean = false;
  @Input() rejectedAt?: string;

  @Input() timeFrom: any;
  @Input() timeTo: any;

  @Input() set accessoriesDeleted(value: Accessory[] | null) {
    if (!value) {
      this.accessoriesOptionsDeleted = null;
    } else {
      this.accessoriesOptionsDeleted = value.map((x) => ({
        value: x.id,
        label: x.name,
        sublabel: x.description,
        isDeleted: x?.deletedAt == '' || x?.deletedAt == undefined,
      }));
    }
  }
  @Input() set cleaningsDeleted(value: Cleaning[] | null) {
    if (!value) {
      this.cleaningOptionsDeleted = null;
    } else {
      this.cleaningOptionsDeleted = value.map((x) => ({
        value: x.id,
        label: x.name,
        sublabel: x.description,
        isDeleted: x?.deletedAt == '' || x?.deletedAt == undefined,
      }));
    }
  }

  @Input() set vendorsDeleted(value: Company[] | null) {
    if (!value) {
      this.vendorsOptionsDeleted = null;
    } else {
      this.vendorsOptionsDeleted = value.map((x) => this.mapCompanyDel(x));
    }
  }

  vendorsOptionsDeleted: any;
  accessoriesOptionsDeleted: DatalistOption[] | null = [];
  cleaningOptionsDeleted: DatalistOption[] | null = [];

  @Input() set companies(value: Company[] | null) {
    if (!value) {
      this.clientOptions = null;
      this.vendorOptions = null;
    } else {
      this.clientOptions = value
        .filter((x) => x.canBeClient)
        .map((x) => this.mapCompany(x));
      this.vendorOptions = value
        .filter((x) => x.canBeVendor)
        .map((x) => this.mapCompany(x));
    }
  }

  clientOptionsDeleted: any;
  vandorOptionsDeleted: any;

  @Input() set companiesDeleted(value: Company[] | null) {
    if (!value) {
      this.clientOptionsDeleted = null;
      this.vandorOptionsDeleted = null;
    } else {
      this.clientOptionsDeleted = value
        .filter((x) => x.canBeClient)
        .map((x) => this.mapCompanyDel(x));
      // this.vandorOptionsDeleted = value
      //   .filter((x) => x.canBeVendor)
      //   .map((x) => this.mapCompany(x));
    }
  }

  @Input() set products(value: any) {
    if (value) {
      this.productOptions = value.map((x: any) => ({
        value: x.id,
        label: x.name,
        sublabel: x.description,
      }));
    }
  }

  productOptionsDeleted: any;

  @Input() set productsDeleted(value: Product[] | null) {
    if (!value) {
      this.productOptionsDeleted = null;
    } else {
      this.productOptionsDeleted = value.map((x) => ({
        value: x.id,
        label: x.name,
        sublabel: x.description,
        isDeleted: x?.deletedAt == '' || x?.deletedAt == undefined,
      }));
    }
  }

  @Input() set drivers(value: User[] | null) {
    if (!value) {
      this.driverOptions = null;
    } else {
      this.driverOptions = value.map((x) => ({
        value: x.id,
        label: x.name + ' ' + x.surname,
      }));
    }
  }

  @Input() set driversDeleted(value: User[] | null) {
    if (!value) {
      this.driverOptionsDeleted = null;
    } else {
      this.driverOptionsDeleted = value.map((x) => ({
        value: x.id,
        label: x.name + ' ' + x.surname,
        isDeleted: x?.deletedAt == '' || x?.deletedAt == undefined,
      }));
    }
  }

  timeStart: any;
  timeEnd: any;
  driverOptionsDeleted: any;
  previousFormData: any;
  parentIdS: any;

  sub1: any;
  sub2: any;
  getHeight(s?: string) {
    let length = s?.length || 0;
    let lineCount = s?.split('\n').length || 0;
    if (!s || length < 48) {
      return `${60 + lineCount * 50}px`;
    }
    if (s && length >= 48 && length < 96) {
      return `${100 + lineCount * 50}px`;
    }
    if (s && length >= 96) {
      return `${Math.round(length / 48 + lineCount) * 50}px`;
    }
    return '90px';
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    public ngDay: DayjsService,
    private baseService: BaseService
  ) {
    this.sub1 = this.baseService.previousDashboardFromData$.subscribe((x) => {
      if (x) {
        this.previousFormData = x;
      }
    });

    this.sub2 = this.baseService.orderFormParentId$.subscribe((x) => {
      this.parentIdS = x;
    });

    this.myOptions = new Options({
      componentRestrictions: {
        country: 'DK',
      },
    });

    this.sub3 = this.baseService.starttime$.subscribe((x) => {
      this.timeStart = x;
      this.recTimeStart = x;
    });
    this.sub4 = this.baseService.endtime$.subscribe((x) => {
      this.timeEnd = x;
      this.recTimeEnd = x;
    });
  }

  sub3: any;
  sub4: any;

  ngOnDestroy() {
    this.sub1?.unsubscribe();
    this.sub2?.unsubscribe();
    this.sub3?.unsubscribe();
    this.sub4?.unsubscribe();
  }

  reccurenceStart: string = '';
  reccurenceEnd: string = '';

  changeReccurenceInterval(type: number, event: any) {
    if (type == 1 && event.target.value) {
      this.reccurenceStart = event.target.value;
    } else if (type == 2 && event.target.value) {
      this.reccurenceEnd = event.target.value;
    }
  }

  startDateChange() {
    if (!this.orderUpdate) {
      this.form!.controls['plannedToEndAtDate'].setValue(
        this.form?.value.plannedToStartAtDate
      );
    }
  }

  ngOnInit(): void {
    var today = new Date();
    // Get the year, month, and day components
    var year = today.getFullYear();
    var month = String(today.getMonth() + 1).padStart(2, '0');
    var day = String(today.getDate()).padStart(2, '0');

    // Create a string in "YYYY-MM-DD" format
    this.today = year + '-' + month + '-' + day;
    this.todayPlusMonth = year + '-' + (Number(month) + 1) + '-' + day;

    this.reccurenceStart = this.today;
    this.reccurenceEnd = this.todayPlusMonth;

    today.setMonth(today.getMonth() + 1);
    year = today.getFullYear();
    month = String(today.getMonth() + 1).padStart(2, '0');
    day = String(today.getDate()).padStart(2, '0');

    this.todayPlusMonth = year + '-' + month + '-' + day;

    if (this.parentIdS) {
      this.DriversDisabled = true;
    } else {
      this.DriversDisabled = false;
    }

    const dayObjStart = this.ngDay.dayjs
      .utc(this.orderUpdate?.plannedToStartAt)
      .local();
    const dayObjEnd = this.ngDay.dayjs
      .utc(this.orderUpdate?.plannedToEndAt)
      .local();
    let weight = this.orderUpdate?.weight?.toString();
    if (weight?.includes('.')) {
      weight = weight.replace(/\./g, ',');
    }

    if (!this.previousFormData) {
      if (this.orderUpdate) {
        this.timeFrom = this.ngDay.dayjs
          .utc(this.orderUpdate?.plannedToStartAt)
          .local()
          .format('HH:mm');

        this.timeTo = this.ngDay.dayjs
          .utc(this.orderUpdate?.plannedToEndAt)
          .local()
          .format('HH:mm');
      }

      this.form = this.formBuilder.group({
        plannedToStartAtDate: [
          this.ngDay.dayjs
            .utc(this.orderUpdate?.plannedToStartAt)
            .local()
            .format('YYYY-MM-DD'),
          Validators.required,
        ],
        plannedToStartAtTime: [this.timeFrom, Validators.required],
        plannedToEndAtDate: [
          this.ngDay.dayjs
            .utc(this.orderUpdate?.plannedToEndAt)
            .local()
            .format('YYYY-MM-DD'),
          Validators.required,
        ],
        plannedToEndAtTime: [this.timeTo, Validators.required],
        driverIds: [this.orderUpdate?.driverIds],
        parentId: [this.orderUpdate?.parentId],
        weight: [weight || 0],
        deliveryAddress: [this.orderUpdate?.deliveryAddress],
        pickUpAddress: [this.orderUpdate?.pickUpAddress],
        clientId: [this.orderUpdate?.clientId, Validators.required],
        vendorId: [this.orderUpdate?.vendorId],
        accessoryId: [this.orderUpdate?.accessoryId],
        contactPerson: [this.orderUpdate?.contactPerson],
        reference: [this.orderUpdate?.reference],
        comment: [this.orderUpdate?.comment],
        cleaningId: [this.orderUpdate?.cleaningId],
        note: [this.orderUpdate?.note],
        tenantNote: [this.orderUpdate?.tenantNote],
        productIds: [this.orderUpdate?.products?.map((x) => x.id)],
        tripEvents: [this.orderUpdate?.tripEvents],
        reccurenceTimeOption1: ['11:00'],
        commentPhotos: this.formBuilder.array([]),
      });
      this.setCommentPhotos();
    } else {
      var startTime = this.ngDay.dayjs
        .utc(this.orderUpdate?.plannedToStartAt)
        .local()
        .format('YYYY-MM-DD');

      var endTime = this.ngDay.dayjs
        .utc(this.orderUpdate?.plannedToEndAt)
        .local()
        .format('YYYY-MM-DD');

      if (this.parentIdS) {
        startTime = moment(
          moment().format('YYYY-MM-DD') +
            ' ' +
            this.ngDay.dayjs
              .utc(this.previousFormData?.plannedToStartAt)
              .local()
              .format('HH:mm')
        ).format('HH:mm');

        endTime = moment(
          moment().format('YYYY-MM-DD') +
            ' ' +
            this.ngDay.dayjs
              .utc(this.previousFormData?.plannedToEndAt)
              .local()
              .format('HH:mm')
        ).format('HH:mm');

        this.timeFrom = startTime;
        this.timeTo = endTime;
      }

      this.form = this.formBuilder.group({
        plannedToStartAtDate: [
          this.ngDay.dayjs
            .utc(this.previousFormData?.plannedToStartAt)
            .local()
            .format('YYYY-MM-DD'),
          Validators.required,
        ],
        plannedToStartAtTime: [startTime, Validators.required],
        plannedToEndAtDate: [
          this.ngDay.dayjs
            .utc(this.previousFormData?.plannedToEndAt)
            .local()
            .format('YYYY-MM-DD'),
          Validators.required,
        ],
        plannedToEndAtTime: [endTime, Validators.required],
        driverIds: [this.previousFormData?.driverIds],
        parentId: [
          this.parentIdS ? this.parentIdS : this.previousFormData?.parentId,
        ],
        weight: [weight || 0],
        deliveryAddress: [this.previousFormData?.deliveryAddress],
        pickUpAddress: [this.previousFormData?.pickUpAddress],
        clientId: [this.previousFormData?.clientId, Validators.required],
        vendorId: [this.previousFormData?.vendorId],
        accessoryId: [this.previousFormData?.accessoryId],
        contactPerson: [this.previousFormData?.contactPerson],
        reference: [this.previousFormData?.reference],
        comment: [this.previousFormData?.comment],
        cleaningId: [this.previousFormData?.cleaningId],
        note: [this.previousFormData?.note],
        tenantNote: [this.previousFormData?.tenantNote],
        productIds: [this.previousFormData?.productIds],
        tripEvents: [this.previousFormData?.tripEvents],
        reccurenceTimeOption1: ['11:00'],
        commentPhotos: this.formBuilder.array([]),
      });
    }
    if (this.clientOptions) {
      this.clientOptions = this.clientOptions.filter(
        (x) => x.isActive || x.value == this.form?.value.clientId
      );
    }
    this.validateDeleted();
  }

  setCommentPhotos() {
    let control = <UntypedFormArray>this.form?.controls.commentPhotos;
    this.orderUpdate?.commentPhotos?.forEach((x) => {
      control.push(
        this.formBuilder.group({
          id: [x.id],
          tripId: [x.tripId],
          imagePath: [x.imagePath],
        })
      );
    });
  }

  timeChange() {
    this.timeStart = this.form?.value.plannedToStartAtTime;
    this.timeEnd = this.form?.value.plannedToEndAtTime;
  }

  validateDeleted() {
    var companyCheck = this.clientOptionsDeleted
      ?.map((x: any) => x.value)
      ?.filter(
        (x: any) =>
          this.form?.value?.clientId?.length > 0 &&
          this.form?.value?.clientId.includes(x)
      );

    var driversCheck = this.driverOptionsDeleted
      ?.map((x: any) => x.value)
      ?.filter(
        (x: any) =>
          this.form?.value?.driverIds?.length > 0 &&
          this.form?.value?.driverIds.includes(x)
      );

    var vendorsCheck = this.vendorsOptionsDeleted
      ?.map((x: any) => x.value)
      ?.filter(
        (x: any) =>
          this.form?.value?.vendorId?.length > 0 &&
          this.form?.value?.vendorId.includes(x)
      );

    var accessoriesCheck = this.accessoriesOptionsDeleted
      ?.map((x: any) => x.value)
      ?.filter(
        (x: any) =>
          this.form?.value?.accessoryId?.length > 0 &&
          this.form?.value?.accessoryId.includes(x)
      );

    var cleanningsCheck = this.cleaningOptionsDeleted
      ?.map((x: any) => x.value)
      ?.filter(
        (x: any) =>
          this.form?.value?.cleaningId?.length > 0 &&
          this.form?.value?.cleaningId.includes(x)
      );

    var productsCheck = this.productOptionsDeleted
      ?.map((x: any) => x.value)
      ?.filter(
        (x: any) =>
          this.form?.value?.productIds?.length > 0 &&
          this.form?.value?.productIds.includes(x)
      );

    if (
      (productsCheck && productsCheck.length > 0) ||
      (accessoriesCheck && accessoriesCheck.length > 0) ||
      (cleanningsCheck && cleanningsCheck.length > 0) ||
      (vendorsCheck && vendorsCheck.length > 0) ||
      (driversCheck && driversCheck.length > 0) ||
      (companyCheck && companyCheck.length > 0)
      //||(companyCheck && companyCheck.length > 0)
    ) {
      this.deletedSelected = true;
    } else {
      this.deletedSelected = false;
    }
  }

  getStartTime() {
    return this.form?.value.plannedToStartAtTime;
  }
  getEndTime() {
    return this.form?.value.plannedToEndAtTime;
  }

  private mapCompany(x: Company): DatalistOption {
    return {
      value: x.id,
      label: x.name,
      sublabel: [x.address, x.city, x.zipCode].filter((x) => x).join(', '),
      isActive: x.isActive,
    };
  }

  private mapCompanyDel(x: Company): DatalistOption {
    return {
      value: x.id,
      label: x.name,
      sublabel: [x.address, x.city, x.zipCode].filter((x) => x).join(', '),
      isDeleted: x?.deletedAt == '' || x?.deletedAt == undefined,
    };
  }

  updateValue(control: string, value: any) {
    const controlObject = this.form?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
    this.validateDeleted();
  }

  onDateChange(): void {
    const startDate = this.form?.get('plannedToStartAtDate')?.value;
    const endDateControl = this.form?.get('plannedToEndAtDate');
    endDateControl?.setValue(startDate);
  }

  handleAddressChange($event: any, control: string) {
    this.formattedaddress = $event.formatted_address;
    this.updateValue(control, this.formattedaddress);
  }

  markAsInvalid(control: AbstractControl | undefined) {
    control?.addValidators(Validators.required);
    control?.setErrors({ incorrect: true });
  }

  markAsValid(control: AbstractControl | undefined) {
    control?.addValidators(Validators.required);
    control?.setErrors({ correct: true });
  }

  validateInput(event: KeyboardEvent) {
    const key = event.key;
    const allowedKeys = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      ',',
      'Backspace',
    ];

    if (!allowedKeys.includes(key)) {
      event.preventDefault();
    }
  }
  deletedSelected: boolean = false;

  locationCheck() {
    if (window.location.host !== this.testsLocation) {
      return true;
    }
    return false;
  }

  childSubmit() {
    if (this.form && !this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    document.getElementById('cancel_btn')?.click();

    const start_date =
      this.form?.value?.plannedToStartAtDate?.toString() ??
      this.previousFormData.plannedToStartAtDate;
    const start_time = this.timeStart;

    const start_date_time = this.ngDay
      .dayjs(start_date + ' ' + start_time)
      .toDate();

    const end_date =
      this.form?.value?.plannedToEndAtDate?.toString() ??
      this.previousFormData.plannedToEndAtDate;
    const end_time = this.timeEnd;
    const end_date_time = this.ngDay.dayjs(end_date + ' ' + end_time).toDate();
    let weight = this.form?.value.weight;

    if (weight && weight.toString().includes(',')) {
      weight = +this.form?.value.weight.replace(/\,/g, '.');
    }

    var model = {
      parentId: this.form?.value.parentId,
      driverIds: this.form?.value.driverIds,
      weight: weight,
      deliveryAddress: this.form?.value.deliveryAddress,
      pickUpAddress: this.form?.value.pickUpAddress,
      clientId: this.form?.value.clientId,
      plannedToStartAt: start_date_time,
      plannedToEndAt: end_date_time,
      vendorId: this.form?.value.vendorId,
      accessoryId: this.form?.value.accessoryId,
      contactPerson: this.form?.value.contactPerson,
      reference: this.form?.value.reference,
      cleaningId: this.form?.value.cleaningId,
      note: this.form?.value.note,
      tenantNote: this.form?.value.tenantNote,
      productIds: this.form?.value.productIds,
      commentPhotos: this.form?.value.commentPhotos,
    };

    this.baseService.sendCancelEvent(true);
    this.baseService.changePreviousDashboardFromData(model);

    this.baseService.sendLinkedOrderEvent(model);

    //this.form?.reset();
    //this.form?.markAsUntouched();
  }

  compareTimeStrings(timeString1: string, timeString2: string): number {
    // Parse the time strings into Date objects
    const date1 = new Date(`1970-01-01T${timeString1}`);
    const date2 = new Date(`1970-01-01T${timeString2}`);

    // Compare the Date objects
    if (date1 < date2) {
      return -1;
    } else if (date1 > date2) {
      return 1;
    } else {
      return 0;
    }
  }

  reccurenceTimeError: boolean = false;
  reccurrenceEmptyError: boolean = false;
  reccurrenceIntervalError: boolean = false;

  onSubmit() {
    if (this.recurrence && !this.orderUpdate) {
      this.reccurrenceEmptyError = false;

      if (
        (this.curentOption == 1 &&
          this.firstTypeReccurenceData.dayNumbers.length <= 0) ||
        (this.curentOption == 3 &&
          this.thirdTypeReccurenceData.dayNumbers.length <= 0) ||
        (this.curentOption == 2 &&
          this.daysCheckboxesCopy.filter((x) => x).length <= 0)
      ) {
        this.reccurrenceEmptyError = true;
        return;
      }
      this.reccurrenceIntervalError = false;
      if (
        (this.curentOption == 1 &&
          this.compareTimeStrings(
            this.firstTypeReccurenceData.timeStart,
            this.firstTypeReccurenceData.timeEnd
          ) > 0) ||
        (this.curentOption == 3 &&
          this.compareTimeStrings(
            this.thirdTypeReccurenceData.timeStart,
            this.thirdTypeReccurenceData.timeEnd
          ) > 0) ||
        (this.curentOption == 2 &&
          this.compareTimeStrings(this.reccurenceTime2, this.reccurenceTime22) >
            0)
      ) {
        this.reccurrenceIntervalError = true;
        return;
      }
      this.reccurenceTimeError = false;
      if (
        (this.recurrence && !this.reccurenceStart && !this.reccurenceEnd) ||
        new Date(this.reccurenceStart) > new Date(this.reccurenceEnd)
      ) {
        this.reccurenceTimeError = true;
        return;
      }
    }

    this.baseService.sendRequestToGetTime(true);
    if (this.form && !this.form.valid) {
      this.form.markAllAsTouched();
      this.baseService.sendCancelEvent(true);
      return;
    }

    this.form?.controls.plannedToEndAtTime.setValue(this.timeEnd?.slice(0, 5));
    this.form?.controls.plannedToStartAtTime.setValue(
      this.timeStart?.slice(0, 5)
    );

    const start_date = this.form?.value
      ? this.form?.value.plannedToStartAtDate?.toString()
      : '';
    const start_time = this.form?.value
      ? this.form?.value?.plannedToStartAtTime?.toString()
      : '';

    var start = start_date + ' ' + start_time;

    if (!start_time) {
      start = this.previousFormData.plannedToStartAt;
    }

    const start_date_time = this.ngDay.dayjs(start).utc().toDate();

    const end_date = this.form?.value?.plannedToEndAtDate
      ? this.form?.value?.plannedToEndAtDate.toString()
      : '';
    const end_time = this.form?.value?.plannedToEndAtTime
      ? this.form?.value?.plannedToEndAtTime?.toString()
      : '';

    var end = end_date + ' ' + end_time;

    if (!end_time) {
      end = this.previousFormData.plannedToEndAt;
    }

    const end_date_time = this.ngDay.dayjs(end).utc().toDate();
    let weight = this.form?.value.weight;

    if (weight && weight.toString().includes(',')) {
      weight = +this.form?.value.weight.replace(/\,/g, '.');
    }

    this.baseService.changePreviousDashboardFromData(undefined);
    let note = this.form?.value.note
      ? this.form?.value.note.toString().replace(/(\S+)[\s\n]+$/, '$1')
      : '';
    this.baseService.sendSaveEvent({
      parentId: this.form?.value.parentId,
      driverIds: this.form?.value.driverIds,
      weight: weight,
      deliveryAddress: this.form?.value.deliveryAddress,
      pickUpAddress: this.form?.value.pickUpAddress,
      clientId: this.form?.value.clientId,
      plannedToStartAt: start_date_time,
      plannedToEndAt: end_date_time,
      vendorId: this.form?.value.vendorId,
      accessoryId: this.form?.value.accessoryId,
      contactPerson: this.form?.value.contactPerson,
      reference: this.form?.value.reference,
      comment: this.form?.value.comment,
      cleaningId: this.form?.value.cleaningId,
      note: note,
      tenantNote: this.form?.value.tenantNote,
      productIds: this.form?.value.productIds,
      isOrder: this.isOrder,
      isRejected: this.isRejected,
      rejectedAt: this?.rejectedAt,
      casualUpdate: true,
      isReccurence: this.recurrence,
      commentPhotos: this.form?.value.commentPhotos,
      reccurence: {
        type: this.curentOption,
        start: this.reccurenceStart,
        end: this.reccurenceEnd,
        first: this.firstTypeReccurenceData,
        second: {
          dayNumbers: this.daysCheckboxesCopy,
          timeStart: this.reccurenceTime2,
          timeEnd: this.reccurenceTime22,
        },
        third: this.thirdTypeReccurenceData,
      },
    });

    this.form?.reset();
    this.form?.markAsUntouched();
  }

  cancel() {
    this.baseService.sendCancelEvent(true);
    this.clearForm.emit();
  }

  // onDateChange(event: Event): void {
  //   const endDateControl = this.form?.get('plannedToEndAtDate');
  //   const endTimeControl = this.form?.get('plannedToEndAtTime');
  //   const startDateControl = this.form?.get('plannedToStartAtDate');
  //   const startTimeControl = this.form?.get('plannedToStartAtTime');
  //   const now = this.ngDay.dayjs.utc().add(-1, 'minutes').toDate();
  //   const startDate = startDateControl?.value;
  //   const startTime = startTimeControl?.value;
  //   const endDate = endDateControl?.value;
  //   const endTime = endTimeControl?.value;
  //   const start_date_time = this.ngDay
  //     .dayjs(startDate + ' ' + startTime)
  //     .toDate();
  //   const end_date_time = this.ngDay.dayjs(endDate + ' ' + endTime).toDate();

  //   if (
  //     startDateControl &&
  //     startTimeControl &&
  //     endDateControl &&
  //     endTimeControl
  //   ) {
  //     if (start_date_time >= end_date_time) {
  //       this.markAsInvalid(startDateControl);
  //       this.markAsInvalid(startTimeControl);
  //     }
  //     if (start_date_time < now) {
  //       this.markAsInvalid(startDateControl);
  //       this.markAsInvalid(startTimeControl);
  //     }

  //     if (end_date_time < now) {
  //       this.markAsInvalid(endDateControl);
  //       this.markAsInvalid(endTimeControl);
  //     }
  //   }
  // }
}
