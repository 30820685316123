import { Injectable } from '@angular/core';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';
import { PriceType } from './pricetypes.repository';

export interface Product {
  id: string;
  name: string;
  description?: string;
  isBeingUsed: boolean;
  productNumber: string;
  createdAt: Date;
  priceTypeId: string;
  priceType: PriceType;
  barCode: number;
  priceTypeName: string;
  deletedAt?: string;
}

export const productsSortOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'name' },
  {
    label: $localize`:Sort label Description:Description`,
    property: 'description',
  },
  { label: $localize`:Sort label Date:Date`, property: 'createdAt' },
];

@Injectable({ providedIn: 'root' })
export class ProductsRepository extends BaseRepository<Product> {
  constructor() {
    super('products', productsSortOptions);
  }
}
