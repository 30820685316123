import { Component, OnInit } from '@angular/core';
import { DeletedTripsRepository, deletedTripsSortOptions } from 'src/app/state/deletedtrips.repository';
import { DeletedTripsService } from 'src/app/state/deletedtrips.service';
import { TripsService } from 'src/app/state/trips.service';

@Component({
  selector: 'app-deleted-trips-page',
  templateUrl: './deleted-trips-page.component.html',
  styleUrls: ['./deleted-trips-page.component.scss']
})
export class DeletedTripsPageComponent  implements OnInit {
  sortOptions = deletedTripsSortOptions;
  constructor(public service: DeletedTripsService,
              public repo: DeletedTripsRepository) {}
  ngOnInit(): void {
    this.service.reloadPage().subscribe();
  }

  restore($event: string) {
    this.service.restore($event).subscribe(() => {

    });
  }
}
