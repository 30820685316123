import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UrlIdResolverService } from 'src/app/modules/shared/services/url-id-resolver.service';
import { EProductsRepository } from 'src/app/state/eproducts.repository';
import { EProductsService } from 'src/app/state/eproducts.service';
import { PriceTypeRepository } from 'src/app/state/pricetypes.repository';
import { PriceTypesService } from 'src/app/state/pricetypes.service';
import { Product } from 'src/app/state/products.repository';

@Component({
  selector: 'app-eproduct-edit-page',
  templateUrl: './eproduct-edit-page.component.html',
  styleUrls: ['./eproduct-edit-page.component.scss'],
})
export class EproductEditPageComponent implements OnInit {
  eproduct$: Observable<Product> | null = null;
  id: string | undefined;
  submitErrors: string[] | null = null;
  constructor(
    private eproductService: EProductsService,
    private priceTypeService: PriceTypesService,
    public priceTypeRepo: PriceTypeRepository,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private repo: EProductsRepository,
    private urlId: UrlIdResolverService
  ) {}

  ngOnInit(): void {
    this.eproduct$ = this.urlId.resolve(
      this.activatedRoute.paramMap,
      (id) => this.eproductService.loadOne(id),
      (id) => this.repo.one(id),
      (id) => this.repo.statusOne(id),
      (id) => (this.id = id)
    );
    this.priceTypeService.loadTypes().subscribe();
  }

  updateEProduct(product: Partial<Product>) {
    this.submitErrors = null;
    let updateResult: Observable<Product> | undefined;
    if (this.id) {
      updateResult = this.eproductService.update(this.id, product);
      this.eproductService.deleteUsedProducts(this.id).subscribe();
    }
    if (updateResult) {
      updateResult.subscribe({
        complete: () => {
          this.router.navigate(['/eproducts']);
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }
}
