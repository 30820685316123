<div
  class="modal fade"
  bsModal
  [config]="{ show: true }"
  id="addressModal"
  tabindex="-1"
  #addressModal
  aria-hidden="true"
  (onHidden)="cancel.emit()"
>
  <div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content bg-lights border-0">
      <div class="modal-header">
        <h6 class="modal-title" id="addressModalLabel" i18n="editAddress">
          Edit address
        </h6>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          i18n-aria-label="Close label @@close"
          (click)="cancel.emit()"
        ></button>
      </div>
      <div class="modal-body">
        <div class="modal-body">
          <form
            *ngIf="form && locationCheck()"
            [formGroup]="form"
            (ngSubmit)="save()"
          >
            <div class="col-12">
              <div class="form-group form-floating mb-3">
                <div class="input-group form-floating">
                  <input
                    ngx-google-places-autocomplete
                    [options]="myOptions"
                    #placePickRef="ngx-places"
                    (onAddressChange)="
                      handleAddressChange($event, 'pickUpAddress')
                    "
                    formControlName="pickUpAddress"
                    aria-label="pickUpAddress"
                    aria-describedby="pickUpAddressButton"
                    class="form-control"
                  />
                  <label
                    class="visible"
                    for="pickUpAddress"
                    i18n="pickUpAddress label"
                    >PickUp Address</label
                  >
                  <button
                    class="btn btn-outline-secondary"
                    [disabled]="
                      !form.value.pickUpAddress ||
                      form.value.pickUpAddress.length < 5
                    "
                    type="button"
                    (click)="redirectToAddressOnMaps(form.value.pickUpAddress)"
                    id="pickUpAddressButton"
                  >
                    <i class="bi bi-geo-alt-fill strong danger-text"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group form-floating mb-3">
                <div class="input-group form-floating">
                  <input
                    ngx-google-places-autocomplete
                    [options]="myOptions"
                    #placeDelRef="ngx-places"
                    (onAddressChange)="
                      handleAddressChange($event, 'deliveryAddress')
                    "
                    formControlName="deliveryAddress"
                    aria-label="deliveryAddress"
                    aria-describedby="deliveryAddressButton"
                    class="form-control"
                  />
                  <label
                    class="visible"
                    for="deliveryAddress"
                    i18n="deliveryAddress label"
                    >Delivery Address</label
                  >
                  <button
                    class="btn btn-outline-secondary"
                    [disabled]="
                      !form.value.deliveryAddress ||
                      form.value.deliveryAddress.length < 5
                    "
                    type="button"
                    (click)="
                      redirectToAddressOnMaps(form.value.deliveryAddress)
                    "
                    id="deliveryAddressButton"
                  >
                    <i class="bi bi-geo-alt-fill strong danger-text"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer justify-content-center">
          <button
            class="btn btn-primary w-100"
            (click)="save()"
            i18n="Save button"
          >
            Save
          </button>
          <button
            class="btn btn-secondary w-100"
            (click)="cancel.emit()"
            i18n="@@cancel"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
