import { Injectable } from '@angular/core';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';

export interface Cleaning {
  id: string;
  name: string;
  createdAt: Date;
  isBeingUsed: boolean;
  description?: string;
  deletedAt?: string;
}

export const cleaningsSortOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'name' },
  { label: $localize`:Sort label Date:Date`, property: 'createdAt' },
];

@Injectable({ providedIn: 'root' })
export class CleaningsRepository extends BaseRepository<Cleaning> {
  constructor() {
    super('cleanings', cleaningsSortOptions);
  }
}
