<div *ngIf="order" class="d-flex mb-2">
  <div class="card shadow-sm yellow text-white w-100 h-75 me-2" role="button" (click)="load.emit()">
    <div class="card-body">
      <h2 class="mb-3">
        <i *ngIf="!isLoading" class="bi bi-box-arrow-up"></i>
        <app-spinner *ngIf="isLoading"></app-spinner>
      </h2>
      <h4 class="mb-2 " i18n="Load label @@load">Load</h4>
    </div>
  </div>
  <div class="card shadow-sm bg-warning text-white w-100 h-75" role="button" (click)="unload.emit()">
    <div class="card-body">
      <h2 class="mb-3">
        <i *ngIf="!isLoading" class="bi bi-box-arrow-down"></i>
        <app-spinner *ngIf="isLoading"></app-spinner>
      </h2>
      <h4 class="mb-2" i18n="Unload label @@unload">Unload</h4>
    </div>
  </div>
</div>

<div *ngIf="order" class="d-flex">
  <label *ngIf="innerWidth && innerWidth > 450" for="img" class="card shadow-sm light-grey text-white lesswide me-2" role="button">
    <input type="file" accept="image/*" id="img"
           (change)="addImage($event)" style="display: none;" multiple>
    <div class="card-body">
      <h2 class="mb-1">
        <i *ngIf="!isLoading" class="bi bi-camera"></i>
        <app-spinner *ngIf="isLoading"></app-spinner>
      </h2>
      <h4 class="mb-2" i18n="@@makephoto">Take Photo</h4>
    </div>
  </label>
  <label *ngIf="innerWidth && innerWidth > 450" for="weighImg" class="card shadow-sm dark-blue text-white lesswide me-2" role="button">
    <input type="file" accept="image/*" id="weighImg"
           (change)="addWeighingImage($event)" style="display: none;" multiple>
    <div class="card-body">
      <h2 class="mb-1">
        <i *ngIf="!isLoading" class="bi bi-camera-fill"></i>
        <app-spinner *ngIf="isLoading"></app-spinner>
      </h2>
      <h4 class="mb-2" i18n="@@weighingPhoto">Weigh Photo</h4>
    </div>
  </label>
  <div *ngIf="innerWidth && innerWidth < 450" class="half-size-div me-2" style="width: 100%;height:145px;">
    <label for="img" class="card shadow-sm light-grey half-size text-white se mb-2" style="height:79px; " role="button">
      <input type="file" accept="image/*" id="img"
             (change)="addImage($event)" style="display: none;" multiple>
      <div class="card-body d-flex">
        <h2 class="mb-1">
          <i *ngIf="!isLoading" class="bi bi-camera me-2"></i>
          <app-spinner *ngIf="isLoading"></app-spinner>
        </h2>
        <h5 class="mb-2" i18n="@@makephoto">Take Photo</h5>
      </div>
    </label>
    <label for="weighImg" class="card shadow-sm dark-blue text-white se" style="height:79px;" role="button">
      <input type="file" accept="image/*" id="weighImg"
             (change)="addWeighingImage($event)" style="display: none;" multiple>
      <div class="card-body d-flex">
        <h2 class="mb-1">
          <i *ngIf="!isLoading" class="bi bi-camera-fill me-2"></i>
          <app-spinner *ngIf="isLoading"></app-spinner>
        </h2>
        <h5 class="mb-2" i18n="@@weighingPhoto">Weigh Photo</h5>
      </div>
    </label>
  </div>
  <div *ngIf="order" class="card shadow-sm bg-success text-white w-100 " role="button"
       (click)="endOrder.emit()">
    <div class="card-body">
      <h2 class="mb-1">
        <i *ngIf="!isLoading" class="bi bi-stop-fill"></i>
        <app-spinner *ngIf="isLoading"></app-spinner>
      </h2>
      <h4 class="mb-2" i18n="End trip label">End trip</h4>
      <p *ngIf="tripStart" class="text-muted small"
         i18n="Trip started label">Trip started: {{ tripStart | timing }}, {{ tripStart | date: 'longDate' }}</p>
    </div>
  </div>
</div>

