import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-image-card',
  templateUrl: './image-card.component.html',
  styleUrls: ['./image-card.component.scss']
})
export class ImageCardComponent implements OnInit {
  @Input() urls = new Array<string>();
  @Output() fileSubmit = new EventEmitter<File[]>();  
  @Input() files? = new Array<File>();
  @Output() urlSubmit = new EventEmitter<string[]>();  
  constructor() { }

  ngOnInit(): void {
  }

  cancelImage(url: string) {
    const index = this.urls.indexOf(url, 0);
    if (index > -1) {
      this.urls.splice(index, 1);      
      this.files?.splice(index, 1);
      this.fileSubmit.emit(this.files);
      this.urlSubmit.emit(this.urls);
    }
  }
}
