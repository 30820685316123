<div class="row mt-1">
    <div class="col-12">
      <div class="card shadow-md mb-4">
        <ul class="list-group list-group-flush">
          <app-model-card *ngFor="let product of products"
            [title]="product.name"
            [subtitle]="product.description"
            icon="bi-box"
            [date]="product.createdAt"
            [id]="product.id"
            routerLinkBase="/products"
            [isDeleteDisabled]="product.isBeingUsed"
            (deleteClick)="deleteConfirmation = product"></app-model-card>
        </ul>
      </div>
    </div>
</div>
<app-confirm-delete *ngIf="deleteConfirmation"
  [deleteCandidate]="deleteConfirmation.name"
  (confirm)="delete.emit(deleteConfirmation.id);deleteConfirmation = null;"
  (cancel)="deleteConfirmation = null"></app-confirm-delete>