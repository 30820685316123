import { AfterContentInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription, combineLatest, forkJoin, map, startWith} from 'rxjs';
import { sortBy } from 'src/app/modules/shared/pipes/sort.pipe';
import { UserSettings } from 'src/app/state/auth.repository';
import { Order } from 'src/app/state/orders.repository';
import { Trip } from 'src/app/state/trips.repository';
import { Workday } from 'src/app/state/workdays.repository';

const ANIMATION_DELAY = 500;

@Component({
  selector: 'app-start-trip-form',
  templateUrl: './start-trip-form.component.html',
  styleUrls: ['./start-trip-form.component.scss']
})
export class StartTripFormComponent implements OnInit{
  @Input() isLoading: boolean | null = null;
  @Input() userSettings: UserSettings | null = null;
  @Output() startTrip = new EventEmitter<void>();
  @Output() reject = new EventEmitter<string>();
  @Output() resume = new EventEmitter<void>();
  @Input() parked: Trip[] | null = null;  
  @Input() isVehicleError = false;

  constructor() {
    
  }

  ngOnInit(): void {
           
  }

  launchStartTrip() {
    if (!this.userSettings?.vehicleId) {
      // Animate a label color to highlight missing vehicle error
      if (this.isVehicleError) {
        this.isVehicleError = false;
        setTimeout(() => this.isVehicleError = true, ANIMATION_DELAY);
      } else {
        this.isVehicleError = true;
      }
      return;
    }
    this.startTrip.emit();
  }

  
}
