import { Component, OnInit } from '@angular/core';
import { AuthRepository, UserRoles } from 'src/app/state/auth.repository';
import { StatisticsService } from 'src/app/state/statistics.service';
import { Subscription, switchMap } from 'rxjs';
import { StatisticsRepository, TruckStatistics } from 'src/app/state/statistics.repository';
import { UsersService } from 'src/app/state/users.service';
import { TenantsService } from 'src/app/state/tenants.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  tripStats = new Array<number>(3);
  productStats = new Array<number>(3);
  isLoaded = new Array<boolean>(3).fill(false);
  subsctiptions = new Array<Subscription>();
  items = ['today', 'thisweek', 'thismonth']

  constructor(
    public repo: AuthRepository,
    private service: UsersService,
    private statsService: StatisticsService,
    private statsRepo: StatisticsRepository,
    private tenantsService: TenantsService,
  ) { }

  ngOnInit(): void {
    this.subsctiptions.push(
      this.statsRepo.dayOwn$.subscribe(stats => this.setStats(0, stats))
    );
    this.subsctiptions.push(
      this.statsRepo.weekOwn$.subscribe(stats => this.setStats(1, stats))
    );
    this.subsctiptions.push(
      this.statsRepo.monthOwn$.subscribe(stats => this.setStats(2, stats))
    );

    this.statsService.load('day', true).pipe(
      switchMap(() => this.statsService.load('week', true)),
      switchMap(() => this.statsService.load('month', true))
    ).subscribe();
    
    this.load();
  }

  ngOnDestroy(): void {
    this.subsctiptions.forEach(s => s.unsubscribe());
  }

  load() {
    this.service.load().subscribe();
    if (this.repo.isInRole(UserRoles.Superamin)) {
      this.tenantsService.load().subscribe();
    }
  }

  setStats(index: number, stats?: TruckStatistics) {
    this.tripStats[index] = stats?.trips || 0;
    //this.productStats[index] = stats?.unloadedProducts || 0;
    this.isLoaded[index] = !!stats;
  }

}
