import { Injectable } from "@angular/core";
import { SortOption } from "../modules/shared/pipes/sort.pipe";
import { BaseRepository } from "./abstract/base.repository";
import { Column } from "./columns.repository";


export interface Template {
    id: string;
    name: string;
    createdAt: Date;
    description: string;
    showExtraData: boolean;
    isBeingUsed: boolean;
    columns: Column[];
}

export const templatesSortOptions: SortOption[] = [
    { label: $localize`:Sort label Name:Name`, property: 'name' },
    {
      label: $localize`:Sort label Description:Description`,
      property: 'description',
    },
    { label: $localize`:Sort label Date:Date`, property: 'createdAt' },
  ];
  
  @Injectable({ providedIn: 'root' })
  export class TemplatesRepository extends BaseRepository<Template> {
    constructor() {
      super('templates', templatesSortOptions);
    }
  }