import { Injectable } from "@angular/core";
import { SortOption } from "../modules/shared/pipes/sort.pipe";
import { BaseRepository } from "./abstract/base.repository";

export interface PriceType {
    id: string;
    name: string;
    unitNumber: number
  }
  
  export const priceTypeSortOptions: SortOption[] = [
    { label: $localize `:Sort label UnitNumber:UnitNumber`, property: 'unitNumber' }    
  ];
  
  @Injectable({ providedIn: 'root' })
  export class PriceTypeRepository extends BaseRepository<PriceType> {
  
    constructor() {
      super('pricetypes', priceTypeSortOptions);
    }
  
  }