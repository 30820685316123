import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Company } from 'src/app/state/companies.repository';

const VENDOR_ROLE = $localize`:Vendor label:Vendor`;
const CLIENT_ROLE = $localize`:Client label:Client`;

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss'],
})
export class CompanyListComponent implements OnInit {
  companiesWithRoles: Company[] | null = null;
  isEconomic = false;
  @Output() companiesChosen = new EventEmitter<string[]>();
  @Input() companiesList = new Array<string>();
  @Input() set companies(value: Company[] | null) {
    if (!value) {
      this.companiesWithRoles = null;
      this.isEconomic = false;
      return;
    }

    this.companiesWithRoles = value.map((x) => {
      x.roles = [];
      if (x.canBeVendor) {
        x.roles.push(VENDOR_ROLE);
      }
      if (x.canBeClient) {
        this.isEconomic = value.every((x) => x.isFromEconomic);
        x.roles.push(CLIENT_ROLE);
      }
      return x;
    });
  }

  changeState(id: string, state: boolean) {
    this.operationOngoing = true;
    this.curOperationCompanyId = id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    this.http
      .post('/api/companies/active/' + id + '/' + state, {}, httpOptions)
      .subscribe((x) => {
        if (x == 200) {
          var customer = this.companiesWithRoles?.find((f) => f.id == id);
          if (customer) {
            customer.isActive = state;
          }
        }
        this.operationOngoing = false;
      });
  }

  curOperationCompanyId: string = '';
  operationOngoing: boolean = false;

  @Input() returnUrl?: string;

  @Output() delete = new EventEmitter<string>();
  deleteConfirmation: Company | null = null;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {}

  selectAll() {
    if (!this.companiesList && this.companiesWithRoles) {
      this.companiesList = this.companiesWithRoles?.map((x) => x.id);
      this.companiesChosen.emit(this.companiesList);
    } else if (
      this.companiesList &&
      this.companiesWithRoles &&
      this.companiesList.length !== this.companiesWithRoles?.length
    ) {
      this.companiesList = this.companiesWithRoles?.map((x) => x.id);
      this.companiesChosen.emit(this.companiesList);
    } else if (
      this.companiesList &&
      this.companiesWithRoles &&
      this.companiesList.length === this.companiesWithRoles?.length
    ) {
      this.companiesList = [];
      this.companiesChosen.emit(this.companiesList);
    }
  }

  changeCompanyList(id: string) {
    if (!this.companiesList || !this.companiesList.includes(id)) {
      this.companiesList?.push(id);
    } else if (this.companiesList && this.companiesList.includes(id)) {
      this.companiesList = this.companiesList.filter((x) => x !== id);
    }
    this.companiesChosen.emit(this.companiesList);
  }
}
