export const environment = {
  production: true,
  name: "TurMaster",
  apiUrl: "/core",
  features: {
    news: false,
    truck: true,
    offlineChanges: true
  },
  locale: 'da'
};
