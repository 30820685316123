import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { updateRequestsStatus, withRequestsCache, withRequestsStatus, createRequestsStatusOperator, selectRequestStatus } from '@ngneat/elf-requests';
import { map } from 'rxjs';

export type StatiscticsPeriods = 'day' | 'week' | 'month';

export interface TruckStatistics {
    activeDrivers?: number | null;
    trips: number;
    unloadedProducts: number;
}

export interface StatisticsProps {
    day?: TruckStatistics;
    week?: TruckStatistics;
    month?: TruckStatistics;
    dayOwn?: TruckStatistics;
    weekOwn?: TruckStatistics;
    monthOwn?: TruckStatistics;
}

const store = createStore(
    { name: 'statistics' }, 
    withProps<StatisticsProps>({}), 
    withRequestsCache(), 
    withRequestsStatus()
);
export const trackStatsRequestsStatus = createRequestsStatusOperator(store);

@Injectable({ providedIn: 'root' })
export class StatisticsRepository {
    name = store.name;
    isLoading$ = (period: StatiscticsPeriods) => store.pipe(
        selectRequestStatus(`${this.name}_${period}`),
        map(x => x.value === 'pending')
    );
    isDayLoading$ = this.isLoading$('day');
    isWeekLoading$ = this.isLoading$('week');
    isMonthLoading$ = this.isLoading$('month');

    one = (period: StatiscticsPeriods | 'dayOwn' | 'weekOwn' | 'monthOwn') => store.pipe(
        select(state => state[period])
    );
    day$ = this.one('day');
    week$ = this.one('week');
    month$ = this.one('month');
    dayOwn$ = this.one('dayOwn');
    weekOwn$ = this.one('weekOwn');
    monthOwn$ = this.one('monthOwn');

    set(period: StatiscticsPeriods, stats: TruckStatistics, isOwn = false) {
        let prop = period;
        if (isOwn) {
            prop += 'Own';
        }
        store.update((state) => ({
          ...state,
          [prop]: stats
        }));
        store.update(
          updateRequestsStatus([`${this.name}_${period}${isOwn ? '_own' : ''}`], 'success')
        );
    }
}
