import { Injectable } from '@angular/core';
import { SortOption } from 'src/app/modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';
import { Template } from './templates.repository';

export interface Company {
  id: string;
  name: string;
  idNumber: string;
  email?: string;
  phoneNumber?: string;
  address?: string;
  zipCode?: number;
  city?: string;
  createdAt: Date;
  canBeVendor: boolean;
  canBeClient: boolean;
  isBeingUsedAsClient: boolean;
  isBeingUsedAsVendor: boolean;
  isFromEconomic: boolean;
  isDeleted: boolean;
  roles: string[];
  templateId: string;
  template: Template;
  deletedAt?: string;
  isActive: boolean;
}

export const companySortOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'name' },
  { label: $localize`:Sort label Zip:Zip`, property: 'zipCode' },
  { label: $localize`:Sort label Address:Address`, property: 'address' },
  { label: $localize`:Sort label City:City`, property: 'city' },
  { label: $localize`:Sort label Date:Date`, property: 'createdAt' },
];

@Injectable({ providedIn: 'root' })
export class CompaniesRepository extends BaseRepository<Company> {
  constructor() {
    super('companies', companySortOptions);
  }
}
