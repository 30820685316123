import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BaseService,
  DEFAULT_ENTITIES_PER_PAGE,
} from './abstract/base.service';
import { Product, ProductsRepository } from './products.repository';
import { Observable, tap } from 'rxjs';
import { PaginationData } from '@ngneat/elf-pagination';
import { SortState } from '../modules/shared/pipes/sort.pipe';
import { DataSelectDto } from './models/dataSelectModels/dataSelectDto';

const API = '/api/products';

@Injectable({
  providedIn: 'root',
})
export class ProductsService extends BaseService<Product> {
  constructor(http: HttpClient, repo: ProductsRepository) {
    super(API, http, repo);
  }

  loadProductsForSelect() {
    return this.http.get<DataSelectDto[]>(`${API}/allforselect`);
  }

  loadEconomicProductsForSelect() {
    return this.http.get<DataSelectDto[]>(`${API}/allforselecteconomic`);
  }

  reloadPage(
    defaultTake: number = DEFAULT_ENTITIES_PER_PAGE,
    search = ''
  ): Observable<(PaginationData & { data: Product[] }) | null> {
    const data = this.repo.getPaginationData();
    if (data && Object.keys(data.pages).length) {
      this.repo.clearPages();
      return this.loadPage(data.currentPage, data.perPage, search);
    }
    return this.loadPage(1, defaultTake, search);
  }

  loadPage(
    page: number,
    take: number = DEFAULT_ENTITIES_PER_PAGE,
    search = ''
  ): Observable<PaginationData & { data: Product[] }> {
    const sortOrder = this.repo.getSort();
    const query = [
      `page=${page}`,
      `take=${take}`,
      `search=${search}`,
      `sort=${sortOrder.parameter.property}`,
      `direction=${sortOrder.direction}`,
    ];
    this.repo.setPage(page);
    return this.http
      .get<PaginationData & { data: Product[] }>(
        `${API + '/all'}?${query.join('&')}`
      )
      .pipe(
        tap((res) => this.repo.addPage(res)),

        this.repo.track(),
        this.repo.skipWhilePageCached(page)
      );
  }

  sort(sortBy: SortState) {
    this.repo.setSort(sortBy);
    return this.reloadPage();
  }
}
