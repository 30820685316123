import { Component, OnInit } from '@angular/core';
import { AuthRepository } from 'src/app/state/auth.repository';
import { CleaningsRepository, cleaningsSortOptions } from 'src/app/state/cleaning.repository';
import { CleaningsService } from 'src/app/state/cleaning.service';

@Component({
  selector: 'app-cleaning-page',
  templateUrl: './cleaning-page.component.html',
  styleUrls: ['./cleaning-page.component.scss']
})
export class CleaningPageComponent implements OnInit {
  sortOptions = cleaningsSortOptions;
  exportFactory = () => this.service.export();
  importFactory = (file: File) => this.service.import(file);


  constructor(
    public service: CleaningsService,
    public repo: CleaningsRepository,
    public auth: AuthRepository
  ) { }

  ngOnInit(): void {
    this.service.reloadPage().subscribe();
  }

}
