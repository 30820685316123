import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import * as dayjs from 'dayjs';
import { tap } from 'rxjs';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { DayjsService } from 'src/app/modules/shared/services/dayjs.service';
import { AccessoriesRepository } from 'src/app/state/accessories.repository';
import { AccessoriesService } from 'src/app/state/accessory.service';
import { CleaningsRepository } from 'src/app/state/cleaning.repository';
import { CleaningsService } from 'src/app/state/cleaning.service';
import { CompaniesRepository } from 'src/app/state/companies.repository';
import { CompaniesService } from 'src/app/state/companies.service';
import { Order, OrderFilter, OrdersRepository } from 'src/app/state/orders.repository';
import { OrdersService } from 'src/app/state/orders.service';
import { Product, ProductsRepository } from 'src/app/state/products.repository';
import { ProductsService } from 'src/app/state/products.service';
import { Trip } from 'src/app/state/trips.repository';
import { UsersRepository } from 'src/app/state/users.repository';
import { UsersService } from 'src/app/state/users.service';
import { OrderFormComponent } from '../../components/order-form/order-form.component';

@Component({
  selector: 'app-orders-page',
  templateUrl: './orders-page.component.html',
  styleUrls: ['./orders-page.component.scss']
})
export class OrdersPageComponent implements OnInit {  
  drivers$ = this.usersRepo.all$;
  @ViewChildren(OrderFormComponent) orderForm: QueryList<OrderFormComponent> | null = null;
  @ViewChild('closeModal') closeModal: ElementRef | null = null;
  orderUpdate: Order | null = null;
  
  constructor(public usersRepo: UsersRepository,
              private usersService: UsersService,
              public ngDay: DayjsService,
              private ordersService: OrdersService,
              public ordersRepo: OrdersRepository,
              public companiesRepo: CompaniesRepository,
              public productsRepo: ProductsRepository,
              public cleaningRepo: CleaningsRepository,
              public accessoryRepo: AccessoriesRepository,
              private accessoryService: AccessoriesService,
              private cleaningService: CleaningsService,
              private companiesService: CompaniesService,
              private productsService: ProductsService) 
              { 
                document.addEventListener("dragover", (event) => {
                  event.preventDefault();
              });
              }

  ngOnInit(): void {
    this.usersService.loadDriversForOrder().subscribe();
    this.ordersService.loadOrders().subscribe();    
  }

resetChild() {
  this.orderForm?.forEach(c => c.form?.reset());
  this.orderUpdate = null;
}

updateValue(form: UntypedFormGroup, control: string, value: any) {
  const controlObject = form?.get(control);
  controlObject?.setValue(value);  
}

updateChild(order?: Order) {
  if(order && this.orderForm) {
    this.orderUpdate = order;
    this.orderForm?.map(c => { 
      if(c.form) {
        this.updateValue(c.form, 'reference', order.reference);
        this.updateValue(c.form, 'plannedToStartAt', order.plannedToStartAt ? order.plannedToStartAt.toISOString().slice(0, 16) : '');
        this.updateValue(c.form, 'plannedToEndAt', order.plannedToEndAt ? order.plannedToEndAt.toISOString().slice(0, 16) : '');
        this.updateValue(c.form, 'userId', order.userId);
        this.updateValue(c.form, 'weight', order.weight);
        this.updateValue(c.form, 'deliveryAddress', order.deliveryAddress);
        this.updateValue(c.form, 'pickUpAddress', order.pickUpAddress);
        this.updateValue(c.form, 'clientId', order.clientId || '');
        this.updateValue(c.form, 'vendorId', order.vendorId);
        this.updateValue(c.form, 'accessoryId', order.accessoryId);
        this.updateValue(c.form, 'contactPerson', order.contactPerson);
        this.updateValue(c.form, 'cleaningId', order.cleaningId);
        this.updateValue(c.form, 'note', order.note);
        this.updateValue(c.form, 'tenantNote', order.tenantNote);
        this.updateValue(c.form, 'productIds', order.products?.map(x => x.id));
      }
    });
  }
  
}

  
  checkRejected(orders: Order[]) {
    if(orders.find(a => a.isRejected)) {
      return true;
    }
    return false;
  }

  checkFinished(orders: Order[], driverid: string) {
    if(orders.find(a => a.userId === driverid && a.tripEvents.find(b => b.type === 'TripEnd'))) {
      return true;
    }
    return false;
  }

  checkStarted(orders: Order[], driverid: string) {
    if(orders.find(a => a.userId === driverid && a.tripEvents.find(x => x.type === 'TripStart') && a.tripEvents.find(y => y.type !== 'TripEnd'))) {
      return true;
    }
    return false;
  }

  getProducts(products: Product[]) {
    if(products.length > 1) {      
      return products[0].name + '...';
    }

    if(products.length === 1) {
      return products[0].name;
    }
    return '';
  }
  
  loadByFilter($event: Partial<OrderFilter>) {
    this.ordersService.loadOrders($event.date_from, $event.date_from, $event.search).subscribe();
    this.drivers$ = this.usersService.loadDriversForPage($event.driverIds);
  }

  loadDataForPopUp() {
    this.accessoryService.load().subscribe();
    this.cleaningService.load().subscribe();
    this.companiesService.load().subscribe();
    this.productsService.load().subscribe();
  }
  

  findEvent(order: Trip, event: string) {
    if(order.tripEvents.find(x => x.type === event)) {
      return true;
    }
    return false;
  }

  compareStrings(start?: string | number, end?: string | number): string {
    if(start && end && start === end ) {
      return start.toString();
    }
    if(start && end && start !== end) {
      return `${start + "/"}<br> ${end}`;
    }
    if(start && !end) {
      return `${start}/`;
    }
    if(!start && end) {
      return `/${end}`;
    }
    return "";
  }

  createUpdateOrder(order: Partial<Order>) {
    if(this.orderUpdate) {      
      this.ordersService.update(this.orderUpdate.id, order).subscribe(() => {
        this.usersService.loadDriversForOrder().subscribe();
        this.ordersService.loadOrders().subscribe();
        this.resetChild();
        if(this.closeModal) {
          this.closeModal.nativeElement.click()
        }
      });
    } 
    if(!this.orderUpdate) {
      this.ordersService.add(order).subscribe(() => {
        this.usersService.loadDriversForOrder().subscribe();
        this.ordersService.loadOrders().subscribe();
        this.resetChild();
        if(this.closeModal) {
          this.closeModal.nativeElement.click()
        }
      });
    }
  }
  
}
