import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { sortBy } from 'src/app/modules/shared/pipes/sort.pipe';
import { Product } from 'src/app/state/products.repository';
import { Trip } from 'src/app/state/trips.repository';


@Component({
  selector: 'app-deleteted-trips-list',
  templateUrl: './deleteted-trips-list.component.html',
  styleUrls: ['./deleteted-trips-list.component.scss']
})
export class DeletetedTripsListComponent implements OnInit {
  @Input() trips: Trip[] | null = null;
  @Output() restore = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }
  getProducts(products?: Product[]) {
    if (products && products.length > 1) {
      return products[0].name + '...';
    }

    if (products && products.length === 1) {
      return products[0].name;
    }
    return '';
  }
  // getTripEvent(trip: Trip, direction:'asc' | 'desc') {
  //   let event = sortBy(
  //     trip.tripEvents, 
  //     { parameter: { property: 'createdAt' }, direction: direction }
  //   )[0];
  //   if(event && event.client && event.client.name) {
  //     return event;
  //   }
  //   return;
  // }
}
