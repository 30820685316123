import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ActiveDriversRepository,
  Driver,
} from 'src/app/state/activedrivers.repository';
import { ActiveDriversService } from 'src/app/state/activedrivers.service';

@Component({
  selector: 'app-active-drivers-list',
  templateUrl: './active-drivers-list.component.html',
  styleUrls: ['./active-drivers-list.component.scss'],
})
export class ActiveDriversListComponent implements OnInit {
  constructor() {}
  @Input() activeDriversData?: Driver[] | null;

  ngOnInit(): void {}
}
