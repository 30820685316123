import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UrlIdResolverService } from 'src/app/modules/shared/services/url-id-resolver.service';
import { EProductsRepository } from 'src/app/state/eproducts.repository';
import { EProductsService } from 'src/app/state/eproducts.service';
import { Product, ProductsRepository } from 'src/app/state/products.repository';
import { ProductsService } from 'src/app/state/products.service';

@Component({
  selector: 'app-product-create-page',
  templateUrl: './product-create-page.component.html',
  styleUrls: ['./product-create-page.component.scss']
})
export class ProductCreatePageComponent implements OnInit {
  product$: Observable<Product> | null = null;
  id: string | undefined;
  submitErrors: string[] | null = null;

  constructor(
    private productService: ProductsService,    
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private repo: ProductsRepository,
    private urlId: UrlIdResolverService,
    private eprodService: EProductsService,
    public eprodRepo: EProductsRepository) { }

    ngOnInit(): void {
      this.product$ = this.urlId.resolve(
        this.activatedRoute.paramMap,
        id => this.productService.loadOne(id),
        id => this.repo.one(id),
        id => this.repo.statusOne(id),
        id => this.id = id
      );      
    }

    createProduct(product: Partial<Product>) {
      this.submitErrors = null;
      let updateResult: Observable<Product> | undefined;
      if(this.id === 'new'){
        updateResult = this.productService.add(product);

      } else if(this.id){
        updateResult = this.productService.update(this.id , product);
      }
      if (updateResult) {
        updateResult.subscribe({
          complete: () => {
            this.router.navigate(['/products']);
          },
          error: data => this.submitErrors = data
        });
      }
    }
}
