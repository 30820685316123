import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-sort-table-pagination',
  templateUrl: './sort-table-pagination.component.html',
  styleUrls: ['./sort-table-pagination.component.scss'],
})
export class SortTablePaginationComponent {
  @Input() pages: number[] = [1];
  @Input() page: number = 1;
  @Output() pageChange = new EventEmitter<number>();

  changePage(page: number) {
    this.page = page;
    this.pageChange.emit(this.page);
  }
}
