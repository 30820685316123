<div class="d-flex align-items-center mb-2" *ngIf="isEconomic">
  <input
    class="mt-1 ms-3"
    *ngIf="isEconomic"
    (click)="selectAll()"
    [checked]="
      isEconomic &&
      this.companiesList.length === this.companiesWithRoles?.length
    "
    style="width: 20px; height: 20px"
    type="checkbox"
  />
  <span class="ms-2 pt-1" i18n="Select all clients">Select all</span>
</div>
<div class="row mt-1">
  <div class="col-12">
    <div class="card shadow-md mb-4">
      <ul class="list-group list-group-flush">
        <li class="list-group-item" *ngFor="let company of companiesWithRoles">
          <div class="row">
            <div class="col-12 col-md">
              <div class="row mb-3 mb-md-0">
                <div
                  class="col-auto position-relative"
                  *ngIf="company.isFromEconomic"
                >
                  <input
                    class="mt-3"
                    style="width: 20px; height: 20px"
                    type="checkbox"
                    [checked]="
                      companiesList && companiesList.includes(company.id)
                    "
                    (click)="changeCompanyList(company.id)"
                  />
                </div>
                <div class="col-auto position-relative">
                  <app-entity-avatar
                    [icon]="'bi bi-briefcase'"
                    [roles]="company.roles"
                  ></app-entity-avatar>
                </div>
                <div class="col-lg-7">
                  <p>
                    {{ company.name }}<br /><small class="text-muted"
                      ><span class="text-muted me-2">{{
                        [company.address, company.city, company.zipCode] | join
                      }}</span></small
                    >
                  </p>
                </div>
                <div
                  class="col-12"
                  *ngIf="company.isFromEconomic && company.template"
                >
                  <span class="badge yellow"
                    ><span i18n="TemplateEnable">Template: </span
                    >{{ company.template.name }}</span
                  >
                </div>
              </div>
            </div>
            <div class="col-12 col-md-5">
              <div style="height: 100%" class="row">
                <div class="col d-flex align-items-center text-md-end">
                  <small class="text-muted mr-2 w-100" i18n="Label created date"
                    >Created {{ company.createdAt | date : "longDate" }}</small
                  >
                </div>
                <div *ngIf="!company.isFromEconomic" class="col-auto">
                  <div class="vr h-100"></div>
                </div>
                <div class="col-auto pl-0 d-flex align-items-center">
                  <div
                    *ngIf="
                      company.id == curOperationCompanyId && operationOngoing
                    "
                    class="spinner-border"
                    style="margin-right: 5px; color: #0d6efd"
                    role="status"
                  ></div>
                  <button
                    *ngIf="company.isActive"
                    type="button"
                    class="btn btn-success"
                    title="Delete"
                    i18n="makeinactive"
                    (click)="changeState(company.id, false)"
                  >
                    Make inactive
                  </button>
                  <button
                    *ngIf="!company.isActive"
                    type="button"
                    class="btn btn-danger"
                    title="Delete"
                    i18n="makeactive"
                    (click)="changeState(company.id, true)"
                  >
                    Make active
                  </button>
                </div>
                <div
                  *ngIf="
                    !(
                      company.isBeingUsedAsClient ||
                      company.isBeingUsedAsVendor ||
                      company.isFromEconomic
                    )
                  "
                  class="col-auto pl-0 d-flex align-items-center"
                >
                  <button
                    type="button"
                    class="btn btn-md btn-link w-100 text-danger"
                    (click)="deleteConfirmation = company"
                    title="Delete"
                    i18n-title="Delete button"
                  >
                    <i class="bi bi-trash"></i>
                  </button>
                </div>
                <div
                  *ngIf="!company.isFromEconomic"
                  class="col-auto pl-0 d-flex align-items-center"
                >
                  <a
                    class="btn btn-md btn-link w-100"
                    [routerLink]="['/companies', company.id]"
                    [queryParams]="{ returnUrl: returnUrl }"
                  >
                    <i class="bi bi-pencil me-1"></i>
                    <ng-container i18n="@@edit">Edit</ng-container>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
<app-confirm-delete
  *ngIf="deleteConfirmation"
  [deleteCandidate]="deleteConfirmation.name"
  (confirm)="delete.emit(deleteConfirmation.id); deleteConfirmation = null"
  (cancel)="deleteConfirmation = null"
></app-confirm-delete>
