import { Injectable } from '@angular/core';

import {
  sortBy,
  SortOption,
  SortState,
} from 'src/app/modules/shared/pipes/sort.pipe';
import { UserRoles } from './auth.repository';
import { BaseRepository, SortProps } from './abstract/base.repository';
import { withPagination } from '@ngneat/elf-pagination';

export interface User {
  id: string;
  email: string;
  phoneNumber: string;
  name: string;
  surname: string;
  title: string;
  location: string;
  birthday?: Date;
  tenantId?: string;
  createdAt: Date;
  image: File | null;
  imagePath: string;
  roles: UserRoles[];
  vehicleId: string;
  vehicleName: string;
  trailerId: string;
  trailerName: string;
  deletedAt?: string;
}

export interface UserSelectDto {
  id: string;
  email: string;
  name: string;
  surname: string;
}

export const userSortOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'name' },
  { label: $localize`:Sort label Surname:Surname`, property: 'surname' },
  { label: $localize`:Sort label Date:Date`, property: 'createdAt' },
];

@Injectable({ providedIn: 'root' })
export class UsersRepository extends BaseRepository<User> {
  constructor() {
    super('users', userSortOptions);
  }
}
