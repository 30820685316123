<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-12 col-md-6 col-lg-4">
      <div
        [appValidateState]="form.get('clientId')"
        class="form-group form-floating mb-3"
      >
        <app-datalist-select
          [isCompanies]="true"
          [isDisabled]="action === 'takephoto'"
          [optionsDeleted]="delCompanies"
          [options]="clientOptions"
          [activeValue]="form.value.clientId"
          (activeValueChange)="updateValue('clientId', $event)"
          label="Client"
          i18n-label="Client label"
        ></app-datalist-select>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4" *ngIf="locationCheck()">
      <fieldset [disabled]="action === 'takephoto'">
        <div class="form-group form-floating mb-3">
          <div class="input-group form-floating">
            <input
              ngx-google-places-autocomplete
              [options]="myOptions"
              #placePickRef="ngx-places"
              (onAddressChange)="handleAddressChange($event, 'pickUpAddress')"
              formControlName="pickUpAddress"
              aria-label="pickUpAddress"
              aria-describedby="pickUpAddressButton"
              class="form-control"
              [class.isDisabled]="
                action !== 'start' &&
                action !== 'stop' &&
                action !== 'unload' &&
                action !== 'load'
              "
            />
            <label
              class="visible"
              for="pickUpAddress"
              i18n="pickUpAddress label"
              >PickUp Address</label
            >
            <button
              class="btn btn-outline-secondary"
              [disabled]="
                !form.value.pickUpAddress || form.value.pickUpAddress.length < 5
              "
              type="button"
              (click)="redirectToAddressOnMaps(form.value.pickUpAddress)"
              id="pickUpAddressButton"
            >
              <i class="bi bi-geo-alt-fill strong danger-text"></i>
            </button>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="col-12 col-md-6 col-lg-4" *ngIf="locationCheck()">
      <fieldset [disabled]="action === 'takephoto'">
        <div class="form-group form-floating mb-3">
          <div class="input-group form-floating">
            <input
              ngx-google-places-autocomplete
              [options]="myOptions"
              #placeDelRef="ngx-places"
              (onAddressChange)="handleAddressChange($event, 'deliveryAddress')"
              formControlName="deliveryAddress"
              aria-label="deliveryAddress"
              aria-describedby="deliveryAddressButton"
              class="form-control"
              [class.isDisabled]="
                action !== 'start' &&
                action !== 'stop' &&
                action !== 'unload' &&
                action !== 'load'
              "
            />
            <label
              class="visible"
              for="deliveryAddress"
              i18n="deliveryAddress label"
              >Delivery Address</label
            >
            <button
              class="btn btn-outline-secondary"
              [disabled]="
                !form.value.deliveryAddress ||
                form.value.deliveryAddress.length < 5
              "
              type="button"
              (click)="redirectToAddressOnMaps(form.value.deliveryAddress)"
              id="deliveryAddressButton"
            >
              <i class="bi bi-geo-alt-fill strong danger-text"></i>
            </button>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <fieldset [disabled]="action === 'takephoto'">
        <div
          [appValidateState]="form.get('km')"
          (keydown)="validateInput($event)"
          class="form-group form-floating mb-3"
        >
          <input
            formControlName="km"
            type="number"
            class="form-control"
            [class.isDisabled]="action === 'takephoto'"
          />
          <label for="km" i18n="Odometer kilometres label">Odometer, km</label>
        </div>
      </fieldset>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <fieldset [disabled]="action === 'takephoto'">
        <div
          [appValidateState]="form.get('weight')"
          class="form-group form-floating mb-3"
        >
          <input
            formControlName="weight"
            type="text"
            (keydown)="validateInput($event)"
            class="form-control"
            [class.isDisabled]="action === 'takephoto'"
          />
          <label for="km" i18n="Weight label">Weight, kg/m<sup>3</sup></label>
        </div>
      </fieldset>
    </div>
    <div
      *ngIf="features.includes(TenantFeatures.Reference)"
      class="col-12 col-md-6 col-lg-4"
    >
      <fieldset [disabled]="action === 'takephoto'">
        <div class="form-group form-floating mb-3">
          <input
            formControlName="reference"
            type="text"
            class="form-control"
            [class.isDisabled]="action === 'takephoto'"
          />
          <label for="km" i18n="Reference label">Reference</label>
        </div>
      </fieldset>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input formControlName="comment" type="text" class="form-control" />
        <label for="km" i18n="Comment label">Comment</label>
      </div>
    </div>

    <div
      *ngIf="features.includes(TenantFeatures.Vendor)"
      class="col-12 col-md-6 col-lg-4"
    >
      <div class="form-group form-floating mb-3">
        <app-datalist-select
          [options]="vendorOptions"
          [isDisabled]="action === 'takephoto'"
          [activeValue]="form.value.vendorId"
          (activeValueChange)="updateValue('vendorId', $event)"
          label="Vendor"
          i18n-label="Vendor label"
        ></app-datalist-select>
      </div>
    </div>
    <div
      *ngIf="features.includes(TenantFeatures.ContactPerson)"
      class="col-12 col-md-6 col-lg-4"
    >
      <fieldset [disabled]="action === 'takephoto'">
        <div class="form-group form-floating mb-3">
          <input
            formControlName="contactPerson"
            type="text"
            class="form-control"
            [class.isDisabled]="action === 'takephoto'"
          />
          <label for="km" i18n="Contact person label">Contact person</label>
        </div>
      </fieldset>
    </div>
    <div *ngIf="action === 'edit'" class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input formControlName="date" type="date" class="form-control" />
        <label for="date" i18n="Date label">Date</label>
      </div>
    </div>
    <div *ngIf="action === 'edit'" class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input formControlName="time" type="time" class="form-control" />
        <label for="time" i18n="Time label">Time</label>
      </div>
    </div>
    <div
      *ngIf="features.includes(TenantFeatures.Cleaning)"
      class="col-12 col-md-6 col-lg-4"
    >
      <div class="form-group form-floating mb-3">
        <app-datalist-select
          [options]="cleaningOptions"
          [isDisabled]="action === 'takephoto'"
          [activeValue]="form.value.cleaningId"
          (activeValueChange)="updateValue('cleaningId', $event)"
          label="Cleaning"
          i18n="Cleaning label"
        ></app-datalist-select>
      </div>
    </div>
    <div
      *ngIf="features.includes(TenantFeatures.Accessory)"
      class="col-12 col-md-6 col-lg-4"
    >
      <div class="form-group form-floating mb-3">
        <app-datalist-select
          [optionsDeleted]="delAccessories"
          [options]="accessoryOptions"
          [isDisabled]="action === 'takephoto'"
          [activeValue]="form.value.accessoryId"
          (activeValueChange)="updateValue('accessoryId', $event)"
          label="Accessory"
          i18n-label="Accessory label"
        ></app-datalist-select>
      </div>
    </div>
    <div
      *ngIf="features.includes(TenantFeatures.Products)"
      class="col-12 col-md-6 col-lg-4"
    >
      <div class="form-group form-floating mb-3">
        <app-datalist-select
          [options]="productOptions"
          class="app-datalist-select-double-height"
          [optionsDeleted]="delProducts"
          [isDisabled]="action === 'takephoto'"
          [multiple]="true"
          [activeValue]="form.value.productIds"
          (activeValueChange)="updateValue('productIds', $event)"
          label="Products"
          i18n-label="Products label"
        ></app-datalist-select>
      </div>
    </div>
    <div
      *ngIf="features.includes(TenantFeatures.Note)"
      class="col-12 col-md-6 col-lg-4"
    >
      <div class="form-group form-floating mb-3">
        <textarea
          [style.height]="getHeight(form.value.note)"
          formControlName="note"
          class="form-control"
          [class.isDisabled]="action === 'takephoto'"
        ></textarea>
        <label for="km" i18n-label="Note label">Note</label>
      </div>
    </div>
  </div>
  <div class="row">
    <div *ngIf="action !== 'edit'" class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <app-image-uploader [files]="files" [urls]="urls"></app-image-uploader>
      </div>
    </div>
  </div>
  <div class="row mb-3" *ngIf="action !== 'edit'">
    <app-image-card [files]="files" [urls]="urls"></app-image-card>
  </div>
  <div class="row">
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating h-100 pb-3 d-grid">
        <button
          type="submit"
          class="btn btn-success shadow-sm"
          [disabled]="isLoading || isClicked"
        >
          <span *ngIf="!isLoading">
            <ng-container *ngIf="action === 'start'" i18n="Start label"
              >Start</ng-container
            >
            <ng-container *ngIf="action === 'stop'" i18n="End label"
              >End</ng-container
            >
            <ng-container *ngIf="action === 'unload'" i18n="@@unload"
              >Unload</ng-container
            >
            <ng-container *ngIf="action === 'load'" i18n="@@load"
              >Load</ng-container
            >
            <ng-container *ngIf="action === 'edit'" i18n="@@update"
              >Update</ng-container
            >
            <ng-container *ngIf="action === 'takephoto'" i18n="@@takeaphoto"
              >Save</ng-container
            >
          </span>
          <app-spinner *ngIf="isLoading"></app-spinner>
        </button>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating h-100 pb-3 d-grid">
        <a
          [routerLink]="
            action === 'edit'
              ? isAdmin === 'true'
                ? '/drivers/trips'
                : '/trips'
              : '/go'
          "
          class="btn btn-secondary shadow-sm"
          [attr.disabled]="isLoading || null"
          [class.disabled]="isLoading"
          i18n="@@cancel"
          >Cancel</a
        >
      </div>
    </div>
  </div>
</form>
