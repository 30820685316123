import { Component, OnInit } from '@angular/core';
import { AuthRepository } from 'src/app/state/auth.repository';
import { VehiclesRepository, vehiclesSortOptions } from 'src/app/state/vehicles.repository';
import { VehiclesService } from 'src/app/state/vehicles.service';


@Component({
  selector: 'app-vehicle-page',
  templateUrl: './vehicle-page.component.html',
  styleUrls: ['./vehicle-page.component.scss']
})
export class VehiclePageComponent implements OnInit {
  sortOptions = vehiclesSortOptions;
  exportFactory = () => this.service.export();
  importFactory = (file: File) => this.service.import(file);


  constructor(
    public repo: VehiclesRepository,
    public service: VehiclesService,
    public auth: AuthRepository
  ) { }

  ngOnInit() {
    this.service.reloadPage().subscribe();
  }

}
