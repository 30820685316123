<h2 class="mb-4" *ngIf="id === 'new'" i18n="Create trailer label">Create trailer</h2>
<h2 class="mb-4" *ngIf="id !== 'new'" i18n="Edit trailer label">Edit trailer</h2>
<div *ngIf="(id === 'new') || (trailer$ | async)" class="row mb-4">
    <div class="col-12">
        <app-trailer-form [editedTrailer]="trailer$ | async"
          (trailerSubmit)="createTrailer($event)"></app-trailer-form>
          <app-error-alert title="Could not submit the trailer info" i18n-title="Could not sumbit trailer message"
            [errors]="submitErrors"></app-error-alert>          
    </div>
</div>
