import { Injectable } from '@angular/core';
import { Store, createState } from '@ngneat/elf';
import { withEntities, selectAllEntities, selectEntity, upsertEntities } from '@ngneat/elf-entities';
import { createRequestsCacheOperator, createRequestsStatusOperator, selectRequestStatus, updateRequestCache, updateRequestsStatus, withRequestsCache, withRequestsStatus } from '@ngneat/elf-requests';
import { map } from 'rxjs';

export interface Post {
  id: string;
  title: string;
  content: string;
  createdAt: Date;
}

const { state, config } = createState(
  withEntities<Post>(),
  withRequestsStatus(),
  withRequestsCache()
);
const store = new Store({ name: 'posts', state, config });
export const trackPostRequestsStatus = createRequestsStatusOperator(store);
export const skipWhilePostsCached = createRequestsCacheOperator(store);

@Injectable({ providedIn: 'root' })
export class PostsRepository {
  name = store.name;

  posts$ = store.pipe(selectAllEntities());
  post = (id: string) => store.pipe(selectEntity(id));
  status = (id: string) => store.pipe(selectRequestStatus(id, { groupKey: store.name }));
  isLoading$ = store.pipe(
    selectRequestStatus(this.name),
    map(x => x.value === 'pending')
  );
  isLoadingOne$ = (id: Post['id']) => store.pipe(
    selectRequestStatus(id),
    map(x => x.value === 'pending')
  );
  
  setPosts(posts: Post[]) {
    store.update(
      updateRequestCache(store.name),
      upsertEntities(posts),
      updateRequestsStatus([store.name], 'success')
    );
  }

  upsertPost(post: Post) {
    store.update(
      updateRequestCache(post.id),
      upsertEntities([post]),
      updateRequestsStatus([post.id], 'success')
    );
  }
}
