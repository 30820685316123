import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './abstract/base.service';
import { Cleaning, CleaningsRepository } from './cleaning.repository';

const API = '/api/cleanings';

@Injectable({
  providedIn: 'root'
})
export class CleaningsService extends BaseService<Cleaning> {

  constructor(
    http: HttpClient,
    repo: CleaningsRepository
  ) {
    super(API, http, repo);
  }

}