import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-single-textbox-modal',
  templateUrl: './single-textbox-modal.component.html',
  styleUrls: ['./single-textbox-modal.component.scss'],
})
export class SingleTextboxModalComponent {
  @Input() title: string | null = null;
  @Input() mutable = true;
  @Input() text: string | undefined = undefined;
  @Output() stringEmmiter = new EventEmitter<string>();
  @Output() cancel = new EventEmitter<void>();

  cancelEmit() {
    this.cancel.emit();
  }

  save() {
    this.stringEmmiter.emit(
      (<HTMLInputElement>document.getElementById('textbox')).value
    );
  }
}
