import { Component } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { UrlIdResolverService } from 'src/app/modules/shared/services/url-id-resolver.service';
import { Column } from 'src/app/state/columns.repository';
import { ColumnsService } from 'src/app/state/columns.service';
import {
  Template,
  TemplatesRepository,
} from 'src/app/state/templates.repository';
import { TemplatesService } from 'src/app/state/templates.service';

@Component({
  selector: 'app-create-template-page',
  templateUrl: './create-template-page.component.html',
  styleUrls: ['./create-template-page.component.scss'],
})
export class CreateTemplatePageComponent {
  id?: string;
  columns: Column[] | null = null;
  resetable?: boolean;
  name = '';
  template$: Observable<Template> | null = null;
  template: Template | null = null;
  form: UntypedFormGroup | null = null;
  submitErrors: string[] | null = null;

  readonly DEFAULT_BACKGROUND = 'assets/img/background_default.webp';

  constructor(
    private service: TemplatesService,
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    public repo: TemplatesRepository,
    private urlId: UrlIdResolverService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.template$ = this.urlId.resolve(
      this.activatedRoute.paramMap,
      (id) => this.service.loadOne(id),
      (id) => this.repo.one(id),
      (id) => this.repo.statusOne(id),
      (id) => (this.id = id)
    );
    this.template$.subscribe((x) => {
      this.template = x;
      this.columns = x.columns;
    });
    this.form = this.formBuilder.group({
      name: [this.template?.name, Validators.required],
      description: [this.template?.description],
      showExtraData: [this.template?.showExtraData],
    });
    if (this.id && this.id !== 'new') {
    }
    if (this.id === 'new') {
      this.service
        .loadTemplateColumns()
        .pipe(
          tap((x) => (this.columns = this.rearangeNumbers(x))),
          tap((x) => (this.resetable = x.every((x) => x.templateId !== null)))
        )
        .subscribe();
      this.form = this.formBuilder.group({
        name: ['', Validators.required],
        description: [''],
        showExtraData: [this.template?.showExtraData],
      });
    }
  }

  replaceWithOnly(str: string) {
    return str
      .replace('Vare art, Vare art Losning, Vare art lastning ', 'Vare art')
      .replace('ID', 'Navn');
  }

  submit() {
    if (this.form && !this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    if (this.columns && this.id === 'new') {
      this.service
        .create(
          this.columns,
          this.form?.value.name,
          !!this.form?.value.showExtraData,
          this.form?.value.description
        )
        .pipe(
          tap((x) => (this.columns = x.columns)),
          tap(
            (x) =>
              (this.resetable = x.columns.every((x) => x.templateId !== null))
          )
        )
        .subscribe({
          complete: () => {
            this.router.navigate(['/templates']);
          },
          error: (data) => (this.submitErrors = data),
        });
    } else if (this.columns && this.id) {
      this.service
        .updateTemplateColumns(
          this.columns,
          this.form?.value.name,
          !!this.form?.value.showExtraData,
          this.form?.value.description,
          this.id
        )
        .pipe(
          tap((x) => (this.columns = x.columns)),
          tap(
            (x) =>
              (this.resetable = x.columns.every((x) => x.templateId !== null))
          )
        )
        .subscribe({
          complete: () => {
            this.router.navigate(['/templates']);
          },
          error: (data) => (this.submitErrors = data),
        });
    }
  }

  rearangeNumbers(columns: Column[]) {
    return columns.map((value, index) => {
      value.orderNumber = index;
      return value;
    });
  }

  resetOrder() {
    if (this.columns && this.id) {
      this.service.loadTemplateColumns;
    }
  }
}
