<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <div class="form-group form-floating mb-3">
          <input formControlName="date" type="date" class="form-control">
          <label for="date" i18n="Date label">Date</label>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="form-group form-floating mb-3">
          <input formControlName="time" type="time" class="form-control">
          <label for="time" i18n="Time label">Time</label>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4" *ngIf="dayEvent?.type === 'Pause' || addPause">
        <div class="form-group form-floating mb-3">
          <input formControlName="pause" type="number" class="form-control">
          <label for="pause" i18n="Duration minutes label">Duration, min</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <div class="form-group form-floating h-100 pb-3 d-grid">
          <button type="submit" class="btn btn-success shadow-sm"
          [disabled]="isLoading">
          <span i18n="Update label @@update">Update</span>
          <app-spinner *ngIf="isLoading"></app-spinner>
          </button>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="form-group form-floating h-100 pb-3 d-grid">
          <a [routerLink]="isAdmin ? '/drivers/workdays' : '/workdays'" class="btn btn-secondary shadow-sm"
          [attr.disabled]="isLoading || null" [class.disabled]="isLoading" i18n="Cancel label @@cancel">Cancel</a>
        </div>
      </div>
    </div>
</form>
