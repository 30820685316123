import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { filter, map, timer } from 'rxjs';
import { DayjsService } from 'src/app/modules/shared/services/dayjs.service';
import { WorkdayEvent } from 'src/app/state/workdays.repository';

@Component({
  selector: 'app-active-pause',
  templateUrl: './active-pause.component.html',
  styleUrls: ['./active-pause.component.scss']
})
export class ActivePauseComponent implements OnInit {
  @Input() isLoading: boolean | null = null;
  @Input() pause?: WorkdayEvent | null;
  duration$ = timer(0, 1000).pipe(
    filter(_ => !!this.pause),
    map(_ => this.ngDay.dayjs
      .duration(this.ngDay.dayjs(this.pause?.eventTime).diff(this.ngDay.dayjs()))
      .add(this.pause?.duration || 0, 'minutes'))
  );

  @Output() resumeDay = new EventEmitter<WorkdayEvent>();

  constructor(public ngDay: DayjsService) { }

  ngOnInit(): void {
  }

  resume() {
    if (!this.pause) {
      return;
    }
    this.pause.duration = this.ngDay.dayjs
      .duration(this.ngDay.dayjs().diff(this.ngDay.dayjs(this.pause?.eventTime)))
      .asMinutes();
    this.resumeDay.emit(this.pause);
  }
}
