<div class="row mb-4">
  <div class="col">
    <h2>News</h2>
  </div>
  <div class="col-auto d-flex align-items-center" 
    *ngIf="authRepo.isAnyAdmin$ | async">
    <a routerLink="/news/new" class="btn btn-sm btn-success">
      <i class="bi bi-plus"></i> <ng-container i18n="@@add">Add</ng-container>
    </a>
  </div>
</div>
<div class="row">
  <app-simple-card *ngFor="let card of postCards$ | async"
    class="col-12 col-md-6 col-xl-4"
    [title]="card.post.title"   
    [actions]="card.actions">
    {{ card.post.content | stripHtml | slice:0:100 }}...
  </app-simple-card>
</div>
