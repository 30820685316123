<div class="row">
    <div class="col-12 col-md">
      <div class="row mb-3 mb-md-0">
        <div class="col-auto" *ngIf="icon">
          <app-entity-avatar [icon]="icon"></app-entity-avatar>
        </div>
        <div class="col">
          <p>
            <span>{{title}}</span><br>
            <small class="">
                <span *ngIf="subtitle" class="text-muted me-2">{{ subtitle }}</span>
              </small>
            </p>
        </div>
        <div class="col d-flex align-items-center">
          <span  class="dark badge" >{{type?.replace('_', ' ')}}</span>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-5">
      <div class="row">
        <div class="col d-flex align-items-center text-md-end">
          <small class="text-muted mr-2 w-100" i18n="Label created date">Created {{date | date:'longDate'}}</small>
        </div>
        <div class="col-auto">
          <div class="vr h-100"></div>
        </div>
        <div *ngIf="!isEditDisabled" class="col-auto pl-0 d-flex align-items-center">
          <a class="btn btn-md btn-link w-100" [routerLink]="[routerLinkBase, id]" [queryParams]="{returnUrl : returnUrl}">
            <i class="bi bi-pencil me-1"></i> <ng-container i18n="@@edit">Edit</ng-container>
          </a>
        </div>
      </div>
    </div>
</div>
