import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filterNil } from '@ngneat/elf';
import { combineLatest, filter, map, Observable, skipWhile, switchMap, tap } from 'rxjs';
import { Post, PostsRepository } from 'src/app/state/posts.repository';
import { PostsService } from 'src/app/state/posts.service';
import { UntypedFormBuilder } from '@angular/forms';
import { UrlIdResolverService } from '../../shared/services/url-id-resolver.service';

@Component({
  selector: 'app-news-edit',
  templateUrl: './news-edit.component.html',
  styleUrls: ['./news-edit.component.scss']
})
export class NewsEditComponent implements OnInit {
  editor: any;
  editForm: UntypedFormBuilder | any;
  post$: Observable<Post> | null = null;
  id!: string;
  submitErrors: string[] | null = null;

  constructor(
    private postService: PostsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private repo: PostsRepository,
    private urlId: UrlIdResolverService
  ) { }

  ngOnInit(): void {
    this.post$ = this.urlId.resolve(
      this.activatedRoute.paramMap,
      id => this.postService.loadOne(id),
      id => this.repo.post(id),
      id => this.repo.status(id),
      id => this.id = id
    );
  }

  updatePost(post: Partial<Post>) {
    this.submitErrors = null;
    let updateResult: Observable<Post> | undefined;
    if (this.id === 'new') {
      updateResult = this.postService.add(post);

    } else if (this.id) {
      updateResult = this.postService.update(this.id, post);
    }
    if (updateResult) {
      updateResult.subscribe({
        complete: () => {
          this.router.navigate(['/news']);
        },
        error: data => this.submitErrors = data
      });
    }
  }
}
