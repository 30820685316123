import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, switchMap } from 'rxjs';
import { DayjsService } from 'src/app/modules/shared/services/dayjs.service';
import { AccessoriesRepository } from 'src/app/state/accessories.repository';
import { AccessoriesService } from 'src/app/state/accessory.service';
import { AuthRepository } from 'src/app/state/auth.repository';
import { AuthService } from 'src/app/state/auth.service';
import { CleaningsRepository } from 'src/app/state/cleaning.repository';
import { CleaningsService } from 'src/app/state/cleaning.service';
import { CompaniesRepository } from 'src/app/state/companies.repository';
import { CompaniesService } from 'src/app/state/companies.service';
import { TenantFeatures } from 'src/app/state/feature.repository';
import {
  Order,
  OrderFamily,
  OrderFilter,
  OrdersRepository,
} from 'src/app/state/orders.repository';
import { OrdersService } from 'src/app/state/orders.service';
import { Product, ProductsRepository } from 'src/app/state/products.repository';
import { ProductsService } from 'src/app/state/products.service';
import {
  StatisticsRepository,
  TruckStatistics,
} from 'src/app/state/statistics.repository';
import { StatisticsService } from 'src/app/state/statistics.service';
import { User, UsersRepository } from 'src/app/state/users.repository';
import { UsersService } from 'src/app/state/users.service';
import { OrderFormComponent } from '../../components/order-form/order-form.component';
import { UntypedFormGroup } from '@angular/forms';
import { Trip } from 'src/app/state/trips.repository';
import { sortBy } from 'src/app/modules/shared/pipes/sort.pipe';
import { ConnectionService } from 'src/app/modules/shared/services/connection.service';
import { EProductsRepository } from 'src/app/state/eproducts.repository';
import { EProductsService } from 'src/app/state/eproducts.service';
import * as dayjs from 'dayjs';
import { BaseService } from 'src/app/base-service';
import { VendorsService } from 'src/app/state/vendors.service';
import { VendorsRepository } from 'src/app/state/vendors.repository';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';

const TODAY = $localize`:Statistics label for today:Today`;
const THIS_WEEK = $localize`:Statistics label for current week:This week`;
const THIS_MONTH = $localize`:Statistics label for current month:This month`;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  display: string = 'block !important';
  opacity: string = '1 !important';
  ORDER_FOR = $localize`:Order label for client:Order for `;
  TenantFeatures = TenantFeatures;
  subtitles = [TODAY, THIS_WEEK, THIS_MONTH];

  parkedOrder: Order | null = null;

  driverStats = new Array<string>(3);
  tripStats = new Array<string>(3);
  productStats = new Array<string>(3);
  subsctiptions = new Array<Subscription>();
  driver?: User | null = null;
  drivers: User[] | null = null;
  pageDrivers: User[] | null = null;
  driversFixed: User[] | null = null;
  lastDriver?: User | null = null;
  order: Order | null = null;
  orderFamily?: OrderFamily | null = null;
  orderFamilies: OrderFamily[] | null = null;
  the_orders: Order[] | null = null;
  orderSub: any;
  apiKey: string | null = null;
  fromDate = new Date().toISOString().split('T')[0];
  toDate = new Date().toISOString().split('T')[0];
  @ViewChildren(OrderFormComponent)
  orderForm: QueryList<OrderFormComponent> | null = null;
  @ViewChild('closeModal') closeModal: ElementRef | null = null;
  @ViewChild('newOrder') newOrder: ElementRef | null = null;
  orderUpdate: Order | null = null;
  deleteConfirmation: Order | null = null;
  forOneDriver: boolean | undefined;
  isMultipleDrivers: boolean = false;
  deleteForDriverId: string | null = null;
  filteredDrivers: Observable<User> | null = null;
  today: Date = new Date();
  tommorow: Date = new Date(
    this.today.getFullYear(),
    this.today.getMonth(),
    this.today.getDate() + 1
  );

  constructor(
    private router: Router,
    private repo: StatisticsRepository,
    private service: StatisticsService,
    private auth: AuthService,
    public authrepo: AuthRepository,
    public eproductRepo: EProductsRepository,
    private eProductService: EProductsService,
    public usersRepo: UsersRepository,
    private usersService: UsersService,
    public ngDay: DayjsService,
    private ordersService: OrdersService,
    public ordersRepo: OrdersRepository,
    public companiesRepo: CompaniesRepository,
    public productsRepo: ProductsRepository,
    public cleaningRepo: CleaningsRepository,
    public accessoryRepo: AccessoriesRepository,
    private accessoryService: AccessoriesService,
    private cleaningService: CleaningsService,
    private companiesService: CompaniesService,
    private productsService: ProductsService,
    public isOnlineService: ConnectionService,
    private ref: ChangeDetectorRef,
    private readonly injector: Injector,
    private readonly resolver: ComponentFactoryResolver,
    private baseService: BaseService,
    private vendorsService: VendorsService,
    private vendorsRepository: VendorsRepository
  ) {
    this.usersService.reloadPage().subscribe();

    this.usersRepo.all$.subscribe((x) => {
      var drivers = x.filter((f: any) => f?.rolesString?.includes('Driver'));
      this.driversFixed = drivers;
      this.pageDrivers = drivers;
    });

    this.vendorsService.load().subscribe((x) => {});
    // this.vendorsService.loadDeleted().subscribe();
    // this.vendorsRepository.allDeleted$.subscribe((x) => {
    //   this.vendorsDeleted = x;
    // });
    this.usersService.loadDriversDeleted().subscribe((x) => {});
    this.usersRepo.allDeleted$.subscribe((x) => {
      this.usersDeleted = x;
    });

    this.productsService.loadAsListwithRepo().subscribe();
    this.productsService.loadDeletedAsListwithRepo().subscribe();
    this.productsRepo.allDeleted$.subscribe((x) => (this.prodsDeleted = x));

    this.companiesService.loadAsListwithRepo().subscribe();
    this.companiesService.loadDeletedAsListwithRepo().subscribe();
    this.companyRepoSubscribe = this.companiesRepo.all$.subscribe((x) => {
      this.companiesT = x;
    });
    this.companyDeletedRepoSubscribe = this.companiesRepo.allDeleted$.subscribe(
      (x) => {
        this.companiesTD = x;
      }
    );
    this.cleaningService.load().subscribe();
    this.cleaningRepo.all$.subscribe((x) => (this.cleanings = x));

    this.productsRepo.all$.subscribe((x) => {
      if (x) {
        this.products = x;
      }
    });
    this.accessoryService.load().subscribe();
    this.accessoryRepo.all$.subscribe((x) => (this.accessories = x));
    this.usersRepo.all$.subscribe((x) => {
      this.driversM = x?.filter((f: any) => f?.rolesString?.includes('Driver'));
    });
    this.loadData();
    this.ordersRepo.orders$.subscribe((x) => {
      this.orders = x;
    });
    this.orderSub = ordersRepo.orders$.subscribe((x) => {
      var tempForInitFamilyNumber: Order[] = x;

      var numbersCounter: number = 1;

      this.orderFamilies = this.groupRelatedOrders(x);

      this.orderFamilies.forEach((x) => {
        if (x.children.length >= 1) {
          if (tempForInitFamilyNumber.find((f) => f.id == x.parentId)) {
            tempForInitFamilyNumber.find(
              (f) => f.id == x.parentId
            )!.familyNumber = numbersCounter;

            tempForInitFamilyNumber
              .filter((h) =>
                x.children.map((t) => t.parentId).includes(h.parentId)
              )
              .forEach((j) => (j.familyNumber = numbersCounter));
            numbersCounter++;
          }
        } else {
          tempForInitFamilyNumber.find(
            (f) => f.id == x.parentId
          )!.familyNumber = undefined;
        }
      });

      this.the_orders = tempForInitFamilyNumber;
    });
    document.addEventListener('dragover', (event) => {
      event.preventDefault();
    });
    this.cleaningService.loadDeleted().subscribe();
    this.accessoryService.loadDeleted().subscribe();
    this.accessoryRepo.allDeleted$.subscribe(
      (x) => (this.accessoriesDeleted = x)
    );
    this.cleaningRepo.allDeleted$.subscribe((x) => (this.cleaningsDeleted = x));
    this.subsctiptions.push(
      this.repo.day$.subscribe((stats) => this.setStats(0, stats))
    );
    this.subsctiptions.push(
      this.repo.week$.subscribe((stats) => this.setStats(1, stats))
    );
    this.subsctiptions.push(
      this.repo.month$.subscribe((stats) => this.setStats(2, stats))
    );
    this.service
      .load('day')
      .pipe(
        switchMap(() => this.service.load('week')),
        switchMap(() => this.service.load('month'))
      )
      .subscribe();
  }

  usersDeleted: User[] | null = null;
  vendorsDeleted: any;
  accessoriesDeleted: any;
  cleaningsDeleted: any;

  companyRepoSubscribe: any;
  companyDeletedRepoSubscribe: any;
  products: Product[] | null = null;
  companiesT: any;
  companiesTD: any;

  prodsDeleted: Product[] | null = null;

  ngOnDestroy(): void {
    this.baseService.sendSaveEvent(undefined);
    this.baseService.sendLinkedOrderEvent(undefined);
    this.baseService.sendCancelEvent(false);
    this.componentRef?.destroy();

    this.subsctiptions?.forEach((s) => s?.unsubscribe());
    this.orderSub?.unsubscribe();
    this.companyRepoSubscribe?.unsubscribe();
    this.companyDeletedRepoSubscribe?.unsubscribe();

    this.saveSub?.unsubscribe();
    this.cancelSub?.unsubscribe();
    this.linkedOrderSub?.unsubscribe();

    this.previous = null;
  }

  ngOnInit(): void {
    this.baseService.sendSaveEvent(undefined);
    this.baseService.sendLinkedOrderEvent(undefined);
    this.baseService.sendCancelEvent(false);
    this.componentRef?.destroy();

    this.service
      .load('day')
      .pipe(
        switchMap(() => this.service.load('week')),
        switchMap(() => this.service.load('month'))
      )
      .subscribe();
    this.subsctiptions.push(
      this.repo.day$.subscribe((stats) => this.setStats(0, stats))
    );
    this.subsctiptions.push(
      this.repo.week$.subscribe((stats) => this.setStats(1, stats))
    );
    this.subsctiptions.push(
      this.repo.month$.subscribe((stats) => this.setStats(2, stats))
    );
    this.loadData();
  }

  loadData() {
    if (!(localStorage.getItem('from') && localStorage.getItem('to'))) {
      localStorage.setItem('from', this.fromDate);
      localStorage.setItem('to', this.fromDate);
    }

    let storedDrivers = localStorage.getItem('driversSelected');
    this.usersService.load().subscribe((x) => {
      var drivers = x.data.filter((f: any) =>
        f?.rolesString?.includes('Driver')
      );
      this.driversFixed = drivers;
      this.pageDrivers = drivers;
    });
    if (storedDrivers && storedDrivers.length >= 2) {
      this.usersService
        .loadDriversForPage(JSON.parse(storedDrivers))
        .subscribe((x) => {
          this.pageDrivers = x;
        });
    }

    this.fromDate = localStorage.getItem('from')!;
    this.toDate = localStorage.getItem('to')!;

    this.ordersService.loadOrders(this.fromDate, this.toDate).subscribe((x) => {
      this.the_orders = x;
    });
  }

  sortDriversByOrders(drivers?: User[]) {
    if (drivers) {
      const orderCounts: { [id: string]: number } = {};
      this.orders
        ?.filter((o) => !o.tripEvents.some((te) => te.type == 'TripEnd'))
        .forEach((order) => {
          if (order.userId) {
            orderCounts[order.userId] = (orderCounts[order.userId] || 0) + 1;
          } else {
            order.driverIds?.forEach((driverId) => {
              orderCounts[driverId] = (orderCounts[driverId] || 0) + 1;
            });
          }
        });
      return drivers.sort((userA, userB) => {
        const orderCountA = orderCounts[userA.id] || 0;
        const orderCountB = orderCounts[userB.id] || 0;
        const orderCountComparison = orderCountB - orderCountA;
        if (orderCountComparison === 0) {
          return userA.name.localeCompare(userB.name);
        }
        return orderCountComparison;
      });
    } else {
      return drivers;
    }
  }

  loadByFilter($event: Partial<OrderFilter>) {
    if ($event.driverIds) {
      localStorage.setItem('driversSelected', JSON.stringify($event.driverIds));
      this.usersService.loadDriversForPage($event.driverIds).subscribe((x) => {
        this.pageDrivers = x;
      });
    }
    this.ordersService
      .loadOrders($event.date_from, $event.date_to, $event.search)
      .subscribe();

    if ($event && $event.date_from && $event.date_to) {
      localStorage.setItem('from', $event.date_from.toString());
      localStorage.setItem('to', $event.date_to.toString());
    }
  }

  groupRelatedOrders(orders: Order[]): OrderFamily[] {
    let parents = orders.filter((x) => !x.parentId);
    let families = new Array<OrderFamily>();
    let common = orders.filter((x) => x.parentId);
    parents.forEach((x) => {
      let list = new Array<Order>();
      let children = this.findChild(x.id, common, list);
      let family = { parentId: x.id, children: children } as OrderFamily;
      families?.push(family);
    });

    return families;
  }

  findChild(id: string, orders: Order[], list: Order[]) {
    let child = orders.find((x) => x.parentId === id);
    if (orders.length !== 0) {
      if (child) {
        let index = orders.indexOf(child);
        list.push(child);
        id = child.id;
        orders.splice(index, 1);
        this.findChild(id, orders, list);
      }
      return list;
    }
    return list;
  }

  sortOrdersByDateDesc(orders: Order[] | null): Order[] {
    if (orders && orders.length > 0) {
      return sortBy(orders, {
        parameter: { property: 'plannedToStartAt' },
        direction: 'asc',
      });
    }
    return [];
  }

  cardClick(val: string) {
    switch (val) {
      case $localize`:Statistics label for today:Today`: {
        this.router.navigate(['/activedrivers/today']);
        break;
      }
      case $localize`:Statistics label for current week:This week`: {
        this.router.navigate(['/activedrivers/thisweek']);
        break;
      }
      case $localize`:Statistics label for current month:This month`: {
        this.router.navigate(['/activedrivers/thismonth']);
        break;
      }
      default: {
        break;
      }
    }
  }

  delete(id: string, driverId: string | null, event: any) {
    const familyByChild = this.orderFamilies?.find((i) =>
      i.children.find((n) => n.id === id)
    );
    const familyByParent = this.orderFamilies?.find((i) => i.parentId === id);

    // if (
    //   familyByChild &&
    //   familyByChild.children &&
    //   familyByChild.children.length > 1
    // ) {
    //   const index = familyByChild.children.findIndex((x) => x.id === id);
    //   const previous = familyByChild.children[index - 1];
    //   const next = familyByChild.children[index + 1];
    // }
    this.ordersService
      .delete(
        id,
        driverId,
        this.isMultipleDrivers ? event.recurrence : event,
        this.isMultipleDrivers ? event.forOneDriver : false
      )
      .subscribe((f) => {
        if (
          familyByParent &&
          familyByParent.children &&
          familyByParent.children.length > 0
        ) {
          const first = familyByParent.children[0];
          this.ordersService.updateParentId(first.id, '').subscribe();
        }

        if (
          familyByChild &&
          familyByChild.children &&
          familyByChild.children.length > 1
        ) {
          const index = familyByChild.children.findIndex((x) => x.id === id);
          const previous = familyByChild.children[index - 1];
          const next = familyByChild.children[index + 1];
          if (previous && next) {
            this.ordersService.updateParentId(next.id, previous.id).subscribe();
          }
          if (!previous && next) {
            this.ordersService
              .updateParentId(next.id, familyByChild.parentId)
              .subscribe();
          }
        }

        if (!event) {
          this.ordersService
            .loadOrders(this.fromDate, this.toDate)
            .subscribe((x) => {
              this.the_orders = x;
            });
        }
      });
  }

  convertMS(duration: number): string {
    let d, h, m, s;
    s = Math.floor(duration / 1000);
    m = Math.floor(s / 60);
    s = s % 60;
    h = Math.floor(m / 60);
    m = m % 60;
    d = Math.floor(h / 24);
    h = h % 24;
    h += d * 24;
    return `${h}h. ${m}m. ${s}s. `;
  }

  getDriver(driver: User | null = null) {
    this.driver = driver;
  }

  getOrder(order: Order) {
    this.order = order;
    this.orderFamily = this.orderFamilies?.find((x) => x.parentId === order.id);
  }

  getLastDriver(driver: User | null = null) {
    this.lastDriver = driver;
  }

  updateDriver() {
    if (this.order && this.driver && this.lastDriver) {
      this.order.driverIds = this.order.driverIds?.filter(
        (x) => x !== this.lastDriver?.id
      );
      if (!this.order.driverIds?.find((x) => x === this.driver?.id)) {
        this.order.driverIds?.push(this.driver.id);
      }
      this.order.productIds = this.order.products?.map((x) => x.id);
      this.ordersService.updateDriver(this.order?.id, this.order).subscribe();
      if (
        this.orderFamily &&
        this.orderFamily.children &&
        this.orderFamily.children.length > 0
      ) {
        this.orderFamily.children.forEach((child) => {
          child.driverIds = child.driverIds?.filter(
            (x) => x !== this.lastDriver?.id
          );
          if (!child.driverIds?.find((x) => x === this.driver?.id)) {
            child.driverIds?.push(this.driver!.id);
          }
          child.productIds = child?.products?.map((x) => x.id);
          this.ordersService.updateDriver(child.id, child).subscribe();
        });
      }
    } else if (this.order && this.driver && !this.lastDriver) {
      if (!this.order.driverIds?.find((x) => x === this.driver?.id)) {
        this.order.driverIds?.push(this.driver.id);
      }
      this.order.productIds = this.order.products?.map((x) => x.id);
      this.ordersService.updateDriver(this.order?.id, this.order).subscribe();
      if (
        this.orderFamily &&
        this.orderFamily.children &&
        this.orderFamily.children.length > 0
      ) {
        this.orderFamily.children.forEach((child) => {
          if (!child.driverIds?.find((x) => x === this.driver?.id)) {
            child.driverIds?.push(this.driver!.id);
          }
          child.productIds = child?.products?.map((x) => x.id);
          this.ordersService.updateDriver(child.id, child).subscribe();
        });
      }
    } else if (this.order && !this.driver) {
      this.order.driverIds = null;
      this.ordersService.updateDriver(this.order?.id, this.order).subscribe();
      if (
        this.orderFamily &&
        this.orderFamily.children &&
        this.orderFamily.children.length > 0
      ) {
        this.orderFamily.children.forEach((child) => {
          child.driverIds = null;
          child.productIds = child?.products?.map((x) => x.id);
          this.ordersService.updateDriver(child.id, child).subscribe();
        });
      }
    }
  }

  openDriversModal(order: Order) {
    this.parkedOrder = order;
    this.lastDriver = (this.driversM as User[]).find(
      (x) => x.id === order.userId
    );
  }

  updateParkedTrip(driverId: string) {
    if (this.parkedOrder) {
      this.driver = (this.driversM as User[]).find((x) => x.id === driverId);
      if (this.driver) {
        this.parkedOrder.userId = this.driver.id;
        this.order = this.parkedOrder;
        this.getOrder(this.parkedOrder);
        this.updateParkedDriver();
        this.parkedOrder = null;
      }
    }
  }

  updateParkedDriver() {
    if (this.order && this.driver && this.lastDriver) {
      this.order.userId = this.driver.id;
      this.ordersService
        .updateParkedDriver(this.order?.id, this.driver?.id)
        .subscribe();
      if (
        this.orderFamily &&
        this.orderFamily.children &&
        this.orderFamily.children.length > 0
      ) {
        this.orderFamily.children.forEach((child) => {
          child.userId = this.driver?.id;
          this.ordersService
            .updateParkedDriver(child.id, child.userId!)
            .subscribe();
        });
      }
    }
  }

  calculateDuration(order: Order) {
    if (order.tripEvents && order.tripEvents.length > 0) {
      let tripStartTime = order.tripEvents.find(
        (x) => x.type === 'TripStart'
      )?.eventTime;
      let tripEnd = order.tripEvents.find((x) => x.type === 'TripEnd');
      if (!tripEnd) {
        let dateTimeNow = this.ngDay.dayjs().utc().local().diff(tripStartTime);
        return this.convertMS(dateTimeNow - (order?.parkDuration ?? 0));
      } else {
        let duration = this.ngDay.dayjs
          .duration(
            this.ngDay
              .dayjs(tripEnd.eventTime)
              .diff(this.ngDay.dayjs(tripStartTime))
          )
          .asMilliseconds();
        return this.convertMS(duration - (order?.parkDuration ?? 0));
      }
    }
    return '';
  }

  calculateDurationForParked(order: Order) {
    if (order.tripEvents && order.tripEvents.length > 0) {
      let tripStartTime = order.tripEvents.find(
        (x) => x.type === 'TripStart'
      )?.eventTime;
      let duration = this.ngDay.dayjs
        .duration(
          this.ngDay.dayjs(order.parkedAt).diff(this.ngDay.dayjs(tripStartTime))
        )
        .asMilliseconds();
      return this.convertMS(duration - (order?.parkDuration ?? 0));
    }
    return '';
  }

  setStats(index: number, stats?: TruckStatistics) {
    this.driverStats[index] = stats?.activeDrivers?.toString() || '';
    this.tripStats[index] = stats?.trips.toString() || '';
    this.productStats[index] = stats?.unloadedProducts.toString() || '';
  }

  resetChild(needToReload: boolean = true, needObliterate = false) {
    if (this.componentRef !== undefined) {
      this.componentRef.destroy();
      this.componentRef = undefined;
    }
    this.companiesService.loadAsListwithRepo().subscribe();
    this.companyRepoSubscribe = this.companiesRepo.all$.subscribe((x) => {
      this.companiesT = x;
    });

    this.companiesService.loadDeletedAsListwithRepo().subscribe();
    this.companyDeletedRepoSubscribe = this.companiesRepo.allDeleted$.subscribe(
      (x) => {
        this.companiesTD = x;
      }
    );

    this.orderForm?.forEach((c) => c.form?.reset());
    this.orderUpdate = null;

    if (needToReload) {
      //this.companiesT = [];
      this.companiesTD = [];
    }

    this.ref.markForCheck();
    if (needObliterate) {
      this.baseService.changePreviousDashboardFromData(undefined);
      this.baseService.changeOrderFormParentId(null);
    }
    // const myNode = document.getElementById('orderForm');
    // if (myNode) {
    //   myNode.innerHTML = '';
    // }
  }

  findParentId() {
    return this.orderForm?.get(0)?.form?.get('parentId')?.value;
  }

  toCorrectDate(date?: Date, type?: string) {
    if (!date) {
      return;
    }
    if (type === 'day') {
      return this.ngDay.dayjs
        .utc(date)
        .local()
        .format('DD/MM/YYYY')
        .slice(0, 16);
    }
    if (type === 'time') {
      return this.ngDay.dayjs.utc(date).local().format('H:mm').slice(0, 16);
    } else {
      return this.ngDay.dayjs
        .utc(date)
        .local()
        .format('H:mm DD/MM/YYYY')
        .slice(0, 16);
    }
  }

  getDatesToModal() {
    if (this.orderForm) {
      let today = this.ngDay.dayjs.utc();
      let minutes = today.get('minutes');
      let todayStart = today
        .subtract(minutes, 'minute')
        .add(1, 'hours')
        .toDate();
      let todayEnd = this.ngDay.dayjs
        .utc()
        .add(2, 'hours')
        .subtract(minutes, 'minute')
        .toDate();
      const dayObjStart = this.ngDay.dayjs.utc(todayStart).local();
      const dayObjEnd = this.ngDay.dayjs.utc(todayEnd).local();
      this.orderForm?.map((c) => {
        if (c.form) {
          this.updateValue(
            c.form,
            'plannedToStartAtDate',
            dayObjStart.format('YYYY-MM-DD')
          );
          this.updateValue(
            c.form,
            'plannedToStartAtTime',
            dayObjStart.subtract(minutes).format('HH:mm')
          );
          this.updateValue(
            c.form,
            'plannedToEndAtDate',
            dayObjEnd.format('YYYY-MM-DD')
          );
          this.updateValue(
            c.form,
            'plannedToEndAtTime',
            dayObjEnd.subtract(minutes).format('HH:mm')
          );
        }
      });
    }
  }

  updateLinkedChild(order?: Order, parentId?: string) {
    this.previous = order;
    if (order && parentId) {
      this.baseService.changeOrderFormParentId(parentId);
      if (this.newOrder) {
        this.newOrder.nativeElement.click();
      }
    }
    // if (order && this.orderForm) {
    //   this.orderForm?.map((c) => {
    //     if (c.form) {
    //       let today = this.ngDay.dayjs.utc().local();
    //       let minutes = today.get('minutes');
    //       let todayStart = today.subtract(minutes, 'minute').add(1, 'hours');
    //       let todayEnd = today.subtract(minutes, 'minute').add(2, 'hours');
    //       const dayObjStart = order?.plannedToStartAt
    //         ? this.ngDay.dayjs
    //             .utc(order.plannedToStartAt)
    //             .add(1, 'hours')
    //             .local()
    //         : todayStart;
    //       const dayObjEnd = order?.plannedToEndAt
    //         ? this.ngDay.dayjs.utc(order.plannedToEndAt).add(1, 'hours').local()
    //         : todayEnd;
    //       this.updateValue(c.form, 'parentId', parentId);
    //       this.updateValue(
    //         c.form,
    //         'plannedToStartAtDate',
    //         dayObjStart.format('YYYY-MM-DD')
    //       );
    //       this.updateValue(
    //         c.form,
    //         'plannedToStartAtTime',
    //         dayObjStart.format('HH:mm')
    //       );
    //       this.updateValue(
    //         c.form,
    //         'plannedToEndAtDate',
    //         dayObjEnd.format('YYYY-MM-DD')
    //       );
    //       this.updateValue(
    //         c.form,
    //         'plannedToEndAtTime',
    //         dayObjEnd.format('HH:mm')
    //       );
    //       this.updateValue(c.form, 'driverIds', order.driverIds);
    //       this.updateValue(
    //         c.form,
    //         'productIds',
    //         order?.products?.map((x) => x.id)
    //       );
    //       this.updateValue(c.form, 'clientId', order.clientId || '');
    //       this.updateValue(c.form, 'vendorId', order.vendorId);
    //       this.updateValue(c.form, 'note', order.note);
    //       this.updateValue(c.form, 'tenantNote', order.tenantNote);
    //     }
    //   });
    // }
  }

  getTimeFrom(order?: Order) {
    let today = this.ngDay.dayjs.utc().local();
    let minutes = today.get('minutes');
    let todayStart = today.subtract(minutes, 'minute').add(1, 'hours');
    let todayEnd = today.subtract(minutes, 'minute').add(2, 'hours');

    const dayObjStart = order?.plannedToStartAt
      ? this.ngDay.dayjs.utc(order.plannedToStartAt).add(1, 'hours').local()
      : todayStart;
    return dayObjStart?.format('HH:mm');
  }

  getTimeTo(order?: Order) {
    let today = this.ngDay.dayjs.utc().local();
    let minutes = today.get('minutes');
    let todayStart = today.subtract(minutes, 'minute').add(1, 'hours');
    let todayEnd = today.subtract(minutes, 'minute').add(2, 'hours');

    const dayObjEnd = order?.plannedToEndAt
      ? this.ngDay.dayjs.utc(order.plannedToEndAt).add(1, 'hours').local()
      : todayEnd;
    return dayObjEnd?.format('HH:mm');
  }

  updateChild(order?: Order) {
    if (order && this.orderForm) {
      this.orderUpdate = order;
      this.orderForm?.map((c) => {
        if (c.form) {
          const dayObjStart = this.ngDay.dayjs
            .utc(order.plannedToStartAt)
            .local();
          const dayObjEnd = this.ngDay.dayjs.utc(order.plannedToEndAt).local();
          let weight = this.orderUpdate?.weight?.toString();
          if (weight?.includes('.')) {
            weight = weight.replace(/\./g, ',');
          }
          this.updateValue(c.form, 'reference', order.reference);
          this.updateValue(c.form, 'parentId', order.parentId);
          this.updateValue(
            c.form,
            'plannedToStartAtDate',
            dayObjStart.format('YYYY-MM-DD')
          );
          this.updateValue(
            c.form,
            'plannedToStartAtTime',
            dayObjStart.format('HH:mm')
          );
          this.updateValue(
            c.form,
            'plannedToEndAtDate',
            dayObjEnd.format('YYYY-MM-DD')
          );
          this.updateValue(
            c.form,
            'plannedToEndAtTime',
            dayObjEnd.format('HH:mm')
          );
          this.updateValue(c.form, 'driverIds', order.driverIds);
          this.updateValue(c.form, 'weight', weight);
          this.updateValue(c.form, 'deliveryAddress', order.deliveryAddress);
          this.updateValue(c.form, 'pickUpAddress', order.pickUpAddress);
          this.updateValue(c.form, 'clientId', order.clientId || '');
          this.updateValue(c.form, 'vendorId', order.vendorId);
          this.updateValue(c.form, 'accessoryId', order.accessoryId);
          this.updateValue(c.form, 'contactPerson', order.contactPerson);
          this.updateValue(c.form, 'cleaningId', order.cleaningId);
          this.updateValue(c.form, 'note', order.note);
          this.updateValue(c.form, 'tenantNote', order.tenantNote);
          this.updateValue(c.form, 'tripEvents', order.tripEvents);
          this.updateValue(
            c.form,
            'productIds',
            order.products?.map((x) => x.id)
          );
        }
      });
    }
  }

  updateValue(form: UntypedFormGroup, control: string, value: any) {
    const controlObject = form?.get(control);
    controlObject?.setValue(value);
  }

  checkRejected(orders: Order[]) {
    if (orders.find((a) => a.isRejected)) {
      return true;
    }
    return false;
  }

  checkFinished(orders: Order[], driverid: string) {
    return orders.filter(
      (a) =>
        a.userId === driverid &&
        !a.isRejected &&
        a.tripEvents.find((b) => b.type === 'TripEnd')
    );
  }

  checkParked(orders: Order[], driverid: string) {
    let parked = orders.filter(
      (a) =>
        (a.userId === driverid &&
          a.tripEvents.find((x) => x.type === 'TripStart') &&
          !a.isRejected &&
          a.parkedAt &&
          !a.resumedAt) ||
        (a.parkedAt &&
          a.resumedAt &&
          a.parkedAt > a.resumedAt &&
          !a.tripEvents.find((y) => y.type === 'TripEnd'))
    );
    return parked;
  }

  checkStarted(orders: Order[], driverid: string) {
    return orders.filter(
      (a) =>
        a.userId === driverid &&
        a.tripEvents.find((x) => x.type === 'TripStart') &&
        !a.isRejected &&
        (!a.parkedAt ||
          (a.parkedAt && a.resumedAt && a.resumedAt > a.parkedAt)) &&
        !a.tripEvents.find((y) => y.type === 'TripEnd')
    );
  }

  checkOpen(orders: Order[]) {
    let length = orders.filter(
      (x) =>
        (!x.driverIds || x.driverIds.length) === 0 && !x.userId && !x.isRejected
    ).length;
    return length > 0;
  }

  checkNotStarted(orders: Order[], driverid: string) {
    return orders.filter(
      (a) =>
        (a.userId === driverid &&
          !a.isRejected &&
          (!a.tripEvents || a.tripEvents.length === 0)) ||
        (!a.userId &&
          a.driverIds &&
          a.driverIds?.includes(driverid) &&
          !a.isRejected &&
          (!a.tripEvents || a.tripEvents.length === 0))
    );
  }

  getProducts(products?: Product[]) {
    if (products && products.length > 1) {
      return products[0].name + '...';
    }

    if (products && products.length === 1) {
      return products[0].name;
    }
    return '';
  }
  cleanings: any;
  accessories: any;
  loadDataForPopUp(order?: any) {
    this.componentRef?.instance.reset();
    if (order) {
      this.updateChild(order);
      this.previous = this.orders?.filter(
        (x: any) => x.id == order.parentId
      )[0];
    }

    const injector = Injector.create({
      providers: [],
      parent: this.injector,
    });
    const factory = this.resolver.resolveComponentFactory(OrderFormComponent);
    this.componentRef = this.container?.createComponent(
      factory,
      undefined,
      injector
    );
    if (this.componentRef?.instance) {
      this.componentRef.instance.companies = this.companiesT;
      this.componentRef.instance.companiesDeleted = this.companiesTD;
      this.componentRef.instance.orderUpdate = this.orderUpdate;
      this.componentRef.instance.products = this.products;

      this.componentRef.instance.productsDeleted = this.prodsDeleted;
      this.componentRef.instance.DriversDisabled =
        this.previous !== null && this.previous !== undefined;
      this.componentRef.instance.orders = this.orders;
      this.componentRef.instance.cleanings = this.cleanings;

      this.componentRef.instance.accessories = this.accessories;
      this.componentRef.instance.drivers = this.driversM;

      this.componentRef.instance.accessoriesDeleted = this.accessoriesDeleted;
      this.componentRef.instance.cleaningsDeleted = this.cleaningsDeleted;

      this.componentRef.instance.isRejected = this.orderUpdate?.isRejected;
      this.componentRef.instance.rejectedAt = this.orderUpdate?.rejectedAt;
      if (!this.orderUpdate?.parentId) {
        this.componentRef.instance.isRejected = false;
      }

      this.componentRef.instance.vendorsDeleted = this.companiesTD.filter(
        (x: any) => x.canBeVendor || (!x.canBeClient && !x.canBeVendor)
      );

      this.componentRef.instance.driversDeleted = this.usersDeleted;

      this.componentRef.instance.timeFrom = this.getTimeFrom(this.orderUpdate!);
      this.componentRef.instance.timeTo = this.getTimeTo(this.orderUpdate!);

      if (!this.saveSub) {
        this.saveSub = this.baseService.saveEvent$.subscribe((x) => {
          if (x) {
            this.createUpdateOrder(x);
            this.baseService.sendSaveEvent(undefined);
          }
        });
      }

      if (!this.cancelSub) {
        this.cancelSub = this.baseService.cancelEvent$.subscribe((x) => {
          if (x) {
            this.componentRef?.destroy();
            this.componentRef = undefined;
          }
        });
      }

      if (!this.linkedOrderSub) {
        this.linkedOrderSub = this.baseService.linkedOrderEvent$.subscribe(
          (x) => {
            if (x) {
              this.componentRef?.destroy();
              this.componentRef = undefined;
              this.addLinkedTrip(x);
              this.baseService.sendLinkedOrderEvent(undefined);
            }
          }
        );
      }
    }
    this.componentRef?.onDestroy(() => {
      this.previous = null;
      this.orderUpdate = null;
      this.resetChild(false, true);
    });
    //this.accessoryService.load().subscribe();
    //this.cleaningService.load().subscribe();
    //this.companiesService.load().subscribe();
    //this.productsService.load().subscribe();
  }

  linkedOrderSub: any;
  saveSub: any;
  cancelSub: any;

  driversM: any;
  orders: Order[] | null = null;
  checkIfOpen(order: Order) {
    if (
      !order.userId &&
      (!order.driverIds || order.driverIds.length === 0) &&
      !order.isRejected
    ) {
      return true;
    }
    return false;
  }

  findEvent(order: Order | null, event: string) {
    if (order && order.tripEvents.find((x) => x.type === event)) {
      return true;
    }
    return false;
  }

  compareStrings(start?: string | number, end?: string | number): string {
    if (start && end && start === end) {
      return start.toString();
    }
    if (start && end && start !== end) {
      return `${start + '/'}<br> ${end}`;
    }
    if (start && !end) {
      return `${start}/`;
    }
    if (!start && end) {
      return `/${end}`;
    }
    return '';
  }

  curentParentId: any;

  addLinkedTrip(order: Partial<Order>) {
    setTimeout(() => {
      order.casualUpdate = true;
      // if (this.curentParentId) {
      //   order.parentId = this.curentParentId;
      // }

      this.ordersService.add(order).subscribe((x) => {
        this.updateLinkedChild(x, x.id);
      });
    }, 200);
  }

  createUpdateOrder(order: Partial<Order>) {
    if (this.orderUpdate) {
      if (order) {
        let parent = this.the_orders?.find((x) => x.id === order?.parentId);
        if (!parent) {
          order.parentId = null;
        }
      }
      this.ordersService.update(this.orderUpdate.id, order).subscribe(() => {
        let family = this.orderFamilies?.find(
          (a) => a.parentId === this.orderUpdate?.id
        );
        if (family) {
          family.children.forEach((x) =>
            this.ordersService.updateDriver(x.id, order).subscribe()
          );
        }
        this.resetChild();
        if (this.closeModal) {
          this.closeModal.nativeElement.click();
        }
        document
          .getElementById('order_filter_search')
          ?.dispatchEvent(new Event('input', { bubbles: true }));
      });
    }
    if (!this.orderUpdate) {
      this.ordersService.add(order).subscribe(() => {
        if (!order.isReccurence) {
          this.resetChild();
          if (this.closeModal) {
            this.closeModal.nativeElement.click();
          }
          document
            .getElementById('order_filter_search')
            ?.dispatchEvent(new Event('input', { bubbles: true }));
        } else {
          this.resetChild();
          if (this.closeModal) {
            this.closeModal.nativeElement.click();
          }
          document
            .getElementById('order_filter_search')
            ?.dispatchEvent(new Event('input', { bubbles: true }));

          // this.ordersService.loadOrders().subscribe();
        }
      });
    }
    this.curentParentId = undefined;
    this.baseService.changeOrderFormParentId(undefined);
    this.baseService.sendSaveEvent(undefined);
    this.order = null;
  }
  @ViewChild('container', { read: ViewContainerRef })
  container?: ViewContainerRef;

  previous: any;
  componentRef?: ComponentRef<any>;

  ngAfterViewInit() {
    this.ordersRepo.one(this.findParentId()).subscribe((x) => {
      this.previous = x;
    });
    this.tooltips();
  }

  tooltips(): void {
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl, {
        popperConfig: function (defaultBsPopperConfig: any) {
          return defaultBsPopperConfig;
        },
      });
    });
  }
  popupTemplate: string = `<div style="max-width:500px; width:500px" class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>`;

  infoPopup: string = `<div style="width:400px !important; color:red">GRØN = Ture der skal køres i dag ( d.d.) <br/>LYSEBLÅ = Ture der skal køres i morgen
  <br/>BLÅ = Ture der skal køres indenfor et dato interval. ( Skifter til LYSEBLÅ / GRØN når man nærmer sig sidste dato for kørsel af turen ).
  <br/>RØD = Alle ture i pulje der IKKE er kørt/påbegyndt endnu.
  <br/>ORANGE = Ture der er påbegyndt af chaufføren.
  <br/>LILLA = Tur der er parkeret af chaufføren ( NB Turen kan flyttes til en anden chauffør hvis det viser sig nødvendigt ).
  </div>`;
}
// children.forEach(checker => {
//       children.forEach(checked => {
//         if(checker.id === checked.parentId) {
//           let family = families?.find(a => a.parentId === checker.parentId);
//           family?.children.push(checker);
//           family?.children.push(checked);
//           let checkerIndex = children.indexOf(checker);
//           let checkedIndex = children.indexOf(checked);
//           children.splice(checkedIndex, 1);
//           children.splice(checkerIndex, 1)
//         }
//       });
//     });
