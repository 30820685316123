import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UrlIdResolverService } from 'src/app/modules/shared/services/url-id-resolver.service';
import { Trailer, TrailersRepository } from 'src/app/state/trailers.repository';
import { TrailersService } from 'src/app/state/trailers.service';

@Component({
  selector: 'app-trailer-create-page',
  templateUrl: './trailer-create-page.component.html',
  styleUrls: ['./trailer-create-page.component.scss']
})
export class TrailerCreatePageComponent implements OnInit {
  trailer$: Observable<Trailer> | null = null;
  id: string | undefined;
  submitErrors: string[] | null = null;

  constructor(
    private trailerService: TrailersService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private repo: TrailersRepository,
    private urlId: UrlIdResolverService
  ) { }

    ngOnInit(): void {
      this.trailer$ = this.urlId.resolve(
        this.activatedRoute.paramMap,
        id => this.trailerService.loadOne(id),
        id => this.repo.one(id),
        id => this.repo.statusOne(id),
        id => this.id = id
      );
    }

    createTrailer(trailer: Partial<Trailer>) {
      this.submitErrors = null;
      let updateResult: Observable<Trailer> | undefined;
      if(this.id === 'new'){
        updateResult = this.trailerService.add(trailer);

      } else if(this.id){
        updateResult = this.trailerService.update(this.id , trailer);
      }
      if (updateResult) {
        updateResult.subscribe({
          complete: () => {
            this.router.navigate(['/trailers']);
          },
          error: data => this.submitErrors = data
        });
      }
    }
}
