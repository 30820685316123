import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthRepository } from 'src/app/state/auth.repository';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit {
  readonly DEFAULT_IMAGE = 'assets/img/user_default.svg';

  @Input() size: 'sm' | 'md' | 'lg' = 'lg';
  @Input() preventClick = false;
  @Output() profileClick = new EventEmitter<Event>();

  constructor(public repo: AuthRepository) { }

  ngOnInit(): void {
  }

}
