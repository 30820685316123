import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DayjsService } from 'src/app/modules/shared/services/dayjs.service';
import { AuthRepository, UserSettings } from 'src/app/state/auth.repository';
import {
  Order,
  OrderFamily,
  OrdersRepository,
} from 'src/app/state/orders.repository';
import { Trip } from 'src/app/state/trips.repository';
import { Workday } from 'src/app/state/workdays.repository';

const ANIMATION_DELAY = 500;

@Component({
  selector: 'app-start-order-form',
  templateUrl: './start-order-form.component.html',
  styleUrls: ['./start-order-form.component.scss'],
})
export class StartOrderFormComponent implements OnInit {
  currentChild?: Order | null = null;
  @Input() isLoading: boolean | null = null;
  @Input() isLoadingOrder: boolean | null = null;
  @Input() userSettings: UserSettings | null = null;
  @Output() startOrder = new EventEmitter<string>();
  @Output() rejectOrder = new EventEmitter<string>();
  @Output() vehicleError = new EventEmitter<void>();
  @Output() resume = new EventEmitter<string>();
  @Input() workDay: Workday | null = null;
  @Input() orders: Order[] | null = null;
  @Input() activeOrder: Order | null = null;
  @Input() isTripActive = false;
  @Input() isOrderActive = false;
  @Input() currentChildren: string[] | null = null;
  @Input() isDayPaused: boolean = false;
  orderFamily?: OrderFamily | null = null;
  orderFamilies: OrderFamily[] | null = null;
  isVehicleError = false;
  userSub: any;
  userId: string | null = null;

  sub: any;

  constructor(
    public ngDay: DayjsService,
    public router: Router,
    public authRepo: AuthRepository,
    public repo: OrdersRepository
  ) {
    this.userSub = this.authRepo.id$.subscribe((a) => (this.userId = a));
    this.sub = repo.ordersByUser$(this.userId!).subscribe((x) => {
      this.orders = x;
      let currentChildrenStored = localStorage.getItem('currentChildren');
      if (currentChildrenStored) {
        this.currentChildren = currentChildrenStored.split(',');
        if (this.currentChildren && this.currentChildren.length > 0) {
          let id = this.currentChildren[0];
          this.currentChild = this.orders.find((x) => x.id === id);
        }
      }
    });
  }

  findChilds(id: string) {
    var ids: string[] = [];
    if (this.orders) {
      var check = true;
      while (check) {
        var order = this.orders.find((x) => x.parentId === id);
        if (order) {
          id = order.id;
          ids.push(id);
        } else {
          check = false;
          return ids;
        }
      }
    }
    return [];
  }

  findChild(id: string) {
    if (this.orders) {
      return this.orders.find((x) => x.parentId === id);
    }
    return null;
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    // this.sub.unsubscribe();
    this.userSub.unsubscribe();
  }

  launchStartOrder(id: string) {
    if (!this.userSettings?.vehicleId) {
      this.vehicleError.emit();
      return;
    }
    this.startOrder.emit(id);
  }

  reject(id: string) {
    this.rejectOrder.emit(id);
  }

  hideNotSatisfied(order: Order) {
    if (order.parentId && order.id !== this.activeOrder?.id) {
      if (this.currentChild && this.currentChild.id === order.id) {
        return false;
      }
      return true;
    } else if (this.currentChild && this.currentChild.id !== order.id) {
      return true;
    }
    return false;
  }

  toCorrectDate(date: Date, type?: string) {
    if (type === 'day') {
      return this.ngDay.dayjs
        .utc(date)
        .local()
        .format('DD/MM/YYYY')
        .slice(0, 16);
    }
    if (type === 'time') {
      return this.ngDay.dayjs.utc(date).local().format('H:mm').slice(0, 16);
    } else {
      return this.ngDay.dayjs
        .utc(date)
        .local()
        .format('H:mm DD/MM/YYYY')
        .slice(0, 16);
    }
  }
}
