import { Injectable } from "@angular/core";
import { createStore } from "@ngneat/elf";
import { selectAllEntities, upsertEntities, withEntities } from "@ngneat/elf-entities";
import { createRequestsCacheOperator, createRequestsStatusOperator, selectRequestStatus, updateRequestCache, updateRequestsStatus, withRequestsCache, withRequestsStatus } from "@ngneat/elf-requests";
import { map, MonoTypeOperatorFunction } from "rxjs";
import { SortOption } from "../modules/shared/pipes/sort.pipe";

export interface Feature {
  id: string;
  name: string;
  createdAt: Date;
}

export const featureSortOptions: SortOption[] = [
  { label: $localize `:Sort label Name:Name`, property: 'name' },
  { label: $localize `:Sort label Date:Date`, property: 'createdAt' }
];

export enum TenantFeatures {
  Accessory = "Accessories",
  Cleaning = "Cleanings",
  Note = "Note",
  Products = "Products",
  Reference = "Reference",
  Vendor = "Vendor",
  ContactPerson = "ContactPerson",
  Order = "Order",
  Economic = "E-conomic",
  EconomicOrder = "EconomicOrder"
}

const store = createStore(
  { name: 'features' },
  withEntities<Feature>(),
  withRequestsCache(),
  withRequestsStatus()
);

@Injectable({ providedIn: 'root' })
export class FeatureRepository {

  constructor() { }

  private trackOperator = createRequestsStatusOperator(store);
  private skipOperator = createRequestsCacheOperator(store);

  isLoading$ = store.pipe(
      selectRequestStatus(store.name),
      map(x => x.value === 'pending')
  );

  all$ = store.pipe(selectAllEntities());

  set(models: Feature[]): void {
      store.update(
          updateRequestCache(store.name),
          upsertEntities(models),
          updateRequestsStatus([store.name], 'success')
      );
  }

  track(): MonoTypeOperatorFunction<any> {
      return this.trackOperator(store.name);
  }

  skipWhileCached(): MonoTypeOperatorFunction<any> {
      return this.skipOperator(store.name);
  }
}