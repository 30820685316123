import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthRepository } from 'src/app/state/auth.repository';
import { Column, ColumnsRepository } from 'src/app/state/columns.repository';
import { ColumnsService } from 'src/app/state/columns.service';
import {
  CompaniesRepository,
  Company,
} from 'src/app/state/companies.repository';
import { CompaniesService } from 'src/app/state/companies.service';
import { TripEventsService } from 'src/app/state/tripevents.service';
import {
  Product,
  ProductsRepository,
} from '../../../../state/products.repository';
import { ProductsService } from '../../../../state/products.service';
import {
  PhotoEventDto,
  PhotoModalEventDto,
  TenantNoteEvent,
  Trip,
  TripEventFile,
  TripsRepository,
} from '../../../../state/trips.repository';
import { TripsService } from '../../../../state/trips.service';
import {
  User,
  UserSelectDto,
  UsersRepository,
} from '../../../../state/users.repository';
import { UsersService } from '../../../../state/users.service';
import { AccessoriesService } from 'src/app/state/accessory.service';
import { CleaningsService } from 'src/app/state/cleaning.service';
import { TripLogDto } from 'src/app/state/models/trip/TripLogDto';
import { DataSelectDto } from 'src/app/state/models/dataSelectModels/dataSelectDto';
import { DayjsService } from 'src/app/modules/shared/services/dayjs.service';
import { FilterOptions } from 'src/app/state/workdays.repository';
import { TenantNoteChangeDto } from 'src/app/state/models/shared/TenantNoteChangeDto';
import { TripEventFileUploadDto } from 'src/app/state/models/shared/TripEventFileUploadDto';
import { TripPhotoUrlDto } from 'src/app/state/models/trip/TripPhotoUrlDto';

@Component({
  selector: 'app-driver-logs-page',
  templateUrl: './driver-logs-page.component.html',
  styleUrls: ['./driver-logs-page.component.scss'],
})
export class DriverLogsPageComponent implements OnInit {
  isColumnsLoaded: boolean = false;
  isClientsLoaded: boolean = false;
  isProductsLoaded: boolean = false;

  isParkedOverview: boolean = false;

  driverSelectOptions: UserSelectDto[] | undefined;
  clientSelectOptions: DataSelectDto[] | undefined;
  productSelectOptions: DataSelectDto[] | undefined;
  loadUnloadColumns: Column[] | null = null;
  tableColumns: Column[] | null = null;

  tripLogs: TripLogDto[] | null | any = null;

  dayObj = this.ngDay.dayjs.utc().local().format('YYYY-MM-DD');
  filterOptions: FilterOptions = {
    isApproved: false,
    dateFrom: this.dayObj,
    dateTo: this.dayObj,
    driverSelected: undefined,
    clientSelected: undefined,
    productSelected: undefined,
    isParkedOverview: false,
  };

  constructor(
    public ngDay: DayjsService,
    public authRepo: AuthRepository,
    public repo: TripsRepository,
    public service: TripsService,
    public router: ActivatedRoute,
    public usersService: UsersService,
    public productsService: ProductsService,
    private columnsService: ColumnsService,
    private companiesService: CompaniesService
  ) {
    this.router.url.subscribe((x) => {
      x.map((f) => {
        if (f.path.includes('parkedtrips')) {
          this.isParkedOverview = true;
          this.filterOptions.isParkedOverview = true;
        }
      });
    });

    this.getFiltersFromLocaleStorage();
  }

  getFiltersFromLocaleStorage() {
    let dateFrom = localStorage.getItem('dateFrom')?.toString();
    if (dateFrom) {
      this.filterOptions.dateFrom = dateFrom;
    }
    let dateTo = localStorage.getItem('dateTo')?.toString();
    if (dateTo) {
      this.filterOptions.dateTo = dateTo;
    }
    this.filterOptions.clientSelected =
      localStorage.getItem('clientSelected')?.toString() || '';
    this.filterOptions.driverSelected =
      localStorage.getItem('driverSelected')?.toString() || '';
    this.filterOptions.isApproved = JSON.parse(
      localStorage.getItem('isApprovedSearch')?.toString() || 'false'
    );
    var productSelected = localStorage.getItem('productSelected');
    if (productSelected) {
      this.filterOptions.productSelected = JSON.parse(productSelected);
    }
  }

  requestLoadData(filterOptions: FilterOptions) {
    this.filterOptions = filterOptions;
    if (
      this.filterOptions &&
      this.filterOptions.dateFrom &&
      this.filterOptions.dateTo &&
      this.filterOptions.dateTo >= this.filterOptions.dateFrom
    ) {
      this.setFiltersToLocaleStorage();
      this.loadTripLogs(this.filterOptions);
    }
  }

  setFiltersToLocaleStorage() {
    localStorage.setItem('dateFrom', this.filterOptions.dateFrom ?? '');
    localStorage.setItem('dateTo', this.filterOptions.dateTo ?? '');
    localStorage.setItem(
      'clientSelected',
      this.filterOptions.clientSelected || ''
    );
    localStorage.setItem(
      'driverSelected',
      this.filterOptions.driverSelected || ''
    );

    localStorage.setItem(
      'productSelected',
      JSON.stringify(this.filterOptions.productSelected || '')
    );
    localStorage.setItem(
      'isApprovedSearch',
      JSON.stringify(this.filterOptions.isApproved)
    );
  }

  loadTripLogs(filterOptions: FilterOptions) {
    this.service.loadTripLogsOverview(filterOptions).subscribe((x) => {
      this.tripLogs = x;
    });
  }

  ngOnInit(): void {
    this.usersService
      .loadDriversForSelect()
      .subscribe((x) => (this.driverSelectOptions = x));

    this.companiesService.loadCompaniesForSelect().subscribe((x) => {
      this.clientSelectOptions = x;
      this.isClientsLoaded = true;
    });

    this.productsService.loadProductsForSelect().subscribe((x) => {
      this.productSelectOptions = x;
      this.isProductsLoaded = true;
    });

    this.columnsService.loadAll().subscribe((x) => {
      this.loadUnloadColumns = x.filter((c) => c.isUnloadColumn);
      this.tableColumns = x.filter((c) => c.isStandartColumn);
      if (!this.isParkedOverview) {
        this.tableColumns = this.tableColumns.map((col) => {
          if (col.name === 'Trip Info' || col.name === 'Time') {
            return { ...col, isVisible: false };
          }
          return col;
        });
      }
      this.isColumnsLoaded = true;
    });

    this.loadTripLogs(this.filterOptions);
  }

  updateTenantNote($event: TenantNoteChangeDto) {
    this.service.updateTenantNote($event).subscribe();
  }

  addPhotos(event: TripEventFileUploadDto) {
    this.service
      .addTripEventPhoto(event.tripId, event.tripEventId, event.files)
      .subscribe({
        complete: () => {
          this.loadTripLogs(this.filterOptions);
        },
      });
  }

  movePhoto(event: TripPhotoUrlDto) {
    this.service.movePhotoEvent(event).subscribe({
      complete: () => {
        this.loadTripLogs(this.filterOptions);
      },
    });
  }

  checkIfDataLoaded() {
    return (
      this.isColumnsLoaded && this.isClientsLoaded && this.isProductsLoaded
    );
  }
}
