import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Column } from 'src/app/state/columns.repository';

@Component({
  selector: 'app-column-card',
  templateUrl: './column-card.component.html',
  styleUrls: ['./column-card.component.scss']
})
export class ColumnCardComponent implements OnInit {
  @Input() column: Column | null = null;
  @Input() isEconomic = false;
  constructor() { }
  
  replaceWithOnly(str: string) {
    if(this.isEconomic) {
      return str.replace('Vare art, Vare art Losning, Vare art lastning ', 'Vare art').replace('ID', 'Navn');
    }
    return str;
  }

  ngOnInit(): void {
  }

  

}
