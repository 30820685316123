<div
  class="modal fade in"
  id="sortModal"
  bsModal
  [config]="{ show: true }"
  tabindex="-1"
  #sortModal
  aria-labelledby="sortModal"
  aria-hidden="false"
  *ngIf="tripLog"
  (onHidden)="cancel.emit()"
>
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content bg-lights border-0">
      <div class="modal-header">
        <h6
          class="modal-title"
          id="sortModalLabel"
          i18n="Title for @LoadUnload"
        >
          Loads and Unloads
        </h6>
        <app-spinner *ngIf="!tripEventLogs"></app-spinner>
        <button
          type="button"
          class="btn-close"
          (click)="cancel.emit()"
          aria-label="Close"
          i18n-aria-label="Close label @@close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="form-floating mb-3">
          <div class="table-responsive custom-scroll">
            <table
              style="margin-top: 15px"
              class="table table-hover table-bordered"
            >
              <thead>
                <tr>
                  <th
                    class="text-center align-middle hoverable"
                    scope="col"
                    [hidden]="!item.isVisible"
                    *ngFor="let item of simpleLoadUnloadColumns; let i = index"
                  >
                    {{ item.danskName }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  id="orderingunload{{ i }}m"
                  [class.bg-even-grey]="i % 2"
                  *ngFor="let log of tripEventLogs; let i = index"
                >
                  <td id="Approved{{ i }}m" class="text-center align-middle">
                    {{ tripLog.isApproved ? "Behandlet" : "Ubehandlet" }}
                  </td>
                  <td id="Type{{ i }}m" class="text-center align-middle">
                    {{ log.type }}
                  </td>
                  <td id="Note{{ i }}m" class="text-center align-middle">
                    {{ log.note }}
                  </td>
                  <td id="ClientID{{ i }}m" class="text-center align-middle">
                    {{ log.clientId }}
                  </td>
                  <td id="ClientName{{ i }}m" class="text-center align-middle">
                    {{ log.clientName }}
                  </td>
                  <td id="Numberplate{{ i }}m" class="text-center align-middle">
                    {{ tripLog.carNumber }}
                  </td>
                  <td id="Totaltime{{ i }}m" class="text-center align-middle">
                    {{ convertMS(tripLog.tripDuration) }}
                  </td>
                  <td id="TripTotalKm{{ i }}m" class="text-center align-middle">
                    {{ tripLog.tripKm }}
                  </td>
                  <td id="DriverID{{ i }}m" class="text-center align-middle">
                    {{ tripLog.driverName }}
                  </td>
                  <td id="Trailer{{ i }}m" class="text-center align-middle">
                    {{ tripLog.trailerNumber }}
                  </td>
                  <td
                    id="ProductNameUnloading{{ i }}m"
                    class="text-center align-middle"
                  >
                    {{ log.productName }}
                  </td>
                  <td
                    id="Weightrelieved{{ i }}m"
                    class="text-center align-middle"
                  >
                    {{ log.weight }}
                  </td>
                  <td id="VendorID{{ i }}m" class="text-center align-middle">
                    {{ log.vendorId }}
                  </td>
                  <td id="VendorName{{ i }}m" class="text-center align-middle">
                    {{ log.vendorName }}
                  </td>
                  <td id="Reference{{ i }}m" class="text-center align-middle">
                    {{ tripLog.referenceStartEnd }}
                  </td>
                  <td id="Orderinfo{{ i }}m" class="text-center align-middle">
                    {{ log.comment }}
                  </td>
                  <td
                    id="Unloadingphotos{{ i }}m"
                    class="text-center align-middle"
                  >
                    <span *ngIf="log.photos && log.photos.length > 0">
                      <a
                        (click)="getPhotos(log)"
                        class="btn btn-sm btn-link py-0 p-md-0"
                      >
                        <i class="bi bi-card-image"></i>
                      </a>
                    </span>
                    <br />
                    <span>
                      <a class="btn btn-sm btn-link py-0 p-md-0">
                        <label
                          for="sortTableImg{{ i }}"
                          class="hoverable"
                          i18n="Label for img"
                          ><i class="bi bi-plus-lg"></i>
                        </label>
                        <input
                          type="file"
                          accept="image/*"
                          id="sortTableImg{{ i }}"
                          (change)="addImage($event, log.id)"
                          style="display: none"
                          multiple
                        />
                      </a>
                    </span>
                  </td>
                  <td
                    id="TimeofUnloading{{ i }}m"
                    class="text-center align-middle"
                  >
                    {{ log.eventTime | date : "dd-MM-YYYY HH:mm:ss" }}
                  </td>
                  <td
                    id="KmofUnloading{{ i }}m"
                    class="text-center align-middle"
                  >
                    {{ log.km }}
                  </td>
                  <td
                    id="EditofUnloading{{ i }}m"
                    *ngIf="!isEconomic"
                    class="text-center align-middle"
                  >
                    <a
                      class="btn btn-sm btn-link py-0 p-md-0"
                      (click)="navigateTo(log.id)"
                    >
                      <i class="bi bi-pencil me-1"></i>
                      <ng-container i18n="@@edit">Edit</ng-container>
                    </a>
                  </td>
                  <td
                    id="UnloadingGPS{{ i }}m"
                    class="text-center align-middle"
                  >
                    <a target="_blank" href="{{ log.position }}">Link</a>
                  </td>
                  <td id="Cleaning{{ i }}m" class="text-center align-middle">
                    {{ log.cleaning }}
                  </td>
                  <td id="Accessory{{ i }}m" class="text-center align-middle">
                    {{ log.accessories }}
                  </td>
                  <td id="Time{{ i }}m" class="text-center align-middle">
                    {{ convertMS(log.duration) }}
                  </td>
                  <td id="DiffKm{{ i }}m" class="text-center align-middle">
                    {{ log.diffKm }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-trip-image-slider
  *ngIf="selectedTripEventLogDto && selectedTripEventLogDto?.photos"
  [photoUrls]="selectedTripEventLogDto.photos"
  (movePhoto)="movePhotos($event)"
  (cancel)="cancelEvent()"
></app-trip-image-slider>
