import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  firstValueFrom,
  isObservable,
} from 'rxjs';

var _appDatalistSelectNextId = 0;

export type DatalistOption = {
  label: string;
  sublabel?: string;
  value: any;
  isDeleted?: boolean;
  isActive?: boolean;
};

const DELETED_RECORD = $localize`:Dropdown label for a deleted options:[Deleted record]`;
const NOT_AVIABLE_RECORD = $localize`:Dropdown label for not aviable options:[Not aviable record]`;
const NOT_AVIABLE_RECORD_SUBTITLE = $localize`:Dropdown description for not aviable options:This option has different tenant or not aviable for you`;
const DELETED_RECORD_SUBTITLE = $localize`:Dropdown description for a deleted options:This option has been removed from the database`;

declare const Zone: any;

@Component({
  selector: 'app-datalist-select',
  templateUrl: './datalist-select.component.html',
  styleUrls: ['./datalist-select.component.scss'],
})
export class DatalistSelectComponent implements OnInit {
  sub$: Subject<boolean> = new BehaviorSubject(false);
  subS: any;
  @Input() multiple = false;
  @Input() activeValue: any | undefined;
  @Output() activeValueChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() isDisabled: boolean | false = false;
  @Input() label: string | undefined;
  @Input() isCompanies: boolean = false;
  @Input() set options(val: DatalistOption[] | null) {
    this.optionsForCalc = val ?? [];
    if (
      val
        ?.map((f) => f.value)
        .some((g) => !this.test?.map((f) => f.value).includes(g)) ||
      this.test
        ?.map((f) => f.value)
        .some((g) => !val?.map((f) => f.value).includes(g)) ||
      val?.length == 0
    ) {
      this.testF();
    }
  }
  @Input() optionsDeleted: DatalistOption[] | null = [];
  @Input() width?: string = '100%';
  @Input() maxW?: string = '100%';
  @Output() clear: EventEmitter<any> = new EventEmitter<any>();
  @Input() isExported: boolean | undefined = false;

  prevB: any;
  checkCounter: number = 0;

  id = `appDatalistSelect_${_appDatalistSelectNextId++}`;

  constructor(private ref: ChangeDetectorRef) {}

  test: DatalistOption[] | null = [];
  optionsForCalc: DatalistOption[] = [];
  firstLoad: boolean = true;

  async delay(ms: number) {
    await new Promise((resolve) =>
      setTimeout(() => {
        if (this.checkCounter <= 20) {
          this.testF();
          this.checkCounter = this.checkCounter + 1;
        }
      }, ms)
    ).then();
  }

  ngOnInit(): void {}

  prev: any;

  ngAfterViewInit() {
    setTimeout(() => {
      this.testF();
    }, 100);
    this.firstLoad = false;
  }

  testF() {
    this.test = [];
    if (!this.activeValue) {
      this.activeValue = undefined;
    } else {
      const values: any[] = this.multiple
        ? this.activeValue
        : [this.activeValue];

      // If any deleted/unknown value are pre-selected,
      // we display them in the list as such.
      if (
        this.optionsDeleted &&
        this.optionsDeleted.some((x) => values.includes(x.value))
      ) {
        values.forEach((value) => {
          if (!this.optionsForCalc?.find((x) => x.value === value)) {
            var delOption = this.optionsDeleted?.find(
              (f: any) => f.value == value
            );

            if (delOption) {
              this.optionsForCalc?.unshift({
                value,
                label: delOption.label,
                sublabel: !delOption.isDeleted
                  ? DELETED_RECORD_SUBTITLE
                  : NOT_AVIABLE_RECORD_SUBTITLE,
                isDeleted: this.isExported ? false : true,
              });
            }
          }
        });
      } else {
        if (this.optionsForCalc) {
          this.optionsForCalc = this.optionsForCalc?.filter(
            (x) => !this.optionsDeleted?.map((f) => f.value).includes(x.value)
          );
        } else {
          this.test = [];
        }
      }
    }
    if (this.optionsForCalc && this.optionsForCalc.length > 0) {
      this.test = this.optionsForCalc;
    } else {
      this.test = [];
    }
  }
}
