import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth.guard';
import { ApphomelayoutComponent } from '../modules/core/layouts/apphomelayout/apphomelayout.component';
import { AppinnerlayoutComponent } from '../modules/core/layouts/appinnerlayout/appinnerlayout.component';
import { AccessoryCreatePageComponent } from '../modules/truck/pages/accessory-create-page/accessory-create-page.component';
import { AccessoryPageComponent } from '../modules/truck/pages/accessory-page/accessory-page.component';
import { CleaningCreatePageComponent } from '../modules/truck/pages/cleaning-create-page/cleaning-create-page.component';
import { CleaningPageComponent } from '../modules/truck/pages/cleaning-page/cleaning-page.component';
import { CompanyCreatePageComponent } from '../modules/truck/pages/company-create-page/company-create-page.component';
import { CompanyPageComponent } from '../modules/truck/pages/company-page/company-page.component';
import { GoPageComponent } from '../modules/truck/pages/go-page/go-page.component';
import { ProductCreatePageComponent } from '../modules/truck/pages/product-create-page/product-create-page.component';
import { ProductPageComponent } from '../modules/truck/pages/product-page/product-page.component';
import { TrailerCreatePageComponent } from '../modules/truck/pages/trailer-create-page/trailer-create-page.component';
import { TrailerPageComponent } from '../modules/truck/pages/trailer-page/trailer-page.component';
import { TripSettingsPageComponent } from '../modules/truck/pages/trip-settings-page/trip-settings-page.component';
import { TripEventPageComponent } from '../modules/truck/pages/trip-event-page/trip-event-page.component';
import { VehicleCreatePageComponent } from '../modules/truck/pages/vehicle-create-page/vehicle-create-page.component';
import { VehiclePageComponent } from '../modules/truck/pages/vehicle-page/vehicle-page.component';
import { UserRoles } from '../state/auth.repository';
import { DayHistoryPageComponent } from '../modules/truck/pages/day-history-page/day-history-page.component';
import { TripHistoryPageComponent } from '../modules/truck/pages/trip-history-page/trip-history-page.component';
import { DayEventPageComponent } from '../modules/truck/pages/day-event-page/day-event-page.component';
import { HomeGuard } from '../home.guard';
import { DashboardComponent } from '../modules/truck/pages/dashboard/dashboard.component';
import { DriverLogsPageComponent } from '../modules/truck/pages/driver-logs-page/driver-logs-page.component';
import { DriversWorkdaysPageComponent } from '../modules/truck/pages/drivers-workdays-page/drivers-workdays-page.component';
import { ColumnsPageComponent } from '../modules/truck/pages/columns-page/columns-page.component';
import { ActiveDriversPageComponent } from '../modules/truck/pages/active-drivers-page/active-drivers-page.component';
import { OrdersPageComponent } from '../modules/truck/pages/orders-page/orders-page.component';
import { OrderEventPageComponent } from '../modules/truck/pages/order-event-page/order-event-page.component';
import { NavigationPageComponent } from '../modules/truck/pages/navigation-page/navigation-page.component';
import { EconomicPageComponent } from '../modules/truck/pages/economic-page/economic-page.component';
import { EproductsPageComponent } from '../modules/truck/pages/eproducts-page/eproducts-page.component';
import { EproductEditPageComponent } from '../modules/truck/pages/eproduct-edit-page/eproduct-edit-page.component';
import { TenantFeatures } from '../state/feature.repository';
import { DeletedTripsPageComponent } from '../modules/truck/pages/deleted-trips-page/deleted-trips-page.component';
import { TripNavigationPageComponent } from '../modules/truck/pages/trip-navigation-page/trip-navigation-page.component';
import { TemplatesPageComponent } from '../modules/truck/pages/templates-page/templates-page.component';
import { CreateTemplatePageComponent } from '../modules/truck/pages/create-template-page/create-template-page.component';

const routes: Routes = [
  {
    path: '',
    component: ApphomelayoutComponent,
    canActivateChild: [AuthGuard],

    children: [
      {
        path: 'home',
        component: DashboardComponent,
        canActivate: [HomeGuard],
      },
      {
        path: 'go',
        component: GoPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: UserRoles.Driver },
      },
      {
        path: 'orders',
        component: OrdersPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: UserRoles.TenantAdmin },
      },
      {
        path: 'workdays',
        component: DayHistoryPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: UserRoles.Driver },
      },
      {
        path: 'workdays/user/:id',
        component: DayHistoryPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
      },
      {
        path: 'trips',
        component: TripHistoryPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: UserRoles.Driver },
      },
      {
        path: 'trips/user/:id',
        component: TripHistoryPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
      },
      {
        path: 'vehicles',
        component: VehiclePageComponent,
        canActivate: [AuthGuard],
        data: { authorize: UserRoles.TenantAdmin },
      },
      {
        path: 'trailers',
        component: TrailerPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: UserRoles.TenantAdmin },
      },
      {
        path: 'cleanings',
        component: CleaningPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: UserRoles.TenantAdmin },
      },
      {
        path: 'accessories',
        component: AccessoryPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: UserRoles.TenantAdmin },
      },
      {
        path: 'products',
        component: ProductPageComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: !TenantFeatures.Economic,
        },
      },
      {
        path: 'companies/clients',
        component: CompanyPageComponent,
        canActivate: [AuthGuard],
        data: { companiesType: 'clients', authorize: UserRoles.TenantAdmin },
      },
      {
        path: 'companies/vendors',
        component: CompanyPageComponent,
        canActivate: [AuthGuard],
        data: { companiesType: 'vendors', authorize: UserRoles.TenantAdmin },
      },
      {
        path: 'columns',
        component: ColumnsPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.TenantAdmin] },
      },
      {
        path: 'templates',
        component: TemplatesPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.TenantAdmin] },
      },
      {
        path: 'deleted-trips',
        component: DeletedTripsPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.TenantAdmin] },
      },
      {
        path: 'eproducts',
        component: EproductsPageComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.Economic,
        },
      },
      {
        path: 'drivers/trips',
        component: DriverLogsPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: UserRoles.TenantAdmin },
      },
      {
        path: 'drivers/parkedtrips',
        component: DriverLogsPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: UserRoles.TenantAdmin },
      },
      {
        path: 'drivers/economic',
        component: EconomicPageComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: UserRoles.TenantAdmin,
          feature: TenantFeatures.Economic,
        },
      },
      {
        path: 'drivers/workdays',
        component: DriversWorkdaysPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: UserRoles.TenantAdmin },
      },
      {
        path: 'drivers/today',
        component: TripHistoryPageComponent,
        canActivate: [AuthGuard],
        data: { dateType: 'day', authorize: UserRoles.Driver },
      },
      {
        path: 'drivers/thisweek',
        component: TripHistoryPageComponent,
        canActivate: [AuthGuard],
        data: { dateType: 'week', authorize: UserRoles.Driver },
      },
      {
        path: 'drivers/thismonth',
        component: TripHistoryPageComponent,
        canActivate: [AuthGuard],
        data: { dateType: 'month', authorize: UserRoles.Driver },
      },
      {
        path: 'activedrivers/:period',
        component: ActiveDriversPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.TenantAdmin, UserRoles.Superamin] },
      },
    ],
  },
  {
    path: '',
    component: AppinnerlayoutComponent,
    canActivateChild: [AuthGuard],

    children: [
      {
        path: 'trip/settings',
        component: TripSettingsPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: UserRoles.Driver },
      },
      {
        path: 'templates/:id',
        component: CreateTemplatePageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.TenantAdmin] },
      },
      {
        path: 'trips/edit/:id/:isAdmin',
        component: TripEventPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Driver, UserRoles.TenantAdmin] },
      },
      {
        path: 'orders/:id/edit/:eventid/:isAdmin',
        component: OrderEventPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Driver, UserRoles.TenantAdmin] },
      },
      {
        path: 'trip/:action',
        component: TripEventPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: UserRoles.Driver },
      },
      {
        path: 'orderinfo/:id',
        component: NavigationPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: UserRoles.Driver },
      },
      {
        path: 'parkedtripinfo/:id',
        component: TripNavigationPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: UserRoles.Driver },
      },
      {
        path: 'order/:id/:action',
        component: OrderEventPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: UserRoles.Driver },
      },
      {
        path: 'workdays/edit/:id/:isAdminPage',
        component: DayEventPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Driver, UserRoles.TenantAdmin] },
      },
      {
        // to add pause on day where's no pause
        path: 'workdays/add/:id/:isAdminPage/:workdayid',
        component: DayEventPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Driver, UserRoles.TenantAdmin] },
      },
      {
        path: 'vehicles/:id',
        component: VehicleCreatePageComponent,
        canActivate: [AuthGuard],
        data: { authorize: UserRoles.TenantAdmin },
      },
      {
        path: 'trailers/:id',
        component: TrailerCreatePageComponent,
        canActivate: [AuthGuard],
        data: { authorize: UserRoles.TenantAdmin },
      },
      {
        path: 'accessories/:id',
        component: AccessoryCreatePageComponent,
        canActivate: [AuthGuard],
        data: { authorize: UserRoles.TenantAdmin },
      },
      {
        path: 'cleanings/:id',
        component: CleaningCreatePageComponent,
        canActivate: [AuthGuard],
        data: { authorize: UserRoles.TenantAdmin },
      },
      {
        path: 'eproducts/:id',
        component: EproductEditPageComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.Economic,
        },
      },
      {
        path: 'products/:id',
        component: ProductCreatePageComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: !TenantFeatures.Economic,
        },
      },
      {
        path: 'companies/:id',
        component: CompanyCreatePageComponent,
        canActivate: [AuthGuard],
        data: { authorize: UserRoles.TenantAdmin },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class TruckRoutingModule {}
