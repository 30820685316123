<form [formGroup]="editForm" (ngSubmit)="onSubmit()" enctype="multipart/form-data">         
  <div class="form-group form-floating mb-3">      
      <div class="form-floating mb-4" [appValidateState]="editForm.get('title')">      
          <input id="title" formControlName="title" type="text" class="form-control" placeholder="Title" i18n-placeholder="@@title" autocomplete="off">      
          <label for="title" i18n="@@title">Title</label>
      </div>      
      <div>       
          <textarea id="editor" formControlName="editor" type="text" class="form-control" placeholder="Description" i18n-placeholder="Label description" autocomplete="off">     
          </textarea>        
      </div>      
  </div>      
  <div class="form-group form-floating h-100 pb-3 d-grid">
      <button type="submit" class="btn btn-primary shadow-sm" i18n="@@save">Save</button>        
  </div>      
</form>
