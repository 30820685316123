import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Vehicle } from 'src/app/state/vehicles.repository';


@Component({
  selector: 'app-vehicle-list',
  templateUrl: './vehicle-list.component.html',
  styleUrls: ['./vehicle-list.component.scss']
})
export class VehicleListComponent implements OnInit {
  @Input() vehicles: Vehicle[] | null = null;
  @Output() delete = new EventEmitter<string>();
  deleteConfirmation: Vehicle | null = null;
  
  constructor() { }

  ngOnInit() {
  }

}
