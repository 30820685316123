import { Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { filterNil } from '@ngneat/elf';
import { StatusState } from '@ngneat/elf-requests';
import { combineLatest, distinctUntilChanged, filter, map, Observable, skipWhile, switchMap, take, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UrlIdResolverService {

  constructor(
    private router: Router
  ) { }

  resolve<T>(
    routeParams: Observable<ParamMap>,
    loader: (id: string) => Observable<T>,
    selector: (id: string) => Observable<T| undefined>,
    status: (id: string) => Observable<StatusState>,
    idCallback?: (id: string) => void
  ) {
    const id$ = routeParams.pipe(
      map(param => param.get('id')!),
      distinctUntilChanged(),
      tap(id => idCallback ? idCallback(id) : null),
      filter(id => !!id && id !== 'new'),
      take(1)
    );
    id$.pipe(
      switchMap(id => loader(id))
    ).subscribe();
    return id$.pipe(
      switchMap(id => combineLatest([selector(id), status(id)])),
      skipWhile(([_, status]) => status.value === 'pending' || status.value === 'idle'),
      map(([model, _]) => model),
      tap(model => {
        if (!model) {
          this.router.navigate(['/not-found'], { skipLocationChange: true });
        }
      }),
      filterNil()      
    );
  }
}
