import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UrlIdResolverService } from 'src/app/modules/shared/services/url-id-resolver.service';
import { Vehicle, VehiclesRepository } from 'src/app/state/vehicles.repository';
import { VehiclesService } from 'src/app/state/vehicles.service';

@Component({
  selector: 'app-vehicle-create-page',
  templateUrl: './vehicle-create-page.component.html',
  styleUrls: ['./vehicle-create-page.component.scss']
})
export class VehicleCreatePageComponent implements OnInit {
  vehicle$: Observable<Vehicle> | null = null;
  id: string | undefined;
  submitErrors: string[] | null = null;

  constructor(
    private vehicleService: VehiclesService,
    private repo: VehiclesRepository,
    private urlId: UrlIdResolverService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

    ngOnInit(): void {
      this.vehicle$ = this.urlId.resolve(
        this.activatedRoute.paramMap,
        id => this.vehicleService.loadOne(id),
        id => this.repo.one(id),
        id => this.repo.statusOne(id),
        id => this.id = id
      );
    }

    createVehicle(vehicle: Partial<Vehicle>) {
      this.submitErrors = null;
      let updateResult: Observable<Vehicle> | undefined;
      if(this.id === 'new'){
        updateResult = this.vehicleService.add(vehicle);

      } else if(this.id){
        updateResult = this.vehicleService.update(this.id , vehicle);
      }
      if (updateResult) {
        updateResult.subscribe({
          complete: () => {
            this.router.navigate(['/vehicles']);
          },
          error: data => this.submitErrors = data
        });
      }
    }
}
