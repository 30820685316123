<div class="form-group form-floating">
  <ng-select
    [disabled]="isDisabled"
    [class.isDisabled]="isDisabled"
    class="form-control"
    [labelForId]="id"
    [multiple]="multiple"
    [(ngModel)]="activeValue"
    (ngModelChange)="activeValueChange.emit($event)"
    [style.max-width]="maxW"
    [style.width]="width"
  >
    <ng-option *ngFor="let option of test" [value]="option.value">
      <span [class.deleted]="option?.isDeleted">
        {{ option.label }}
        <span *ngIf="!option.isActive && isCompanies" style="color: red">
          (Inactive)</span
        ><br />
        <span class="small text-muted">{{ option.sublabel }}</span>
      </span>
    </ng-option>
  </ng-select>
  <label *ngIf="label" class="form-control-label" [attr.for]="id">{{
    label
  }}</label>
</div>
