import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { filterNil } from "@ngneat/elf";
import { concat, map, Observable, of, switchMap, tap } from "rxjs";
import { RETRY_HEADER } from "../modules/core/interceptors/retry.interceptor";
import { RequestsRepository } from "./requests.repository";

type HttpCallFunction = (
    url: string,
    body: any,
    options?: { headers?: { [name: string]: string | string[] } }
) => Observable<any>;

@Injectable({ providedIn: 'root' })
export class RequestsService {

    constructor(
        private http: HttpClient,
        private repo: RequestsRepository
    ) { }

    syncNext(userId: string): Observable<any> | null {
        const next = this.repo.getNextForUser(userId);
        if (!next) {
            return null;
        }
        return this.sync(
            next.id,
            next.method,
            this.getIdPathResolver(next.responseIdPath)
        );
    }

    syncAll(userId: string): Observable<any> {        
        const all = this.repo.getAllForUser(userId)
            .map(x => this.sync(
                x.id,
                x.method,
                this.getIdPathResolver(x.responseIdPath)
            ))
            .filter(x => !!x) as Observable<any>[];
        return concat(...all);
    }

    private getIdPathResolver(responseIdPath?: string): ((response: any) => string) | undefined {
        if (!responseIdPath) {
            return;
        }
        const path = responseIdPath.split('.');
        return (response: any) => {
            while(true) {
                const elem = path.shift();
                if (!elem) {
                    return response;
                }
                response = response[elem]
            }
        };
    }

    private sync(id: number, method: string, idPath?: (response: any) => string): Observable<any> | null {
        let httpCall: HttpCallFunction;
        switch (method) {
            case 'POST': httpCall = this.http.post; break;
            case 'PATCH': httpCall = this.http.patch; break;            
            default: return null;
        }
        return of(id).pipe(
            switchMap(id => of(this.repo.getOne(id))),
            filterNil(),
            map(request => {
                // A placeholder might have been user in an id field while offline.
                // We can just ignore it to avoid Guid parsing errors.
                if(request.body && request.body.id) {
                    delete request.body.id;
                }
                return request
            }),
            switchMap(request => httpCall.call(
                this.http,
                request.url,
                request.body || '',
                { headers: { [RETRY_HEADER]: 'yes' } }
            )),
            tap((resp) => {
                const respId = resp && (idPath ? idPath(resp) : resp.id);
                if (respId) {
                    this.repo.resolveId(id, respId);
                }
                this.repo.remove(id);
            }),
            this.repo.track(id),
            this.repo.track()
        );
    }
}
