<div class="row mt-1">
  <div class="col-12">
    <div class="card shadow-md mb-4">
      <ul class="list-group list-group-flush">
        <app-model-card *ngFor="let vehicle of vehicles"
          [title]="vehicle.numberplate"
          [subtitle]="vehicle.name"
          icon="bi-truck"
          [date]="vehicle.createdAt"
          [id]="vehicle.id"
          routerLinkBase="/vehicles"
          [isDeleteDisabled]="vehicle.isBeingUsed"
          (deleteClick)="deleteConfirmation = vehicle"></app-model-card>
      </ul>
    </div>
  </div>
</div>
<app-confirm-delete *ngIf="deleteConfirmation"
  [deleteCandidate]="'[' + deleteConfirmation.numberplate + '] ' + deleteConfirmation.name"
  (confirm)="delete.emit(deleteConfirmation.id);deleteConfirmation = null;"
  (cancel)="deleteConfirmation = null"></app-confirm-delete>
