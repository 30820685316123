<div class="dropdown">
  <div class="btn-group" role="group">
    <button class="btn btn-outline-primary" data-bs-toggle="dropdown">
      <span i18n="Sort by label">Sort by {{ sorting?.parameter?.label }}</span>
      <i class="ms-2 bi bi-caret-down-fill"></i>
    </button>
    <ul class="dropdown-menu">
      <li *ngFor="let option of sortingOptions" class="dropdown-item"
        (click)="ChangeParam(option)">{{ option.label }}</li>
    </ul>
    <button (click)="ChangeDirection()" class="btn btn-outline-primary">
      <i class="bi" 
        [class.bi-sort-down]="sorting?.direction === 'desc'"
        [class.bi-sort-up]="sorting?.direction === 'asc'"></i>
    </button>
  </div>
</div>
