import { Component, OnInit } from '@angular/core';
import { AccessoriesRepository, accessorysSortOptions } from 'src/app/state/accessories.repository';
import { AccessoriesService } from 'src/app/state/accessory.service';
import { AuthRepository } from 'src/app/state/auth.repository';

@Component({
  selector: 'app-accessory-page',
  templateUrl: './accessory-page.component.html',
  styleUrls: ['./accessory-page.component.scss']
})
export class AccessoryPageComponent implements OnInit {
  sortOptions = accessorysSortOptions;
  exportFactory = () => this.service.export();
  importFactory = (file: File) => this.service.import(file);

  constructor(
    public service: AccessoriesService,
    public repo: AccessoriesRepository,
    public auth: AuthRepository
  ) { }

  ngOnInit(): void {
    this.service.reloadPage().subscribe();
  }

}
