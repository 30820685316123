import { Injectable } from "@angular/core";
import { BaseService } from "./abstract/base.service";
import { HttpClient } from "@angular/common/http";
import { PriceType, PriceTypeRepository } from "./pricetypes.repository";
import { Observable, tap } from "rxjs";

const API = '/api/products/pricetypes';

@Injectable({
  providedIn: 'root'
})
export class PriceTypesService extends BaseService<PriceType> {

  constructor(
    http: HttpClient,
    repo: PriceTypeRepository
  ) {
    super(API, http, repo);
  } 

  loadTypes() {
    return this.http
      .get<PriceType[]>(API)
      .pipe(
        tap(x => this.repo.set(x))        
      );
  }

}