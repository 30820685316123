<div
  class="modal fade"
  bsModal
  [config]="{ show: true }"
  id="deleteModal"
  tabindex="-1"
  #deleteModal
  aria-hidden="true"
  (onHidden)="cancel.emit()"
>
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content bg-lights border-0">
      <div class="modal-header">
        <h6
          class="modal-title"
          id="deleteModalLabel"
          i18n="Title for confirmation dialog"
        >
          Confirm your action
        </h6>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          i18n-aria-label="Close label @@close"
          (click)="cancel.emit()"
        ></button>
      </div>
      <div class="modal-body">
        <div class="form-floating mb-3">
          <p
            class="mb-1"
            *ngIf="!deleteText"
            i18n="Confirm delete message start"
          >
            You are about to delete the following entry:
          </p>
          <p class="mb-1" *ngIf="deleteText">{{ deleteText }}</p>
          <p class="mb-1">
            <strong [innerHTML]="addNewLines(deleteCandidate)"></strong>
          </p>
          <p
            class="mb-0"
            *ngIf="!deleteMessage"
            i18n="Confirm delete message end"
          >
            Please confirm your action by clicking Delete.
          </p>
          <p class="mb-0" *ngIf="deleteMessage">{{ deleteMessage }}</p>
        </div>
        <div *ngIf="isReccurence">
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              #isRecurred
              checked
            />
            <label
              i18n="deleteonlyonetrip"
              class="form-check-label"
              for="flexRadioDefault1"
            >
              Delete only this trip
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
            />
            <label
              i18n="deletealltrips"
              class="form-check-label"
              for="flexRadioDefault2"
            >
              Delete all trips in group
            </label>
          </div>
        </div>
        <div *ngIf="isMultipleDrivers">
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault3"
              [checked]="isMultipleDrivers"
              #isForOneDriver
            />
            <label class="form-check-label" for="flexRadioDefault3">
              Slet kun for denne chauffør
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault4"
            />
            <label class="form-check-label" for="flexRadioDefault4">
              Slet for alle chaufførerne
            </label>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button
          class="btn btn-danger me-2"
          *ngIf="!deleteButton"
          (click)="confirmCall()"
          i18n="Delete button"
        >
          Delete
        </button>
        <button
          class="btn btn-danger me-2"
          *ngIf="deleteButton"
          (click)="confirmCall()"
        >
          {{ deleteButton }}
        </button>
        <button
          class="btn btn-secondary"
          (click)="cancel.emit()"
          i18n="@@cancel"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
