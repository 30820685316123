import { Injectable } from "@angular/core";
import { createState, createStore, Store } from "@ngneat/elf";
import { deleteAllEntities, selectAllEntities, selectEntity, upsertEntities, withEntities } from "@ngneat/elf-entities";
import { createRequestsCacheOperator, createRequestsStatusOperator, selectRequestStatus, updateRequestCache, updateRequestsStatus, withRequestsCache, withRequestsStatus } from "@ngneat/elf-requests";
import { map, MonoTypeOperatorFunction } from "rxjs";

export interface Column {
    id: string;
    name: string;
    danskName: string;
    orderNumber: number;
    isVisible: boolean;
    isStandartColumn: boolean;
    isUnloadColumn: boolean;
    isLoadColumn: boolean;
    tenantId: string;
    templateId: string;
}

export class SimpleColumn {
    name?: string;
    danskName?: string;
    isVisible?: boolean;
}


const { state, config } = createState(
    
    withEntities<Column>(),
    withRequestsCache(), 
    withRequestsStatus()
  );
  const store = new Store({ name: 'columns', state, config });
  export const trackTenantRequestsStatus = createRequestsStatusOperator(store);
  export const skipWhileTenantsCached = createRequestsCacheOperator(store);
  
  @Injectable({ providedIn: 'root' })
  export class ColumnsRepository {
name = store.name;

isLoading$ = store.pipe(
    selectRequestStatus(this.name),
    map(x => x.value === 'pending')
);

isAdding$ = store.pipe(
    selectRequestStatus(`${store.name}_add`),
    map(x => x.value === 'pending')
);


all$ = store.pipe(selectAllEntities());


set(models: Column[]): void {
    store.update(
        updateRequestCache(store.name),
        upsertEntities(models),
        updateRequestsStatus([store.name], 'success')
    );
}

clear(): void {
    store.update(
        deleteAllEntities()
    );
}






}