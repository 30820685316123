<!-- Tenant list items  -->

<div class="row mt-1">
  <div class="col-12">
    <div class="card shadow-md mb-4">
      <ul class="list-group list-group-flush">
        <li class="list-group-item" *ngFor="let tenant of tenants">
          <div class="row">
            <div class="col-12 col-md">
              <div class="row mb-3 mb-md-0">
                <div class="col-auto">     
                  <app-entity-avatar [img]="tenant.imagePath" [alt]="tenant.name"
                    icon="bi-building"></app-entity-avatar>      
                </div>
                <div class="col">
                  <p>{{tenant.name}}<br><small class="text-muted" i18n="Users counter">{tenant.countOfUsers, plural, =1 {1 user} other {{{tenant.countOfUsers}} users}}</small></p>
                </div>
                <div class="col">                 
                 <span *ngIf="isOrderInTenant(tenant)" class="badge yellow text-muted" i18n="OrderEnable">Order active</span>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-5">
              <div class="row">
                <div class="col d-flex align-items-center text-md-end">
                  <small class="text-muted mr-2 w-100" i18n="Label created date">Created {{tenant.createdAt | date:'longDate'}}</small>
                </div>
                <div class="col-auto">
                  <div class="vr h-100"></div>
                </div>
                <div *ngIf="!isDeleteDisabled" class="col-auto pl-0 d-flex align-items-center">
                  <button type="button" class="btn btn-md btn-link w-100 text-danger" data-bs-target="#deletetenantmodal"
                      (click)="deleteConfirmation = tenant" title="Delete" i18n-title="Delete button">
                    <i class="bi bi-trash"></i>
                  </button>
                </div>
                <div class="col-auto pl-0 d-flex align-items-center">
                  <a class="btn btn-md btn-link w-100" [routerLink]="['/tenants', tenant.id]">
                    <i class="bi bi-pencil me-1"></i> <ng-container i18n="Button edit @@edit">Edit</ng-container>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
<app-confirm-delete id="deletetenantmodal" *ngIf="deleteConfirmation"
  [deleteCandidate]="deleteConfirmation.name"
  (confirm)="handleDeleteClick(deleteConfirmation);deleteConfirmation = null;"
  (cancel)="deleteConfirmation = null"></app-confirm-delete>
