import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  IgxTimePickerComponent,
  PickerInteractionMode,
} from 'igniteui-angular';
import { BaseService } from 'src/app/base-service';

@Component({
  selector: 'app-timepicker-dropdown',
  styleUrls: ['./timepicker-dropdown.component.scss'],
  templateUrl: './timepicker-dropdown.component.html',
})
export class TimepickerDropdownComponent {
  @Input() title: string | null = null;
  @Input() date: string | Date = new Date();
  @Input() dateStr: string | null = null;
  @Input() disabled: boolean = false;
  @Output() timeOutputEvent = new EventEmitter<string>();
  public mode: PickerInteractionMode = PickerInteractionMode.DropDown;
  public format = 'HH:mm';

  constructor(public element: ElementRef, private baseService: BaseService) {}

  sub: any;

  sendNewValue(val: any) {
    if (typeof val !== 'string') {
      let hours = val.getHours().toString().padStart(2, '0');
      let minutes = val.getMinutes().toString().padStart(2, '0');
      var a = `${hours}:${minutes}`;
      this.date = a;
    } else {
      this.date = val;
    }

    this.timeOutputEvent.emit(this.date.toString());
    if (this.dateStr === 'Start') {
      this.baseService.sendStartTimeValue(this.date);
    }
    if (this.dateStr === 'End') {
      this.baseService.sendEndTimeValue(this.date);
    }
  }

  public selectNow(timePicker: IgxTimePickerComponent) {
    var strhours, strminutes;
    var curdate = new Date();
    var hours = curdate.getHours();
    var minutes = curdate.getMinutes();
    if (hours < 10) {
      strhours = `0${hours}`;
    } else {
      strhours = hours.toString();
    }
    if (minutes < 10) {
      strminutes = `0${minutes}`;
    } else {
      strminutes = minutes.toString();
    }
    timePicker.value = `${strhours}:${strminutes}`;
    timePicker.close();
  }

  ngOnDestroy() {
    //this.sub.unsubscribe();
  }
}
