import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './abstract/base.service';
import { AccessoriesRepository, Accessory } from "./accessories.repository";

const API = '/api/accessories';

@Injectable({
  providedIn: 'root'
})
export class AccessoriesService extends BaseService<Accessory> {

  constructor(
    http: HttpClient,
    repo: AccessoriesRepository
  ) {
    super(API, http, repo);
  }

}