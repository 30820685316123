import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleColumn } from 'src/app/state/columns.repository';
import { TripEventLogDto } from 'src/app/state/models/trip/TripEventLogDto';
import { TripLogDto } from 'src/app/state/models/trip/TripLogDto';
import { TripPhotoUrlDto } from 'src/app/state/models/trip/TripPhotoUrlDto';
import { TripEventsService } from 'src/app/state/tripevents.service';
import { TripsService } from 'src/app/state/trips.service';

@Component({
  selector: 'app-sort-table-modal',
  templateUrl: './sort-table-modal.component.html',
  styleUrls: ['./sort-table-modal.component.scss'],
})
export class SortTableModalComponent implements OnInit {
  @Input() tripLog: TripLogDto | undefined = undefined;
  @Input() simpleLoadUnloadColumns: SimpleColumn[] | null = null;
  @Input() isAdminPage: boolean | null = null;
  @Input() muted: boolean = true;
  @Input() isEconomic: boolean = false;
  tripEventLogs: TripEventLogDto[] | null = null;

  @Output() cancel = new EventEmitter<void>();

  constructor(
    private tripEventService: TripEventsService,
    private tripService: TripsService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.loadTripEventLogs();
  }

  loadTripEventLogs() {
    if (this.tripLog) {
      this.tripEventService
        .loadLoadUnloadTripEvents(this.tripLog.id)
        .subscribe((x) => {
          this.tripEventLogs = x;
          this.contectCheckCount = 0;
          this.maxContectCheckCount = x.length;
        });
    }
  }

  addImage($event: any, tripEventId: string) {
    if (this.tripLog) {
      let files = $event.target.files;
      if (tripEventId) {
        this.tripService
          .addTripEventPhoto(this.tripLog.id, tripEventId, files)
          .subscribe({
            complete: () => {
              this.loadTripEventLogs();
            },
          });
      }
    }
  }

  selectedTripEventLogDto: TripEventLogDto | undefined = undefined;

  getPhotos(tripeventLog: TripEventLogDto) {
    this.selectedTripEventLogDto = tripeventLog;
  }

  cancelEvent() {
    this.selectedTripEventLogDto = undefined;
  }

  navigateTo(id: string) {
    this.tripEventService.loadOneWithoutTrack(id).subscribe((x) => {
      if (x) {
        this.router.navigate([`/trips/edit/${x.id}/${this.isAdminPage}`]);
      }
    });
  }

  movePhotos(photo: TripPhotoUrlDto) {
    this.tripService.movePhotoEvent(photo).subscribe({
      complete: () => {
        this.loadTripEventLogs();
      },
    });
  }

  convertMS(duration: number): string {
    let d, h, m, s;
    s = Math.floor(duration / 1000);
    m = Math.floor(s / 60);
    s = s % 60;
    h = Math.floor(m / 60);
    m = m % 60;
    d = Math.floor(h / 24);
    h = h % 24;
    h += d * 24;
    return `${h}h. ${m}m. ${s}s. `;
  }
  contectCheckCount: number = 0;
  maxContectCheckCount: number = 0;
  ngAfterContentChecked() {
    if (this.contectCheckCount < this.maxContectCheckCount) {
      this.tripEventLogs?.forEach((x, y) => {
        let row = document.getElementById(`orderingunload${y}m`);

        this.simpleLoadUnloadColumns?.forEach((a, b) => {
          let sortedColumnName = `${a.name}${y}m`;
          let unsortedColumnName = row?.children.item(b)?.id;
          if (row) {
            let sortedColumn = row.querySelector(`#${sortedColumnName}`);
            let unsortedColumn = row.querySelector(`#${unsortedColumnName}`);
            if (sortedColumn) {
              row.insertBefore(sortedColumn, unsortedColumn);
            }
          }
          if (!a.isVisible) {
            let toRemoveColumn = row?.querySelector(`#${sortedColumnName}`);
            if (row && toRemoveColumn) {
              row.removeChild(toRemoveColumn);
            }
          }
        });
      });
      this.contectCheckCount += 1;
    }
  }
}
