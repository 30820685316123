<div class="row">
    <div class="col-auto pr-0">
        <button type="button" class="btn btn-link back-btn text-white" (click)="backnav()">
            <i class="bi bi-arrow-left"></i>
        </button>
    </div>
    <div class="col text-center align-self-center px-0">
        <app-logo></app-logo>
    </div>
    <div class="col-auto align-self-center">
        <a routerLink="/home" class="btn btn-link text-white">
            <i class="bi bi-house"></i>
        </a>
    </div>
</div>