<h2 class="mb-4" *ngIf="id === 'new'" i18n="Create template label">
  Create template
</h2>
<h2 class="mb-4" *ngIf="id !== 'new'" i18n="Edit template label">
  Edit template
</h2>
<form *ngIf="form" [formGroup]="form">
  <div class="row mb-3">
    <div class="col-lg-6 col-md-6 col-sm-12">
      <div
        [appValidateState]="form.get('name')"
        class="form-group form-floating mb-3"
      >
        <input
          formControlName="name"
          type="text"
          class="form-control"
          placeholder="Name"
          i18n-placeholder="@@name"
        />
        <label for="name" i18n="@@name">Name</label>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12">
      <div class="form-group form-floating mb-3">
        <input
          formControlName="description"
          type="text"
          class="form-control"
          placeholder="Description"
          i18n-placeholder="@@description"
        />
        <label class="ms-2" for="description" i18n="@@description"
          >Description</label
        >
      </div>
    </div>
  </div>
  <div class="mb-4 d-flex align-content-center">
    <input
      class="mt-1"
      formControlName="showExtraData"
      style="width: 20px; height: 20px"
      type="checkbox"
    />
    <span class="ms-2 mt-1" i18n="Show extra data">Show extra data</span>
  </div>
</form>
<app-spinner *ngIf="!columns"></app-spinner>
<div class="mb-4">
  <app-columns-list
    [columns]="columns"
    [isEconomic]="true"
    (colEmiter)="columns = $event"
  ></app-columns-list>
</div>
<div class="row mb-4">
  <div class="col-md-2 centered" (click)="submit()">
    <div class="card btn btn-primary shadow-md">
      <div class="text-center">
        <h5 i18n="Save button @@colSave">Save</h5>
      </div>
    </div>
  </div>
  <div *ngIf="resetable" class="col-md-2 centered" (click)="resetOrder()">
    <div class="card btn btn-warning shadow-md">
      <div class="text-center">
        <h5 i18n="Reset button @@colReset">Reset</h5>
      </div>
    </div>
  </div>
</div>
<app-error-alert
  title="Could not submit the template"
  i18n-title="Could not sumbit product template"
  [errors]="submitErrors"
></app-error-alert>

<div class="mt-4">
  <div
    [class.invisible]="!item.isVisible"
    class="me-2"
    *ngFor="let item of columns"
  >
    [{{ replaceWithOnly(item.danskName) }}];
  </div>
</div>
