import { Injectable } from '@angular/core';

export interface Coordinates {
  longitude: number;
  latitude: number;
}

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor() { } 

   async getPosition(): Promise<Coordinates> {
    return new Promise<Coordinates>((resolve, reject) => {  
      navigator.geolocation.getCurrentPosition(position => resolve({
        latitude: position.coords.latitude, 
        longitude: position.coords.longitude
      }), reject);
    });
  } 
}
