import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { LocationService } from 'src/app/modules/shared/services/location.service';
import { AdressChangeDto } from 'src/app/state/models/shared/AddressChangeDto';

@Component({
  selector: 'app-office-address-form',
  templateUrl: './office-address-form.component.html',
  styleUrls: ['./office-address-form.component.scss'],
})
export class OfficeAddressFormComponent {
  testsLocation = 'localhost:9876';
  @ViewChild('placePickRef') placePickRef: GooglePlaceDirective | null = null;
  @ViewChild('placeDelRef') placeDelRef: GooglePlaceDirective | null = null;
  myOptions = new Options({
    componentRestrictions: {
      country: 'Dk',
    },
  });

  form?: UntypedFormGroup;

  @Input() pickUpAddress: string | undefined = undefined;
  @Input() deliveryAddress: string | undefined = undefined;

  @Output() cancel = new EventEmitter<void>();
  @Output() confirm = new EventEmitter<AdressChangeDto>();

  constructor(
    private locationService: LocationService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      pickUpAddress: [this.pickUpAddress],
      deliveryAddress: [this.deliveryAddress],
    });
  }
  redirectToAddressOnMaps(destination: string) {
    if (!destination) {
      return;
    }
    var coordinates = this.locationService.getPosition();
    coordinates.then((x) => {
      window.open(
        `https://www.google.com/maps/dir/?api=1&origin=${x.latitude.valueOf()},${x.longitude.valueOf()}` +
          '&destination=' +
          destination +
          '&travelmode=driving',
        '_blank'
      );
    });
  }

  updateValue(control: string, value: any) {
    const controlObject = this.form?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  handleAddressChange($event: any, control: string) {
    let formattedaddress = $event.formatted_address;
    this.updateValue(control, formattedaddress);
  }

  locationCheck() {
    if (window.location.host !== this.testsLocation) {
      return true;
    }
    return false;
  }

  save() {
    this.confirm.emit({
      pickUpAddress: this.form?.value.pickUpAddress,
      deliveryAddress: this.form?.value.deliveryAddress,
    });
  }
}
