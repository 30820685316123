<div
  *ngIf="updateInProgress"
  class="w-100 d-flex justify-content-center align-item-center"
  style="
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9999;
    top: 0;
    left: 0;
  "
>
  <div
    class="spinner-border m-auto text-light"
    style="width: 5em; height: 5em"
    role="status"
  >
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
<div class="row mb-4">
  <div class="col">
    <h2 i18n="Title for the Economic">Economic</h2>
  </div>
</div>
<div class="row mb-4">
  <div class="col">
    <app-economic-table1
      *ngIf="checkIfDataLoaded(); else loadingContent"
      [products]="productSelectOptions"
      [clients]="clientSelectOptions"
      [drivers]="driverSelectOptions"
      [tableColumns]="tableColumns"
      [priceTypes]="priceTypes"
      [loadUnloadColumns]="loadUnloadColumns"
      [tripLogs]="tripLogs"
      [filterOptions]="filterOptions"
      (movePhoto)="movePhoto($event)"
      (filterOptionsChange)="requestLoadData($event)"
      (tenantNoteChange)="updateTenantNote($event)"
      (clientsEmmiter)="updateTripEventClients($event)"
      (economicEmmiter)="updateTripProducts($event)"
    ></app-economic-table1>

    <ng-template #loadingContent>
      <app-spinner [showWithText]="true"></app-spinner>
    </ng-template>
  </div>
</div>
