import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { EProductsRepository, eproductsSortOptions } from 'src/app/state/eproducts.repository';
import { EProductsService } from 'src/app/state/eproducts.service';

@Component({
  selector: 'app-eproducts-page',
  templateUrl: './eproducts-page.component.html',
  styleUrls: ['./eproducts-page.component.scss']
})
export class EproductsPageComponent implements OnInit {
  sortOptions = eproductsSortOptions;
  search = "";
  isSync = false;
  isSyncError = false;
  constructor(public eservice: EProductsService, public erepo: EProductsRepository, private http: HttpClient) { }

  ngOnInit(): void {
    this.eservice.reloadPage().subscribe();
  }

  loadBySearch($event: string) {    
    this.eservice.reloadPage(10, $event).subscribe(); 
  }

  sync() {
    if (this.isSync) {
      return false;
    }
    this.isSyncError = false;
    this.isSync = true;
    this.http.post('/api/sync/productsimport', null)
      .subscribe({
        complete: () => window.location.reload(),
        error: () => {
          this.isSync = false;
          this.isSyncError = true;
        }
      });
    return false;
  }
}
