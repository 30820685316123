<h2 class="mb-4" *ngIf="id === 'new'" i18n="Create accessory label">Create accessory</h2>
<h2 class="mb-4" *ngIf="id !== 'new'" i18n="Edit accessory label">Edit accessory</h2>
<div *ngIf="(id === 'new') || (accessory$ | async)" class="row mb-4">
    <div class="col-12">
        <app-accessory-form [editedAccessory]="accessory$ | async" 
          (accessorySubmit)="createAccessory($event)"></app-accessory-form>  
        <app-error-alert title="Could not submit the accessory info" i18n-title="Could not sumbit accessory message"
          [errors]="submitErrors"></app-error-alert>      
    </div>
</div>
