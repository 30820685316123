import { Injectable } from '@angular/core';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';
import { PriceType } from './pricetypes.repository';
import { Product } from './products.repository';

export interface LabelValue {
  label: string;
  tripEventId: string;
  eventType: string;
  value: number;
  eProductId: string;
  diffKM: string;
  diffKg: string;
  duration: string;
  onlyTakeValueFromTotal?: boolean;
}
export const eproductsSortOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'name' },
  {
    label: $localize`:Sort label Description:Description`,
    property: 'description',
  },
  { label: $localize`:Sort label Date:Date`, property: 'createdAt' },
  { label: $localize`:Sort label Date:Type`, property: 'priceTypeName' },
];

@Injectable({ providedIn: 'root' })
export class EProductsRepository extends BaseRepository<Product> {
  constructor() {
    super('eproducts', eproductsSortOptions);
  }
}
