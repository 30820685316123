import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-role-pin',
  templateUrl: './role-pin.component.html',
  styleUrls: ['./role-pin.component.scss']
})
export class RolePinComponent implements OnInit {
  badges: string[] = [];

  @Input() set roles(value: string[]) {
    this.badges = value;
    setTimeout(() => this.tooltips());
  }

  constructor() { }

  ngOnInit(): void {
  }

  tooltips(): void {
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });
  }

}
