<div
  class="modal fade"
  bsModal
  [config]="{ show: true }"
  id="singlegroupModal"
  tabindex="-1"
  #singlegroupModal
  aria-hidden="true"
  (onHidden)="obliterate()"
  *ngIf="tevents"
>
  <div
    style="width: 90dvw !important; max-width: 90dvw !important"
    class="modal-dialog modal-dialog-centered modal-xl"
  >
    <div class="modal-content bg-lights border-0">
      <div class="modal-header">
        <h6 class="modal-title" id="singlegroupModal">Produktgruppe</h6>
        <button
          type="button"
          class="btn-close"
          (click)="obliterate()"
          aria-label="Close"
          i18n-aria-label="Close label @@close"
        ></button>
      </div>
      <form
        [formGroup]="productGroupForm"
        class="modal-body py-0"
        (ngSubmit)="save()"
        *ngIf="productGroupForm && additions"
      >
        <div class="form-floating mb-3">
          <div
            style="overflow-x: auto; max-height: 80dvh; overflow-y: scroll"
            class="table-responsive custom"
          >
            <table
              [class.isDisabled]="theTrip?.exportedAt"
              style="margin-top: 15px"
              class="table table-hover table-bordered"
            >
              <thead>
                <tr>
                  <th
                    style="width: 70px"
                    class="text-center align-middle hoverable"
                  >
                    <strong>Type</strong>
                  </th>
                  <th
                    style="width: 90px"
                    class="text-center align-middle hoverable"
                  >
                    <strong>KM {{ "  " }} / {{ "  " }} DiffKM</strong>
                  </th>
                  <th
                    style="width: 130px"
                    class="text-center align-middle hoverable"
                    i18n="Label for time @@Time"
                  >
                    <strong>Time</strong>
                  </th>
                  <th
                    style="width: 70px !important"
                    class="text-center align-middle hoverable"
                    i18n="Label for time @@Kg"
                  >
                    <strong>Kg</strong>
                  </th>
                  <th
                    style="width: 100px"
                    class="text-center align-middle hoverable"
                    i18n="Label for used Product @@UsedProduct"
                  >
                    <strong>Used Product</strong>
                  </th>
                  <th
                    style="width: 140px"
                    class="text-center align-middle hoverable"
                    i18n="Label for used Client @@Client"
                  >
                    <strong>Client</strong>
                  </th>
                  <th
                    style="
                      width: 200px;
                      min-width: 10px !important;
                      max-width: 200px !important;
                    "
                    class="text-center align-middle hoverable"
                    i18n="Label for group @@EProduct"
                  >
                    <strong>Product</strong>
                  </th>
                  <th
                    style="width: 150px"
                    class="text-center align-middle hoverable"
                    i18n="Label for valuetype @@ValueType"
                  >
                    <strong>Value</strong>
                  </th>
                </tr>
              </thead>
              <tbody formArrayName="eProductInfos">
                <tr
                  [style.height]="
                    tripEvent.type === 'TripStart'
                      ? '40px'
                      : (tevents.length === 2 &&
                        filterOptionsByEvent(tripEvent).length > 1
                          ? 300
                          : 150) +
                        getAdd(tripEvent.tripEventId!).length * 10 +
                        getFast(tripEvent.tripEventId!).length * 60 +
                        'px'
                  "
                  *ngFor="let tripEvent of tevents; let i = index"
                >
                  <td class="text-center">
                    {{ getDanishEventType(tripEvent.type!) }}
                  </td>
                  <!-- FIRST LINE -->
                  <td
                    class="text-center no-borders"
                    style="min-width: 110px; max-width: 125px"
                  >
                    <ng-container>
                      <div class="row">
                        <div class="col-12" *ngIf="tripEvent.type !== 'Total'">
                          {{ getDiffKmHeader(tevents, tripEvent) }}
                        </div>
                      </div>
                      <div
                        *ngIf="tripEvent.type !== 'TripStart'"
                        class="padding-div"
                      ></div>
                    </ng-container>
                    <div *ngIf="tripEvent.type !== 'TripStart'">
                      <div
                        *ngFor="let item of additions; let i = index"
                        class="text-center"
                      >
                        <div
                          class="reverse"
                          *ngIf="
                            item &&
                            (item.label.includes('Fast') ||
                              item.label.includes('Stk')) &&
                            item.tripEventId === tripEvent.tripEventId
                          "
                          class="w-100 form-group form-floating pe-3 mb-3"
                        >
                          <input
                            class="form-control reverse"
                            placeholder="Price"
                            i18n-placeholder="Price placeholder @@priceV"
                          />
                          <label
                            class="reverse"
                            for="price"
                            i18n="@@priceVType"
                            >{{ item.label + " " + "Værdi" }}</label
                          >
                        </div>
                      </div>
                    </div>
                    <tr
                      class="bottom"
                      style="height: 10%; width: 100%"
                      *ngIf="
                        tripEvent.type !== 'TripStart' &&
                        tripEvent.type !== 'Total'
                      "
                    >
                      <div
                        *ngFor="
                          let item of getAdd(tripEvent.tripEventId!);
                          let i = index
                        "
                      >
                        <div class="bg-th-color{{ i + 1 }}">
                          <span
                            *ngIf="
                              !(
                                item.label.includes('Fast') ||
                                item.label.includes('Stk')
                              ) && item.tripEventId === tripEvent.tripEventId
                            "
                            [class.reverse]="
                              item.label.includes('Minutter') ||
                              item.label.includes('Kg')
                            "
                            class="notFixed"
                            >{{ tripEvent.diffKm || 0 }}</span
                          >
                        </div>
                      </div>
                    </tr>
                  </td>
                  <!-- SECOND LINE -->
                  <td
                    class="text-center no-borders"
                    style="min-width: 110px; max-width: 125px"
                  >
                    <ng-container>
                      <div class="row">
                        <div class="col-12" *ngIf="tripEvent.type !== 'Total'">
                          {{ tripEvent.eventTime }}
                        </div>
                      </div>
                      <div
                        *ngIf="tripEvent.type !== 'TripStart'"
                        class="padding-div"
                      ></div>
                    </ng-container>
                    <div *ngIf="tripEvent.type !== 'TripStart'">
                      <div
                        *ngFor="let item of additions; let i = index"
                        class="text-center"
                      >
                        <div
                          class="reverse"
                          *ngIf="
                            item &&
                            (item.label.includes('Fast') ||
                              item.label.includes('Stk')) &&
                            item.tripEventId === tripEvent.tripEventId
                          "
                          class="w-100 form-group form-floating pe-3 mb-3"
                        >
                          <input
                            class="form-control reverse"
                            placeholder="Price"
                            i18n-placeholder="Price placeholder @@priceV"
                          />
                          <label
                            class="reverse"
                            for="price"
                            i18n="@@priceVType"
                            >{{ item.label + " " + "Værdi" }}</label
                          >
                        </div>
                      </div>
                    </div>
                    <tr
                      class="bottom"
                      style="height: 10%; width: 100%"
                      *ngIf="
                        tripEvent.type !== 'TripStart' &&
                        tripEvent.type !== 'Total'
                      "
                    >
                      <div
                        *ngFor="
                          let item of getAdd(tripEvent.tripEventId!);
                          let i = index
                        "
                      >
                        <div class="bg-th-color{{ i + 1 }}">
                          <span
                            *ngIf="
                              !(
                                item.label.includes('Fast') ||
                                item.label.includes('Stk')
                              ) && item.tripEventId === tripEvent.tripEventId
                            "
                            [class.reverse]="
                              item.label.includes('Km') ||
                              item.label.includes('Navn') ||
                              item.label.includes('Kg')
                            "
                            class="notFixed"
                            >{{
                              timeStringJustify(
                                tripEvent,
                                tevents,
                                tripEvent.duration
                              )
                            }}
                          </span>
                        </div>
                      </div>
                    </tr>
                  </td>
                  <!-- THIRD LINE-->
                  <td
                    class="text-center no-borders"
                    style="min-width: 30px; max-width: 90px"
                  >
                    <ng-container>
                      <div class="row">
                        <div class="col-12" *ngIf="tripEvent.type !== 'Total'">
                          {{ tripEvent.weight }}
                        </div>
                      </div>
                      <div
                        *ngIf="tripEvent.type !== 'TripStart'"
                        class="padding-div"
                      ></div>
                    </ng-container>
                    <div *ngIf="tripEvent.type !== 'TripStart'">
                      <div
                        *ngFor="let item of additions; let a = index"
                        class="text-center"
                      >
                        <div
                          class="reverse"
                          *ngIf="
                            item &&
                            (item.label.includes('Fast') ||
                              item.label.includes('Stk')) &&
                            item.tripEventId === tripEvent.tripEventId
                          "
                          class="w-100 form-group form-floating pe-3 mb-3"
                        >
                          <input
                            class="form-control reverse"
                            placeholder="Price"
                            i18n-placeholder="Price placeholder @@priceV"
                          />
                          <label
                            class="reverse"
                            for="price"
                            i18n="@@priceVType"
                            >{{ item.label + " " + "Værdi" }}</label
                          >
                        </div>
                      </div>
                    </div>
                    <tr
                      class="bottom"
                      style="height: 10%; width: 100%"
                      *ngIf="
                        tripEvent.type !== 'TripStart' &&
                        tripEvent.type !== 'Total'
                      "
                    >
                      <div
                        *ngFor="
                          let item of getAdd(tripEvent.tripEventId!);
                          let i = index
                        "
                      >
                        <div class="bg-th-color{{ i + 1 }}">
                          <span
                            *ngIf="
                              !(
                                item.label.includes('Fast') ||
                                item.label.includes('Stk')
                              ) && item.tripEventId === tripEvent.tripEventId
                            "
                            [class.reverse]="
                              item.label.includes('Km') ||
                              item.label.includes('Navn') ||
                              item.label.includes('Minutter')
                            "
                            class="notFixed"
                            style="white-space: pre"
                            >{{ tripEvent.weight }}</span
                          >
                        </div>
                      </div>
                    </tr>
                  </td>
                  <td class="text-center no-borders" style="max-width: 220px">
                    <ng-container *ngIf="tripEvent.type !== 'TripStart'">
                      <div class="row">
                        <div
                          *ngIf="tripEvent.productName"
                          class="col-12 ms-1 me-2"
                        >
                          {{ tripEvent.productName }}
                        </div>
                      </div>
                      <div *ngIf="!tripEvent.productName" class="reverse">
                        Extra row for
                      </div>
                    </ng-container>
                    <div
                      class="bottom"
                      style="height: 10%; width: 100%"
                      *ngIf="
                        tripEvent.type !== 'TripStart' &&
                        tripEvent.type !== 'Total'
                      "
                    >
                      <div
                        class="inside"
                        *ngFor="
                          let add of getAdd(tripEvent.tripEventId!);
                          let b = index
                        "
                      >
                        <div
                          *ngIf="
                            add &&
                            !(
                              add.label.includes('Fast') ||
                              add.label.includes('Stk')
                            ) &&
                            add.tripEventId === tripEvent.tripEventId
                          "
                          class="bg-th-color{{ b + 1 }}"
                        >
                          <div class="reverse h23">Extra row for</div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-center no-borders" style="min-width: 140px">
                    <ng-container *ngIf="tripEvent.type !== 'TripStart'">
                      <div class="row">
                        <div *ngIf="tripEvent.clientName" class="col-12 me-2">
                          {{ tripEvent.clientName }}
                        </div>
                      </div>
                      <div class="reverse" *ngIf="!tripEvent.clientName">
                        Extra row for
                      </div>
                    </ng-container>

                    <div
                      class="bottom"
                      style="height: 10%; width: 100%"
                      *ngIf="
                        tripEvent.type !== 'TripStart' &&
                        tripEvent.type !== 'Total'
                      "
                    >
                      <div
                        class="inside"
                        *ngFor="
                          let add of getAdd(tripEvent.tripEventId!);
                          let b = index
                        "
                      >
                        <div
                          *ngIf="
                            add &&
                            !(
                              add.label.includes('Fast') ||
                              add.label.includes('Stk')
                            ) &&
                            add.tripEventId === tripEvent.tripEventId
                          "
                          class="bg-th-color{{ b + 1 }}"
                        >
                          <div class="reverse">Extra row for</div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <!-- Fourth LINE-->
                  <td class="no-borders">
                    <div
                      class="form-group form-floating"
                      *ngIf="eProductOptions && tripEvent.type !== 'TripStart'"
                    >
                      <app-datalist-select
                        class="app-datalist-select-without-label"
                        [isDisabled]="theTrip?.exportedAt !== ''"
                        [optionsDeleted]="eProductOptionsDeleted"
                        [options]="filterOptionsByEvent(tripEvent)"
                        [multiple]="true"
                        [activeValue]="
                          productGroupForm.value.eProductInfos[i].eproductIds
                        "
                        (activeValueChange)="
                          updateproductGroupFormValue('eproductIds', $event, i)
                        "
                      ></app-datalist-select>
                    </div>
                    <div
                      class="bottom"
                      style="height: 10%; width: 100%"
                      *ngIf="
                        tripEvent.type !== 'TripStart' &&
                        tripEvent.type !== 'Total'
                      "
                    >
                      <div
                        class=""
                        *ngFor="
                          let add of getAdd(tripEvent.tripEventId!);
                          let c = index
                        "
                      >
                        <div
                          *ngIf="
                            add &&
                            !add.label.includes('Fast') &&
                            !add.label.includes('Stk') &&
                            add.tripEventId === tripEvent.tripEventId
                          "
                          class=" bg-th-color{{ c + 1 }}"
                        >
                          <div class="">
                            <span class="ms-1"
                              >{{ removeBeforeWhitespace(add.label) }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      [formArrayName]="i"
                      *ngIf="tripEvent.type !== 'TripStart'"
                    >
                      <div *ngFor="let item of additions; let i = index">
                        <div
                          *ngIf="
                            item &&
                            (item.label.includes('Fast') ||
                              item.label.includes('Stk') ||
                              tripEvent.type == 'Total') &&
                            item.tripEventId === tripEvent.tripEventId
                          "
                          class="w-100 d-flex row p-0 m-0 align-items-end pe-3 mb-3 p-1"
                          style="min-height: 45px; max-height: 45px"
                        >
                          <div class="col-12 col-lg-6">
                            <label i18n="@@priceValType">{{
                              removeBeforeWhitespace(item.label)
                            }}</label>
                          </div>
                          <div
                            *ngIf="tripEvent.type == 'Total'"
                            class="col-12 col-lg-6 d-flex justify-content-lg-end pe-1"
                          >
                            <div class="me-1" i18n="onlyTakeValueFromTotal">
                              Only From Total
                            </div>
                            <input
                              class=""
                              [formControlName]="
                                removeSpaces(
                                  'onlyTakeValueFromTotal' + item.eProductId
                                )
                              "
                              type="checkbox"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="no-borders">
                    <div
                      *ngIf="tripEvent.type !== 'TripStart'"
                      class="reverse h-100px"
                    >
                      Extra row for
                    </div>

                    <div
                      [formArrayName]="i"
                      *ngIf="tripEvent.type !== 'TripStart'"
                    >
                      <div *ngFor="let item of additions; let i = index">
                        <div
                          *ngIf="
                            item &&
                            (item.label.includes('Fast') ||
                              item.label.includes('Stk') ||
                              tripEvent.type === 'Total') &&
                            item.tripEventId === tripEvent.tripEventId
                          "
                          class="w-100 form-group form-floating pe-0 mb-3"
                        >
                          <div class="row w-100 me-0">
                            <div
                              class="w-75"
                              style="
                                display: inline-block !important;
                                min-height: 45px;
                                max-height: 45px;
                                max-width: 65%;
                              "
                            >
                              <input
                                [class.isExported]="theTrip?.exportedAt"
                                [formControlName]="
                                  removeSpaces('price' + item.eProductId)
                                "
                                (input)="
                                  updateTotal(
                                    item.eProductId,
                                    tripEvent.type === 'Total'
                                  )
                                "
                                type="number"
                                class="form-control"
                                style="padding-top: 0.625rem !important"
                                placeholder="Price"
                                i18n-placeholder="Price placeholder @@priceVal"
                              />
                            </div>
                            <div
                              class="w-25 ps-0 pe-0 mt-1"
                              style="
                                display: inline-block !important;
                                min-width: 35%;
                              "
                            >
                              <span>{{
                                getNameForTypes(item.label.split(" ")[0])
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <tr
                      [formArrayName]="i"
                      class="bottom mt-2"
                      style="height: 10%; width: 100%"
                      *ngIf="
                        tripEvent.type !== 'TripStart' &&
                        tripEvent.type !== 'Total'
                      "
                    >
                      <div
                        *ngFor="
                          let item of getAdd(tripEvent.tripEventId!);
                          let i = index;
                          let j = index
                        "
                      >
                        <div
                          class="bg-th-color{{ i + 1 }}"
                          *ngIf="
                            item &&
                            !(
                              item.label.includes('Fast') ||
                              item.label.includes('Stk')
                            ) &&
                            item.tripEventId === tripEvent.tripEventId
                          "
                        >
                          <div
                            class="w-50"
                            style="display: inline-block !important"
                          >
                            <input
                              style="max-height: 20px !important"
                              [class.isExportedTextBox]="theTrip?.exportedAt"
                              [formControlName]="
                                removeSpaces('price' + item.eProductId)
                              "
                              (input)="
                                updateTotal(
                                  item.eProductId,
                                  tripEvent.type === 'Total'
                                )
                              "
                              type="number"
                              class="form-control"
                            />
                          </div>
                          <div
                            class="w-50"
                            style="display: inline-block !important"
                          >
                            <span class="ms-1">{{
                              item.label.replace("Minutter", "Timer")
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </tr>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div
          *ngIf="auth.hasFeature$(TenantFeatures.EconomicOrder) | async"
          class="d-flex"
        >
          <div *ngFor="let photo of photos; let i = index" class="d-flex">
            <div
              class="image-container mx-1 d-flex justify-content-center align-items-center"
            >
              <img
                (click)="getImgModal(i)"
                class="image"
                src="{{ photo.url }}"
              />
            </div>

            <input
              type="checkbox"
              id="order_photo_{{ i }}"
              class="form-check-input"
              [attr.checked]="photo.isActive ? true : undefined"
            />
          </div>
        </div>

        <div *ngIf="!theTrip?.exportedAt" class="form-floating mt-4">
          <div
            style="width: auto; overflow-x: scroll; overflow-y: scroll"
            class="table-responsive custom-scroll"
          >
            <div class="modal-footer justify-content-center">
              <button
                class="btn btn-primary me-2"
                style="width: 45%"
                type="submit"
                i18n="Save group button"
              >
                Save
              </button>
              <button
                class="btn btn-secondary"
                style="width: 45%"
                (click)="obliterate()"
                i18n="@@cancelGroup"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div
  *ngIf="auth.hasFeature$(TenantFeatures.EconomicOrder) | async"
  #imageSlider
></div>
