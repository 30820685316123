
<div class="container-fluid">
    <div class="btn-group" role="group">
      <button *ngFor="let pageNumber of pages"
              class="btn"
              [class.btn-outline-primary]="page !== pageNumber"
              [class.btn-primary]="page === pageNumber"
              (click)="changePage(pageNumber)">
        {{ pageNumber }}
      </button>
      <div style="padding-left:10px;padding-top:5px;">
        <app-spinner *ngIf="loading$ | async"></app-spinner>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <!-- <div class="col-sm-3 col-md-6 col-lg-4">
        <label for="table-complete-search" class="col-xs-3 col-sm-auto col-form-label">Søg:</label>
        <input id="table-complete-search" type="text" class="form-control" name="searchTerm" [(ngModel)]="searchTerm">
      </div> -->
      <div class="col-sm-4 col-md-8 col-lg-6">
        <label for="dateFrom" class="col-xs-3 col-sm-auto col-form-label">Fra</label>
        <input id="dateFrom" type="date" class="form-control" name="dateFrom" [(ngModel)]="dateFrom" (ngModelChange)="changeState()">
      </div>
      <div class="col-sm-4 col-md-8 col-lg-6">
        <label for="dateTo" class="col-xs-3 col-sm-auto col-form-label">Til</label>
        <input id="dateTo" type="date" class="form-control" name="dateTo" [(ngModel)]="dateTo" (ngModelChange)="changeState()">
      </div>    
      <div *ngIf="isAdminPage" class="col-sm-3 col-md-6 col-lg-4">
        <label for="dateTo" class="col-xs-3 col-sm-auto col-form-label">Chauffør</label>
        <app-datalist-select *ngIf="!(loading$ | async)" id="driversSelect" [options]="driverOptions"
                             [(activeValue)]="driverSelected"
                             (activeValueChange)="changeState()"
                             label="Chauffør" i18n-label="Label driver"></app-datalist-select>
      </div>

      <div *ngIf="isAdminPage" style="padding-top:15px;" class="col-sm-3 col-md-6 col-lg-4  mt-4">
        <label for="isApprovedSearch" class="col-xs-3 col-sm-auto col-form-label align-middle">Ikke behandlet</label>
        <input id="isApprovedSearch" name="isApprovedSearch" [(ngModel)]="isApprovedSearch" (ngModelChange)="changeState()" class=" align-middle" style="margin-left:10px; width: 20px; height: 20px;" type="checkbox" aria-label="">

      </div>
      <div style="padding-top:15px;" class="col-sm-3 col-md-6 col-lg-4  mt-4">
        <div style="display: inline; ">
          <app-import-export *ngIf="authRepo.isTenantAdmin$ | async" class="float-right"
                             filenamePrefix="workdays"
                             [exportFactory]="exportFactory"></app-import-export>
        </div>
      </div>
    </div>
  </div>

  <div style="width:auto;overflow-x:scroll;" class="table-responsive custom-scroll">

    <table style="margin-top:15px;" class="table table-hover table-bordered" [class.darkModeCustom]="ui.isDarkMode$ | async">
      <thead>
        <tr>
          <th *ngIf="isAdminPage" class="text-center align-middle" sortable="isApprove" (sort)="onSort($event)">
            Behandlet
          </th>
          <th class="text-center align-middle" scope="col" sortable="userName" (sort)="onSort($event)">
            Chauffør
          </th>
          <th class="text-center align-middle" scope="col" sortable="dateForShown" (sort)="onSort($event)">
            Start Dato
          </th>
          <th class="text-center align-middle" scope="col" sortable="startTime" (sort)="onSort($event)">
            Ch. Dagstart*
          </th>
          <th class="text-center align-middle" scope="col">
            Redigere Start
          </th>
          <th class="text-center align-middle" scope="col" sortable="stopDate" (sort)="onSort($event)">
            Slut Dato
          </th>
          <th class="text-center align-middle" scope="col" sortable="stopTime" (sort)="onSort($event)">
            Ch. Dagslut*
          </th>
          <th class="text-center align-middle" scope="col">
            Redigere Slut
          </th>
          <th class="text-center align-middle" scope="col" sortable="pause" (sort)="onSort($event)">
            Pause min.
          </th>
          <th class="text-center align-middle" scope="col">
            Redigere Pause
          </th>
          <th class="text-center align-middle" scope="col" sortable="workTime" (sort)="onSort($event)">
            Løn timer
          </th>

        </tr>
      </thead>
      <tbody>
        <tr [class.darkModeCustomTr]="workDay?.isApprove ? (ui.isDarkMode$ | async): ''"  [style.background-color]="isAdminPage ? workDay?.isApprove ? '#b5ffd5' : '' : ''" style="background-color: #7297f8;"  *ngFor="let workDay of OutValues">
          <th *ngIf="isAdminPage" class="text-center align-middle">
            <input [checked]="workDay?.isApprove" [attr.disabled]="isAdminPage ? null : true" (change)="changeApprove(workDay)" style="width: 20px; height: 20px;" type="checkbox" aria-label="">
          </th>
          <td class="text-center align-middle">
            {{workDay.userName}}
          </td>
          <td class="text-center align-middle">
            {{workDay.dateForShown}}
          </td>
          <td class="text-center align-middle">
            {{workDay.startTime}}
          </td>
          <td class="text-center align-middle">
            <a *ngIf="workDay.eventSrartId" class="btn btn-sm btn-link py-0 p-md-0"
               [routerLink]="['/workdays', 'edit', workDay.eventSrartId, isAdminPage]">
              <i class="bi bi-pencil me-1"></i> <ng-container i18n="@@edit">Edit</ng-container>
            </a>
          </td>
          <td class="text-center align-middle">
            {{workDay.stopDate}}
          </td>
          <td class="text-center align-middle">
            {{workDay.stopTime}}
          </td>
          <td class="text-center align-middle">
            <a *ngIf="workDay.eventEndId" class="btn btn-sm btn-link py-0 p-md-0"
               [routerLink]="['/workdays', 'edit', workDay.eventEndId, isAdminPage]">
              <i class="bi bi-pencil me-1"></i> <ng-container i18n="@@edit">Edit</ng-container>
            </a>
          </td>
          <td class="text-center align-middle">
            {{workDay.pause}}
          </td>
          <td class="text-center align-middle">
            <a *ngIf="workDay.eventPauseId" class="btn btn-sm btn-link py-0 p-md-0"
               [routerLink]="['/workdays', 'edit', workDay.eventPauseId, isAdminPage]">
              <i class="bi bi-pencil me-1"></i> <ng-container i18n="@@edit">Edit</ng-container>
            </a>
            <a *ngIf="!workDay.eventPauseId" class="btn btn-sm btn-link py-0 p-md-0"
               [routerLink]="['/workdays', 'add', 'new', isAdminPage, workDay.workdayId]">
              <i class="bi bi-pencil me-1"></i> <ng-container i18n="@@addWhereNoPause">Add</ng-container>
            </a>
          </td>
          <td class="text-center align-middle">
            {{workDay.workTimeShow}}
          </td>     
        </tr>
        <tr>
          <th class="text-center align-middle" scope="col">Sum</th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th *ngIf="isAdminPage"></th>
          <th class="text-center">{{OutValues && calculateDurationSum(OutValues)}}</th>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="container-fluid mt-4">
    <div class="btn-group" role="group">
      <button *ngFor="let pageNumber of pages"
              class="btn"
              [class.btn-outline-primary]="page !== pageNumber"
              [class.btn-primary]="page === pageNumber"
              (click)="changePage(pageNumber)">
        {{ pageNumber }}
      </button>
      <div style="padding-left:10px;padding-top:5px;">
        <app-spinner *ngIf="loading$ | async"></app-spinner>
      </div>
    </div>
  </div>

