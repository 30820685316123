import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { sortBy } from 'src/app/modules/shared/pipes/sort.pipe';
import { ConnectionService } from 'src/app/modules/shared/services/connection.service';
import { DayjsService } from 'src/app/modules/shared/services/dayjs.service';
import { LocationService } from 'src/app/modules/shared/services/location.service';
import { Accessory } from 'src/app/state/accessories.repository';
import { Cleaning } from 'src/app/state/cleaning.repository';
import { Company } from 'src/app/state/companies.repository';
import { TenantFeatures } from 'src/app/state/feature.repository';
import { Product } from 'src/app/state/products.repository';
import { TripEvent } from 'src/app/state/trips.repository';

@Component({
  selector: 'app-trip-info-form',
  templateUrl: './trip-info-form.component.html',
  styleUrls: ['./trip-info-form.component.scss'],
})
export class TripInfoFormComponent implements OnInit {
  @ViewChild('placePickRef') placePickRef: GooglePlaceDirective | null = null;
  @ViewChild('placeDelRef') placeDelRef: GooglePlaceDirective | null = null;
  myOptions = new Options({
    componentRestrictions: {
      country: 'Dk',
    },
  });
  testsLocation = 'localhost:9876';
  formattedaddress = ' ';
  TenantFeatures = TenantFeatures;
  isClicked = false;

  @Input() action:
    | 'start'
    | 'stop'
    | 'unload'
    | 'load'
    | 'edit'
    | 'takephoto'
    | null = null;
  @Input() features: string[] = [];
  @Input() isAdmin: 'true' | 'false' = 'false';
  @Input() initialEvent?: TripEvent | null;
  @Input() pickUpAddress?: string | undefined;
  @Input() deliveryAddress?: string | undefined;
  @Output() tripSubmit = new EventEmitter<Partial<TripEvent>>();
  @Output() tripLocationSubmit = new EventEmitter<Partial<TripEvent>>();
  @Input() set accessories(value: Accessory[] | null) {
    if (!value) {
      this.accessoryOptions = null;
    } else {
      this.accessoryOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
        sublabel: x.description,
      }));
    }
  }
  @Input() set deletedProducts(value: Product[] | null) {
    if (!value) {
      this.delProducts = null;
    } else {
      this.delProducts = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }

  @Input() set deletedCompanies(value: Company[] | null) {
    if (!value) {
      this.delCompanies = null;
    } else {
      this.delCompanies = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }

  @Input() set deletedAccessories(value: Accessory[] | null) {
    if (!value) {
      this.delAccessories = null;
    } else {
      this.delAccessories = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }

  @Input() set cleanings(value: Cleaning[] | null) {
    if (!value) {
      this.cleaningOptions = null;
    } else {
      this.cleaningOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
        sublabel: x.description,
      }));
    }
  }
  @Input() set companies(value: Company[] | null) {
    if (!value) {
      this.clientOptions = null;
      this.vendorOptions = null;
    } else {
      this.clientOptions = sortBy(
        value.filter((x) => x.canBeClient).map((x) => this.mapCompany(x)),
        { parameter: { property: 'label' }, direction: 'asc' }
      );
      this.clientOptions = this.clientOptions.filter(
        (x) => x.isActive || x.value == this.form?.value.clientId
      );
      this.vendorOptions = value
        .filter((x) => x.canBeVendor)
        .map((x) => this.mapCompany(x));
    }
  }
  @Input() set products(value: Product[] | null) {
    if (!value) {
      this.productOptions = null;
    } else {
      this.productOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
        sublabel: x.description,
      }));
      this.productOptions = sortBy(this.productOptions, {
        parameter: { property: 'label' },
        direction: 'asc',
      });
    }
  }
  @Input() isLoading: boolean | null = null;

  form?: UntypedFormGroup;
  delCompanies: DatalistOption[] | null = null;
  delProducts: DatalistOption[] | null = null;
  delAccessories: DatalistOption[] | null = null;
  accessoryOptions: DatalistOption[] | null = null;
  cleaningOptions: DatalistOption[] | null = null;
  clientOptions: DatalistOption[] | null = null;
  vendorOptions: DatalistOption[] | null = null;
  productOptions: DatalistOption[] | null = null;
  files? = new Array<File>();
  urls = new Array<string>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private locationService: LocationService,
    public ngDay: DayjsService
  ) {}

  locationCheck() {
    if (window.location.host !== this.testsLocation) {
      return true;
    }
    return false;
  }

  ngOnInit(): void {
    const dayObj = this.ngDay.dayjs.utc(this.initialEvent?.eventTime).local();
    let weight = this.initialEvent?.weight?.toString();
    if (weight?.includes('.')) {
      weight = weight.replace(/\./g, ',');
    }
    if (this.action !== 'takephoto') {
      this.form = this.formBuilder.group({
        km:
          this.action === 'edit'
            ? [this.initialEvent?.km, Validators.required]
            : [0, Validators.required],
        weight: this.initialEvent?.weight
          ? [weight, [Validators.required, this.commaOccurrenceValidator()]]
          : [0, [Validators.required, this.commaOccurrenceValidator()]],
        clientId: [this.initialEvent?.clientId, Validators.required],
        vendorId: [this.initialEvent?.vendorId],
        accessoryId: [this.initialEvent?.accessoryId],
        contactPerson: [this.initialEvent?.contactPerson],
        reference: [this.initialEvent?.reference],
        comment: [this.initialEvent?.comment],
        cleaningId: [this.initialEvent?.cleaningId],
        note: [
          this.action === 'load' || this.action === 'unload'
            ? ''
            : this.initialEvent?.note,
        ],
        productIds: [this.initialEvent?.products?.map((x) => x.id)],
        type: [this.initialEvent?.type],
        date: [dayObj.format('YYYY-MM-DD')],
        time: [dayObj.format('HH:mm:ss')],
        pickUpAddress: [this.pickUpAddress ?? ''],
        deliveryAddress: [this.deliveryAddress ?? ''],
      });
    } else {
      this.form = this.formBuilder.group({
        km: [this.initialEvent?.km, Validators.required],
        weight: [
          weight,
          [Validators.required, this.commaOccurrenceValidator()],
        ],
        clientId: [this.initialEvent?.clientId],
        vendorId: [this.initialEvent?.vendorId],
        accessoryId: [this.initialEvent?.accessoryId],
        contactPerson: [this.initialEvent?.contactPerson],
        reference: [this.initialEvent?.reference],
        comment: [this.initialEvent?.comment],
        cleaningId: [this.initialEvent?.cleaningId],
        note: [this.initialEvent?.note],
        productIds: [this.initialEvent?.products?.map((x) => x.id)],
        type: [this.initialEvent?.type],
        date: [dayObj.format('YYYY-MM-DD')],
        time: [dayObj.format('HH:mm:ss')],
      });
    }

    if (this.clientOptions) {
      this.clientOptions = this.clientOptions.filter(
        (x) => x.isActive || x.value == this.form?.value.clientId
      );
    }
  }

  getHeight(s?: string) {
    let length = s?.length || 0;
    let lineCount = s?.split('\n').length || 0;
    if (!s || length < 48) {
      return `${60 + lineCount * 50}px`;
    }
    if (s && length >= 48 && length < 96) {
      return `${100 + lineCount * 50}px`;
    }
    if (s && length >= 96) {
      return `${Math.round(length / 48 + lineCount) * 50}px`;
    }
    return '90px';
  }

  private mapCompany(x: Company): DatalistOption {
    return {
      value: x.id,
      label: x.name,
      sublabel: [x.address, x.city, x.zipCode].filter((x) => x).join(', '),
      isActive: x.isActive,
    };
  }

  updateValue(control: string, value: any) {
    const controlObject = this.form?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  validateInput(event: KeyboardEvent) {
    const key = event.key;
    const allowedKeys = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      ',',
      'Backspace',
    ];

    if (!allowedKeys.includes(key)) {
      event.preventDefault();
    }
  }

  commaOccurrenceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const inputValue = control.value as string;
      if (inputValue) {
        const commaCount = (inputValue.match(/,/g) || []).length;
        if (commaCount <= 1) {
          return null; // Return null if the validation passes
        } else {
          return { required: true }; // Return an error object if the validation fails
        }
      } else {
        return null;
      }
    };
  }

  redirectToAddressOnMaps(destination: string) {
    if (!destination) {
      return;
    }
    const p = 'DK';
    var coordinates = this.locationService.getPosition();
    coordinates.then((x) => {
      window.open(
        `https://www.google.com/maps/dir/?api=1&origin=${x.latitude.valueOf()},${x.longitude.valueOf()}` +
          '&destination=' +
          destination +
          '&travelmode=driving',
        '_blank'
      );
    });
  }

  handleAddressChange($event: any, control: string) {
    this.formattedaddress = $event.formatted_address;
    this.updateValue(control, this.formattedaddress);
  }

  onSubmit() {
    if (this.form && !this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    let weight = this.form?.value.weight;

    if (weight.toString().includes(',')) {
      weight = +this.form?.value.weight.replace(/\,/g, '.');
    }
    // this.tripLocationSubmit.emit({
    //   pickUpAddress: this.form?.value?.pickUpAddress,
    //   deliveryAddress: this.form?.value?.deliveryAddress,
    // });
    this.isClicked = true;

    const date = this.form?.value?.date.toString();
    const time = this.form?.value?.time.toString();
    const date_time = this.ngDay
      .dayjs(date + ' ' + time)
      .utc()
      .toDate();
    let note = this.form?.value.note
      ? this.form?.value.note.toString().replace(/(\S+)[\s\n]+$/, '$1')
      : '';
    localStorage.setItem('pickupaddress', this.form?.value?.pickUpAddress);
    localStorage.setItem('deliveryaddress', this.form?.value?.deliveryAddress);
    this.tripSubmit.emit({
      km: this.form?.value.km,
      weight: weight,
      accessoryId: this.form?.value.accessoryId || null,
      contactPerson: this.form?.value.contactPerson,
      reference: this.form?.value.reference,
      comment: this.form?.value.comment,
      cleaningId: this.form?.value.cleaningId || null,
      clientId: this.form?.value.clientId || null,
      vendorId: this.form?.value.vendorId || null,
      note: note,
      productIds: this.form?.value.productIds,
      type: this.form?.value.type,
      eventTime: date_time,
      tripFiles: this.files,
      tenantNote: this.initialEvent?.tenantNote,
      pickUpAddress: this.form?.value?.pickUpAddress,
      deliveryAddress: this.form?.value?.deliveryAddress,
    });
    this.form?.markAsUntouched();
  }
}
